import React from 'react';
import styled from 'styled-components';
import {
  NumberInput,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  useLocalStorage,
} from '@vizcom/shared-ui-components';
import {
  Palette,
  VERSION_SELECTIONS_KEY,
  VersionTag,
} from '../../modals/PaletteSelector';
import { VerticalDivider } from '../WorkbenchStudioMenu/WorkbenchStudioMenu';

type PaletteChipProps = {
  palette: Palette;
  paletteInfluence: number;
  onPaletteInfluenceChange: (value: number) => void;
  showInfluenceSlider: boolean;
};

const PaletteChip: React.FC<PaletteChipProps> = ({
  palette,
  paletteInfluence,
  onPaletteInfluenceChange,
  showInfluenceSlider,
}) => {
  const [versionSelections] = useLocalStorage(
    VERSION_SELECTIONS_KEY,
    {} as Record<string, number>
  );

  return (
    <ChipContainer>
      <ThumbnailWrapper>
        {palette.thumbnailPath && (
          <Thumbnail src={palette.thumbnailPath} alt={palette.name} />
        )}
      </ThumbnailWrapper>
      <PaletteName $fullWidth={!showInfluenceSlider}>
        {palette.name}
      </PaletteName>
      {palette.versions && palette.versions.length > 0 && (
        <VersionTag>
          v{versionSelections[palette.id] ?? palette.versions.length + 1}
        </VersionTag>
      )}
      {showInfluenceSlider && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <VerticalDivider />
          <NumberInputWrapper
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <RichTooltip
              noParentIntegration
              placement="top"
              trigger="hover"
              delay={{
                open: 500,
                close: 0,
              }}
              padding={8}
            >
              <RichTooltipTrigger>
                <div>
                  <NumberInput
                    value={Math.round(paletteInfluence * 100)}
                    min={0}
                    max={100}
                    unit="%"
                    setValue={(value) =>
                      onPaletteInfluenceChange(
                        (Math.round(value / 5) * 5) / 100
                      )
                    }
                    dragArrows={false}
                    enableGestureSlider
                  />
                </div>
              </RichTooltipTrigger>
              <RichTooltipContent>
                <Text>Adjust the strength of the palette influence</Text>
              </RichTooltipContent>
            </RichTooltip>
          </NumberInputWrapper>
        </div>
      )}
      <Spacer />
    </ChipContainer>
  );
};

const ChipContainer = styled.div`
  display: flex;
  width: 168px;
  height: 32px;
  padding: 4px 2px 4px 4px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.secondary.default};
  background: ${({ theme }) => theme.surface.e1};
  cursor: pointer;
  overflow: hidden;
  &:hover {
    border: 1px solid ${({ theme }) => theme.secondary.hover};
  }
`;

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 4px;
  flex-shrink: 0;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const PaletteName = styled.span<{ $fullWidth?: boolean }>`
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: ${(props) => (props.$fullWidth ? 'calc(100% - 40px)' : '64px')};
  margin-left: 8px;
  margin-right: 8px;
`;

const NumberInputWrapper = styled.div`
  width: 48px;
  flex-shrink: 0;
`;

const Spacer = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

export default PaletteChip;
