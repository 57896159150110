import styled, { css } from 'styled-components';

// @ts-ignore
import noThumbnailImg from './icon-no-thumbnail.svg?url';

const WrapperHoverStyles = css`
  box-shadow: 0 0 0 2px ${(p) => p.theme.primary.default};
  ::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent 70%);
    opacity: 1;
  }
`;

const WrapperSelectedStyles = css`
  ${WrapperHoverStyles}

  ::after {
    background: rgba(76, 76, 239, 0.2);
    opacity: 1;
    inset: 3px;
    border-radius: ${(p) => p.theme.borderRadius.file};
  }
`;

export const Wrapper = styled.div<{
  $logoBackground?: boolean;
  $selected?: boolean;
}>`
  padding: 3px;
  cursor: pointer;
  overflow: hidden;

  border-radius: ${(p) => p.theme.borderRadius.file};
  color: ${(p) => p.theme.text.default};

  transition: 0.2s all ease;

  ::after {
    transition: 0.2s all ease;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent 70%);
    opacity: 0;
    z-index: 2;
  }

  ${(p) => p.$selected && WrapperSelectedStyles}

  :hover,
  .hover & {
    ${WrapperHoverStyles}
  }

  position: relative;
  ${(p) =>
    p.$logoBackground &&
    `
    background-image: url(${noThumbnailImg});
    background-repeat: no-repeat;
    background-position: 50% 45%;
  `}
`;

export const ThumbnailContainer = styled.div`
  border-radius: ${(p) => p.theme.borderRadius.file}
    ${(p) => p.theme.borderRadius.file} 0 0;
  overflow: hidden;
  aspect-ratio: 16/9;
  display: flex;
  img {
    transition: 0.15s ease transform;
    pointer-events: none;
    object-fit: contain;
  }

  ${Wrapper}:hover & img, .hover & img {
    transform: scale(1.2);
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
`;

export const Content = styled.div`
  padding: 17px 17px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 0 0 ${(p) => p.theme.borderRadius.file}
    ${(p) => p.theme.borderRadius.file};
  background-color: ${({ theme }) => theme.surface.e1};
`;
