import {
  ChoiceContainer,
  Choice as ChoiceStyled,
  Locked,
  Selected,
} from '../style';

type ChoiceProps = {
  choices: { name: string; value: string | null; locked?: boolean }[];
  value: any;
  disabled?: boolean;
  onChange: (value: any) => void;
  onLocked?: (value: any) => void;
};

export const Choice = ({
  choices,
  value,
  disabled,
  onChange,
  onLocked,
}: ChoiceProps) => (
  <ChoiceContainer>
    {choices.map((choice) => (
      <ChoiceStyled
        key={choice.value}
        $selected={choice.value === `${value}`}
        onPointerDown={() => {
          if (disabled) return;

          if (choice.locked) {
            if (onLocked) onLocked(choice.value);
            return;
          }
          if (choice.value !== `${value}`) {
            onChange(choice.value);
          }
        }}
        data-close
      >
        {choice.name}
        {choice.locked && <Locked />}
        {choice.value === `${value}` && <Selected />}
      </ChoiceStyled>
    ))}
  </ChoiceContainer>
);
