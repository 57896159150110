import { useKeyboardShortcut } from '@vizcom/shared-ui-components';
import { SelectionApi } from './useSelectionApi';
import { isSelectionTool, useWorkbenchStudioToolState } from '../studioState';
import { StoreApi } from 'zustand';

//common hotkeys for selection tools
export function useSelectionShortcuts(selectionApi: StoreApi<SelectionApi>) {
  const [tool] = useWorkbenchStudioToolState((s) => [s.tool]);
  useKeyboardShortcut(
    'i',
    (e) => {
      e.preventDefault();
      if (isSelectionTool(tool) || selectionApi.getState().hasMask) {
        selectionApi.getState().invertMask();
      }
    },
    { ctrl: true }
  );

  useKeyboardShortcut(
    'd',
    (e) => {
      e.preventDefault();
      selectionApi.getState().deselectMask();
    },
    { ctrl: true }
  );

  useKeyboardShortcut(
    'a',
    (e) => {
      e.preventDefault();
      selectionApi.getState().selectAll();
    },
    { ctrl: true }
  );
}
