import { Toaster } from 'react-hot-toast';

export const ToastsContainer = () => {
  return (
    <Toaster
      containerStyle={{
        position: 'relative',
        inset: '0px',
        height: 'fit-content',
        userSelect: 'none',
        touchAction: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
      }}
      position="bottom-center"
    />
  );
};
