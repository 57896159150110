import styled from 'styled-components';
import { ToolSettings } from './ToolSettings';

export const EraserSettings = () => {
  return <StyledToolSettings />;
};

const StyledToolSettings = styled(ToolSettings)`
  width: 360px;
`;
