import { button, folder, useControls } from 'leva';
import { MutableRefObject, useMemo, useState } from 'react';
import { REALTIME_PREVIEW_CUSTOM_WORKFLOW_LOCALSTORAGE_KEY } from '../../../../lib/useCompositeSceneRender';
import { useTheme } from 'styled-components';
import { ButtonInput } from 'leva/dist/declarations/src/types';
import { RenderingOption } from '../types';
import { useSnapshot3D } from './useSnapshot3D';
import { RootState } from '@react-three/fiber';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import { useObjectURL } from '@vizcom/shared-ui-components';
import { OBJExporter } from 'three-stdlib';
import { Object3D } from 'three';

export type CompositeSceneDebugSettings = {
  enabled: boolean;
  activePreview: RenderingOption | 'default';
  sendToBackend: Record<RenderingOption, boolean>;
  debugViewport: string | undefined;
};

const renderingOptions: Record<RenderingOption, string> = {
  color: 'Color',
  depth: 'Depth',
  normal: 'Normal',
  depthPlusAO: 'Depth+AO',
  sobel: 'Sketch (Contrast)',
  sobelDepth: 'Sketch (Depth)',
};

export const useCompositeSceneDebug = ({
  compositeScene,
  threeStateRef,
}: {
  threeStateRef: MutableRefObject<RootState | null>;
  compositeScene?: CompositeSceneFullData;
}): CompositeSceneDebugSettings => {
  const enabled = !!localStorage.getItem('vizcom:debug:compositeEditor');
  const theme = useTheme();
  const [activePreview, setActivePreview] = useState<
    RenderingOption | 'default'
  >('default');
  const [sendToBackend, setSendToBackend] = useState(() => {
    const options = {} as Record<RenderingOption, boolean>;

    Object.keys(renderingOptions).forEach((key) => {
      options[key as RenderingOption] = false;
    });

    return options;
  });

  const { scene, camera } = threeStateRef.current || {};

  const compositeSceneResolution = 512;
  const compositeSceneRatio = compositeScene
    ? compositeScene.width / compositeScene.height
    : 1.0;

  const snapshot = useSnapshot3D(
    enabled ? scene ?? null : null,
    enabled ? camera ?? null : null,
    {
      noHDRI: true,
      width: compositeSceneResolution * compositeSceneRatio,
      height: compositeSceneResolution,
      quality: 100,
      type: 'jpg',
      postprocessing: activePreview === 'default' ? null : activePreview,
    },
    [enabled, activePreview, compositeScene, scene, camera]
  );
  const debugViewport = useObjectURL(
    enabled && snapshot ? snapshot : undefined
  );

  const livePreviewOptions = useMemo(() => {
    const options: Record<string, ButtonInput> = {};

    Object.entries(renderingOptions).forEach(([key, label]) => {
      options[`Show ${label} Preview`] = button(
        () => {
          setActivePreview(key as RenderingOption);
        },
        { disabled: activePreview === key }
      );
    });

    return options;
  }, [activePreview]);

  const uploadOptions = useMemo(() => {
    const options: Record<
      string,
      { value: boolean; onChange: (newValue: boolean) => void }
    > = {};

    Object.entries(sendToBackend).forEach(([key, value]) => {
      options[key] = {
        value,
        onChange: (newValue: boolean) =>
          setSendToBackend((current) => ({
            ...current,
            [key]: newValue,
          })),
      };
    });

    return options;
  }, [sendToBackend]);

  useControls(
    enabled
      ? {
          'Live Preview': folder(
            {
              'Show AI Preview': button(
                () => {
                  setActivePreview('default');
                },
                {
                  disabled: activePreview === 'default',
                }
              ),
              ...livePreviewOptions,
            },
            {
              color: theme.primary.default,
              order: 1,
            }
          ),
          'Textures Sent': folder(uploadOptions, {
            color: theme.white,
            order: 2,
          }),
          '3D Scene': folder(
            {
              'Export as OBJ': button(() => {
                if (!scene) {
                  return;
                }

                const sceneClone = scene.clone();
                let sceneRoot: Object3D | null;

                sceneClone.traverse((object) => {
                  if (object.name === 'composite-scene-root') {
                    sceneRoot = object;
                  }
                });

                if (!sceneRoot!) {
                  return;
                }

                const invalidObjects: Array<Object3D> = [];

                sceneRoot.traverse((object) => {
                  if (object !== sceneRoot && !object.userData.selectable) {
                    invalidObjects.push(object);
                  }
                });

                invalidObjects.forEach((object) => {
                  object.removeFromParent();
                });

                const exporter = new OBJExporter();
                const result = exporter.parse(sceneClone);
                const blob = new Blob([result], {
                  type: 'text/plain',
                });
                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = 'vizcom-preview.obj';
                a.style.display = 'none';
                document.body.appendChild(a);

                a.click();
                URL.revokeObjectURL(url);
              }),
            },
            {
              color: '#ff00ff',
              order: 2,
            }
          ),
          'ComfyUI Custom Workflow': folder(
            {
              'Upload workflow': button(() => {
                const res = prompt('Custom workflow');
                if (!res) {
                  return;
                }
                try {
                  JSON.parse(res);
                } catch (e) {
                  alert(`Could not parse the workflow: ${e}`);
                }
                localStorage.setItem(
                  REALTIME_PREVIEW_CUSTOM_WORKFLOW_LOCALSTORAGE_KEY,
                  res
                );
              }),
              'Reset workflow': button(() => {
                localStorage.removeItem(
                  REALTIME_PREVIEW_CUSTOM_WORKFLOW_LOCALSTORAGE_KEY
                );
              }),
            },
            {
              color: theme.tertiary.default,
              order: 2,
            }
          ),
        }
      : {},
    [activePreview, sendToBackend, scene]
  );

  return {
    enabled,
    activePreview,
    sendToBackend,
    debugViewport,
  };
};
