import {
  addToast,
  Button,
  CheckIcon,
  LinkIcon,
} from '@vizcom/shared-ui-components';
import { publishTrackingEvent } from '@vizcom/shared/data-access/graphql';
import { WorkbenchSharingName } from '@vizcom/shared/data-shape';
import { WorkbenchBasicDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useState } from 'react';
import { useTheme } from 'styled-components';

type CopySharedLinkButtonProps = {
  workbench: WorkbenchBasicDataFragment;
  disabled?: boolean;
  style?: React.CSSProperties;
  variant?: 'transparent' | 'primary';
};

export function CopySharedLinkButton({
  workbench,
  disabled = false,
  style = {},
  variant = 'transparent',
}: CopySharedLinkButtonProps) {
  const [linkCopied, setLinkCopied] = useState(false);
  const theme = useTheme();

  const handleCopyLink = async () => {
    const displayDuration = 3_000;

    await navigator.clipboard.writeText(`${location.href}`);

    addToast('Your link has been copied to your clipboard', {
      duration: displayDuration,
    });

    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, displayDuration);

    publishTrackingEvent({
      type: WorkbenchSharingName.SHARING_LINK_COPIED,
      data: {
        workbenchId: workbench.id,
      },
    });
  };

  return (
    <Button
      variant={variant}
      size="L"
      onClick={handleCopyLink}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        padding: '10px 0px',
        width: '110px',
        justifyContent: 'center',
        fontSize: '12px',
        ...style,
      }}
      disabled={disabled || linkCopied}
    >
      {linkCopied ? (
        <CheckIcon width={12} height={12} />
      ) : (
        <LinkIcon
          width={15}
          height={15}
          fill={disabled ? theme.primary.disabled : theme.text.default}
        />
      )}
      <span style={{ marginLeft: '5px' }}>
        {linkCopied ? 'Link copied!' : 'Copy link'}
      </span>
    </Button>
  );
}
