const ATTENUATE_FACTOR = 1.5;

export const logScale = (value: number, min: number, max: number): number => {
  const logMin = Math.log(min);
  const logMax = Math.log(max);
  const scale = (Math.log(value) - logMin) / (logMax - logMin);
  return Math.pow(scale, ATTENUATE_FACTOR);
};

export const expScale = (value: number, min: number, max: number): number => {
  const logMin = Math.log(min);
  const logMax = Math.log(max);
  const scale = Math.pow(value, 1 / ATTENUATE_FACTOR);
  return Math.exp(logMin + scale * (logMax - logMin));
};
