import { CubicBezierCurve, Vector2 } from 'three';
import { BezierPoint, ControlPoint } from '../useSelectionApi';

// When "dragging" a line, we're actually moving its two adjecent control points.
// moving the control points at the rate of the cursor movement would make the line
// not keep up with the cursor, so we multiply the cursor movement by this value
// which represents the ratio of the control point movement to the line movement
export const LINE_DRAG_MULTIPLIER = 4 / 3;

const createLUT = (curve: CubicBezierCurve, precision = 100) => {
  const lut = [];

  for (let i = 0; i <= precision; i++) {
    const t = i / precision;
    const point = curve.getPoint(t);
    lut.push({ t, point });
  }

  return lut;
};

const distanceSquared = (p1: Vector2, p2: Vector2) => {
  const dx = p1.x - p2.x;
  const dy = p1.y - p2.y;
  return dx * dx + dy * dy;
};

const findClosestLUTIndex = (
  lut: Array<{ t: number; point: Vector2 }>,
  point: Vector2
) => {
  let minDistSq = Infinity;
  let closestIndex = 0;

  for (let i = 0; i < lut.length; i++) {
    const distSq = distanceSquared(lut[i].point, point);
    if (distSq < minDistSq) {
      minDistSq = distSq;
      closestIndex = i;
    }
  }

  return closestIndex;
};

const refineClosestT = (
  curve: CubicBezierCurve,
  point: Vector2,
  t1: number,
  t2: number,
  iterations = 10
) => {
  for (let iter = 0; iter < iterations; iter++) {
    const interval = (t2 - t1) / 4;

    const testPoints = [t1, t1 + interval, (t1 + t2) / 2, t2 - interval, t2];

    let minDistSq = Infinity;
    let closestT = (t1 + t2) / 2;

    for (const t of testPoints) {
      const curvePoint = curve.getPoint(t);
      const distSq = distanceSquared(curvePoint, point);
      if (distSq < minDistSq) {
        minDistSq = distSq;
        closestT = t;
      }
    }

    t1 = closestT - interval;
    t2 = closestT + interval;
  }

  return (t1 + t2) / 2;
};

export const getParametricValue = (
  curve: CubicBezierCurve,
  point: Vector2,
  precision = 100,
  iterations = 10
) => {
  const lut = createLUT(curve, precision);

  const closestIndex = findClosestLUTIndex(lut, point);

  const t1 = lut[Math.max(closestIndex - 1, 0)].t;
  const t2 = lut[Math.min(closestIndex + 1, lut.length - 1)].t;

  return refineClosestT(curve, point, t1, t2, iterations);
};

export const moveCounterControlPoint = (
  point: BezierPoint,
  control: ControlPoint,
  counterControl: ControlPoint,
  length: number
) => {
  const innerControlVectorX = control.x - point.x;
  const innerControlVectorY = control.y - point.y;

  const innerControlDirectionLength = Math.sqrt(
    innerControlVectorX * innerControlVectorX +
      innerControlVectorY * innerControlVectorY
  );
  const normalizedInnerControlX =
    innerControlVectorX / innerControlDirectionLength;
  const normalizedInnerControlY =
    innerControlVectorY / innerControlDirectionLength;

  counterControl.x = point.x - normalizedInnerControlX * length;
  counterControl.y = point.y - normalizedInnerControlY * length;
};
