import {
  urqlClient,
  DeleteWorkbenchElementsMutation,
  Drawing,
  drawingsByIds,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { MultiCreateAction } from './multiCreateAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import { assertExists } from '@vizcom/shared/js-utils';
import { WorkbenchEventName } from '@vizcom/shared/data-shape';

export const deletedDrawingHistory = new Map<string, Drawing>();

export const MultiDeleteAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'deleteElements';
    elementIds: string[];
  },
  {
    drawingIds: string[];
  }
> = {
  type: 'deleteElements',
  optimisticUpdater: ({ payload }, elements) => {
    payload.elementIds.forEach((id) => {
      const elementIndex = elements.findIndex((el) => el.id === id);
      if (elementIndex !== -1) {
        elements.splice(elementIndex, 1);
      }
    });
  },
  remoteUpdater: async ({ payload, meta }) => {
    assertExists(meta.custom);
    const drawingIds = meta.custom.drawingIds;

    const workbenchIds: Set<string> = new Set();

    if (drawingIds.length) {
      // fetch and store drawing data for undo/redo history
      const { data } = await urqlClient.query(drawingsByIds, {
        ids: drawingIds,
      });
      if (data?.drawings?.nodes) {
        data.drawings.nodes.forEach((drawing) => {
          workbenchIds.add(drawing.workbenchId);
          if (drawing) {
            deletedDrawingHistory.set(drawing.id, drawing);
          }
        });
      }
    }

    // Perform the delete operation for all elementIds
    const res = await urqlClient.mutation(DeleteWorkbenchElementsMutation, {
      deleteWorkbenchElementsInput: {
        ids: payload.elementIds,
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while deleting elements, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Delete Elements');

    publishTrackingEvent({
      type: WorkbenchEventName.DELETE_ELEMENTS,
      data: {
        workbenchIds: Array.from(workbenchIds),
        elementIds: payload.elementIds,
      },
    });
  },

  metaConstructor: (payload, state) => {
    return {
      custom: {
        drawingIds: state
          .filter(
            (el) =>
              el.__typename === 'Drawing' && payload.elementIds.includes(el.id)
          )
          .map((el) => el.id),
      },
    };
  },
  undoConstructor: ({ payload }, elements) => {
    const newElements = payload.elementIds.map((id) => {
      const el = elementById(elements, id);
      assertExists(el, `Element with id ${id} not found`);
      return el;
    });
    const undoPayloads: SyncedActionPayloadFromType<typeof MultiCreateAction> =
      {
        type: 'createElements',
        newElements,
      };

    return undoPayloads;
  },
};
