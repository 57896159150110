import styled from 'styled-components';

export const GoogleButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px #98989b solid;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
  margin: 20px 0 12px 0;
  padding: 0;
  text-decoration: none;

  span {
    margin-left: 8px;
  }
`;
