import { Folder } from '@vizcom/shared/data-access/graphql';
import { MutableRefObject, useRef, useState } from 'react';
import { paths } from '@vizcom/shared-utils-paths';
import { Content, Wrapper } from '../Files/File/File.styled';
import styled from 'styled-components';
import {
  ContextMenu,
  ContextMenuItem,
  MenuHorizontalIcon,
  Text,
  FolderIcon as FolderIconBase,
  ContextMenuDivider,
} from '@vizcom/shared-ui-components';
import { RenameModal } from '../Files/File/RenameModal';
import { SelectableItem, useSelectedItems } from './SelectedItemsProvider';
import { Link } from 'react-router-dom';

const FolderIcon = styled(FolderIconBase)`
  width: 32px;
  height: 32px;

  background: #1c1c1d;
  padding: 6px;
  border-radius: 8px;
`;

const FolderContent = styled(Content)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 24px;
`;

const FolderTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-left: 10px;
  gap: 5px;
`;

const VerticalMenuIcon = styled(MenuHorizontalIcon)`
  transform: rotate(90deg);
  opacity: 0.5;

  &:hover {
    background: transparent !important;
    background-color: transparent !important;
  }
`;

const FolderName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

export const FolderItem = (props: {
  folder: Folder['childFolders']['nodes'][0];
  index: number;
}) => {
  const { folder } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const {
    selectedItems,
    setSelectedItems,
    setMultipleDeleteModalOpen,
    setMultipleMoveModalOpen,
  } = useSelectedItems();

  const handleOpenInNewTab = () => {
    window.open(paths.files.folder(folder.id), '_blank');
  };

  const isSelected = !!selectedItems.find(({ id }) => id === folder.id);
  const hasSelectedItems = selectedItems.length > 1;
  return (
    <>
      <SelectableItem
        type="folder"
        id={folder.id}
        index={props.index}
        ref={containerRef}
      >
        <Wrapper $selected={isSelected}>
          <FolderContent>
            <FolderIcon />
            <FolderTextInfo>
              <FolderName type="sh1" color="default">
                {folder.name}
              </FolderName>
              <Text type="b1" color="secondary">
                {`${folder.workbenches.totalCount || 0} ${
                  folder.workbenches.totalCount === 1 ? 'file' : 'files'
                }`}
              </Text>
            </FolderTextInfo>
          </FolderContent>
          {(!hasSelectedItems || isSelected) && (
            <ContextMenu
              buttonProps={{
                variant: 'transparentNoHover',
                size: 'icon',
                style: {
                  position: 'absolute',
                  top: 24,
                  right: 10,
                  zIndex: 3,
                  display: hasSelectedItems && 'none',
                },
              }}
              items={
                <>
                  {!hasSelectedItems && (
                    <>
                      <ContextMenuItem
                        as={Link}
                        to={paths.files.folder(folder.id)}
                      >
                        Open
                      </ContextMenuItem>
                      <ContextMenuItem onClick={handleOpenInNewTab}>
                        Open in new tab
                      </ContextMenuItem>
                      <ContextMenuDivider />
                    </>
                  )}
                  {folder.isEditable && (
                    <>
                      <ContextMenuItem
                        onClick={() => {
                          if (!hasSelectedItems) {
                            setSelectedItems([
                              {
                                type: 'folder',
                                id: folder.id,
                                index: props.index,
                              },
                            ]);
                          }
                          setMultipleMoveModalOpen(true);
                        }}
                      >
                        Move
                      </ContextMenuItem>
                      {!hasSelectedItems && (
                        <ContextMenuItem
                          onClick={() => setRenameModalOpen(true)}
                        >
                          Rename
                        </ContextMenuItem>
                      )}
                      <ContextMenuDivider />
                      <ContextMenuItem
                        onClick={() => {
                          if (!hasSelectedItems) {
                            setSelectedItems([
                              {
                                type: 'folder',
                                id: folder.id,
                                index: props.index,
                              },
                            ]);
                          }
                          setMultipleDeleteModalOpen(true);
                        }}
                      >
                        Delete
                      </ContextMenuItem>
                    </>
                  )}
                  {hasSelectedItems && !folder.isEditable && (
                    <ContextMenuItem>No editorial permissions</ContextMenuItem>
                  )}
                </>
              }
              parentRef={containerRef as MutableRefObject<HTMLElement>}
            >
              <VerticalMenuIcon />
            </ContextMenu>
          )}
        </Wrapper>
      </SelectableItem>
      <RenameModal
        folderId={folder.id}
        isOpen={renameModalOpen}
        setIsOpen={setRenameModalOpen}
        previousName={folder.name}
      />
    </>
  );
};
