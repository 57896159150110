import { memo, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ThreeEvent, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useDrag } from '@use-gesture/react';

import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { WorkbenchElementDrawing } from './elements/drawing/WorkbenchElementDrawing';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { WorkbenchElementImg2Img } from './elements/img2img/WorkbenchElementImg2Img';
import {
  elementIsDrawing,
  elementIsPalette,
  elementIsSection,
  elementIsText,
  floorPlane,
  isDraggingContext,
  useIsWorkbenchViewer,
} from '../lib/utils';
import { WorkbenchElementPlaceholder } from './elements/placeholder/WorkbenchElementPlaceholder';
import { WorkbenchElementText } from './elements/text/WorkbenchElementText';
import { MAX_Z_POSITION, getElementSize } from './helpers';
import {
  WorkbenchElementContainerUserData,
  WorkbenchObjectObject3D,
  filterChildByWorkbenchElementUserData,
  filterChildByWorkbenchObjectUserData,
  getBoundingBoxFromWorkbenchObjects,
} from './objectsUserdata';
import { FocusIndicator } from './utils/FocusIndicator';
import { MultiplayerPresence } from '../lib/useWorkbenchMultiplayer';
import { WorkbenchElementCompositeScene } from './elements/compositeScene/WorkbenchCompositeScene';
import { WorkbenchElementPalette } from './elements/palette/WorkbenchElementPalette';
import {
  getCameraBoundingBox,
  handleScrollCanvas,
  useMapControls,
} from './utils/mapControls/utils';
import {
  SourceDrawingData,
  WorkbenchElementMix,
} from './elements/mix/WorkbenchElementMix';
import { WorkbenchElementSection } from './elements/section/WorkbenchElementSection';
import { WorkbenchContextMenuByElementType } from './workbenchContextMenu/WorkbenchContextMenuByElementType';
import {
  addToast,
  ContextMenu,
  eventTargetIsInput,
} from '@vizcom/shared-ui-components';
import { HtmlOverlay } from './utils/HtmlOverlay';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { findSnapGuides, SnapGuide, SnapGuideLines } from './utils/Snapping';
import { useWorkbenchToolState } from './toolbar/WorkbenchToolContext';
import { limitSourceImages } from './elements/palette/helpers';
import { WorkbenchElementConnections } from './WorkbenchElementConnections';

export const WorkbenchElement = memo(
  (props: {
    element: ClientSideWorkbenchElementData;
    workbenchId: string;
    thumbnailDrawingId?: string | null;
    isEditing: boolean;
    multiplayerPresences: MultiplayerPresence[];
    elementIsActive: boolean;
    isResizing: boolean;
    isDragging: boolean;
    sourceDrawingsThumbnails: Record<string, SourceDrawingData> | undefined;
    focused: boolean;
    singleFocused: boolean;
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
    setEditingElementId: (id: string | null) => void;
    setIsDragging: (value: boolean) => void;
  }) => {
    const [snapGuides, setSnapGuides] = useState<SnapGuide[]>([]);
    const scene = useThree((s) => s.scene);
    const camera = useThree((s) => s.camera);
    const isViewer = useIsWorkbenchViewer();
    const { tool } = useWorkbenchToolState();
    const {
      element,
      multiplayerPresences,
      elementIsActive,
      isResizing,
      isDragging,
      setIsDragging,
      isEditing,
      focused,
      singleFocused,
    } = props;
    const isDraggingRef = useContext(isDraggingContext);
    const controls = useMapControls();
    const [contextMenuOpen, setContextMenuOpen] = useState<
      | boolean
      | {
          x: number;
          y: number;
        }
    >(false);
    const [selectedSourceId, setSelectedSourceId] = useState<string | null>(
      null
    );

    const { width, height } = getElementSize(element);

    const multiplayerSelected = multiplayerPresences.find((presence) =>
      presence.focusedElementId?.includes(element.id)
    );
    const showSingleFocusedControls =
      !elementIsActive &&
      !isViewer &&
      !isEditing &&
      !elementIsSection(element) &&
      props.singleFocused;

    // This is used for when conencting elements together, to know which element is hovered and should be connected
    const userData: WorkbenchElementContainerUserData = {
      workbenchObjectType: 'container',
      elementId: element.id,
      elementTypename: element.__typename,
      elementWidth: width,
      elementHeight: height,
      elementX: element.x,
      elementY: element.y,
      elementZIndex: element.zIndex,
      multiFocused: props.focused,
      singleFocused: props.singleFocused,
      cursor: 'auto',
      paletteSourceImageCount: elementIsPalette(element)
        ? element.sourceImages.nodes.length
        : undefined,
      paletteStatus: elementIsPalette(element) ? element.status : undefined,
    };

    const setFocusedElementsId =
      useWorkbenchElementSelectionState.getState().setFocusedElementsId;

    const handleLastDrag = (
      allObjectsToMove: WorkbenchObjectObject3D[],
      onlyDrawings: boolean,
      paletteDropTarget?: WorkbenchObjectObject3D
    ) => {
      setIsDragging(false);
      setSnapGuides([]);

      const workbenchElementsToMove = allObjectsToMove.filter(
        ({ userData }) =>
          userData.workbenchObjectType !== 'multi-focused-element-container'
      );
      if (onlyDrawings && paletteDropTarget) {
        if (paletteDropTarget.userData.paletteStatus === 'idle') {
          const sourceDrawingIds = limitSourceImages(
            workbenchElementsToMove,
            paletteDropTarget.userData.paletteSourceImageCount || 0
          ).map((element) => element.userData.elementId);

          if (sourceDrawingIds.length) {
            props.handleAction({
              type: 'insertDrawingsToPalette',
              id: paletteDropTarget.userData.elementId,
              sourceDrawingIds,
            });
          }
        } else {
          addToast('Trained palette locked. Duplicate to edit', {
            ctaText: 'Duplicate',
            ctaAction: () =>
              props.handleAction({
                type: 'duplicateElements',
                elementIds: [paletteDropTarget.userData.elementId],
                newElementIds: [uuidv4()],
              }),
          });
        }
        // reset position of all elements
        allObjectsToMove.forEach((child) => {
          child.position.set(
            child.userData.elementX,
            child.userData.elementY,
            child.userData.elementZIndex
          );
        });
        return;
      }

      props.handleAction({
        type: 'multiPosition',
        newElementData: workbenchElementsToMove.map((element) => ({
          id: element.userData.elementId,
          x: element.position.x,
          y: element.position.y,
        })),
      });
      // we intentionally don't revert the position back on the group element
      // to let React do it on the next render loop when the client state has been updated correctly
      // this is required to prevent a one-frame flash of the old position
    };

    const handleDragging = (
      allObjectsToMove: WorkbenchObjectObject3D[],
      onlyDrawings: boolean,
      paletteDropTarget: WorkbenchObjectObject3D,
      delta: [number, number],
      snapElements: boolean
    ) => {
      const cameraVisibleSpace = getCameraBoundingBox(camera);

      const visibleWorkbenchElements = filterChildByWorkbenchObjectUserData(
        scene,
        (userData) =>
          userData.workbenchObjectType === 'container' &&
          (!cameraVisibleSpace ||
            (cameraVisibleSpace.left <
              userData.elementX + userData.elementWidth / 2 &&
              cameraVisibleSpace.right >
                userData.elementX - userData.elementWidth / 2 &&
              cameraVisibleSpace.top >
                userData.elementY - userData.elementHeight / 2 &&
              cameraVisibleSpace.bottom <
                userData.elementY + userData.elementHeight / 2))
      );

      const workbenchElementsToMove = allObjectsToMove.filter(
        ({ userData }) =>
          userData.workbenchObjectType !== 'multi-focused-element-container'
      );
      const boundingBox = getBoundingBoxFromWorkbenchObjects(
        workbenchElementsToMove
      );
      boundingBox.left += delta[0];
      boundingBox.right += delta[0];
      boundingBox.top += delta[1];
      boundingBox.bottom += delta[1];
      // handle snapping
      let snapOffsetX = 0;
      let snapOffsetY = 0;
      if (snapElements) {
        const { guides, bestSnapX, bestSnapY } = findSnapGuides(
          visibleWorkbenchElements.filter(
            (el) => !workbenchElementsToMove.includes(el)
          ),
          boundingBox
        );
        setSnapGuides(guides);

        const boundingBoxCenterX = (boundingBox.left + boundingBox.right) / 2;
        const boundingBoxCenterY = (boundingBox.top + boundingBox.bottom) / 2;

        if (bestSnapX !== null) {
          if (
            Math.abs(bestSnapX - boundingBoxCenterX) <
              Math.abs(bestSnapX - boundingBox.left) &&
            Math.abs(bestSnapX - boundingBoxCenterX) <
              Math.abs(bestSnapX - boundingBox.right)
          ) {
            snapOffsetX = bestSnapX - boundingBoxCenterX;
          } else if (
            Math.abs(bestSnapX - boundingBox.left) <
            Math.abs(bestSnapX - boundingBox.right)
          ) {
            snapOffsetX = bestSnapX - boundingBox.left;
          } else {
            snapOffsetX = bestSnapX - boundingBox.right;
          }
        }

        if (bestSnapY !== null) {
          if (
            Math.abs(bestSnapY - boundingBoxCenterY) <
              Math.abs(bestSnapY - boundingBox.top) &&
            Math.abs(bestSnapY - boundingBoxCenterY) <
              Math.abs(bestSnapY - boundingBox.bottom)
          ) {
            snapOffsetY = bestSnapY - boundingBoxCenterY;
          } else if (
            Math.abs(bestSnapY - boundingBox.top) <
            Math.abs(bestSnapY - boundingBox.bottom)
          ) {
            snapOffsetY = bestSnapY - boundingBox.top;
          } else {
            snapOffsetY = bestSnapY - boundingBox.bottom;
          }
        }
      }

      // Apply movement to all elements
      allObjectsToMove.forEach((child) => {
        const newX = child.userData.elementX + delta[0] + snapOffsetX;
        const newY = child.userData.elementY + delta[1] + snapOffsetY;
        child.position.set(newX, newY, child.userData.elementZIndex);
      });

      // Handle palette hover effect
      if (onlyDrawings && paletteDropTarget) {
        userData.cursor =
          paletteDropTarget.userData.paletteStatus === 'idle'
            ? 'copy'
            : 'not-allowed';
      }
    };

    const bind = useDrag<ThreeEvent<PointerEvent>>(
      ({ event, intentional, down, last, memo, tap }) => {
        if (tool !== 'select') {
          return;
        }
        if (elementIsSection(element) || isEditing) {
          return;
        }
        event.stopPropagation();

        if (tap) {
          if (event.shiftKey) {
            if (focused) {
              useWorkbenchElementSelectionState
                .getState()
                .removeElementFromFocus(element.id);
              return;
            } else {
              useWorkbenchElementSelectionState
                .getState()
                .addToFocus(element.id);
              return;
            }
          } else {
            if (!focused) {
              setFocusedElementsId(element.id);
              props.setEditingElementId('');
              return;
            }
          }
        }

        if (elementIsActive || isViewer || isEditing) {
          return;
        }

        if (event.ctrlKey) {
          setSnapGuides([]);
        }

        const planeIntersectPoint = new THREE.Vector3();
        event.ray.intersectPlane(floorPlane, planeIntersectPoint);
        // on first click, compute the position of the client from the center of the element,
        // we then use this delta for all position in the future of the element
        const initialPosition = memo?.initialPosition || [
          planeIntersectPoint.x,
          planeIntersectPoint.y,
        ];

        const xDelta = planeIntersectPoint.x - initialPosition[0];
        const yDelta = planeIntersectPoint.y - initialPosition[1];
        const direction =
          Math.abs(xDelta) > Math.abs(yDelta) ? 'horizontal' : 'vertical';
        const lockHorizontal = direction === 'horizontal' && event.shiftKey;
        const lockVertical = direction === 'vertical' && event.shiftKey;
        const delta: [number, number] = [
          lockVertical ? 0 : xDelta,
          lockHorizontal ? 0 : yDelta,
        ];

        if (intentional) {
          // prevent elements actions firing when dragging
          isDraggingRef.current = down;
          setIsDragging(true);
          userData.cursor = 'grabbing';

          if (!focused) {
            setFocusedElementsId(element.id);
            return;
          }

          const allObjectsToMove = filterChildByWorkbenchObjectUserData(
            scene,
            (userData) =>
              userData.workbenchObjectType ===
                'multi-focused-element-container' ||
              userData.elementId === element.id ||
              userData.multiFocused
          );

          const workbenchElementsToMove = allObjectsToMove.filter(
            ({ userData }) =>
              userData.workbenchObjectType !== 'multi-focused-element-container'
          );

          const onlyDrawings =
            workbenchElementsToMove.every(
              (element) => element.userData.elementTypename === 'Drawing'
            ) && workbenchElementsToMove.length > 0;

          const mousePosition = new THREE.Vector2(event.point.x, event.point.y);
          const [paletteDropTarget] = filterChildByWorkbenchElementUserData(
            scene,
            (userData) =>
              userData.elementTypename === 'WorkbenchElementPalette' &&
              mousePosition.x >=
                userData.elementX - userData.elementWidth / 2 &&
              mousePosition.x <=
                userData.elementX + userData.elementWidth / 2 &&
              mousePosition.y >=
                userData.elementY - userData.elementHeight / 2 &&
              mousePosition.y <= userData.elementY + userData.elementHeight / 2
          );

          if (last) {
            handleLastDrag(allObjectsToMove, onlyDrawings, paletteDropTarget);
          } else {
            handleDragging(
              allObjectsToMove,
              onlyDrawings,
              paletteDropTarget,
              delta,
              !event.ctrlKey
            );
            // move camera if dragging element to edge of screen
            handleScrollCanvas(event, camera, controls);
          }
        }
        return {
          initialPosition,
        };
      },
      {
        threshold: 5,
        triggerAllEvents: true,
        pointer: {
          keys: false,
        },
      }
    );

    return (
      <>
        <SnapGuideLines snapGuides={snapGuides} />
        <WorkbenchElementConnections
          element={element}
          focused={focused}
          selectedSourceId={selectedSourceId}
          handleAction={props.handleAction}
        />

        <group
          position={
            [
              element.x,
              element.y,
              element.zIndex -
                (element.__typename !== 'WorkbenchElementSection'
                  ? 0
                  : MAX_Z_POSITION / 2),
            ]
            // The group elements z position should be between (element.zIndex) and (element.zIndex  + 1),
            // every element inside the group should have a z position between 0 and 1
            // this is done to make sure the zIndex works correctly between different elements
          }
          userData={userData}
          onContextMenu={(e) => {
            if (contextMenuOpen || e.button !== 2 || isViewer) {
              return;
            }

            const { clientX, clientY } = e;

            if (eventTargetIsInput(e.nativeEvent)) {
              return;
            }

            e.stopPropagation();
            e.nativeEvent.preventDefault();

            setFocusedElementsId(element.id);

            setContextMenuOpen({
              x: clientX,
              y: clientY,
            });
          }}
        >
          <FocusIndicator
            multiplayerSelected={multiplayerSelected}
            active={focused && !isDragging && !isResizing}
            height={height}
            width={width}
          />
          <group
            {...(bind() as any)}
            onDoubleClick={() => {
              if (isViewer) return;
              if (elementIsText(element) || elementIsDrawing(element)) {
                props.setEditingElementId(element.id);
              }
            }}
          >
            {element.__typename === 'Drawing' && (
              <WorkbenchElementDrawing
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                setEditingElementId={props.setEditingElementId}
                workbenchId={props.workbenchId}
                isDragging={isDragging}
                isThumbnail={element.id === props.thumbnailDrawingId}
                showSingleFocusedControls={showSingleFocusedControls}
              />
            )}
            {element.__typename === 'WorkbenchElementImg2Img' && (
              <WorkbenchElementImg2Img
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                focused={focused}
                workbenchId={props.workbenchId}
                isDragging={isDragging}
                isResizing={isResizing}
                showSingleFocusedControls={showSingleFocusedControls}
              />
            )}

            {element.__typename === 'WorkbenchElementText' && (
              <WorkbenchElementText
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                workbenchId={props.workbenchId}
                isEditing={isEditing}
                setEditingElementId={props.setEditingElementId}
                isDragging={isDragging}
              />
            )}
            {element.__typename === 'WorkbenchElementPlaceholder' && (
              <WorkbenchElementPlaceholder
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                workbenchId={props.workbenchId}
                setEditingElementId={props.setEditingElementId}
                isDragging={isDragging}
                isResizing={isResizing}
              />
            )}
            {element.__typename === 'CompositeScene' && (
              <WorkbenchElementCompositeScene
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                workbenchId={props.workbenchId}
                setEditingElementId={props.setEditingElementId}
                isDragging={isDragging}
                isResizing={isResizing}
              />
            )}
            {element.__typename === 'WorkbenchElementPalette' && (
              <WorkbenchElementPalette
                element={element}
                singleFocused={singleFocused}
                isDragging={isDragging}
                isResizing={isResizing}
                handleAction={props.handleAction}
              />
            )}
            {element.__typename === 'WorkbenchElementMix' && (
              <WorkbenchElementMix
                element={element}
                singleFocused={singleFocused}
                isDragging={isDragging}
                isResizing={isResizing}
                showSingleFocusedControls={showSingleFocusedControls}
                sourceDrawingsThumbnails={props.sourceDrawingsThumbnails}
                selectedSourceId={selectedSourceId}
                setSelectedSourceId={setSelectedSourceId}
                handleAction={props.handleAction}
              />
            )}
            {element.__typename === 'WorkbenchElementSection' && (
              <WorkbenchElementSection
                element={element}
                singleFocused={singleFocused}
                focused={focused}
                isDragging={isDragging}
                isResizing={isResizing}
                elementIsActive={elementIsActive}
                handleAction={props.handleAction}
                setEditingElementId={props.setEditingElementId}
                setIsDragging={setIsDragging}
              />
            )}

            {singleFocused && (
              <HtmlOverlay>
                <ContextMenu
                  withButton={false}
                  autoOpen={contextMenuOpen}
                  onOpenStateChange={(state) => {
                    if (state === false) {
                      setContextMenuOpen(false);
                    }
                  }}
                  items={
                    <WorkbenchContextMenuByElementType
                      element={element}
                      workbenchId={props.workbenchId}
                      handleAction={props.handleAction}
                    />
                  }
                />
              </HtmlOverlay>
            )}
          </group>
        </group>
      </>
    );
  }
);
