// interface SignUpEventProps {
//   Id: string;
//   email: string;
//   planType: string;
//   registrationType: string;
// }

// interface LogInEventProps {
//   planType: string;
// }

// interface CompleteOnboardingEventProps {
//   name: string;
//   occupation: string;
//   description: string;
// }

// interface CreateProjectEventProps {
//   amountOfProjects: string;
// }

// interface ImageRenderEventProps {
//   renderType: string;
// }

// interface ImageExportEventProps {
//   exportType: string;
// }

// interface UpgradeEventProps {
//   planType: string;
// }

// export interface EventMap {
//   signUp: SignUpEventProps;
//   logIn: LogInEventProps;
//   completeOnboarding: CompleteOnboardingEventProps;
//   createProject: CreateProjectEventProps;
//   imageRender: ImageRenderEventProps;
//   imageExport: ImageExportEventProps;
//   upgrade: UpgradeEventProps;
// }

// interface IdentifyUserProps {
//   userId: string;
//   email: string;
//   planType: string;
//   name?: string;
//   occupation?: string;
//   description?: string;
// }

let _analytics;
const getAnalytics = async () => {
  if (_analytics) {
    return _analytics;
  }

  let Analytics;
  let mixpanelPlugin;

  try {
    Analytics = (await import('analytics')).default;
    mixpanelPlugin = (await import('@analytics/mixpanel')).default;
  } catch (e) {
    throw new Error(`Unable to import modules`);
  }

  _analytics = Analytics({
    app: 'vizcom',
    plugins: [
      ...(import.meta.env.NX_MIXPANEL_TOKEN
        ? [
            mixpanelPlugin({
              token: import.meta.env.NX_MIXPANEL_TOKEN,
            }),
          ]
        : []),
    ],
  });

  return _analytics;
};

// export function trackEvent<K extends keyof EventMap>(
//   eventName: K,
//   properties: EventMap[K]
// ): void {
//   analytics.track(eventName, properties);
// }

export async function trackEvent(eventName, properties) {
  const analytics = await getAnalytics();
  analytics.track(eventName, properties);
}

// export function identify(userId: string, traits?: IdentifyUserProps): void {
//   analytics.identify(userId, traits);
// }

export async function identifyUser(userId, traits) {
  const analytics = await getAnalytics();
  analytics.identify(userId, traits);
}

export async function mixPanelAlias(userId) {
  const analytics = await getAnalytics();
  analytics.alias(userId);
}
