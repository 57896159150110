import styled from 'styled-components';
import {
  Button,
  CarretDownIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  styledScrollbar,
  useStringHashNumber,
} from '../../../../../../shared/ui/components/src';
import {
  AnonymousAvatarImage,
  MultiplayerPresenceIndicatorName,
} from './MultiplayerPresenceIndicator';
import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import {
  getMultiplayerDisplayConfig,
  getMultiplayerUserId,
} from './multiplayerDisplayConfig';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';

export const LargeListMultiplayerPresencesButton = (props: {
  selfPresence: MultiplayerPresence | null;
  multiplayerPresences: MultiplayerPresence[];
  setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedPresence: MultiplayerPresence | undefined;
}) => {
  const { data: currentUser } = useCurrentUser();

  const userId = getMultiplayerUserId(props.selfPresence?.id || '');
  const hash = useStringHashNumber(userId);
  const selfDisplayConfig = getMultiplayerDisplayConfig(hash, currentUser);

  return (
    <RichTooltip trigger="hover" placement="bottom" padding={11}>
      <RichTooltipTrigger>
        <MultiPresenceButton variant="secondary">
          <MultiplayerPresenceIndicatorName
            style={{
              backgroundColor: selfDisplayConfig.color,
            }}
            $selected={false}
          >
            {!props.selfPresence?.userId && (
              <AnonymousAvatarImage src={selfDisplayConfig.image} />
            )}
            {selfDisplayConfig.short}
          </MultiplayerPresenceIndicatorName>
          {props.multiplayerPresences.length} members
          <CarretDownIcon />
        </MultiPresenceButton>
      </RichTooltipTrigger>
      <RichTooltipContent
        style={{ padding: '12px 0 0 0' }}
        $backgroundSurface="e0"
      >
        <Text type="b1" style={{ padding: '0 18px' }}>
          Members
        </Text>
        <Divider />
        <PresenceListContainer>
          <MultiplayerPresenceListIndicatorButton style={{ cursor: 'auto' }}>
            <MultiplayerPresenceIndicatorName
              style={{
                backgroundColor: selfDisplayConfig.color,
              }}
              $selected={false}
            >
              {!props.selfPresence?.userId && (
                <AnonymousAvatarImage src={selfDisplayConfig.image} />
              )}
              {selfDisplayConfig.short}
            </MultiplayerPresenceIndicatorName>
            <Text
              style={{
                flex: 1,
                whiteSpace: 'nowrap',
                width: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selfDisplayConfig.name}
            </Text>
            <Text color="secondary">You</Text>
          </MultiplayerPresenceListIndicatorButton>
          {props.multiplayerPresences.map((presence) => (
            <MultiplayerPresenceListIndicator
              key={presence.id}
              presence={presence}
              onSelect={() => props.setSelectedPresenceId(presence.id)}
              selected={presence.id === props.selectedPresence?.id}
            />
          ))}
        </PresenceListContainer>
      </RichTooltipContent>
    </RichTooltip>
  );
};

const MultiPresenceButton = styled(Button)`
  display: flex;
  gap: 3px;
  border-radius: 1000px;
  padding: 4px;
  align-items: center;
  background-color: ${({ theme }) => theme.surface.e1};
  color: ${({ theme }) => theme.text.default};
  font-size: 12px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.surface.e2};
  margin-top: 12px;
`;

const PresenceListContainer = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  max-height: 300px;
  overflow-y: scroll;
  padding: 12px 18px;

  ${styledScrollbar}
`;

const MultiplayerPresenceListIndicator = (props: {
  presence: MultiplayerPresence;
  onSelect: () => void;
  selected: boolean;
}) => {
  const { presence } = props;

  const userId = getMultiplayerUserId(presence.id);
  const hash = useStringHashNumber(userId);
  const displayConfig = getMultiplayerDisplayConfig(hash, presence.user);

  return (
    <MultiplayerPresenceListIndicatorButton onClick={props.onSelect}>
      <MultiplayerPresenceIndicatorName
        style={{
          backgroundColor: displayConfig.color,
        }}
        $selected={props.selected}
      >
        {!presence.user && <AnonymousAvatarImage src={displayConfig.image} />}
        {displayConfig.short}
      </MultiplayerPresenceIndicatorName>
      <Text
        style={{
          flex: 1,
          whiteSpace: 'nowrap',
          width: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {displayConfig.name}
      </Text>
      <Text color="primary">{props.selected ? 'Following' : 'Follow'}</Text>
    </MultiplayerPresenceListIndicatorButton>
  );
};

const MultiplayerPresenceListIndicatorButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
