import {
  InfiniteScrollPage,
  VizcomLogoAnimated,
} from '@vizcom/shared-ui-components';
import {
  PromptData,
  PromptOutput,
  usePaginatedDrawingPrompts,
} from '@vizcom/shared/data-access/graphql';
import { FullWidthGridItem } from 'libs/web/features/files/src/components/Files/Files.styled';
import styled, { css } from 'styled-components';
import { PromptHistoryItem } from './WorkbenchStudioHistory';
import { useEffect, useMemo } from 'react';

const PROMPTS_PER_PAGE = 20;

export const StudioHistoryPage = ({
  drawingId,
  cursor,
  selectedPromptHistoryItem,
  setSelectedPromptHistoryItem,
  setNextPageCursor,
  updatePrompts,
}: {
  drawingId: string;
  cursor: string | null;
  selectedPromptHistoryItem?: PromptHistoryItem;
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
  setNextPageCursor: (cursor: string) => void;
  updatePrompts: (cursor: string, prompts: PromptData[]) => void;
}) => {
  const { data, error, fetching, pageInfo } = usePaginatedDrawingPrompts(
    drawingId,
    cursor,
    PROMPTS_PER_PAGE
  );

  const history = useMemo(
    () => data?.flatMap((prompt) => prompt?.outputs.nodes) || [],
    [data]
  );

  useEffect(() => {
    if (data && !fetching) {
      updatePrompts(cursor || 'root', data);
    }
  }, [data, fetching]);

  if (fetching) {
    return (
      <div
        style={{
          gridColumn: '1 / -1',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <VizcomLogoAnimated />;
      </div>
    );
  }

  if (error) {
    return (
      <FullWidthGridItem>
        There was an error loading files, please retry.
      </FullWidthGridItem>
    );
  }

  return (
    <InfiniteScrollPage
      loadMore={() => setNextPageCursor(pageInfo?.endCursor)}
      hasNextPage={pageInfo?.hasNextPage}
    >
      {history.map((output) => (
        <HistoryItem
          key={output.id}
          $selected={output.id === selectedPromptHistoryItem?.output.id}
          onClick={() =>
            selectedPromptHistoryItem?.output.id === output.id
              ? setSelectedPromptHistoryItem(undefined)
              : setSelectedPromptHistoryItem({
                  output,
                  prompt: data!.find((p) => p.id === output.promptId)!,
                })
          }
        >
          {output.imagePath && (
            <img
              crossOrigin="anonymous"
              src={output.imagePath}
              draggable={false}
            />
          )}
        </HistoryItem>
      ))}
    </InfiniteScrollPage>
  );
};

const HistoryItem = styled.div<{ $selected?: boolean }>`
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  height: 30px;
  background: ${({ theme }) => theme.surface.e1};
  box-shadow: ${({ $selected, theme }) =>
    $selected ? `0 0 0px 2px ${theme.primary.default}` : 'none'};

  & img {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    object-fit: cover;
  }

  ${({ $selected }) =>
    $selected
      ? css`
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
            opacity: 0.4;
            background: ${({ theme }) => theme.white};
            border-radius: 2px;
          }
        `
      : ''}
`;
