import { useFeatureFlag } from './useFeatureFlags';

export const FeatureFlagged = (props: {
  flag: string;
  children: any;
  inverted?: boolean;
}) => {
  const { loading, flagEnabled } = useFeatureFlag(props.flag);

  if (loading) {
    return;
  }

  if (props.inverted && !flagEnabled) {
    return props.children;
  }
  if (!props.inverted && flagEnabled) {
    return props.children;
  }

  return null;
};
