import { Text } from '@react-three/drei';
import { ComponentProps } from 'react';
import { Texture } from 'three';

/**
 * @typedef {object} TroikaTextRenderInfo - Format of the result from `getTextRenderInfo`.
 * @property {TypesetParams} parameters - The normalized input arguments to the render call.
 * @property {Texture} sdfTexture - The SDF atlas texture.
 * @property {number} sdfGlyphSize - The size of each glyph's SDF; see `configureTextBuilder`.
 * @property {number} sdfExponent - The exponent used in encoding the SDF's values; see `configureTextBuilder`.
 * @property {Float32Array} glyphBounds - List of [minX, minY, maxX, maxY] quad bounds for each glyph.
 * @property {Float32Array} glyphAtlasIndices - List holding each glyph's index in the SDF atlas.
 * @property {Uint8Array} [glyphColors] - List holding each glyph's [r, g, b] color, if `colorRanges` was supplied.
 * @property {Float32Array} [caretPositions] - A list of caret positions for all characters in the string; each is
 *           four elements: the starting X, the ending X, the bottom Y, and the top Y for the caret.
 * @property {number} [caretHeight] - An appropriate height for all selection carets.
 * @property {number} ascender - The font's ascender metric.
 * @property {number} descender - The font's descender metric.
 * @property {number} capHeight - The font's cap height metric, based on the height of Latin capital letters.
 * @property {number} xHeight - The font's x height metric, based on the height of Latin lowercase letters.
 * @property {number} lineHeight - The final computed lineHeight measurement.
 * @property {number} topBaseline - The y position of the top line's baseline.
 * @property {Array<number>} blockBounds - The total [minX, minY, maxX, maxY] rect of the whole text block;
 *           this can include extra vertical space beyond the visible glyphs due to lineHeight, and is
 *           equivalent to the dimensions of a block-level text element in CSS.
 * @property {Array<number>} visibleBounds - The total [minX, minY, maxX, maxY] rect of the whole text block;
 *           unlike `blockBounds` this is tightly wrapped to the visible glyph paths.
 * @property {Array<object>} chunkedBounds - List of bounding rects for each consecutive set of N glyphs,
 *           in the format `{start:N, end:N, rect:[minX, minY, maxX, maxY]}`.
 * @property {object} timings - Timing info for various parts of the rendering logic including SDF
 *           generation, typesetting, etc.
 * @property {string} text - The original text string.
 */
export interface TroikaTextRenderInfo {
  parameters: ComponentProps<typeof Text>;
  sdfTexture: Texture;
  sdfGlyphSize: number;
  sdfExponent: number;
  glyphBounds: Float32Array;
  glyphAtlasIndices: Float32Array;
  glyphColors?: Uint8Array;
  caretPositions?: Float32Array;
  caretHeight?: number;
  ascender: number;
  descender: number;
  capHeight: number;
  xHeight: number;
  lineHeight: number;
  topBaseline: number;
  blockBounds: Array<number>;
  visibleBounds: Array<number>;
  chunkedBounds: Array<object>;
  timings: object;
  text: string;
}

/**
 * From: https://github.com/protectwise/troika/blob/main/packages/troika-three-text/src/selectionUtils.js
 * Given start and end character indexes, return a list of rectangles covering all the
 * characters within that selection.
 * @param {TroikaTextRenderInfo} textRenderInfo
 * @param {number} start - index of the first char in the selection
 * @param {number} end - index of the first char after the selection
 * @return {Array<{left, top, right, bottom}> | null}
 */
export function getSelectionRects(
  textRenderInfo: TroikaTextRenderInfo,
  start: number,
  end: number
): Array<{ left: number; top: number; right: number; bottom: number }> | null {
  const rects: Array<{
    left: number;
    top: number;
    right: number;
    bottom: number;
  }> = [];
  if (textRenderInfo) {
    const { caretPositions } = textRenderInfo;
    if (!caretPositions) {
      return null;
    }

    // Normalize
    if (end < start) {
      const s = start;
      start = end;
      end = s;
    }
    start = Math.max(start, 0);
    end = Math.min(end, caretPositions.length + 1);

    // Build list of rects, expanding the current rect for all characters in a run and starting
    // a new rect whenever reaching a new line or a new bidi direction
    let currentRect: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    } | null = null;
    for (let i = start; i < end; i++) {
      const x1 = caretPositions[i * 4];
      const x2 = caretPositions[i * 4 + 1];
      const left = Math.min(x1, x2);
      const right = Math.max(x1, x2);
      const bottom = caretPositions[i * 4 + 2];
      const top = caretPositions[i * 4 + 3];
      if (
        !currentRect ||
        bottom !== currentRect.bottom ||
        top !== currentRect.top ||
        left > currentRect.right ||
        right < currentRect.left
      ) {
        currentRect = {
          left: Infinity,
          right: -Infinity,
          bottom,
          top,
        };
        rects.push(currentRect);
      }
      currentRect.left = Math.min(left, currentRect.left);
      currentRect.right = Math.max(right, currentRect.right);
    }

    // Merge any overlapping rects, e.g. those formed by adjacent bidi runs
    rects.sort((a, b) => b.bottom - a.bottom || a.left - b.left);
    for (let i = rects.length - 1; i-- > 0; ) {
      const rectA = rects[i];
      const rectB = rects[i + 1];
      if (
        rectA.bottom === rectB.bottom &&
        rectA.top === rectB.top &&
        rectA.left <= rectB.right &&
        rectA.right >= rectB.left
      ) {
        rectB.left = Math.min(rectB.left, rectA.left);
        rectB.right = Math.max(rectB.right, rectA.right);
        rects.splice(i, 1);
      }
    }
  }
  return rects;
}
