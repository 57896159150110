import { WorkbenchStudioTool, isSelectionTool } from '../studio/studioState';
import { AllHotkeys, HotkeyCategoryItems } from './types';
import PanPreview from './resources/pan-preview.svg?react';
// import ResizeBrushPreview from './resources/resize-brush-preview.svg?react';
// import RotatePreview from './resources/rotate-preview.svg?react';
import Enter2DPreview from './resources/enter-2d-studio-preview.svg?react';
import ZoomPreview from './resources/zoom-in-preview.svg?react';
import { ControlCmdKey, KbdKey, KbdPlus, KbdText, KeysRow } from './components';

const selectionToolsCycle = [
  WorkbenchStudioTool.Lasso,
  WorkbenchStudioTool.BrushSelection,
  WorkbenchStudioTool.BezierSelection,
  WorkbenchStudioTool.AutoSelection,
];

export const ImageStudioShortcuts = {
  '2dStudio': {
    rotate: {
      id: 'rotate',
      triggerPreview: (
        <KeysRow>
          <KbdKey>r</KbdKey>
          <KbdPlus>+</KbdPlus>
          <KbdKey>mouse drag</KbdKey>
        </KeysRow>
      ),
      category: 'general',
      description: 'Rotate canvas',
      isTool: false,
    },
    brush: {
      id: 'brush',
      isTool: true,
      category: '2dStudio',
      trigger: {
        combination: 'b',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.Brush);
        },
      },
      triggerPreview: <KbdKey>b</KbdKey>,
      description: 'Brush tool',
    },

    eraserTool: {
      id: 'eraserTool',
      triggerPreview: <KbdKey>e</KbdKey>,
      category: '2dStudio',
      description: 'Eraser tool',
      isTool: true,

      trigger: {
        combination: 'e',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.Eraser);
        },
      },
    },
    moveTool: {
      id: 'moveTool',
      triggerPreview: <KbdKey>m</KbdKey>,
      category: '2dStudio',
      description: 'Move tool',
      isTool: true,
      trigger: {
        combination: 'm',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.Move);
        },
      },
    },
    transformTool: {
      id: 'transformTool',
      triggerPreview: <KbdKey>t</KbdKey>,
      category: '2dStudio',
      description: 'Skew tool',
      isTool: true,
      trigger: {
        combination: 't',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.Transform);
        },
      },
    },

    paintBucket: {
      id: 'paintBucket',
      triggerPreview: <KbdKey>v</KbdKey>,
      category: '2dStudio',
      description: 'Paint bucket tool',
      isTool: true,

      trigger: {
        combination: 'v',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.PaintBucket);
        },
      },
    },

    newLayer: {
      id: 'newLayer',
      triggerPreview: <KbdKey>n</KbdKey>,
      category: '2dStudio',
      description: 'New layer',
      isTool: true,
    },

    lineShapeTool: {
      id: 'lineShapeTool',
      triggerPreview: <KbdKey>l</KbdKey>,
      category: '2dStudio',
      description: 'Line shape tool',
      isTool: true,
      trigger: {
        combination: 'l',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.Shape);
          toolState.shapeSettings.setType('line');
        },
      },
    },

    eclipseShapeTool: {
      id: 'eclipseShapeTool',
      triggerPreview: <KbdKey>o</KbdKey>,
      category: '2dStudio',
      description: 'Ellipse shape tool',
      isTool: true,

      trigger: {
        combination: 'o',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.Shape);
          toolState.shapeSettings.setType('ellipse');
        },
      },
    },

    boxShapeTool: {
      id: 'boxShapeTool',
      triggerPreview: <KbdKey>u</KbdKey>,
      category: '2dStudio',
      description: 'Box shape tool',
      isTool: true,

      trigger: {
        combination: 'u',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState.setTool(WorkbenchStudioTool.Shape);
          toolState.shapeSettings.setType('rectangle');
        },
      },
    },

    selectionTools: {
      id: 'selection',
      triggerPreview: <KbdKey>g</KbdKey>,
      category: '2dStudio',
      description: 'Selection tools',
      isTool: true,
      trigger: {
        combination: 'g',
        modifiers: {},
        callback: ({ toolState }) => {
          const currentIndex = selectionToolsCycle.indexOf(
            toolState.selectionSettings.lastSelectionTool
          );
          const nextIndex = isSelectionTool(toolState.tool)
            ? (currentIndex + 1) % selectionToolsCycle.length
            : currentIndex;
          toolState.setTool(selectionToolsCycle[nextIndex]);
        },
      },
    },

    flipCanvas: {
      id: 'flipCanvas',
      triggerPreview: <KbdKey>f</KbdKey>,
      category: '2dStudio',
      description: 'Flip canvas',
      isTool: true,
    },
    uploadImage: {
      id: 'uploadImage',
      triggerPreview: <KbdKey>i</KbdKey>,
      category: '2dStudio',
      description: 'Upload an image',
      isTool: true,
    },

    decreaseToolSize: {
      id: 'decreaseToolSize',
      triggerPreview: <KbdKey>[</KbdKey>,
      category: '2dStudio',
      description: 'Decrease tool size',
      isTool: true,

      trigger: {
        combination: '[',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState
            .getToolSettings()
            .setToolSize(toolState.getToolSettings().toolSize - 1);
        },
      },
    },

    increaseToolSize: {
      id: 'increaseToolSize',
      triggerPreview: <KbdKey>]</KbdKey>,
      category: '2dStudio',
      description: 'Increase tool size',
      isTool: true,

      trigger: {
        combination: ']',
        modifiers: {},
        callback: ({ toolState }) => {
          toolState
            .getToolSettings()
            .setToolSize(toolState.getToolSettings().toolSize + 1);
        },
      },
    },
  },

  general: {
    pan: {
      id: 'pan',
      triggerPreview: null,
      category: 'general',
      description: 'Pan',
      isTool: true,
      hoverPreview: PanPreview,
    },

    zoom: {
      id: 'zoom',
      triggerPreview: (
        <KeysRow>
          <ControlCmdKey />
          <KbdPlus>+</KbdPlus>
          <KbdKey>+</KbdKey>
          <KbdText>/</KbdText>
          <KbdKey>-</KbdKey>
        </KeysRow>
      ),
      category: 'general',
      description: 'Zoom in/out',
      isTool: true,
      hoverPreview: ZoomPreview,
    },

    undo: {
      id: 'undo',
      // triggerPreview: ['ctrl', '+', 'z'],
      triggerPreview: (
        <KeysRow>
          <ControlCmdKey />
          <KbdPlus>+</KbdPlus>
          <KbdKey>z</KbdKey>
        </KeysRow>
      ),
      category: 'general',
      description: 'Undo',
      isTool: false,
    },

    redo: {
      id: 'redo',
      triggerPreview: (
        <KeysRow>
          <ControlCmdKey />
          <KbdPlus>+</KbdPlus>
          <KbdKey>shift</KbdKey>
          <KbdPlus>+</KbdPlus>
          <KbdKey>z</KbdKey>
        </KeysRow>
      ),
      category: 'general',
      description: 'Redo',
      isTool: false,
    },

    resetView: {
      id: 'resetView',
      triggerPreview: <KbdKey>esc</KbdKey>,
      category: 'general',
      description: 'Reset view',
      isTool: false,
    },

    delete: {
      id: 'delete',
      triggerPreview: <KbdKey>Delete</KbdKey>,
      category: 'general',
      description: 'Delete',
      isTool: false,
    },

    copy: {
      id: 'copy',
      triggerPreview: (
        <KeysRow>
          <ControlCmdKey />
          <KbdPlus>+</KbdPlus>
          <KbdKey>c</KbdKey>
        </KeysRow>
      ),
      category: 'general',
      description: 'Copy',
      isTool: false,
    },

    paste: {
      id: 'paste',
      triggerPreview: (
        <KeysRow>
          <ControlCmdKey />
          <KbdPlus>+</KbdPlus>
          <KbdKey>v</KbdKey>
        </KeysRow>
      ),
      category: 'general',
      description: 'Paste',
      isTool: false,
    },

    // reset view
    // delete
    // bring to front
    // bring to back
  },
  workbench: {
    enter2d: {
      id: 'enter2d',
      triggerPreview: <KbdKey>Double Click</KbdKey>,
      category: 'workbench',
      description: 'Enter 2D Studio',
      isTool: false,
      hoverPreview: Enter2DPreview,
    },
    textTool: {
      id: 'textTool',
      triggerPreview: <KbdKey>t</KbdKey>,
      category: 'workbench',
      description: 'Text tool',
      isTool: true,
    },
    import3dModel: {
      id: 'import3dModel',
      triggerPreview: <KbdKey>p</KbdKey>,
      category: '2dStudio',
      description: 'Import 3D model',
      isTool: false,
    },
  },
} satisfies AllHotkeys;

export type ImageStudioShortcuts = typeof ImageStudioShortcuts;

const validString = (str: string | null): str is string =>
  str?.length !== undefined && str.length > 0;

export const filterHotkeyCategory = (
  hotkeys: HotkeyCategoryItems,
  filter: string | null
): HotkeyCategoryItems => {
  if (!validString(filter)) return hotkeys;
  return Object.fromEntries(
    Object.entries(hotkeys).filter((hotkey) =>
      hotkey[1].description.toLowerCase().includes(filter.toLowerCase())
    )
  );
};

export const filterHotkeys = (
  hotkeys: ImageStudioShortcuts,
  filter: string | null
): AllHotkeys => {
  return {
    general: filterHotkeyCategory(hotkeys.general, filter),
    workbench: filterHotkeyCategory(hotkeys.workbench, filter),
    '2dStudio': filterHotkeyCategory(hotkeys['2dStudio'], filter),
  };
};
