import styled from 'styled-components';

interface StepIndicatorProps {
  count: number;
  currentStep: number;
}

export const StepIndicator = ({ count, currentStep }: StepIndicatorProps) => {
  return (
    <StepContainer $count={count}>
      {new Array(count).fill(0).map((_, i) => (
        <Step key={i} $active={i === currentStep} />
      ))}
    </StepContainer>
  );
};

const StepContainer = styled.div<{ $count: number }>`
  display: grid;
  grid-template-columns: repeat(${(p) => p.$count}, 1fr);
  width: 450px;
  max-width: 100%;
  gap: 8px;
`;

const Step = styled.div<{ $active: boolean }>`
  background-color: ${(p) =>
    p.$active ? p.theme.primary.default : p.theme.surface.e1};
  transition: 0.3s all ease;
  border-radius: 18px;
  height: 6px;
`;
