import {
  addToast,
  downloadFile,
  createImageElementWithSrc,
  urlToBlob,
  zipFiles,
  FileToZip,
  imageDataToBlob,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  meshesByIdsQuery,
  publishTrackingEvent,
  urqlClient,
  useUpscaleImage,
} from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import * as Sentry from '@sentry/react';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { StudioEventName } from '@vizcom/shared/data-shape';
import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import { runExportLayersVideo } from '../Toolbar/export/exportLayersVideo';
import { getLayeredPSD } from '../Toolbar/export/getLayeredPSD';
import toast from 'react-hot-toast';

const VIZCOM_SVG_LOGO_COLOR = '#011414';
const VIZCOM_SVG_LOGO = `<svg width="195" height="34" viewBox="0 0 195 34" fill="${VIZCOM_SVG_LOGO_COLOR}" xmlns="http://www.w3.org/2000/svg">...</svg>`;

export const useExportUtils = (
  drawing: Drawing2dStudio,
  isFreePlan: boolean
) => {
  const upscaleImage = useUpscaleImage();

  const handleExportToVideo = async (
    setExporting: (exporting: boolean) => void
  ) => {
    setExporting(true);

    // Show loading toast
    const loadingToastId = addToast('Exporting video...', {
      type: 'default',
      duration: Infinity,
    });

    try {
      if (typeof VideoEncoder === 'undefined') {
        throw new Error(
          'Your browser does not meet all the requirements to export the video. Please use a recent version of Chrome.'
        );
      }

      const videoName = drawing.workbench?.name || 'export-vizcom-layers';

      const base64Logo = window.btoa(VIZCOM_SVG_LOGO);
      const image = await createImageElementWithSrc(
        `data:image/svg+xml;base64,${base64Logo}`
      );
      const watermarkImage = await createImageBitmap(image);

      const blob = await runExportLayersVideo(
        drawing,
        isFreePlan,
        watermarkImage
      );

      downloadFile(blob, videoName, 'mp4');

      // Dismiss loading toast
      toast.dismiss(loadingToastId);

      // Show success toast
      addToast('Your video is ready. Please check your downloads.', {
        type: 'default',
      });

      trackEvent('Video Export', { type: 'exportLayersVideo' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: 'VIDEO',
        },
      });
    } catch (error) {
      // Dismiss loading toast
      toast.dismiss(loadingToastId);

      // Capture the error with Sentry
      Sentry.captureException(error, {
        extra: {
          drawingId: drawing.id,
          workbenchId: drawing.workbench?.id,
        },
      });

      // Format and show error toast
      const message = formatErrorMessage(
        error,
        'An error occurred while exporting the video'
      );

      addToast(message, { type: 'danger' });
    } finally {
      setExporting(false);
    }
  };

  const handleExportToPng = async (
    upscale: boolean,
    setExporting: (exporting: boolean) => void,
    triggerPaywallModal: () => void,
    layersCompositor: any
  ) => {
    if (upscale && isFreePlan) {
      triggerPaywallModal();
      return;
    }

    setExporting(true);

    try {
      let compositeImage: Blob | string = await imageDataToBlob(
        layersCompositor.getCompositedImage()
      );
      if (upscale) {
        compositeImage = await upscaleImage(drawing.id, compositeImage);
      }
      const imageName = drawing.workbench?.name || 'export-vizcom-layers';

      downloadFile(compositeImage, imageName, 'png');

      addToast('Your image is ready. Please check your downloads.', {
        type: 'default',
      });
      trackEvent('Image Export', { type: 'exportLayersImage' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: 'PNG',
        },
      });
    } catch (error) {
      const message = formatErrorMessage(
        error,
        'An error occurred while exporting the image'
      );

      addToast(message, { type: 'danger' });
    }

    setExporting(false);
  };

  const exportPSD = async (setExporting: (exporting: boolean) => void) => {
    setExporting(true);

    try {
      const layeredPSD = await getLayeredPSD(drawing);
      const psdName = drawing.workbench?.name || 'export-vizcom-layers';

      downloadFile(layeredPSD, psdName, 'psd');

      addToast('Your PSD is ready. Please check your downloads.', {
        type: 'default',
      });
      trackEvent('PSD Export', { type: 'exportLayersPSD' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: 'PSD',
        },
      });
    } catch (error) {
      const message = formatErrorMessage(
        error,
        'An error occurred while exporting the PSD'
      );

      addToast(message, { type: 'danger' });
    }

    setExporting(false);
  };

  const exportAllModels = async (
    setExporting: (exporting: boolean) => void,
    triggerPaywallModal: () => void
  ) => {
    if (isFreePlan) {
      triggerPaywallModal();
      return;
    }

    setExporting(true);

    try {
      const meshes = [] as {
        name: string;
        url: string;
        isObjectUrl: boolean;
      }[];
      const externalMeshesId = drawing.layers.nodes
        .map((layer) => layer.metadata3D?.mesh)
        .filter(filterExists);
      if (externalMeshesId.length) {
        const res = await urqlClient.query(meshesByIdsQuery, {
          ids: externalMeshesId,
        });
        if (res.error || !res.data?.meshes) {
          addToast('An error occurred while exporting the models', {
            type: 'danger',
          });
          setExporting(false);
          return;
        }
        meshes.push(
          ...res.data.meshes.nodes.map((mesh) => ({
            name: mesh.name,
            url: mesh.path,
            isObjectUrl: false,
          }))
        );
      }

      const internalMeshes = drawing.layers.nodes
        .map((layer) => {
          if (layer.meshPath) {
            const url =
              layer.meshPath instanceof Blob
                ? URL.createObjectURL(layer.meshPath)
                : layer.meshPath;

            return {
              name: layer.name,
              isObjectUrl: layer.meshPath instanceof Blob,
              url,
            };
          }
          return null;
        })
        .filter(filterExists);
      meshes.push(...internalMeshes);

      if (meshes.length === 0) {
        addToast('No models to export', { type: 'default' });
        return;
      }

      const files: FileToZip[] = await Promise.all(
        meshes.map(async (mesh, index) => ({
          name: `${mesh.name}-${index}.glb`,
          data: await urlToBlob(mesh.url),
        }))
      );

      const zipBlob = await zipFiles(files);
      const zipName = drawing.workbench?.name || 'export-vizcom-models';

      downloadFile(zipBlob, `${zipName}-models-export`, 'zip');
      addToast('Your models are ready. Please check your downloads.', {
        type: 'default',
      });

      internalMeshes.forEach((mesh) => {
        if (mesh.isObjectUrl) {
          URL.revokeObjectURL(mesh.url);
        }
      });

      trackEvent('3D Export', { type: 'exportLayers3D' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: '3D',
        },
      });
    } finally {
      setExporting(false);
    }
  };

  return {
    handleExportToVideo,
    handleExportToPng,
    exportPSD,
    exportAllModels,
  };
};
