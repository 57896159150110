import {
  Button,
  InvertIcon,
  CopyContentIcon,
  PlusIcon,
  MinusIcon,
  getShortcutKey,
  RichTooltipTrigger,
  RichTooltip,
  RichTooltipContent,
  getAltShortcutKey,
  bottomScreenTunnel,
} from '@vizcom/shared-ui-components';
import { Actions, ToolbarContainer } from '../style';
import { MaskOperation } from './utils';
import styled, { useTheme } from 'styled-components';
import { isSelectionTool, useWorkbenchStudioToolState } from '../studioState';
import {
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from './useSelectionApi';

export const SelectionToolbar = ({
  copySelectionToNewLayer,
}: {
  copySelectionToNewLayer: () => void;
}) => {
  const selectionApiStore = useSelectionApiStore();
  const hasMask = useSubscribeToSelectionApi((state) => state.hasMask);
  const { tool, operation, setOperation } = useWorkbenchStudioToolState(
    (s) => ({
      tool: s.tool,
      operation: s.selectionSettings.operation,
      setOperation: s.selectionSettings.setOperation,
    })
  );
  const theme = useTheme();

  return (
    <bottomScreenTunnel.In>
      <ToolbarContainer>
        <Actions>
          {isSelectionTool(tool) && (
            <>
              <RichTooltip padding={11}>
                <RichTooltipTrigger>
                  <Button
                    variant={
                      operation === MaskOperation.Add
                        ? 'primary'
                        : 'transparent'
                    }
                    size="iconSquared"
                    onClick={() => {
                      setOperation(
                        operation === MaskOperation.Add
                          ? MaskOperation.Replace
                          : MaskOperation.Add
                      );
                    }}
                  >
                    <PlusIcon
                      style={{
                        color:
                          operation === MaskOperation.Add
                            ? theme.text.default
                            : theme.text.info,
                      }}
                    />
                  </Button>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ color: theme.white }}>
                  Add to selection <Hotkey>Shift</Hotkey>
                </RichTooltipContent>
              </RichTooltip>
              <RichTooltip padding={11}>
                <RichTooltipTrigger>
                  <Button
                    variant={
                      operation === MaskOperation.Remove
                        ? 'primary'
                        : 'transparent'
                    }
                    size="iconSquared"
                    onClick={() => {
                      setOperation(
                        operation === MaskOperation.Remove
                          ? MaskOperation.Replace
                          : MaskOperation.Remove
                      );
                    }}
                  >
                    <MinusIcon
                      style={{
                        color:
                          operation === MaskOperation.Remove
                            ? theme.text.default
                            : theme.text.info,
                      }}
                    />
                  </Button>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ color: theme.white }}>
                  Remove from selection <Hotkey>{getAltShortcutKey()}</Hotkey>
                </RichTooltipContent>
              </RichTooltip>
              <ToolbarDivider />
              <RichTooltip padding={11}>
                <RichTooltipTrigger>
                  <Button
                    variant="transparent"
                    size="iconSquared"
                    onClick={() => copySelectionToNewLayer()}
                    disabled={!hasMask}
                  >
                    <CopyContentIcon
                      style={{
                        width: '18px',
                        height: '18px',
                        color: hasMask ? theme.text.info : theme.text.disabled,
                      }}
                    />
                  </Button>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ color: theme.white }}>
                  Copy selection to layer
                </RichTooltipContent>
              </RichTooltip>
            </>
          )}

          <RichTooltip padding={11}>
            <RichTooltipTrigger>
              <Button
                variant="transparent"
                size="iconSquared"
                onClick={() => selectionApiStore.getState().invertMask()}
              >
                <InvertIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    color: theme.text.info,
                  }}
                />
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Invert selection<Hotkey>{getShortcutKey(true)} + I</Hotkey>
            </RichTooltipContent>
          </RichTooltip>

          <ToolbarDivider />

          <RichTooltip padding={11}>
            <RichTooltipTrigger>
              <Button
                variant="primary"
                onClick={() => selectionApiStore.getState().deselectMask()}
                style={{
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Deselect
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Deselect <Hotkey>{getShortcutKey(true)} + D</Hotkey>
            </RichTooltipContent>
          </RichTooltip>
        </Actions>
      </ToolbarContainer>
    </bottomScreenTunnel.In>
  );
};

const Hotkey = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.text.info};
`;

const ToolbarDivider = styled.div`
  width: 1px;
  height: 16px;
  align-self: center;
  background-color: ${(p) => p.theme.surface.e2};
`;
