import {
  urqlClient,
  UpdateDrawingLayersMutation,
} from '@vizcom/shared/data-access/graphql';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { LayerMetadata3d, filterExists } from '@vizcom/shared/js-utils';
import { LayerPayload } from './addLayer';
import { omit } from 'lodash';
import { imageDataToBlob } from '@vizcom/shared-ui-components';
import { cachedLayerImagesByUrl } from './updateLayer';

export type LayerPatchPayLoad = {
  id: string;
  name?: string;
  visible?: boolean;
  opacity?: number;
  blendMode?: string;
  fill?: string;
  metadata3D?: LayerMetadata3d;
  orderKey?: string | null;
};

export const UpdateBulkLayersAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'updateBulkLayers';
    layerUpdates?: LayerPatchPayLoad[];
    newLayers?: LayerPayload[];
    deletedLayerIds?: string[];
  }
> = {
  type: 'updateBulkLayers',
  optimisticUpdater: ({ payload }, drawing) => {
    payload.layerUpdates?.forEach((layer) => {
      const existingLayer = drawing.layers.nodes.find((l) => l.id === layer.id);
      if (existingLayer) {
        existingLayer.name = layer.name ?? existingLayer.name;
        existingLayer.visible = layer.visible ?? existingLayer.visible;
        existingLayer.opacity = layer.opacity ?? existingLayer.opacity;
        existingLayer.blendMode = layer.blendMode ?? existingLayer.blendMode;
        existingLayer.fill = layer.fill ?? existingLayer.fill;
        existingLayer.metadata3D = layer.metadata3D ?? existingLayer.metadata3D;
        existingLayer.orderKey = layer.orderKey ?? existingLayer.orderKey;
      }
    });

    payload.newLayers?.forEach((layer) => {
      const existingLayer = drawing.layers.nodes.find((l) => l.id === layer.id);
      if (existingLayer) {
        return;
      }

      const newLayer: typeof drawing.layers.nodes[0] = {
        ...omit(layer, 'image'),
        drawingId: drawing.id,
        updatedAt: '0',
        createdAt: '0',
        meshPath: layer.meshPath,
        imagePath: layer.image ?? undefined,
      };

      drawing.layers.nodes.push(newLayer);
    });

    drawing.layers.nodes = drawing.layers.nodes.filter(
      (l) => !payload.deletedLayerIds?.includes(l.id)
    );
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    const newLayers = payload.newLayers
      ? await Promise.all(
          payload.newLayers?.map(async (layer) => ({
            ...omit(layer, 'image'),
            drawingId,
            imagePath:
              layer.image instanceof ImageData
                ? await imageDataToBlob(layer.image)
                : layer.image,
          }))
        )
      : undefined;

    const res = await urqlClient.mutation(UpdateDrawingLayersMutation, {
      input: {
        id: drawingId,
        layerUpdates: payload.layerUpdates,
        newLayers,
        deletedLayerIds: payload.deletedLayerIds,
      },
    });

    res.data?.updateDrawingLayers.drawing.layers.nodes.forEach((layer) => {
      const payloadData = payload.newLayers?.find((l) => l.id === layer.id);
      if (!payloadData) return;

      if (
        layer.imagePath &&
        (payloadData.image instanceof ImageData ||
          payloadData.image instanceof Blob)
      ) {
        cachedLayerImagesByUrl[layer.imagePath] = payloadData.image;
      }

      if (layer.meshPath && payloadData.meshPath instanceof Blob) {
        cachedLayerImagesByUrl[layer.meshPath] = payloadData.meshPath;
      }
    });

    if (res?.error) {
      throw new Error(
        `Error while updating drawing, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  actionMerger: (a, b) => {
    const aLayerUpdates = a.payload.layerUpdates || [];
    const bLayerUpdates = b.payload.layerUpdates || [];
    const finalLayerUpdates: typeof aLayerUpdates = [];

    aLayerUpdates.forEach((layer) => {
      finalLayerUpdates.push({
        ...layer,
      });
    });

    bLayerUpdates.forEach((layer) => {
      const existingLayer = finalLayerUpdates.find((l) => l.id === layer.id);
      const existingLayerIndex = finalLayerUpdates.findIndex(
        (l) => l.id === layer.id
      );

      if (existingLayer) {
        finalLayerUpdates[existingLayerIndex] = {
          ...existingLayer,
          ...layer,
        };
      } else {
        finalLayerUpdates.push({
          ...layer,
        });
      }
    });

    return {
      ...b,
      payload: {
        ...a.payload,
        ...b.payload,
        layerUpdates: finalLayerUpdates,
        deletedLayerIds: [
          ...(a.payload.deletedLayerIds || []),
          ...(b.payload.deletedLayerIds || []),
        ],
        newLayers: [
          ...(a.payload.newLayers || []),
          ...(b.payload.newLayers || []),
        ],
      },
    };
  },
  undoConstructor: ({ payload }, drawing) => {
    const existingLayers = drawing.layers.nodes.filter(
      (l) =>
        payload.layerUpdates?.find((layer) => layer.id === l.id) !== undefined
    );

    const layerUpdates = payload.layerUpdates
      ?.map((update) => {
        const existingLayer = existingLayers.find((l) => l.id === update.id);
        if (existingLayer) {
          const updates = Object.keys(update) as (keyof LayerPatchPayLoad)[];
          const layerUpdate = updates.reduce((acc, key) => {
            (acc[key] as string) = existingLayer[key];
            return acc;
          }, {} as LayerPatchPayLoad);

          return layerUpdate;
        }
        return undefined;
      })
      .filter(filterExists);

    const newLayers = payload.deletedLayerIds
      ?.map((id) => {
        const layer = drawing.layers.nodes.find((l) => l.id === id);
        if (layer) {
          return {
            ...layer,
            image: layer.imagePath,
          };
        }
        return undefined;
      })
      .filter(filterExists);

    const undoPayload: SyncedActionPayloadFromType<
      typeof UpdateBulkLayersAction
    > = {
      type: 'updateBulkLayers',
      layerUpdates,
      deletedLayerIds: payload.newLayers?.map((l) => l.id),
      newLayers: payload.deletedLayerIds
        ?.map((id) => {
          const layer = drawing.layers.nodes.find((l) => l.id === id);
          if (layer) {
            return {
              ...layer,
              image: layer.imagePath,
            };
          }
          return undefined;
        })
        .filter(filterExists),
    };

    return undoPayload;
  },
};
