import { useCallback, useEffect, useRef } from 'react';
import { useLastValue } from '@vizcom/shared-ui-components';

const VALUE_CHANGED_WATCHER_TIMEOUT = 100;

// This hook is used to reimplement the logic of a contenteditable div in a way that is stable and doesn't cause the cursor to jump around
// This is done natively in React but breaks when multiple renderer are used, in our case when using react-dom and react-three-fiber
// This is done by adding a timeout after calling `onValueChanged` to check if `value` still correspond to the content of the div
// if not, we reset the content of the div (making the cursor jump), but if it does, we do nothing and keep the cursor position
export const useContentEditableWithStableSelection = (
  value: string,
  onValueChanged: (value: string) => void,
  { selectAllContentOnMount = false } = {}
) => {
  const ref = useRef<HTMLElement | null>();
  const abortControllerRef = useRef<AbortController>();

  const valueRef = useLastValue(value);
  const onValueChangedRef = useLastValue(onValueChanged);
  const waitForValueToChangeTimeoutRef = useRef<ReturnType<
    typeof setTimeout
  > | null>(null);

  const setRef = useCallback(
    (node: HTMLElement | null) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = undefined;
      }

      ref.current = node;
      if (node) {
        if (selectAllContentOnMount) {
          setTimeout(() => {
            window.getSelection()?.selectAllChildren(node);
          }, 0); // need to wait for the element to be correctly inserted in the DOM before selecting it
        }
        node.textContent = valueRef.current;
        abortControllerRef.current = new AbortController();
        node.addEventListener(
          'input',
          () => {
            if (node.textContent !== valueRef.current) {
              onValueChangedRef.current?.(node.textContent ?? '');
              if (waitForValueToChangeTimeoutRef.current) {
                clearTimeout(waitForValueToChangeTimeoutRef.current);
              }
              waitForValueToChangeTimeoutRef.current = setTimeout(() => {
                waitForValueToChangeTimeoutRef.current = null;
                if (
                  ref.current &&
                  valueRef.current !== ref.current.textContent
                ) {
                  // we called onValueChanged but the value has not changed, meaning we should rollback the div content to the original value
                  // this will make the cursor jump
                  ref.current.textContent = valueRef.current;
                }
              }, VALUE_CHANGED_WATCHER_TIMEOUT);
            }
          },
          {
            signal: abortControllerRef.current.signal,
          }
        );
      }
    },
    [onValueChangedRef, valueRef, selectAllContentOnMount]
  );

  useEffect(() => {
    if (
      !ref.current ||
      waitForValueToChangeTimeoutRef.current
      // if waitForValueToChangeTimeoutRef is set, we're waiting to see if the value changed, we'll synchronize the content with the value after this timeout
      // we shouldn't do it now to prevent race-conditions
    ) {
      return;
    }
    if (ref.current.textContent !== value) {
      ref.current.textContent = value;
    }
    return () => {
      if (waitForValueToChangeTimeoutRef.current) {
        clearTimeout(waitForValueToChangeTimeoutRef.current);
      }
    };
  }, [value]);

  const bind = useCallback(() => {
    return { ref: setRef };
  }, [setRef]);

  return {
    bind,
    ref,
  };
};
