import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Text,
  TextInput,
  addToast,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';
import styled from 'styled-components';

interface CreateOrganizationNameInputPageProps {
  name: string;
  onSetName: (name: string) => void;
}

export const CreateOrganizationNameInputPage = ({
  name,
  onSetName,
}: CreateOrganizationNameInputPageProps) => {
  const navigate = useNavigate();

  const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!name) {
      return addToast('Please enter a name for your workspace');
    }
    navigate(paths.organization.create() + '/invites');
  };

  return (
    <Container>
      <Text block type="t1">
        Create a new workspace
      </Text>
      <Text>
        Workspaces are shared environments where teams can collaborate on files.
        After creating a workspace, you can invite members to join.
      </Text>

      <form onSubmit={handleSubmitForm} style={{ display: 'contents' }}>
        <div style={{ textAlign: 'left' }}>
          <Text block style={{ marginBottom: 8 }} type="sh2">
            Workspace name
          </Text>
          <TextInput
            $background="e1"
            value={name}
            required
            autoFocus
            onChange={(e) => onSetName(e.target.value)}
          />
        </div>
        <Button>Continue</Button>
      </form>
      <Text
        as={Link}
        to={paths.organization.create() + '/join_existing'}
        style={{ textDecoration: 'underline' }}
      >
        Join an existing workspace
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 32px;
  text-align: center;
`;
