import { v4 as uuidv4 } from 'uuid';
import * as THREE from 'three';
import { Line, Plane } from '@react-three/drei';
import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { floorPlane } from '../lib/utils';
import { useRef } from 'react';
import { filterChildByWorkbenchElementUserData } from './objectsUserdata';
import { damp } from 'three/src/math/MathUtils';
import { Line2 } from 'three-stdlib';
import {
  BOX_POINTS,
  MAX_Z_POSITION,
  getWorkbenchElementZPositionRange,
} from './helpers';
import { useWorkbenchToolState } from './toolbar/WorkbenchToolContext';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import {
  SECTION_DEFAULT_COLOR,
  SECTION_DEFAULT_TITLE,
} from './elements/section/WorkbenchElementSection';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { getElementDefaultSize } from './utils/getElementDefaultSize';

const planeIntersectPoint = new THREE.Vector3();

const AREA_OPACITY = 0.1;
const AREA_COLOR = 0x7070f2;

export type Area = [[number, number], [number, number]];

const createTextElement = (
  scene: THREE.Scene,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  setEditingElementId: (id: string | null) => void,
  x: number,
  y: number,
  width?: number,
  height?: number
) => {
  const zRange = getWorkbenchElementZPositionRange(scene);
  const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;
  const size = getElementDefaultSize('WorkbenchElementText');
  const elementId = uuidv4();
  handleAction({
    type: 'createElements',
    newElements: [
      {
        __typename: 'WorkbenchElementText',
        updatedAt: '0',
        id: elementId,
        x,
        y,
        width: width ?? size.x,
        height: height ?? size.y,
        zIndex,
        angle: 0,
        content: '',
        contentHeight: height ?? size.y,
        lockWidth: !!width,
        style: {},
      },
    ],
  });
  useWorkbenchElementSelectionState.getState().setFocusedElementsId(elementId);
  setEditingElementId(elementId);
};

const createSectionElement = (
  scene: THREE.Scene,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  x: number,
  y: number,
  width: number,
  height: number
) => {
  const [xBounds, yBounds] = getBounds([
    [x - width / 2, y - height / 2],
    [x + width / 2, y + height / 2],
  ]);
  const matchingObjects = getMatchingObjects(scene, xBounds, yBounds, true);

  const minZIndex = Math.min(
    ...matchingObjects.map((e) => e.userData.elementZIndex)
  );

  const zRange = getWorkbenchElementZPositionRange(scene);
  const zIndexFallback = isFinite(zRange[1])
    ? zRange[1] - 1
    : Math.round(MAX_Z_POSITION / 4);

  handleAction({
    type: 'createElements',
    newElements: [
      {
        __typename: 'WorkbenchElementSection',
        updatedAt: '0',
        id: uuidv4(),
        x,
        y,
        zIndex: minZIndex === Infinity ? zIndexFallback : minZIndex - 1,
        width,
        height,
        color: SECTION_DEFAULT_COLOR,
        title: SECTION_DEFAULT_TITLE,
      },
    ],
  });
};

export const getBounds = (area: Area): Area => {
  const xBounds = [
    Math.min(area[0][0], area[1][0]),
    Math.max(area[0][0], area[1][0]),
  ] as [number, number];
  const yBounds = [
    Math.min(area[0][1], area[1][1]),
    Math.max(area[0][1], area[1][1]),
  ] as [number, number];
  return [xBounds, yBounds];
};

const getMatchingObjects = (
  scene: THREE.Scene,
  xBounds: number[],
  yBounds: number[],
  isSectionTool: boolean
) => {
  const objects = filterChildByWorkbenchElementUserData(scene, () => true);

  return objects.filter((element) => {
    if (element.userData.elementTypename === 'WorkbenchElementSection') {
      return false;
    }
    const xStart = element.position.x - element.userData.elementWidth / 2;
    const xEnd = element.position.x + element.userData.elementWidth / 2;
    const yStart = element.position.y - element.userData.elementHeight / 2;
    const yEnd = element.position.y + element.userData.elementHeight / 2;

    if (isSectionTool) {
      return (
        xEnd <= xBounds[1] &&
        xStart >= xBounds[0] &&
        yEnd <= yBounds[1] &&
        yStart >= yBounds[0]
      );
    }

    return (
      xEnd >= xBounds[0] &&
      xStart <= xBounds[1] &&
      yEnd >= yBounds[0] &&
      yStart <= yBounds[1]
    );
  });
};

const updateFocusedElements = (
  scene: THREE.Scene,
  area: Area,
  isSectionTool: boolean
) => {
  const [xBounds, yBounds] = getBounds(area);
  const matchingObjects = getMatchingObjects(
    scene,
    xBounds,
    yBounds,
    isSectionTool
  );

  const focusedElementId = matchingObjects
    .map((object) => object.userData.elementId)
    .join('/');

  useWorkbenchElementSelectionState
    .getState()
    .setFocusedElementsId(focusedElementId);
};

export const getArea = (point: THREE.Vector3, area: Area | null): Area => {
  return area
    ? [area[0], [point.x, point.y]]
    : [
        [point.x, point.y],
        [point.x, point.y],
      ];
};

export const AreaSelector = (props: {
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}) => {
  const { tool, setTool } = useWorkbenchToolState();
  const scene = useThree((s) => s.scene);
  const area = useRef<null | Area>(null);
  const areaPlaneRef = useRef<THREE.Mesh>(null!);
  const outlineRef = useRef<Line2>(null!);

  const handleLastDrag = () => {
    if (area.current) {
      const width = Math.abs(area.current[0][0] - area.current[1][0]);
      const height = Math.abs(area.current[0][1] - area.current[1][1]);
      const x = (area.current[0][0] + area.current[1][0]) / 2;
      const y = (area.current[0][1] + area.current[1][1]) / 2;

      if (tool === 'section') {
        createSectionElement(scene, props.handleAction, x, y, width, height);
      } else if (tool === 'text') {
        createTextElement(
          scene,
          props.handleAction,
          props.setEditingElementId,
          x,
          y,
          width,
          height
        );
      }
    }

    setTool('select');
    area.current = null;
  };

  const bind = useDrag<ThreeEvent<PointerEvent>>(
    ({ event, active, last, distance, tap, intentional }) => {
      event.ray.intersectPlane(floorPlane, planeIntersectPoint);
      if (distance[0] + distance[1] < 2) {
        useWorkbenchElementSelectionState.getState().setFocusedElementsId('');
      }

      if (tool === 'text' && tap) {
        createTextElement(
          scene,
          props.handleAction,
          props.setEditingElementId,
          planeIntersectPoint.x,
          planeIntersectPoint.y
        );
        setTool('select');
        return;
      }

      if (!intentional) {
        return;
      }

      switch (tool) {
        case 'text':
          handleTextTool({ active, last });
          break;
        case 'section':
          handleSectionTool({ active, last });
          break;
        default:
          handleSelectTool({ active, last });
      }
    },
    {
      threshold: 5,
      triggerAllEvents: true,
      pointer: {
        keys: false,
      },
    }
  );

  const handleTextTool = ({
    active,
    last,
  }: {
    active: boolean;
    last: boolean;
  }) => {
    if (active) {
      area.current = getArea(planeIntersectPoint, area.current);
      // Set the height of the text element to the default height
      area.current[1][1] =
        area.current[0][1] + getElementDefaultSize('WorkbenchElementText').y;
    }

    if (last) {
      handleLastDrag();
    }
  };

  const handleSectionTool = ({
    active,
    last,
  }: {
    active: boolean;
    last: boolean;
  }) => {
    if (active) {
      area.current = getArea(planeIntersectPoint, area.current);
      updateFocusedElements(scene, area.current!, true);
    }

    if (last) {
      handleLastDrag();
    }
  };

  const handleSelectTool = ({
    active,
    last,
  }: {
    active: boolean;
    last: boolean;
  }) => {
    if (active) {
      area.current = getArea(planeIntersectPoint, area.current);
      updateFocusedElements(scene, area.current!, false);
    }

    if (last) {
      handleLastDrag();
    }
  };

  useFrame((s, dt) => {
    if (area.current) {
      const [x1, y1] = area.current[0];
      const [x2, y2] = area.current[1];

      outlineRef.current.material.opacity = 1;
      (areaPlaneRef.current.material as THREE.MeshBasicMaterial).opacity =
        AREA_OPACITY;

      const centerX = (x1 + x2) / 2;
      const centerY = (y1 + y2) / 2;
      const scaleX = Math.abs(x1 - x2);
      const scaleY = Math.abs(y1 - y2);

      areaPlaneRef.current.position.set(centerX, centerY, MAX_Z_POSITION);
      areaPlaneRef.current.scale.set(scaleX, scaleY, 1);
      outlineRef.current.position.set(centerX, centerY, MAX_Z_POSITION);
      outlineRef.current.scale.set(scaleX / 2, scaleY / 2, 1);
    } else {
      const material = areaPlaneRef.current.material as THREE.MeshBasicMaterial;
      if (material.opacity === 0) return;

      const opacity = damp(material.opacity, 0, 10, dt);

      material.opacity = opacity;
      outlineRef.current.material.opacity = opacity;
      if (opacity <= 0.01) {
        material.opacity = 0;
        outlineRef.current.material.opacity = 0;
      }
    }
  });

  return (
    <>
      <Plane
        args={[5000000, 5000000]}
        {...(bind() as any)}
        material-transparent={true}
        material-opacity={0}
        position={[0, 0, -5000000]}
      />
      <mesh ref={areaPlaneRef} renderOrder={MAX_Z_POSITION}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial color={AREA_COLOR} transparent />
      </mesh>
      <Line
        ref={outlineRef}
        points={BOX_POINTS}
        color={AREA_COLOR}
        lineWidth={1.5}
        renderOrder={MAX_Z_POSITION}
        transparent
      />
    </>
  );
};
