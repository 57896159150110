import {
  CompositeSceneFullData,
  useCompositeScene,
} from '@vizcom/shared/data-access/graphql';
import { useCallback, useMemo, useRef, useSyncExternalStore } from 'react';
import { useAlertOnUnload } from '@vizcom/shared-ui-components';
import { SyncQueue } from './syncQueue';
import produce from 'immer';
import { SyncQueueMeta } from './SyncedAction';
import { assertExists } from '@vizcom/shared/js-utils';
import { SyncQueueSynchronizer } from './SyncQueueSynchronizer';
import {
  CompositeSceneActionPayload,
  CompositeSceneActionTypes,
} from './actions/compositeScene';

export type SyncedStateActionHandler = (
  payload:
    | CompositeSceneActionPayload
    | ((
        state: CompositeSceneFullData
      ) => CompositeSceneActionPayload | undefined),
  meta?: SyncQueueMeta
) => void;

export const useCompositeSceneSyncedState = (
  compositeSceneId: string,
  syncQueueSynchronizer: SyncQueueSynchronizer
) => {
  const { data, fetching, error } = useCompositeScene(compositeSceneId);

  const optimisticCompositeSceneRef = useRef<
    CompositeSceneFullData | null | undefined
  >();

  const syncQueue = useMemo(
    () =>
      new SyncQueue<CompositeSceneFullData, CompositeSceneActionPayload>(
        syncQueueSynchronizer,
        CompositeSceneActionTypes,
        () => optimisticCompositeSceneRef.current!,
        compositeSceneId,
        'compositeScene'
      ),
    [compositeSceneId, syncQueueSynchronizer]
  );

  const queue = useSyncExternalStore(syncQueue.listen, syncQueue.getQueue);

  const hasUnsavedChanges = queue.length > 0;
  useAlertOnUnload(hasUnsavedChanges);

  const optimisticCompositeScene =
    data &&
    produce(data, (draft) => {
      for (const action of queue) {
        if (typeof action === 'function') {
          action(draft);
        } else {
          const actionType = CompositeSceneActionTypes.find(
            ({ type }) => type === action.payload.type
          );
          assertExists(
            actionType,
            `Action type ${action.payload.type} is not registered`
          );
          actionType.optimisticUpdater(action as any, draft);
        }
      }
    });
  optimisticCompositeSceneRef.current = optimisticCompositeScene;

  const handleAction = useCallback<SyncedStateActionHandler>(
    (payload, meta: SyncQueueMeta = {}) => {
      const finalPayload =
        typeof payload === 'function'
          ? payload(optimisticCompositeSceneRef.current!)
          : payload;
      if (!finalPayload) {
        return;
      }

      syncQueue.push(finalPayload, meta);
    },
    [compositeSceneId, syncQueue]
  );

  return {
    optimisticCompositeScene,
    optimisticCompositeSceneRef,
    handleAction,
    undoAction: () => syncQueue.undoAction(),
    redoAction: () => syncQueue.redoAction(),
    error,
    fetching,
    hasUnsavedChanges,
  };
};
