import { useCompositeSceneEditorContext } from './context';
import { EffectComposer, Outline } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
import { useThree } from '@react-three/fiber';

export const Postprocessing = () => {
  const { selected, hovered } = useCompositeSceneEditorContext();
  const { size } = useThree();

  return (
    <EffectComposer autoClear={false}>
      <Outline
        selection={hovered ?? undefined}
        edgeStrength={5}
        selectionLayer={2}
        visibleEdgeColor={0x000000}
        hiddenEdgeColor={0x555555}
        blendFunction={BlendFunction.ALPHA}
        width={size.width}
        height={size.height}
      />
      <Outline
        selection={selected ?? undefined}
        selectionLayer={1}
        edgeStrength={6}
        visibleEdgeColor={0x0000ff}
        hiddenEdgeColor={0x0000ff}
        blendFunction={BlendFunction.ALPHA}
        width={size.width}
        height={size.height}
      />
    </EffectComposer>
  );
};
