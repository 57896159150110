import { useMemo } from 'react';
import { Shape } from 'three';

export const RoundedPlaneGeometry = ({
  width = 1,
  height = 1,
  radius = 1,
  smoothness = 16,
}) => {
  const shape = useMemo(
    () => createShape(width, height, radius),
    [width, height, radius]
  );

  return <shapeGeometry args={[shape, smoothness]} />;
};

/**
 *
 * Taken from https://github.com/mrdoob/three.js/blob/master/examples/webgl_geometry_shapes.html#L233
 */
function createShape(width: number, height: number, radius: number) {
  const x = -width / 2;
  const y = -height / 2;
  const shape = new Shape();
  shape.moveTo(x + radius, y);
  shape.lineTo(x + width - radius, y);
  shape.absarc(
    x + width - radius,
    y + radius,
    radius,
    Math.PI * 1.5,
    Math.PI * 2,
    false
  );
  shape.lineTo(x + width, y + height - radius);
  shape.absarc(
    x + width - radius,
    y + height - radius,
    radius,
    0,
    Math.PI * 0.5,
    false
  );
  shape.lineTo(x + radius, y + height);
  shape.absarc(
    x + radius,
    y + height - radius,
    radius,
    Math.PI * 0.5,
    Math.PI,
    false
  );
  shape.lineTo(x, y + radius);
  shape.absarc(x + radius, y + radius, radius, Math.PI, Math.PI * 1.5, false);

  return shape;
}
