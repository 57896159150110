import { Text } from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, useCrisp } from '@vizcom/shared-ui-components';

const FullPageContainer = styled.div`
  background-color: ${(p) => p.theme.background.base};
  display: flex;
  flex-direction: column;
  padding: 4em;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: ${(p) => p.theme.text.default};
`;

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  width: 100%;
`;

const ErrorMessage = styled(Text)`
  color: ${(p) => p.theme.functional.danger.default};
  background-color: ${(p) => p.theme.surface.e1};
  padding: 16px;
  border-radius: ${(p) => p.theme.borderRadius.default};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

interface ErrorPageProps {
  title: string;
  description: string;
  errorMessage: string;
}

export const MobileWorkbenchActionErrorPage = ({
  title,
  description,
  errorMessage,
}: ErrorPageProps) => {
  const crisp = useCrisp();

  return (
    <FullPageContainer>
      <ErrorContent>
        <Text block type="h1" color="primary">
          {title}
        </Text>
        <Text block type="b1" color="secondary">
          {description}
        </Text>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <ButtonContainer>
          <Button as={Link} to="/">
            Return to dashboard
          </Button>
          <Text block color="secondary">
            Or
          </Text>
          <Button variant="secondary" onClick={() => crisp.openChat()}>
            Contact support
          </Button>
        </ButtonContainer>
      </ErrorContent>
    </FullPageContainer>
  );
};
