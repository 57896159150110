import { v4 as uuidv4 } from 'uuid';
import {
  AiSparklesIcon,
  Button,
  DownloadIcon,
  EditIcon,
  FeatureFlagged,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  downloadFile,
  GLOBAL_ENABLE_FLAG,
  ThumbnailIconRaw,
} from '@vizcom/shared-ui-components';
import {
  ImageInferenceType,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  ExtraHorizontalDelimiter,
  WorkbenchElementExtra,
} from '../../WorkbenchElementExtra';
import { CustomHtml } from '../../utils/CustomHtml';
import {
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import { WorkbenchElementDragConnector } from '../../elementConnector/workbenchElementDragConnector';
import { useThree } from '@react-three/fiber';
import { CustomImage } from '../../utils/CustomImage';
import { getElementDefaultSize } from '../../utils/getElementDefaultSize';
import {
  GENERATED_IMAGES_MARGIN,
  useIsWorkbenchViewer,
} from '../../../lib/utils';
import { findFirstFreeSlotInScene } from '../../utils/freeSlotFinders';
import styled, { useTheme } from 'styled-components';
import { useState } from 'react';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';
import { StaticSvg } from '../../utils/StaticSvg';
import { CustomText } from '../../utils/CustomText';
import { DrawingContextMenuItems } from '../../workbenchContextMenu/contextMenuItemsPerType/Drawing';
import { useWorkbenchElementSelectionState } from '../../../lib/elementSelectionState';
import { DrawingContentLayersIndicator } from './DrawingContentLayersIndicator';
import { useIsElementFilteredOut } from '../../WorkbenchFilters/WorkbenchFiltersContext';
import { ClientSideWorkbenchElementDrawing } from '../../../lib/clientState';

const DRAWING_NAME_WIDTH = 115;
const DRAWING_NAME_HEIGHT = 22;

interface WorkbenchElementDrawingProps {
  element: ClientSideWorkbenchElementDrawing;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  singleFocused: boolean;
  setEditingElementId: (id: string | null) => void;
  isDragging: boolean;
  workbenchId: string;
  isThumbnail: boolean;
  showSingleFocusedControls: boolean;
}

export const WorkbenchElementDrawing = ({
  element,
  handleAction,
  singleFocused,
  setEditingElementId,
  isDragging,
  isThumbnail,
  workbenchId,
  showSingleFocusedControls,
}: WorkbenchElementDrawingProps) => {
  const isFilteredOut = useIsElementFilteredOut(element);
  const scene = useThree((s) => s.scene);
  const isViewer = useIsWorkbenchViewer();
  const theme = useTheme();
  const { width, height } = getElementSize(element);
  const [editingName, setEditingName] = useState(false);

  const user = useCurrentUser();
  const shouldHaveAiMenu =
    user.data?.featureFlags['IMG2MESH'] ||
    user.data?.featureFlags[GLOBAL_ENABLE_FLAG] ||
    user.data?.featureFlags['WORKBENCH_MULTI_VIEW'];

  const handleDownload = () => {
    if (element.thumbnailPath) {
      // TODO change the name of the downloaded file to use the workbench name
      downloadFile(element.thumbnailPath, 'download');
    }
  };
  const handleCreateImg2ImgElementFromDrawing = () => {
    const img2imgElementId = uuidv4();
    const size = getElementDefaultSize('WorkbenchElementImg2Img');
    handleAction({
      type: 'createElements',
      newElements: [
        {
          __typename: 'WorkbenchElementImg2Img',
          updatedAt: '0',
          id: img2imgElementId,
          width: size.x,
          height: size.y,
          x: element.x + width / 2 + width / 2 + 40,
          y: element.y,
          zIndex: element.zIndex,
          sourceDrawingId: element.id,
          prompt: '',
          sourceImageInfluence: 1,
          publicPaletteId: PublicPalette.generalV2,
          imageInferenceType: ImageInferenceType.Render,
        },
      ],
    });
    useWorkbenchElementSelectionState
      .getState()
      .setFocusedElementsId(img2imgElementId);
    setTimeout(() => {
      const elementInputField = document.querySelector(
        `[data-workbench-id="${img2imgElementId}"] [data-workbench-role="promptInput"]`
      );
      if (elementInputField instanceof HTMLInputElement) {
        elementInputField.focus();
      }
      // TODO: better handle this to not use a timeout and instead have a reliable way of detecting the element has been added to the DOM
    }, 50);
  };

  const handleDoubleClickOnImage = () => {
    setEditingElementId(element.id);
  };

  const handleCreateMultiViews = () => {
    handleAction({
      type: 'createMultiViewsFromDrawing',
      drawingId: element.id,
    });
  };

  const handleCreateMeshFromDrawing = () => {
    const drawingSize = getElementSize(element);
    const width = 300;
    const height = 300;
    const position = findFirstFreeSlotInScene(scene, {
      firstSlotX:
        element.x + drawingSize.width / 2 + width / 2 + GENERATED_IMAGES_MARGIN,
      firstSlotY: element.y,
      slotWidth: width + GENERATED_IMAGES_MARGIN,
      slotHeight: height + GENERATED_IMAGES_MARGIN,
      maxElementPerLine: 4,
    });
    const zRange = getWorkbenchElementZPositionRange(scene);

    handleAction({
      type: 'createWorkbenchMeshFromDrawingAction',
      drawingId: element.id,
      placeholderId: uuidv4(),
      x: position[0],
      y: position[1],
      zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : element.zIndex + 1,
      width,
      height,
    });
  };

  const updateDrawingName = (name: string) => {
    handleAction({
      type: 'updateDrawing',
      drawingId: element.id,
      name,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      updateDrawingName(e.currentTarget.innerText.replace('\n', ''));
      setEditingName(false);
      e.preventDefault();
    }
  };

  const extra = isViewer ? (
    <>
      <Button onClick={() => setEditingElementId(element.id)}>View</Button>
      <RichTooltip padding={8}>
        <RichTooltipTrigger>
          <Button
            variant="transparent"
            size="iconSquared"
            onClick={handleDownload}
          >
            <DownloadIcon />
          </Button>
        </RichTooltipTrigger>
        <RichTooltipContent>
          <Text>Download</Text>
        </RichTooltipContent>{' '}
      </RichTooltip>
    </>
  ) : (
    <>
      <Button
        variant="primary"
        size="S"
        onClick={() => setEditingElementId(element.id)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '12px',
          fontSize: '12px',
          padding: '4px 12px',
        }}
      >
        <EditIcon
          style={{
            width: '24px',
            height: '24px',
            marginRight: '4px',
          }}
        />
        Edit
      </Button>
      <RichTooltip padding={8}>
        <RichTooltipTrigger>
          <Button
            variant="transparent"
            size="iconSquared"
            onClick={handleDownload}
          >
            <DownloadIcon />
          </Button>
        </RichTooltipTrigger>
        <RichTooltipContent>
          <Text>Download</Text>
        </RichTooltipContent>{' '}
      </RichTooltip>
      <ExtraHorizontalDelimiter />
      {shouldHaveAiMenu ? (
        <RichTooltip trigger="click" padding={8}>
          <RichTooltipTrigger>
            <Button size="iconSquared" variant="transparent">
              <AiSparklesIcon />
            </Button>
          </RichTooltipTrigger>
          <RichTooltipContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <Button
              variant="secondary"
              onClick={handleCreateImg2ImgElementFromDrawing}
            >
              Prompt
            </Button>
            <FeatureFlagged flag="IMG2MESH">
              <Button variant="secondary" onClick={handleCreateMeshFromDrawing}>
                3D model
              </Button>
            </FeatureFlagged>
            <FeatureFlagged flag="WORKBENCH_MULTI_VIEW">
              <Button variant="secondary" onClick={handleCreateMultiViews}>
                Multiviews
              </Button>
            </FeatureFlagged>
          </RichTooltipContent>
        </RichTooltip>
      ) : (
        <RichTooltip trigger="hover" padding={8}>
          <RichTooltipTrigger>
            <Button
              size="iconSquared"
              variant="transparent"
              onClick={handleCreateImg2ImgElementFromDrawing}
            >
              <AiSparklesIcon />
            </Button>
          </RichTooltipTrigger>
          <RichTooltipContent>
            <Text>Create with AI</Text>
          </RichTooltipContent>
        </RichTooltip>
      )}
    </>
  );

  return (
    <>
      {showSingleFocusedControls && (
        <WorkbenchElementDragConnector
          element={element}
          handleAction={handleAction}
        />
      )}
      <CustomImage
        position={[0, 0, 0.5]}
        onDoubleClick={handleDoubleClickOnImage}
        scale={[width, height]}
        url={element.thumbnailPath}
        opacity={isFilteredOut ? 0.2 : 1}
      />

      {isThumbnail && (
        <StaticSvg
          svg={ThumbnailIconRaw}
          strokeMaterial={{ color: theme.primary.default }}
          anchorX="left"
          anchorY="middle"
          position={[-width / 2, height / 2 + 13, 0.5]}
        />
      )}

      <group
        position={[-width / 2 + (isThumbnail ? 14 : 0), height / 2 + 14, 0.5]}
      >
        {editingName ? (
          <CustomHtml
            contentSize={{
              width: DRAWING_NAME_WIDTH,
              height: DRAWING_NAME_HEIGHT,
            }}
            transform
            position={[DRAWING_NAME_WIDTH / 2, 0, 0]}
          >
            <div
              style={{
                width: DRAWING_NAME_WIDTH,
              }}
            >
              <NameInput
                contentEditable
                ref={(el) => {
                  if (el) {
                    el.focus();
                    el.innerText = element.name || '';
                    window.getSelection()?.selectAllChildren(el);
                  }
                }}
                onBlur={(e) => {
                  updateDrawingName(e.target.innerText.replace('\n', ''));
                  setEditingName(false);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </CustomHtml>
        ) : (
          <CustomText
            color={singleFocused ? theme.primary.default : theme.text.info}
            fontSize={12}
            font={'Inter'}
            fontWeight={'semiBold'}
            anchorX={'left'}
            maxWidth={width}
            whiteSpace="nowrap"
            clipRect={[0, -10, DRAWING_NAME_WIDTH, 10]}
            onDoubleClick={(e) => {
              e.stopPropagation();
              setEditingName(true);
            }}
            content={element.name?.replace('\n', '') || 'Untitled'}
            visible={!editingName}
            userData={{ cursor: 'text' }}
          />
        )}
      </group>

      {/* <DrawingContentLayersIndicator
        drawing={element}
        position={[width / 2, height / 2 + 2, 0.5]}
      /> */}

      {!isDragging && singleFocused && (
        <CustomHtml
          style={{ pointerEvents: 'none' }}
          position={[0, height / 2, 0.5]}
        >
          <WorkbenchElementExtra
            element={element}
            handleAction={handleAction}
            menuItems={
              <DrawingContextMenuItems
                element={element}
                workbenchId={workbenchId}
                handleAction={handleAction}
              />
            }
          >
            {extra}
          </WorkbenchElementExtra>
        </CustomHtml>
      )}
    </>
  );
};

const NameInput = styled.div`
  display: inline-flex;
  min-width: 20px;
  max-width: ${DRAWING_NAME_WIDTH}px;
  height: 26px;
  background: white;
  color: ${({ theme }) => theme.primary.default};
  border: 2px solid ${({ theme }) => theme.primary.default};
  border-radius: 8px;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 500;
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  align-items: center;
`;
