import { Button, useObjectURL } from '@vizcom/shared-ui-components';
import {
  Container,
  Backdrop,
  Resizer,
  PreviewList,
  PreviewItem,
  PreviewItemButton,
} from './styles';
import { CompositeSceneDebugSettings } from '../compositeSceneEditor/hooks/useCompositeSceneDebug';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { v4 as uuidv4 } from 'uuid';
import { Object3D, Vector3 } from 'three';
import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { Icon } from '../ui/Icon/Icon';

import CameraIcon from '../../../assets/icons/camera.svg';

const RenderPreview = ({ render }: { render: Uint8Array | null }) => {
  const lastPreviewUrl = useObjectURL(render);

  return <img src={lastPreviewUrl} />;
};

export const CompositeSceneAIOverlay = ({
  renders,
  compositeSceneDebug,
  compositeScene,
  handleAction,
}: {
  renders: (Uint8Array | null)[];
  compositeSceneDebug: CompositeSceneDebugSettings;
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const theme = useTheme();
  const { aiPreviewMode, setAIPreviewMode } = useCompositeSceneEditorContext();
  const { activePreview, debugViewport } = compositeSceneDebug;
  const showDebugAIRender = activePreview !== 'default' && debugViewport;
  const [useMinifiedUI, setUseMinifiedUI] = useState<boolean>(false);
  const emptyRenderFields = useMemo(() => {
    const camerasCount = compositeScene.compositeSceneElements.nodes.filter(
      (node) => node.basicShape === 'Camera'
    ).length;
    const emptyRenders = Math.max(0.0, camerasCount - renders.length);

    return Array(emptyRenders).fill(0);
  }, [compositeScene, renders]);

  const startResize = () => {
    const handlePointerMove = (event: PointerEvent) => {
      const ratio = Math.max(0.05, 1.0 - event.clientX / window.innerWidth);

      setAIPreviewMode({
        mode: 'splitscreen',
        splitScreenRatio: ratio,
      });

      setUseMinifiedUI(ratio < 0.315);
    };

    const handlePointerUp = () => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
    };

    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('pointerup', handlePointerUp);
  };

  if (aiPreviewMode.mode !== 'splitscreen') {
    return null;
  }

  return (
    <>
      <Resizer
        $offset={Math.floor(aiPreviewMode.splitScreenRatio! * 100)}
        onPointerDown={() => startResize()}
      />
      <Container
        $width={`${Math.floor(aiPreviewMode.splitScreenRatio! * 100)}dvw`}
      >
        <PreviewList>
          {showDebugAIRender && (
            <PreviewItem>
              <img src={debugViewport} />
            </PreviewItem>
          )}
          {(renders || []).map((render, index) => (
            <PreviewItem key={index}>
              <RenderPreview render={render} />
            </PreviewItem>
          ))}
          {emptyRenderFields.map((_, index) => (
            <PreviewItem key={index} />
          ))}
          <PreviewItem>
            <PreviewItemButton
              onClick={() => {
                const uuid = uuidv4();

                const currentCameraPosition = new Vector3(
                  compositeScene.cameraPositionX,
                  compositeScene.cameraPositionY,
                  compositeScene.cameraPositionZ
                );

                const mock = new Object3D();
                mock.position.copy(currentCameraPosition);
                mock.lookAt(
                  compositeScene.cameraTargetX,
                  compositeScene.cameraTargetY,
                  compositeScene.cameraTargetZ
                );
                mock.updateMatrix();
                mock.rotateY(Math.PI);

                const mockDirection = mock.getWorldDirection(new Vector3());
                mock.position.add(mockDirection.multiplyScalar(-0.5));

                handleAction({
                  type: 'createCompositeSceneElement',
                  id: uuid,
                  name: 'Camera',
                  basicShape: 'Camera',
                });
                handleAction({
                  type: 'updateCompositeSceneElement',
                  id: uuid,
                  meshes: {
                    root: {
                      position: mock.position.toArray(),
                      quaternion: mock.quaternion.toArray(),
                      scale: [1.0, 1.0, 1.0],
                    },
                  },
                });
              }}
            >
              <span>
                {useMinifiedUI ? (
                  <Button variant="transparent" size="iconSquared" disabled>
                    <Icon
                      icon={CameraIcon}
                      color={theme.surface.e2}
                      size={24}
                      className="minified-ui-icon"
                    />
                  </Button>
                ) : (
                  'New Camera +'
                )}
              </span>
            </PreviewItemButton>
          </PreviewItem>
        </PreviewList>
        <Backdrop />
      </Container>
    </>
  );
};
