import { TeamRole } from '@vizcom/shared/data-access/graphql';

export enum MemberOrderBy {
  createdAtAsc = 'Date added (oldest first)',
  createdAtDesc = 'Date added (newest first)',
  emailAsc = 'Email (A-Z)',
  emailDesc = 'Email (Z-A)',
  lastActiveAsc = 'Last active (oldest first)',
  lastActiveDesc = 'Last active (newest first)',
}

export interface MemberFiltersType {
  role?: TeamRole;
  status?: 'joined' | 'pending';
  orderBy: MemberOrderBy;
}
