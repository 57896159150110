import { Lasso } from './Lasso';
import {
  WorkbenchStudioTool,
  isSelectionTool,
  useWorkbenchStudioToolState,
} from '../studioState';
import {
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from './useSelectionApi';
import { BrushSelection } from './BrushSelection';
import { HtmlOverlay } from '../../utils/HtmlOverlay';
import { SelectionToolbar } from './SelectionToolbar';
import { getLayerOrderKey } from '@vizcom/shared/js-utils';
import { v4 as uuid } from 'uuid';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { applyMaskToImage, imageToCanvas } from '@vizcom/shared-ui-components';
import { AutoSelection } from './AutoSelection';
import { ActiveMask, MaskDisplayMode } from './ActiveMask';
import { BezierSelection } from './bezier';

export function SelectionTools({
  drawing,
  layer,
  drawingSize,
  handleAction,
  setActiveLayer,
  isInferenceRunning,
  getCompositedImage,
}: {
  drawingSize: [number, number];
  drawing: Drawing2dStudio;
  layer: Drawing2dStudio['layers']['nodes'][0] | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayer: (id: string | undefined) => void;
  isInferenceRunning: boolean;
  getCompositedImage: (() => ImageData) | undefined;
}) {
  const selectionApiStore = useSelectionApiStore();
  const toolState = useWorkbenchStudioToolState();

  const copySelectionToNewLayer = async () => {
    if (!layer) {
      return;
    }
    const maskData = selectionApiStore.getState().getSelectionImage()?.data;
    if (!maskData) {
      return;
    }

    let imageData;

    if (layer.imagePath) {
      const { canvas, ctx } = await imageToCanvas(layer.imagePath);
      imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      applyMaskToImage(imageData.data, maskData);
    } else if (layer.fill.length === 7) {
      const colorValue = parseInt(layer.fill.slice(1), 16);
      const r = (colorValue >> 16) & 0xff;
      const g = (colorValue >> 8) & 0xff;
      const b = colorValue & 0xff;

      const bufferSize = drawingSize[0] * drawingSize[1] * 4;
      const pixels = new Uint8ClampedArray(bufferSize);
      for (let i = 0; i < bufferSize; i += 4) {
        pixels[i] = r;
        pixels[i + 1] = g;
        pixels[i + 2] = b;
        pixels[i + 3] = maskData[i];
      }
      imageData = new ImageData(pixels, drawingSize[0]);
    } else {
      return;
    }

    const newLayerId = uuid();
    handleAction({
      type: 'addLayer',
      layer: {
        id: newLayerId,
        name: `${layer.name} copy`,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        orderKey: getLayerOrderKey(drawing.layers.nodes, layer.id),
        image: imageData,
      },
    });
    toolState.setTool(WorkbenchStudioTool.Move);
    setActiveLayer(newLayerId);
  };

  let selectionTool = null;
  if (toolState.tool === WorkbenchStudioTool.Lasso) {
    selectionTool = <Lasso drawingSize={drawingSize} />;
  }
  if (toolState.tool === WorkbenchStudioTool.BrushSelection) {
    selectionTool = <BrushSelection drawingSize={drawingSize} />;
  }
  if (toolState.tool === WorkbenchStudioTool.AutoSelection) {
    selectionTool = (
      <AutoSelection
        drawingSize={drawingSize}
        getCompositedImage={getCompositedImage}
      />
    );
  }
  if (toolState.tool === WorkbenchStudioTool.BezierSelection) {
    selectionTool = <BezierSelection drawingSize={drawingSize} />;
  }

  const hasMask = useSubscribeToSelectionApi((state) => state.hasMask);
  const selectionTexture = useSubscribeToSelectionApi((state) => state.texture);

  return (
    <>
      {toolState.tool !== WorkbenchStudioTool.Move &&
        toolState.tool !== WorkbenchStudioTool.Transform &&
        toolState.tool !== WorkbenchStudioTool.BrushSelection && (
          <ActiveMask
            drawingSize={drawingSize}
            maskTexture={selectionTexture}
            mode={MaskDisplayMode.MARCHING_ANTS}
          />
        )}
      {selectionTool}
      <HtmlOverlay>
        {!isInferenceRunning &&
          (isSelectionTool(toolState.tool) || hasMask) && (
            <SelectionToolbar
              copySelectionToNewLayer={copySelectionToNewLayer}
            />
          )}
      </HtmlOverlay>
    </>
  );
}
