import { useCreateOrganizationSubscriptionManagementPortal } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  LoadingLogoInset,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

export const OrganizationInvoices = (props: { organizationId: string }) => {
  const { organizationId } = props;
  const [res, createPortal] =
    useCreateOrganizationSubscriptionManagementPortal();

  const handleViewInvoices = async () => {
    const res = await createPortal({
      input: {
        organizationId,
      },
    });
    if (res.error || !res.data) {
      return addToast('Error while accessing invoices', {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
    window.location.assign(
      res.data.createOrganizationSubscriptionManagementPortal.url
    );
  };

  return (
    <>
      <Button variant="secondary" onClick={handleViewInvoices}>
        View Invoices
      </Button>
      <LoadingLogoInset active={res.fetching} />
    </>
  );
};
