import { DoubleSide } from 'three';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { LayerEditor } from './LayerEditor';
import { useLayerTexture } from '../utils';
import { LayerContent } from './LayersCompositor/LayerContent';
import { ErrorBoundary, ToastIndicator } from '@vizcom/shared-ui-components';

interface CompositeLayerProps {
  layer: Drawing2dStudio['layers']['nodes'][0];
  active: boolean;
  drawingSize: [number, number];
  visible: boolean;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
}

export const CompositeLayer = (props: CompositeLayerProps) => {
  return (
    <ErrorBoundary
      logToSentry
      fallbackRender={({ error }) => (
        <ToastIndicator
          text={
            <div>
              <p>
                There was an error loading the layer "{props.layer.name}
                ", please retry or delete this layer.
              </p>
              <p style={{ fontSize: 10, marginTop: 12 }}>
                {error?.message ?? 'unknown error'}
              </p>
            </div>
          }
          type="error"
        />
      )}
    >
      <CompositeLayerContent {...props} />
    </ErrorBoundary>
  );
};

const CompositeLayerContent = ({
  layer,
  active,
  drawingSize,
  visible,
  handleAction,
  filterHistory,
}: CompositeLayerProps) => {
  const image = useLayerTexture(layer);

  return (
    <group visible={visible}>
      {active && layer.visible ? (
        <LayerEditor
          drawingSize={drawingSize}
          layer={layer}
          layerImage={image}
          handleAction={handleAction}
          filterHistory={filterHistory}
        />
      ) : (
        <LayerContent
          id={layer.id}
          opacity={layer.opacity}
          blendMode={layer.blendMode}
          visible={layer.visible}
        >
          <LayerStaticView layer={layer} drawingSize={drawingSize} />
        </LayerContent>
      )}
    </group>
  );
};

//A simple non-interactive view of a layer
export function LayerStaticView({
  layer,
  drawingSize,
}: {
  layer: Drawing2dStudio['layers']['nodes'][0];
  drawingSize: [number, number];
}) {
  const image = useLayerTexture(layer);

  const fill =
    layer.fill && layer.fill.match(/^#([0-9a-f]{6})$/i)
      ? layer.fill
      : undefined;
  return (
    <>
      {image && (
        <mesh renderOrder={1} scale={[drawingSize[0], drawingSize[1], 1]}>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            map={image}
            transparent
            depthTest={false}
            side={DoubleSide}
          />
        </mesh>
      )}
      {fill && !layer.imagePath && (
        <mesh renderOrder={0} scale={[drawingSize[0], drawingSize[1], 1]}>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            color={fill}
            transparent
            depthTest={false}
            side={DoubleSide}
          />
        </mesh>
      )}
    </>
  );
}
