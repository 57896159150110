import { GroupProps } from '@react-three/fiber';
import { useTheme } from 'styled-components';
import {
  HANDLE_SIZE,
  HIT_AREA_MARGIN,
  OutlinedCircleHandle,
} from '../../utils/Handles';
import { ROTATION_CURSORS } from '../../utils/ResizePositionRotationPresenter';
import { StaticSvg } from '../../utils/StaticSvg';
import { Circle, OutlinedCircle } from '../../utils/Shapes';
import { useSpring, easings, animated } from '@react-spring/three';
import { ReactDOMAttributes } from '@use-gesture/react/dist/declarations/src/types';
import { LockState } from './SymmetryAxis';

const rotationSVG = `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 32 32' fill='white'>
    <path d='M13.1459 8.33672L13.9885 7.53423C18.6426 12.3039 18.6426 19.694 13.9885 24.4638L13.1459 23.6613C12.4727 23.0201 11.2952 23.2964 11.0499 24.2308L9.54165 29.9771C9.29575 30.9138 10.1824 31.6716 11.0575 31.4633L17.0908 30.0267C18.02 29.8054 18.393 28.6587 17.6709 27.971L16.8515 27.1906C23.0522 20.9093 23.0522 11.0887 16.8515 4.80747L17.6709 4.02703C18.393 3.33931 18.02 2.19259 17.0908 1.97132L11.0575 0.534763C10.1824 0.32639 9.29575 1.08419 9.54164 2.02097L11.0499 7.76721C11.2952 8.70161 12.4727 8.97793 13.1459 8.33672Z'/>
    </svg>
  `;

export const SymmetryRotateHandle = ({
  lockState,
  pointerEvents,
  ...groupProps
}: {
  lockState: LockState;
  pointerEvents: ReactDOMAttributes;
} & GroupProps) => {
  return (
    <group
      {...groupProps}
      {...(lockState === LockState.UNLOCKED ? (pointerEvents as any) : {})}
      userData={{
        ...groupProps.userData,
        cursor:
          lockState === LockState.UNLOCKED ? ROTATION_CURSORS['1/1'] : 'none',
      }}
      visible={lockState === LockState.UNLOCKED}
      name="SymmetryRotateHandle"
    >
      <OutlinedCircleHandle color={'#d5d5dd'} scale={[1.1, 1.1, 1]} />
      <StaticSvg
        rotation={[0, 0, Math.PI / 2]}
        scale={[0.3, 0.3, 1]}
        svg={rotationSVG}
        fillMaterial={{ color: '#d5d5dd' }}
      />
    </group>
  );
};

export const SymmetryPositionHandle = ({
  lockState,
  pointerEvents,
  ...groupProps
}: {
  lockState: LockState;
  pointerEvents: ReactDOMAttributes;
} & GroupProps) => {
  const theme = useTheme();

  const transitionProps = useSpring({
    scale: (lockState === LockState.UNLOCKING ? [1.3, 1.3, 1] : [1, 1, 1]) as [
      number,
      number,
      number
    ],
    config: { duration: 400, easing: easings.easeOutCubic },
    reset: true,
  });

  const color =
    lockState === LockState.LOCKED ? '#d5d5d5' : theme.primary.default;
  const radius =
    lockState === LockState.UNLOCKED ? 1.3 * HANDLE_SIZE : HANDLE_SIZE;
  return (
    <group
      {...groupProps}
      userData={{
        ...groupProps.userData,
        cursor: lockState !== LockState.UNLOCKED ? 'pointer' : 'move',
      }}
      name="SymmetryPositionHandle"
      {...(pointerEvents as any)}
    >
      <animated.group scale={transitionProps.scale}>
        <OutlinedCircle radius={radius} color={color} />
        <Circle
          radius={7}
          color={color}
          opacity={lockState === LockState.UNLOCKED ? 0 : 1}
        />
      </animated.group>

      <Circle
        radius={HANDLE_SIZE + HIT_AREA_MARGIN}
        opacity={0}
        renderOrder={2}
      />
    </group>
  );
};
