import { drawingsByIds, urqlClient } from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import { addToast, formatErrorMessage } from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from './clientState';
import { elementById } from './utils';
import { v4 as uuidv4 } from 'uuid';
import { deletedDrawingHistory } from './actions/workbench/multiDeleteAction';
import { useWorkbenchSyncedState } from './useWorkbenchSyncedState';
import { RootState } from '@react-three/fiber';
import { Scene } from 'three';
import { findChildByWorkbenchElementUserData } from '../components/objectsUserdata';

const ELEMENTS_CLIPBOARD_KEY =
  `workbench-keyboard-shortcuts-elements-clipboard` as const;

interface WorkbenchClipboardData {
  type: typeof ELEMENTS_CLIPBOARD_KEY;
  data: ClientSideWorkbenchElementData[];
}

export const workbenchElementClipboardDataFromString = (
  data: string | undefined
) => {
  if (!data) {
    return false;
  }
  try {
    const body = JSON.parse(data);
    if (body.type === ELEMENTS_CLIPBOARD_KEY) {
      return body as WorkbenchClipboardData;
    }
  } catch {}
  return false;
};

export const copyWorkbenchElementsToClipboard = async (
  elements: ClientSideWorkbenchElementData[],
  focusedElementsId: string
) => {
  if (document.getSelection()?.toString()) {
    return;
  }
  const data = focusedElementsId
    .split('/')
    .filter(Boolean)
    .map((id) => elementById(elements, id))
    .filter(filterExists);

  if (!data.length) {
    return;
  }
  await navigator.clipboard.writeText(
    JSON.stringify({
      type: ELEMENTS_CLIPBOARD_KEY,
      data,
    } as WorkbenchClipboardData)
  );
};

export const pasteWorkbenchElementsFromClipboard = async (
  data: string,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  setFocusedElementsId: (id: string) => void,
  threeState: RootState // used to position the new elements
) => {
  if (!data) {
    return;
  }
  const parsedData = workbenchElementClipboardDataFromString(data);
  if (!parsedData) {
    return;
  }

  try {
    const updatedDrawingIds: { oldId: string; newId: string }[] = [];

    const { data: drawingData } = await urqlClient.query(drawingsByIds, {
      ids: parsedData.data
        .filter((el) => el.__typename === 'Drawing')
        .map(({ id }) => id),
    });
    const drawings = drawingData?.drawings?.nodes ?? [];
    const sourceImagesPerPalette: Record<string, any> = {};

    const elementsWithDrawingData = parsedData.data
      .map((el) => {
        if (el.__typename !== 'Drawing') {
          if ((el as { failureReason?: string }).failureReason) {
            addToast(
              `The image you're trying to paste has thrown an error, it will be ignored`,
              {
                type: 'danger',
              }
            );
            return null;
          }
          if (
            el.__typename === 'WorkbenchElementPlaceholder' &&
            el.type !== 'drawing'
          ) {
            addToast(
              `The image you're trying to paste is still loading, it will be ignored`,
              {
                type: 'danger',
              }
            );
            return null;
          }
          return el;
        }
        const drawing = drawings.find((d) => d.id === el.id);
        if (!drawing) {
          addToast(
            `The source of the image you're trying to paste has been deleted, it will be ignored`,
            {
              type: 'danger',
            }
          );
          return null;
        }
        return {
          ...el,
          layersOrder: drawing.layersOrder,
          layers: drawing.layers,
        };
      })
      .filter(filterExists);

    const maxZIndex =
      Math.max(...elementsWithDrawingData.map((el) => el.zIndex)) + 1;
    const newElements = elementsWithDrawingData.map((el, index) => {
      const newId = uuidv4();
      const hasHistory = deletedDrawingHistory.get(el.id);
      if (hasHistory) {
        updatedDrawingIds.push({ oldId: el.id, newId });
        deletedDrawingHistory.set(newId, { ...hasHistory, id: newId });
      }
      if (el.__typename === 'Drawing' && el.layers?.nodes) {
        const newLayerIds = Object.fromEntries(
          el.layers.nodes.map((layer) => [layer.id, uuidv4()])
        );
        const nodes = el.layers.nodes?.map((layer) => ({
          ...layer,
          id: newLayerIds[layer.id],
          drawingId: newId,
        }));

        return {
          ...el,
          zIndex: maxZIndex + index,
          id: newId,
          layersOrder: el.layersOrder?.map(
            (layerId: string) => newLayerIds[layerId]
          ),
          layers: { nodes },
        };
      }

      if (el.__typename === 'WorkbenchElementPalette') {
        const filteredSourceImages = el.sourceImages.nodes.filter(
          (image) => typeof image.imagePath === 'string'
        );
        sourceImagesPerPalette[newId] = filteredSourceImages;

        if (filteredSourceImages.length !== el.sourceImages.nodes.length) {
          addToast('Could not paste source image(s) that are being uploaded');
        }
      }

      return {
        ...el,
        zIndex: maxZIndex + index,
        id: newId,
      };
    });

    // update any references to the previous id
    updatedDrawingIds.forEach(({ oldId, newId }) => {
      newElements.forEach((el) => {
        if (el.__typename === 'WorkbenchElementImg2Img') {
          if (el.sourceDrawingId === oldId) {
            el.sourceDrawingId = newId;
          }
        }
      });
    });

    recenterElementsAroundPosition(newElements, [
      threeState.camera.position.x,
      threeState.camera.position.y,
    ]);
    shiftElementsToAvoidOverlap(newElements, threeState.scene);

    handleAction({
      type: 'createElements',
      newElements: newElements,
    });

    // Add source images to palette(s)
    Object.entries(sourceImagesPerPalette).forEach(([id, sourceImages]) => {
      if (sourceImages.length === 0) return;
      handleAction({
        type: 'insertImagesToPalette',
        id,
        sourceImages: sourceImages.map(
          ({ imagePath }: { imagePath: string }) => ({
            id: uuidv4(),
            image: imagePath,
          })
        ),
      });
    });
    setFocusedElementsId(newElements.map((el) => el.id).join('/'));
  } catch (e: any) {
    addToast('Error pasting elements', {
      secondaryText: formatErrorMessage(e),
      type: 'danger',
    });
  }
};

const recenterElementsAroundPosition = (
  elements: ClientSideWorkbenchElementData[],
  centerPosition: [number, number]
) => {
  const currentCenterPosition = elements
    .map((el) => [el.x, el.y])
    .reduce((acc, [x, y]) => [acc[0] + x, acc[1] + y], [0, 0])
    .map((v) => v / elements.length);

  elements.forEach((el) => {
    el.x = el.x - currentCenterPosition[0] + centerPosition[0];
    el.y = el.y - currentCenterPosition[1] + centerPosition[1];
  });
};

const shiftElementsToAvoidOverlap = (
  elements: ClientSideWorkbenchElementData[],
  scene: Scene
) => {
  while (
    elements.some((el) =>
      findChildByWorkbenchElementUserData(
        scene,
        (userData) =>
          Math.abs(userData.elementX - el.x) < 1 &&
          Math.abs(userData.elementY - el.y) < 1
      )
    )
  ) {
    elements.forEach((el) => {
      el.x += 20;
      el.y -= 20;
    });
  }
};
