import {
  ColorSwatch,
  ExtraPopup,
  Field,
  Input,
  InputOption,
} from '../../style';
import { textColors } from '../../../../constants';
import ColorWheel from '../../../../icons/colorwheel.svg?react';
import { useState } from 'react';
import { ColorPicker, CarretDownIcon } from '@vizcom/shared-ui-components';

type WorkbenchElementSectionColorProps = {
  color: string;
  handleChangeColor: (newColor: string) => void;
};

export const WorkbenchElementSectionColor = ({
  color,
  handleChangeColor,
}: WorkbenchElementSectionColorProps) => {
  const [isSelectingColor, setIsSelectingColor] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <Field>
      <Input>
        <div
          onClick={() => {
            setIsSelectingColor(!isSelectingColor);
            setShowColorPicker(false);
          }}
          style={{
            display: 'flex',
            gap: '12px',
            cursor: 'pointer',
          }}
        >
          <ColorSwatch style={{ background: color ?? '#000000' }} />
          <CarretDownIcon
            style={{
              alignSelf: 'center',
            }}
          />
        </div>
      </Input>
      {isSelectingColor && (
        <ExtraPopup $top={-75} $right={0}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
            }}
          >
            {textColors.map((c) => (
              <InputOption key={c} onClick={() => handleChangeColor(c)}>
                <ColorSwatch style={{ background: c }} />
              </InputOption>
            ))}
            <InputOption
              onClick={() => {
                setShowColorPicker(true);
                setIsSelectingColor(false);
              }}
            >
              <ColorWheel
                style={{
                  border: '1px solid white',
                  borderRadius: '50%',
                }}
              />
            </InputOption>
          </div>
        </ExtraPopup>
      )}
      {showColorPicker && (
        <ExtraPopup
          $top={-265}
          $right={0}
          style={{
            padding: 0,
          }}
        >
          <ColorPicker
            color={color ?? '#000000'}
            onChange={(c) => handleChangeColor(c)}
          />
        </ExtraPopup>
      )}
    </Field>
  );
};
