import { SyncedActionPayloadFromType } from '../../SyncedAction';
import { AddLayerAction } from './addLayer';
import { CreateLayer3dFromDrawing } from './createLayer3dFromDrawingAction';
import { DeleteLayerAction } from './deleteLayer';
import { MergeLayersAction } from './mergeLayers';
import { MutateLocalStateAction } from './mutateLocalStateAction';
import { UnmergeLayersAction } from './unMergeLayers';
import { UpdateBulkLayersAction } from './updateBulkLayers';
import { UpdateDrawingAction } from './updateDrawing';
import { UpdateDrawingSizeAction } from './updateDrawingSize';
import { UpdateDrawingThumbnailAction } from './updateDrawingThumbnail';
import { UpdateLayerAction } from './updateLayer';

export const DrawingActionTypes = [
  UpdateBulkLayersAction,
  UpdateDrawingThumbnailAction,
  MergeLayersAction,
  CreateLayer3dFromDrawing,
  UnmergeLayersAction,
  UpdateDrawingSizeAction,
  UpdateDrawingAction,
  MutateLocalStateAction,
  AddLayerAction,
  DeleteLayerAction,
  UpdateLayerAction,
];

export type DrawingActionPayload = SyncedActionPayloadFromType<
  typeof DrawingActionTypes[number]
>;
