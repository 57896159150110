import {
  useCurrentUser,
  useOrganization,
} from '@vizcom/shared/data-access/graphql';

export const useCanEditOrganization = (
  organizationId: string | null | undefined
) => {
  const { data: currentUser } = useCurrentUser();
  const organization = useOrganization(organizationId);

  return currentUser?.isAdmin || organization.data?.currentUserRole === 'ADMIN';
};
