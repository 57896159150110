import styled from 'styled-components';
import { useState } from 'react';
import {
  addToast,
  resizeImageToCoverSize,
  Text,
  ToastIndicator,
  useDocumentEventListener,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import { v4 as uuid } from 'uuid';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import {
  filterExists,
  getLayerOrderKey,
} from '../../../../../../shared/js-utils/src';
import { is3DModelFile } from '../utils/meshHelpers';
import {
  LayerPayload,
  prepare3DLayerActionPayload,
} from '../../lib/actions/drawing/addLayer';

export const WorkbenchStudioFileDropper = ({
  drawing,
  activeLayerId,
  hasPrompt,
  handleAction,
  setActiveLayerId,
  triggerAutoPrompt,
}: {
  drawing: Drawing2dStudio;
  activeLayerId: string | undefined;
  hasPrompt: boolean;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  triggerAutoPrompt: (delay: number | undefined) => Promise<void>;
}) => {
  const [isDroppingFiles, setIsDroppingFiles] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleImportFiles = useStableCallback(async (files: File[]) => {
    setLoading(true);

    const newOrderKeys = [
      {
        orderKey: getLayerOrderKey(drawing.layers.nodes, activeLayerId),
        id: uuid(),
      },
    ];
    try {
      const layers: {
        layer: LayerPayload;
      }[] = (
        await Promise.all(
          files.map(async (file) => {
            try {
              if (file.type.includes('image')) {
                const image = await resizeImageToCoverSize(
                  file,
                  drawing.width,
                  drawing.height
                );
                const { id, orderKey } = newOrderKeys[newOrderKeys.length - 1];
                newOrderKeys.push({
                  orderKey: getLayerOrderKey(
                    [...drawing.layers.nodes, ...newOrderKeys],
                    id
                  ),
                  id: uuid(),
                });
                return {
                  layer: {
                    id,
                    name: file.name,
                    visible: true,
                    opacity: 1,
                    blendMode: 'normal',
                    fill: '',
                    image,
                    orderKey,
                  },
                };
              } else if (is3DModelFile(file.name)) {
                const orderKey = getLayerOrderKey(
                  drawing.layers.nodes,
                  activeLayerId
                );
                return await prepare3DLayerActionPayload(
                  file,
                  orderKey,
                  drawing.width,
                  drawing.height
                );
              }

              throw new Error();
            } catch (e) {
              addToast(
                `Cannot import file "${file.name}", please check it's a valid image or 3D model.`,
                {
                  type: 'danger',
                }
              );
            }
          })
        )
      ).filter(filterExists);

      if (layers.length === 0) {
        return;
      }

      layers.forEach(({ layer }) => {
        handleAction({
          type: 'addLayer',
          layer,
        });
      });

      setActiveLayerId(layers[layers.length - 1].layer.id);
    } finally {
      setLoading(false);
      setIsDroppingFiles(false);

      if (!hasPrompt) {
        triggerAutoPrompt(1000);
      }
    }
  });

  useDocumentEventListener('paste', (event) => {
    const items = event.clipboardData?.items;
    if (!items) return;

    const files = [...items]
      .filter((item) => item.kind === 'file')
      .map((item) => item.getAsFile())
      .filter(filterExists);
    if (files.length) {
      event.preventDefault();
      event.stopPropagation();
      handleImportFiles(files);
    }
  });

  useDocumentEventListener('dragover', (e) => {
    e.preventDefault(); // needed for drop event to fire
    setIsDroppingFiles(true);
  });
  useDocumentEventListener('dragleave', () => {
    setIsDroppingFiles(false);
  });
  useDocumentEventListener(
    'drop',
    () => {
      setIsDroppingFiles(false);
    },
    { capture: true } // capturing here to also receive the drop event even if another element catches it
  );
  useDocumentEventListener('drop', (e) => {
    // this event will be triggered if no other element catches the drop event
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer?.items
      ? [...e.dataTransfer.items]
          .map((item) => {
            if (item.kind === 'file') {
              const file = item.getAsFile();
              return file;
            }
          })
          .filter(filterExists)
      : e.dataTransfer?.files
      ? [...e.dataTransfer.files]
      : [];
    handleImportFiles(files);
  });

  return (
    <>
      <Container
        $active={isDroppingFiles}
        onClick={() => setIsDroppingFiles(false)} // if for any reason we missed the dragleave or drop event, let the user click anywhere to reset the state
      >
        <Text type="h1">Drop file(s)</Text>
      </Container>
      {loading && <ToastIndicator text="Importing files" type="loading" />}
    </>
  );
};

const Container = styled.div<{ $active: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
  z-index: 10000000;
  transition: 0.5s opacity ease;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;
