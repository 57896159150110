import { useState } from 'react';
import {
  addToast,
  browseForFile,
  BrushIcon,
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalHeader,
  ModalTitle,
  resizeImageToCoverSize,
  resizeImageToPixelCount,
  Text,
  useDocumentEventListener,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { getFileImageInfo } from '../helpers';
import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../../constants';
import { v4 as uuid } from 'uuid';
import { filterExists, genOrderKeys } from '@vizcom/shared/js-utils';
import { LayerPayload } from '../../lib/actions/drawing/addLayer';

const DEFAULT_SIZES = [
  {
    width: 1920,
    height: 1080,
    label: 'Landscape',
  },
  {
    width: 1080,
    height: 1920,
    label: 'Portrait',
  },
  {
    width: 1080,
    height: 1080,
    label: 'Square',
  },
];

export const NewDrawingModal = ({
  drawing,
  handleAction,
  handleInitiateDrawing,
}: {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  handleInitiateDrawing: (autoPromptDelay: number) => void;
}) => {
  const [open, setOpen] = useState(true);
  const [selectedSize, setSelectedSize] = useState(DEFAULT_SIZES[0]);

  const closeNewDrawingModal = async (o: boolean) => {
    handleAction(
      {
        type: 'updateDrawingSize',
        width: drawing.width,
        height: drawing.height,
      },
      {
        skipHistory: true,
      }
    );
    setOpen(o);
    handleInitiateDrawing(60000);
  };

  const onSelectSize = async (size: { width: number; height: number }) => {
    handleAction(
      {
        type: 'updateDrawingSize',
        width: size.width,
        height: size.height,
      },
      {
        skipHistory: true,
      }
    );

    setOpen(false);
    handleInitiateDrawing(60000);
  };

  const handleImportFiles = useStableCallback(async (files: File[]) => {
    const [firstFile, ...rest] = files;

    // only used to check if the image is a valid image
    try {
      await getFileImageInfo(firstFile);
    } catch (e) {
      addToast(
        `Cannot import file "${firstFile.name}", please check it's a valid image`,
        {
          type: 'danger',
        }
      );
      throw e;
    }

    const layerId = uuid();
    const { width, height, image } = await resizeImageToPixelCount(
      firstFile,
      WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
    );
    const orderKeys = genOrderKeys(files.length);

    const imageLayer = {
      layer: {
        id: uuid(),
        name: firstFile.name,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        orderKey: orderKeys[0],
        image,
      },
    };

    const additionalLayers: { layer: LayerPayload }[] = (
      await Promise.all(
        rest.map(async (file) => {
          if (file.type.includes('image')) {
            let image;
            try {
              image = await resizeImageToCoverSize(file, width, height);
            } catch (e) {
              addToast(
                `Cannot import file "${file.name}", please check it's a valid image`,
                {
                  type: 'danger',
                }
              );
              return null;
            }
            const id = uuid();
            return {
              layer: {
                id: id,
                name: file.name,
                visible: true,
                opacity: 1,
                blendMode: 'normal',
                fill: '',
                orderKey: orderKeys[rest.indexOf(file) + 1],
                image,
              },
              anchor: layerId,
            };
          } else {
            addToast(
              `Cannot import file "${file.name}", please check it's a valid image`,
              {
                type: 'danger',
              }
            );
          }
        })
      )
    ).filter(filterExists);

    handleAction(
      {
        type: 'updateDrawingSize',
        width,
        height,
      },
      {
        skipHistory: true,
      }
    );
    drawing.layers.nodes.forEach((l) => {
      handleAction(
        {
          type: 'deleteLayer',
          id: l.id,
        },
        {
          skipHistory: true,
        }
      );
    });

    [imageLayer, ...additionalLayers].forEach(({ layer }) => {
      handleAction(
        {
          type: 'addLayer',
          layer,
        },
        {
          skipHistory: true,
        }
      );
    });

    handleInitiateDrawing(1000);
    setOpen(false);
  });

  const onBrowseImage = async () => {
    const file = await browseForFile({
      accept: 'image/*',
    });
    await handleImportFiles([file]);
  };

  useDocumentEventListener('paste', (event) => {
    const items = event.clipboardData?.items;
    if (!items) return;

    const files = [...items]
      .filter((item) => item.kind === 'file')
      .map((item) => item.getAsFile())
      .filter(filterExists);
    if (files.length) {
      event.preventDefault();
      event.stopPropagation();
      handleImportFiles(files);
    }
  });

  useDocumentEventListener('dragover', (e) => {
    e.preventDefault(); // needed for drop event to fire
  });
  useDocumentEventListener('drop', (e) => {
    // this event will be triggered if no other element catches the drop event
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer?.items
      ? [...e.dataTransfer.items]
          .map((item) => {
            if (item.kind === 'file') {
              const file = item.getAsFile();
              return file;
            }
          })
          .filter(filterExists)
      : e.dataTransfer?.files
      ? [...e.dataTransfer.files]
      : [];
    handleImportFiles(files);
  });

  return (
    <Modal
      isOpen={open}
      setIsOpen={closeNewDrawingModal}
      style={{ padding: '25px 27px' }}
    >
      <ModalHeader>
        <StyledIconContainer>
          <BrushIcon />
        </StyledIconContainer>
        <ModalTitle style={{ fontSize: '32px' }}>Studio</ModalTitle>
        <ModalCloseButton color="#FAFAFA" />
      </ModalHeader>
      <SizesContainer>
        {DEFAULT_SIZES.map((size, i) => (
          <SizeContainer
            key={i}
            onClick={() => setSelectedSize(size)}
            onDoubleClick={() => onSelectSize(size)}
            $active={selectedSize === size}
          >
            <SizePreviewContainer>
              <SizePreview
                style={{
                  width: size.width / 48,
                  height: size.height / 48,
                }}
              />
            </SizePreviewContainer>
            <Text type="h1">{size.label}</Text>
            <Text type="b2" color="info">
              {size.width} x {size.height}
            </Text>
          </SizeContainer>
        ))}
      </SizesContainer>
      <ModalActions style={{ gap: '16px' }}>
        <Button variant="action2" onClick={onBrowseImage}>
          Upload an image
        </Button>
        <Button
          onClick={() =>
            onSelectSize({
              width: selectedSize.width,
              height: selectedSize.height,
            })
          }
        >
          Create
        </Button>
      </ModalActions>
    </Modal>
  );
};

const UploadImageButton = styled(Button)`
  background-color: #2f2f31;

  :hover {
    background-color: #3c3c3e;
  }
`;

const StyledIconContainer = styled.div`
  background: #368ce4;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: 0px 0.5px 1px 0px #ffffffb0 inset;
  box-shadow: 0px 2px 4px 0px #0000007a;
  padding: 6.5px 8px;
  color: white;
  margin-right: 8px;
`;

const SizesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  align-items: stretch;
`;

const SizeContainer = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: center;
  gap: 8px;
  border: #39393a 1px solid;
  border-radius: 16px;
  padding: 26px 35px;
  background-color: ${(p) =>
    p.$active ? p.theme.secondary.hover : 'transparent'};
`;
const SizePreviewContainer = styled.div`
  flex: 1;

  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all ease;
`;
const SizePreview = styled.div`
  box-shadow: 0 0 0 1px white;
  border-radius: ${({ theme }) => theme.borderRadius.subtle};
`;
