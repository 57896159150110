import styled from 'styled-components';
import { Button, Tooltip } from '@vizcom/shared-ui-components';
import { Link } from 'react-router-dom';
import { Folder, Team } from '@vizcom/shared/data-access/graphql';
import { paths } from '@vizcom/shared-utils-paths';
import { useState } from 'react';
import { CreateFolderModal } from './CreateFolderModal';

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

interface HeaderActionsProps {
  isTeamPage?: boolean;
  newPath: string;
  team?: Team;
  folder: Folder;
  isDraftsPage?: boolean;
}

export const HeaderActions = ({
  isTeamPage,
  newPath,
  team,
  folder,
  isDraftsPage,
}: HeaderActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const NewFileButton = folder.isEditable ? (
    <Button
      as={Link}
      to={isDraftsPage ? paths.workbench.new(folder.id) : newPath}
      size="M"
      variant="primary"
      style={{
        textAlign: 'center',
        height: '100%',
      }}
    >
      New file
    </Button>
  ) : (
    <Tooltip
      position="left"
      tip="You don't have the permissions to create a new file."
    >
      <Button
        disabled={true}
        size="M"
        variant="primary"
        style={{
          textAlign: 'center',
          height: '100%',
        }}
      >
        New file
      </Button>
    </Tooltip>
  );

  const AddFolderButton = folder.isEditable ? (
    <Button size="M" variant="secondary" onClick={() => setIsOpen(true)}>
      New folder
    </Button>
  ) : (
    <Tooltip
      position="left"
      tip="You don't have the permissions to create a new folder."
    >
      <Button disabled={true} size="M" variant="secondary">
        New folder
      </Button>
    </Tooltip>
  );

  const InviteMembersButton = (
    <Button
      as={Link}
      to={
        team && team.globalInOrganization
          ? paths.settings.organization.membersInvite(
              team.organization ? team.organization.id : ''
            )
          : paths.settings.organization.teamInvite(
              team?.organization ? team.organization.id : '',
              team ? team.id : ''
            )
      }
      variant="secondary"
      size="M"
    >
      Invite Members
    </Button>
  );

  return (
    <>
      <Container>
        {isTeamPage && team?.currentUserRole === 'ADMIN' && InviteMembersButton}
        {AddFolderButton}
        {NewFileButton}
      </Container>
      <CreateFolderModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        folderId={folder.id}
      />
    </>
  );
};
