import { ComponentProps, ComponentType } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  CubeToolbarIcon,
  MixToolbarIcon,
  PaletteToolbarIcon,
  PromptToolbarIcon,
  SketchToolbarIcon,
  Text,
} from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { getElementDefaultSize } from '../utils/getElementDefaultSize';
import {
  OnboardingStepName,
  OnboardingTooltip,
} from '@vizcom/shared-ui-components';
import { ImageInferenceType } from '../../../../../../shared/data-access/graphql/src';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';

export const ToolboxButton = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.surface.e1};
  border-radius: ${(p) => p.theme.borderRadius.default};
  padding: 14px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: grab;
  transition: 0.1s background-color ease, 0.1s box-shadow ease,
    0.5s opacity ease;
  touch-action: none;
  user-select: none;
  &:hover {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.icon.default};
    background-color: ${(p) => p.theme.surface.e2};
  }
`;

const CreateImageButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <OnboardingTooltip
    name={OnboardingStepName.InsertImage}
    previousStep={OnboardingStepName.ClickInsert}
    title="Drag the image block onto the canvas."
    show={props.toolboxOpen}
  >
    <ToolboxButton {...props}>
      <SketchToolbarIcon />
      <Text block type="sh1">
        Sketch
      </Text>
    </ToolboxButton>
  </OnboardingTooltip>
);

const CreateImg2ImgButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <PromptToolbarIcon />
    <Text block type="sh1">
      Prompt
    </Text>
  </ToolboxButton>
);

const CreateCompositeSceneButton = (
  props: ComponentProps<typeof ToolboxButton>
) => (
  <ToolboxButton {...props}>
    <CubeToolbarIcon />
    <Text block type="sh1">
      3D
    </Text>
  </ToolboxButton>
);

const CreateMixButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <MixToolbarIcon />
    <Text block type="sh1">
      Mix
    </Text>
  </ToolboxButton>
);

const StyledChip = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 4px;
  background: #4c4cef;
  font-size: 8px;
  font-weight: 700;
  color: #f8f8f8;
  padding: 2px;
`;

const CreatePaletteButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <PaletteToolbarIcon />
    <Text type="sh1">Palette</Text>
    <StyledChip>BETA</StyledChip>
  </ToolboxButton>
);

export const elementCreators: {
  featureFlag?: string;
  Button: ComponentType<{
    toolboxOpen?: boolean;
  }>;
  action: (
    position: { x: number; y: number; zIndex: number },
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
    completeOnboardingStep?: (step: OnboardingStepName) => void
  ) => string;
}[] = [
  {
    Button: CreateImg2ImgButton,
    action: (position, handleAction) => {
      const size = getElementDefaultSize('WorkbenchElementImg2Img');
      const elementId = uuidv4();

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementImg2Img',
            updatedAt: '0',
            id: elementId,
            width: size.x,
            height: size.y,
            ...position,
            prompt: '',
            sourceImageInfluence: 1,
            imageInferenceType: ImageInferenceType.Render,
            publicPaletteId: PublicPalette.generalV2,
          },
        ],
      });
      return elementId;
    },
  },
  {
    Button: CreateImageButton,
    action: (position, handleAction, completeOnboardingStep) => {
      const size = getElementDefaultSize('WorkbenchElementPlaceholder');
      const elementId = uuidv4();

      if (completeOnboardingStep) {
        completeOnboardingStep(OnboardingStepName.InsertImage);
      }

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementPlaceholder',
            updatedAt: '0',
            id: elementId,
            width: size.x,
            height: size.y,
            type: 'drawing',
            ...position,
          },
        ],
      });

      return elementId;
    },
  },
  {
    Button: CreatePaletteButton,
    action: (position, handleAction) => {
      const elementId = uuidv4();
      const size = getElementDefaultSize('WorkbenchElementPalette');

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementPalette',
            id: elementId,
            width: size.x,
            height: size.y,
            name: '',
            status: 'idle',
            tags: [],
            sourceImages: {
              nodes: [],
            },
            ...position,
          },
        ],
      });
      return elementId;
    },
    featureFlag: 'WORKBENCH_PALETTE_TRAINING',
  },
  {
    Button: CreateMixButton,
    action: (position, handleAction) => {
      const elementId = uuidv4();
      const size = getElementDefaultSize('WorkbenchElementMix');

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementMix',
            updatedAt: '0',
            id: elementId,
            width: size.x,
            height: size.y,
            ...position,
            sourceDrawings: [],
          },
        ],
      });
      return elementId;
    },
  },
  {
    featureFlag: 'COMPOSITE_SCENE',
    Button: CreateCompositeSceneButton,
    action: (position, handleAction) => {
      const size = getElementDefaultSize('CompositeScene');
      const elementId = uuidv4();

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'CompositeScene',
            id: elementId,
            width: size.x,
            height: size.y,
            ...position,
          },
        ],
      });
      return elementId;
    },
  },
];
