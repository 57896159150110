import { v4 as uuid } from 'uuid';

import {
  DuplicateIcon,
  ExportIcon,
  MergeIcon,
  MoveToBottomIcon,
  MoveToTopIcon,
  RenameIcon,
  TrashIcon,
  VisibleIcon,
  ContextMenuDivider,
  TooltipMenu,
  TooltipMenuItem,
  downloadFile,
  downloadFileAtUrl,
  imageUrlToBlob,
  DuplicateToWorkbenchIcon,
  Chip,
  CopyContentIcon,
  imageDataToBlob,
  addToast,
  Convert2dTo3dIcon,
} from '@vizcom/shared-ui-components';
import { useState } from 'react';
import { Layout } from './types';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useLayersCompositor } from './DrawingCompositor/LayersCompositor/context';
import {
  assertExists,
  genBottomOrderKey,
  genTopOrderKey,
  getLayerOrderKey,
  sortByOrderKey,
} from '@vizcom/shared/js-utils';
import {
  OrganizationSubscriptionPlan,
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  publishTrackingEvent,
  ImageTo3dQualityType,
} from '@vizcom/shared/data-access/graphql';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { copyImageToClipboard } from '../../lib/drawingUtils';
import { useSelectionApiStore } from './selection/useSelectionApi';
import { cachedLayerImagesByUrl } from '../../lib/actions/drawing/updateLayer';
import { StudioEventName } from 'libs/shared/data-shape/src/lib/tracking/StudioTrackingEventSchema';
import { Layer3dMeshExportMenu } from './Layer3dMeshExportMenu';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';

export const LayerContextMenu = ({
  drawing,
  layer,
  isLast,
  activeLayer,
  showContextMenu,
  setViewerVisibility,
  setActiveLayer,
  handleAction,
  onCreateDrawingFromImage,
  setIsEditingName,
  setShowContextMenu,
}: {
  drawing: Drawing2dStudio;
  layer: Drawing2dStudio['layers']['nodes'][0];
  isLast: boolean;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  activeLayer: string | undefined;
  showContextMenu: boolean;
  setActiveLayer: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  onCreateDrawingFromImage: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    },
    name?: string
  ) => void;
  setIsEditingName: React.Dispatch<React.SetStateAction<boolean>>;
  setShowContextMenu: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const [showExportMenu, setShowExportMenu] = useState(false);
  const layersCompositor = useLayersCompositor();
  const isViewer = useIsWorkbenchViewer();
  const selectionApiStore = useSelectionApiStore();
  const id = layer.id;

  const exportLayer = async () => {
    const isLayer3d = layer.meshPath || layer.metadata3D?.mesh;

    if (isLayer3d) {
      setShowExportMenu(true);
    } else if (layer.imagePath) {
      downloadFile(layer.imagePath, layer.name, 'png');
      trackEvent('Image Export', { type: 'exportLayersImage' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: 'PNG',
        },
      });
    }
  };

  const renameLayer = () => {
    setIsEditingName(true);
  };

  const removeLayer = () => {
    if (activeLayer && activeLayer.split('/').length > 1) {
      handleAction({
        type: 'updateBulkLayers',
        deletedLayerIds: activeLayer.split('/'),
      });
      return;
    }

    handleAction({
      type: 'deleteLayer',
      id,
    });
  };

  const duplicateLayer = async (id: string) => {
    const newLayer = {
      ...layer,
      id: uuid(),
      name: `${layer.name} copy`,
      image: layer.imagePath,
      orderKey: getLayerOrderKey(drawing.layers.nodes, id),
    };
    handleAction({
      type: 'addLayer',
      layer: newLayer,
    });
  };

  const duplicateMultipleLayers = async () => {
    const selectedLayers = activeLayer?.split('/');
    if (!selectedLayers) return;

    const newLayersCache = [] as Drawing2dStudio['layers']['nodes'];
    const newLayers = selectedLayers.map((id) => {
      const layer = drawing.layers.nodes.find((l) => l.id === id);
      assertExists(layer, 'Could not find layer to duplicate');

      const orderKey = getLayerOrderKey(
        [...drawing.layers.nodes, ...newLayersCache],
        selectedLayers[0]
      );
      const newLayer = {
        ...layer,
        id: uuid(),
        name: `${layer.name} copy`,
        image: layer.imagePath,
        orderKey,
      };

      newLayersCache.push(newLayer);

      return newLayer;
    });

    handleAction({
      type: 'updateBulkLayers',
      newLayers,
    });
  };

  const mergeDown = async () => {
    const sortedLayers = sortByOrderKey(drawing.layers.nodes);
    const currentLayerIndex = sortedLayers.findIndex((l) => l.id === id);
    const nextLayer = sortedLayers[currentLayerIndex + 1];

    assertExists(nextLayer, 'Could not find next layer to merge down');

    handleAction({
      type: 'mergeLayers',
      targetLayerId: nextLayer.id,
      sourceLayersId: [layer.id],
      mergedImage: layersCompositor.getCompositedImage({
        onlyDisplayLayersIds: [nextLayer.id, layer.id],
        forceFullOpacityForLayersId: [nextLayer.id],
      }),
    });
  };

  const mergeSelected = async () => {
    const selectedLayers = activeLayer?.split('/');
    if (!selectedLayers) return;

    const sortedLayers = sortByOrderKey(
      drawing.layers.nodes.filter((l) => selectedLayers.includes(l.id))
    );
    const target = sortedLayers.pop();

    handleAction({
      type: 'mergeLayers',
      targetLayerId: target!.id,
      sourceLayersId: sortedLayers.map((l) => l.id),
      mergedImage: layersCompositor.getCompositedImage({
        onlyDisplayLayersIds: selectedLayers,
        forceFullOpacityForLayersId: [target!.id],
      }),
    });
  };

  const moveLayerToTop = () => {
    if (activeLayer && activeLayer.split('/').length > 1) {
      const layers = activeLayer.split('/').map((id) => {
        const layer = drawing.layers.nodes.find((l) => l.id === id);
        assertExists(layer, 'Could not find layer to move to top');
        return layer;
      });

      const newLayers = [] as {
        id: string;
        orderKey: string;
      }[];

      const movedLayers = sortByOrderKey(layers)
        .reverse()
        .map((layer, i) => {
          const orderKey = genTopOrderKey([
            ...drawing.layers.nodes,
            ...newLayers,
          ]);
          const layerUpdate = {
            id: layer.id,
            orderKey: orderKey + i,
          };
          newLayers.push(layerUpdate);
          return layerUpdate;
        });

      handleAction({
        type: 'updateBulkLayers',
        layerUpdates: movedLayers,
      });

      return;
    }

    handleAction({
      type: 'updateLayer',
      id,
      data: {
        orderKey: genTopOrderKey(drawing.layers.nodes),
      },
    });
  };

  const moveLayerToBottom = (id: string) => {
    if (activeLayer && activeLayer.split('/').length > 1) {
      const layers = activeLayer.split('/').map((id) => {
        const layer = drawing.layers.nodes.find((l) => l.id === id);
        assertExists(layer, 'Could not find layer to move to bottom');
        return layer;
      });

      const newLayers = [] as {
        id: string;
        orderKey: string;
      }[];

      const movedLayers = sortByOrderKey(layers).map((layer, i) => {
        const orderKey = genBottomOrderKey([
          ...drawing.layers.nodes,
          ...newLayers,
        ]);
        const layerUpdate = {
          id: layer.id,
          orderKey: orderKey + i,
        };
        newLayers.push(layerUpdate);
        return layerUpdate;
      });

      handleAction({
        type: 'updateBulkLayers',
        layerUpdates: movedLayers,
      });

      return;
    }

    handleAction({
      type: 'updateLayer',
      id,
      data: {
        orderKey: genBottomOrderKey(drawing.layers.nodes),
      },
    });
  };

  const handleGenerateMeshFromLayer = () => {
    if (!layer.imagePath) {
      return addToast(
        'Your layer is not ready to be converted to a 3D model. Please add an image to your layer first.',
        {
          type: 'danger',
        }
      );
    }

    const id = uuid();
    handleAction({
      type: 'createLayer3dFromDrawing',
      id,
      name: `${layer.name} - 3D`,
      sourceLayerId: layer.id,
      qualityType: ImageTo3dQualityType.Basic,
    });
    setActiveLayer(id);
  };

  const handleDuplicateInWorkbench = async () => {
    if (!layer.imagePath) {
      return addToast(
        `This layer cannot be duplicated in workbench because it's empty`
      );
    }

    const sourceImage =
      layer.imagePath instanceof ImageData
        ? await imageDataToBlob(layer.imagePath)
        : typeof layer.imagePath === 'string'
        ? await imageUrlToBlob(layer.imagePath)
        : layer.imagePath;
    onCreateDrawingFromImage(sourceImage, undefined, layer.name);
    addToast('This layer has been duplicated in workbench');
    setShowContextMenu(false);
  };

  const handleCopyRawLayerImage = () => {
    if (!layer.imagePath) {
      addToast('This layer is empty');
    }
    copyImageToClipboard(
      layer.imagePath,
      selectionApiStore.getState().getSelectionImage()
    );
  };

  const getTooltipMenuContent = () => {
    if (isViewer) {
      return (
        <>
          <TooltipMenuItem
            icon={<VisibleIcon />}
            label="Show/hide layer"
            onClick={() => {
              setViewerVisibility((prev) => ({
                ...prev,
                [id]: prev[id] !== undefined ? !prev[id] : !layer.visible,
              }));
              setShowContextMenu(false);
            }}
          />
          <TooltipMenuItem
            icon={<ExportIcon />}
            label="Export"
            onClick={() => {
              exportLayer();
              setShowContextMenu(false);
            }}
          />
        </>
      );
    } else if (activeLayer !== id && activeLayer?.split('/').includes(id)) {
      return (
        <>
          <TooltipMenuItem
            icon={<DuplicateIcon />}
            label="Duplicate"
            onClick={(e) => {
              e.stopPropagation();
              duplicateMultipleLayers();
              setShowContextMenu(false);
            }}
          />
          <TooltipMenuItem
            icon={<VisibleIcon />}
            label="Hide layers"
            onClick={(e) => {
              e.stopPropagation();
              handleAction?.({
                type: 'updateBulkLayers',
                layerUpdates: activeLayer.split('/').map((id) => ({
                  id,
                  visible: false,
                })),
              });
              setShowContextMenu(false);
            }}
          />
          <ContextMenuDivider />
          <TooltipMenuItem
            icon={<MergeIcon />}
            label="Merge layers"
            onClick={async (e) => {
              e.stopPropagation();
              setShowContextMenu(false);
              await mergeSelected();
            }}
          />
          <TooltipMenuItem
            icon={<MoveToTopIcon />}
            label="Move to top"
            onClick={(e) => {
              e.stopPropagation();
              moveLayerToTop();
              setShowContextMenu(false);
            }}
          />
          <TooltipMenuItem
            icon={<MoveToBottomIcon />}
            label="Move to bottom"
            onClick={(e) => {
              e.stopPropagation();
              moveLayerToBottom(id);
              setShowContextMenu(false);
            }}
          />
          <ContextMenuDivider />
          <TooltipMenuItem
            icon={<TrashIcon />}
            label="Delete"
            onClick={(e) => {
              e.stopPropagation();
              removeLayer();
              setShowContextMenu(false);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <TooltipMenuItem
            icon={<CopyContentIcon />}
            label="Copy image"
            onClick={handleCopyRawLayerImage}
          />
          <TooltipMenuItem
            icon={<RenameIcon />}
            label="Rename"
            onClick={() => {
              renameLayer();
              setShowContextMenu(false);
            }}
          />
          <TooltipMenuItem
            icon={<DuplicateIcon />}
            label="Duplicate"
            onClick={() => {
              duplicateLayer(id);
              setShowContextMenu(false);
            }}
          />
          <ContextMenuDivider />
          {isLast || (
            <TooltipMenuItem
              icon={<MergeIcon />}
              label="Merge down"
              onClick={async () => {
                setShowContextMenu(false);
                await mergeDown();
              }}
            />
          )}
          <TooltipMenuItem
            icon={<MoveToTopIcon />}
            label="Move to top"
            onClick={() => {
              moveLayerToTop();
              setShowContextMenu(false);
            }}
          />
          <TooltipMenuItem
            icon={<MoveToBottomIcon />}
            label="Move to bottom"
            onClick={() => {
              moveLayerToBottom(id);
              setShowContextMenu(false);
            }}
          />
          <ContextMenuDivider />
          {!layer.metadata3D && (
            <TooltipMenuItem
              icon={<Convert2dTo3dIcon />}
              label="Generate 3D model from layer"
              onClick={handleGenerateMeshFromLayer}
            />
          )}
          {!layer.metadata3D && (
            <TooltipMenuItem
              icon={<DuplicateToWorkbenchIcon />}
              label="Duplicate in workbench"
              onClick={handleDuplicateInWorkbench}
            />
          )}
          <TooltipMenuItem
            style={{
              minHeight: 35,
              paddingTop: 0,
              paddingBottom: 0,
            }}
            icon={<ExportIcon />}
            label={
              layer.metadata3D ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ flex: 1, marginRight: 10 }}>
                    Export 3D model
                  </span>
                  {drawing.workbench?.folder?.organization?.subscriptionPlan ===
                    OrganizationSubscriptionPlan.Free && (
                    <Chip variant="primary">Pro</Chip>
                  )}
                </div>
              ) : (
                'Export'
              )
            }
            onClick={() => {
              exportLayer();
              setShowContextMenu(false);
            }}
          />
          <TooltipMenuItem
            icon={<TrashIcon />}
            label="Delete"
            onClick={() => {
              removeLayer();
              setShowContextMenu(false);
            }}
          />
        </>
      );
    }
  };

  const layer3dMeshExportMenu = (
    <Layer3dMeshExportMenu
      isOpen={showExportMenu}
      onClose={() => setShowExportMenu(false)}
      layer={layer}
      subscriptionPlan={
        drawing.workbench?.folder?.organization?.subscriptionPlan ??
        OrganizationSubscriptionPlan.Free
      }
    />
  );

  return (
    <>
      <TooltipMenu
        direction={layout === 'default' ? 'left' : 'right'}
        isOpen={showContextMenu}
        onClose={() => setShowContextMenu(false)}
        customHorizontalMargin={30}
        customVerticalMargin={-5}
      >
        {getTooltipMenuContent()}
      </TooltipMenu>
      {layer3dMeshExportMenu}
    </>
  );
};
