import styled from 'styled-components';

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width="5rem"
    height="5rem"
  >
    <circle cx="75" cy="50" r="6.39718">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="-0.875s"
      ></animate>
    </circle>
    <circle cx="67.678" cy="67.678" r="4.8">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="-0.75s"
      ></animate>
    </circle>
    <circle cx="50" cy="75" r="4.8">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="-0.625s"
      ></animate>
    </circle>
    <circle cx="32.322" cy="67.678" r="4.8">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="-0.5s"
      ></animate>
    </circle>
    <circle cx="25" cy="50" r="4.8">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="-0.375s"
      ></animate>
    </circle>
    <circle cx="32.322" cy="32.322" r="4.80282">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="-0.25s"
      ></animate>
    </circle>
    <circle cx="50" cy="25" r="6.40282">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="-0.125s"
      ></animate>
    </circle>
    <circle cx="67.678" cy="32.322" r="7.99718">
      <animate
        attributeName="r"
        values="4.8;4.8;8;4.8;4.8"
        keyTimes="0;0.1;0.2;0.3;1"
        dur="1s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
  </svg>
);

const Wrapper = styled.div`
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const Loading = () => {
  return (
    <Wrapper>
      <Container>
        <Icon />
        <div>Loading...</div>
      </Container>
    </Wrapper>
  );
};
