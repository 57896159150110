import {
  Button,
  PlusIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  browseForFile,
  styledScrollbar,
  VizcomLogoAnimated,
  resizeImageToCoverSize,
  LoadingLogoInset,
  useKeyboardShortcut,
  addToast,
  formatErrorMessage,
  Import3DModelIcon,
  ImportImageIcon,
} from '@vizcom/shared-ui-components';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import {
  useMeshForDrawingId,
  usePublicResourcesMesh,
} from '@vizcom/shared/data-access/graphql';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useState } from 'react';
import { DEFAULT_LAYER_3D_CAMERA_VIEW } from '../DrawingCompositor/Layer3D/types';
import { genTopOrderKey, getLayerOrderKey } from '@vizcom/shared/js-utils';
import { prepare3DLayerActionPayload } from '../../../lib/actions/drawing/addLayer';

export const InsertButton = (props: {
  drawing: Drawing2dStudio;
  activeLayerId: string | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveLayerId: (id: string | undefined) => void;
}) => {
  const [loadingInsertMesh, setLoadingInsertMesh] = useState(false);

  const handleUploadFile = async () => {
    const file = await browseForFile({
      accept: 'image/*',
    });
    if (!file) {
      return;
    }
    props.setShowOverlay(true);

    const image = await resizeImageToCoverSize(
      file,
      props.drawing.width,
      props.drawing.height
    );
    const id = uuid();
    props.handleAction({
      type: 'addLayer',
      layer: {
        id: id,
        name: file.name,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        orderKey: getLayerOrderKey(
          props.drawing.layers.nodes,
          props.activeLayerId
        ),
        image,
      },
    });
    props.setActiveLayerId(id);
    props.setShowOverlay(false);
  };

  const handleUpload3dModel = async () => {
    const file = await browseForFile({
      accept: '.fbx,.glb,.gltf,.obj,.stl',
    });

    if (!file) return;

    props.setShowOverlay(true);
    setLoadingInsertMesh(true);

    try {
      const orderKey = getLayerOrderKey(
        props.drawing.layers.nodes,
        props.activeLayerId
      );

      const payload = await prepare3DLayerActionPayload(
        file,
        orderKey,
        props.drawing.width,
        props.drawing.height
      );
      props.handleAction(payload);
      props.setActiveLayerId(payload.layer.id);
    } catch (e) {
      addToast(`Error while importing 3D model ${file.name}`, {
        secondaryText: formatErrorMessage(e),
        type: 'danger',
      });
    } finally {
      setLoadingInsertMesh(false);
      props.setShowOverlay(false);
    }
  };

  useKeyboardShortcut('i', handleUploadFile);
  useKeyboardShortcut('p', handleUpload3dModel);

  return (
    <RichTooltip trigger={'click'}>
      <RichTooltipTrigger>
        <ToolbarButton variant="transparent" size="M">
          <PlusIcon />
          <Text type="button2" style={{ color: 'inherit' }}>
            Insert
          </Text>
        </ToolbarButton>
      </RichTooltipTrigger>
      <RichTooltipContent
        closeOnClick
        hideArrow
        $backgroundSurface="e0"
        style={{
          maxHeight: 440,
          overflow: 'hidden',
          padding: 0,
          borderRadius: 16,
        }}
      >
        <InsertActionButtonContainer>
          <InsertActionButton variant="transparent" onClick={handleUploadFile}>
            <ImportImageIcon />
            <Text
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Upload an image <Text color="info">I</Text>
            </Text>
          </InsertActionButton>
          <InsertActionButton
            variant="transparent"
            onClick={handleUpload3dModel}
            disabled={loadingInsertMesh}
          >
            <Import3DModelIcon />
            <Text
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Upload a 3D model <Text color="info">P</Text>
            </Text>
            <LoadingLogoInset active={loadingInsertMesh} />
          </InsertActionButton>
        </InsertActionButtonContainer>
        <Divider />
        <InsertMeshesSection
          drawing={props.drawing}
          handleAction={props.handleAction}
          setActiveLayerId={props.setActiveLayerId}
        />
      </RichTooltipContent>
    </RichTooltip>
  );
};

const InsertMeshesSection = (props: {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
}) => {
  const { data: meshes, fetching } = useMeshForDrawingId(props.drawing.id);
  const { data: publicMeshes, fetching: fetchingPublicMeshes } =
    usePublicResourcesMesh();

  const handleAddMesh = (meshId: string) => {
    const id = uuid();
    props.handleAction({
      type: 'addLayer',
      layer: {
        id: id,
        name: `3D model`,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        orderKey: genTopOrderKey(props.drawing.layers.nodes),
        metadata3D: {
          mesh: meshId,
          view: DEFAULT_LAYER_3D_CAMERA_VIEW,
        },
      },
    });
    props.setActiveLayerId(id);
  };

  if (fetching || fetchingPublicMeshes) {
    return (
      <LoadingContainer>
        <VizcomLogoAnimated />
      </LoadingContainer>
    );
  }

  return (
    <MeshesContainer>
      {meshes.map((mesh) => (
        <MeshButton
          variant="secondary"
          key={mesh.id}
          onClick={() => handleAddMesh(mesh.id)}
        >
          <img
            draggable={false}
            crossOrigin="anonymous"
            src={mesh.thumbnailPath}
            alt={mesh.name}
          />
        </MeshButton>
      ))}
      {publicMeshes.map((mesh) => (
        <MeshButton
          variant="secondary"
          key={mesh.id}
          onClick={() => handleAddMesh(mesh.id)}
        >
          <img
            draggable={false}
            crossOrigin="anonymous"
            src={mesh.thumbnailPath}
            alt={mesh.name}
          />
        </MeshButton>
      ))}
    </MeshesContainer>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ToolbarButton = styled(Button)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${({ theme }) => theme.text.info};

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background: ${({ theme }) => theme.surface.e1};
    color: ${({ theme }) => theme.white};
  }
`;

const InsertActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 8px 8px 12px 8px;
  gap: 12px;
`;

const InsertActionButton = styled(Button)`
  display: flex;
  gap: 7px;
  text-align: left;
  align-items: center;
  padding: 8px 16px;

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.surface.e1};
  }
`;

const MeshesContainer = styled.div`
  max-height: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  overflow-y: auto;
  padding: 20px 8px 8px;
  padding-right: 4px;
  scrollbar-gutter: stable;
  ${styledScrollbar}
`;

const MeshButton = styled(Button)`
  width: 108px;
  height: 112px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.surface.e1};
`;

const Divider = styled.div`
  background-color: ${(p) => p.theme.surface.e1};
  height: 1px;
`;
