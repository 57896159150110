import { memo } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  Button,
  FeatureFlagged,
  ImageIcon,
  OnboardingStepName,
  OnboardingTooltip,
  PlusIcon,
  RedoIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  SectionIcon,
  SelectIcon,
  TextIcon,
  UndoIcon,
} from '@vizcom/shared-ui-components';

import { WorkbenchToolbarContainer, WorkbenchToolbarDivider } from './styles';
import { useWorkbenchToolState } from './WorkbenchToolContext';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { Hotkey } from '../studio/style';

const ToolboxButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.text.info};
`;

interface WorkbenchToolbarProps {
  canUndo: boolean;
  canRedo: boolean;
  onOpenMobileUploadModal: () => void;
  handleToggleToolbox: () => void;
  handleUndo: () => void;
  handleRedo: () => void;
}

export const WorkbenchToolbar = memo(
  ({
    canUndo,
    canRedo,
    onOpenMobileUploadModal,
    handleToggleToolbox,
    handleUndo,
    handleRedo,
  }: WorkbenchToolbarProps) => {
    const theme = useTheme();
    const isViewer = useIsWorkbenchViewer();
    const { tool, setTool } = useWorkbenchToolState();

    if (isViewer) {
      return (
        <ToolbarContainer>
          <WorkbenchToolbarContainer>
            <RichTooltip trigger="hover" padding={11}>
              <RichTooltipTrigger>
                <ToolboxButton size="icon">
                  <SelectIcon />
                </ToolboxButton>
              </RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.white }}>
                <div>Select</div>
              </RichTooltipContent>
            </RichTooltip>

            <WorkbenchToolbarDivider />
            <div
              style={{
                display: 'flex',
                color: theme.text.info,
                alignItems: 'center',
                paddingRight: '8px',
              }}
            >
              View only
            </div>
          </WorkbenchToolbarContainer>
        </ToolbarContainer>
      );
    }

    return (
      <ToolbarContainer>
        <WorkbenchToolbarContainer>
          <OnboardingTooltip
            name={OnboardingStepName.ClickInsert}
            title="Click to insert a new element"
          >
            <ToolboxButton onClick={handleToggleToolbox} variant="transparent">
              <PlusIcon /> Insert
            </ToolboxButton>
          </OnboardingTooltip>
          <WorkbenchToolbarDivider />

          <RichTooltip trigger="hover" padding={11}>
            <RichTooltipTrigger>
              <ToolboxButton
                onClick={() => setTool('select')}
                variant={tool === 'select' ? 'primary' : 'transparent'}
                size="icon"
              >
                <SelectIcon />
              </ToolboxButton>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Select
            </RichTooltipContent>
          </RichTooltip>

          <WorkbenchToolbarDivider />

          <RichTooltip trigger="hover" padding={11}>
            <RichTooltipTrigger>
              <ToolboxButton
                onClick={() => setTool('text')}
                variant={tool === 'text' ? 'primary' : 'transparent'}
                size="icon"
              >
                <TextIcon />
              </ToolboxButton>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Text <Hotkey>T</Hotkey>
            </RichTooltipContent>
          </RichTooltip>

          <WorkbenchToolbarDivider />
          <FeatureFlagged flag="WORKBENCH_SECTION_ELEMENT">
            <RichTooltip trigger="hover" padding={11}>
              <RichTooltipTrigger>
                <ToolboxButton
                  onClick={() => setTool('section')}
                  variant={tool === 'section' ? 'primary' : 'transparent'}
                  size="icon"
                >
                  <SectionIcon />
                </ToolboxButton>
              </RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.white }}>
                Section
              </RichTooltipContent>
            </RichTooltip>

            <WorkbenchToolbarDivider />
          </FeatureFlagged>

          <FeatureFlagged flag="WORKBENCH_PHONE_REDIRECT_ELEMENT">
            <RichTooltip trigger="hover" padding={11}>
              <RichTooltipTrigger>
                <ToolboxButton
                  onClick={onOpenMobileUploadModal}
                  variant={tool === 'section' ? 'primary' : 'transparent'}
                  size="icon"
                >
                  <ImageIcon />
                </ToolboxButton>
              </RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.white }}>
                Scan with phone
              </RichTooltipContent>
            </RichTooltip>

            <WorkbenchToolbarDivider />
          </FeatureFlagged>

          <RichTooltip trigger="hover" padding={11}>
            <RichTooltipTrigger>
              <ToolboxButton
                onClick={handleUndo}
                size="icon"
                variant="transparent"
              >
                <UndoIcon
                  style={{
                    color: canUndo ? theme.icon.default : theme.text.disabled,
                  }}
                />
              </ToolboxButton>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Undo
            </RichTooltipContent>
          </RichTooltip>

          <RichTooltip trigger="hover" padding={11}>
            <RichTooltipTrigger>
              <ToolboxButton
                onClick={handleRedo}
                size="icon"
                variant="transparent"
              >
                <RedoIcon
                  style={{
                    color: canRedo ? theme.icon.default : theme.text.disabled,
                  }}
                />
              </ToolboxButton>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Redo
            </RichTooltipContent>
          </RichTooltip>
        </WorkbenchToolbarContainer>
      </ToolbarContainer>
    );
  }
);

const ToolbarContainer = styled.div`
  z-index: 10000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 1rem;
  left: max(50%, 480px);
  transform: translateX(-50%);
  gap: 16px;
  pointer-events: none;
`;
