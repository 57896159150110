import { useTheme } from 'styled-components';
import { useCameraZoom } from '../helpers';
import { MeshProps } from '@react-three/fiber';
import { memo, useRef } from 'react';
import { Vector3, Vector2, DoubleSide, Group } from 'three';
import { OutlinedSquareHandle, SegmentHandle } from './Handles';
import {
  WarpGeometry,
  WarpPoint,
} from '../studio/DrawingCompositor/LayerTransform/warp';
import { useWorkbenchStudioToolState } from '../studio/studioState';

const HANDLES_CLICK_THRESHOLD_PIXELS = 20; // add a margin around every interactable to make it easier to click on
const SIDE_LINE_WIDTH = 3;

interface ResizerPresenterProps {
  points: WarpPoint[];
  warpHandleMeshPropsGetter: (pointIndices: number[]) => any[];
  moveHandleMeshProps: MeshProps[];
  renderOrder?: number;
}

const va = new Vector2();
const vb = new Vector2();

export const TransformEnveloppeHelper = memo(
  ({
    points,
    warpHandleMeshPropsGetter,
    moveHandleMeshProps,
    renderOrder,
  }: ResizerPresenterProps) => {
    const zoom = useCameraZoom();
    const t = useTheme();

    const handleScaleDirections = [
      [-1, -1],
      [1, -1],
      [1, 1],
      [-1, 1],
    ];
    const edgesScaleDirections = [
      [0, -1],
      [1, 0],
      [0, 1],
      [-1, 0],
    ];

    //Adding a margin for the translation mesh.
    //for simplicity, margin is not parallel to the edges,
    //but at a fixed distance from the handles.
    const pointsWithMargin: WarpPoint[] = points.map((p, i) => {
      const prev = points[(i + 3) % 4];
      const next = points[(i + 1) % 4];
      va.set(p[0] - prev[0], p[1] - prev[1]);
      vb.set(next[0] - p[0], next[1] - p[1]);
      va.normalize();
      vb.normalize();
      const tangent = va.add(vb).normalize();
      return [
        p[0] - (tangent.y * HANDLES_CLICK_THRESHOLD_PIXELS) / zoom,
        p[1] + (tangent.x * HANDLES_CLICK_THRESHOLD_PIXELS) / zoom,
      ];
    });

    const yFlipSign = useWorkbenchStudioToolState().flipped ? -1 : 1;

    return (
      <group renderOrder={renderOrder}>
        <mesh userData={{ cursor: 'move' }} {...moveHandleMeshProps}>
          <WarpGeometry points={pointsWithMargin} />
          <meshBasicMaterial
            side={DoubleSide}
            color={t.primary.default}
            transparent
            opacity={0}
          />
        </mesh>

        {points.map((point, pointIndex) => {
          const position = new Vector3(point[0], point[1], 0);
          const [xDir, yDir] = handleScaleDirections[pointIndex];
          return (
            <group key={`${xDir}${yDir}`}>
              <OutlinedSquareHandle
                color={t.primary.default}
                position={position}
                scale={[1 / zoom, 1 / zoom, 1]}
                userData={{
                  cursor:
                    yFlipSign * xDir * yDir > 0 ? 'nesw-resize' : 'nwse-resize',
                }}
                renderOrder={2}
                {...warpHandleMeshPropsGetter([pointIndex])}
              />
            </group>
          );
        })}

        {points.map((point, pointIndex) => {
          const nextPointIndex = (pointIndex + 1) % 4;
          const next = points[nextPointIndex];
          const [xDir] = edgesScaleDirections[pointIndex];

          return (
            <SegmentHandle
              key={pointIndex}
              from={point}
              to={next}
              thickness={SIDE_LINE_WIDTH / zoom}
              color={t.primary.default}
              userData={{
                cursor: xDir ? 'ns-resize' : 'ew-resize',
              }}
              {...warpHandleMeshPropsGetter([pointIndex, nextPointIndex])}
            />
          );
        })}
      </group>
    );
  }
);
