import React, { useEffect, useState } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import {
  Button,
  CheckCircleIconRaw,
  CloseIconOrangeRaw,
  ErrorIconRaw,
  Modal,
  PlayCircleIconRaw,
  RetryIconRaw,
} from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { CustomText } from '../../utils/CustomText';
import { StaticSvg } from '../../utils/StaticSvg';
import { AnimatedLoadingSvg } from './AnimatedLoadingSvg';
import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import {
  PALETTE_COLOR,
  PALETTE_COLOR_DISABLED,
} from './WorkbenchElementPalette';
import styled, { useTheme } from 'styled-components';
import { CustomHtml } from '../../utils/CustomHtml';

interface PaletteStatusButtonProps {
  element: ClientSideWorkbenchElementPalette;
  width: number;
  height: number;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  paletteNameWidth: number;
}

export const PaletteStatusButton: React.FC<PaletteStatusButtonProps> = ({
  element,
  width,
  height,
  handleAction,
  paletteNameWidth,
}) => {
  const theme = useTheme();
  const [showCancel, setShowCancel] = useState(false);
  const [showRetry, setShowRetry] = useState(false);
  const [confirmCancelTraining, setConfirmCancelTraining] = useState(false);

  useEffect(() => {
    if (element.status !== 'training') {
      setConfirmCancelTraining(false);
    }
  }, [element.status]);

  const handleStatusButtonClick = async (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();

    if (element.status === 'failed') {
      handleAction({ type: 'trainPalette', id: element.id });
      setShowRetry(false);
    } else if (element.status === 'training') {
      setConfirmCancelTraining(true);
      setShowCancel(false);
    } else if (element.status === 'idle') {
      handleAction({ type: 'trainPalette', id: element.id });
    }
  };

  const getStatus = () => {
    switch (element.status) {
      case 'idle':
        return 'Train';
      case 'training':
        return 'Training';
      case 'ready':
        return 'Complete';
      case 'failed':
        return 'Failed';
      default:
        return 'Unknown';
    }
  };

  const status = getStatus();

  const getStatusIcon = () => {
    if (status === 'Failed') {
      return {
        startAdornment: ErrorIconRaw,
        endAdornment: RetryIconRaw,
        color: 0xffffff,
      };
    } else if (status === 'Training') {
      return {
        startAdornment: null,
        endAdornment: CloseIconOrangeRaw,
        color: 0xff5106,
      };
    } else if (status === 'Train') {
      return {
        startAdornment: PlayCircleIconRaw,
        endAdornment: null,
        color: 0xffffff,
      };
    } else {
      return {
        startAdornment: CheckCircleIconRaw,
        endAdornment: null,
        color: 0xffffff,
      };
    }
  };

  const { startAdornment, endAdornment, color } = getStatusIcon();

  if (!element.sourceImages?.nodes?.length) {
    return null;
  }

  return (
    <>
      <group
        userData={{
          cursor: ['Train', 'Training', 'Failed'].includes(status)
            ? 'pointer'
            : 'auto',
        }}
        position={[-width / 2 + paletteNameWidth + 20, height / 2 + 14, 1]}
        onClick={handleStatusButtonClick}
        onPointerOver={() => {
          if (status === 'Training') setShowCancel(true);
          if (status === 'Failed') setShowRetry(true);
        }}
        onPointerLeave={() => {
          setShowRetry(false);
          setShowCancel(false);
        }}
      >
        {(showRetry || showCancel) && (
          <CustomText
            position={[showRetry ? 90 : 100, 0, 0]}
            color="#FFF"
            outlineColor="#000"
            fontSize={12}
            font="Inter"
            fontWeight="semiBold"
            anchorX="left"
            whiteSpace="nowrap"
            content={showRetry ? 'Retry Training' : 'Cancel Training'}
            backgroundColor={'#000'}
            radius="subtle"
            paddingX={10}
            paddingY={5}
            renderOrder={100}
          />
        )}
        <CustomText
          color={['Training'].includes(status) ? PALETTE_COLOR : '#FFF'}
          outlineColor="#000"
          fontSize={12}
          font="Inter"
          fontWeight="semiBold"
          anchorX="left"
          whiteSpace="nowrap"
          content={status}
          backgroundColor={
            status === 'Complete'
              ? theme.functional.success.default
              : status === 'Train'
              ? PALETTE_COLOR
              : status === 'Training'
              ? PALETTE_COLOR_DISABLED
              : theme.functional.danger.default
          }
          radius="subtle"
          paddingX={10}
          paddingY={5}
          startAdornment={
            startAdornment
              ? {
                  svg: (
                    <StaticSvg svg={startAdornment} fillMaterial={{ color }} />
                  ),
                  width: 18,
                }
              : status === 'Training'
              ? { svg: <AnimatedLoadingSvg />, width: 18 }
              : undefined
          }
          endAdornment={
            endAdornment
              ? {
                  svg: (
                    <StaticSvg
                      svg={endAdornment}
                      fillMaterial={{ color: color }}
                    />
                  ),
                  width: 18,
                }
              : undefined
          }
        />
      </group>
      {confirmCancelTraining && (
        <CustomHtml>
          <Modal
            isOpen={true}
            setIsOpen={() => setConfirmCancelTraining(false)}
            style={{
              backgroundColor: theme.surface.e0,
              padding: '16px',
              minWidth: '320px',
              width: '320px',
            }}
          >
            <Container>
              <Title>Are you sure you'd like to stop palette training?</Title>
              <Content>This action can't be undone.</Content>
              <Buttons>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    setConfirmCancelTraining(false);
                  }}
                >
                  Return
                </Button>
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => {
                    handleAction({
                      type: 'cancelPaletteTraining',
                      id: element.id,
                    });
                    setConfirmCancelTraining(false);
                  }}
                >
                  Stop Training
                </Button>
              </Buttons>
            </Container>
          </Modal>
        </CustomHtml>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.text.default};
  font-weight: 600;
`;

const Content = styled.div`
  color: ${(p) => p.theme.text.info};
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 16px;
`;
