import { Color } from 'three';
import { RangeInput } from '@vizcom/shared-ui-components';
import { ColorInput } from '../ui/ColorInput';
import styled from 'styled-components';
import { MaterialPropertyFieldType } from './types';

const color = new Color();

export const MaterialPropertyField = ({
  label,
  value,
  fieldType = MaterialPropertyFieldType.Scalar,
  onChange,
}: {
  label: string;
  value: number;
  fieldType?: MaterialPropertyFieldType;
  onChange: (value: number) => void;
}) => {
  return (
    <Wrapper>
      {fieldType === MaterialPropertyFieldType.Scalar && (
        <FieldWrapper>
          <Row>
            <FieldLabel>{label}</FieldLabel>
            <FieldValue>{value.toFixed(2)}</FieldValue>
          </Row>
          <Row>
            <RangeInput
              value={value}
              onChange={(event) => {
                onChange(parseFloat(event.target.value));
              }}
              min={0.0}
              max={1.0}
              step={0.01}
              variant="tertiary"
            />
          </Row>
        </FieldWrapper>
      )}
      {fieldType === MaterialPropertyFieldType.Color && (
        <FieldWrapper>
          <Row>
            <ColorInput
              value={value.toString(16)}
              withPreview
              onChange={(value) => {
                if (!value) {
                  onChange(0.0);

                  return;
                }

                onChange(color.set(value).getHex());
              }}
            />
          </Row>
        </FieldWrapper>
      )}
      <TextureUploadIcon />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TextureUploadIcon = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;

  &:after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-color: ${({ theme }) => theme.surface.e2};
    border-radius: ${({ theme }) => theme.borderRadius.subtle};
    content: '';
    z-index: 2;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text.default};
`;

const FieldValue = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text.default};
`;
