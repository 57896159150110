import {
  Button,
  InfoIcon,
  PUBLIC_PALETTES_DETAILS,
  PALETTE_DISPLAY_ORDER,
  styledScrollbarDark,
  Text,
  useSelectedOrganization,
  Dropdown,
  PaletteCategory,
  useFeatureFlag,
  PlusIcon,
  MenuEllipsesIcon,
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
  useLocalStorage,
} from '@vizcom/shared-ui-components';
import {
  useCustomModels,
  useWorkbenchPalettes,
} from '@vizcom/shared/data-access/graphql';
import { groupBy } from 'lodash';
import styled from 'styled-components';
import { Divider } from '../../studio/style';
import { createUseGesture, dragAction, wheelAction } from '@use-gesture/react';
import { useEffect, useRef, useState } from 'react';
import { PublicPaletteThumbnail } from '@vizcom/shared-ui-components';
import { PaletteVersions, VersionedPalette } from './PaletteVersions';

export const VERSION_SELECTIONS_KEY = 'vizcom:palette_versions';

export type Palette = {
  id: string;
  name: string;
  desc: string;
  type: 'publicPaletteId' | 'customModelId' | 'workbenchPaletteId';
  value: string;
  category: PaletteCategory;
  thumbnailPath: string | null | undefined;
  versions?: { value: string; version: number }[];
  tags?: string[];
};

export const useAvailablePalettes = (workbenchId?: string) => {
  const { data } = useSelectedOrganization();
  const customModels = useCustomModels(data?.id);
  const { data: palettes } = useWorkbenchPalettes(workbenchId);

  return [
    ...PUBLIC_PALETTES_DETAILS.map((palette) => ({
      type: 'publicPaletteId',
      ...palette,
    })),
    ...(customModels.data?.nodes?.map((customModel) => ({
      id: customModel.id,
      name: customModel.name,
      value: customModel.id,
      desc: 'Custom',
      thumbnailPath: customModel.thumbnailPath,
      type: 'customModelId',
      category: data?.name,
    })) ?? []),
    ...(palettes?.nodes
      .filter((palette) => palette.status === 'ready')
      .map((palette) => ({
        id: palette.id,
        name: palette.name,
        value: palette.id,
        desc: 'Custom',
        thumbnailPath: palette.thumbnailPath ?? PublicPaletteThumbnail,
        type: 'workbenchPaletteId',
        category: PaletteCategory.Local,
        tags: palette.tags,
      })) ?? []),
  ] as Palette[];
};

const useGesture = createUseGesture([wheelAction, dragAction]);

type Props = {
  size: 'small' | 'large';
  availablePalettes: Palette[];
  selectedPaletteId: string;
  handleSelectPalette: (paletteId: string, type: string) => void;
};

export const PaletteSelector = ({
  size,
  availablePalettes,
  selectedPaletteId,
  handleSelectPalette: _handleSelectPalette,
}: Props) => {
  const { data } = useSelectedOrganization();
  const ref = useRef<HTMLDivElement>(null);
  const [categoryFilter, setCategoryFilter] = useState<string>('All libraries');
  const { flagEnabled } = useFeatureFlag('WORKBENCH_PALETTE_TRAINING');
  const [versionSelections, setVersionSelections] = useLocalStorage(
    VERSION_SELECTIONS_KEY,
    {} as Record<string, number>
  );

  const showFilter = availablePalettes.some(
    (palette) =>
      palette.type === 'workbenchPaletteId' || palette.type === 'customModelId'
  );

  const selectedPalette = availablePalettes.find(
    (palette) =>
      palette.value === selectedPaletteId ||
      (
        palette.versions &&
        palette.versions.find((v) => v.value === selectedPaletteId)
      )?.value
  );

  const handleSelectPalette = (
    id: string,
    version: number,
    paletteValue: string,
    type: string
  ) => {
    setVersionSelections({
      ...versionSelections,
      [id]: version,
    });

    _handleSelectPalette(paletteValue, type);
  };

  useEffect(() => {
    const selectedPaletteName = selectedPalette?.name;
    // scroll to the selected palette
    if (ref.current) {
      const selectedPaletteElement = ref.current.querySelector(
        `[data-palette-id="${selectedPaletteName}"]`
      ) as HTMLButtonElement | null;

      if (selectedPaletteElement) {
        ref.current.scrollTop =
          selectedPaletteElement.offsetTop - ref.current.clientHeight / 2;
      }
    }
  }, []);

  useGesture(
    {
      onWheel: (state) => {
        const { event } = state;
        event.preventDefault();
        event.stopPropagation();

        if (ref.current) {
          ref.current.scrollTop += state.delta[1];
        }
      },
      onDrag: (state) => {
        const { event } = state;
        event.preventDefault();
        event.stopPropagation();

        if (ref.current) {
          ref.current.scrollTop += state.delta[1];
        }
      },
    },
    {
      eventOptions: {
        passive: false,
      },
      target: ref,
    }
  );

  const groupedPalettes = {
    ...groupBy(availablePalettes, 'category'),
    ...(flagEnabled &&
    !availablePalettes.some((palette) => palette.type === 'workbenchPaletteId')
      ? { [PaletteCategory.Local]: [] as Palette[] }
      : {}),
  };
  const filterCategories = [
    { data: { value: 'All libraries', label: 'All libraries' } },
    { data: { value: 'Vizcom', label: 'Vizcom' } },
    ...(availablePalettes.some(
      (palette) => palette.type === 'workbenchPaletteId'
    ) || flagEnabled
      ? [
          {
            data: {
              value: 'Local palettes',
              label: 'Local palettes',
            },
          },
        ]
      : []),
    ...(availablePalettes.some((palette) => palette.type === 'customModelId')
      ? [
          {
            data: {
              value: data?.name || 'Workspace',
              label: data?.name || 'Workspace',
            },
          },
        ]
      : []),
  ];

  return (
    <Container ref={ref} $size={size}>
      <TitleContainer $size={size}>
        {size === 'large' && (
          <>
            <Title>
              Palettes
              <a target="_blank" href="https://docs.vizcom.ai/render-styles">
                <InfoIcon />
              </a>
            </Title>
            <Divider />
          </>
        )}
        {showFilter && (
          <div
            style={{
              display: 'flex',
              gap: '12px',
              flexDirection: 'column',
            }}
          >
            <Dropdown
              options={filterCategories}
              value={categoryFilter}
              setValue={(value) => setCategoryFilter(value)}
              OptionComponent={({ option }) => <Text>{option.label}</Text>}
              optionToValueMapper={(option) => option.label}
              buttonProps={{
                style: {
                  padding: '8px 12px',
                  width: '100%',
                },
              }}
            >
              <span
                style={{
                  fontSize: 12,
                }}
              >
                {categoryFilter}
              </span>
            </Dropdown>
            <Divider />
          </div>
        )}
      </TitleContainer>
      <div
        style={{
          marginTop:
            showFilter && size === 'large'
              ? '76px'
              : !showFilter && size === 'small'
              ? 0
              : '48px',
        }}
      >
        {Object.entries(groupedPalettes)
          .sort((a, b) => {
            const indexA = PALETTE_DISPLAY_ORDER.indexOf(a[0]);
            const indexB = PALETTE_DISPLAY_ORDER.indexOf(b[0]);

            const effectiveIndexA = indexA === -1 ? Infinity : indexA;
            const effectiveIndexB = indexB === -1 ? Infinity : indexB;

            return effectiveIndexA - effectiveIndexB;
          })
          .filter(([category]) => {
            if (categoryFilter === 'Vizcom') {
              return ['Essentials', 'Stylized', 'Automotive'].includes(
                category
              );
            }

            if (categoryFilter === 'Local palettes') {
              return category === 'Local palettes';
            }

            if (categoryFilter === data?.name) {
              return category === data?.name;
            }
            return true;
          })
          .map(([category, palettes]) => (
            <div key={category}>
              <Text color="info">{category}</Text>
              <PalettesContainer>
                {palettes.map((palette) => (
                  <PaletteButton
                    $selected={palette.name === selectedPalette?.name}
                    variant="transparent"
                    key={palette.value}
                    data-palette-id={palette.name}
                    onClick={() => {
                      if (!palette.versions || !palette.versions.length) {
                        _handleSelectPalette(palette.value, palette.type);
                        return;
                      }

                      const version =
                        versionSelections[palette.id] ??
                        palette.versions.length + 1;

                      const id =
                        version === 1
                          ? palette.value
                          : palette.versions[version - 2].value;

                      handleSelectPalette(
                        palette.id,
                        version,
                        id,
                        palette.type
                      );
                    }}
                  >
                    {palette.thumbnailPath && (
                      <img alt={palette.name} src={palette.thumbnailPath} />
                    )}
                    <span>{palette.name}</span>
                    {palette.versions && palette.versions.length > 0 && (
                      <VersionTag>
                        v
                        {versionSelections[palette.id] ??
                          palette.versions.length + 1}
                      </VersionTag>
                    )}
                    {palette.versions && Boolean(palette.versions.length) && (
                      <RichTooltip noParentIntegration trigger="click">
                        <RichTooltipTrigger>
                          <div
                            style={{
                              marginLeft: 'auto',
                              display: 'block',
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <MenuEllipsesIcon />
                          </div>
                        </RichTooltipTrigger>
                        <RichTooltipContent
                          hideArrow
                          $backgroundSurface="e2"
                          style={{
                            padding: '8px',
                            borderRadius: '16px',
                          }}
                        >
                          <PaletteVersions
                            selectedPaletteId={selectedPaletteId}
                            palette={palette as VersionedPalette}
                            handleSelectPalette={handleSelectPalette}
                          />
                        </RichTooltipContent>
                      </RichTooltip>
                    )}
                  </PaletteButton>
                ))}

                {!palettes.length && (
                  <PaletteButton
                    $selected={false}
                    variant="transparent"
                    as="a"
                    target="_blank"
                    href="https://vizcom.notion.site/Vizcom-Palettes-Beta-7466392fb27d4138999a79ed61402c72"
                  >
                    <PlusIconContainer>
                      <StyledPlusIcon />
                    </PlusIconContainer>
                    Create a palette
                  </PaletteButton>
                )}
              </PalettesContainer>
            </div>
          ))}
      </div>
    </Container>
  );
};

const Container = styled.div<{ $size: 'small' | 'large' }>`
  background-color: ${(p) => p.theme.surface.e0};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  height: ${(p) => (p.$size === 'small' ? '252px;' : '416px')};
  width: ${(p) => (p.$size === 'small' ? '215px;;' : '252px')};
  border-radius: 16px;
  padding: 12px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  pointer-events: all;
  ${styledScrollbarDark}
`;

const TitleContainer = styled.div<{ $size: 'small' | 'large' }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  padding-top: 12px;
  margin-top: -12px;
  background-color: ${(p) => p.theme.surface.e0};
  width: ${(p) => (p.$size === 'small' ? '191px' : '228px')};
`;

const Title = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.text.default};

  a {
    margin-top: 3px;
  }
`;

const PalettesContainer = styled.div`
  margin: 16px 0px;
`;

const PaletteButton = styled(Button)<{ $selected: boolean }>`
  background-color: ${(p) =>
    p.$selected ? p.theme.surface.e1 : 'transparent'};
  width: 100%;
  font-size: 12px;
  padding: 6px;
  display: flex;
  gap: 8px;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }
`;

const PlusIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.surface.e2};
  border-radius: 6px;
  width: 32px;
  height: 32px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 16px;
  height: 16px;
  fill: ${(p) => p.theme.text.info};
`;

export const VersionTag = styled.div`
  background-color: ${(p) => p.theme.surface.e2};
  color: ${(p) => p.theme.text.info};
  font-size: 12px;
  min-width: 20px;
  text-align: center;
  border-radius: 3px;
  padding: 2px;
`;
