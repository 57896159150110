import { useMemo } from 'react';
import * as THREE from 'three';
import { useSnapshot3D } from '../compositeSceneEditor/hooks/useSnapshot3D';
import { MaterialIcon } from './style';
import { useObjectURL } from '@vizcom/shared-ui-components';

export const MaterialPreviewIcon = ({
  material,
  $size = 24,
  $fallbackColor,
}: {
  material: THREE.Material;
  $size?: number;
  $fallbackColor?: string;
}) => {
  const { scene, camera } = useMemo(() => {
    const scene = new THREE.Scene();
    scene.add(new THREE.Mesh(new THREE.SphereGeometry(1.0, 32, 32), material));

    const camera = new THREE.PerspectiveCamera();
    camera.position.z = 2.0;
    scene.add(camera);

    return { scene, camera };
  }, [material]);
  const previewSnapshot = useSnapshot3D(
    scene,
    camera,
    {
      width: $size ?? 24,
      height: $size ?? 24,
      type: 'jpg',
      quality: 1,
      debounce: 0,
    },
    [material]
  );
  const previewUrl = useObjectURL(
    previewSnapshot ? previewSnapshot : undefined
  );

  return (
    <MaterialIcon $size={$size} $fallbackColor={$fallbackColor}>
      {previewUrl && <img src={previewUrl} />}
    </MaterialIcon>
  );
};
