import { NumberInput, RangeInput, Text } from '@vizcom/shared-ui-components';
import { HeaderSecondary } from '../../style';
import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';
import { useState } from 'react';
import { LayerData } from '../../../../lib/actions/drawing/updateLayer';

export const Layer3dLightSettings = ({
  layer,
  updateLayer,
  onEnvironmentIntensityChange,
}: {
  layer: Drawing2dStudio['layers']['nodes'][0];
  updateLayer: (
    update?: Partial<LayerData>,
    updateLayerThumbnail?: boolean
  ) => void;
  onEnvironmentIntensityChange: (environmentIntensity: number) => void;
}) => {
  const metadata3d = layer.metadata3D as LayerMetadata3d;
  const [environmentIntensity, setEnvironmentIntensity] = useState<number>(
    metadata3d?.view?.environmentIntensity ?? 1.0
  );

  const handleUpdate = (environmentIntensity: number) => {
    const metadata3DUpdate: Partial<LayerMetadata3d> = {
      ...layer.metadata3D,
      view: {
        ...(layer.metadata3D?.view ?? {}),
        environmentIntensity,
      },
    };

    updateLayer({ metadata3D: metadata3DUpdate });
  };

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          gap: '16px',
          alignItems: 'center',
          padding: '0 8px',
          width: 240,
        }}
      >
        <HeaderSecondary>Light Intensity</HeaderSecondary>
        <RangeInput
          value={environmentIntensity * 100.0}
          min={0}
          max={200}
          step={5}
          onChange={(e) => {
            setEnvironmentIntensity(parseFloat(e.target.value) / 100.0);
            onEnvironmentIntensityChange(parseFloat(e.target.value) / 100.0);
          }}
          onMouseUp={() => handleUpdate(environmentIntensity)}
        />
        <Text>
          <NumberInput
            value={environmentIntensity * 100.0}
            min={0}
            max={200}
            unit="%"
            setValue={(v) => handleUpdate(v / 100.0)}
            dragArrows={false}
          />
        </Text>
      </div>
    </>
  );
};
