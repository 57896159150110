import { useFrame } from '@react-three/fiber';
import { ReactNode, useState } from 'react';

export const ZoomFallbackGroup = (props: {
  fallback: ReactNode;
  zoomThreshold?: number;
  children: ReactNode;
}) => {
  const [useFallback, setUseFallback] = useState(false);

  const { fallback, zoomThreshold = 0.25, children } = props;

  useFrame((s) => {
    const zoom = s.camera.zoom;
    if (zoom < zoomThreshold !== useFallback) {
      setUseFallback(zoom < zoomThreshold);
    }
  });

  return <>{useFallback ? fallback : children}</>;
};
