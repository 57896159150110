import { useEffect, useRef } from 'react';

type TypedArray =
  | Int8Array
  | Uint8Array
  | Uint8ClampedArray
  | Int16Array
  | Uint16Array
  | Int32Array
  | Uint32Array
  | Float32Array
  | Float64Array
  | BigInt64Array
  | BigUint64Array;

// This returns an objectURL for a given blob or array buffer
// This is a little bit more complex than just using a useEffect
// to make sure we return the objectUrl directly and do not cause a re-render
export const useObjectURL = (
  data: Blob | ArrayBuffer | TypedArray | null | undefined
) => {
  const objectUrlRef = useRef<{
    data: Blob | ArrayBuffer | TypedArray;
    url: string;
  } | null>(null);

  useEffect(() => {
    return () => {
      if (objectUrlRef.current) {
        URL.revokeObjectURL(objectUrlRef.current.url);
      }
      objectUrlRef.current = null;
    };
  }, []);

  if (!data) {
    if (objectUrlRef.current) {
      URL.revokeObjectURL(objectUrlRef.current.url);
      objectUrlRef.current = null;
    }
  } else {
    if (!objectUrlRef.current || objectUrlRef.current?.data !== data) {
      if (objectUrlRef.current?.url) {
        URL.revokeObjectURL(objectUrlRef.current.url);
      }

      let source: Blob | MediaSource;

      if (data instanceof ArrayBuffer) {
        source = new Blob([data]);
      } else if ((data as TypedArray).buffer instanceof ArrayBuffer) {
        source = new Blob([data as TypedArray]);
      } else {
        source = data as Blob;
      }

      objectUrlRef.current = {
        data: data,
        url: URL.createObjectURL(source),
      };
    }
  }

  return objectUrlRef.current?.url;
};
