import React from 'react';
import styled from 'styled-components';
import { useListItem } from '@floating-ui/react';

import {
  addToast,
  CheckIcon,
  CustomSelectTriggerContentProps,
  DropdownIcon,
  formatErrorMessage,
  Select,
  Text,
  triggerConfirmModal,
  useSelectContext,
  ConfirmationModalComponent,
} from '@vizcom/shared-ui-components';
import { WorldOutlineIcon, NoEntryIcon } from '@vizcom/shared-ui-components';
import { useUpdateWorkbench } from '@vizcom/shared/data-access/graphql';
import { WorkbenchBasicDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';

const LabelText = styled(Text)<{ $restricted: boolean }>`
  color: ${(p) => (p.$restricted ? p.theme.text.danger : p.theme.text.default)};
`;

const GreyWorldOutlineIcon = styled(WorldOutlineIcon)`
  color: ${(p) => p.theme.text.info};
`;

const RedNoEntryIcon = styled(NoEntryIcon)`
  color: ${(p) => p.theme.text.danger};
`;

type FileSharingOptionConfig = {
  label: string;
  description: string;
  value: boolean;
  icon: React.ReactNode;
};

const FILE_SHARING_OPTIONS: FileSharingOptionConfig[] = [
  {
    label: 'Anyone with link',
    description: 'Anyone with the link can access the file',
    value: true,
    icon: <GreyWorldOutlineIcon width={15} height={15} />,
  },
  {
    label: 'Link sharing off',
    description: 'Team members will still be able to access the file',
    value: false,
    icon: <RedNoEntryIcon width={15} height={15} />,
  },
];

export const FileSharingSelectComponent = ({
  workbench,
}: {
  workbench: WorkbenchBasicDataFragment;
}) => {
  const [, updateWorkbench] = useUpdateWorkbench();

  const onSelectSharingMode = async (value: string | null) => {
    const newPublicSharingEnabled = Boolean(value === 'true');

    if (newPublicSharingEnabled === false) {
      try {
        await triggerConfirmModal({
          body: (onConfirm, onCancel) => (
            <ConfirmationModalComponent
              onConfirm={onConfirm}
              onCancel={onCancel}
              title="Are you sure you want to disable link sharing?"
              description="The link to this file will no longer be valid if it has already been shared, however it can be restored by admins and editors at any time."
            />
          ),
        });
      } catch {
        // User cancelled disabling link sharing
        return;
      }
    }

    const res = await updateWorkbench({
      id: workbench.id,
      patch: {
        publicSharingEnabled: newPublicSharingEnabled,
      },
    });

    if (res.error) {
      return addToast('Error while updating link sharing', {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }

    return addToast(
      `Link sharing ${newPublicSharingEnabled ? 'enabled' : 'disabled'}`
    );
  };

  const selectedIndex = workbench.publicSharingEnabled ? 0 : 1;

  return (
    <Select
      onSelectElement={onSelectSharingMode}
      customSelectedTriggerContent={CustomSelectedTriggerContent}
      selectedOptionIndex={selectedIndex}
    >
      {FILE_SHARING_OPTIONS.map((config, index) => (
        <FileSharingOptionComponent key={index} config={config} />
      ))}
    </Select>
  );
};

const CustomSelectedTriggerContent = (
  props: CustomSelectTriggerContentProps
) => {
  const { selectedIndex } = props;

  if (selectedIndex === null) {
    return (
      <Text block type="b1">
        Select sharing mode
      </Text>
    );
  }

  const selectedConfig = FILE_SHARING_OPTIONS[selectedIndex];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '8px 8px',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
        {selectedConfig.icon}
        <Text block={true} type="b1">
          {selectedConfig.label}
        </Text>
      </div>
      <DropdownIcon style={{ float: 'right' }} />
    </div>
  );
};

const FileSharingOptionComponent = ({
  config,
}: {
  config: FileSharingOptionConfig;
}) => {
  const { activeIndex, selectedIndex, getItemProps, handleSelect } =
    useSelectContext();

  const { ref, index } = useListItem({ label: config.label });

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <OptionButton
      ref={ref}
      role="option"
      value={String(config.value)}
      tabIndex={isActive ? 0 : -1}
      $active={isActive}
      {...getItemProps({
        onClick: () => handleSelect(index),
      })}
    >
      <FlexCenterContainer>
        {config.icon}
        <div>
          <LabelText
            block
            type="sh2"
            $restricted={!config.value}
            style={{
              marginBottom: 4,
            }}
          >
            {config.label}
          </LabelText>
          <Text block color="info" type="b1">
            {config.description}
          </Text>
        </div>
      </FlexCenterContainer>
      {isSelected && <CheckIcon width={15} height={15} />}
    </OptionButton>
  );
};

const OptionButton = styled.button<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 10px 16px 10px 8px;
  cursor: pointer;
  text-align: left;
  border: none;
  border-radius: 4px;
  background-color: ${(p) => (p.$active ? p.theme.surface.e2 : 'transparent')};
`;

const FlexCenterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
`;
