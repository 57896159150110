import {
  urqlClient,
  UpdateDrawingLayersMutation,
  UpdateLayerMutation,
  CreateLayerMutation,
} from '@vizcom/shared/data-access/graphql';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { omit } from 'lodash-es';
import { MergeLayersAction } from './mergeLayers';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

export const UnmergeLayersAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'unmergeLayers';
    targetLayerId: string;
    sourceLayers: Drawing2dStudio['layers']['nodes'];
    originalImage?: string | ImageData | Blob | null;
    mergedImage: ImageData;
  }
> = {
  type: 'unmergeLayers',
  optimisticUpdater: ({ payload }, drawing) => {
    const targetLayer = drawing.layers.nodes.find(
      (layer) => layer.id === payload.targetLayerId
    );

    if (!targetLayer || !payload.originalImage) {
      return;
    }
    targetLayer.imagePath = payload.originalImage;

    payload.sourceLayers.forEach((layer) => {
      const existingLayer = drawing.layers.nodes.find((l) => l.id === layer.id);
      if (existingLayer) {
        existingLayer.name = layer.name;
        existingLayer.visible = layer.visible;
        existingLayer.opacity = layer.opacity;
        existingLayer.blendMode = layer.blendMode;
        existingLayer.fill = layer.fill;
        existingLayer.metadata3D = layer.metadata3D;
        existingLayer.orderKey = layer.orderKey;
      } else {
        drawing.layers.nodes.push({
          ...layer,
          drawingId: drawing.id,
          orderKey: layer.orderKey,
          updatedAt: '0',
          createdAt: '0',
        });
      }
    });
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    const resUpdateLayer = await urqlClient.mutation(UpdateLayerMutation, {
      id: payload.targetLayerId,
      patch: {
        imagePath:
          payload.originalImage instanceof ImageData
            ? await imageDataToBlob(payload.originalImage)
            : payload.originalImage,
      },
    });
    if (resUpdateLayer?.error) {
      throw new Error(
        `Error while unmerging layers, please retry. ${
          resUpdateLayer.error.graphQLErrors[0]?.message ??
          resUpdateLayer.error.message
        }`
      );
    }

    for (const layer of payload.sourceLayers) {
      const res = await urqlClient.mutation(CreateLayerMutation, {
        input: {
          layer: {
            ...omit(layer, 'image', 'anchor'),
            imagePath:
              layer.imagePath instanceof ImageData
                ? await imageDataToBlob(layer.imagePath)
                : layer.imagePath,
          },
        },
      });
      if (res?.error) {
        throw new Error(
          `Error while merging layers, please retry. ${
            res.error.graphQLErrors[0]?.message ?? res.error.message
          }`
        );
      }
    }
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<typeof MergeLayersAction> = {
      type: 'mergeLayers',
      targetLayerId: payload.targetLayerId,
      sourceLayersId: payload.sourceLayers.map((l) => l.id),
      mergedImage: payload.mergedImage,
    };

    return undoPayload;
  },
};
