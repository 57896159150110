import styled, { css, keyframes, useTheme } from 'styled-components';
import { IconPlus } from '@vizcom/shared-utils-assets';
import Layers from './Layers';
import {
  Button,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  slideInFromLeft,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import { v4 as uuid } from 'uuid';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { TransitionStatus } from 'react-transition-group';
import { Hotkey, Overlay, SidebarHeader, SidebarHeaderContent } from './style';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { useDrag } from '@use-gesture/react';
import { boundNumber, getLayerOrderKey } from '@vizcom/shared/js-utils';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import { Layout } from './types';
import { PromptHistoryItem } from './history/WorkbenchStudioHistory';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';

const UI_MIN_SIZE = 342;

const slideInFromRight = keyframes`
  from {
    transform: translateX(calc(100% + 14px));
  }
  to {
    transform: translateX(0);
  }
`;

const Resizer = styled.div`
  position: absolute;
  cursor: ns-resize;
  align-self: center;
  top: 12px;
  margin-top: -32px;
  padding: 8px 0;

  > div {
    width: 222px;
    height: 4px;
    background-color: ${({ theme }) => theme.surface.e2};
    border-radius: 16px;
  }
`;

const StudioTabs = styled(animated.div)<{
  $state: TransitionStatus;
  $disabled: boolean;
  $hidden: boolean;
  $layout: Layout;
}>`
  ${({ $layout, $state }) => {
    if ($layout === 'default') {
      return css`
        top: calc(1rem + 66px);
        left: 14px;
        animation: ${$state === 'entering' ? slideInFromLeft : 'none'} 0.5s ease;
        transform: ${$state === 'entered' || $state === 'entering'
          ? 'translateX(0)'
          : 'translateX(calc(-100% - 14px))'};
      `;
    } else if ($layout === 'stacked') {
      return css`
        height: calc(calc(100% * 0.45) - 100px);
        right: 14px;
        animation: ${$state === 'entering' ? slideInFromRight : 'none'} 0.5s
          ease;
        transform: ${$state === 'entered' || $state === 'entering'
          ? 'translateX(0)'
          : 'translateX(calc(100% + 14px))'};
      `;
    }
  }}

  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};
  position: absolute;
  transition: transform 0.5s ease-in-out;
  bottom: 100px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.text.info};
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'all')};
  gap: 0.5rem;
  align-items: flex-start;
  z-index: 10000000;

  * {
    pointer-events: ${({ $hidden }) => ($hidden ? 'none !important' : 'all')};
  }
`;

const StyledPlusIcon = styled(IconPlus)`
  fill: ${({ theme }) => theme.text.default};
  width: 16px;
  height: 16px;
`;

const StyledButton = styled(Button)`
  padding: 0;
  background: transparent;
  height: 16px;
  width: 16px;
  padding: 2px;

  &:hover {
    background: ${({ theme }) => theme.surface.e2};
  }
`;

type Props = {
  hidden: boolean;
  disabled: boolean;
  state: TransitionStatus;
  drawing: Drawing2dStudio;
  activeLayerId: string | undefined;
  historyOpen: boolean;
  viewerVisibility: Record<string, boolean>;
  uiRatio: number;
  setUiRatio: React.Dispatch<React.SetStateAction<number>>;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setActiveLayerId: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  onCreateDrawingFromImage: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    },
    name?: string
  ) => void;
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
};

const WorkbenchStudioLayers = ({
  hidden,
  disabled,
  state,
  drawing,
  activeLayerId,
  historyOpen,
  viewerVisibility,
  uiRatio,
  setViewerVisibility,
  handleAction,
  setActiveLayerId,
  onCreateDrawingFromImage,
  setUiRatio,
  setSelectedPromptHistoryItem,
}: Props) => {
  const [spring, api] = useSpring(() => ({}));
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const ref = useRef<HTMLDivElement>(null);
  const userPreferedLayout = useCurrentUserClientStateByKey(
    UserClientStateKeys.StudioLayout
  );
  const layout: Layout = isViewer ? 'default' : userPreferedLayout || 'default';
  const [, updateState] = useSetCurrentUserClientState();

  const addLayer = useStableCallback(() => {
    const id = uuid();
    const selectedIds = activeLayerId?.split('/');
    const anchorId =
      selectedIds && selectedIds.length > 1
        ? selectedIds[selectedIds.length - 1]
        : activeLayerId;
    handleAction?.({
      type: 'addLayer',
      layer: {
        id: id,
        name: `Layer ${drawing?.layers.nodes.length + 1}`,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        orderKey: getLayerOrderKey(drawing.layers.nodes, anchorId),
      },
    });
    setActiveLayerId(id);
  });

  useHotkeys(
    'n',
    ({ repeat }) => {
      if (!repeat && !isViewer) {
        addLayer();
      }
    },
    [isViewer]
  );

  useEffect(() => {
    if (isViewer) return;
    if (layout !== 'default') {
      ref.current!.style.bottom = '100px';
      return;
    }

    // slide up and down to make space for history panel
    if (historyOpen) {
      api.start({
        to: {
          bottom: 330,
        },
        config: { duration: 150 },
        delay: 200,
      });
    } else {
      api.start({
        to: {
          bottom: 100,
        },
        config: { duration: 150 },
        delay: 150,
      });
    }
  }, [historyOpen, layout]);

  useEffect(() => {
    const onWindowResize = () => {
      if (!ref.current) return;
      if (layout !== 'stacked') return;
      const newHeight = window.innerHeight * uiRatio - 101;
      ref.current.style.height = `${newHeight}px`;
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [layout, uiRatio]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    if (layout !== 'stacked') {
      ref.current.style.height = 'auto';
      return;
    }

    const newHeight = window.innerHeight * uiRatio - 101;
    ref.current.style.height = `${newHeight}px`;
  }, [uiRatio, layout]);

  const bindResize = useDrag(({ event, down, tap, last }) => {
    const clientY = 'clientY' in event ? event.clientY : 0;
    const minRatio = UI_MIN_SIZE / window.innerHeight;
    const newRatio = boundNumber(
      minRatio,
      1 - clientY / window.innerHeight,
      1 - minRatio
    );

    if (last) {
      updateState({
        input: {
          key: UserClientStateKeys.StudioRatio,
          value: newRatio,
        },
      });
    }

    if (tap || !down) {
      return;
    }

    setUiRatio(newRatio);
    const newHeight = window.innerHeight * newRatio - 101;
    ref.current!.style.height = `${newHeight}px`;
  });

  return (
    <>
      <StudioTabs
        $state={state}
        $disabled={disabled}
        $hidden={hidden}
        $layout={layout}
        style={spring}
        ref={ref}
      >
        <>
          {layout === 'stacked' && (
            <Resizer {...bindResize()}>
              <div />
            </Resizer>
          )}
          {disabled && (
            <Overlay
              style={{ borderRadius: '16px' }}
              onClick={() => setSelectedPromptHistoryItem(undefined)}
            />
          )}
          {drawing && (
            <>
              <SidebarHeader>
                <SidebarHeaderContent>Layers</SidebarHeaderContent>
                {!isViewer && (
                  <RichTooltip trigger="hover" placement="right" padding={29}>
                    <RichTooltipTrigger>
                      <StyledButton
                        size="iconSquared"
                        variant="secondary"
                        onClick={(e) => {
                          (e.target as HTMLButtonElement).blur();
                          addLayer();
                        }}
                      >
                        <StyledPlusIcon />
                      </StyledButton>
                    </RichTooltipTrigger>
                    <RichTooltipContent style={{ color: theme.white }}>
                      <div>
                        New layer <Hotkey>N</Hotkey>
                      </div>
                    </RichTooltipContent>
                  </RichTooltip>
                )}
              </SidebarHeader>

              <Layers
                drawing={drawing}
                handleAction={handleAction}
                activeLayer={activeLayerId}
                setActiveLayer={setActiveLayerId}
                viewerVisibility={viewerVisibility}
                setViewerVisibility={setViewerVisibility}
                onCreateDrawingFromImage={onCreateDrawingFromImage}
              />
            </>
          )}
        </>
      </StudioTabs>
    </>
  );
};

export default WorkbenchStudioLayers;
