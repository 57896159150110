import { Transition, TransitionStatus } from 'react-transition-group';
import { OrthographicCamera, ShaderMaterial } from 'three';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import WorkbenchStudioLayers from './WorkbenchStudioLayers';
import WorkbenchStudioCreate from './WorkbenchStudioCreate';
import { SyncQueueSynchronizer } from '../../lib/SyncQueueSynchronizer';
import { useDrawingSyncedState } from '../../lib/useDrawingSyncedState';
import { HtmlOverlay } from '../utils/HtmlOverlay';
import { CustomImage } from '../utils/CustomImage';
import { getElementSize } from '../helpers';
import {
  Button,
  FullPageDarkLoader,
  FullscreenExitIcon,
  FullscreenIcon,
  OnboardingStepName,
  PaywallModal,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  ToastIndicator,
  TooltipNotificationName,
  useCompleteOnboardingStep,
  useHasStylus,
  useInitDrawWithFinger,
  useKeyboardShortcut,
  useLastNonNullValue,
  useStableCallback,
  useTooltipNotificationState,
} from '@vizcom/shared-ui-components';
import { WorkbenchElementContainerUserData } from '../objectsUserdata';
import { FlippableGroup } from './FlippableGroup';
import { WorkbenchStudioToolbar } from './Toolbar/WorkbenchStudioToolbar';
import { Suspense, useCallback, useRef, useState } from 'react';
import { InferenceSettings, useInference } from './useInference';
import { InferenceTray } from './create/InferenceTray';
import { InferencePreview } from './create/InferencePreview';
import {
  WorkbenchStudioTool,
  isPaintingTool,
  useWorkbenchStudioToolState,
} from './studioState';
import { LoadingPlaceholder } from '../utils/LoadingPlaceholder';
import { useActiveLayer } from './lib/useActiveLayer';
import {
  PromptHistoryItem,
  WorkbenchStudioHistory,
} from './history/WorkbenchStudioHistory';
import { useIsDebugMode } from './lib/useIsDebugMode';
import { StatsGl } from '@react-three/drei';
import { WorkbenchStudioDebug } from './WorkbenchStudioDebug';
import { useImageStudioHotkeys } from './useImageStudioHotkeys';
import { LayersCompositorApi } from './DrawingCompositor/LayersCompositor/context';
import { LayersCompositor } from './DrawingCompositor/LayersCompositor/LayersCompositor';
import { CompositeLayer } from './DrawingCompositor/CompositeLayer';
import { DoubleSide } from 'three';
import { CompositedLayersMesh } from './DrawingCompositor/LayersCompositor/CompositedLayersMesh';
import { WorkbenchStudioContextMenu } from './Toolbar/WorkbenchStudioContextMenu';
import { useThree } from '@react-three/fiber';
import { CanvasColorPicker } from './CanvasColorPicker';
import { LayerPicker } from './LayerPicker';
import { SymmetryAxis } from './DrawingCompositor/SymmetryAxis';

import { WorkbenchViewportControls } from '../WorkbenchViewportControls';
import {
  getCameraLimitsFromActiveDrawing,
  useMapControls,
} from '../utils/mapControls/utils';
import { WorkbenchHelpMenu } from '../WorkbenchHelpMenu';
import { WorkbenchStudioCameraGestures } from './camera/WorkbenchStudioCameraGestures';
import { WorkbenchStudioCamera } from './camera/WorkbenchStudioCamera';
import { WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS } from './utils';
import { WorkbenchStudioFileDropper } from './WorkbenchStudioFileDropper';
import { useTheme } from 'styled-components';
import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { WorkbenchCollaborationToolbar } from '../WorkbenchCollaborationToolbar/WorkbenchCollaborationToolbar';
import { WorkbenchStudioMenu } from './WorkbenchStudioMenu/WorkbenchStudioMenu';
import { Layout } from './types';
import WorkbenchStudioCombinedUI from './WorkbenchStudioCombinedUI';

import { NewDrawingModal } from './NewDrawingModal';
import {
  SelectionApiContextProvider,
  useSelectionApi,
} from './selection/useSelectionApi';
import { SelectionTools } from './selection/SelectionTools';
import { sortByOrderKey } from '@vizcom/shared/js-utils';
import { useSelectionShortcuts } from './selection/useSelectionHotkeys';
import { PublicFileSharingButton } from '../WorkbenchCollaborationToolbar/publicFileSharing/PublicFileSharing';
import { useAutoPrompt } from './useAutoPrompt';
import { GlobalCursor } from '../utils/GlobalCursor';
import { OnboardingMultiStepProvider } from '../utils/OnboardingMultiStep';
import { ClientSideWorkbenchElementDrawing } from '../../lib/clientState';

// The UI is 250px + 14px margin from the viewport edge
const HALF_UI_WIDTH = 132;

// This component is used as a wrapper over WorkbenchImageStudioContent
// to load it lazily on the first drawing selected and animate it in/out correctly
// This makes sure WorkbenchImageStudioContent is always passed a drawing as props
export const WorkbenchImageStudio = (props: {
  activeElement: ClientSideWorkbenchElementDrawing | undefined;
  syncQueueSynchronizer: SyncQueueSynchronizer;
  onExit: () => void;
  selfPresence: MultiplayerPresence | null;
  selectedPresence: MultiplayerPresence | undefined;
  setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
  multiplayerPresences: MultiplayerPresence[];
  onCreateDrawingFromImage: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    },
    name?: string
  ) => void;
}) => {
  const lastActiveElement = useLastNonNullValue(props.activeElement);

  if (!lastActiveElement) {
    return null;
  }

  return (
    <Transition
      in={!!props.activeElement}
      timeout={WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS}
      appear
      unmountOnExit
    >
      {(state) => (
        <WorkbenchImageStudioContent
          drawing={lastActiveElement}
          state={state}
          syncQueueSynchronizer={props.syncQueueSynchronizer}
          key={lastActiveElement.id}
          _onExit={props.onExit}
          selfPresence={props.selfPresence}
          selectedPresence={props.selectedPresence}
          setSelectedPresenceId={props.setSelectedPresenceId}
          multiplayerPresences={props.multiplayerPresences}
          onCreateDrawingFromImage={props.onCreateDrawingFromImage}
        />
      )}
    </Transition>
  );
};

const WorkbenchImageStudioContent = (props: {
  drawing: ClientSideWorkbenchElementDrawing;
  syncQueueSynchronizer: SyncQueueSynchronizer;
  state: TransitionStatus;
  selfPresence: MultiplayerPresence | null;
  selectedPresence: MultiplayerPresence | undefined;
  setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
  multiplayerPresences: MultiplayerPresence[];
  _onExit: () => void;
  onCreateDrawingFromImage: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    },
    name?: string
  ) => void;
}) => {
  const [, updateState] = useSetCurrentUserClientState();
  const toolState = useWorkbenchStudioToolState();
  const debugMode = useIsDebugMode();
  const [showOverlay, setShowOverlay] = useState(false);

  const layersCompositorRef = useRef<LayersCompositorApi>(null!);

  const [viewerVisibility, setViewerVisibility] = useState<
    Record<string, boolean>
  >({});

  const savedUiRatio: number =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioRatio) || 0.45;
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const [uiRatio, setUiRatio] = useState(savedUiRatio);

  const { connected } = useThree((s) => s.events);

  const theme = useTheme();

  const {
    handleAction: _handleAction,
    filterHistory,
    drawing,
    undoAction,
    redoAction,
    canUndo,
    canRedo,
    hasUnsavedChanges,
  } = useDrawingSyncedState(props.drawing.id, props.syncQueueSynchronizer);

  const {
    activeLayer: _activeLayer,
    activeLayerId,
    setActiveLayerId,
  } = useActiveLayer(drawing);

  const [paywallModalOpen, setPaywallModalOpen] = useState(false);
  const [previewHidden, setPreviewHidden] = useState(false);
  const [selectedPromptId, setSelectedPromptId] = useState<string | undefined>(
    undefined
  );
  const [selectedOutputId, setSelectedOutputId] = useState<string | undefined>(
    undefined
  );
  const [historyOpen, setHistoryOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [selectedPromptHistoryItem, setSelectedPromptHistoryItem] =
    useState<PromptHistoryItem>(undefined);

  const activeLayer =
    selectedPromptId === undefined &&
    !selectedPromptHistoryItem &&
    props.state === 'entered'
      ? _activeLayer
      : undefined;

  const handleAction: typeof _handleAction = useCallback(
    (action, meta) => {
      const payload = _handleAction(action, meta);

      if (
        payload &&
        payload?.type !== 'updateDrawingThumbnail' &&
        payload?.type !== 'mutateLocalState'
      ) {
        _handleAction({
          type: 'updateDrawingThumbnail',
          thumbnail: layersCompositorRef.current.getCompositedImage,
        });
      }
      return payload;
    },
    [_handleAction]
  );

  const handleInitiateDrawing = useStableCallback((autoPromptDelay: number) => {
    triggerAutoPrompt(autoPromptDelay);
  });

  const selectionApi = useSelectionApi(
    drawing ? [drawing.width, drawing.height] : [0, 0],
    handleAction
  );

  const {
    trigger,
    anyOutputLoading,
    selectedPrompt,
    inferenceSettings,
    setInferenceSettings,
  } = useInference({
    drawing,
    selectedPromptId,
    setSelectedPromptId,
    getCompositeImage: layersCompositorRef.current?.getCompositedImage,
    getSelectionImage: () => selectionApi.getState().getSelectionImage(),
  });

  const { triggerAutoPrompt, cancelAutoPrompt, isGeneratingPrompt } =
    useAutoPrompt({
      inferenceSettings,
      getCompositeImage: layersCompositorRef.current?.getCompositedImage,
      setInferenceSettings,
    });

  const { width, height } = getElementSize(props.drawing);
  const userData: WorkbenchElementContainerUserData = {
    workbenchObjectType: 'container',
    elementId: props.drawing.id,
    elementTypename: 'Drawing',
    elementWidth: width,
    elementHeight: height,
    elementX: props.drawing.x,
    elementY: props.drawing.y,
    elementZIndex: props.drawing.zIndex,
    multiFocused: false,
    cursor: 'auto',
    singleFocused: true,
  };

  useKeyboardShortcut('z', undoAction, { ctrl: true });
  useKeyboardShortcut('z', redoAction, { ctrl: true, shift: true });
  useKeyboardShortcut(['Delete', 'Backspace'], () => {
    if (activeLayerId && activeLayerId.split('/').length > 1) {
      handleAction({
        type: 'updateBulkLayers',
        deletedLayerIds: activeLayerId.split('/'),
      });
      return;
    }

    if (activeLayer && !selectionApi.getState().hasMask) {
      handleAction({
        type: 'deleteLayer',
        id: activeLayer.id,
      });
    }
  });
  useSelectionShortcuts(selectionApi);

  const completeOnboardingStep = useCompleteOnboardingStep();
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};

  const exitStudioMode = () => {
    if (!onboardingStatus['workbench-tour-back-infinite-canvas']) {
      completeOnboardingStep(OnboardingStepName.BackInfiniteCanvas);
    }

    if (hasUnsavedChanges) {
      const compositedImage = layersCompositorRef.current.getCompositedImage();
      // if there's some unsaved changes, export the drawing thumbnail here, before exiting
      // studio mode and losing the canvas context
      _handleAction({
        type: 'updateDrawingThumbnail',
        thumbnail: compositedImage,
        final: true,
      });
    }

    props._onExit();
  };

  useImageStudioHotkeys({
    readOnly: !drawing?.isEditable,
    getCompositedImage: layersCompositorRef.current?.getCompositedImage,
    selectionApi,
  });

  const applyHistoryItem = (inferenceSettings: InferenceSettings) => {
    if (selectedPromptHistoryItem?.prompt.maskImagePath) {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = selectedPromptHistoryItem.prompt.maskImagePath;
      img.onload = () => {
        selectionApi.getState().setSelectionImage(img);
      };
    }
    setInferenceSettings(inferenceSettings);
    setSelectedPromptHistoryItem(undefined);
  };

  const controls = useMapControls();
  const camera = useThree((s) => s.camera as OrthographicCamera);

  const hasStylus = useHasStylus();
  useInitDrawWithFinger({ hasStylus });

  const loadingFallback = (
    <>
      <CustomImage
        scale={[props.drawing.drawingWidth, props.drawing.drawingHeight]}
        url={props.drawing.thumbnailPath}
      />
    </>
  );

  if (!drawing) {
    // loading state
    return (
      <group
        position={[
          props.drawing.x,
          props.drawing.y,
          props.drawing.zIndex + 0.5,
        ]}
        userData={userData}
        scale={[
          props.drawing.workbenchSizeRatio,
          props.drawing.workbenchSizeRatio,
          1,
        ]}
      >
        {loadingFallback}
      </group>
    );
  }

  const drawingSize: [number, number] = [drawing.width, drawing.height];

  const cameraLimits = getCameraLimitsFromActiveDrawing(props.drawing);
  const onFitToScreen = () => {
    const zoomForWidth =
      (camera.right - camera.left) / (cameraLimits.xMax - cameraLimits.xMin);
    const zoomForHeight =
      (camera.top - camera.bottom) / (cameraLimits.yMax - cameraLimits.yMin);
    const zoomTarget = Math.min(zoomForWidth, zoomForHeight);

    controls.moveTo({
      x: layout === 'default' ? 0 : HALF_UI_WIDTH / zoomTarget,
      y: 0,
      zoom: zoomTarget,
      controlled: true,
      skipAnimation: true,
    });
  };

  const shouldShowHiddenLayerToast = () => {
    if (
      props.state !== 'entered' ||
      !activeLayer ||
      selectedOutputId ||
      selectedPromptHistoryItem
    ) {
      return false;
    }

    if (isPaintingTool(toolState.tool)) {
      return !activeLayer.visible;
    }
  };

  const shouldShowMultiSelectionToast =
    activeLayerId && activeLayerId.split('/').length > 1;

  const handleShowInferenceTrayExitButtonTooltip = () => {
    const inferenceModeActive = selectedPromptId !== undefined;
    if (!inferenceModeActive) return;

    useTooltipNotificationState
      .getState()
      .setDisplay(TooltipNotificationName.ExitInferenceTray, true);
  };

  const handleEndTour = () => {
    updateState({
      input: {
        key: UserClientStateKeys.OnboardingStatus,
        value: {
          ...onboardingStatus,
          v_1_1: true,
        },
      },
    });
  };

  return (
    <>
      <SelectionApiContextProvider value={selectionApi}>
        <WorkbenchStudioCamera
          state={props.state}
          cameraLimits={cameraLimits}
          drawingPosition={[props.drawing.x, props.drawing.y]}
          drawingWorkbenchSizeRatio={props.drawing.workbenchSizeRatio}
          offsetX={layout === 'default' ? 0 : HALF_UI_WIDTH}
        />
        {(props.state === 'entering' || props.state === 'entered') && (
          <>
            <WorkbenchViewportControls
              collapsed={fullscreen}
              cameraLimits={cameraLimits}
              onFitToScreen={onFitToScreen}
              prependControls={
                <RichTooltip trigger="hover" padding={11}>
                  <RichTooltipTrigger>
                    <Button
                      onClick={() => setFullscreen((f) => !f)}
                      size="iconSquared"
                      variant="transparent"
                    >
                      {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </Button>
                  </RichTooltipTrigger>
                  <RichTooltipContent style={{ color: theme.white }}>
                    {fullscreen ? 'Minimize' : 'Fullscreen'}
                  </RichTooltipContent>
                </RichTooltip>
              }
              appendControls={
                <WorkbenchHelpMenu context="Drawing" fullscreen={fullscreen} />
              }
            />
            <WorkbenchStudioCameraGestures cameraLimits={cameraLimits} />
          </>
        )}
        <GlobalCursor activeLayer={activeLayer} drawingSize={drawingSize} />

        <group
          userData={userData}
          renderOrder={0}
          visible={props.state === 'entering' || props.state === 'entered'}
        >
          <FlippableGroup>
            <group renderOrder={2}>
              <SelectionTools
                drawing={drawing}
                layer={activeLayer}
                drawingSize={drawingSize}
                handleAction={handleAction}
                setActiveLayer={setActiveLayerId}
                isInferenceRunning={selectedPromptId !== undefined}
                getCompositedImage={
                  layersCompositorRef.current?.getCompositedImage
                }
              />
            </group>
            {/* Using two groups, parent one for the roate, child for the flip to not isolate the different rotation and correctly composite them */}
            <LayersCompositor
              drawingSize={drawingSize}
              ref={layersCompositorRef}
              layersOrder={[
                ...sortByOrderKey(drawing.layers.nodes).map((l) => l.id),
              ].reverse()}
            >
              {shouldShowHiddenLayerToast() && (
                <ToastIndicator
                  type="default"
                  text="This layer is hidden, make it visible to edit it"
                />
              )}
              {shouldShowMultiSelectionToast && (
                <ToastIndicator
                  type="default"
                  text="Select a single layer to edit it"
                />
              )}

              <group renderOrder={2}>
                <Suspense fallback={loadingFallback}>
                  {sortByOrderKey(drawing.layers.nodes).map((layer) => (
                    <CompositeLayer
                      layer={layer}
                      key={layer.id}
                      active={layer.id === activeLayer?.id}
                      drawingSize={drawingSize}
                      handleAction={handleAction}
                      filterHistory={filterHistory}
                      visible={viewerVisibility[layer.id] ?? layer.visible}
                    />
                  ))}
                </Suspense>
              </group>

              {(toolState.tool === WorkbenchStudioTool.Move ||
                activeLayer?.metadata3D) && (
                <LayerPicker
                  width={drawing.width}
                  height={drawing.height}
                  setActiveLayerId={setActiveLayerId}
                />
              )}
              <CompositedLayersMesh
                width={drawing.width}
                height={drawing.height}
                meshProps={{
                  renderOrder: 1,
                }}
              />

              <mesh
                renderOrder={0}
                onDoubleClick={(e) => e.stopPropagation()}
                onBeforeRender={(_r, _s, camera, _g, material) => {
                  const { zoom } = camera as OrthographicCamera;
                  const { uniforms } = material as ShaderMaterial;
                  uniforms.checkboardSize.value = 8 / zoom;
                }}
              >
                <planeGeometry args={[drawing.width, drawing.height, 1, 1]} />
                <checkboardMaterial
                  scale={[drawing.width, drawing.height]}
                  side={DoubleSide}
                />
              </mesh>

              <mesh renderOrder={-1} onDoubleClick={exitStudioMode}>
                <planeGeometry
                  args={[drawing.width * 100, drawing.height * 100, 1, 1]}
                />
                <meshBasicMaterial transparent opacity={0} side={DoubleSide} />
              </mesh>

              <Suspense fallback={<LoadingPlaceholder />}>
                <InferencePreview
                  imagePath={
                    selectedPrompt?.outputs.nodes.find(
                      (output) => output.id === selectedOutputId
                    )?.imagePath
                  }
                  visible={!previewHidden && selectedPromptId !== 'loading'}
                  scale={[drawing.width, drawing.height]}
                  selectedPromptHistoryItem={selectedPromptHistoryItem}
                />
              </Suspense>

              {toolState.symmetry.enabled && (
                <SymmetryAxis
                  drawingSize={drawingSize}
                  handleAction={handleAction}
                />
              )}

              <CanvasColorPicker
                width={drawing.width}
                height={drawing.height}
              />

              <HtmlOverlay>
                {!drawing.initiated && drawing.isEditable && (
                  <NewDrawingModal
                    drawing={drawing}
                    handleAction={handleAction}
                    handleInitiateDrawing={handleInitiateDrawing}
                  />
                )}
                <OnboardingMultiStepProvider
                  active={drawing.initiated}
                  totalSteps={4}
                  onLastStep={handleEndTour}
                >
                  <WorkbenchStudioToolbar
                    drawing={drawing}
                    disabled={
                      Boolean(selectedPromptId) ||
                      Boolean(selectedPromptHistoryItem)
                    }
                    handleAction={handleAction}
                    state={props.state}
                    activeLayer={activeLayer}
                    setActiveLayerId={setActiveLayerId}
                    handleUndo={undoAction}
                    handleRedo={redoAction}
                    onExit={exitStudioMode}
                    canUndo={canUndo}
                    canRedo={canRedo}
                    setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
                    onClick={handleShowInferenceTrayExitButtonTooltip}
                  />
                  {connected && (
                    <WorkbenchStudioContextMenu target={connected} />
                  )}
                  <div onClick={handleShowInferenceTrayExitButtonTooltip}>
                    {layout === 'classic' ? (
                      <WorkbenchStudioCombinedUI
                        hidden={fullscreen}
                        drawing={drawing}
                        state={props.state}
                        anyOutputLoading={anyOutputLoading}
                        inferenceSettings={inferenceSettings}
                        setInferenceSettings={setInferenceSettings}
                        handleAction={handleAction}
                        trigger={trigger}
                        triggerAutoPrompt={triggerAutoPrompt}
                        isGeneratingPrompt={isGeneratingPrompt}
                        cancelAutoPrompt={cancelAutoPrompt}
                        triggerPaywallModal={() => setPaywallModalOpen(true)}
                        selectedPromptHistoryItem={selectedPromptHistoryItem}
                        applyHistoryItem={applyHistoryItem}
                        uiRatio={uiRatio}
                        activeLayerId={activeLayerId}
                        setActiveLayerId={setActiveLayerId}
                        viewerVisibility={viewerVisibility}
                        setViewerVisibility={setViewerVisibility}
                        onCreateDrawingFromImage={
                          props.onCreateDrawingFromImage
                        }
                        layersDisabled={
                          Boolean(selectedPromptId) ||
                          Boolean(selectedPromptHistoryItem) ||
                          toolState.tool === WorkbenchStudioTool.AutoSelection
                        }
                        setSelectedPromptHistoryItem={
                          setSelectedPromptHistoryItem
                        }
                      />
                    ) : (
                      <>
                        <WorkbenchStudioLayers
                          hidden={fullscreen}
                          disabled={
                            Boolean(selectedPromptId) ||
                            Boolean(selectedPromptHistoryItem) ||
                            toolState.tool === WorkbenchStudioTool.AutoSelection
                          }
                          historyOpen={historyOpen}
                          drawing={drawing}
                          handleAction={handleAction}
                          state={props.state}
                          activeLayerId={activeLayerId}
                          setActiveLayerId={setActiveLayerId}
                          viewerVisibility={viewerVisibility}
                          setViewerVisibility={setViewerVisibility}
                          onCreateDrawingFromImage={
                            props.onCreateDrawingFromImage
                          }
                          uiRatio={uiRatio}
                          setUiRatio={setUiRatio}
                          setSelectedPromptHistoryItem={
                            setSelectedPromptHistoryItem
                          }
                        />
                        <WorkbenchStudioCreate
                          hidden={fullscreen}
                          drawing={drawing}
                          state={props.state}
                          anyOutputLoading={anyOutputLoading}
                          inferenceSettings={inferenceSettings}
                          setInferenceSettings={setInferenceSettings}
                          handleAction={handleAction}
                          trigger={trigger}
                          triggerAutoPrompt={triggerAutoPrompt}
                          isGeneratingPrompt={isGeneratingPrompt}
                          cancelAutoPrompt={cancelAutoPrompt}
                          triggerPaywallModal={() => setPaywallModalOpen(true)}
                          selectedPromptHistoryItem={selectedPromptHistoryItem}
                          applyHistoryItem={applyHistoryItem}
                          uiRatio={uiRatio}
                          setIsPrompting={toolState.setIsPrompting}
                        />
                      </>
                    )}
                  </div>
                  <WorkbenchStudioHistory
                    disabled={selectedPromptId !== undefined}
                    drawing={drawing}
                    open={historyOpen}
                    state={props.state}
                    setOpen={setHistoryOpen}
                    handleAction={handleAction}
                    selectedPromptHistoryItem={selectedPromptHistoryItem}
                    setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
                    setActiveLayerId={setActiveLayerId}
                  />
                  <InferenceTray
                    drawing={drawing}
                    selectedPrompt={selectedPrompt}
                    trigger={trigger}
                    handleAction={handleAction}
                    previewHidden={previewHidden}
                    setPreviewHidden={setPreviewHidden}
                    triggerPaywallModal={() => setPaywallModalOpen(true)}
                    selectedPromptId={selectedPromptId}
                    setSelectedPromptId={setSelectedPromptId}
                    selectedOutputId={selectedOutputId}
                    setSelectedOutputId={setSelectedOutputId}
                  />
                  <PaywallModal
                    isOpen={paywallModalOpen}
                    onClose={() => setPaywallModalOpen(false)}
                    orgId={drawing.workbench?.folder?.organization?.id}
                  />
                  {drawing.initiated && drawing.isEditable && (
                    <WorkbenchStudioFileDropper
                      handleAction={handleAction}
                      drawing={drawing}
                      activeLayerId={activeLayer?.id}
                      setActiveLayerId={setActiveLayerId}
                      triggerAutoPrompt={triggerAutoPrompt}
                      hasPrompt={inferenceSettings.prompt.trim().length > 0}
                    />
                  )}
                  <WorkbenchStudioDebug />
                  <WorkbenchStudioMenu
                    drawing={drawing}
                    onExit={exitStudioMode}
                    handleAction={handleAction}
                    triggerPaywallModal={() => setPaywallModalOpen(true)}
                    setShowOverlay={setShowOverlay}
                    setActiveLayerId={setActiveLayerId}
                    activeLayerId={activeLayer?.id ?? ''}
                  />
                  {showOverlay && <FullPageDarkLoader />}
                </OnboardingMultiStepProvider>
              </HtmlOverlay>
              {debugMode && <StatsGl />}
              <WorkbenchCollaborationToolbar
                selfPresence={props.selfPresence}
                workbenchId={drawing.workbenchId}
                selectedPresence={props.selectedPresence}
                setSelectedPresenceId={props.setSelectedPresenceId}
                multiplayerPresences={props.multiplayerPresences}
              >
                <PublicFileSharingButton
                  workbenchId={drawing.workbenchId}
                  drawing={drawing}
                  triggerPaywallModal={() => setPaywallModalOpen(true)}
                />
              </WorkbenchCollaborationToolbar>
            </LayersCompositor>
          </FlippableGroup>
        </group>
      </SelectionApiContextProvider>
    </>
  );
};
