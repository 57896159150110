import { useRecoilState } from 'recoil';
import { paywallModalOpen } from './paywallModalState';
import { RecoilPaywallModal } from './';
import { useSelectedOrganization } from '../hooks/useSelectedOrganization';

export const GlobalPaywallModal = () => {
  const [modalOpen, setModalOpen] = useRecoilState(paywallModalOpen);

  return (
    <RecoilPaywallModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
    />
  );
};
