import { Navigate, Route, Routes } from 'react-router-dom';
import { LoggedInAuthGuard } from '@vizcom/auth/ui';
import { paths } from '@vizcom/shared-utils-paths';
import { WorkbenchPage } from './WorkbenchPage';
import { NewWorkbenchPage } from './NewWorkbenchPage';
import { MobileImageUpload } from './components/MobileWorkbenchActions/MobileImageUpload';

const WORKBENCH_ROUTES_PREFIX = '/workbench';

export const WorkbenchRoutes = () => {
  return (
    <LoggedInAuthGuard acceptSharingSecret={true}>
      <Routes>
        <Route
          path={paths.workbench
            .new(':folderId')
            .replace(WORKBENCH_ROUTES_PREFIX + '/', '')}
          element={<NewWorkbenchPage />}
        />
        <Route
          path={paths.workbench
            .compositeSceneEditor(':workbenchId', ':compositeSceneId')
            .replace(WORKBENCH_ROUTES_PREFIX + '/', '')}
          element={<WorkbenchPage />}
        />
        <Route
          path={paths.workbench
            .drawing(':workbenchId', ':drawingId')
            .replace(WORKBENCH_ROUTES_PREFIX + '/', '')}
          element={<WorkbenchPage />}
        />
        <Route
          path={paths.workbench
            .file(':workbenchId')
            .replace(WORKBENCH_ROUTES_PREFIX + '/', '')}
          element={<WorkbenchPage />}
        />
        <Route path="*" element={<Navigate to={paths.files.dashboard()} />} />
      </Routes>
    </LoggedInAuthGuard>
  );
};

// TODO: consider moving these routes to a new module after figuring out
// how we create modules with nx (note by @toonikolai)
const MOBILE_WORKBENCH_ROUTES_PREFIX = '/mobile-workbench';
export const MobileWorkbenchRoutes = () => {
  return (
    <LoggedInAuthGuard acceptSharingSecret={true} isMobileAccessible={true}>
      <Routes>
        <Route
          path={paths.mobileWorkbench
            .upload(':workbenchId')
            .replace(MOBILE_WORKBENCH_ROUTES_PREFIX + '/', '')}
          element={<MobileImageUpload />}
        />
      </Routes>
    </LoggedInAuthGuard>
  );
};
