import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';

// This action is a only used to represent in the undo/redo queue a user action mutating a local state
// it doesn't do anything by itself, but let the caller set its own method to execute when action is added to the queue
// and method to construct the undo action
export const MutateLocalStateAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'mutateLocalState';
    actionId?: string;
    onExecute: () => void;
    undoConstructor: () => SyncedActionPayloadFromType<
      typeof MutateLocalStateAction
    >;
  }
> = {
  type: 'mutateLocalState',
  // no-op
  optimisticUpdater: () => {},
  // no-op
  remoteUpdater: async () => {},
  onAddedToQueue: (action) => {
    action.payload.onExecute();
  },
  undoConstructor: (action) => {
    return action.payload.undoConstructor();
  },
};
