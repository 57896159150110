import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { Files } from '@vizcom/web/features/files';
import { useIdentifyMixpanelUser } from '@vizcom/shared-data-access-analytics';
import { AuthPages } from './pages/auth';
import {
  MoveModal,
  GlobalPaywallModal,
  ToastsContainer,
  DirectModalContainer,
  GlobalChangelogModal,
  useHasStylus,
  isOutdatedElectron,
  bottomScreenTunnel,
} from '@vizcom/shared-ui-components';
import * as Sentry from '@sentry/react';
import { useSyncUserInSentryContext } from '@vizcom/shared/data-access/sentry';
import { lazy } from 'react';
import { SettingsPage } from './pages/settings';
import { OrganizationRoutes } from './pages/organization';
import { LoggedInAuthGuard } from '@vizcom/auth/ui';
import { RootRedirectorPage } from './pages/RootRedirector';
import { OnboardingRoutes } from './pages/onboarding/onboardingRoutes';
import { UpgradeSubscriptionRoutes } from './pages/upgradeSubscription/UpgradeSubscriptionRoutes';
import { DeprecatedElectronPage } from './pages/DeprecatedElectronPage';

import styled from 'styled-components';
import { MobileWorkbenchRoutes } from '@vizcom/web/features/workbench';

const WorkbenchRoutes = lazy(async () => ({
  default: (await import('@vizcom/web/features/workbench')).WorkbenchRoutes,
}));
const AdminFeatureShell = lazy(async () => ({
  default: (await import('@vizcom/admin/feature-shell')).AdminFeatureShell,
}));

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const UiTunnelContainer = styled.div`
  // Position the container at the bottom-center of the screen
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;

  // Stack children vertically with a gap of 10px
  display: flex;
  gap: 10px;
  flex-direction: column;

  // Disable pointer events on the container, but enable them on the children
  pointer-events: none;
  > div {
    pointer-events: all;
  }
`;

const Wrapper = () => {
  return (
    <>
      <Outlet />
      <GlobalPaywallModal />
      <MoveModal />
      <bottomScreenTunnel.In>
        <ToastsContainer />
      </bottomScreenTunnel.In>
      <UiTunnelContainer>
        <bottomScreenTunnel.Out />
      </UiTunnelContainer>
      <DirectModalContainer />
      <GlobalChangelogModal />
    </>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<Wrapper />}>
      <Route path="/admin/*" element={<AdminFeatureShell />} />
      <Route path="/auth/*" element={<AuthPages />} />
      <Route path="/files/*" element={<Files />} />
      <Route path="/settings/*" element={<SettingsPage />} />
      <Route path="/upgrade/*" element={<UpgradeSubscriptionRoutes />} />
      <Route path={`/workbench/*`} element={<WorkbenchRoutes />} />
      <Route path="/mobile-workbench/*" element={<MobileWorkbenchRoutes />} />
      <Route path="/organization/*" element={<OrganizationRoutes />} />
      <Route path="/onboarding/*" element={<OnboardingRoutes />} />
      <Route
        path="/"
        element={
          <LoggedInAuthGuard>
            <RootRedirectorPage />
          </LoggedInAuthGuard>
        }
      />
      <Route path="*" element={<Navigate replace to="/auth" />} />
    </Route>
  )
);

export function App() {
  useIdentifyMixpanelUser();
  useSyncUserInSentryContext();
  // Start detection of stylus app-wide, useful in brush-studio
  useHasStylus();

  if (isOutdatedElectron()) {
    return <DeprecatedElectronPage />;
  }

  return <RouterProvider router={router} />;
}

export default App;
