import { WarningIcon, Text, Button } from '@vizcom/shared-ui-components';

/*
 * This component is used to display a confirmation modal with a title and description.
 * It should be used  with the triggerConfirmModal function.
 *
 * @param onConfirm - The function to call when the user confirms the action.
 * @param onCancel - The function to call when the user cancels the action.
 * @param title - The title of the modal.
 * @param description - The description of the modal.
 *
 * @example
 * ```tsx
 * triggerConfirmModal({
 *  body: (resolve, reject) => (
 *   <ConfirmationModalComponent
 *     onConfirm={resolve}
 *     onCancel={reject}
 *     title="Are you sure?"
 *     description="This action cannot be undone."
 *   />
 *  ),
 * });
 * ```
 */
export function ConfirmationModalComponent({
  onConfirm,
  onCancel,
  title,
  description,
}: {
  onConfirm: (value: unknown) => void;
  onCancel: (reason?: any) => void;
  title: string;
  description: string;
}) {
  return (
    <div
      style={{
        maxWidth: 350,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <Text block type="sh2" color="default">
          {title}
        </Text>
      </div>
      <br />
      <Text block color="info">
        {description}
      </Text>
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          gap: 10,
        }}
      >
        <Button
          variant="secondary"
          onClick={onCancel}
          style={{ flex: 1 }}
          data-close-modal
        >
          Cancel
        </Button>
        <Button
          variant="warning"
          onClick={onConfirm}
          style={{ flex: 1 }}
          data-close-modal
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}
