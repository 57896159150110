import { RequirementItem, RequirementList } from './styles';

const PASSWORD_REQUIREMENTS_LABEL = {
  lowercase: 'Includes lowercase letter',
  uppercase: 'Includes uppercase letter',
  number: 'Includes a number',
  minLength: 'Is at least 8 characters long',
  passwordsMatch: 'Passwords match',
};

export const usePasswordValidation = (
  password: string,
  confirmPassword: string
) => {
  const passwordReq = {
    lowercase: /[a-z]/.test(password),
    uppercase: /[A-Z]/.test(password),
    number: /[0-9]/.test(password),
    minLength: password.length >= 8,
    passwordsMatch: password === confirmPassword,
  } as Record<keyof typeof PASSWORD_REQUIREMENTS_LABEL, boolean>;

  const passwordChecklist = (
    <RequirementList>
      {Object.entries(passwordReq).map(([key, valid]) => (
        <RequirementItem key={key} valid={valid}>
          {
            PASSWORD_REQUIREMENTS_LABEL[
              key as keyof typeof PASSWORD_REQUIREMENTS_LABEL
            ]
          }
        </RequirementItem>
      ))}
    </RequirementList>
  );

  return {
    passwordReq,
    passwordChecklist,
    valid: Object.values(passwordReq).every(Boolean),
  };
};
