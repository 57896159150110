import {
  Button,
  Text,
  useLastNonNullValue,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import { useOrganization } from '@vizcom/shared/data-access/graphql';
import { useState } from 'react';
import { useLayersCompositor } from '../../DrawingCompositor/LayersCompositor/context';
import { useExportUtils } from '../../lib/useExportUtils';

export const StudioExportButtons = (props: {
  drawing: Drawing2dStudio;
  triggerPaywallModal: () => void;
}) => {
  const organizationId = useLastNonNullValue(
    props.drawing.workbench?.team?.organization?.id
  );
  const { data: organization } = useOrganization(organizationId);
  const layersCompositor = useLayersCompositor();

  const [exporting, setExporting] = useState(false);

  const isFreePlan = organization?.subscriptionPlan === 'FREE';

  const { handleExportToVideo, handleExportToPng, exportPSD, exportAllModels } =
    useExportUtils(props.drawing, isFreePlan);

  const handleExportToVideoWrapper = () => handleExportToVideo(setExporting);
  const handleExportToPngWrapper = (upscale: boolean) =>
    handleExportToPng(
      upscale,
      setExporting,
      props.triggerPaywallModal,
      layersCompositor
    );
  const exportPSDWrapper = () => exportPSD(setExporting);
  const exportAllModelsWrapper = () =>
    exportAllModels(setExporting, props.triggerPaywallModal);

  return (
    <InsertActionButtonContainer>
      <InsertActionButton
        variant="transparent"
        onClick={() => handleExportToPngWrapper(false)}
        disabled={exporting}
      >
        <Text>Image · 1x</Text>
        <Size>
          {props.drawing.width} x {props.drawing.height}
        </Size>
      </InsertActionButton>
      <InsertActionButton
        variant="transparent"
        onClick={() => handleExportToPngWrapper(true)}
        disabled={exporting}
      >
        <OptionContainer>
          <Text>Image · 2x</Text>
          {isFreePlan && <ProPill>PRO</ProPill>}
        </OptionContainer>
        <Size>
          {props.drawing.width * 2} x {props.drawing.height * 2}
        </Size>
      </InsertActionButton>
      <Divider />
      <InsertActionButton
        variant="transparent"
        onClick={handleExportToVideoWrapper}
        disabled={exporting}
      >
        <Text style={{ flex: 1 }}>
          Video ·{' '}
          <span style={{ color: '#7070F2' }}>Export a layer time-lapse</span>
        </Text>
        <Size>
          {props.drawing.width} x {props.drawing.height}
        </Size>
      </InsertActionButton>
      {organization?.subscriptionPlan === 'FREE' && (
        <div style={{ padding: '10px 16px' }}>
          <Text>Upgrade your plan to remove Vizcom watermark</Text>
        </div>
      )}
      <Divider />
      <InsertActionButton
        variant="transparent"
        onClick={exportPSDWrapper}
        disabled={exporting}
      >
        <Text>PSD · 1x</Text>
        <Size>
          {props.drawing.width} x {props.drawing.height}
        </Size>
      </InsertActionButton>
      <Divider />
      <InsertActionButton
        variant="transparent"
        onClick={exportAllModelsWrapper}
        disabled={exporting}
      >
        <OptionContainer>
          <Text>3D · All models</Text>
          {isFreePlan && <ProPill>PRO</ProPill>}
        </OptionContainer>
        <Size>ZIP</Size>
      </InsertActionButton>
    </InsertActionButtonContainer>
  );
};

const InsertActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

const InsertActionButton = styled(Button)`
  display: flex;
  gap: 7px;
  padding: 10px 16px;
  text-align: left;
  align-items: flex-start;
  justify-content: space-between;
`;

const Divider = styled.div`
  background-color: ${(p) => p.theme.surface.e2};
  height: 1px;
`;

const Size = styled(Text)`
  color: ${({ theme }) => theme.text.info};
  text-align: right;
  font-feature-settings: 'calt';
`;

const OptionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ProPill = styled.div`
  width: 33px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.chip.primary.text};
  border: 0.5px solid ${({ theme }) => theme.chip.primary.text};
  background: ${({ theme }) => theme.chip.primary.background};
  font-size: 10px;
  font-weight: 600;
`;
