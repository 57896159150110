import {
  addToast,
  browseForFiles,
  resizeImageToPixelCount,
} from '@vizcom/shared-ui-components';
import { MAX_Z_POSITION, getWorkbenchElementZPositionRange } from '../helpers';
import { masonryLayoutBestFit } from '../utils/layoutHelpers';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { v4 as uuidv4 } from 'uuid';
import { filterExists } from '@vizcom/shared/js-utils';
import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../../constants';
import toast from 'react-hot-toast';
import { Object3D } from 'three';

export const importFilesToWorkbench = async ({
  handleAction,
  maxZIndex,
  targetPosition,
}: {
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  maxZIndex: number;
  targetPosition: [number, number];
}) => {
  const files = await browseForFiles({ accept: 'image/*', capture: 'camera' });
  const toastId = addToast('Importing files', {
    loading: true,
    duration: Infinity,
  });

  try {
    const filesData = (
      await Promise.all(
        files.map(async (file) => {
          try {
            const resized = await resizeImageToPixelCount(
              file,
              WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
            );
            return resized;
          } catch (e) {
            addToast(
              `The file you're trying to upload is not a valid image: ${file.name}`,
              { type: 'danger' }
            );
          }
        })
      )
    ).filter(filterExists);

    if (filesData.length === 0) {
      return;
    }

    const zIndex = isFinite(maxZIndex) ? maxZIndex + 1 : MAX_Z_POSITION / 2;

    const workbenchSizeRatio = 0.2;

    const positions = masonryLayoutBestFit(
      filesData.map(({ width, height }) => ({
        width: width * workbenchSizeRatio,
        height: height * workbenchSizeRatio,
        id: uuidv4(),
        x: targetPosition[0],
        y: targetPosition[1],
      })),
      2
    );

    handleAction({
      type: 'createElements',
      newElements: filesData.map((file, index) => {
        const { id, x, y } = positions[index];
        return {
          __typename: 'Drawing',
          updatedAt: '0',
          id,
          x,
          y,
          drawingWidth: file.width,
          drawingHeight: file.height,
          zIndex: zIndex + index,
          workbenchSizeRatio,
          image: file.image,
          name: '',
        };
      }),
    });
  } finally {
    toast.dismiss(toastId);
  }
};

export const handleImportFilesWithSceneContext = async ({
  scene,
  camera,
  handleAction,
}: {
  scene: Object3D;
  camera: Object3D;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  const zRange = getWorkbenchElementZPositionRange(scene) as [number, number];
  const targetPosition: [number, number] = [
    camera.position.x,
    camera.position.y,
  ];
  await importFilesToWorkbench({
    handleAction,
    maxZIndex: zRange[1],
    targetPosition,
  });
};
