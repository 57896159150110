// DirectModal is a helper tool to display a modal element without adding any additional react element in the caller component
// The modal is global to the whole app

import { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from '../Modal/ModalItems';

type DirectModalContent =
  | {
      title: React.ReactNode;
      content?: React.ReactNode;
      action?: React.ReactNode;
    }
  | {
      body: React.ReactNode;
    };

let _triggerDirectModal = null as
  | null
  | ((content: DirectModalContent) => void);

// This is used to prevent having to use a context and a hook in each producer of Modal
// we make the assumption that we'll always ever have one and only one DirectModalContainer
export const triggerDirectModal = (content: DirectModalContent) => {
  _triggerDirectModal?.(content);
};

export const DirectModalContainer = () => {
  const [modal, setModal] = useState<{
    displayed: boolean;
    content?: DirectModalContent;
  }>({ displayed: false });

  useEffect(() => {
    _triggerDirectModal = (newContent) => {
      setModal({ displayed: true, content: newContent });
    };
    return () => {
      _triggerDirectModal = null;
    };
  }, []);

  const handleSetIsOpen = (open: boolean) => {
    if (!open) {
      setModal((c) => ({ ...c, displayed: false }));
    }
  };

  return (
    <Modal isOpen={modal.displayed} setIsOpen={handleSetIsOpen}>
      {modal.content && 'body' in modal.content ? (
        modal.content.body
      ) : (
        <>
          <ModalHeader>
            <ModalTitle>{modal.content?.title}</ModalTitle>
            <ModalCloseButton />
          </ModalHeader>
          {modal.content?.content && (
            <ModalContent>{modal.content?.content}</ModalContent>
          )}
          {modal.content?.action && (
            <ModalActions>{modal.content.action}</ModalActions>
          )}
        </>
      )}
    </Modal>
  );
};
