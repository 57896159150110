import {
  ChangeEventHandler,
  MouseEventHandler,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Dropdown,
  NumberInput,
  SparklesIcon,
  Text,
  TextArea,
  TextInput,
  addToast,
  styledScrollbar,
} from '@vizcom/shared-ui-components';
import {
  ImageInferenceType,
  WorkbenchElementImg2ImgData,
} from '@vizcom/shared/data-access/graphql';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  ExtraHorizontalDelimiter,
  WorkbenchElementExtra,
} from '../../WorkbenchElementExtra';
import { CustomHtml } from '../../utils/CustomHtml';
import {
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import { WorkbenchElementDragConnector } from '../../elementConnector/workbenchElementDragConnector';
import styled, { useTheme } from 'styled-components';
import { useThree } from '@react-three/fiber';
import {
  GENERATED_IMAGES_MARGIN,
  isDraggingContext,
  useIsWorkbenchViewer,
} from '../../../lib/utils';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import { findFirstFreeSlotInScene } from '../../utils/freeSlotFinders';
import {
  PublicPalette,
  promptLegacyTypeToModeAndPalette,
} from '@vizcom/shared/inference-worker-queues';
import { capitalize } from '../../../../../../../shared/js-utils/src';
import { Img2ImgPaletteSelector } from './Img2ImgPaletteSelector';
import { useAvailablePalettes } from '../../modals/PaletteSelector';
import { getNewPromptFromTags } from '../palette/helpers';
import { ZoomFallbackGroup } from '../../utils/ZoomFallbackGroup';

const Img2ImgTextArea = styled(TextArea)`
  resize: none;
  padding: 8px;

  ::placeholder {
    color: ${(p) => p.theme.text.info};
  }

  :focus {
    box-shadow: none;
  }

  ${styledScrollbar}
`;

interface WorkbenchElementImg2ImgProps {
  workbenchId: string;
  element: WorkbenchElementImg2ImgData;
  focused: boolean;
  singleFocused: boolean;
  isDragging: boolean;
  isResizing: boolean;
  showSingleFocusedControls: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const WorkbenchElementImg2Img = ({
  element,
  focused,
  singleFocused,
  workbenchId,
  isDragging,
  isResizing,
  handleAction,
  showSingleFocusedControls,
}: WorkbenchElementImg2ImgProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const scene = useThree((s) => s.scene);
  const isDraggingRef = useContext(isDraggingContext);
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const [cursor, setCursor] = useState<[number | null, number | null] | null>(
    null
  );
  const availablePalettes = useAvailablePalettes(workbenchId);

  useLayoutEffect(() => {
    if (inputRef.current) {
      const cursor1 = cursor?.[0] ?? inputRef.current.selectionStart;
      const cursor2 = cursor?.[1] ?? inputRef.current.selectionEnd;

      [inputRef.current.selectionStart, inputRef.current.selectionEnd] = [
        cursor1,
        cursor2,
      ];

      inputRef.current.value = element.prompt;
    }
  }, [element.prompt, cursor]);

  const handlePromptChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value, selectionStart, selectionEnd },
  }) => {
    setCursor([selectionStart, selectionEnd]);
    handleAction({
      type: 'updateAiImg2Img',
      elementId: element.id,
      prompt: value,
    });
  };
  const handleSourceImageInfluence = (sourceImageInfluence: number) => {
    handleAction({
      type: 'updateAiImg2Img',
      elementId: element.id,
      sourceImageInfluence: sourceImageInfluence / 100,
    });
  };
  const handleSubmit: MouseEventHandler = (e) => {
    if (isDraggingRef.current) {
      return;
    }
    e.preventDefault();
    if (!element.sourceDrawingId) {
      addToast('You need to connect this block to an image first', {
        type: 'danger',
      });
      return;
    }

    handleAction((elements) => {
      const target = elements.find((el) => el.id === element.sourceDrawingId);
      if (target?.__typename !== 'Drawing') {
        return;
      }
      const width = target.drawingWidth * target.workbenchSizeRatio;
      const height = target.drawingHeight * target.workbenchSizeRatio;
      const position = findFirstFreeSlotInScene(scene, {
        firstSlotX:
          element.x + element.width / 2 + width / 2 + GENERATED_IMAGES_MARGIN,
        firstSlotY: element.y,
        slotWidth: width + GENERATED_IMAGES_MARGIN,
        slotHeight: height + GENERATED_IMAGES_MARGIN,
        maxElementPerLine: 4,
      });
      const zRange = getWorkbenchElementZPositionRange(scene);

      return {
        type: 'triggerAiImg2Img',
        elementId: element.id,
        placeholderId: uuidv4(),
        x: position[0],
        y: position[1],
        width,
        height,
        zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : element.zIndex + 1,
      };
    });
  };

  const handleChangeImageInferenceType = (value: string) => {
    handleAction({
      type: 'updateAiImg2Img',
      elementId: element.id,
      imageInferenceType: value as ImageInferenceType,
    });
  };

  const { width, height } = getElementSize(element);

  const imageInferenceType = element.legacyType
    ? (promptLegacyTypeToModeAndPalette(
        element.legacyType
      ).mode.toUpperCase() as ImageInferenceType)
    : element.imageInferenceType;
  const publicPaletteId = element.legacyType
    ? promptLegacyTypeToModeAndPalette(element.legacyType).publicPaletteId
    : element.publicPaletteId;

  const handleChangePalette = (paletteId: string, paletteType: string) => {
    handleAction((elements) => {
      const currentElement = elements.find(
        (el) => el.id === element.id
      ) as WorkbenchElementImg2ImgData;
      if (!currentElement) return;

      const updates: Partial<WorkbenchElementImg2ImgData> = {
        [paletteType]: paletteId,
      };

      if (paletteType === 'workbenchPaletteId') {
        const selectedPalette = availablePalettes.find(
          (p) => p.value === paletteId
        );
        if (selectedPalette?.tags) {
          updates.prompt = getNewPromptFromTags(
            selectedPalette.tags,
            currentElement.prompt
          );
        }
      }

      return {
        type: 'updateAiImg2Img',
        elementId: element.id,
        ...updates,
      };
    });
  };

  const currentPaletteId =
    publicPaletteId ||
    element.workbenchPaletteId ||
    element.customModelId ||
    PublicPalette.generalV2;

  const selectedPalette =
    availablePalettes.find((palette) => palette.value === currentPaletteId) ??
    availablePalettes.find(
      (palette) =>
        palette.versions &&
        palette.versions.find((v) => v.value === currentPaletteId)
    ) ??
    availablePalettes[0];

  const fallback = (
    <mesh position={[0, 0, 0.5]}>
      <RoundedPlaneGeometry width={width} height={height} radius={7.5} />
      <meshBasicMaterial color={theme.surface.e0} />
    </mesh>
  );

  if (isResizing) {
    return fallback;
  }

  return (
    <>
      {showSingleFocusedControls && (
        <WorkbenchElementDragConnector
          element={element}
          handleAction={handleAction}
        />
      )}

      <ZoomFallbackGroup fallback={fallback}>
        <CustomHtml
          transform
          occlude="blending"
          scaleOcclusionGeometry={false}
          position={[0, 0, 0.5]}
          contentSize={{ width, height }}
          geometry={
            <RoundedPlaneGeometry radius={7.5} width={width} height={height} />
          }
        >
          <div
            style={{
              width,
              height,
              userSelect: 'none',
            }}
          >
            <Img2Img data-workbench-id={element.id}>
              <Img2ImgTextArea
                ref={inputRef}
                defaultValue={element.prompt}
                onChange={handlePromptChange}
                // workbench-role is used to programatically focus this field when creating a new img2img element
                data-workbench-role="promptInput"
                onKeyDown={(e) => e.stopPropagation()}
                // prevent using the cursor to select text from dragging the element itself
                onPointerMove={(e) => e.stopPropagation()}
                placeholder="What are you creating?"
                disabled={isViewer}
              />
              <GenerateButton
                onClick={handleSubmit}
                variant="primary"
                size="M"
                disabled={isViewer}
              >
                <SparklesIcon />
                <span>Generate</span>
              </GenerateButton>
            </Img2Img>
          </div>
        </CustomHtml>
      </ZoomFallbackGroup>

      {!isDragging && singleFocused && !isViewer && (
        <CustomHtml
          style={{ pointerEvents: 'none' }}
          position={[0, height / 2, 0.5]}
        >
          <WorkbenchElementExtra element={element} handleAction={handleAction}>
            <Dropdown
              value={imageInferenceType}
              setValue={handleChangeImageInferenceType}
              options={Object.values(ImageInferenceType).map(
                (imageInferenceType) => ({
                  data: imageInferenceType,
                })
              )}
              buttonProps={{
                variant: 'transparent',
                style: {
                  padding: '3px 8px',
                  alignSelf: 'stretch',
                },
              }}
              OptionComponent={(props) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flex: 1,
                  }}
                >
                  <Text>{capitalize(props.option)}</Text>
                </div>
              )}
              optionToValueMapper={(value) => value}
            >
              <div style={{ textAlign: 'left' }}>
                <Text style={{ fontSize: 8 }} block color="info">
                  Mode
                </Text>
                <Text type="b2" style={{ whiteSpace: 'nowrap' }}>
                  {capitalize(imageInferenceType)}
                </Text>
              </div>
            </Dropdown>
            <ExtraHorizontalDelimiter />
            <Img2ImgPaletteSelector
              availablePalettes={availablePalettes}
              selectedPaletteId={currentPaletteId}
              selectedPalette={selectedPalette}
              handleSelectPalette={handleChangePalette}
            />
            <ExtraHorizontalDelimiter />
            <NumberInput
              // handle legacy sourceImageInfluence values from 0 to 100
              value={
                element.sourceImageInfluence > 1
                  ? element.sourceImageInfluence
                  : element.sourceImageInfluence * 100
              }
              setValue={handleSourceImageInfluence}
              min={0}
              max={100}
            />
          </WorkbenchElementExtra>
        </CustomHtml>
      )}
    </>
  );
};

const Img2Img = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 0.5rem;
  background: ${({ theme }) => theme.surface.e0};
  border-radius: ${(p) => p.theme.borderRadius.default};
  color: ${({ theme }) => theme.text.default};
  ${TextInput} {
    flex: 1;
  }
`;

const GenerateButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: flex-end;
`;
