import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
  RelativeDate,
  Text,
} from '@vizcom/shared-ui-components';
import { SyncQueueSynchronizer } from '../lib/SyncQueueSynchronizer';

export const SyncErrorModal = ({
  syncQueueSynchronizer,
}: {
  syncQueueSynchronizer: SyncQueueSynchronizer;
}) => {
  return (
    <Modal
      isOpen={!!syncQueueSynchronizer.errorMessage}
      setIsOpen={() => null}
      loading={syncQueueSynchronizer.retrying}
    >
      <ModalTitle>
        <Text>There was an error while syncing your changes</Text>
      </ModalTitle>
      <ModalContent>
        <Text block>
          We couldn't synchronize your most recent change with the server.{' '}
          {syncQueueSynchronizer.lastRemoteUpdaterExecutedAt && (
            <>
              The last change was saved{' '}
              <RelativeDate
                date={
                  new Date(syncQueueSynchronizer.lastRemoteUpdaterExecutedAt)
                }
              />
              .{' '}
            </>
          )}
          Make sure you're still connected to the internet and try again. If it
          still fails, please contact the support.
        </Text>
        <Text block color="secondary" type="sh2" style={{ margin: '24px 0' }}>
          {syncQueueSynchronizer.errorMessage}
        </Text>
      </ModalContent>
      <ModalActions>
        <Button
          onClick={() => {
            syncQueueSynchronizer.resetErrorAndRetry();
          }}
        >
          Retry
        </Button>
      </ModalActions>
    </Modal>
  );
};
