import { create } from 'zustand';

type WorkbenchElementSelectionState = {
  // using a single string with the ids separated by '/' to easily compare with equality the
  // changes to the focused ids
  focusedElementsId: string;
  setFocusedElementsId: (ids: string) => void;
  removeElementFromFocus: (id: string) => void;
  addToFocus: (id: string) => void;
};

export const useWorkbenchElementSelectionState =
  create<WorkbenchElementSelectionState>()((set) => ({
    focusedElementsId: '',
    setFocusedElementsId: (id) => set({ focusedElementsId: id }),
    removeElementFromFocus: (id) =>
      set((state) => ({
        focusedElementsId: state.focusedElementsId
          .split('/')
          .filter((focusedId) => focusedId !== id)
          .join('/'),
      })),
    addToFocus: (id) =>
      set((state) => ({
        focusedElementsId: state.focusedElementsId
          ? `${state.focusedElementsId}/${id}`
          : id,
      })),
  }));
