import { Button, LoadingLogoInset, Modal } from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { useEffect, useState } from 'react';
import { useLayerCountByDrawingIds } from '@vizcom/shared/data-access/graphql';
import styled, { useTheme } from 'styled-components';
import toast from 'react-hot-toast';

let _triggerDeleteElementsModal = null as
  | null
  | ((
      deletedElements: ClientSideWorkbenchElementData[],
      resolve: (value?: unknown) => void,
      reject: (reason?: any) => void
    ) => void);

export const triggerDeleteElementsModalImpl = (
  deletedElements: ClientSideWorkbenchElementData[],
  resolve: (value?: unknown) => void,
  reject: (reason?: any) => void
) => {
  _triggerDeleteElementsModal?.(deletedElements, resolve, reject);
};

export const triggerDeleteElementsModal = (
  deletedElements: ClientSideWorkbenchElementData[]
) => {
  return new Promise((resolve, reject) => {
    triggerDeleteElementsModalImpl(deletedElements, resolve, reject);
  });
};

export const DeleteElementsModal = ({
  handleUndo,
}: {
  handleUndo: () => void;
}) => {
  const [modal, setModal] = useState<{
    displayed: boolean;
    elements?: ClientSideWorkbenchElementData[];
    resolve?: (value?: unknown) => void;
    reject?: (reason?: any) => void;
  }>({ displayed: false });
  const drawings = modal.elements?.filter((el) => el.__typename === 'Drawing');
  const theme = useTheme();
  const { data, fetching } = useLayerCountByDrawingIds(
    drawings?.map((el) => el.id)
  );

  useEffect(() => {
    _triggerDeleteElementsModal = (deletedElements, resolve, reject) => {
      setModal({ displayed: true, elements: deletedElements, resolve, reject });
    };
    return () => {
      _triggerDeleteElementsModal = null;
    };
  }, []);

  const handleSetIsOpen = (open: boolean) => {
    if (!open) {
      modal.reject?.();
      setModal({ displayed: false });
    }
  };

  const confirmDelete = () => {
    modal.resolve?.();
    setModal({ displayed: false });
    toast(
      (t) => (
        <div
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <div>Selection deleted</div>
          <Button
            variant="secondary"
            onClick={() => {
              handleUndo();
              toast.dismiss(t.id);
            }}
          >
            Undo
          </Button>
        </div>
      ),
      {
        duration: 5000,
        style: {
          backgroundColor: theme.surface.e0,
          color: theme.white,
          borderRadius: '16px',
          padding: '16px',
          height: '52px',
          margin: 0,
        },
      }
    );
  };

  if (fetching) {
    return (
      <Modal
        style={{
          backgroundColor: theme.surface.e0,
          padding: '16px',
          minWidth: '320px',
          width: '320px',
        }}
        isOpen={modal.displayed}
        setIsOpen={handleSetIsOpen}
      >
        <Container>
          <LoadingLogoInset />
        </Container>
      </Modal>
    );
  }

  if (!data && drawings?.length) {
    return (
      <Modal
        style={{
          backgroundColor: theme.surface.e0,
          padding: '16px',
          minWidth: '320px',
          width: '320px',
        }}
        isOpen={modal.displayed}
        setIsOpen={handleSetIsOpen}
      >
        <Container>
          <Title>
            There was a problem with your request, please try again.
          </Title>
          <Buttons>
            <Button
              type="button"
              variant="secondary"
              onClick={modal.reject}
              data-close-modal
            >
              Close
            </Button>
          </Buttons>
        </Container>
      </Modal>
    );
  }

  const drawingCount = drawings?.length || 0;
  const layerCount =
    drawings?.reduce(
      (acc, el) =>
        acc + (data?.find((d) => d.id === el.id)?.layers.totalCount || 1),
      0
    ) || 0;

  return (
    <Modal
      style={{
        backgroundColor: theme.surface.e0,
        padding: '16px',
        minWidth: '320px',
        width: '320px',
      }}
      isOpen={modal.displayed}
      setIsOpen={handleSetIsOpen}
    >
      <Container>
        <Title>Are you sure you want to delete this selection?</Title>
        {drawings?.length && (
          <Content>
            This selection includes {drawingCount} image block
            {drawingCount > 1 ? 's' : ''} and {layerCount} individual layer
            {layerCount > 1 ? 's' : ''}.
          </Content>
        )}
        <Buttons>
          <Button
            type="button"
            variant="secondary"
            onClick={modal.reject}
            data-close-modal
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="danger"
            onClick={confirmDelete}
            data-close-modal
          >
            Delete
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  background-color: ${(p) => p.theme.surface.e0};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.text.default};
  font-weight: 600;
`;

const Content = styled.div`
  color: ${(p) => p.theme.text.info};
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
`;
