import {
  OrganizationSubscriptionPlan,
  useDrawing,
} from '@vizcom/shared/data-access/graphql';

export const useIsPro = (drawingId?: string) => {
  const drawingRes = useDrawing(drawingId);

  const plan =
    drawingRes.data?.workbench?.folder?.organization?.subscriptionPlan;

  if (!plan) return false;

  return plan !== OrganizationSubscriptionPlan.Free;
};
