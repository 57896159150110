import { useDocumentEventListener } from '@vizcom/shared-ui-components';
import {
  WorkbenchStudioTool,
  useWorkbenchStudioToolState,
} from '../studioState';
import { useEffect, useMemo, useRef } from 'react';
import { BrushSettings } from './BrushSettings';
import { EraserSettings } from './EraserSettings';
import { ShapeSettings } from './ShapeSettings';
import styled from 'styled-components';

type Props = {
  target: HTMLElement;
};

export const WorkbenchStudioContextMenu = ({ target }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const toolState = useWorkbenchStudioToolState();

  useEffect(() => {
    const onContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      if (!menuRef.current) return;

      const x = Math.min(e.x, window.innerWidth - menuRef.current.clientWidth);
      const y = Math.min(
        e.y,
        window.innerHeight - menuRef.current.clientHeight
      );

      menuRef.current.style.visibility = 'visible';
      menuRef.current.style.transform = `translate(${x}px, ${y}px)`;
    };

    const onEscape = (e: KeyboardEvent) => {
      if (!menuRef.current) return;
      if (
        e.key === 'Escape' &&
        // escape is also the key to reset the view, hijack it only when the menu is open
        menuRef.current.style.visibility === 'visible'
      ) {
        e.preventDefault();
        e.stopPropagation();
        menuRef.current.style.visibility = 'hidden';
      }
    };

    target.addEventListener('contextmenu', onContextMenu);
    target.addEventListener('keydown', onEscape);

    return () => {
      target.removeEventListener('contextmenu', onContextMenu);
      target.removeEventListener('keydown', onEscape);
    };
  }, [target, menuRef.current]);

  useDocumentEventListener('click', (e) => {
    if (!menuRef.current || menuRef.current.contains(e.target as Node)) return;
    menuRef.current.style.visibility = 'hidden';
  });

  const menu = useMemo(() => {
    switch (toolState.tool) {
      case WorkbenchStudioTool.Brush:
      case WorkbenchStudioTool.PaintBucket:
        return <BrushSettings />;
      case WorkbenchStudioTool.Eraser:
        return <EraserSettings />;
      case WorkbenchStudioTool.Shape:
        return <ShapeSettings />;
    }
  }, [toolState.tool]);

  if (!menu) return null;

  return <Container ref={menuRef}>{menu}</Container>;
};

const Container = styled.div`
  position: fixed;
  z-index: 100000001;
  visibility: hidden;
  pointer-events: all;
  padding: 14px;
  border-radius: 8px;
  gap: 0.5rem;
  background: ${({ theme }) => theme.surface.e0};
`;
