import styled from 'styled-components';
import {
  Button,
  EyeDropperIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
} from '@vizcom/shared-ui-components';
import { HexColorInput, HexColorPicker } from 'react-colorful';

interface BrushColorInputProps {
  color: string;
  setColor: (color: string) => void;
  onOpenCanvasColorPicker: () => void;
}

export const BrushColorInput = (props: BrushColorInputProps) => {
  return (
    <RichTooltip placement="bottom" trigger="click" padding={12}>
      <RichTooltipTrigger>
        <Button variant="transparent" size="iconSquared">
          <ColorIndicatorWrapper>
            <ColorIndicator style={{ backgroundColor: props.color }} />
          </ColorIndicatorWrapper>
        </Button>
      </RichTooltipTrigger>
      <RichTooltipContent>
        <StyledHexColorPicker color={props.color} onChange={props.setColor} />
        <InputRow>
          <ToggleButton>HEX</ToggleButton>
          <StyledHexColorInput color={props.color} onChange={props.setColor} />
          <Button
            variant="transparent"
            size="icon"
            onClick={props.onOpenCanvasColorPicker}
          >
            <EyeDropperIcon />
          </Button>
        </InputRow>
      </RichTooltipContent>
    </RichTooltip>
  );
};

const ColorIndicatorWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(
    from 0deg,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet,
    red
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColorIndicator = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(p) => p.theme.icon.default};
`;

const StyledHexColorPicker = styled(HexColorPicker)`
  width: 255px;
  height: 255px;
  box-shadow: 1px 1px solid black;
  border-radius: 2px;
  border: none;
  cursor: pointer;

  .react-colorful__saturation-pointer,
  .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    cursor: pointer;
  }

  .react-colorful__hue,
  .react-colorful__alpha {
    margin-top: 0.5rem;
    border-radius: 15px;
    height: 12px;
    cursor: pointer;
  }
`;
const InputRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 10px;
`;

const ToggleButton = styled.button`
  padding: 5px 10px;
  border-radius: 4px 0 0 4px;
  background-color: ${({ theme }) => theme.surface.e1};
  color: ${({ theme }) => theme.text.default};
  font-size: 12px;
  border: none;
  border-right: 1px solid ${({ theme }) => theme.surface.e2};
`;

const StyledHexColorInput = styled(HexColorInput)`
  width: 0;
  flex-grow: 1;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.surface.e1};
  border-radius: 0px 4px 4px 0px;
  background-color: ${({ theme }) => theme.surface.e1};
  color: ${({ theme }) => theme.text.default};
  padding: 0 10px;
  outline: none;
  transition: box-shadow 0.2s ease-in-out;
  font-size: 12px;
  margin-right: 8px;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;
