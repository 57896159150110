import * as THREE from 'three';

/**
 * NOTE CompositeSceneNodeUUID is used to identify objects uploaded and modified on the backend as
 *      a part of the composite scene graph.
 */

export const setCompositeSceneNodeUUID = (
  target: THREE.Object3D | THREE.Material,
  uuid: string
): void => {
  target.userData.__vizUUID__ = uuid;
};

export const getCompositeSceneNodeUUID = (
  target: THREE.Object3D | THREE.Material
): string => {
  return target.userData.__vizUUID__ ?? target.name;
};
