import { v4 as uuidv4 } from 'uuid';
import {
  ContextMenuDivider,
  ContextMenuItem,
  ContextMenuSuffix,
  addToast,
  getShortcutKey,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';
import { ContextMenuItemsProps } from '../WorkbenchContextMenuByElementType';
import {
  MAX_Z_POSITION,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import { useStore, useThree } from '@react-three/fiber';
import { useCallback } from 'react';
import {
  copyWorkbenchElementsToClipboard,
  pasteWorkbenchElementsFromClipboard,
} from '../../../lib/workbenchClipboardUtils';
import { publishTrackingEvent } from '@vizcom/shared/data-access/graphql';
import { DrawingEventName } from '@vizcom/shared/data-shape';
import { triggerDeleteElementsModal } from '../../modals/triggerDeleteElementsModal';
import { useWorkbenchElementSelectionState } from '../../../lib/elementSelectionState';

const ensureClipboardFeatureAvailable = () => {
  if (navigator.clipboard.readText === undefined) {
    addToast(
      `The feature is not supported. Please use Chrome or Edge to use this feature.`,
      {
        type: 'danger',
      }
    );

    return false;
  }

  return true;
};

export const SharedContextMenuItems = ({
  element,
  handleAction,
}: Pick<ContextMenuItemsProps, 'element' | 'handleAction'>) => {
  const { scene } = useThree();
  const store = useStore();
  const setFocusedElementsId = useWorkbenchElementSelectionState(
    (state) => state.setFocusedElementsId
  );

  const handleZIndexUp = () => {
    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;

    handleAction({
      type: 'multiPosition',
      newElementData: [
        {
          id: element.id,
          zIndex,
        },
      ],
    });
  };

  const handleZIndexDown = () => {
    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[0]) ? zRange[0] - 1 : MAX_Z_POSITION / 2 - 1;

    handleAction({
      type: 'multiPosition',
      newElementData: [
        {
          id: element.id,
          zIndex,
        },
      ],
    });
  };

  const handleCopy = () => {
    if (!ensureClipboardFeatureAvailable()) {
      return;
    }

    copyWorkbenchElementsToClipboard([element], element.id);
  };

  const handlePaste = async () => {
    if (!ensureClipboardFeatureAvailable()) {
      return;
    }

    pasteWorkbenchElementsFromClipboard(
      await navigator.clipboard.readText(),
      handleAction,
      setFocusedElementsId,
      store.getState()
    );
  };

  const handleDuplicate = () => {
    handleAction({
      type: 'duplicateElements',
      elementIds: [element.id],
      newElementIds: [uuidv4()],
    });

    if (element.__typename === 'Drawing') {
      publishTrackingEvent({
        type: DrawingEventName.DRAWING_DUPLICATED,
        data: {
          drawingId: element.id,
        },
      });
    }
  };

  const handleDelete = useCallback(async () => {
    if (element.__typename === 'Drawing') {
      try {
        await triggerDeleteElementsModal([element]);
      } catch {
        return;
      }

      publishTrackingEvent({
        type: DrawingEventName.DRAWING_DELETED,
        data: {
          drawingId: element.id,
        },
      });
    }

    handleAction({
      type: 'deleteElements',
      elementIds: [element.id],
    });
  }, [element.id]);

  useKeyboardShortcut('[', handleZIndexDown);
  useKeyboardShortcut(']', handleZIndexUp);

  return (
    <>
      <ContextMenuItem onClick={handleZIndexUp}>
        Bring to front <ContextMenuSuffix>]</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuItem onClick={handleZIndexDown}>
        Send to back <ContextMenuSuffix>[</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuDivider />
      <ContextMenuItem onClick={handleCopy}>
        Copy <ContextMenuSuffix>{getShortcutKey(true)}C</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuItem onClick={handlePaste}>
        Paste <ContextMenuSuffix>{getShortcutKey(true)}V</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuItem onClick={handleDuplicate}>
        Duplicate
        <ContextMenuSuffix>{getShortcutKey(true)}D</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuDivider />
      <ContextMenuItem onClick={handleDelete}>
        Delete <ContextMenuSuffix>Del</ContextMenuSuffix>
      </ContextMenuItem>
    </>
  );
};
