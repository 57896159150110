// Debounce an async function
// this is different from a "normal" debounce because it waits for the function to finish running before queueing another debounced call
// this makes sure we only have the function running maximum once in parallel
// and that if we call the debounced function again while it's already executing, we queue another call to this function
export const debounceAsyncFunction = <TProps extends any[], TResult>(
  func: (...args: TProps) => Promise<TResult>,
  waitMs: number
) => {
  let sleepingPromise = null as null | Promise<TResult>;
  let runningPromise = null as null | Promise<TResult>;
  let lastCalledArguments = null as null | TProps;
  let currentControlledSleep = null as null | ReturnType<
    typeof controlledSleep
  >;

  const debouncedFunction: typeof func = async (...args: TProps) => {
    lastCalledArguments = args;
    currentControlledSleep?.resetSleep();

    if (!sleepingPromise) {
      sleepingPromise = (async () => {
        await runningPromise;

        if (currentControlledSleep) {
          throw new Error(
            'Controlled sleep is already defined, this shouldnt happen'
          );
        }
        currentControlledSleep = controlledSleep(waitMs);
        await currentControlledSleep.promise;
        currentControlledSleep = null;
        sleepingPromise = null;

        runningPromise = func(...lastCalledArguments);
        return runningPromise;
      })();
    }

    return sleepingPromise;
  };

  return debouncedFunction;
};

const controlledSleep = (waitMs: number) => {
  let resolved = false;
  let resolve: () => void;

  let timeout = setTimeout(() => resolve(), waitMs);

  return {
    promise: new Promise<void>((r) => {
      resolve = () => {
        resolved = true;
        r();
      };
    }),
    resetSleep: () => {
      if (resolved) {
        throw new Error(
          'Trying to reset controlledSleep that has already been resolved'
        );
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => resolve(), waitMs);
    },
  };
};
