import { useRef, useSyncExternalStore } from 'react';

const shallowCompare = (obj1: any, obj2: any) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    (key) =>
      Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key]
  );

// same as useSyncExternalStore but with a cache of each object from snapshot to prevent infinite re-renders
export function useComplexSyncExternalStore<Snapshot>(
  ...params: Parameters<typeof useSyncExternalStore<Snapshot>>
) {
  const cache = useRef<Snapshot>();

  return useSyncExternalStore(params[0], () => {
    const snapshot = params[1]();
    if (
      typeof snapshot === 'object' &&
      typeof cache.current === 'object' &&
      shallowCompare(cache.current, snapshot)
    ) {
      return cache.current;
    }
    cache.current = snapshot;
    return cache.current;
  });
}
