export const getGlobalCacheKeyArrayBuffer = async (
  name: string,
  key: string
) => {
  const cache = await caches.open(name);
  const value = await cache.match(key);
  if (!value) {
    return null;
  }
  return value.arrayBuffer();
};

export const setGlobalCacheKeyArrayBuffer = async (
  name: string,
  key: string,
  value: ArrayBuffer
) => {
  const cache = await caches.open(name);
  await cache.put(key, new Response(value));
};
