import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  createPrompt,
  useCurrentUser,
  usePrompt,
  ImageInferenceType,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import {
  Text,
  imageDataToBlob,
  triggerDirectModal,
} from '@vizcom/shared-ui-components';
import { CombinedError } from 'urql';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  InferenceEventName,
  RateLimitQuotaDetails,
} from '@vizcom/shared/data-shape';
import { artists } from './constants';
import { LayersCompositorApi } from './DrawingCompositor/LayersCompositor/context';
import {
  PublicPalette,
  promptLegacyTypeToModeAndPalette,
} from '@vizcom/shared/inference-worker-queues';
import { Drawing2dStudio } from '../../lib/useDrawingSyncedState';

export type InferenceSettings = {
  outputsCount: number;
  imageInferenceType: ImageInferenceType;
  publicPaletteId: string | null | undefined;
  customModelId: string | null | undefined;
  workbenchPaletteId: string | null | undefined;

  sourceImageInfluence: number;
  paletteInfluence: number;
  fidelity: number;

  prompt: string;
  negativePrompt: string;

  styleReferenceId?: string;
  styleReferenceStrength: number;
};

type Props = {
  drawing?: Drawing2dStudio | null;
  selectedPromptId: string | undefined;
  setSelectedPromptId: Dispatch<SetStateAction<string | undefined>>;
  getCompositeImage: LayersCompositorApi['getCompositedImage'];
  getSelectionImage: () => ImageData | undefined;
};

const defaultInferenceSettings: InferenceSettings = {
  imageInferenceType: ImageInferenceType.Render,
  outputsCount: 1,
  publicPaletteId: PublicPalette.generalV2,
  customModelId: null,
  workbenchPaletteId: null,

  sourceImageInfluence: 1,
  paletteInfluence: 1.0,
  fidelity: 0.5,

  prompt: '',
  negativePrompt: '',

  styleReferenceId: undefined,
  styleReferenceStrength: 1,
};

export const useInference = ({
  drawing,
  getCompositeImage,
  getSelectionImage,
  selectedPromptId,
  setSelectedPromptId,
}: Props) => {
  const [inferenceSettings, setInferenceSettings] = useState<InferenceSettings>(
    {
      ...defaultInferenceSettings,
      publicPaletteId: PublicPalette.generalV2,
    }
  );

  useEffect(() => {
    if (drawing) {
      const prompt = drawing.prompts.nodes[0];
      if (!prompt) {
        setInferenceSettings({
          ...defaultInferenceSettings,
          publicPaletteId: PublicPalette.generalV2,
        });
      } else {
        let imageInferenceType = prompt.imageInferenceType;
        let publicPaletteId = prompt.publicPaletteId;
        if (prompt.legacyType) {
          const convertedLegacyType = promptLegacyTypeToModeAndPalette(
            prompt.legacyType
          );
          imageInferenceType =
            convertedLegacyType.mode.toUpperCase() as ImageInferenceType;
          publicPaletteId = convertedLegacyType.publicPaletteId;
        }
        const sourceImageInfluence = prompt.sourceImageInfluence ?? 0.5;

        if (
          !publicPaletteId &&
          !prompt.customModelId &&
          !prompt.workbenchPaletteId
        ) {
          // this can happen if the customModel or workbenchPalette has been deleted, in this case we rollback to the default palette
          publicPaletteId = PublicPalette.generalV2;
        }

        const styleReference = drawing?.workbench?.styleReferences?.nodes.find(
          (ref) => ref.imagePath === prompt.styleReferenceImagePath
        );

        setInferenceSettings({
          imageInferenceType,
          outputsCount: prompt && prompt.outputs.nodes.length > 1 ? 4 : 1,
          sourceImageInfluence:
            sourceImageInfluence > 1 // legacy sourceImageInfluence were from 0 to 100, normalizing it here
              ? sourceImageInfluence / 100
              : sourceImageInfluence,
          prompt: prompt.text || '',
          fidelity: defaultInferenceSettings.fidelity,
          paletteInfluence: prompt.paletteInfluence,

          negativePrompt: '',

          publicPaletteId,
          customModelId: prompt.customModelId,
          workbenchPaletteId: prompt.workbenchPaletteId,

          styleReferenceId: styleReference?.id,
          styleReferenceStrength: 1,
        });
      }
    }
  }, [drawing?.id]);

  const selectedPrompt = usePrompt(selectedPromptId);
  const selectedPromptOutputs =
    selectedPrompt.data?.id === selectedPromptId
      ? selectedPrompt.data?.outputs.nodes
      : undefined;

  const anyOutputLoading =
    !!selectedPromptId &&
    selectedPrompt.data?.outputs.nodes.some(
      (output) => !output.imagePath && !output.failureReason
    );

  const failureReason = selectedPromptOutputs?.[0]?.failureReason;

  useEffect(() => {
    if (failureReason) {
      setSelectedPromptId(undefined);
      if (failureReason.includes('timeout')) {
        triggerDirectModal({
          title: 'Over capacity',
          content: (
            <>
              <Text block>
                Vizcom is at capacity right now. Please retry later.
              </Text>
              <Text block>
                If you haven't yet, you can upgrade your Workspace to Vizcom
                Professional to get priority access to our service.
              </Text>
            </>
          ),
        });
      } else {
        triggerDirectModal({
          title: "Couldn't generate your image",
          content: (
            <>
              <Text block>There was an unexpected error.</Text>
              <Text block>Please try again in a few moments.</Text>
            </>
          ),
        });
      }
    }
  }, [selectedPromptId, setSelectedPromptId, failureReason]);

  const trigger = useCallback(async () => {
    if (!drawing) {
      return;
    }

    for (let i = 0, l = artists.length; i < l; i++) {
      if (
        new RegExp(`\\b${artists[i]}\\b`, 'gi').exec(
          inferenceSettings.prompt
        ) !== null
      ) {
        triggerDirectModal({
          title:
            "We don't allow the use of artists' names for prompting without their permission.",
          content: (
            <>
              <Text block>
                We care deeply about building a product that respects the work
                of artists and designers. Use the learn more button to read our
                statement on ethical AI practices.
              </Text>
              <br />
              <a
                href="https://www.notion.so/vizcom/Vizcom-Ethical-AI-Statement-08056a4df90b414f84e840c58611077b"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </>
          ),
        });
        return;
      }
    }

    const selectionImage = getSelectionImage();
    setSelectedPromptId('loading');
    const [composite, mask] = await Promise.all([
      imageDataToBlob(getCompositeImage()),
      selectionImage && imageDataToBlob(selectionImage),
    ]);
    try {
      const inferenceOutput = await createPrompt({
        data: composite,
        mask,
        prompt: inferenceSettings.prompt || '',
        sourceImageInfluence: inferenceSettings.sourceImageInfluence,
        fidelity: inferenceSettings.fidelity,
        paletteInfluence: inferenceSettings.paletteInfluence,
        drawingId: drawing.id,
        imageInferenceType: inferenceSettings.imageInferenceType,
        outputsCount: inferenceSettings.outputsCount,
        publicPaletteId: inferenceSettings.publicPaletteId,
        customModelId: inferenceSettings.customModelId,
        workbenchPaletteId: inferenceSettings.workbenchPaletteId,
        negativePrompt: inferenceSettings.negativePrompt,
        styleReference: inferenceSettings.styleReferenceId
          ? {
              strength: inferenceSettings.styleReferenceStrength,
              styleReferenceId: inferenceSettings.styleReferenceId,
            }
          : undefined,

        // only for Vizcom admin, overwrite the workflow from ComfyUI, used to test different settings live
        workflow: localStorage.getItem('vizcom:2dstudio:customWorkflow'),
        magicPrompt:
          localStorage.getItem('vizcom:2dstudio:magicPrompt') === 'true',
      });
      setSelectedPromptId((selectedId) => {
        if (selectedId === undefined) {
          // prompt was cancelled before `createPrompt` finished, should not set the inference output id
          return undefined;
        }
        return inferenceOutput.id;
      });
    } catch (e) {
      if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.code ===
          'offensive_word'
      ) {
        triggerDirectModal({
          title: 'Your prompt contains offensive words',
          content: (
            <>
              <Text block>
                Your prompt contains offensive words. Please change it.
              </Text>
              <Text block>
                If you believe it's a mistake, contact us (support@vizcom.ai)
              </Text>
            </>
          ),
        });
      } else if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.code ===
          'trademarked_word'
      ) {
        triggerDirectModal({
          title: 'Your prompt contains a trademarked source',
          content: (
            <>
              <Text block>
                Your prompt contains a trademarked source. Please change it.
              </Text>
              <Text block>
                If you believe it's a mistake, contact us (support@vizcom.ai)
              </Text>
            </>
          ),
        });
      } else if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.code ===
          'organization_forbidden_keyword'
      ) {
        triggerDirectModal({
          title: 'Your prompt contains a forbidden word',
          content: (
            <>
              <Text block>
                Your prompt contains a keyword forbidden by your workspace
                administrator. Please change it.
              </Text>
              <Text block>
                If you believe it's a mistake, contact your workspace
                administrator.
              </Text>
            </>
          ),
        });
      } else if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (e.graphQLErrors[0]?.extensions?.exception as any)
          ?.rateLimit as RateLimitQuotaDetails;
        triggerDirectModal({
          title: 'You have been generating too many images',
          content: (
            <Text block>
              Please wait {(rateLimitInfo.resetInMs / 1000).toFixed(0)} seconds
              before trying again.
            </Text>
          ),
        });
      } else {
        triggerDirectModal({
          title: "Couldn't generate your image",
          content: (
            <>
              <Text block>There was an unexpected error.</Text>
              <Text block>Please try again in a few moments.</Text>
            </>
          ),
        });
      }
      setSelectedPromptId(undefined);
    }

    trackEvent('Generate Image', {
      imageInferenceType: inferenceSettings.imageInferenceType,
      publicPaletteId: inferenceSettings.publicPaletteId,
      customModelId: inferenceSettings.customModelId,
      workbenchPaletteId: inferenceSettings.workbenchPaletteId,
      outputsCount: inferenceSettings.outputsCount,
      sourceImageInfluence: inferenceSettings.sourceImageInfluence,
      prompt: inferenceSettings.prompt,
      styleReferenceEnabled: Boolean(inferenceSettings.styleReferenceId),
    });

    publishTrackingEvent({
      type: InferenceEventName.GENERATE_IMAGE,
      data: {
        imageInferenceType: inferenceSettings.imageInferenceType || undefined,
        publicPaletteId: inferenceSettings.publicPaletteId || undefined,
        customModelId: inferenceSettings.customModelId || undefined,
        workbenchPaletteId: inferenceSettings.workbenchPaletteId || undefined,
        outputsCount: inferenceSettings.outputsCount,
        sourceImageInfluence: inferenceSettings.sourceImageInfluence,
        prompt: inferenceSettings.prompt,
        styleReferenceEnabled: !!inferenceSettings.styleReferenceId,
        source: 'STUDIO',
        paletteInfluence: inferenceSettings.paletteInfluence,
      },
    });
  }, [
    drawing,
    getCompositeImage,
    inferenceSettings,
    getSelectionImage,
    setSelectedPromptId,
  ]);

  return {
    anyOutputLoading,
    selectedPromptId,
    inferenceSettings,
    selectedPrompt: selectedPrompt.data,
    setSelectedPromptId: (id?: string) => setSelectedPromptId(id),
    setInferenceSettings,
    trigger,
  };
};
