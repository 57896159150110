// This memoize a function result by taking into account all the function parameters
// the lodash memoize function only memoize according to the first argument which can be a problem for functions taking multiple arguments
// This only keep the last memoize operation in memory, meaning that this is only useful if we want to keep the result of this function stable
// between two subsequent calls with the same parameters
export function memoizeLastCall<T extends (...args: any[]) => any>(func: T) {
  let cache = null as null | {
    parameters: Parameters<T>;
    result: ReturnType<T>;
  };

  return ((...args: Parameters<T>) => {
    if (
      cache &&
      cache.parameters.length === args.length &&
      cache.parameters.every((value, index) => value === args[index])
    ) {
      return cache.result;
    }
    const result = func(...args);
    cache = {
      parameters: args,
      result,
    };

    return result;
  }) as T;
}
