import React from 'react';

import { useColorArea } from '@react-aria/color';
import { useColorAreaState, ColorAreaProps } from '@react-stately/color';
import { useFocusRing } from '@react-aria/focus';

const SIZE = 175;
const FOCUSED_THUMB_SIZE = 24;
const THUMB_SIZE = 20;
const BORDER_RADIUS = 4;

type FidelityAreaProps = {
  setInfluence: (value: number) => void;
  setFidelity: (value: number) => void;
} & ColorAreaProps;

export const FidelityArea = ({
  setInfluence,
  setFidelity,
  ...rest
}: FidelityAreaProps) => {
  const { isDisabled } = rest;

  const inputXRef = React.useRef(null);
  const inputYRef = React.useRef(null);
  const containerRef = React.useRef(null);

  const state = useColorAreaState({
    ...rest,
    xChannel: 'red',
    yChannel: 'green',
    onChange: (value) => {
      const influence = Math.round((value.getChannelValue('red') / 255) * 100);
      const fidelity = Math.round((value.getChannelValue('green') / 255) * 100);
      setInfluence(influence);
      setFidelity(fidelity);
    },
  });

  const {
    colorAreaProps,
    gradientProps,
    xInputProps,
    yInputProps,
    thumbProps,
  } = useColorArea(
    // @ts-ignore
    { ...rest, inputXRef, inputYRef, containerRef },
    {
      ...state,
      xChannelStep: 12.75,
      yChannelStep: 12.75,
      xChannelPageStep: 25.5,
      yChannelPageStep: 25.5,
    }
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <div
      ref={containerRef}
      {...colorAreaProps}
      style={{
        ...colorAreaProps.style,
        background: '#242425',

        width: SIZE,
        height: SIZE,
        borderRadius: BORDER_RADIUS,
      }}
    >
      <div
        style={{
          width: '100%',
          height: 1,
          border: 'none',
          borderTop: '1px dashed white',
          position: 'absolute',
          top: '50%',
        }}
      ></div>
      <div
        style={{
          borderRadius: BORDER_RADIUS,
          height: SIZE,
          width: SIZE,
        }}
      />
      <div
        {...thumbProps}
        style={{
          ...thumbProps.style,
          background: 'white',
          border: `2px solid ${isDisabled ? 'rgb(142, 142, 142)' : 'white'}`,
          borderRadius: '50%',
          boxShadow: '0 0 0 1px black, inset 0 0 0 1px black',
          boxSizing: 'border-box',
          height: isFocusVisible ? FOCUSED_THUMB_SIZE : THUMB_SIZE,
          transform: 'translate(-50%, -50%)',
          width: isFocusVisible ? FOCUSED_THUMB_SIZE : THUMB_SIZE,
        }}
      >
        <input ref={inputXRef} {...xInputProps} {...focusProps} />
        <input ref={inputYRef} {...yInputProps} {...focusProps} />
      </div>
    </div>
  );
};
