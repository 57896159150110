import { Text, WarningIcon } from '@vizcom/shared-ui-components';
import styled from 'styled-components';

export const OrganizationFileSharingWarning = () => {
  return (
    <Container>
      <WarningIcon height={18} width={18} />
      <div>
        <Text block type="">
          Public file sharing disabled
        </Text>
        <Text block type="b1" color="info">
          Contact admin to enable public file sharing.
        </Text>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 15px 10px;
`;
