import { createUseGesture } from '@use-gesture/react';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { Mesh, OrthographicCamera } from 'three';
import { isSelectionTool, useWorkbenchStudioToolState } from '../studioState';
import { BrushSegment } from '../types';
import { screenPositionToLayer } from '../../helpers';
import { stabilizePoint } from './stabilization';
import { Browser, getBrowser } from '@vizcom/shared-ui-components';
import { useRef } from 'react';

const useGesture = createUseGesture([]);

const IS_SAFARI = getBrowser().browser === Browser.Safari;
const SAFARI_MIN_INITIAL_DISTANCE = 10; // (in screen space pixels)
const LINE_TOOL_ANGLE_GRANULARITY = (Math.PI * 2) / 8; // 45°
const LINE_TOOL_CONSTRAINT_THRESHOLD = 10; // (in screen space pixels)
const POINT_HISTORY_MAX_LENGTH = 4;

type SegmentedBrushProps = {
  drawingSize: [number, number];
  eventPlaneRef: React.MutableRefObject<Mesh>;
  onNewSegments: (
    event: ThreeEvent<PointerEvent>,
    segments: BrushSegment[]
  ) => void;
  onStrokeEnd: (event: ThreeEvent<PointerEvent>) => void;
  preventFingerInteractions?: boolean;
};

type Constraint = {
  enabled: boolean;
  angle: number;
};

type Point = {
  screenPosition: [number, number];
  stabilizedScreenPosition: [number, number];
  position: [number, number];
  pressure: number;
};

export const useBrushSegments = ({
  drawingSize,
  eventPlaneRef,
  onNewSegments,
  onStrokeEnd,
  preventFingerInteractions = false,
}: SegmentedBrushProps) => {
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const { getToolSettings, resizing, tool } = useWorkbenchStudioToolState();
  const { toolPressureSize, toolStabilizer } = getToolSettings();
  const isDrawing = useRef(false);

  const initialPoint = useRef([0, 0]);
  const gestureMemo = useRef<null | {
    constraint: Constraint;
    previousPoints: Point[];
  }>(null);

  const drawSegment = (state: {
    event: ThreeEvent<PointerEvent>;
    shiftKey: boolean;
  }) => {
    if (resizing || !isDrawing.current) return;

    const event = state.event as unknown as ThreeEvent<PointerEvent>;
    const shiftKey = state.shiftKey;

    const { constraint, previousPoints } = (gestureMemo.current || {
      constraint: { enabled: false, angle: 0 },
      previousPoints: [],
    }) as {
      constraint: Constraint;
      previousPoints: Point[];
    };
    if (constraint.enabled && !shiftKey) {
      constraint.enabled = false;
    }

    const newSegments = [] as BrushSegment[];
    // if getCoalescedEvents is not available, fallback to the event itself
    let precisePointerEvents = event.nativeEvent.getCoalescedEvents?.() || [
      event.nativeEvent,
    ];
    if (precisePointerEvents.length === 0) {
      precisePointerEvents = [event.nativeEvent];
    }

    precisePointerEvents.forEach((e) => {
      if (
        // Safari is the downfall of modern civilization
        IS_SAFARI &&
        previousPoints.length === 1 &&
        Math.sqrt(
          (initialPoint.current[0] - e.clientX) ** 2 +
            (initialPoint.current[1] - e.clientY) ** 2
        ) < SAFARI_MIN_INITIAL_DISTANCE
      ) {
        previousPoints.pop();
      }

      const previousPoint = previousPoints[previousPoints.length - 1];

      if (
        // Deduplicate PointerEvents (Safari)
        previousPoint &&
        previousPoint.screenPosition[0] === e.clientX &&
        previousPoint.screenPosition[1] === e.clientY &&
        // Allow duplicated point when is last and we have single previous point
        // This draws a dot when the user just taps without moving the pointer
        !(previousPoints.length === 1)
      ) {
        return;
      }

      if (
        !isSelectionTool(tool) &&
        !constraint.enabled &&
        previousPoint &&
        shiftKey
      ) {
        const dx = previousPoint.screenPosition[0] - e.clientX;
        const dy = previousPoint.screenPosition[1] - e.clientY;
        if (Math.sqrt(dx ** 2 + dy ** 2) < LINE_TOOL_CONSTRAINT_THRESHOLD) {
          return;
        }
        const angle =
          Math.round(Math.atan2(dy, dx) / LINE_TOOL_ANGLE_GRANULARITY) *
          LINE_TOOL_ANGLE_GRANULARITY;
        constraint.enabled = true;
        constraint.angle = angle;
      }

      let screenPosition: [number, number] = [e.clientX, e.clientY];
      if (constraint.enabled) {
        const dx = previousPoint.screenPosition[0] - e.clientX;
        const dy = previousPoint.screenPosition[1] - e.clientY;
        const h = Math.sqrt(dx ** 2 + dy ** 2);
        const a = Math.cos(Math.atan2(-dy, -dx) - constraint.angle) * h;
        screenPosition = [
          previousPoint.screenPosition[0] + Math.cos(constraint.angle) * a,
          previousPoint.screenPosition[1] + Math.sin(constraint.angle) * a,
        ];
      }

      const point: Point = {
        screenPosition,
        stabilizedScreenPosition: [...screenPosition],
        position: [0, 0], // This will be set after stabilization
        pressure:
          !toolPressureSize || ['mouse', 'touch'].includes(event.pointerType)
            ? 1
            : Math.min(event.pressure / 0.4, 1),
        // use 0.4 as the max pressure value, this is done because the pressure range is very high on some devices and would require a huge amount
        // of pressure to reach 1
      };

      stabilizePoint(point, previousPoints, toolStabilizer);

      point.position = screenPositionToLayer(
        point.stabilizedScreenPosition,
        camera,
        eventPlaneRef.current,
        drawingSize
      ) as [number, number];

      if (previousPoint) {
        newSegments.push({
          startPosition: [previousPoint.position[0], previousPoint.position[1]],
          startPressure: previousPoint.pressure,
          endPosition: point.position,
          endPressure: point.pressure,
        });
      } else {
        // First point
        newSegments.push({
          startPosition: [point.position[0], point.position[1]],
          startPressure: point.pressure,
          endPosition: point.position,
          endPressure: point.pressure,
        });
      }

      previousPoints.push(point);
      previousPoints.length > POINT_HISTORY_MAX_LENGTH &&
        previousPoints.shift();
    });

    newSegments.length && onNewSegments(event, newSegments);

    gestureMemo.current = { constraint, previousPoints };
  };

  const bind = useGesture({
    onPointerDown: ({ event, shiftKey }) => {
      if (preventFingerInteractions && event.pointerType === 'touch') {
        return;
      }

      isDrawing.current = true;
      gestureMemo.current = null;

      const e = event as unknown as ThreeEvent<PointerEvent>;
      initialPoint.current[0] = e.clientX;
      initialPoint.current[1] = e.clientY;

      drawSegment({ event: e, shiftKey });
    },
    onPointerUp: (state) => {
      const event = state.event as unknown as ThreeEvent<PointerEvent>;

      isDrawing.current = false;
      onStrokeEnd(event);
      gestureMemo.current = null;
    },
    onPointerMove: (state) => {
      const event = state.event as unknown as ThreeEvent<PointerEvent>;
      drawSegment({
        event,
        shiftKey: event.shiftKey,
      });
    },
  });

  return {
    bind,
  };
};
