import styled from 'styled-components';

export const Drawing = styled.div`
  position: relative;
  height: 100%;
  background: repeating-conic-gradient(#d7d7d7 0% 25%, #ffffff 0% 50%) 50% / 8px
    8px;
  > img {
    width: 100%;
    object-fit: contain;
    pointer-events: none;
  }
`;

export const PlaceHolder = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text.info};
`;

export const Img2Img = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.text.default};
  border-radius: 0.5rem;
`;

export const TextInput = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 30px;
  min-width: 30px;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => theme.surface.e0};
  outline: none;
  overflow: hidden;
  background-color: transparent;
  appearance: auto;
  resize: none;
  border: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;

  :empty::before {
    content: attr(placeholder);
    color: ${({ theme }) => theme.text.info};
  }
`;

export const ColorSwatch = styled.div`
  height: 16px;
  width: 16px;
  outline: none;
  -webkit-appearance: none;
  background: none;
  border: 1px solid white;
  border-radius: 50%;
`;

export const Field = styled.div`
  padding: 0 0 0 8px;
  font-size: 18px;
  display: grid;
  gap: 0.25rem;
  flex-shrink: 0;
  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.surface.e2};
  }
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  gap: 8px;
`;

export const InputOption = styled.button<{ $selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: none;
  outline: none;
  background: ${({ theme, $selected }) =>
    $selected ? theme.surface.e2 : 'none'};
  color: ${({ theme }) => theme.text.info};
  cursor: pointer;
  padding: 0;

  border-radius: 0.25rem;
  color: #fff;

  &:hover {
    background: ${({ theme }) => theme.surface.e2};
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.text.info};
`;

export const ExtraPopup = styled.div<{ $top: number; $right: number }>`
  position: absolute;
  padding: 4px;
  top: ${({ $top }) => $top}px;
  right: ${({ $right }) => $right}px;
  background: ${({ theme }) => theme.surface.e0};
  border-radius: 16px;
  z-index: 1;
`;
