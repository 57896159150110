import * as Comlink from 'comlink';

import { Source, Options } from './psd.implementation';

import { psd as psdFallback } from './psd.fallback';

const shouldFallback = !('OffscreenCanvas' in window);

type TWorker = Comlink.Remote<typeof import('./psd.worker')>;
let _worker: Comlink.Remote<TWorker> | null = null;
const worker = () => {
  if (_worker === null) {
    _worker = Comlink.wrap<TWorker>(
      new Worker(new URL('./psd.worker.ts', import.meta.url), {
        type: 'module',
      })
    );
  }

  return _worker;
};

export const psd = async (sources: Source[], options: Options) => {
  if (shouldFallback) {
    return psdFallback(sources, options);
  }

  return worker().psd(sources, options);
};
