import { Button, ImportImageIcon, Tooltip } from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from '../../../../lib/useWorkbenchSyncedState';

import { handleAddImagesToPalette } from '../helpers';
import { useTheme } from 'styled-components';
import { ClientSideWorkbenchElementPalette } from '../../../../lib/clientState';

type WorkbenchElementPaletteAddImagesProps = {
  paletteData: ClientSideWorkbenchElementPalette;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

export const WorkbenchElementPaletteAddImages = ({
  paletteData,
  handleAction,
}: WorkbenchElementPaletteAddImagesProps) => {
  const theme = useTheme();
  return (
    <Tooltip tip="Add images" position="top">
      <Button
        size="iconSquared"
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          handleAddImagesToPalette(
            paletteData.id,
            paletteData.sourceImages.nodes.length,
            handleAction
          );
        }}
      >
        <ImportImageIcon
          style={{ color: theme.icon.default, width: '22px', height: '22px' }}
        />
      </Button>
    </Tooltip>
  );
};
