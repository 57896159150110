import {
  Text,
  NewFolderIcon as NewFolderIconBase,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import SortContextMenu from './SortContextMenu';
import { CreateFolderModal } from './CreateFolderModal';
import { useState } from 'react';

const NewFolderIcon = styled(NewFolderIconBase)`
  height: 18px;
  width: 18px;
  margin-right: 10px;
`;

interface SectionGridHeaderProps {
  title: string;
  showNewFolderButton?: boolean;
  folderId: string;
}

export const SectionGridHeader = ({
  title,
  showNewFolderButton,
  folderId,
}: SectionGridHeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
      }}
    >
      <Text type="h1">{title}</Text>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showNewFolderButton && (
          <div
            onClick={() => setIsOpen(true)}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              marginRight: 8,
            }}
          >
            <NewFolderIcon />
            <Text type="b1">New Folder</Text>
          </div>
        )}
        {/* <Text
          type="b1"
          color="info"
          style={{
            marginLeft: 24,
          }}
        >
          Sort:
        </Text> */}
        {/* <SortContextMenu onSortOptionChange={onSortOptionChange} /> */}
        <CreateFolderModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          folderId={folderId}
        />
      </div>
    </div>
  );
};
