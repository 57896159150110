import { Button, Surface, Text } from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import {
  removeAccessToken,
  useDeleteCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import { useLocation } from 'react-router-dom';
import { FormEventHandler, useState } from 'react';

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfirmDeleteAccountPage = () => {
  const { hash } = useLocation();
  const token = hash.startsWith('#token=')
    ? hash.slice('#token='.length)
    : null;

  const [res, deleteCurrentUser] = useDeleteCurrentUser();

  const handleConfirmDelete: FormEventHandler = async (e) => {
    e.preventDefault();
    const res = await deleteCurrentUser({
      input: {
        emailConfirmToken: token!,
      },
    });
    if (!res.error) {
      removeAccessToken();
      window.location.href = '/';
    }
  };

  return (
    <Wrapper>
      {token ? (
        <Surface
          style={{
            marginTop: 48,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            maxWidth: 400,
            gap: 12,
          }}
          loading={res.fetching}
        >
          <Text block type="h1">
            Delete account
          </Text>
          <Text block>
            Deleting your account will delete all the files and other content
            your created on Vizcom. All the workspaces for which you are the
            only members will be deleted. All subscriptions linked to these
            workspaces will be canceled. This action is permanent.
          </Text>
          <form
            onSubmit={handleConfirmDelete}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              alignItems: 'stretch',
            }}
          >
            <Button disabled={res.fetching} type="submit">
              Delete account
            </Button>

            {res.error && (
              <Text color="danger" style={{ marginTop: 12 }} block type="h1">
                {res.error.graphQLErrors[0]?.message ?? res.error.message}
              </Text>
            )}
          </form>
        </Surface>
      ) : (
        <Surface>
          <Text block type="h1">
            Error
          </Text>
        </Surface>
      )}
    </Wrapper>
  );
};
