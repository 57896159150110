import { Button, MatchCaseIcon, Tooltip } from '@vizcom/shared-ui-components';

import { useTheme } from 'styled-components';

type WorkbenchElementPaletteRenameProps = {
  handleRenamePalette: () => void;
};

export const WorkbenchElementPaletteRename = ({
  handleRenamePalette,
}: WorkbenchElementPaletteRenameProps) => {
  const theme = useTheme();
  return (
    <Tooltip tip="Rename Palette" position="top">
      <Button
        size="iconSquared"
        variant="transparent"
        onClick={() => {
          handleRenamePalette();
        }}
      >
        <MatchCaseIcon
          style={{ color: theme.icon.default, width: '42px', height: '42px' }}
        />
      </Button>
    </Tooltip>
  );
};
