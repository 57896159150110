import {
  urqlClient,
  UpdateWorkbenchElementPaletteMutation,
} from '@vizcom/shared/data-access/graphql';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';

export const UpdatePaletteAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updatePalette';
    elementId: string;
    name: string;
  }
> = {
  type: 'updatePalette',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementPalette') {
      sourceElement.name = payload.name;
    }
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(
      UpdateWorkbenchElementPaletteMutation,
      {
        input: {
          id: payload.elementId,
          patch: {
            name: payload.name,
          },
        },
      }
    );

    if (res?.error) {
      throw new Error(
        `Error while updating element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload) => ({
    delay: 500,
    debounceId: payload.elementId,
  }),
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element || element.__typename !== 'WorkbenchElementPalette') {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof UpdatePaletteAction> =
      {
        type: 'updatePalette',
        elementId: payload.elementId,
        name: element.name,
      };

    return undoPayload;
  },
};
