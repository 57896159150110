import styled, { useTheme } from 'styled-components';
import { logStep } from '@vizcom/shared/js-utils';
import { CameraLimits, useMapControls } from './utils/mapControls/utils';
import {
  Button,
  FitToScreenIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  useKeyboardShortcut,
  ZoomInIcon,
  ZoomOutIcon,
} from '@vizcom/shared-ui-components';
import { HtmlOverlay } from './utils/HtmlOverlay';
import { useCameraZoom } from './helpers';
import { ReactNode } from 'react';

interface WorkbenchControlsProps {
  cameraLimits: CameraLimits;
  prependControls?: ReactNode;
  appendControls?: ReactNode;
  collapsed?: boolean;
  onFitToScreen: () => void;
}

export const ZOOM_CLICK_STEP = 0.05;

export const WorkbenchViewportControls = ({
  cameraLimits,
  prependControls,
  appendControls,
  collapsed,
  onFitToScreen,
}: WorkbenchControlsProps) => {
  const zoom = useCameraZoom();
  const controls = useMapControls();
  const theme = useTheme();

  const onZoomIn = () => {
    controls.moveTo({
      zoom: logStep(
        zoom,
        ZOOM_CLICK_STEP,
        cameraLimits.zoomMin,
        cameraLimits.zoomMax
      ),
    });
  };

  const onZoomOut = () => {
    controls.moveTo({
      zoom: logStep(
        zoom,
        -ZOOM_CLICK_STEP,
        cameraLimits.zoomMin,
        cameraLimits.zoomMax
      ),
    });
  };

  useKeyboardShortcut('escape', onFitToScreen);
  useKeyboardShortcut(['+', '='], onZoomIn, { ctrl: true });
  useKeyboardShortcut('-', onZoomOut, { ctrl: true });
  useKeyboardShortcut(['+', '='], onZoomIn);
  useKeyboardShortcut('-', onZoomOut);

  return (
    <HtmlOverlay>
      <Controls $collapsed={collapsed}>
        {prependControls}
        <div>
          <RichTooltip trigger="hover" padding={11}>
            <RichTooltipTrigger>
              <Button
                onClick={onFitToScreen}
                size="iconSquared"
                variant="transparent"
              >
                <FitToScreenIcon />
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Fit to screen
            </RichTooltipContent>
          </RichTooltip>
        </div>
        <Divider />
        <div>
          <RichTooltip trigger="hover" padding={11}>
            <RichTooltipTrigger>
              <Button
                onClick={onZoomOut}
                size="iconSquared"
                variant="transparent"
              >
                <ZoomOutIcon />
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Zoom out
            </RichTooltipContent>
          </RichTooltip>
        </div>
        <Zoom>{Math.round(zoom * 100)}%</Zoom>
        <div>
          <RichTooltip trigger="hover" padding={11}>
            <RichTooltipTrigger>
              <Button
                onClick={onZoomIn}
                size="iconSquared"
                variant="transparent"
              >
                <ZoomInIcon />
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Zoom in
            </RichTooltipContent>
          </RichTooltip>
        </div>

        {appendControls}
      </Controls>
    </HtmlOverlay>
  );
};

const Controls = styled.div<{ $collapsed?: boolean }>`
  position: absolute;
  bottom: 1rem;
  right: 14px;
  z-index: 10000000;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 16px;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.text.info};
  justify-content: space-between;
  pointer-events: all;
  transition: max-width 0.3s;
  width: 250px;
  max-width: ${({ $collapsed }) => ($collapsed ? '48px' : '250px')};
  overflow: hidden;
  gap: 4px;

  svg {
    color: ${({ theme }) => theme.icon.default};
  }
`;

const Zoom = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
`;

const Divider = styled.div`
  width: 2px;
  height: 16px;
  align-self: center;
  background-color: ${(p) => p.theme.surface.e2};
`;
