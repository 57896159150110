import {
  Text,
  RangeInput,
  RectangleIcon,
  EllipseIcon,
  LineIcon,
  NumberInput,
  Checkbox,
} from '@vizcom/shared-ui-components';
import { useWorkbenchStudioToolState } from '../studioState';
import styled from 'styled-components';

export const ShapeSettings = () => {
  const {
    shapeSettings: {
      opacity,
      setOpacity,
      fill,
      setFill,
      size,
      setSize,
      type,
      setType,
    },
  } = useWorkbenchStudioToolState();

  return (
    <>
      <Shapes>
        <ShapeTool
          $active={type === 'rectangle'}
          onClick={() => {
            setType('rectangle');
          }}
        >
          <RectangleIcon />
          <div>Rectangle</div>
          <div>U</div>
        </ShapeTool>
        <ShapeTool
          $active={type === 'ellipse'}
          onClick={() => {
            setType('ellipse');
          }}
        >
          <EllipseIcon />
          <div>Ellipse</div>
          <div>O</div>
        </ShapeTool>
        <ShapeTool
          $active={type === 'line'}
          onClick={() => {
            setType('line');
          }}
        >
          <LineIcon />
          <div>Line</div>
          <div>L</div>
        </ShapeTool>
      </Shapes>

      <Text
        style={{
          padding: '12px 12px 6px',
        }}
        type="sh2"
        as="div"
      >
        Shape settings
      </Text>
      <Content>
        <div>
          <Label>
            Thickness
            <Value>
              <NumberInput
                value={size}
                min={0}
                max={500}
                unit="px"
                setValue={(v) => setSize(v)}
                dragArrows={false}
              />
            </Value>
          </Label>
          <RangeInput
            variant="secondary"
            disabled={type !== 'line' && fill}
            value={size}
            min={1}
            max={500}
            step={1}
            onChange={({ target: { value } }) => setSize(+value)}
          />
        </div>
        <div>
          <Label>
            Opacity
            <Value>
              <NumberInput
                value={opacity * 100}
                min={0}
                max={100}
                unit="%"
                setValue={(v) => setOpacity(v / 100)}
                dragArrows={false}
              />
            </Value>
          </Label>
          <RangeInput
            variant="secondary"
            value={opacity}
            min={0}
            max={1}
            step={0.01}
            onChange={({ target: { value } }) => setOpacity(+value)}
          />
        </div>
        {type !== 'line' && (
          <div>
            <CheckboxLabel>
              Fill
              <Checkbox checked={fill} onClick={() => setFill(!fill)} />
            </CheckboxLabel>
          </div>
        )}
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 12px;
  gap: 6px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text.info};
  font-weight: 600;
  padding-bottom: 6px;
`;

const Value = styled.div`
  margin-left: auto;
  color: ${({ theme }) => theme.text.default};
  font-weight: 400;
`;

const CheckboxLabel = styled(Label)`
  padding-bottom: 0;
  gap: 12px;
`;

const Tool = styled.button<{ $active?: boolean }>`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ $active, theme }) =>
    $active ? theme.text.default : theme.text.info};
  &:disabled {
    color: ${({ theme }) => theme.text.disabled};
    cursor: default;
  }
`;

const ShapeTool = styled(Tool)`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0.75rem 12px;
  gap: 12px;
  text-align: left;
`;

const Shapes = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.surface.e2};
`;
