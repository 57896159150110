import { suspend } from 'suspend-react';
import { assertUnreachable, sleep } from '@vizcom/shared/js-utils';
import { type GLTF, GLTFLoader } from 'three-stdlib';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const loader = new GLTFLoader();

const loadModelWithRetry = async (url: string | undefined | null | Blob) => {
  if (!url) {
    return undefined;
  }

  const loadModel = () =>
    new Promise<GLTF>((resolve, reject) => {
      if (typeof url === 'string') {
        loader.load(
          url,
          (model) => {
            resolve(model);
          },
          () => {},
          (error) => reject(error)
        );
      } else if (url instanceof Blob) {
        url
          .arrayBuffer()
          .then((buffer) => {
            loader.parse(
              buffer,
              '',
              (model) => {
                resolve(model);
              },
              (error) => reject(error)
            );
          })
          .catch((error) => reject(error));
      } else {
        assertUnreachable(url);
      }
    });

  let tryCount = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const res = await loadModel();
      return res;
    } catch (e) {
      await sleep(RETRY_DELAY);
      tryCount++;
      if (tryCount > MAX_RETRIES) {
        throw e;
      }
    }
  }
};

const loadModelsInParallel = async (
  _symbol: any,
  ...urls: (string | undefined | null | Blob)[]
) => {
  const promises = urls.map((url) => loadModelWithRetry(url));
  return await Promise.all(promises);
};

const useGltfSymbol = Symbol();

// Inspired from https://github.com/pmndrs/drei/blob/595396507dbedbd0ff678945cd7c425db736f759/src/core/Gltf.tsx
// and https://github.com/pmndrs/react-three-fiber/blob/master/packages/fiber/src/core/hooks.tsx#L124
// load a model from an url or a blob with a retry mechanism in case of failure
export const useGltf = (
  urls: (string | undefined | null | Blob)[]
): (GLTF | undefined)[] => {
  const result = suspend(loadModelsInParallel, [useGltfSymbol, ...urls]);

  return result;
};
