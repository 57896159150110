import {
  format,
  formatDistance,
  formatDistanceToNow,
  formatRelative,
} from 'date-fns';
import { useMemo } from 'react';

export const FormattedDate = (props: { date: string | number | undefined }) => {
  const formatted = useMemo(() => {
    return props.date && format(new Date(props.date), 'PPP');
  }, [props.date]);

  if (!formatted) {
    return null;
  }

  return <>{formatted}</>;
};

export const RelativeDate = (props: { date: string | Date }) => {
  const formatted = useMemo(() => {
    return formatDistanceToNow(new Date(props.date), {
      addSuffix: true,
    });
  }, [props.date]);

  return <>{formatted}</>;
};
