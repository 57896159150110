import { ImageTo3dQualityType } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Chip,
  LockIcon,
  addToast,
  useTriggerPaywallModalOpen,
} from '@vizcom/shared-ui-components';
import { v4 as uuid } from 'uuid';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { SUBSCRIPTION_PLANS_LIMIT } from '@vizcom/shared/plans-limit';
import styled from 'styled-components';

export const Generate3dLayerMenu = ({
  drawing,
  handleAction,
  fromLayerId,
  setActiveLayerId,
}: {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  fromLayerId?: string;
  setActiveLayerId: (id: string | undefined) => void;
}) => {
  const triggerPaywallModal = useTriggerPaywallModalOpen();

  const subscriptionPlan =
    drawing.workbench?.folder?.organization?.subscriptionPlan;
  const canGenerateHighQuality3d =
    subscriptionPlan &&
    SUBSCRIPTION_PLANS_LIMIT[subscriptionPlan]
      .highQuality3dGenerationLimitPerMonth > 0;

  const handleGenerate3d = (quality: ImageTo3dQualityType) => {
    const layerWithImageCount = drawing.layers.nodes.filter(
      (l) => !!l.imagePath
    ).length;

    if (layerWithImageCount === 0) {
      return addToast(
        'Please add content to the canvas before generating a 3D model',
        {
          type: 'danger',
        }
      );
    }

    const layerId = uuid();
    handleAction({
      type: 'createLayer3dFromDrawing',
      name: 'Generated 3D model',
      id: layerId,
      sourceLayerId: fromLayerId,
      qualityType: quality,
    });
    setActiveLayerId(layerId);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
      }}
    >
      <ActionButton
        variant="transparent"
        onClick={() => handleGenerate3d(ImageTo3dQualityType.Basic)}
        size="M"
      >
        Generate 3D · Standard
      </ActionButton>
      {canGenerateHighQuality3d ? (
        <ActionButton
          variant="transparent"
          onClick={() => handleGenerate3d(ImageTo3dQualityType.High)}
          size="M"
        >
          Generate 3D · Detailed
          <Chip variant="primary">BETA</Chip>
        </ActionButton>
      ) : (
        <PaywalledButton
          variant="transparent"
          onClick={triggerPaywallModal}
          size="M"
        >
          Generate 3D · Detailed
          <LockIcon />
        </PaywalledButton>
      )}
    </div>
  );
};

const PaywalledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  color: ${(p) => p.theme.text.info};
`;

const ActionButton = styled(Button)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;
