import errorIcon from '../../icons/error.svg?raw';
import { StaticSvg } from './StaticSvg';

export const ErrorPlaceholder = ({
  scale,
}: {
  scale?: number | [number, number];
}) => {
  return (
    <group>
      <mesh scale={Array.isArray(scale) ? ([...scale, 1] as any) : scale}>
        <planeGeometry args={[1, 1, 1, 1]} />
        <meshBasicMaterial color={0xbbbbbb} />
      </mesh>
      <StaticSvg svg={errorIcon} position={[0, 0, 0.1]} scale={[3, 3, 1]} />
    </group>
  );
};
