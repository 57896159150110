import {
  Button,
  LoadingLogoInset,
  OrganizationAvatar,
  Text,
  addToast,
  browseForFile,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  OrganizationData,
  useUpdateOrganization,
} from '@vizcom/shared/data-access/graphql';
import { useCanEditOrganization } from '../useCanEditOrganization';

export const OrganizationLogoSetting = ({
  organization,
}: {
  organization: OrganizationData;
}) => {
  const [updateOrganizationRes, updateOrganization] = useUpdateOrganization();
  const canEdit = useCanEditOrganization(organization.id);

  const handleUploadLogo = async () => {
    const file = await browseForFile({
      accept: 'image/*',
    });

    const res = await updateOrganization({
      input: {
        id: organization.id,
        patch: {
          logoPath: file,
        },
      },
    });

    if (res.error) {
      return addToast('Error while updating logo', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('The logo has been updated');
  };

  return (
    <div style={{ position: 'relative' }}>
      <Text block type="sh1" style={{ marginBottom: 16 }}>
        Workspace logo
      </Text>

      <div
        style={{
          display: 'flex',
          gap: 16,
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <OrganizationAvatar organization={organization} size="large" />
        {canEdit && (
          <Button type="button" variant="secondary" onClick={handleUploadLogo}>
            Upload logo
          </Button>
        )}
      </div>

      {canEdit && (
        <Text type="b1" color="secondary">
          Recommended size is 256x256px{' '}
        </Text>
      )}

      <LoadingLogoInset active={updateOrganizationRes.fetching} />
    </div>
  );
};
