import {
  ImageInferenceType,
  WorkbenchElementImg2ImgData,
  WorkbenchElementMixData,
} from '@vizcom/shared/data-access/graphql';
import { Line } from '@react-three/drei';
import { v4 as uuidv4 } from 'uuid';
import { useDrag } from '@use-gesture/react';
import { ThreeEvent, useFrame } from '@react-three/fiber';
import { ComponentRef, useMemo, useRef } from 'react';
import * as THREE from 'three';
import { Line2, LineGeometry, LineSegments2 } from 'three-stdlib';
import { useSpring, animated } from '@react-spring/three';
import { getBoxToBoxArrow } from 'perfect-arrows';

import { MAX_Z_POSITION, Z_AXIS, getElementSize } from '../helpers';
import { elementById, floorPlane } from '../../lib/utils';
import { assertUnreachable, filterExists } from '@vizcom/shared/js-utils';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import connectorSvg from './connector.svg?raw';
import connectorDisabledSvg from './connector_disabled.svg?raw';
import { findNearestParentObjectWithWorkbenchElementUserData } from '../objectsUserdata';
import { damp } from 'three/src/math/MathUtils';
import {
  ARROW_OPTIONS,
  ZERO_SEGMENTS,
  Z_POSITION_CONNECTOR_AREA,
  getSmoothLinePoints,
} from './utils';
import { FocusIndicator } from '../utils/FocusIndicator';
import { FixedSizeGroup } from '../utils/FixedSizeGroup';
import { ArrowTip } from '../utils/ArrowTip';
import { useTheme } from 'styled-components';
import { StaticSvg } from '../utils/StaticSvg';
import { getElementDefaultSize } from '../utils/getElementDefaultSize';
import { MixSourceDrawing } from '../elements/mix/WorkbenchElementMix';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';
import { ClientSideWorkbenchElementDrawing } from '../../lib/clientState';

const VALID_ELEMENT_TARGETS_BY_TYPENAME = {
  Drawing: ['WorkbenchElementImg2Img', 'WorkbenchElementMix'],
  WorkbenchElementImg2Img: ['Drawing'],
  WorkbenchElementMix: ['Drawing'],
};

const geom = new LineGeometry();

export const WorkbenchElementDragConnector = (props: {
  element:
    | WorkbenchElementImg2ImgData
    | ClientSideWorkbenchElementDrawing
    | WorkbenchElementMixData;
  disabled?: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  const theme = useTheme();
  const { element, handleAction } = props;
  const { width, height } = getElementSize(element);
  const rootGroupRef = useRef<THREE.Group>(null!);
  const userDraggingLineRef = useRef<Line2 | LineSegments2>(null!);
  const targetIndicatorRef = useRef<Line2 | LineSegments2>(null!);
  const arrowTipRef = useRef<ComponentRef<typeof ArrowTip>>(null!);

  const dragState = useMemo(
    () => ({
      target: new THREE.Vector3(0, 0, 0),
      targetWidth: -1,
      targetHeight: -1,
      isDragging: false,
    }),
    []
  );

  const connectorHorizontalPlacement =
    element.__typename === 'Drawing' ? 1 : -1;

  // handle user drag on the connector element, when dropped,
  const bind = useDrag<ThreeEvent<PointerEvent>>(
    ({ event, last }) => {
      event.stopPropagation();
      dragState.isDragging = true;
      const [target] = event.intersections
        .map((int) =>
          findNearestParentObjectWithWorkbenchElementUserData(int.object)
        )
        .filter(filterExists)
        .filter(
          (object) =>
            object.userData.elementId !== element.id &&
            VALID_ELEMENT_TARGETS_BY_TYPENAME[element.__typename].includes(
              object.userData.elementTypename
            )
        );
      const targetUserData = target?.userData;
      if (!target) {
        event.ray.intersectPlane(floorPlane, dragState.target);
        dragState.targetWidth = -1;
        dragState.targetHeight = -1;
      } else {
        dragState.target.copy(target.position);
        dragState.targetWidth = targetUserData.elementWidth;
        dragState.targetHeight = targetUserData.elementHeight;
      }
      rootGroupRef.current.worldToLocal(dragState.target);

      if (last) {
        dragState.isDragging = false;
        if (!target) {
          if (
            element.__typename === 'WorkbenchElementImg2Img' ||
            element.__typename === 'WorkbenchElementMix'
          ) {
            return;
          }
          const promptSize = getElementDefaultSize('WorkbenchElementImg2Img');

          return handleAction({
            type: 'createElements',
            newElements: [
              {
                __typename: 'WorkbenchElementImg2Img',
                id: uuidv4(),
                x: dragState.target.x + element.x + promptSize.x / 2,
                y: dragState.target.y + element.y,
                zIndex: element.zIndex,
                width: promptSize.x,
                height: promptSize.y,
                prompt: '',
                sourceImageInfluence: 1,
                imageInferenceType: ImageInferenceType.Render,
                publicPaletteId: PublicPalette.generalV2,
                updatedAt: new Date().toISOString(),
                sourceDrawingId: element.id,
              },
            ],
          });
        }

        if (element.__typename === 'Drawing') {
          if (targetUserData.elementTypename === 'WorkbenchElementImg2Img') {
            handleAction({
              type: 'updateAiImg2Img',
              elementId: targetUserData.elementId,
              sourceDrawingId: element.id,
            });
          } else {
            handleAction((elements) => {
              const sourceElement = elementById(
                elements,
                targetUserData.elementId
              );
              if (sourceElement?.__typename === 'WorkbenchElementMix') {
                const hasDrawings =
                  sourceElement.sourceDrawings &&
                  sourceElement.sourceDrawings.length > 0;

                const sourceDrawings = hasDrawings
                  ? [...sourceElement.sourceDrawings]
                  : [];
                if (sourceDrawings.length >= 5) return;

                const drawingIsConnected = sourceDrawings.some(
                  (source: MixSourceDrawing) =>
                    source.sourceDrawingId === element.id
                );

                if (drawingIsConnected) return;
                return {
                  type: 'updateMix',
                  elementId: targetUserData.elementId,
                  sourceDrawings: [
                    ...sourceDrawings,
                    { sourceDrawingId: element.id, weight: 0.5 },
                  ],
                };
              }
            });
          }
        } else if (element.__typename == 'WorkbenchElementImg2Img') {
          handleAction({
            type: 'updateAiImg2Img',
            elementId: element.id,
            sourceDrawingId: targetUserData.elementId,
          });
        } else if (element.__typename == 'WorkbenchElementMix') {
          handleAction((elements) => {
            const sourceElement = elementById(elements, element.id);
            if (sourceElement?.__typename === 'WorkbenchElementMix') {
              const hasDrawings =
                sourceElement.sourceDrawings &&
                sourceElement.sourceDrawings.length > 0;
              const sourceDrawings = hasDrawings
                ? [...sourceElement.sourceDrawings]
                : [];
              const drawingIsConnected = sourceDrawings.some(
                (source: MixSourceDrawing) =>
                  source.sourceDrawingId === targetUserData.elementId
              );

              if (drawingIsConnected) return;

              if (sourceDrawings.length >= 5) {
                const cappedDrawings = sourceDrawings.slice(0, 5);
                cappedDrawings.pop();
                return {
                  type: 'updateMix',
                  elementId: element.id,
                  sourceDrawings: [
                    ...cappedDrawings,
                    { sourceDrawingId: targetUserData.elementId, weight: 0.5 },
                  ],
                };
              }

              return {
                type: 'updateMix',
                elementId: element.id,
                sourceDrawings: [
                  ...sourceDrawings,
                  { sourceDrawingId: targetUserData.elementId, weight: 0.5 },
                ],
              };
            }
          });
        } else {
          assertUnreachable(element);
        }
      }
    },
    {
      pointer: {
        keys: false,
      },
    }
  );

  useFrame((s, dt) => {
    // update the line connector points positions by either using the temporary position the user is currently dragging to
    // or finding the target element and getting its position
    if (dragState.isDragging) {
      const targetBoxWidth =
        dragState.targetWidth === -1 ? 5 : dragState.targetWidth;
      const targetBoxHeight =
        dragState.targetHeight === -1 ? 5 : dragState.targetHeight;
      let arrow: number[];
      if (
        element.__typename === 'WorkbenchElementImg2Img' ||
        element.__typename === 'WorkbenchElementMix'
      ) {
        // to keep the arrows consistent between dragging and releasing, we need to always keep the same source/target combo between the img2img element and the drawing
        // this means that when the parent element of <WorkbenchElementDragConnector /> is not img2img, we invert the source and target of the arrow to always keep the img2img
        // element as the target
        arrow = getBoxToBoxArrow(
          dragState.target.x + targetBoxWidth / 2 + 10,
          dragState.target.y,
          1,
          1,
          -10 - width / 2, // we are generating this in the coordinate space of the source element, we can use 0,0 as coordinates
          0 - height / 2,
          1,
          height,
          ARROW_OPTIONS
        );
      } else {
        arrow = getBoxToBoxArrow(
          10 + width / 2, // we are generating this in the coordinate space of the source element, we can use 0,0 as coordinates
          0,
          1,
          1,
          dragState.target.x - targetBoxWidth / 2 - 10,
          dragState.target.y - targetBoxHeight / 2,
          1,
          targetBoxHeight,
          ARROW_OPTIONS
        );
      }

      const [sx, sy, cx, cy, ex, ey, ae, as, ec] = arrow;

      const points = getSmoothLinePoints(sy, sx, cy, ex, ey);

      geom.setPositions(points.flatMap((p) => [p.x, p.y, p.z]));
      userDraggingLineRef.current.geometry = geom.clone();
      userDraggingLineRef.current.material.opacity = 1;

      const pointLeft =
        element.__typename === 'WorkbenchElementImg2Img' ||
        element.__typename === 'WorkbenchElementMix';
      arrowTipRef.current.position.set(
        pointLeft ? sx - 10 : ex + 10,
        pointLeft ? sy : ey,
        0
      );
      arrowTipRef.current.setRotationFromAxisAngle(
        Z_AXIS,
        pointLeft ? Math.PI : 0
      );
      arrowTipRef.current.material.opacity = 1;
      arrowTipRef.current.renderOrder = 100;

      // Show a focus indicator around the targeted element
      if (dragState.targetWidth !== -1) {
        targetIndicatorRef.current.position.copy(dragState.target);
        targetIndicatorRef.current.position.z = 1;
        targetIndicatorRef.current.scale.set(
          dragState.targetWidth / 2,
          dragState.targetHeight / 2,
          1
        );
        targetIndicatorRef.current.material.opacity = damp(
          targetIndicatorRef.current.material.opacity,
          1,
          20,
          dt
        );
      } else {
        targetIndicatorRef.current.material.opacity = damp(
          targetIndicatorRef.current.material.opacity,
          0,
          20,
          dt
        );
      }
    } else {
      arrowTipRef.current.material.opacity = 0;
      arrowTipRef.current.renderOrder = -100;

      if (userDraggingLineRef.current.material.opacity > 0.01) {
        userDraggingLineRef.current.material.opacity = damp(
          userDraggingLineRef.current.material.opacity,
          0,
          20,
          dt
        );
      }
      if (targetIndicatorRef.current.material.opacity > 0.01) {
        targetIndicatorRef.current.material.opacity = damp(
          targetIndicatorRef.current.material.opacity,
          0,
          20,
          dt
        );
      }
    }
  });

  const { scale } = useSpring({
    from: { scale: [0, 0, 1] },
    to: { scale: [1, 1, 1] },
  });

  return (
    <group
      position={[0, 0, MAX_Z_POSITION - element.zIndex]}
      ref={rootGroupRef}
      renderOrder={20}
    >
      <FixedSizeGroup
        scale={[1.5, 1.5, 1]}
        position={[(width / 2) * connectorHorizontalPlacement, 0, 0.1]}
        maxScale={6}
        userData={{
          cursor: 'grab',
        }}
      >
        <animated.group scale={scale} {...(bind() as any)}>
          <StaticSvg
            svg={props.disabled ? connectorDisabledSvg : connectorSvg}
          />
        </animated.group>
      </FixedSizeGroup>
      <Line
        ref={userDraggingLineRef}
        color={theme.primary.default}
        lineWidth={2}
        points={ZERO_SEGMENTS}
        transparent
        depthTest={false}
        depthWrite={false}
        renderOrder={-100}
      />
      <ArrowTip ref={arrowTipRef} />
      <FocusIndicator
        active={false}
        height={0}
        width={0}
        ref={targetIndicatorRef}
      />
    </group>
  );
};
