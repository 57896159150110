import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ThreeEvent, useStore, useThree } from '@react-three/fiber';
import { Line } from '@react-three/drei';
import { useDrag } from '@use-gesture/react';
import styled, { useTheme } from 'styled-components';
import {
  DoubleSide,
  MathUtils,
  Mesh,
  Group,
  OrthographicCamera,
  Plane,
  Vector3,
} from 'three';
import {
  WorkbenchStudioTool,
  isSelectionTool,
  useWorkbenchStudioToolState,
} from '../studioState';
import {
  layerPositionToScreen,
  screenPositionToLayer,
  useCameraZoom,
} from '../../helpers';
import { Line2 } from 'three-stdlib';
import { SyncedActionPayloadFromType } from '../../../lib/SyncedAction';
import { MutateLocalStateAction } from '../../../lib/actions/drawing/mutateLocalStateAction';
import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  useLastValue,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import { useDrawingSyncedState } from '../../../lib/useDrawingSyncedState';
import { EventMesh } from './EventMesh';
import { HtmlOverlay } from '../../utils/HtmlOverlay';
import {
  SymmetryPositionHandle,
  SymmetryRotateHandle,
} from './symmetryHandles';
import { useSpring, animated, easings } from '@react-spring/three';

interface SymmetryAxisProps {
  drawingSize: [number, number];
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
}

export enum LockState {
  LOCKED,
  UNLOCKING,
  UNLOCKED,
}

export const SymmetryAxis = ({
  drawingSize,
  handleAction,
}: SymmetryAxisProps) => {
  const { symmetry, setSymmetry, tool } = useWorkbenchStudioToolState();

  const axisPositionGroupRef = useRef<Group>(null!);
  const axisRotationGroupRef = useRef<Group>(null!);
  const eventPlaneRef = useRef<Mesh>(null!);
  const lineRef = useRef<Line2>(null!);
  const theme = useTheme();
  const store = useStore();
  const zoom = useCameraZoom();
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const [lockState, setLockState] = useState(LockState.LOCKED);
  const [rotation, setRotation] = useState(0);
  const [position, setPosition] = useState([0, 0, 0]);
  const [isRotating, setIsRotating] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const tooltipHandle = useRef<LockTooltipHandle>(null!);

  const rotationSpring = useSpring({
    opacity: isRotating ? 1 : 0,
    config: { duration: 400, easing: easings.easeInOutCubic },
  });

  const unlockingTimeout = useRef<ReturnType<typeof setTimeout>>();
  const startUnlocking = () => {
    setLockState(LockState.UNLOCKING);
    clearTimeout(unlockingTimeout.current);
    unlockingTimeout.current = setTimeout(() => {
      tooltipHandle.current?.hide();
      setLockState(LockState.UNLOCKED);
    }, 500);
  };

  const cancelUnlocking = () => {
    setLockState(LockState.LOCKED);
    tooltipHandle.current?.show();
    clearTimeout(unlockingTimeout.current);
  };

  const lock = () => {
    setIsMoving(false);
    setIsRotating(false);
    setLockState(LockState.LOCKED);
    tooltipHandle.current?.hide();
    clearTimeout(unlockingTimeout.current);
  };

  useEffect(() => {
    return () => {
      clearTimeout(unlockingTimeout.current);
    };
  }, []);

  const symmetryStateRef = useLastValue({
    origin: symmetry.origin,
    rotation: symmetry.rotation,
  });

  //Undo - Redo
  const getMutateLocalStateAction = useStableCallback(
    (editionFunction: () => void) => {
      const snapshot = {
        origin: [0.5, 0.5] as [number, number],
        rotation: 0,
      };

      const action = {
        type: 'mutateLocalState',
        onExecute: () => {
          snapshot.origin = symmetryStateRef.current.origin;
          snapshot.rotation = symmetryStateRef.current.rotation;
          editionFunction();
        },
        undoConstructor: () =>
          getMutateLocalStateAction!(() => {
            setSymmetry({
              ...symmetry,
              origin: snapshot.origin,
              rotation: snapshot.rotation,
            });
          }),
      } as SyncedActionPayloadFromType<typeof MutateLocalStateAction>;
      return action;
    }
  );

  const size = 2 * Math.hypot(drawingSize[0], drawingSize[1]);
  const lockedStyle = {
    color: '#d5d5dd',
    lineWidth: 1,
    isDashed: true,
    dash: 32,
    gap: 46,
  };
  const activeStyle = {
    color: theme.primary.default,
    lineWidth: 1,
    isDashed: false,
    dash: 1,
    gap: 0,
  };

  const rotate = useDrag<ThreeEvent<PointerEvent>>((gesture) => {
    gesture.event.stopPropagation();
    if (lockState !== LockState.UNLOCKED) {
      return;
    }
    setIsRotating(true);

    const camera = store.getState().camera as OrthographicCamera;
    const pointer = screenPositionToLayer(
      [gesture.event.clientX, gesture.event.clientY],
      camera,
      eventPlaneRef.current,
      drawingSize
    );

    let rotation = Math.atan2(
      symmetry.origin[0] * drawingSize[0] - pointer[0],
      symmetry.origin[1] * drawingSize[1] - pointer[1]
    );

    const snap = 0.05 / camera.zoom;
    const snapGranularity = (Math.PI * 2) / 8;
    const snappedRotation =
      Math.round(rotation / snapGranularity) * snapGranularity;
    if (Math.abs(rotation - snappedRotation) < snap) {
      rotation = snappedRotation;
    }

    setRotation(rotation);
    if (gesture.last) {
      setIsRotating(false);
      handleAction(
        getMutateLocalStateAction!(() => {
          setSymmetry({
            ...symmetry,
            rotation,
          });
        })
      );
      return;
    }

    axisRotationGroupRef.current.rotation.z = rotation;
  });

  const translate = useDrag<ThreeEvent<PointerEvent>>((gesture) => {
    if (gesture.tap) {
      if (lockState === LockState.UNLOCKING) {
        cancelUnlocking();
      }
      return;
    }
    if (gesture.first && lockState === LockState.LOCKED) {
      startUnlocking();
      return;
    }
    gesture.event.stopPropagation();
    if (lockState !== LockState.UNLOCKED) {
      return;
    }

    setIsMoving(true);

    const camera = store.getState().camera as OrthographicCamera;
    const pointer = screenPositionToLayer(
      [gesture.event.clientX, gesture.event.clientY],
      camera,
      eventPlaneRef.current,
      drawingSize
    );

    pointer[0] /= drawingSize[0];
    pointer[1] /= drawingSize[1];

    const offset = gesture.memo || {
      x: symmetry.origin[0] - pointer[0],
      y: symmetry.origin[1] - pointer[1],
    };
    const origin: [number, number] = [
      MathUtils.clamp(pointer[0] + offset.x, 0, 1),
      MathUtils.clamp(pointer[1] + offset.y, 0, 1),
    ];

    const snap = 15 / camera.zoom;
    if (Math.abs(origin[0] - 0.5) < snap / drawingSize[0]) origin[0] = 0.5;
    if (Math.abs(origin[1] - 0.5) < snap / drawingSize[1]) origin[1] = 0.5;

    setPosition([origin[0], origin[1], 0]);
    if (gesture.last) {
      setIsMoving(false);
      handleAction(
        getMutateLocalStateAction!(() => {
          setSymmetry({
            ...symmetry,
            origin,
          });
        })
      );
      return;
    }

    axisPositionGroupRef.current.position.set(
      (origin[0] - 0.5) * drawingSize[0],
      -(origin[1] - 0.5) * drawingSize[1],
      1
    );

    return offset;
  });

  const resetRotation = useCallback(() => {
    setSymmetry({
      ...symmetry,
      rotation: 0,
    });
  }, [symmetry, setSymmetry]);

  const resetPosition = useCallback(() => {
    lock();
    setSymmetry({
      ...symmetry,
      origin: [0.5, 0.5],
    });
  }, [symmetry, setSymmetry]);

  useEffect(() => {
    if (lineRef.current?.material) {
      lineRef.current.material.clippingPlanes = [
        new Plane(new Vector3(1, 0, 0), 0.5 * drawingSize[0]),
        new Plane(new Vector3(-1, 0, 0), 0.5 * drawingSize[0]),
        new Plane(new Vector3(0, 1, 0), 0.5 * drawingSize[1]),
        new Plane(new Vector3(0, -1, 0), 0.5 * drawingSize[1]),
      ];
    }
  }, [lineRef, drawingSize]);

  const onEnterHandle = useCallback((e: PointerEvent) => {
    e.stopPropagation();
  }, []);

  const onLeaveHandle = useCallback(
    (e: PointerEvent) => {
      e.stopPropagation();
      if (lockState === LockState.UNLOCKING) {
        cancelUnlocking();
      }
    },
    [lockState]
  );

  if (
    ![
      WorkbenchStudioTool.Brush,
      WorkbenchStudioTool.Eraser,
      WorkbenchStudioTool.Shape,
    ].includes(tool) ||
    isSelectionTool(tool)
  ) {
    return null;
  }

  const lineStyle =
    lockState === LockState.UNLOCKED ? activeStyle : lockedStyle;

  return (
    <>
      <EventMesh
        drawingSize={drawingSize}
        ref={eventPlaneRef}
        eventMeshProps={{
          renderOrder: 2,
          onPointerDown: () => {
            lock();
          },
        }}
      />
      <group
        ref={axisPositionGroupRef}
        position={[
          (symmetry.origin[0] - 0.5) * drawingSize[0],
          (0.5 - symmetry.origin[1]) * drawingSize[1],
          1,
        ]}
        renderOrder={3}
      >
        {
          // @ts-expect-error ts: Type instantiation is excessively deep and possibly infinite.
          <AnimatedCircleLine
            radius={150}
            pointsCount={40}
            renderOrder={2}
            side={DoubleSide}
            dashed={true}
            dashSize={10}
            gapSize={10}
            dashOffset={-5}
            color={theme.primary.default}
            lineWidth={2}
            opacity={rotationSpring.opacity}
            transparent
            visible={isRotating}
          />
        }
        <group
          ref={axisRotationGroupRef}
          rotation={[0, 0, symmetry.rotation]}
          renderOrder={3}
        >
          <Line
            ref={lineRef}
            color={lineStyle.color}
            transparent
            depthTest={false}
            side={DoubleSide}
            lineWidth={lineStyle.lineWidth}
            dashed={lineStyle.isDashed}
            dashSize={lineStyle.dash}
            dashOffset={size}
            gapSize={lineStyle.gap}
            points={[
              [0, -size * 0.5, 0],
              [0, size * 0.5, 0],
            ]}
          />
          <SymmetryRotateHandle
            renderOrder={3}
            position={[0, 150, 0]}
            scale={[1 / zoom, 1 / zoom, 1]}
            lockState={lockState}
            pointerEvents={{
              ...(rotate() as any),
              onDoubleClick: resetRotation,
              onPointerEnter: onEnterHandle,
              onPointerLeave: onLeaveHandle,
            }}
          />
          <SymmetryPositionHandle
            renderOrder={3}
            scale={[1 / zoom, 1 / zoom, 1]}
            lockState={lockState}
            pointerEvents={{
              ...(translate() as any),
              onDoubleClick: resetPosition,
              onPointerEnter: onEnterHandle,
              onPointerLeave: onLeaveHandle,
            }}
          />
        </group>
      </group>
      <LockTooltip
        ref={tooltipHandle}
        position={
          layerPositionToScreen(
            symmetry.origin,
            camera,
            axisPositionGroupRef.current,
            drawingSize
          ) as [number, number]
        }
        tip="Tap & Hold to Unlock"
      />
      <HtmlOverlay>
        {isRotating && (
          <StyledPosition>
            <p>{Math.round((-rotation * 180) / Math.PI)}º</p>
          </StyledPosition>
        )}
        {isMoving && (
          <StyledPosition>
            <table>
              <tbody>
                <tr>
                  <th style={{ fontWeight: 'normal' }}>X: </th>
                  <td>{Math.round(position[0] * 100)} %</td>
                </tr>
                <tr>
                  <th style={{ fontWeight: 'normal' }}>Y: </th>
                  <td>{Math.round(position[1] * 100)} %</td>
                </tr>
              </tbody>
            </table>
          </StyledPosition>
        )}
      </HtmlOverlay>
    </>
  );
};

const StyledPosition = styled.div`
  padding: 5px 7px;
  border-radius: 15px;
  position: absolute;
  top: calc(1rem + 66px);
  z-index: 10000000;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: right;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.primary.default};
`;

interface LockTooltipHandle {
  show: () => void;
  hide: () => void;
}

const LockTooltip = forwardRef<
  LockTooltipHandle,
  { position: [number, number]; tip: string }
>(({ position, tip }, ref) => {
  const theme = useTheme();

  const visibleTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(visibleTimeout.current);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    show: () => {
      setIsOpen(true);
      clearTimeout(visibleTimeout.current);
      visibleTimeout.current = setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    },
    hide: () => {
      clearTimeout(visibleTimeout.current);
      setIsOpen(false);
    },
  }));
  return (
    <>
      <HtmlOverlay>
        <RichTooltip manualOpen={isOpen} placement="top" padding={0}>
          <RichTooltipTrigger>
            <div
              style={{
                position: 'absolute',
                top: position[1] - 10 + 'px',
                left: position[0] + 'px',
              }}
            ></div>
          </RichTooltipTrigger>
          <RichTooltipContent style={{ color: theme.white, padding: 14 }}>
            {tip}
          </RichTooltipContent>
        </RichTooltip>
      </HtmlOverlay>
    </>
  );
});

interface CircleLineProps
  extends Omit<React.ComponentProps<typeof Line>, 'points'> {
  radius: number;
  pointsCount?: number;
}

const CircleLine = ({
  radius,
  pointsCount = 20,
  ...lineProps
}: CircleLineProps) => {
  const points = useMemo(() => {
    const pts: [number, number, number][] = [];
    for (let i = 0; i < pointsCount; i++) {
      const ang = (2 * Math.PI * i) / (pointsCount - 1);
      pts[i] = [radius * Math.cos(ang), radius * Math.sin(ang), 0];
    }
    return pts;
  }, [radius, pointsCount]);
  return <Line {...lineProps} points={points} />;
};

const AnimatedCircleLine = animated(CircleLine);
