import { useEffect, useMemo, useState } from 'react';
import {
  UserBasicData,
  getAccessToken,
  getSharingSecret,
  useUsersById,
} from '@vizcom/shared/data-access/graphql';
import {
  workbenchMultiplayerClientMessage,
  workbenchMultiplayerServerMessage,
} from '@vizcom/shared/data-shape';
import {
  useImmer,
  useWebsocket,
} from '../../../../../shared/ui/components/src';
import { throttle } from 'lodash-es';
import { z } from 'zod';
import {
  assertExists,
  assertUnreachable,
} from '../../../../../shared/js-utils/src';

export interface MultiplayerPresence {
  id: string; // id of the connection browser, is unique
  userId: string | null; // id of the user, a single user can have multiple cursors
  cursor: null | {
    x: number;
    y: number;
  };
  camera: null | {
    x: number;
    y: number;
    zoom: number;
  };
  focusedElementId: string | null;
  editingElementId: string | null;
  user?: UserBasicData;
}

let multiplayerHttpUrl =
  (import.meta.env.NX_VIZCOM_API_BASE_URL || '/api/v1') +
  '/workbench_multiplayer';
if (!multiplayerHttpUrl.includes('http')) {
  // in production, we don't include the full URL, just `/api/v1/graph`, in this case
  // we need to add back the origin to the URL
  multiplayerHttpUrl = `${location.origin}${multiplayerHttpUrl}`;
}
const wsUrl = multiplayerHttpUrl.replace('http', 'ws');

export const useWorkbenchMultiplayer = (
  workbenchId: string,
  editingElementId: string | null
) => {
  const [multiplayerPresences, setMultiplayerPresences] = useImmer<
    MultiplayerPresence[]
  >([]);
  const [selectedPresenceId, setSelectedPresenceId] = useState<string | null>(
    null
  );
  const [selfPresence, setSelfPresence] = useState<MultiplayerPresence | null>(
    null
  );

  const { sendMessage } = useWebsocket<
    z.infer<typeof workbenchMultiplayerClientMessage>,
    z.infer<typeof workbenchMultiplayerServerMessage>
  >(
    wsUrl,
    (data) => {
      if (data.type === 'update') {
        setMultiplayerPresences((presences) => {
          const existingPresence = presences.find((c) => c.id === data.id);

          if (!existingPresence) {
            presences.push({
              id: data.id,
              userId: data.userId,
              cursor: {
                x: data.x,
                y: data.y,
              },
              camera: null,
              focusedElementId: null,
              editingElementId: null,
            });
          } else {
            existingPresence.cursor = {
              x: data.x,
              y: data.y,
            };
          }
        });
      } else if (data.type === 'left') {
        setMultiplayerPresences((presences) => {
          const index = presences.findIndex((c) => c.id === data.id);
          if (index !== -1) {
            presences.splice(index, 1);
          }
        });
      } else if (data.type === 'authResponse') {
        setSelfPresence({
          id: data.id,
          userId: data.userId,
          cursor: null,
          camera: null,
          focusedElementId: null,
          editingElementId: null,
        });
      } else if (data.type === 'updateCamera') {
        setMultiplayerPresences((presences) => {
          const existingPresence = presences.find((c) => c.id === data.id);

          if (!existingPresence) {
            presences.push({
              id: data.id,
              userId: data.userId,
              cursor: null,
              camera: {
                x: data.x,
                y: data.y,
                zoom: data.zoom,
              },
              focusedElementId: null,
              editingElementId: null,
            });
          } else {
            existingPresence.camera = {
              x: data.x,
              y: data.y,
              zoom: data.zoom,
            };
          }
        });
      } else if (data.type === 'updateFocusedElement') {
        setMultiplayerPresences((presences) => {
          const existingPresence = presences.find((c) => c.id === data.id);
          if (!existingPresence) {
            presences.push({
              id: data.id,
              userId: data.userId,
              cursor: null,
              camera: null,
              focusedElementId: data.focusedElementId,
              editingElementId: null,
            });
          } else {
            existingPresence.focusedElementId = data.focusedElementId;
          }
        });
      } else if (data.type === 'updateEditingElement') {
        setMultiplayerPresences((presences) => {
          const existingPresence = presences.find((c) => c.id === data.id);
          if (!existingPresence) {
            presences.push({
              id: data.id,
              userId: data.userId,
              cursor: null,
              camera: null,
              focusedElementId: data.editingElementId,
              editingElementId: data.editingElementId,
            });
          } else {
            existingPresence.editingElementId = data.editingElementId;
            existingPresence.focusedElementId = data.editingElementId;
          }
        });
      } else {
        assertUnreachable(data);
      }
    },
    () => {
      const accessToken = getAccessToken();
      const sharingSecret = getSharingSecret();

      assertExists(
        accessToken || sharingSecret,
        'No access token or sharing secret found'
      );

      if (accessToken) {
        sendMessage({
          type: 'auth-accessToken',
          workbenchId,
          accessToken,
          sharingSecret,
        });
      } else if (sharingSecret) {
        sendMessage({
          type: 'auth-sharingSecret',
          workbenchId,
          sharingSecret,
        });
      }

      // reset presence in case of reconnection
      setMultiplayerPresences([]);
    }
  );

  const handleCursorMove = useMemo(
    () =>
      throttle((x, y) => {
        sendMessage({
          type: 'cursor',
          x,
          y,
        });
      }, 100),
    [sendMessage]
  ); // send every 100ms

  const handleCameraMove = useMemo(
    () =>
      throttle((x, y, zoom) => {
        sendMessage({
          type: 'camera',
          x,
          y,
          zoom,
        });
      }, 100),
    [sendMessage]
  ); // send every 100ms

  const handleFocusedElementChange = useMemo(
    () => (focusedElementId: string) => {
      sendMessage({
        type: 'focusedElement',
        focusedElementId,
      });
    },
    [sendMessage]
  );

  useEffect(() => {
    sendMessage({
      type: 'editingElement',
      editingElementId,
    });
  }, [editingElementId, sendMessage]);

  const { data: users } = useUsersById(
    multiplayerPresences
      .filter((presence) => Boolean(presence.userId))
      .map((presence) => presence.userId!)
  );

  const multiplayerPresencesWithUserData = useMemo(
    () =>
      multiplayerPresences.map((presence) => ({
        ...presence,
        user: users?.find((u) => u.id === presence.userId),
      })),
    [multiplayerPresences, users]
  );

  const selectedPresence = multiplayerPresencesWithUserData.find(
    ({ id }) => id === selectedPresenceId
  );

  return {
    selfPresence,
    multiplayerPresences: multiplayerPresencesWithUserData,
    handleCursorMove,
    handleCameraMove,
    handleFocusedElementChange,
    selectedPresence: selectedPresence,
    setSelectedPresenceId,
  };
};
