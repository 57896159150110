import {
  LoadingLogoInset,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import { StepIndicator } from './stepIndicator';
import { CreateOrganizationHeader } from './CreateOrganizationHeader';
import { paths } from '@vizcom/shared-utils-paths';
import { CreateOrganizationNameInputPage } from './CreateOrganizationNameInputPage';
import { useState } from 'react';
import { CreateOrganizationInvitesPage } from './CreateOrganizationInvitesPage';
import {
  useCreateOrganization,
  useCreateOrganizationInvite,
  useCurrentUser,
  usePendingInvitedOrganizations,
  useWebConfig,
} from '@vizcom/shared/data-access/graphql';
import { CreateOrganizationSuccessPage } from './CreateOrganizationSuccessPage';
import { CreateOrganizationJoinExistingPage } from './CreateOrganizationJoinExistingPage';
import { CreateOrganizationBlockedPage } from './CreateOrganizationBlockedPage';

export const CreateOrganizationPage = () => {
  const { data: webConfig, fetching: fetchingWebConfig } = useWebConfig();
  const { data: currentUser } = useCurrentUser();
  const [createOrganizationRes, createOrganization] = useCreateOrganization();
  const {
    data: pendingInvitedOrganizations,
    fetching: fetchingPendingInvites,
  } = usePendingInvitedOrganizations();
  const [createOrganizationInvitesRes, createOrganizationInvite] =
    useCreateOrganizationInvite();
  const navigate = useNavigate();
  const routesState = [
    useMatch(paths.organization.create() + '/name'),
    useMatch(paths.organization.create() + '/invites'),
    useMatch(paths.organization.create() + '/success'),
  ];

  const currentStep = routesState.findIndex((active) => active);

  const [name, setName] = useState(
    currentUser?.name ? `${currentUser.name}'s Workspace` : ''
  );
  const [inviteEmails, setInviteEmails] = useState<string[]>([]);

  if (fetchingWebConfig || fetchingPendingInvites) {
    return (
      <Container>
        <LoadingLogoInset />
      </Container>
    );
  }

  if (webConfig?.blockCreateOrganization) {
    return (
      <Container>
        <CreateOrganizationHeader />
        <CreateOrganizationBlockedPage />
      </Container>
    );
  }

  const handleCreateOrganization = async () => {
    if (!name) {
      navigate(paths.organization.create() + '/name');
      return;
    }
    const res = await createOrganization({
      input: {
        name,
      },
    });
    if (res.error || !res.data?.createOrganization?.organization) {
      return addToast('Error while creating organization', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    for (const email of inviteEmails) {
      const inviteRes = await createOrganizationInvite({
        input: {
          organizationInvite: {
            organizationId: res.data.createOrganization.organization.id,
            email,
          },
        },
      });
      if (inviteRes.error) {
        addToast(`Error while inviting ${email}:`, {
          type: 'danger',
          secondaryText: formatErrorMessage(inviteRes.error),
        });
      }
    }
    navigate(paths.organization.create() + '/success');
    setName('');
    setInviteEmails([]);
  };

  const createOrganizationGlobalTeamId =
    createOrganizationRes.data?.createOrganization?.organization?.globalTeam
      ?.id;

  return (
    <Container>
      <CreateOrganizationHeader />

      <LoadingLogoInset
        active={
          createOrganizationRes.fetching ||
          createOrganizationInvitesRes.fetching
        }
      />

      <Routes>
        <Route
          path="name"
          element={
            <CreateOrganizationNameInputPage name={name} onSetName={setName} />
          }
        />
        <Route
          path="invites"
          element={
            <CreateOrganizationInvitesPage
              emails={inviteEmails}
              onSetEmails={setInviteEmails}
              onSubmit={handleCreateOrganization}
            />
          }
        />
        <Route
          path="success"
          element={
            createOrganizationGlobalTeamId && (
              <CreateOrganizationSuccessPage
                organizationGlobalTeamId={createOrganizationGlobalTeamId}
              />
            )
          }
        />
        <Route
          path="join_existing"
          element={<CreateOrganizationJoinExistingPage />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={
                pendingInvitedOrganizations &&
                pendingInvitedOrganizations.length > 0
                  ? 'join_existing'
                  : 'name'
              }
              replace
            />
          }
        />
      </Routes>

      <Spacer />

      <StepIndicator count={3} currentStep={currentStep} />
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(p) => p.theme.surface.e0};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 40px;
`;

const Spacer = styled.div`
  flex: 1;
`;
