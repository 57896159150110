import {
  Button,
  LoadingErrorPage,
  LoadingLogo,
  Text,
  Wrapper,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { useFetchOrCreateUserOrganizationDraftFolder } from '@vizcom/shared/data-access/graphql';
import { Link } from 'react-router-dom';
import { Folder } from '../Folder/Folder';

export const DraftsPage = () => {
  const {
    data: organization,
    loading: loadingOrganization,
    error: errorOrganization,
  } = useSelectedOrganization();

  const { loading, error, data } = useFetchOrCreateUserOrganizationDraftFolder(
    organization?.id
  );

  if (!loadingOrganization && (errorOrganization || !organization)) {
    return (
      <LoadingErrorPage
        targetType="page"
        errorMessage={
          errorOrganization?.graphQLErrors?.[0]?.message ??
          errorOrganization?.message
        }
      />
    );
  }

  if (loading || loadingOrganization) {
    return (
      <Wrapper
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <LoadingLogo />
      </Wrapper>
    );
  }

  if (error || data === null || data === undefined) {
    return (
      <LoadingErrorPage
        targetType="page"
        errorMessage={error.graphQLErrors?.[0]?.message ?? error.message}
      />
    );
  }

  return <Folder folderId={data.id} isDraftsPage />;
};
