import { useEffect, useState, useMemo } from 'react';

import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { WorkbenchElement } from './WorkbenchElement';
import { getElementSize } from './helpers';
import { MultiplayerPresence } from '../lib/useWorkbenchMultiplayer';
import { groupBy } from 'lodash';
import { CustomHtml } from './utils/CustomHtml';
import { WorkbenchElementsExtra } from './WorkbenchElementsExtra';
import { WorkbenchViewportControls } from './WorkbenchViewportControls';
import {
  getCameraLimitsFromWorkbenchElements,
  useMapControls,
} from './utils/mapControls/utils';
import { WorkbenchCameraGestures } from './WorkbenchCameraGestures';
import { useLastWorkbenchCameraPosition } from './utils/useLastWorkbenchCameraPosition';
import { ResizerControls } from './ResizerControls';
import { WorkbenchHelpMenu } from './WorkbenchHelpMenu';
import { WorkbenchContentFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { elementIsMix, useIsWorkbenchViewer } from '../lib/utils';
import { OrthographicCamera } from 'three';
import { useThree } from '@react-three/fiber';
import { NewElementData } from '../lib/actions/workbench/multiUpdateElementsAction';
import {
  MixSourceDrawing,
  SourceDrawingData,
} from './elements/mix/WorkbenchElementMix';
import { WorkbenchElementDrawingData } from '@vizcom/shared/data-access/graphql';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { useWorkbenchToolState } from './toolbar/WorkbenchToolContext';
import { FloatingMouseText } from './utils/FloatingMouseText';
import { WORKBENCH_ELEMENT_TEXT_PLACEHOLDER } from './elements/text/WorkbenchElementText';

export const WorkbenchCanvasContent = (props: {
  elements: ClientSideWorkbenchElementData[];
  workbench: WorkbenchContentFragment;
  editingElementId: string | null;
  multiplayerPresences: MultiplayerPresence[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}) => {
  const isViewer = useIsWorkbenchViewer();
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const controls = useMapControls();
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const { focusedElementsId } = useWorkbenchElementSelectionState();
  const { tool } = useWorkbenchToolState();

  const { elements, handleAction } = props;

  const onFitToScreen = () => {
    const { xMin, xMax, yMin, yMax } = cameraLimits;
    const x = (xMin + xMax) / 2;
    const y = (yMin + yMax) / 2;
    const zoomForWidth = (camera.right - camera.left) / (xMax - xMin);
    const zoomForHeight = (camera.top - camera.bottom) / (yMax - yMin);
    const zoom = Math.min(zoomForWidth, zoomForHeight);
    controls.moveTo({ x, y, zoom });
  };

  const sourceDrawingsByMixId = useMemo(() => {
    return elements.reduce<Record<string, Record<string, SourceDrawingData>>>(
      (acc, element) => {
        if (elementIsMix(element)) {
          if (!acc[element.id]) {
            acc[element.id] = {};
          }

          (element.sourceDrawings as MixSourceDrawing[]).forEach((source) => {
            const drawing = elements.find(
              (el) => el.id === source.sourceDrawingId
            ) as WorkbenchElementDrawingData | undefined;
            if (!drawing) {
              return;
            }
            const { height, width } = getElementSize(drawing);
            acc[element.id][source.sourceDrawingId] = {
              thumbnail: drawing?.thumbnailPath,
              height,
              width,
            };
          });
        }
        return acc;
      },
      {} as Record<string, Record<string, SourceDrawingData>>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(
      elements
        .filter(elementIsMix)
        .map((element) =>
          (element.sourceDrawings as MixSourceDrawing[]).map(
            (source) => source.sourceDrawingId
          )
        )
    ),
  ]);

  useEffect(() => {
    if (isViewer) {
      return;
    }

    const elementsWithoutSections = elements.filter(
      (element) => element.__typename !== 'WorkbenchElementSection'
    );
    // Make sure that elements with the same z-index are not overlapping
    // by moving overlapping elements on top
    const zIndexGroupedElements = groupBy(
      elementsWithoutSections,
      (element) => element.zIndex
    );
    let maxZPosition = Math.max(
      ...elementsWithoutSections.map((element) => element.zIndex)
    );

    const newZpositions: NewElementData[] = [];
    Object.values(zIndexGroupedElements).forEach((elements) => {
      if (elements.length < 2) {
        return;
      }
      elements.slice(1).forEach((element) => {
        newZpositions.push({
          id: element.id,
          x: element.x,
          y: element.y,
          zIndex: maxZPosition + 1,
          width: getElementSize(element).width,
          height: getElementSize(element).height,
        });
        maxZPosition++;
      });
    });
    if (newZpositions.length > 0) {
      handleAction(
        {
          type: 'multiPosition',
          newElementData: newZpositions,
        },
        {
          skipHistory: true,
        }
      );
    }
  }, [elements, handleAction, isViewer]);

  const focusedElementsIds = focusedElementsId.split('/').filter((id) => id);
  const multiFocused = focusedElementsIds.length > 1;
  const focusedElements = elements.filter((element) =>
    focusedElementsIds.some((id) => id === element.id)
  );

  const [focusedGroupX, focusedGroupY, focusedGroupZ] =
    getFocusedGroupPosition(focusedElements);

  const cameraLimits = getCameraLimitsFromWorkbenchElements(elements);
  useLastWorkbenchCameraPosition(props.workbench.id, cameraLimits);

  return (
    <>
      <WorkbenchViewportControls
        cameraLimits={cameraLimits}
        appendControls={<WorkbenchHelpMenu context="Workbench" />}
        onFitToScreen={onFitToScreen}
      />
      <WorkbenchCameraGestures cameraLimits={cameraLimits} />
      {elements.map((element) => (
        <WorkbenchElement
          focused={focusedElementsId.includes(element.id)}
          singleFocused={focusedElementsId === element.id}
          isEditing={props.editingElementId === element.id}
          setEditingElementId={props.setEditingElementId}
          element={element}
          key={element.id}
          handleAction={handleAction}
          workbenchId={props.workbench.id}
          thumbnailDrawingId={props.workbench.thumbnailDrawingId}
          multiplayerPresences={props.multiplayerPresences}
          elementIsActive={
            !!props.multiplayerPresences.find(
              (presence) => presence.editingElementId === element.id
            )
          }
          isResizing={isResizing && focusedElementsId === element.id}
          isDragging={isDragging && focusedElementsId === element.id}
          setIsDragging={setIsDragging}
          sourceDrawingsThumbnails={sourceDrawingsByMixId[element.id]}
        />
      ))}
      {tool === 'text' && (
        <FloatingMouseText>
          {WORKBENCH_ELEMENT_TEXT_PLACEHOLDER}
        </FloatingMouseText>
      )}
      <ResizerControls
        active={focusedElementsId.split('/').length > 0}
        elements={focusedElements}
        handleAction={handleAction}
        setIsResizing={setIsResizing}
      />
      {multiFocused && (
        <group
          position={[focusedGroupX, focusedGroupY, focusedGroupZ]}
          userData={{
            workbenchObjectType: 'multi-focused-element-container',
            elementX: focusedGroupX,
            elementY: focusedGroupY,
          }}
        >
          <CustomHtml style={{ pointerEvents: 'none' }} position={[0, 0, 0.5]}>
            <WorkbenchElementsExtra
              workbench={props.workbench}
              handleAction={handleAction}
              elements={focusedElements}
              isDragging={isDragging}
            />
          </CustomHtml>
        </group>
      )}
    </>
  );
};

function getFocusedGroupPosition(elements: ClientSideWorkbenchElementData[]) {
  let minX = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;
  let maxHeight = 0;

  // Finds the position and height of the highest element
  for (const element of elements) {
    minX = Math.min(minX, element.x);
    maxX = Math.max(maxX, element.x);
    const { height } = getElementSize(element);
    if (element.y + height > maxY + maxHeight) {
      maxY = element.y;
      maxHeight = height;
    }
  }

  const midX = (minX + maxX) / 2;

  return [midX, maxY + maxHeight / 2, 0];
}
