import { expScale, logScale } from '../Toolbar/mathHelpers';
import { BrushSizePreview } from '../Toolbar/BrushSizePreview';
import {
  WorkbenchStudioTool,
  useWorkbenchStudioToolState,
} from '../studioState';
import {
  BrushSelectionIcon,
  AutoSelectionIcon,
  LassoIcon,
  BezierIcon,
} from '@vizcom/shared-ui-components';
import { NumberInput, Text, RangeInput } from '@vizcom/shared-ui-components';

import styled from 'styled-components';

export const SelectionSettings = ({ closeMenu }: { closeMenu: () => void }) => {
  const {
    tool,
    setTool,
    selectionSettings: {
      brushSettings: { toolSize, setToolSize },
    },
  } = useWorkbenchStudioToolState();

  return (
    <>
      <SelectionTool
        $active={tool === WorkbenchStudioTool.Lasso}
        onClick={() => {
          setTool(WorkbenchStudioTool.Lasso);
          closeMenu();
        }}
      >
        <LassoIcon />
        <div>Lasso Selection</div>
        <div>G</div>
      </SelectionTool>
      <SelectionTool
        $active={tool === WorkbenchStudioTool.BrushSelection}
        onClick={() => {
          setTool(WorkbenchStudioTool.BrushSelection);
        }}
      >
        <BrushSelectionIcon />
        <div>Brush Selection</div>
        <div>G</div>
      </SelectionTool>
      <SelectionTool
        $active={tool === WorkbenchStudioTool.BezierSelection}
        onClick={() => {
          setTool(WorkbenchStudioTool.BezierSelection);
          closeMenu();
        }}
      >
        <BezierIcon />
        <div>Bezier Selection</div>
        <div>G</div>
      </SelectionTool>

      <SelectionTool
        $active={tool === WorkbenchStudioTool.AutoSelection}
        onClick={() => {
          setTool(WorkbenchStudioTool.AutoSelection);
          closeMenu();
        }}
      >
        <AutoSelectionIcon
          style={{
            width: '16px',
            height: '16px',
          }}
        />
        <div>Auto Selection</div>
        <div>G</div>
      </SelectionTool>

      {tool === WorkbenchStudioTool.BrushSelection && (
        <Settings>
          <Text
            style={{
              padding: '12px 12px 6px',
            }}
            type="sh2"
            as="div"
          >
            Brush settings
          </Text>
          <InputGroup>
            <div>
              <InputHeader>
                <Text style={{ flex: 1 }} type="b1">
                  Size
                </Text>
                <NumberInput
                  value={toolSize}
                  min={1}
                  max={250}
                  setValue={setToolSize}
                  unit="px"
                  dragArrows={false}
                />
              </InputHeader>
              <RangeInput
                variant="secondary"
                value={logScale(toolSize, 1, 250)}
                min={0}
                max={1}
                step={0.01}
                onChange={({ target: { value } }) =>
                  setToolSize(Math.round(expScale(+value, 1, 250)))
                }
              />
            </div>
            <BrushSizePreview size={toolSize} opacity={1} />
          </InputGroup>
        </Settings>
      )}
    </>
  );
};

const Tool = styled.button<{ $active?: boolean }>`
  border: 0;
  margin: 0;
  padding: 0;
  background: ${({ $active, theme }) =>
    $active ? theme.surface.e2 : 'transparent'};
  border-radius: 8px;
  cursor: pointer;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ $active, theme }) =>
    $active ? theme.text.default : theme.text.info};
  &:disabled {
    color: ${({ theme }) => theme.text.disabled};
    cursor: default;
  }
`;

const SelectionTool = styled(Tool)`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0.75rem 12px;
  gap: 12px;
  text-align: left;
`;

const InputHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const Settings = styled.div`
  border-top: 1px solid ${({ theme }) => theme.surface.e2};
`;

const InputGroup = styled.div`
  display: flex;
  gap: 6px;
  padding: 12px;
`;
