import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
  ErrorMessage,
  Input,
  PageContainer,
  SectionContainer,
  FormContainer,
  InputGroup,
  AuthLinkContainer,
} from '../styles';
import {
  Text,
  Button,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { useCreateUser } from '@vizcom/shared/data-access/graphql';
import { useRegisterToken } from '../useRegisterToken';
import { paths } from '@vizcom/shared-utils-paths';
import { usePasswordValidation } from '../usePasswordValidation';

type FormData = {
  password: string;
  passwordTwo: string;
};

export const SignUpWithTokenPage = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState } = useForm<FormData>({
    defaultValues: {
      password: '',
      passwordTwo: '',
    },
  });
  const [createUserRes, createUser] = useCreateUser({ noRedirect: true });
  const registerToken = useRegisterToken();

  const { passwordChecklist, valid } = usePasswordValidation(
    watch('password'),
    watch('passwordTwo')
  );

  const onSubmit = async (data: FormData) => {
    if (!registerToken || !registerToken.token) {
      return addToast('Token is required', { type: 'danger' });
    }

    const { password } = data;
    if (!valid) {
      return addToast('Password does not meet all requirements', {
        type: 'danger',
      });
    }

    const res = await createUser({
      password,
      registerToken: registerToken.token,
    });
    if (res.error) {
      return addToast('Error while creating account', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    navigate(paths.onboarding.profile());
  };

  if (!registerToken) {
    return (
      <PageContainer>
        <ErrorMessage>
          This link is broken, please check the email you received.
        </ErrorMessage>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <SectionContainer>
        <Text type="t1" block color={'black'} style={{ textAlign: 'left' }}>
          Welcome to Vizcom
        </Text>
        <Text type="b1" color={'black'} style={{ textAlign: 'left' }}>
          Let's get you set up. Please choose a password.
        </Text>
      </SectionContainer>

      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <Text type="sh2" color={'black'} style={{ textAlign: 'left' }}>
            Email:
          </Text>
          <Input
            type="text"
            name="email"
            placeholder="Email"
            value={registerToken.body?.email || ''}
            disabled
          />
        </InputGroup>
        <InputGroup style={{ marginTop: 24 }}>
          <Text type="sh2" color={'black'} style={{ textAlign: 'left' }}>
            Password:
          </Text>
          <Input
            type="password"
            {...register('password', { required: 'Password is required' })}
            placeholder="Password"
            required
          />
        </InputGroup>
        <InputGroup style={{ marginTop: 24, marginBottom: 8 }}>
          <Text type="sh2" color={'black'} style={{ textAlign: 'left' }}>
            Confirm Password:
          </Text>
          <Input
            type="password"
            {...register('passwordTwo', {
              required: 'Confirm password is required',
            })}
            placeholder="Confirm Password"
            required
          />
        </InputGroup>
        {createUserRes.error && (
          <ErrorMessage>
            {createUserRes.error?.graphQLErrors[0]?.message || 'Unknown error'}
          </ErrorMessage>
        )}
        {formState.isDirty && passwordChecklist}
        <Button
          disabled={!valid || createUserRes.fetching}
          variant="fullBlack"
          style={{ width: '100%', marginTop: 40 }}
        >
          {createUserRes.fetching ? 'Creating account...' : 'Create account'}
        </Button>
      </FormContainer>
      <AuthLinkContainer>
        <Text
          type="b3"
          color={'info'}
          style={{ textAlign: 'center', marginTop: 8 }}
        >
          By clicking "Create account" you acknowledge that you have read and
          agree to Vizcom’s{' '}
          <Text
            type="b3"
            color="primary"
            style={{ textDecoration: 'underline' }}
            as={Link}
            to="https://vizcom.ai/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </Text>{' '}
          and{' '}
          <Text
            type="b3"
            color="primary"
            style={{ textDecoration: 'underline' }}
            as={Link}
            to="https://vizcom.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Text>
        </Text>
      </AuthLinkContainer>
    </PageContainer>
  );
};
