import { useThree } from '@react-three/fiber';
import { CameraLimits } from './utils';
import {
  useKeyPressedRef,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import { useMapControls } from './utils';
import { FullGestureState, useDrag } from '@use-gesture/react';
import { Vector2 } from 'three';
import { logStep } from '@vizcom/shared/js-utils';
import { MapControlsProto } from './mapControls';
import { useEffect } from 'react';

const DRAG_ZOOM_FACTOR = 0.005;

const v2 = new Vector2();
const originV2 = new Vector2(0, 0);

type Gesture = Omit<FullGestureState<'drag'>, 'event'> & {
  event: PointerEvent;
};

export const useWorkbenchPointerGesture = ({
  getCameraLimits,
}: {
  getCameraLimits: () => CameraLimits;
}) => {
  const domElement = useThree((s) => s.events.connected);
  const camera = useThree((s) => s.camera);
  const controls: MapControlsProto = useMapControls();
  const lastGetCameraLimits = useStableCallback(getCameraLimits);

  const handleMoveGesture = (gesture: Gesture) => {
    v2.set(gesture.delta[0], gesture.delta[1]);
    v2.rotateAround(originV2, -camera.rotation.z);
    v2.divideScalar(camera.zoom);

    controls.moveTo({
      x: v2.x * -1,
      y: v2.y,
      skipAnimation: true,
      relative: true,
      limits: lastGetCameraLimits(),
    });
  };

  // drag with click on mousewheel
  useDrag<PointerEvent>(
    (gesture) => {
      gesture.event.preventDefault();
      gesture.event.stopPropagation();
      handleMoveGesture(gesture);
    },
    {
      target: domElement,
      eventOptions: { passive: false, capture: true },
      pointer: {
        buttons: [4],
        keys: false,
      },
    }
  );

  // drag with spacebar + click
  const spaceBarPressed = useKeyPressedRef(' ');
  useDrag<PointerEvent>(
    (gesture) => {
      if (gesture.first && !spaceBarPressed.current) {
        gesture.cancel();
        return;
      }
      if (gesture.canceled) {
        return;
      }
      gesture.event.preventDefault();
      gesture.event.stopPropagation();
      handleMoveGesture(gesture);
    },
    {
      target: domElement,
      eventOptions: { passive: false, capture: true },
    }
  );

  // drag with right click
  useDrag<PointerEvent>(
    (gesture) => {
      if (gesture.first && gesture.event.button !== 2) {
        gesture.cancel();
        return;
      }
      if (gesture.canceled) {
        return;
      }
      handleMoveGesture(gesture);
    },
    {
      target: domElement,
      eventOptions: { passive: false, capture: true },
      pointer: {
        buttons: [2],
        keys: false,
      },
    }
  );

  // Prevent spacebar from acting as a click
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.code === 'Space' &&
        e.target instanceof HTMLElement &&
        e.target.tagName !== 'INPUT' &&
        e.target.tagName !== 'TEXTAREA' &&
        !e.target.isContentEditable
      ) {
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // zoom with z + drag
  const zKeyPressed = useKeyPressedRef('z');
  useDrag<PointerEvent>(
    (gesture) => {
      if (gesture.first && !zKeyPressed.current) {
        return gesture.cancel();
      }

      if (gesture.event.metaKey) {
        return gesture.cancel();
      }

      if (gesture.canceled) {
        return;
      }

      gesture.event.preventDefault();
      gesture.event.stopPropagation();

      if (gesture.axis !== 'x') return;

      const movement = gesture.delta[0];
      const cameraLimits = lastGetCameraLimits();

      controls.moveTo({
        zoom: logStep(
          camera.zoom,
          DRAG_ZOOM_FACTOR * movement,
          cameraLimits.zoomMin,
          cameraLimits.zoomMax
        ),
        skipAnimation: true,
      });
    },
    {
      target: domElement,
      eventOptions: { passive: false, capture: true },
      pointer: {
        buttons: -1,
      },
    }
  );
};
