import {
  useWorkbenchContent,
  useWorkbenchUpdates,
} from '@vizcom/shared/data-access/graphql';
import { useCallback, useMemo, useSyncExternalStore } from 'react';
import { ClientSideWorkbenchElementData } from './clientState';
import {
  useAlertOnUnload,
  useLastValue,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import { SyncQueue } from './syncQueue';
import {
  WorkbenchActionPayload,
  WorkbenchActionTypes,
} from './actions/workbench';
import produce from 'immer';
import { SyncQueueMeta } from './SyncedAction';
import { assertExists, memoizeLastCall } from '@vizcom/shared/js-utils';
import { SyncQueueSynchronizer } from './SyncQueueSynchronizer';

const getRenderedElements = memoizeLastCall(
  (
    elements: ClientSideWorkbenchElementData[],
    queue: ReturnType<
      SyncQueue<
        ClientSideWorkbenchElementData[],
        WorkbenchActionPayload
      >['getQueue']
    >
  ) =>
    produce(elements, (draft) => {
      for (const action of queue) {
        if (typeof action === 'function') {
          action(draft);
        } else {
          const actionType = WorkbenchActionTypes.find(
            ({ type }) => type === action.payload.type
          );
          assertExists(
            actionType,
            `Action type ${action.payload.type} is not registered`
          );
          actionType.optimisticUpdater(action as any, draft);
        }
      }
    })
);

export const useWorkbenchSyncedState = (
  workbenchId: string,
  syncQueueSynchronizer: SyncQueueSynchronizer
) => {
  const { data, fetching, error } = useWorkbenchContent(workbenchId);

  useWorkbenchUpdates(workbenchId);

  const elements = useMemo(
    () => [
      ...(data?.drawings.nodes ?? []),
      ...(data?.img2imgs.nodes ?? []),
      ...(data?.placeholders.nodes ?? []),
      ...(data?.textElements.nodes ?? []),
      ...(data?.compositeScenes.nodes ?? []),
      ...(data?.palettes.nodes ?? []),
      ...(data?.mixElements.nodes ?? []),
      ...(data?.sectionElements.nodes ?? []),
    ],
    [
      data?.drawings,
      data?.img2imgs,
      data?.placeholders,
      data?.textElements,
      data?.compositeScenes,
      data?.palettes,
      data?.mixElements,
      data?.sectionElements,
    ]
  );
  const elementsRef = useLastValue(elements);

  // we use an external store "syncQueue" that handle the pending actions and also makes sure that we're synchronizing them
  // in-order with the server, one at a time
  const syncQueue: SyncQueue<
    ClientSideWorkbenchElementData[],
    WorkbenchActionPayload
  > = useMemo(
    () =>
      new SyncQueue<ClientSideWorkbenchElementData[], WorkbenchActionPayload>(
        syncQueueSynchronizer,
        WorkbenchActionTypes,
        () => getRenderedElements(elementsRef.current, syncQueue.getQueue()),
        workbenchId,
        'workbench'
      ),
    [workbenchId, syncQueueSynchronizer, elementsRef]
  );

  // because the syncQueue state is not handled by react, we have to manually sync up with it here, it will trigger a re-render of the component
  // calling this hook when the syncQueue state changes, but we always reference syncQueue.getQueue() everywhere else to always get the last version
  // of the queue
  useSyncExternalStore(syncQueue.listen, syncQueue.getQueue);
  const hasUnsavedChanges = syncQueue.getQueue.length > 0;
  useAlertOnUnload(hasUnsavedChanges);

  const renderedElements = getRenderedElements(elements, syncQueue.getQueue());

  // This method is called in reaction to user actions
  // it register an action, apply its optimistic update to the workbench state and send the related mutation to the server
  // For some actions (like position), we apply a debounce before sending the GQL mutation to the server
  // and replace the previous position action object with the new one if the GQL mutation was not sent yet
  const handleAction = useCallback(
    (
      payload:
        | WorkbenchActionPayload
        | ((
            elements: ClientSideWorkbenchElementData[]
          ) => WorkbenchActionPayload | undefined),
      meta: SyncQueueMeta = {}
    ) => {
      const finalPayload =
        typeof payload === 'function'
          ? payload(
              getRenderedElements(elementsRef.current, syncQueue.getQueue())
            )
          : payload;
      if (!finalPayload) {
        return;
      }

      syncQueue.push(finalPayload, meta);
    },
    [syncQueue, elementsRef]
  );

  const undoAction = useStableCallback(() => syncQueue.undoAction());
  const redoAction = useStableCallback(() => syncQueue.redoAction());

  return {
    elements: renderedElements,
    handleAction,
    undoAction,
    redoAction,
    canUndo: syncQueue.history.length > 0,
    canRedo: syncQueue.redoHistory.length > 0,
    error,
    fetching,
    hasUnsavedChanges,
  };
};
