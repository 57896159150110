import {
  PageContainer,
  ErrorMessage,
  SectionContainer,
  FormContainer,
  AuthLinkContainer,
  Input,
  InputGroup,
  StyledButtonContainer,
  InputWrapper,
  ResetInputIcon,
} from '../styles';
import {
  useLogin,
  useNextLoginStep,
  useWebConfig,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Loader,
  LoadingLogoInset,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { Link } from 'react-router-dom';
import { LogInWithGoogleButton } from '../LogInWithGoogleButton';
import { useRegisterToken } from '../useRegisterToken';
import { LogInWithAzureButton } from '../LoginWithAzureButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SignupForm } from './signupForm';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';

type FormData = {
  email: string;
  password: string;
};

const SignInPage = () => {
  const { data, fetching, error: webConfigError } = useWebConfig();
  const registerToken = useRegisterToken();
  const [_nextLoginStepRes, getNextLoginStep] = useNextLoginStep();
  const [loginResult, login] = useLogin();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: registerToken?.body?.email || '',
      password: '',
    },
  });

  const email = watch('email');
  const password = watch('password');

  const nextLoginStepRes =
    _nextLoginStepRes.data?.nextLoginStep?.email === email
      ? _nextLoginStepRes.data?.nextLoginStep
      : null;

  const shouldShowPasswordLogin =
    !!password || nextLoginStepRes?.hasAccount === true;

  const handleResetEmail = () => {
    reset({
      email: '',
      password: '',
    });
  };

  const onSubmit: SubmitHandler<FormData> = async (formData) => {
    const { email, password } = formData;

    if (!password) {
      const nextLoginStepResponse = await getNextLoginStep({
        input: { email },
      });
      if (nextLoginStepResponse.error) {
        return addToast('Error while logging in.', {
          type: 'danger',
          secondaryText: formatErrorMessage(nextLoginStepResponse.error),
        });
      }
      if (nextLoginStepResponse.data?.nextLoginStep.redirectUrl) {
        window.location.href =
          nextLoginStepResponse.data.nextLoginStep.redirectUrl;
        return;
      }
      if (nextLoginStepResponse.data?.nextLoginStep.hasAccount) {
        setFocus('password');
      }
      return;
    }

    const loginResponse = await login({ email, password });
    if (loginResponse.error || !loginResponse.data?.login) {
      if (
        (loginResponse.error?.graphQLErrors?.[0]?.extensions?.exception as any)
          ?.rateLimit
      ) {
        const rateLimitInfo = (
          loginResponse.error?.graphQLErrors[0]?.extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        setError('root', {
          message: `You have tried too many invalid password. Please try again in ${(
            rateLimitInfo.resetInMs / 1000
          ).toFixed(0)} seconds.`,
        });
      } else {
        setError('root', {
          message:
            loginResponse.error?.graphQLErrors[0].message ??
            loginResponse.error?.message ??
            'Unknown error',
        });
      }
    }
  };

  if (fetching) {
    return (
      <PageContainer
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </PageContainer>
    );
  }

  if (!data || webConfigError) {
    return (
      <PageContainer>
        <Text type="t1" block color={'black'} style={{ textAlign: 'left' }}>
          Error
        </Text>
        <Text
          type="sh1"
          block
          color={'black'}
          style={{ textAlign: 'left', marginTop: '16px' }}
        >
          There was an error connecting to the server. Please reload the page to
          retry.
        </Text>
        <Text color="danger" style={{ textAlign: 'center', marginTop: '24px' }}>
          {webConfigError?.message ?? 'unknown error'}
        </Text>
      </PageContainer>
    );
  }

  if (nextLoginStepRes?.hasAccount === false) {
    return <SignupForm email={email} onEmailReset={handleResetEmail} />;
  }

  return (
    <PageContainer>
      <SectionContainer>
        <Text type="t1" color={'black'} style={{ textAlign: 'left' }}>
          Welcome
        </Text>
        <Text type="sh1" color={'black'}>
          We’re happy to see you. Please sign in below.
        </Text>
      </SectionContainer>

      {data?.loginWithEmail === false && (
        <>
          <LogInWithGoogleButton />
          <LogInWithAzureButton />
        </>
      )}

      {data?.loginWithEmail && (
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <InputGroup>
            <Text type="sh2" color={'black'}>
              Email:
            </Text>
            <InputWrapper>
              <Input
                type="email"
                {...register('email', { required: 'Email is required' })}
                placeholder="Email"
                readOnly={nextLoginStepRes?.hasAccount === true}
                disabled={nextLoginStepRes?.hasAccount === true}
                autoFocus
              />
              {!!email && <ResetInputIcon onClick={handleResetEmail} />}
            </InputWrapper>
          </InputGroup>

          <InputGroup
            style={{
              marginTop: shouldShowPasswordLogin ? 24 : 0,
              height: shouldShowPasswordLogin ? 'auto' : 0,
              overflow: 'hidden',
            }}
          >
            <Text block type="sh2" color={'black'}>
              Password:
            </Text>
            <Input
              type="password"
              placeholder="Password"
              {...register('password', {
                required: shouldShowPasswordLogin && 'Password required',
              })}
            />
          </InputGroup>

          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
          {errors.password && (
            <ErrorMessage>{errors.password.message}</ErrorMessage>
          )}
          {errors.root && <ErrorMessage>{errors.root.message}</ErrorMessage>}

          <LoadingLogoInset
            backdropColor="rgba(255,255,255,.5)"
            active={_nextLoginStepRes.fetching}
            color="black"
          />

          <StyledButtonContainer>
            <Button
              disabled={loginResult.fetching}
              variant="fullBlack"
              style={{
                width: '100%',
              }}
            >
              {loginResult.fetching ? 'Logging in...' : 'Continue'}
            </Button>
            <LogInWithGoogleButton />
            <LogInWithAzureButton />
          </StyledButtonContainer>

          {shouldShowPasswordLogin && (
            <AuthLinkContainer>
              <Text
                type="b1"
                color="primary"
                style={{
                  textDecoration: 'underline',
                  marginTop: '16px',
                }}
                as={Link}
                to="forgotpassword"
              >
                Forgot password?
              </Text>
            </AuthLinkContainer>
          )}
        </FormContainer>
      )}
    </PageContainer>
  );
};

export default SignInPage;
