import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  useJoinTeam,
  useOrganizationMembers,
  useOrganizationTeams,
} from '@vizcom/shared/data-access/graphql';
import { paths } from '@vizcom/shared-utils-paths';
import {
  Button,
  FullPageDarkLoader,
  Table,
  TableCell,
  TableHeader,
  Text,
  Tooltip,
  addToast,
  formatErrorMessage,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from '../components/SettingsPageLayout';
import { OrganizationTeamRow } from './OrganizationTeamRow';
import { CreateTeamModal } from './CreateTeamModal';
import { TeamSettingsModal } from './TeamSettingsModal';
import { AddMembersModal } from './AddMembersModal';
import { OrganizationInvitedTeamRow } from './OrganizationInvitedTeamRow';
import { useEffect } from 'react';
import { useCanEditOrganization } from '../useCanEditOrganization';
import { getEmailFromInviteOrMember } from '../OrganizationMembers/OrganizationMembers';

type OrganizationTeamsProps =
  | Record<string, never>
  | { createTeamOpen: boolean }
  | { openSettingsForSelectedTeam: boolean }
  | { openAddMembersForSelectedTeam: boolean }
  | { autoJoinSelectedTeam: boolean };

export const OrganizationTeams = (props: OrganizationTeamsProps) => {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const autoJoinSelectedTeam =
    'autoJoinSelectedTeam' in props && props.autoJoinSelectedTeam;

  const { data: organization, loading } = useSelectedOrganization();
  const { data, fetching } = useOrganizationTeams(organization?.id);
  const { data: orgMembers } = useOrganizationMembers(organization?.id);
  const canEdit = useCanEditOrganization(organization?.id);
  const [, joinTeam] = useJoinTeam();

  const mixedInvitesAndMembers = [
    ...(orgMembers?.members.edges || []),
    ...(orgMembers?.organizationInvites?.nodes || []),
  ];

  const autocompleteOptions = mixedInvitesAndMembers.map((member) =>
    getEmailFromInviteOrMember(member)
  );

  useEffect(() => {
    (async () => {
      if (autoJoinSelectedTeam && teamId && organization) {
        const res = await joinTeam({
          input: {
            teamId: teamId,
          },
        });
        if (res.error) {
          if (res.error.graphQLErrors?.[0]?.message.includes('Conflict')) {
            addToast(`You already joined this team`);
          } else {
            addToast('Error while joining team', {
              type: 'danger',
              secondaryText: formatErrorMessage(res.error),
            });

            navigate(paths.settings.organization.teams(organization.id), {
              replace: true,
            });
            return;
          }
        } else {
          addToast(`Successfully joined team`);
        }
        navigate(
          paths.settings.organization.teamSettings(organization.id, teamId),
          { replace: true }
        );
      }
    })();
  }, [autoJoinSelectedTeam, teamId, organization, joinTeam, navigate]);

  if (fetching || loading) {
    return <FullPageDarkLoader />;
  }

  if (!organization) {
    return <Navigate to="/" replace />;
  }

  return (
    <SettingsPageContainer>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <SettingsPageHeader>
          <Text style={{ fontSize: 18, fontWeight: 600 }}>Teams</Text>
          <Text color="info" type="b1">
            Browse and manage teams here
          </Text>
        </SettingsPageHeader>
        <div>
          {canEdit ? (
            <Button
              variant="primary"
              as={Link}
              to={paths.settings.organization.createTeam(organization.id)}
            >
              Create team
            </Button>
          ) : (
            <Tooltip tip="Only workspace admins can create new teams">
              <Button variant="primary" disabled>
                Create team
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <SettingsPageDivider />
      <Table
        cols={3}
        tableStyle={{
          gridTemplateColumns: '[start] minmax(0, 1fr) auto auto auto [end]',
        }}
      >
        <TableHeader>
          <TableCell>Name</TableCell>
          <TableCell>Members</TableCell>
          <TableCell>Visibility</TableCell>
          <TableCell />
        </TableHeader>
        {data?.teams?.nodes
          .filter(
            // remove teams that are in invitedToTeams array to prevent duplication
            (team) => !data?.invitedToTeams.nodes.some((t) => t.id === team.id)
          )
          ?.map((team) => (
            <OrganizationTeamRow
              organization={organization}
              team={team}
              key={team.id}
            />
          ))}
        {data?.invitedToTeams?.nodes?.map((team) => (
          <OrganizationInvitedTeamRow team={team} key={team.id} />
        ))}
      </Table>
      <CreateTeamModal
        organizationId={organization.id}
        autocompleteOptions={autocompleteOptions}
        open={'createTeamOpen' in props ? props.createTeamOpen : false}
        setOpen={(open) => {
          if (!open) {
            navigate(paths.settings.organization.teams(organization.id));
          }
        }}
      />
      <TeamSettingsModal
        teamId={teamId}
        open={
          'openSettingsForSelectedTeam' in props
            ? props.openSettingsForSelectedTeam
            : false
        }
        setOpen={(open) => {
          if (!open) {
            navigate(paths.settings.organization.teams(organization.id));
          }
        }}
      />
      <AddMembersModal
        teamId={teamId}
        autocompleteOptions={autocompleteOptions}
        open={
          'openAddMembersForSelectedTeam' in props
            ? props.openAddMembersForSelectedTeam
            : false
        }
        setOpen={(open) => {
          if (!open) {
            navigate(paths.settings.organization.teams(organization.id));
          }
        }}
      />
    </SettingsPageContainer>
  );
};
