import {
  Button,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Tabs,
  Tab,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import { StudioExportButtons } from '../../studio/Toolbar/export/StudioExportButtons';
import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import { LinkSharingTabContent } from './LinkSharingTab';

export const PublicFileSharingButton = ({
  workbenchId,
  drawing,
  triggerPaywallModal,
}: {
  workbenchId: string;
  drawing?: Drawing2dStudio;
  triggerPaywallModal?: () => void;
}) => {
  return (
    <RichTooltip trigger="click">
      <RichTooltipTrigger>
        <ExportButtonStyled variant="primary">Share</ExportButtonStyled>
      </RichTooltipTrigger>

      <RichTooltipContent
        hideArrow
        style={{ overflow: 'hidden', padding: 16, borderRadius: 12 }}
      >
        <Tabs>
          {drawing && triggerPaywallModal && (
            <Tab key="export" title="Export">
              <ExportFloatingContainer>
                <StudioExportButtons
                  drawing={drawing}
                  triggerPaywallModal={triggerPaywallModal}
                />
              </ExportFloatingContainer>
            </Tab>
          )}

          <Tab key="share" title="Share">
            <LinkSharingTabContent workbenchId={workbenchId} />
          </Tab>
        </Tabs>
      </RichTooltipContent>
    </RichTooltip>
  );
};

const ExportButtonStyled = styled(Button)`
  width: 64px;
  height: 32px;
  font-size: 12px;
  padding: 10px 12px;
`;

export const ExportFloatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 350px;
  background: ${(p) => p.theme.surface.e1};
`;
