import { Leva, button, folder, useControls } from 'leva';
import { useIsDebugMode } from './lib/useIsDebugMode';

export const WorkbenchStudioDebug = () => {
  const debugMode = useIsDebugMode();

  const data = useControls({
    'Magic Prompt': {
      value: false,
      onChange: (value) => {
        localStorage.setItem('vizcom:2dstudio:magicPrompt', value);
      },
    },
    'ComfyUI Custom Workflow': folder(
      {
        'Upload workflow': button(() => {
          const res = prompt('Custom workflow');
          if (!res) {
            return;
          }
          try {
            JSON.parse(res);
          } catch (e) {
            alert(`Could not parse the workflow: ${e}`);
          }
          localStorage.setItem('vizcom:2dstudio:customWorkflow', res);
        }),
        'Reset workflow': button(() => {
          localStorage.removeItem('vizcom:2dstudio:customWorkflow');
        }),
      },
      {
        color: 'blue',
        order: 2,
      }
    ),
  });

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 10000001,
        pointerEvents: 'all',
      }}
    >
      <Leva hidden={!debugMode} />
    </div>
  );
};
