import { ClientSideWorkbenchElementData } from '../../clientState';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  InsertImagesToPaletteMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { elementById } from '../../utils';
import { SourceImageInput } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { RemoveImagesFromPaletteAction } from './removeImagesFromPaletteAction';
import { PaletteEventName } from '@vizcom/shared/data-shape';
import { assertExists } from '@vizcom/shared/js-utils';

export const InsertImagesToPaletteAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'insertImagesToPalette';
    id: string;
    sourceImages: SourceImageInput[];
  },
  {
    status?: string;
  }
> = {
  type: 'insertImagesToPalette',
  optimisticUpdater: ({ payload, meta }, elements) => {
    assertExists(meta.custom);
    if (meta.custom.status !== 'idle') return;

    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette') return;

    payload.sourceImages.forEach((sourceImage) => {
      if (
        element.sourceImages.nodes.some((node) => node.id === sourceImage.id)
      ) {
        return;
      }

      element.sourceImages.nodes.push({
        __typename: 'WorkbenchElementPaletteSourceImage',
        id: sourceImage.id,
        imagePath: sourceImage.image,
        workbenchElementPaletteId: payload.id,
      });
    });
  },
  remoteUpdater: async ({ payload, meta }, workbenchId) => {
    assertExists(meta.custom);
    if (meta.custom.status !== 'idle') return;

    const res = await urqlClient.mutation(InsertImagesToPaletteMutation, {
      input: {
        id: payload.id,
        sourceImages: payload.sourceImages.map((data) => {
          return {
            id: data.id,
            image: data.image,
          };
        }),
        workbenchId,
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while creating palette, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Insert images to palette', {
      paletteId: payload.id,
      imagesCount: payload.sourceImages.length,
    });
    publishTrackingEvent({
      type: PaletteEventName.ADD_IMAGES,
      data: {
        paletteId: payload.id,
        imagesCount: payload.sourceImages.length,
      },
    });
  },
  metaConstructor: (payload, elements) => {
    const element = elementById(elements, payload.id);

    return {
      custom: {
        status:
          element?.__typename === 'WorkbenchElementPalette'
            ? element.status
            : '',
      },
    };
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<
      typeof RemoveImagesFromPaletteAction
    > = {
      type: 'removeImagesFromPalette',
      id: payload.id,
      imageIds: payload.sourceImages.map((image) => image.id),
    };
    return undoPayload;
  },
};
