import { v4 as uuidv4 } from 'uuid';
import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../../../constants';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { assertExists, filterExists } from '@vizcom/shared/js-utils';
import {
  addToast,
  browseForFiles,
  downloadFile,
  FileToZip,
  formatErrorMessage,
  imageToBlob,
  resizeImageToPixelCount,
  zipFiles,
} from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { trackEvent } from '@vizcom/shared-data-access-analytics';

export const handleDownloadPaletteImages = async (
  element: ClientSideWorkbenchElementPalette
) => {
  if (!element.sourceImages?.nodes || element.sourceImages.nodes.length === 0) {
    return addToast('No images to export', { type: 'danger' });
  }
  try {
    const files: FileToZip[] = await Promise.all(
      element.sourceImages.nodes
        .filter((image) => image.imagePath)
        .map(async (image, index) => {
          return {
            name: `${element.name}-${index}.png`,
            data: await imageToBlob(image.imagePath),
          };
        })
    );

    const zipFile = await zipFiles(files);

    downloadFile(zipFile, element.name, 'zip');

    trackEvent('Image Export', {
      type: 'exportPaletteImages',
    });
  } catch {
    return addToast('An error occurred while downloading palette images', {
      type: 'danger',
    });
  }
};

export const handleAddImagesToPalette = async (
  paletteId: string,
  numExistingImages: number,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction']
) => {
  const files = await browseForFiles({
    accept: 'image/*',
  });

  const filesData = (
    await Promise.all(
      files.map(async (file) => {
        let resized;
        try {
          resized = await resizeImageToPixelCount(
            file,
            WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
          );
        } catch (e: any) {
          addToast(
            `Error while importing file "${file.name}", please check it's a valid image.`,
            {
              type: 'danger',
              secondaryText: formatErrorMessage(e),
            }
          );
        }
        assertExists(resized);
        return { ...resized, name: file.name };
      })
    )
  ).filter(filterExists);

  if (!filesData.length) {
    return;
  }

  const sourceImages = limitSourceImages(filesData, numExistingImages);

  if (!sourceImages.length) {
    return;
  }

  handleAction({
    type: 'insertImagesToPalette',
    id: paletteId,
    sourceImages: sourceImages.map((file) => ({
      ...file,
      id: uuidv4(),
    })),
  });
};

export const getNewPromptFromTags = (tags: string[], prompt: string) => {
  const missingTags = tags.filter((tag) => !prompt.includes(tag));
  return [prompt, ...missingTags].filter((tag) => tag !== '').join(', ');
};

const PALETTE_SOURCE_IMAGE_LIMIT = 30;
export const limitSourceImages = <T,>(
  sourceImages: T[],
  numExistingImages: number
) => {
  const limit = PALETTE_SOURCE_IMAGE_LIMIT - numExistingImages;
  if (sourceImages.length <= limit) {
    return sourceImages;
  }
  addToast(
    `You can only add up to ${PALETTE_SOURCE_IMAGE_LIMIT} images to a palette, the rest were ignored.`
  );
  return sourceImages.slice(0, Math.max(limit, 0));
};
