import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { useEffect, useState } from 'react';
import {
  InvisibleIcon,
  RegenerateIcon,
  VisibleIcon,
  DownloadIcon,
  LoadingLogoInset,
  downloadFile,
  CloseIcon,
  Button,
  useIsPro,
  useKeyboardShortcut,
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
  TooltipNotification,
  TooltipNotificationName,
  useTooltipNotificationState,
  bottomScreenTunnel,
} from '@vizcom/shared-ui-components';
import {
  Actions,
  ToolbarContainer,
  Output,
  Outputs,
  HorizontalDivider,
  Overlay,
  LoaderIcon,
} from '../style';
import {
  useCancelPrompt,
  PromptData,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { useTheme } from 'styled-components';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { v4 as uuid } from 'uuid';
import { genTopOrderKey } from '@vizcom/shared/js-utils';
import { InferenceEventName } from '@vizcom/shared/data-shape';

type Props = {
  drawing: Drawing2dStudio;
  selectedPrompt?: PromptData | null;
  previewHidden: boolean;
  selectedPromptId: string | undefined;
  selectedOutputId: string | undefined;
  setSelectedOutputId: (id: string | undefined) => void;
  setSelectedPromptId: (id: string | undefined) => void;
  triggerPaywallModal: () => void;
  setPreviewHidden: (hidden: boolean) => void;
  trigger: () => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
};

export const InferenceTray = ({
  drawing,
  selectedPrompt,
  previewHidden,
  selectedPromptId,
  selectedOutputId,
  setSelectedOutputId,
  setSelectedPromptId,
  setPreviewHidden,
  trigger,
  handleAction,
  triggerPaywallModal,
}: Props) => {
  const isPro = useIsPro(drawing.id);
  const theme = useTheme();
  const [, cancelPrompt] = useCancelPrompt();
  const [addedPromptIds, setAddedPromptIds] = useState<string[]>([]);
  const [order, setOrder] = useState<string[]>([]);
  const [addedLayerIds, setAddedLayerIds] = useState<string[]>([]);

  const selectedPromptOutputs =
    selectedPrompt?.id === selectedPromptId
      ? selectedPrompt?.outputs.nodes.sort((a, b) => {
          const indexA = order.indexOf(a.id);
          const indexB = order.indexOf(b.id);
          const posA = indexA === -1 ? order.length : indexA;
          const posB = indexB === -1 ? order.length : indexB;

          return posA - posB;
        })
      : undefined;

  const selectedOutput = selectedPromptOutputs?.find(
    (p) => p.id === selectedOutputId
  );

  useKeyboardShortcut('ArrowLeft', () => {
    if (selectedOutputId && selectedPromptOutputs) {
      const index = selectedPromptOutputs.findIndex(
        (p) => p.id === selectedOutputId
      );
      if (index !== undefined && index > 0) {
        setSelectedOutputId(selectedPromptOutputs[index - 1].id);
      }
    }
  });
  useKeyboardShortcut('ArrowRight', () => {
    if (selectedOutputId && selectedPromptOutputs) {
      const index = selectedPromptOutputs?.findIndex(
        (p) => p.id === selectedOutputId
      );
      if (index !== undefined && index < selectedPromptOutputs.length - 1) {
        setSelectedOutputId(selectedPromptOutputs[index + 1].id);
      }
    }
  });

  // selectedPromptOutputs are created in a one order but the order the images come back
  // is arbitrary. We reorder them and present them to the user based on the order they
  // get the images back.
  useEffect(() => {
    if (selectedPromptOutputs) {
      const newOrder = [...order];
      selectedPromptOutputs.forEach((p) => {
        if (p.imagePath && !newOrder.includes(p.id)) {
          newOrder.push(p.id);
        }
      });
      setOrder(newOrder);

      if (newOrder.length && !selectedOutputId) {
        setSelectedOutputId(newOrder[0]);
      }
    } else {
      setOrder([]);
    }
  }, [selectedPromptOutputs]);

  useEffect(() => {
    setPreviewHidden(false);
  }, [selectedOutputId]);

  useEffect(() => {
    setAddedPromptIds([]);
    setSelectedOutputId(undefined);
  }, [selectedPromptId]);

  const handleAddAsLayer = async () => {
    if (!selectedOutput || !selectedOutput.imagePath) {
      return;
    }

    setAddedPromptIds((prev) => [...prev, selectedOutput.id]);

    const id = uuid();

    handleAction?.({
      type: 'addLayer',
      layer: {
        id,
        name:
          selectedPrompt?.text || `Layer ${drawing.layers.nodes.length + 1}`,
        visible: false,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        image: selectedOutput.imagePath,
        orderKey: genTopOrderKey(drawing.layers.nodes),
      },
    });

    setAddedLayerIds((prev) => [...prev, id]);

    trackEvent('Image Added to Canvas', {
      publicPalette: selectedPrompt?.publicPaletteId,
    });

    publishTrackingEvent({
      type: InferenceEventName.ADD_TO_LAYER,
      data: {
        publicPaletteId: selectedPrompt?.publicPaletteId || undefined,
      },
    });
  };

  const handleCancel = () => {
    if (!selectedPromptId) {
      return;
    }
    if (selectedPromptId !== 'loading') {
      // user cancelled before receiving the inference id, cannot cancel it
      cancelPrompt({
        input: {
          promptId: selectedPromptId,
        },
      });
    }

    setSelectedPromptId(undefined);
    setSelectedOutputId(undefined);

    useTooltipNotificationState
      .getState()
      .setDisplay(TooltipNotificationName.ExitInferenceTray, false);

    if (addedLayerIds.length) {
      addedLayerIds.forEach((id) => {
        handleAction?.({
          type: 'updateLayer',
          id: id,
          data: {
            visible: true,
          },
        });
      });

      handleAction?.({
        type: 'updateBulkLayers',
        layerUpdates: drawing.layers.nodes
          .filter((l) => addedLayerIds.includes(l.id))
          .map((l) => ({
            id: l.id,
            visible: true,
          })),
      });
      setAddedLayerIds([]);
    }

    trackEvent('Cancel Inference');
    publishTrackingEvent({
      type: InferenceEventName.CANCEL_INFERENCE,
      data: {
        promptId: selectedPromptId,
      },
    });
  };

  const handleSelect = (id: string) => {
    setSelectedOutputId(id);
  };

  const handleToggle = () => {
    setPreviewHidden(!previewHidden);
  };

  const handleDownload = () => {
    const output = selectedPromptOutputs?.find(
      (p) => p.id === selectedOutputId
    );

    if (!output || !output.imagePath) {
      return;
    }

    const name = drawing?.workbench?.name || 'unnamed file';
    downloadFile(output.imagePath, name);
    trackEvent('Image Export', { type: 'inferenceTrayDownload' });
    publishTrackingEvent({
      type: InferenceEventName.EXPORT_IMAGE,
      data: {
        promptId: selectedPromptId,
      },
    });
  };

  if (!selectedPromptId) {
    return null;
  }

  const loading =
    selectedPromptId === 'loading' ||
    !selectedOutput ||
    !selectedPromptOutputs?.some((p) => p.imagePath);

  return (
    <>
      {loading && (
        <Overlay>
          <LoaderIcon />
        </Overlay>
      )}
      <bottomScreenTunnel.In>
        <ToolbarContainer>
          <Actions>
            {selectedPrompt && (
              <>
                <RichTooltip trigger="hover" padding={11}>
                  <RichTooltipTrigger>
                    <Button
                      variant="transparent"
                      size="iconSquared"
                      disabled={loading}
                      onClick={handleToggle}
                    >
                      {!previewHidden ? (
                        <VisibleIcon color={theme.icon.default} />
                      ) : (
                        <InvisibleIcon color={theme.icon.default} />
                      )}
                    </Button>
                  </RichTooltipTrigger>
                  <RichTooltipContent style={{ color: theme.white }}>
                    <div>
                      {previewHidden
                        ? 'Show generated image'
                        : 'Show original canvas'}
                    </div>
                  </RichTooltipContent>
                </RichTooltip>

                <RichTooltip trigger="hover" padding={11}>
                  <RichTooltipTrigger>
                    <Button
                      variant="transparent"
                      size="iconSquared"
                      disabled={loading}
                      onClick={trigger}
                    >
                      <RegenerateIcon color={theme.icon.default} />
                    </Button>
                  </RichTooltipTrigger>
                  <RichTooltipContent style={{ color: theme.white }}>
                    <div>Regenerate</div>
                  </RichTooltipContent>
                </RichTooltip>

                <Outputs
                  $hasOutputs={
                    !!selectedPromptOutputs && selectedPromptOutputs.length > 1
                  }
                >
                  {selectedPromptOutputs &&
                    selectedPromptOutputs.length > 1 && (
                      <>
                        <HorizontalDivider />
                        {selectedPromptOutputs.map(
                          ({ id, imagePath, failureReason }) => (
                            <Output
                              key={id}
                              $aspect={drawing.width / drawing.height}
                              $current={selectedOutputId === id}
                              onClick={() => handleSelect(id)}
                            >
                              <LoadingLogoInset
                                active={!imagePath && !failureReason}
                                style={{ width: 32, height: 32 }}
                              />
                              {imagePath && (
                                <img crossOrigin="anonymous" src={imagePath} />
                              )}
                            </Output>
                          )
                        )}
                      </>
                    )}
                </Outputs>

                <Outputs
                  $hasOutputs={!!selectedPromptOutputs?.length && !isPro}
                >
                  {selectedPromptOutputs &&
                    selectedPromptOutputs.length === 1 &&
                    !isPro && (
                      <>
                        <Output
                          $aspect={drawing.width / drawing.height}
                          $current={true}
                        >
                          <LoadingLogoInset
                            active={
                              !selectedPromptOutputs[0].imagePath &&
                              !selectedPromptOutputs[0].failureReason
                            }
                            style={{ width: 32, height: 32 }}
                          />
                          {selectedPromptOutputs[0].imagePath && (
                            <img
                              crossOrigin="anonymous"
                              src={selectedPromptOutputs[0].imagePath}
                            />
                          )}
                        </Output>
                        {Array.from({ length: 3 }).map((_, i) => (
                          <Output
                            key={i}
                            $aspect={drawing.width / drawing.height}
                            $current={false}
                            $blur={true}
                            onClick={() => triggerPaywallModal()}
                          >
                            {selectedPromptOutputs[0].imagePath && (
                              <img
                                crossOrigin="anonymous"
                                src={selectedPromptOutputs[0].imagePath}
                              />
                            )}
                          </Output>
                        ))}
                      </>
                    )}
                </Outputs>

                <HorizontalDivider />

                <RichTooltip trigger="hover" padding={11}>
                  <RichTooltipTrigger>
                    <Button
                      disabled={loading}
                      onClick={handleDownload}
                      size="iconSquared"
                      variant="transparent"
                    >
                      <DownloadIcon />
                    </Button>
                  </RichTooltipTrigger>
                  <RichTooltipContent style={{ color: theme.white }}>
                    <div>Download</div>
                  </RichTooltipContent>
                </RichTooltip>
              </>
            )}
            <Button
              variant="primary"
              size="M"
              disabled={
                loading ||
                !selectedOutputId ||
                addedPromptIds.includes(selectedOutputId)
              }
              style={{
                height: '32px',
                width: '64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleAddAsLayer}
            >
              {selectedOutputId && addedPromptIds.includes(selectedOutputId)
                ? 'Added'
                : 'Add'}
            </Button>
            <HorizontalDivider />

            <TooltipNotification
              name={TooltipNotificationName.ExitInferenceTray}
              autoTriggerAfter={30_000}
              message="Click to exit generation preview"
            >
              <Button
                variant="secondary"
                size="iconSquared"
                onClick={handleCancel}
              >
                <CloseIcon />
              </Button>
            </TooltipNotification>
          </Actions>
        </ToolbarContainer>
      </bottomScreenTunnel.In>
    </>
  );
};
