import { PropsWithChildren } from 'react';
import { useLayersCompositor } from './context';
import { createPortal } from '@react-three/fiber';
import { BlendMode } from '../../types';
import { CompositedLayerContentUserData } from './types';

export const LayerContent = ({
  children,
  id,
  opacity,
  blendMode,
  visible,
}: PropsWithChildren<{
  id: string;
  opacity: number;
  blendMode: string;
  visible: boolean;
}>) => {
  const layerCompositor = useLayersCompositor();

  const userData: CompositedLayerContentUserData = {
    type: 'vizcom:compositedLayerContent',
    id,
    opacity,
    blendMode: blendMode as BlendMode,
    visible,
  };

  return createPortal(
    <group userData={userData}>{children}</group>,
    layerCompositor.scene
  );
};
