import { useTheme } from 'styled-components';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { ToolbarContainer, Toolbar, ToolbarDivider } from './style';
import {
  Button,
  CloseIcon,
  Import3DModelIcon,
  Tooltip,
} from '@vizcom/shared-ui-components';
import { Icon } from '../ui/Icon/Icon';

import CheckIcon from '../../../assets/icons/check.svg';
import CameraIcon from '../../../assets/icons/camera.svg';

const GeneralToolbar = ({ onExit }: { onExit: () => void }) => {
  const { uploadInputRef } = useCompositeSceneEditorContext();
  const theme = useTheme();

  return (
    <>
      <Tooltip tip="Upload model (.fbx, .gltf, .glb, .stl, .obj)">
        <Button
          variant="transparent"
          size="iconSquared"
          onClick={() => {
            if (!uploadInputRef) {
              return;
            }

            uploadInputRef.click();
          }}
        >
          <Import3DModelIcon style={{ color: theme.icon.default }} />
        </Button>
      </Tooltip>
      <ToolbarDivider />
      <Button onClick={onExit} variant="secondary" size="iconSquared">
        <CloseIcon />
      </Button>
    </>
  );
};

const ActiveCameraToolbar = () => {
  const theme = useTheme();
  const { setActiveCamera } = useCompositeSceneEditorContext();

  return (
    <>
      <Button variant="transparent" size="iconSquared" disabled>
        <Icon icon={CameraIcon} color={theme.text.secondary} size={24} />
      </Button>
      <Button
        onClick={() => {
          setActiveCamera(null);
        }}
        variant="primary"
        size="iconSquared"
        style={{
          backgroundColor: theme.tertiary.default,
        }}
      >
        <Icon icon={CheckIcon} color={theme.secondary.default} size={18} />
      </Button>
    </>
  );
};

export const CompositeSceneToolbar = ({ onExit }: { onExit: () => void }) => {
  const { activeCamera } = useCompositeSceneEditorContext();

  return (
    <ToolbarContainer>
      <Toolbar>
        {activeCamera ? (
          <ActiveCameraToolbar />
        ) : (
          <GeneralToolbar onExit={onExit} />
        )}
      </Toolbar>
    </ToolbarContainer>
  );
};
