import { Box3, Object3D, Vector3 } from 'three';

export const getBoundingBoxSize = (object?: Object3D) => {
  const box = new Box3();
  if (object) {
    box.setFromObject(object);
  }
  const size = new Vector3();
  box.getSize(size);
  return size;
};
