import {
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useRef,
  useState,
} from 'react';
import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import styled from 'styled-components';

const ARROW_SIZE = 10;

const RichTooltipArrow = styled(FloatingArrow)`
  fill: ${(p) => p.theme.surface.e0};
`;

export const ToolbarMenu = forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & {
    enabled: boolean;
    menu?: ReactNode | ((props: { closeMenu: () => void }) => ReactNode);
    onOpenChange?: (open: boolean) => void;
  }
>(({ children, enabled, menu, onOpenChange, ...parentProps }, parentRef) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef(null);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    if (onOpenChange) {
      onOpenChange(newOpen);
    }
  };

  const {
    refs: { setFloating, setReference },
    context,
  } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: ARROW_SIZE + 12, crossAxis: 0 }),
      flip({
        crossAxis: true,
        fallbackAxisSideDirection: 'start',
        padding: ARROW_SIZE,
      }),
      shift({ padding: ARROW_SIZE }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled }),
    useDismiss(context),
    useRole(context, { role: 'menu' }),
  ]);

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: '0',
      transform: 'scale(0.9)',
    },
    duration: 200,
  });

  const ref = useMergeRefs([setReference, parentRef]);

  if (!isValidElement(children)) {
    throw new Error(
      'ToolbarMenu children is not a valid element, it should only contain a single element'
    );
  }

  const menuWithProps =
    typeof menu === 'function'
      ? menu({ closeMenu: () => setOpen(false) })
      : menu;

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({
          ref,
          ...children.props,
          ...parentProps,
        })
      )}
      <FloatingPortal>
        {open && isMounted && (
          <div
            style={{ ...context.floatingStyles, zIndex: 11 }}
            {...getFloatingProps()}
            ref={setFloating}
          >
            <Container
              style={{
                ...styles,
              }}
            >
              <RichTooltipArrow
                ref={arrowRef}
                context={context}
                width={ARROW_SIZE * 1.5}
                height={ARROW_SIZE}
              />
              {menuWithProps}
            </Container>
          </div>
        )}
      </FloatingPortal>
    </>
  );
});

const Container = styled.div`
  background-color: ${(p) => p.theme.surface.e0};
  padding: 14px;
  border-radius: ${(p) => p.theme.borderRadius.default};
`;
