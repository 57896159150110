import React, { useState, useEffect, useRef } from 'react';
import {
  RangeInput,
  CloseIcon,
  FileDropper,
  addToast,
  resizeImageToPixelCount,
  TrashIcon,
  Text,
  styledScrollbarDark,
  useKeyboardShortcut,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import styled, { useTheme } from 'styled-components';
import { useCreateDrawingStyleReference } from '@vizcom/shared/data-access/graphql';
import { useIsTouchScreen } from '../../../lib/touchState';

type StyleReferenceImageModalProps = {
  onExitClick: () => void;
  styleReferenceStrength: number;
  setStyleReferenceStrength: (value: number) => void;
  references: {
    id: string;
    imagePath: string;
    imageName?: string | null;
  }[];
  handleSelectReference: (id: string) => void;
  onDeleteClick: (e: React.MouseEvent, id: string) => void;
  workbenchId: string;
  setStyleReferenceId: (id: string | undefined) => void;
  activeReference:
    | {
        id: string;
        imagePath: string;
        imageName?: string | null;
      }
    | undefined;
};

export const StyleReferenceImageModal = ({
  onExitClick,
  styleReferenceStrength,
  setStyleReferenceStrength,
  references,
  handleSelectReference,
  onDeleteClick,
  workbenchId,
  setStyleReferenceId,
  activeReference,
}: StyleReferenceImageModalProps) => {
  const [, createDrawingStyleReference] = useCreateDrawingStyleReference();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const theme = useTheme();
  const [focusedIndex, setFocusedIndex] = useState<number>(
    references.findIndex((ref) => ref.id === activeReference?.id) || 0
  );
  const ref = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const totalItems = references.length;
  const columns = 7;

  const scrollToFocusedItem = (index: number) => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const focusedItem = scrollContainer.children[index + 1] as HTMLElement; // +1 to account for PlusButton
      if (focusedItem) {
        focusedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  };

  const updateFocusAndSelectReference = (newIndex: number) => {
    setFocusedIndex(newIndex);
    handleSelectReference(references[newIndex].id);
    scrollToFocusedItem(newIndex);
  };

  const selectNextItem = () => {
    const newIndex = (focusedIndex + 1) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  const selectPreviousItem = () => {
    const newIndex = (focusedIndex - 1 + totalItems) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  const selectNextRowItem = () => {
    const newIndex = (focusedIndex + columns) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  const selectPreviousRowItem = () => {
    const newIndex = (focusedIndex - columns + totalItems) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  useKeyboardShortcut('ArrowRight', selectNextItem);
  useKeyboardShortcut('ArrowLeft', selectPreviousItem);
  useKeyboardShortcut('ArrowDown', selectNextRowItem);
  useKeyboardShortcut('ArrowUp', selectPreviousRowItem);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      uploadStyleReference(file);
    }
  };

  const handlePasteFile = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const items = await navigator.clipboard.read();
      const data = [...items].filter((item) =>
        item.types.some((type) => type.includes('image'))
      );

      if (!data || data.length === 0) {
        addToast('No images found in clipboard', {
          type: 'danger',
        });
        return;
      }

      data.forEach(async (item) => {
        const blob = await item.getType(item.types[0]);
        uploadStyleReference(blob as File);
      });
    } catch (err) {
      addToast('Failed to read clipboard contents', {
        type: 'danger',
      });
      return;
    }
  };

  const uploadStyleReference = async (file: File) => {
    const name = file.name;
    let resizedImage;
    try {
      resizedImage = await resizeImageToPixelCount(
        file,
        512 * 512,
        'image/webp'
      );
    } catch (e) {
      return addToast(
        'This image is not supported, please try with another image',
        {
          type: 'danger',
        }
      );
    }
    const res = await createDrawingStyleReference({
      input: {
        styleReference: {
          workbenchId,
          imageName: name,
          imagePath: resizedImage.image,
        },
      },
    });
    if (res.error || !res.data?.createStyleReference?.styleReference) {
      return addToast(`Error while uploading image`, {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
    const newReferenceId = res.data.createStyleReference.styleReference.id;
    setStyleReferenceId(newReferenceId);
    handleSelectReference(newReferenceId);
    setFocusedIndex(references.length);
    scrollToFocusedItem(references.length);
  };

  useEffect(() => {
    setFocusedIndex(
      references.findIndex((ref) => ref.id === activeReference?.id) || 0
    );
  }, [activeReference?.id, references]);

  return (
    <PopoverContainer
      ref={ref}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <PopoverHeader>
        <Text
          style={{
            color: theme.text.default,
          }}
        >
          Reference Image
        </Text>
        <CloseIconStyled onClick={onExitClick} />
      </PopoverHeader>
      <PopoverContent>
        {activeReference?.imagePath ? (
          <PopoverImageContainer isDragOver={isDraggingOver}>
            <PopoverImage
              src={activeReference.imagePath}
              alt={activeReference.imageName || 'No name'}
            />
            <Overlay
              className="overlay"
              as={FileDropper}
              browseForFileOnClick
              onFileSelected={uploadStyleReference}
            >
              <UploadButton>Upload</UploadButton>
              <UploadButton onClick={handlePasteFile}>Paste Image</UploadButton>
            </Overlay>
            <TrashIconStyled
              onClick={(e) => onDeleteClick(e, activeReference.id)}
            />
          </PopoverImageContainer>
        ) : (
          <EmptyStateContainer
            as={FileDropper}
            browseForFileOnClick
            onFileSelected={uploadStyleReference}
            isDraggingOver={isDraggingOver}
          >
            <UploadContainer>
              <UploadButton>Upload</UploadButton>
              <UploadButton onClick={handlePasteFile}>Paste Image</UploadButton>
            </UploadContainer>
          </EmptyStateContainer>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '16px',
          }}
        >
          <Text
            style={{
              color: theme.text.info,
            }}
          >
            Strength
          </Text>
          <RangeInput
            value={styleReferenceStrength * 100}
            min={0}
            max={100}
            step={1}
            disabled={!activeReference?.imagePath}
            onChange={(e) =>
              setStyleReferenceStrength(
                (Math.round(Number(e.target.value) / 5) * 5) / 100
              )
            }
          />
          <Text
            style={{
              color: theme.text.info,
            }}
          >
            {(styleReferenceStrength * 100).toFixed(0)}%
          </Text>
        </div>
        <ReferenceImageContainer ref={scrollContainerRef}>
          {references.length > 0 && (
            <PlusButton
              as={FileDropper}
              browseForFileOnClick
              onFileSelected={uploadStyleReference}
            >
              +
            </PlusButton>
          )}
          {references.map((reference, index) => (
            <ImageContainer
              key={reference.id}
              active={
                activeReference?.id === reference.id || focusedIndex === index
              }
              onClick={(e) => {
                e.stopPropagation();
                handleSelectReference(reference.id);
                setFocusedIndex(index);
              }}
            >
              <ImageThumbnail
                src={reference.imagePath}
                alt={reference.imageName || 'Style reference'}
              />
            </ImageContainer>
          ))}
        </ReferenceImageContainer>
      </PopoverContent>
    </PopoverContainer>
  );
};

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 252px;
  padding: 16px;
  background-color: ${(p) => p.theme.surface.e0};
  color: #fff;
  border-radius: 16px;
  max-height: 358px;
  overflow: hidden;
`;

const PopoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const PopoverContent = styled.div`
  height: auto;
  border-radius: 8px;
`;

const PopoverImageContainer = styled.div<{ isDragOver: boolean }>`
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 16px;
  border-radius: 8px;
  position: relative;
  background-color: ${(p) => p.theme.surface.e1};

  border: ${(p) =>
    p.isDragOver ? `2px dashed ${p.theme.primary.default}` : 'none'};

  &:hover .overlay {
    display: flex;
  }
`;

const PopoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
`;

const Overlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 1px;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: ${(p) => p.theme.text.default};
`;

const TrashIconStyled = styled(TrashIcon)`
  width: 24px;
  height: 24px;
  fill: ${(p) => p.theme.text.button};
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  background-color: ${(p) => p.theme.surface.e0};
  border-radius: 4px;
  padding: 4px;
`;

const ReferenceImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  max-height: calc(
    26px * 3 + 6px * 2
  ); /* 3 rows of 26px height with 2 gaps of 6px */
  overflow-y: auto;
  width: calc(
    26px * 7 + 6px * 6 + 12px
  ); /* 7 columns of 26px width with 6 gaps of 6px */
  ${styledScrollbarDark};
`;

const ImageContainer = styled.div<{ active: boolean }>`
  position: relative;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.primary.default : props.theme.surface.e0};
  cursor: pointer;
  &:hover {
    border-color: ${(props) => props.theme.secondary.hover};
  }
  border-radius: 4px;
  width: 36px;
  height: 36px;
  flex: 0 0 auto;
`;

const ImageThumbnail = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.surface.e0};
  object-fit: cover;
`;

const EmptyStateContainer = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 221px;
  height: 140px;
  background-color: ${(p) => p.theme.surface.e1};
  border-radius: 16px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 16px;
  border: 1px solid ${(p) => p.theme.primary.default};

  &,
  & {
    border: 2px solid ${(p) => p.theme.primary.default};
  }
`;

const UploadButton = styled.div`
  background-color: ${(p) => p.theme.surface.e0};
  color: #fff;
  padding: 8px 8px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 2px 0px ${(p) => p.theme.surface.e2};

  &:nth-of-type(1):not(:last-of-type) {
    border-radius: 8px 0px 0px 8px;
  }

  &:nth-of-type(2) {
    border-radius: 0px 8px 8px 0px;
  }
`;

const UploadContainer = styled.div`
  display: flex;
  gap: 1px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const PlusButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: ${(p) => p.theme.surface.e1};
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  color: ${(p) => p.theme.text.info};
`;
