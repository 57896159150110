import { useRef, MutableRefObject, PropsWithChildren, useEffect } from 'react';
import { Canvas, RootState } from '@react-three/fiber';

import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { isDraggingContext } from '../lib/utils';
import { FileDropper } from './FileDropper';
import { WorkbenchDebugger } from './utils/WorkbenchDebugger';
import { MAX_Z_POSITION, isIpad } from './helpers';
import { MapControls } from './utils/mapControls/mapControls';
import { MultiplayerPresence } from '../lib/useWorkbenchMultiplayer';
import { SceneLayer } from './SceneLayer';
import { BackgroundLayer } from './BackgroundLayer';
import styled from 'styled-components';
import { sortObjectsByHierarchicalRenderOrder } from './utils/threeRenderingOrder';
import { MouseCursorUpdater } from './utils/MouseCursorUpdater';
import { createMultitouchThreejsEvents } from './utils/mapControls/multitouchThreejsEvents';

const StyledFileDropper = styled(FileDropper)`
  width: 100vw;
  width: 100dvw;
  height: 100vh;
  height: 100dvh;
  background-color: #f8f8f8;
`;

const StyledCanvas = styled(Canvas)`
  touch-action: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const WorkbenchCanvas = (
  props: PropsWithChildren<{
    onMissed: () => void;
    enableFileDrop: boolean;
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
    workbenchId: string;
    threeStateRef: MutableRefObject<RootState>;
    multiplayerPresences: MultiplayerPresence[];
    pauseRendering: boolean;
  }>
) => {
  const eventParent = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);

  const handleCreated = (state: RootState) => {
    state.gl.setTransparentSort(sortObjectsByHierarchicalRenderOrder);
    props.threeStateRef.current = state;
  };

  useEffect(() => {
    const target = eventParent.current;

    if (!target) {
      return;
    }

    // Prevent the default context menu on the page
    // for the right click event to be handled by the canvas
    const unBindPageContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    target.addEventListener('contextmenu', unBindPageContextMenu);

    return () => {
      target.removeEventListener('contextmenu', unBindPageContextMenu);
    };
  }, []);

  return (
    <>
      <StyledFileDropper
        handleAction={props.handleAction}
        state={props.threeStateRef}
        ref={eventParent}
        workbenchId={props.workbenchId}
        enabled={props.enableFileDrop}
      >
        <StyledCanvas
          orthographic
          camera={{
            position: [0, 0, MAX_Z_POSITION + 100],
            zoom: 1,
            far: MAX_Z_POSITION + 200,
            near: 0,
          }}
          onPointerMissed={props.onMissed}
          // Disable tone mapping and srgb -> linear conversion
          // this is very important to keep colors accurate
          // more info:
          // - https://threejs.org/docs/#manual/en/introduction/Color-management
          // - https://discourse.threejs.org/t/different-color-output-when-rendering-to-webglrendertarget/57494
          flat
          linear
          legacy
          onCreated={handleCreated}
          gl={{
            powerPreference: 'high-performance',
            antialias: !isIpad,
            localClippingEnabled: true, //used to prevent drawing some objects out of the drawing area
          }}
          // by default, the coordinates are computed from the target element bounding box
          // this breaks in our case because we use the parent as the event source and the event can be triggered from a DOM element
          // to fix it, we use the "client" prefix to force r3f to compute the pointer coordinate from the root div element
          eventPrefix="client"
          // attach the root event handler to the parent div, this is required because we have <Html> element inside the canvas
          // which are mounted in a portal in this div
          // this makes so we get the click event in our canvas even if the click event was triggered from a DOM element above it
          eventSource={eventParent.current || undefined}
          frameloop={props.pauseRendering ? 'never' : 'always'}
          events={createMultitouchThreejsEvents}
        >
          <MouseCursorUpdater />
          <BackgroundLayer />
          <SceneLayer />
          <isDraggingContext.Provider value={isDraggingRef}>
            <MapControls />
            {props.children}
            {localStorage.getItem('vizcom:debug') && <WorkbenchDebugger />}
          </isDraggingContext.Provider>
        </StyledCanvas>
      </StyledFileDropper>
    </>
  );
};
