import { useDrag } from '@use-gesture/react';
import { EventMesh } from './DrawingCompositor/EventMesh';
import { Mesh, OrthographicCamera } from 'three';

import { useLayersCompositor } from './DrawingCompositor/LayersCompositor/context';

import { MeshProps, ThreeEvent, useThree } from '@react-three/fiber';
import { useRef } from 'react';
import { screenPositionToLayer } from '../helpers';

export function LayerPicker({
  width,
  height,
  setActiveLayerId,
}: {
  width: number;
  height: number;
  setActiveLayerId: (id: string | undefined) => void;
}) {
  const eventPlaneRef = useRef<Mesh>(null!);

  const layerCompositor = useLayersCompositor();
  const camera = useThree((state) => state.camera as OrthographicCamera);

  const bindDrag = useDrag<ThreeEvent<PointerEvent>>((gesture) => {
    if (gesture.tap) {
      const position = screenPositionToLayer(
        [gesture.event.clientX, gesture.event.clientY],
        camera,
        eventPlaneRef.current,
        [width, height]
      );
      const layerId = layerCompositor.getLayerIdAt(position[0], position[1]);
      if (layerId !== null) {
        setActiveLayerId(layerId);
      }
    }
  });

  return (
    <EventMesh
      drawingSize={[width, height]}
      ref={eventPlaneRef}
      eventMeshProps={
        {
          position: [0, 0, -100],
          ...bindDrag(),
        } as MeshProps
      }
    />
  );
}
