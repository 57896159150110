import { Mesh, OrthographicCamera, Texture } from 'three';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useRef } from 'react';
import { useWorkbenchStudioToolState } from '../studioState';
import { ThreeEvent, useStore } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import {
  BrushTextureRenderer,
  BrushTextureRendererRef,
} from './BrushEngine/BrushTextureRenderer';
import { useShapeRenderer, ShapeType } from './BrushEngine/useShapeRenderer';
import { EventMesh } from './EventMesh';
import { LayerContent } from './LayersCompositor/LayerContent';
import { screenPositionToLayer } from '../../helpers';
import { useSubscribeToSelectionApi } from '../selection/useSelectionApi';
import { BrushCursorPreview } from './BrushEngine/BrushCursorPreview';

interface ShapeRendererProps {
  drawingSize: [number, number];
  layer: Drawing2dStudio['layers']['nodes'][0];
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
}

export const ShapeRenderer = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
}: ShapeRendererProps) => {
  const store = useStore();
  const eventPlaneRef = useRef<Mesh>(null!);
  const brushTextureRendererRef = useRef<BrushTextureRendererRef>(null!);
  const shapeRenderer = useShapeRenderer(drawingSize);

  const layerFill =
    layer.fill && layer.fill.match(/^#([0-9a-f]{6})$/i)
      ? layer.fill
      : undefined;

  const {
    shapeSettings: { type, size, fill, opacity },
    color,
    symmetry,
  } = useWorkbenchStudioToolState();

  const resetShapeOnNextRenderRef = useRef(false);
  if (resetShapeOnNextRenderRef.current) {
    shapeRenderer.clear();
    resetShapeOnNextRenderRef.current = false;
  }

  const bind = useDrag<ThreeEvent<PointerEvent>>(
    ({ event, last, first, memo }) => {
      if (last) {
        handleAction({
          type: 'updateLayer',
          id: layer.id,
          data: {
            image: brushTextureRendererRef.current.exportTexture(),
          },
        });
        // only reset the shape on next render, when we'll get the updated texture of the layer from `layerImage`
        // this prevents a flash of the old texture
        resetShapeOnNextRenderRef.current = true;
        return;
      }

      const camera = store.getState().camera as OrthographicCamera;
      const pointer = screenPositionToLayer(
        [event.clientX, event.clientY],
        camera,
        eventPlaneRef.current,
        drawingSize
      );

      const point = { x: pointer[0], y: pointer[1] };

      if (first) {
        memo = point;
      }

      shapeRenderer.render(
        [memo.x, memo.y],
        [point.x, point.y],
        color,
        !fill,
        -camera.rotation.z,
        size,
        event.shiftKey,
        symmetry,
        ShapeType[type]
      );

      return memo;
    }
  );

  const selectionTexture = useSubscribeToSelectionApi(
    (state) => state.texture_treatEmptyMaskAsFull
  );

  return (
    <>
      <BrushCursorPreview
        drawingSize={drawingSize}
        toolSize={0}
        toolAspect={1}
        toolAngle={0}
        color={color}
      />
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        visible={layer.visible}
        blendMode={layer.blendMode}
      >
        <BrushTextureRenderer
          size={drawingSize}
          layerFill={layerFill}
          layerTexture={layerImage}
          brushTexture={shapeRenderer.getTexture()}
          maskTexture={selectionTexture}
          brushOpacity={opacity}
          ref={brushTextureRendererRef}
        />
      </LayerContent>
      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={bind() as any}
        ref={eventPlaneRef}
      />
    </>
  );
};
