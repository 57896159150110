import { useForm, SubmitHandler } from 'react-hook-form';

import {
  Button,
  LoadingLogoInset,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  useCurrentUser,
  useUpdateUser,
} from '@vizcom/shared/data-access/graphql';

type ChangeNameInputs = {
  name: string;
};

export const ProfileName = () => {
  const { data: currentUser } = useCurrentUser();
  const [updateUserRes, updateUser] = useUpdateUser();

  const { register, handleSubmit, reset, formState } =
    useForm<ChangeNameInputs>({
      values: {
        name: currentUser?.name ?? '',
      },
    });

  const handleChangeName: SubmitHandler<ChangeNameInputs> = async (data) => {
    if (updateUserRes.fetching) {
      return;
    }
    const res = await updateUser({
      id: currentUser!.id,
      patch: {
        name: data.name,
      },
    });
    if (res.error) {
      return addToast('Error while changing name', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    } else {
      addToast('Name changed successfully');
      reset();
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(handleChangeName)}
        style={{
          display: 'flex',
          marginTop: 24,
          flexDirection: 'column',
          width: '100%',
          gap: 12,
        }}
      >
        <TextInput
          style={{ gridColumn: '1/3' }}
          required
          type="text"
          disabled={updateUserRes.fetching}
          $background="e1"
          {...register('name')}
        />
        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Button
            type="submit"
            disabled={updateUserRes.fetching || !formState.isDirty}
          >
            Save
          </Button>
          <Button
            type="button"
            variant="transparent"
            onClick={() => reset()}
            disabled={!formState.isDirty}
          >
            Cancel
          </Button>
        </div>
      </form>
      <LoadingLogoInset active={updateUserRes.fetching} />
    </div>
  );
};
