import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { useRequestDeleteCurrentUserEmail } from '@vizcom/shared/data-access/graphql';
import { useState } from 'react';

export const DeleteAccount = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [res, requestDeleteCurrentUserEmail] =
    useRequestDeleteCurrentUserEmail();

  const handleRequestDeleteEmail = async () => {
    const res = await requestDeleteCurrentUserEmail({});
    if (res.error) {
      return addToast('Error while removing email', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast(
      `An email with the instructions to delete your account has been sent. Please check your mailbox to continue.`
    );
    setIsOpen(false);
  };

  return (
    <>
      <Button
        style={{ marginTop: 12 }}
        onClick={() => setIsOpen(true)}
        variant="danger"
        disabled={res.fetching}
      >
        Delete account
      </Button>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <ModalHeader>
          <ModalTitle>Delete account</ModalTitle>
          <ModalCloseButton />
        </ModalHeader>
        <ModalContent>
          <Text block>
            Want to delete your Vizcom account? We'll send you an email to
            confirm this action.
          </Text>
        </ModalContent>
        <ModalActions>
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRequestDeleteEmail}>
            Send confirmation email
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};
