import { DragEventHandler, PropsWithChildren, useCallback } from 'react';
import { useLastValue } from './hooks/useLastValue';
import { browseForFile } from './helpers';

interface FileDropperProps {
  onFileSelected: (file: File) => void;
  browseForFileOnClick?: boolean;
}

export const FileDropper = (
  props: PropsWithChildren<
    React.HTMLAttributes<HTMLDivElement> & FileDropperProps
  >
) => {
  const { onFileSelected, browseForFileOnClick, ...rest } = props;
  const lastOnFileSelected = useLastValue(props.onFileSelected);
  const handleDragEnter = useCallback<DragEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
  }, []);
  const handleDragOver = useCallback<DragEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
  }, []);
  const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const files = e.dataTransfer?.files;
      const file = files && files[0];

      if (file) {
        lastOnFileSelected.current(file);
      }
    },
    [lastOnFileSelected]
  );
  const handleClick = async () => {
    if (props.browseForFileOnClick) {
      const file = await browseForFile();
      lastOnFileSelected.current(file);
    }
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
      {...rest}
    >
      {props.children}
    </div>
  );
};
