import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  TargetFolderSelector,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  useDuplicateWorkbench,
  useWorkbench,
} from '@vizcom/shared/data-access/graphql';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import { WorkbenchEventName } from '@vizcom/shared/data-shape';
import { publishTrackingEvent } from '@vizcom/shared/data-access/graphql';

interface DuplicateModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  workbenchId: string;
  onDuplicated?: (newId: string) => void;
}

export const DuplicateWorkbenchModal = (props: DuplicateModalProps) => {
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [duplicateWorkbenchRes, duplicateWorkbench] = useDuplicateWorkbench();
  const navigate = useNavigate();
  const workbenchRes = useWorkbench(props.workbenchId);
  const loading = duplicateWorkbenchRes.fetching || workbenchRes.fetching;

  const handleCloseModal = () => {
    props.setIsOpen(false);
  };
  const handleConfirm = async () => {
    if (!selectedFolderId) {
      return;
    }
    const res = await duplicateWorkbench({
      input: {
        id: props.workbenchId,
        targetFolderId: selectedFolderId,
      },
    });

    trackEvent('Workbench duplicated');

    publishTrackingEvent({
      type: WorkbenchEventName.WORKBENCH_DUPLICATED,
      data: {
        workbenchIds: [props.workbenchId],
      },
    });

    if (res.error || !res.data?.duplicateWorkbench.workbench) {
      addToast('There was an error while duplicating this file', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });

      return;
    }

    if (props.onDuplicated) {
      props.onDuplicated(res.data?.duplicateWorkbench.workbench.id);
    } else {
      navigate(paths.files.folder(selectedFolderId));
    }
    handleCloseModal();
  };

  const organizationId = workbenchRes.data?.folder?.organization?.id;

  return (
    <Modal
      style={{ minWidth: 430 }}
      isOpen={props.isOpen}
      setIsOpen={handleCloseModal}
      loading={loading}
    >
      <ModalHeader>
        <ModalTitle>Duplicate File</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>

      <ModalContent style={{ marginTop: 0 }}>
        {organizationId ? (
          <TargetFolderSelector
            organizationId={organizationId}
            selectedFolderId={selectedFolderId}
            setSelectedFolderId={setSelectedFolderId}
          />
        ) : (
          <Text>
            Error while duplicating file, please reload the page and try again.
          </Text>
        )}
      </ModalContent>

      <ModalActions>
        <Button onClick={handleCloseModal} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} disabled={!selectedFolderId || loading}>
          Duplicate
        </Button>
      </ModalActions>
    </Modal>
  );
};
