import styled from 'styled-components';
// @ts-ignore
import { SimpleDropzone } from 'simple-dropzone';
import { useEffect, useRef } from 'react';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import {
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { v4 as uuidv4 } from 'uuid';
import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import * as THREE from 'three';
import { setCompositeSceneNodeUUID } from '../compositeSceneEditor/utils/compositeSceneNodeUUID';
import {
  checkImportedMeshFileSize,
  exportObject3d,
  loadObject3DFromFile,
  scaleObject3dToBox,
} from '../../utils/meshHelpers';
import { zIndexOrder } from '../utils/consts';

export const CompositeSceneEditorDropper = ({
  handleAction,
}: {
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const { enableDnD, setEnableDnD, setUploadInputRef } =
    useCompositeSceneEditorContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const dropzoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!inputRef.current || !dropzoneRef.current) {
      return;
    }

    setUploadInputRef(inputRef.current);

    const dropzone = new SimpleDropzone(dropzoneRef.current, inputRef.current);

    dropzone.on('drop', (payload: { files: Map<string, File> }) => {
      setEnableDnD(false);

      Array.from(payload.files).forEach(async ([, file]) => {
        try {
          checkImportedMeshFileSize(file);
          const mesh = await loadObject3DFromFile(file);
          mesh.traverse((child) => {
            setCompositeSceneNodeUUID(child, uuidv4());

            const childAsMesh = child as THREE.Mesh;

            if (childAsMesh.material instanceof Array) {
              childAsMesh.material.forEach((material) => {
                setCompositeSceneNodeUUID(material, uuidv4());
              });
            } else if (childAsMesh.material) {
              setCompositeSceneNodeUUID(childAsMesh.material, uuidv4());
            }
          });

          const { center } = scaleObject3dToBox(mesh);
          mesh.position.y = center.y / 2.0;

          const { result: gltfBinary } = await exportObject3d(mesh);
          handleAction({
            type: 'createCompositeSceneElement',
            id: uuidv4(),
            modelFile: new Blob([gltfBinary]),
            name: file.name || 'Untitled model',
          });
        } catch (e: any) {
          return addToast(`Error while importing 3D model ${file.name}`, {
            type: 'danger',
            secondaryText: formatErrorMessage(e),
          });
        }
      });
    });

    return () => dropzone.destroy();
  }, [inputRef.current, dropzoneRef.current, handleAction, setEnableDnD]);

  return (
    <Wrapper
      ref={dropzoneRef}
      $enabled={enableDnD}
      onDragEnd={() => setEnableDnD(false)}
      onDragLeave={() => setEnableDnD(false)}
      onPointerMove={(event) => {
        if (!event.buttons) {
          setEnableDnD(false);
        }
      }}
    >
      <LabelContainer>
        <Text type="h1">Drop file(s)</Text>
        <Text type="sh2" color="tertiary">
          .fbx, .gltf, .glb, .stl, .obj
        </Text>
      </LabelContainer>
      <input
        type="file"
        id="composite-scene-editor-upload"
        accept=".glb,.gltf,.zip,.fbx,.obj,.stl"
        ref={inputRef}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $enabled: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ $enabled }) => ($enabled ? 'all' : 'none')};
  border: solid 2px
    ${({ $enabled, theme }) =>
      $enabled ? theme.tertiary.default : 'transparent'};
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ $enabled }) => ($enabled ? 1.0 : 0.0)};
  z-index: ${zIndexOrder.DragAndDropZIndex};
  transition: 0.5s opacity ease;

  & > input {
    display: none;
  }
`;

const LabelContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
