import { MeshStandardMaterial } from 'three';

export type MaterialOverrides = Pick<
  MeshStandardMaterial,
  'roughness' | 'metalness' | 'opacity' | 'transparent'
> & {
  color: string;
};

export enum MaterialPropertyFieldType {
  Scalar,
  Color,
}
