import { object3dIsMesh, useCameraZoom } from '../../../helpers';
import {
  LayerMetadata3d,
  LayerMetadata3dView,
  assertExists,
} from '@vizcom/shared/js-utils';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import InteractionIcon from './layer3dinteractionsIcon.svg?react';
import OrbitCursor from './orbitCursor.svg';

import styled from 'styled-components';

import { ContactShadows, Environment, OrbitControls } from '@react-three/drei';
// @ts-ignore
import ENV_PRESET_OVERCAST from '../../../../assets/environments/kloofendal_overcast_puresky_1k.hdr.jpg';
import {
  ReactNode,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ACESFilmicToneMapping,
  DoubleSide,
  MeshStandardMaterial,
  PerspectiveCamera,
  Spherical,
  Vector2,
  Vector3,
  Box2,
  Box3,
  Euler,
  Group,
  Event as ThreeEvent,
} from 'three';
import { CustomHtml } from '../../../utils/CustomHtml';
import { FixedSizeGroup } from '../../../utils/FixedSizeGroup';
import {
  Button,
  ErrorBoundary,
  Layer3dLightIcon,
  FeatureFlagged,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  ToastIndicator,
  useStableCallback,
  Layer3dDropShadowIcon,
} from '@vizcom/shared-ui-components';
import {
  LayerTextureRenderer,
  LayerTextureRendererRef,
} from '../LayerTextureRenderer';
import { ResizePositionRotationPresenter } from '../../../utils/ResizePositionRotationPresenter';
import { useLayerTranslationTransform } from '../LayerTransform/useLayerTranslationTransform';
import { useLayerResizeTransform } from '../LayerTransform/useLayerResizeTransform';
import {
  DEFAULT_LAYER_3D_CAMERA_DISTANCE,
  DEFAULT_LAYER_3D_CAMERA_VIEW,
} from './types';
import MaterialModeToggle from './MaterialToggle';
import { LayerContent } from '../LayersCompositor/LayerContent';
import { scaleObject3dToBox } from '../../../utils/meshHelpers';
import { useTheme } from 'styled-components';
import { useFrame } from '@react-three/fiber';
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { ToolbarDivider } from '../../../compositeScene/compositeSceneToolbar/style';
import { cachedLayerMeshByUrl } from '../../../../lib/actions/drawing/addLayer';
import { useMesh } from '@vizcom/shared/data-access/graphql';
import { useGltf } from '../../../../lib/useGltf';
import { Layer3dLightSettings } from './Layer3dLightSettings';
import { Layer3dDropShadowSettings } from './Layer3dShadowDropShadowSettings';
import { LayerData } from '../../../../lib/actions/drawing/updateLayer';

interface Layer3dEditorProps {
  layer: Drawing2dStudio['layers']['nodes'][0];
  active: boolean;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  drawingSize: [number, number];
  layerFallback: ReactNode;
}

const v2 = new Vector2();
const v3 = new Vector3();

const box2 = new Box2();

const MODEL_MINIMUM_SIZE = 48;

export const Layer3dEditor = ({
  layer,
  drawingSize,
  active,
  handleAction,
  layerFallback,
}: Layer3dEditorProps) => {
  const metadata3d = layer.metadata3D as LayerMetadata3d;
  assertExists(metadata3d);
  const { data, fetching } = useMesh(metadata3d.mesh);

  const loadingState = (
    <>
      <ToastIndicator type="loading" text="Loading 3D model" />
      {layerFallback}
    </>
  );

  if (metadata3d.generatedFrom2dTo3dError) {
    return (
      <>
        <ToastIndicator
          type="error"
          text={`There was an error generating this 3D layer. Please retry. (${metadata3d.generatedFrom2dTo3dError})`}
        />
        {layerFallback}
      </>
    );
  }

  if (fetching || (metadata3d.generatedFrom2dTo3d && !layer.meshPath)) {
    return loadingState;
  }

  const meshPath = layer.meshPath || data?.path;

  const errorFallback = (
    <>
      <ToastIndicator
        type="error"
        text="There was an error loading this 3D model, it could have been deleted or you don't have access to it. You cannot edit this layer."
      />
      {layerFallback}
    </>
  );

  if (!meshPath) {
    return errorFallback;
  }

  return (
    <ErrorBoundary fallback={errorFallback}>
      <Suspense fallback={loadingState}>
        <Layer3dEditorContent
          layer={layer}
          active={active}
          drawingSize={drawingSize}
          handleAction={handleAction}
          meshPath={meshPath}
          view={metadata3d.view || DEFAULT_LAYER_3D_CAMERA_VIEW}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

interface Layer3dEditorContentProps {
  view: LayerMetadata3dView;
  layer: Drawing2dStudio['layers']['nodes'][0];
  active: boolean;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  drawingSize: [number, number];
  meshPath: string | Blob;
}

const spherical = new Spherical();

const meshMaterial = new MeshStandardMaterial({
  roughness: 1,
  metalness: 0,
  color: '#cccccc',
  side: DoubleSide,
});

enum ControlsMode {
  Orbit = 'orbit',
  Light = 'light',
  Shadow = 'shadow',
}

const Layer3dEditorContent = ({
  view,
  layer,
  active,
  handleAction,
  drawingSize,
  meshPath,
}: Layer3dEditorContentProps) => {
  const drawingWidth = drawingSize[0];
  const drawingHeight = drawingSize[1];
  const metadata3d = layer.metadata3D as LayerMetadata3d;
  const materialMode = metadata3d.materialMode ?? 'Texture';
  const meshBlobOrUrl =
    meshPath instanceof Blob
      ? meshPath
      : cachedLayerMeshByUrl[meshPath ?? ''] ?? meshPath; // the mesh was uploaded in this session, we already have the source blob cached in memory, we use it instead of re-fetching it from storage
  const [gltf] = useGltf([meshBlobOrUrl]);
  const theme = useTheme();
  const [isOrbiting, setIsOrbiting] = useState(false);
  const [controlsMode, setControlsMode] = useState(ControlsMode.Orbit);

  assertExists(meshBlobOrUrl, 'no model for 3d layer');
  assertExists(gltf);

  const studioCameraZoom = useCameraZoom();

  const normalizedObject = useMemo(() => {
    const clone = gltf.scene.clone(true);
    scaleObject3dToBox(clone, 2);
    clone.traverse((obj) => {
      if (object3dIsMesh(obj)) {
        try {
          obj.geometry = mergeVertices(obj.geometry, 0.001);
        } catch (e) {
          // NOTE This is quality of life which can fail for uploaded models
          //      can safely ignore exceptions
        }

        if (!obj.geometry.attributes.normals) {
          obj.geometry.computeVertexNormals();
        }
        obj.userData.initialMaterial = obj.material;

        const material = obj.material as MeshStandardMaterial;

        material.side = DoubleSide;
        material.onBeforeCompile = (shader) => {
          // force the use of the SRGB colorspace when rendering this object
          // because of how threejs works, we cannot just set the texture colorSpace to sRGB as it uses the global colorSpace
          // of the renderer when compiling this shader to determine the color transfer function
          // we use the sRGB color space here because it's better adapted to rendering 3D objects with HDRI lighting
          // colorspace_fragment comes from here: https://github.com/mrdoob/three.js/blob/6c8b877c0dd161388a30f0418eea26709244476b/src/renderers/shaders/ShaderChunk/colorspace_fragment.glsl.js#L2
          // Which calls linearToOutputTexel, which is actually defined by https://github.com/mrdoob/three.js/blob/6c8b877c0dd161388a30f0418eea26709244476b/src/renderers/webgl/WebGLProgram.js#L94
          // which calls https://github.com/mrdoob/three.js/blob/6c8b877c0dd161388a30f0418eea26709244476b/src/renderers/webgl/WebGLProgram.js#L31
          // and in our case, we need it to call sRGBTransferOETF
          shader.fragmentShader = shader.fragmentShader.replace(
            '#include <colorspace_fragment>',
            'gl_FragColor = sRGBTransferOETF( gl_FragColor );'
          );
        };
        // prevent threejs from recompiling this shader everytime
        material.customProgramCacheKey = () =>
          'Layer3dEditorContent-sRGBColorSpace';
      }
    });

    return clone;
  }, [gltf?.scene]);

  const box3 = useMemo(() => {
    return new Box3().setFromObject(normalizedObject, true);
  }, [normalizedObject]);

  const [environmentIntensity, setEnvironmentIntensity] = useState<number>(
    metadata3d?.view?.environmentIntensity ?? 1.0
  );
  const [dropShadowOpacity, setDropShadowOpacity] = useState<number>(
    metadata3d.view?.dropShadowOpacity ?? 0.0
  );
  const dropShadowOffset = useMemo<[number, number, number]>(() => {
    const size = box3.getSize(new Vector3());

    return [0.0, -size.y / 2.0, 0.0];
  }, [box3]);

  const updateLayer = useStableCallback(
    (update: Partial<LayerData> = {}, updateLayerThumbnail: boolean = true) => {
      const payload: Partial<LayerData> = { ...update };

      if (updateLayerThumbnail) {
        payload.image = textureRendererRef.current.exportTexture();
      }

      handleAction({
        type: 'updateLayer',
        id: layer.id,
        data: payload,
      });
    }
  );

  useEffect(() => {
    const scene = normalizedObject;
    scene.traverse((obj) => {
      if (object3dIsMesh(obj)) {
        obj.material =
          materialMode === 'Texture'
            ? obj.userData.initialMaterial
            : meshMaterial;
      }
    });
  }, [normalizedObject, materialMode]);

  // Only upate the layer when the object actually changes, or the layer
  // has no image path (which means it's the first time we're rendering it)
  const firstUpdate = useRef(Boolean(layer.imagePath));
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    updateLayer();
  }, [normalizedObject]);

  const textureRendererRef = useRef<LayerTextureRendererRef>(null!);
  const [interactionIcon, setInteractionIcon] = useState<HTMLDivElement | null>(
    null
  );

  const x = view.x ?? 0;
  const y = view.y ?? 0;
  const zoom = view.zoom ?? 1;

  const camera = useMemo(() => {
    const camera = new PerspectiveCamera(
      40,
      drawingWidth / drawingHeight,
      0.01,
      10000
    );
    camera.lookAt(0, 0, 0);
    camera.position.setFromSphericalCoords(
      DEFAULT_LAYER_3D_CAMERA_DISTANCE,
      view.phi,
      view.theta
    );
    camera.setViewOffset(
      drawingWidth,
      drawingHeight,
      -x,
      y,
      drawingWidth,
      drawingHeight
    );

    camera.zoom = zoom;
    camera.updateProjectionMatrix();
    return camera;
  }, [drawingWidth, drawingHeight]);

  const [[layerWidth, layerHeight], setLayerSize] = useState([0, 0]);
  const [isLastInteractionOrbiting, setIsLastInteractionOrbiting] =
    useState(true);

  const resizerGroupRef = useRef<Group>(null!);
  const {
    bindTranslationHandle,
    resetTranslationTransform,
    translationTransform: translation,
  } = useLayerTranslationTransform(
    () => handleTranformControlsEnd(),
    resizerGroupRef
  );

  const {
    bindResizeHandle,
    resetResizeTransform,
    scaleTransform: scale,
  } = useLayerResizeTransform(
    () => handleTranformControlsEnd(),
    resizerGroupRef,
    layerWidth,
    layerHeight,
    0,
    {
      forceAspectRatio: true,
      minSize: MODEL_MINIMUM_SIZE,
    }
  );

  useFrame(() => {
    box2.makeEmpty();
    //Project each corner of the object's bounding box and compute the 2d bounding box
    for (let i = 0; i < 8; i++) {
      v3.set(
        (i & 1 ? box3.min : box3.max).x,
        (i & 2 ? box3.min : box3.max).y,
        (i & 4 ? box3.min : box3.max).z
      );
      v3.project(camera);
      v2.set(v3.x * drawingWidth, v3.y * drawingHeight);
      box2.min.min(v2);
      box2.max.max(v2);
    }
    //We want the bounding box to be centered around [x, y],
    //so take the largest distance from the center to the bounding box's edges.
    v2.set(x + translation[0], y + translation[1]).multiplyScalar(2);
    const newLayerWidth =
      Math.max(Math.abs(v2.x - box2.min.x), Math.abs(v2.x - box2.max.x)) /
      scale[0];
    const newLayerHeight =
      Math.max(Math.abs(v2.y - box2.min.y), Math.abs(v2.y - box2.max.y)) /
      scale[1];
    if (
      !isOrbiting &&
      (Math.abs(newLayerWidth - layerWidth) > 1 ||
        Math.abs(newLayerHeight - layerHeight) > 1)
    ) {
      setLayerSize([newLayerWidth, newLayerHeight]);
    }
  });

  useEffect(() => {
    // keeping the camera distance from the model the same, and only using zoom to not introduce artefacts when the camera is too far away (z fighting)
    camera.position.setFromSphericalCoords(
      DEFAULT_LAYER_3D_CAMERA_DISTANCE,
      view.phi,
      view.theta
    );
  }, [view.phi, view.theta, camera]);

  const scalingFactor = scale[0];
  useEffect(() => {
    camera.zoom = zoom * scalingFactor;
    camera.updateProjectionMatrix();
  }, [zoom, scalingFactor, camera]);

  useEffect(() => {
    camera.setViewOffset(
      drawingWidth,
      drawingHeight,
      (x + translation[0]) * -1,
      y + translation[1],
      drawingWidth,
      drawingHeight
    );
    camera.updateProjectionMatrix();
  }, [camera, drawingWidth, drawingHeight, x, y, translation]);

  const handleControlsStart = useStableCallback((event?: ThreeEvent) => {
    if (!event) {
      return;
    }

    if (controlsMode === ControlsMode.Light) {
      const pivot = new Group(); // Using pivot to easily revert to the original scene orientation
      const controls = event.target! as any;

      const frozenPolarAngle = controls.getPolarAngle();

      pivot.userData = {
        originalPolarMinMax: {
          min: controls.minPolarAngle,
          max: controls.maxPolarAngle,
        },
        originalAzimuth: {
          angle: controls.getAzimuthalAngle(),
        },
        originalCamera: {
          position: camera.position.clone(),
          rotation: camera.rotation.clone(),
        },
        originalWorld: {
          matrix: normalizedObject.matrix.clone(),
        },
      };

      controls.maxPolarAngle = controls.minPolarAngle = frozenPolarAngle;

      pivot.lookAt(camera.position);
      normalizedObject.parent!.attach(pivot);
      pivot.attach(normalizedObject);
    }

    setIsOrbiting(true);
  });

  const handleLightingControlsEnd = useStableCallback((event?: ThreeEvent) => {
    setIsOrbiting(false);

    if (!event) {
      return;
    }

    const metadata3DUpdate: Partial<LayerMetadata3d> = {
      ...layer.metadata3D,
      view: {
        ...(layer.metadata3D?.view ?? {}),
      },
    };

    const pivot = normalizedObject.parent;
    const controls = event.target! as any;
    const azimuth = controls.getAzimuthalAngle();

    if (!pivot || !pivot.parent) {
      return;
    }

    controls.minPolarAngle = pivot.userData.originalPolarMinMax.min ?? 0;
    controls.maxPolarAngle = pivot.userData.originalPolarMinMax.max ?? Math.PI;

    const deltaAzimuth = azimuth - pivot.userData.originalAzimuth.angle;

    // NOTE Reset object to the original orientation
    //      otherwise orbit controls will multiply object rotation
    //      and the light rotation, losing proper XYZ axes
    pivot.parent!.add(normalizedObject);
    normalizedObject.matrixAutoUpdate = false;
    normalizedObject.matrix.copy(pivot.userData.originalWorld.matrix);

    camera.position.copy(pivot.userData.originalCamera.position); // Restore original camera position
    camera.rotation.copy(pivot.userData.originalCamera.rotation); // Restore original camera rotation

    pivot.removeFromParent();

    const environmentRotation = metadata3d.view?.environmentRotation ?? 0.0;
    const newEnvironmentRotation = new Euler(
      0.0,
      environmentRotation - deltaAzimuth,
      0.0
    );

    // Update environment imperatively to prevent ms flicker (<Environment /> component does not provide ref and doesn't update quick enough)
    (normalizedObject.parent as any).environmentRotation.copy(
      newEnvironmentRotation
    );

    metadata3DUpdate.view!.environmentRotation = newEnvironmentRotation.y;

    updateLayer({
      metadata3D: metadata3DUpdate,
    });
  });

  const handleTranformControlsEnd = useStableCallback(() => {
    setIsOrbiting(false);

    const metadata3DUpdate: Partial<LayerMetadata3d> = {
      ...layer.metadata3D,
      view: {
        ...(layer.metadata3D?.view ?? {}),
      },
    };

    spherical.setFromVector3(camera.position);

    metadata3DUpdate.view!.phi = spherical.phi;
    metadata3DUpdate.view!.theta = spherical.theta;
    metadata3DUpdate.view!.zoom = zoom * scale[0];
    metadata3DUpdate.view!.x = x + translation[0];
    metadata3DUpdate.view!.y = y + translation[1];

    resetTranslationTransform();
    resetResizeTransform();

    updateLayer({
      metadata3D: metadata3DUpdate,
    });
  });

  useEffect(() => {
    setIsLastInteractionOrbiting(false);
  }, [scale[0], scale[1], studioCameraZoom]);

  const showOrbitControls =
    isLastInteractionOrbiting ||
    (layerWidth * scale[0] * studioCameraZoom > MODEL_MINIMUM_SIZE + 1 &&
      layerHeight * scale[1] * studioCameraZoom > MODEL_MINIMUM_SIZE + 1);

  const toggleRenderMode = useStableCallback(() => {
    const metadata3DUpdate: Partial<LayerMetadata3d> = {
      ...layer.metadata3D,
      materialMode: materialMode === 'Texture' ? 'Mesh' : 'Texture',
    };

    updateLayer(
      {
        metadata3D: metadata3DUpdate,
      },
      false
    );

    setTimeout(() => updateLayer(), 0);
  });

  return (
    <>
      <group
        ref={resizerGroupRef}
        position={[x + translation[0], y + translation[1], 0]}
        renderOrder={2}
      >
        <ResizePositionRotationPresenter
          active={!isOrbiting && active}
          width={layerWidth * scale[0]}
          height={layerHeight * scale[1]}
          moveHandleMeshProps={bindTranslationHandle() as any}
          resizeHandleMeshPropsGetter={bindResizeHandle as any}
          color={theme.tertiary.default}
        />
        {active && showOrbitControls && (
          <FixedSizeGroup>
            <CustomHtml transform>
              <div
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  borderRadius: '100%',
                  padding: 5,
                  opacity: isOrbiting ? 0 : 1,
                  transition: '0.2s opacity ease',
                  cursor: `url("${OrbitCursor}") 12 12, auto`,
                }}
                ref={(r) => setInteractionIcon(r)}
                onPointerDown={(e) => e.stopPropagation()}
              >
                <InteractionIcon />
              </div>
            </CustomHtml>
          </FixedSizeGroup>
        )}
      </group>
      <LayerContent
        blendMode={layer.blendMode}
        id={layer.id}
        opacity={layer.opacity}
        visible={layer.visible}
      >
        <LayerTextureRenderer
          width={drawingWidth}
          height={drawingHeight}
          camera={camera}
          toneMapping={ACESFilmicToneMapping}
          materialProps={{
            opacity: layer.opacity,
          }}
          ref={textureRendererRef}
          multisampled
        >
          <OrbitControls
            enableDamping={controlsMode === ControlsMode.Orbit}
            dampingFactor={0.3}
            enablePan={false}
            camera={camera}
            onStart={handleControlsStart}
            onEnd={(e) => {
              if (controlsMode === ControlsMode.Light) {
                handleLightingControlsEnd(e);
              } else {
                handleTranformControlsEnd();
              }
            }}
            onChange={() => {
              if (controlsMode === ControlsMode.Light) {
                const pivot = normalizedObject.parent;

                if (!pivot || !pivot.parent) {
                  return;
                }

                pivot.lookAt(camera.position);
              }

              setIsLastInteractionOrbiting(true);
            }}
            enableZoom={false}
            rotateSpeed={0.05}
            domElement={interactionIcon ?? undefined}
            enabled={!!interactionIcon}
          />
          <primitive object={normalizedObject} />
          <Environment
            files={ENV_PRESET_OVERCAST}
            environmentRotation={[
              0.0,
              metadata3d.view?.environmentRotation ?? 0.0,
              0.0,
            ]}
            environmentIntensity={environmentIntensity}
          />
          <FeatureFlagged flag="2DSTUDIO_3DLAYERS_LIGHT_CONTROLS">
            {!!metadata3d.view?.dropShadowOpacity &&
              controlsMode !== ControlsMode.Light && (
                <ContactShadows
                  resolution={2048}
                  position={dropShadowOffset}
                  scale={10.0}
                  blur={0.5}
                  opacity={dropShadowOpacity}
                  far={20}
                />
              )}
          </FeatureFlagged>
        </LayerTextureRenderer>
        <CustomHtml
          position={[
            x + translation[0],
            y + (layerHeight * scale[1]) / 2 + translation[1],
            0.5,
          ]}
        >
          <Layer3DMenu
            $active={!isOrbiting && active}
            onPointerDown={(e) => e.stopPropagation()}
          >
            <MaterialModeToggle
              materialMode={materialMode}
              toggleMaterialMode={() => {
                toggleRenderMode();
              }}
              disabled={isOrbiting || !active}
            />
            <FeatureFlagged flag="2DSTUDIO_3DLAYERS_LIGHT_CONTROLS">
              <ToolbarDivider />
              <RichTooltip
                manualOpen={controlsMode === ControlsMode.Light && !isOrbiting}
                placement="bottom"
                padding={0}
              >
                <RichTooltipTrigger>
                  <Button
                    variant={
                      controlsMode === ControlsMode.Light
                        ? 'primary'
                        : 'transparent'
                    }
                    size="iconSquared"
                    onClick={() => {
                      if (controlsMode !== ControlsMode.Light) {
                        setControlsMode(ControlsMode.Light);
                      } else {
                        setControlsMode(ControlsMode.Orbit);
                      }
                    }}
                  >
                    <Layer3dLightIcon
                      style={{
                        height: 20,
                      }}
                    />
                  </Button>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ color: theme.white, padding: 4 }}>
                  <Layer3dLightSettings
                    layer={layer}
                    updateLayer={updateLayer}
                    onEnvironmentIntensityChange={setEnvironmentIntensity}
                  />
                </RichTooltipContent>
              </RichTooltip>
              <RichTooltip
                manualOpen={controlsMode === ControlsMode.Shadow && !isOrbiting}
                placement="bottom"
                padding={0}
              >
                <RichTooltipTrigger>
                  <Button
                    variant={
                      controlsMode === ControlsMode.Shadow
                        ? 'primary'
                        : 'transparent'
                    }
                    size="iconSquared"
                    onClick={() => {
                      if (controlsMode !== ControlsMode.Shadow) {
                        setControlsMode(ControlsMode.Shadow);
                      } else {
                        setControlsMode(ControlsMode.Orbit);
                      }
                    }}
                  >
                    <Layer3dDropShadowIcon
                      style={{
                        height: 20,
                      }}
                    />
                  </Button>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ color: theme.white, padding: 4 }}>
                  <Layer3dDropShadowSettings
                    updateLayer={updateLayer}
                    layer={layer}
                    onDropShadowOpacityChange={setDropShadowOpacity}
                  />
                </RichTooltipContent>
              </RichTooltip>
            </FeatureFlagged>
          </Layer3DMenu>
        </CustomHtml>
      </LayerContent>
    </>
  );
};

export const Layer3DMenu = styled.div<{ $active: boolean }>`
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.icon.default};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: stretch;
  justify-content: center;
  transform: translate3d(-50%, calc(-20px - 100%), 0);
  pointer-events: all;
  flex-direction: row;
  height: 100%;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: opacity 0.2s ease;
`;
