import { CompositeSceneData } from '@vizcom/shared/data-access/graphql';
import { SyncQueueSynchronizer } from '../../../lib/SyncQueueSynchronizer';
import {
  addToast,
  ErrorBoundary,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ToastIndicator,
  useLastNonNullValue,
  bottomScreenTunnel,
} from '@vizcom/shared-ui-components';
import { Transition, TransitionStatus } from 'react-transition-group';
import { HtmlOverlay } from '../../utils/HtmlOverlay';
import { CompositeSceneToolbar } from '../compositeSceneToolbar/CompositeSceneToolbar';
import { CompositeSceneEditorContextProvider } from '../compositeSceneEditor/context';
import { CompositeSceneEditor } from '../compositeSceneEditor/CompositeSceneEditor';
import { useRef } from 'react';
import {
  SyncedStateActionHandler,
  useCompositeSceneSyncedState,
} from '../../../lib/useCompositeSceneSyncedState';
import { useCompositeSceneRender } from '../../../lib/useCompositeSceneRender';
import { RootState } from '@react-three/fiber';
import { useCompositeSceneDebug } from '../compositeSceneEditor/hooks/useCompositeSceneDebug';
import { useRegisterUpdateCompositeSceneThumbnail } from '../../../lib/useSyncCompositeSceneThumbnail';
import { Leva } from 'leva';
import { RenderingOption } from '../compositeSceneEditor/types';
import { useRerender } from 'libs/shared/ui/components/src/lib/hooks/useRerender';
import { isDebugModeEnabled } from '../compositeSceneEditor/utils/isDebugModeEnabled';
import { CompositeSceneEditorDropper } from '../compositeSceneEditorDropper/CompositeSceneEditorDropper';
import { CompositeSceneMenu } from '../compositeSceneMenu/CompositeSceneMenu';
import {
  WorkbenchSceneOverlayPosition,
  WorkbenchSceneOverlayWrapper,
} from './style';
import { CompositeSceneAIOverlay } from '../compositeSceneAIOverlay/CompositeSceneAIOverlay';
import { getImperativeSnapshot3D } from '../compositeSceneEditor/utils/getImperativeSnapshot3D';
import { SceneStructureTree } from '../sceneStructureTree/SceneStructureTree';
import { zIndexOrder } from '../utils/consts';
import { CompositeSceneAIToolbar } from '../compositeSceneAIToolbar/CompositeSceneAIToolbar';
import { WorkbenchCompositeSceneMenu } from './WorkbenchStudioMenu';
import { CompositeSceneAIDockedView } from '../compositeSceneAIDockedView/CompositeSceneAIDockedView';
import { PerspectiveCamera } from 'three';

export const WorkbenchCompositeScene = (props: {
  activeElement: CompositeSceneData | undefined;
  syncQueueSynchronizer: SyncQueueSynchronizer;
  onEnter: () => void;
  onExit: () => void;
  onAddPreviewToWorkbench: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
}) => {
  const lastActiveElement = useLastNonNullValue(props.activeElement);

  if (!lastActiveElement) {
    return null;
  }

  return (
    <Transition in={!!props.activeElement} timeout={1000} appear unmountOnExit>
      {(state) => (
        <WorkbenchCompositeSceneImpl
          state={state}
          onEnter={props.onEnter}
          onExit={props.onExit}
          compositeSceneId={lastActiveElement.id}
          syncQueueSynchronizer={props.syncQueueSynchronizer}
          onAddPreviewToWorkbench={props.onAddPreviewToWorkbench}
        />
      )}
    </Transition>
  );
};

const WorkbenchCompositeSceneImpl = ({
  state,
  compositeSceneId,
  syncQueueSynchronizer,
  onEnter,
  onExit,
  onAddPreviewToWorkbench,
}: {
  state: TransitionStatus;
  compositeSceneId: string;
  syncQueueSynchronizer: SyncQueueSynchronizer;
  onEnter: () => void;
  onExit: () => void;
  onAddPreviewToWorkbench: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
}) => {
  const debugMode = isDebugModeEnabled();
  const threeStateRef = useRef<RootState | null>(null);
  const {
    fetching,
    error,
    handleAction,
    optimisticCompositeScene,
    optimisticCompositeSceneRef,
    redoAction,
    undoAction,
  } = useCompositeSceneSyncedState(compositeSceneId, syncQueueSynchronizer);
  const registerUpdateThumbnail = useRegisterUpdateCompositeSceneThumbnail(
    compositeSceneId,
    threeStateRef
  );
  const compositeSceneDebug = useCompositeSceneDebug({
    threeStateRef,
    compositeScene: optimisticCompositeScene ?? undefined,
  });
  const rerender = useRerender();
  const { renders, setRenders, rendersRequested, requestRendersUpdate } =
    useCompositeSceneRender(compositeSceneId);

  const sceneHasContents =
    !!optimisticCompositeSceneRef.current?.compositeSceneElements.nodes.length;

  const requestRenders = () => {
    const showAiPreview =
      threeStateRef.current && threeStateRef.current.scene.children.length > 0;

    if (!showAiPreview || !sceneHasContents) {
      return;
    }

    requestRendersUpdate(optimisticCompositeSceneRef.current!, async () => {
      if (!threeStateRef.current) {
        return [];
      }

      const cameraRoots = (
        optimisticCompositeScene?.compositeSceneElements.nodes || []
      )
        .filter((node) => {
          return node.basicShape === 'Camera';
        })
        .map((cameraRoot) => {
          const camera =
            threeStateRef.current!.camera.clone() as PerspectiveCamera;
          const mesh = cameraRoot.meshes[Object.keys(cameraRoot.meshes)[0]];

          if (!mesh || !camera) {
            return null;
          }

          camera.position.set(
            mesh.position[0],
            mesh.position[1],
            mesh.position[2]
          );
          camera.quaternion.set(
            mesh.quaternion[0],
            mesh.quaternion[1],
            mesh.quaternion[2],
            mesh.quaternion[3]
          );
          camera.fov = mesh.cameraFov ?? 50.0;

          return {
            camera,
          };
        })
        .filter(Boolean) as Array<{
        camera: PerspectiveCamera;
      }>;
      if (cameraRoots.length === 0) {
        cameraRoots.push({
          camera: threeStateRef.current!.camera as PerspectiveCamera,
        });
      }

      const imageSets: Array<Partial<Record<RenderingOption, Blob>>> = Array(
        cameraRoots.length
      )
        .fill(0)
        .map(() => ({}));

      let imageSnapshotsMap: Partial<Record<RenderingOption, boolean>>;
      const showDebugAIRender =
        compositeSceneDebug.activePreview !== 'default' &&
        compositeSceneDebug.enabled;
      const imageSnapshotResolution = 1024;

      if (showDebugAIRender) {
        imageSnapshotsMap = compositeSceneDebug.sendToBackend;
      } else {
        imageSnapshotsMap = {
          color: true,
        };
      }

      for (
        let imageSetIndex = 0;
        imageSetIndex < imageSets.length;
        imageSetIndex++
      ) {
        const imageSet: Partial<Record<RenderingOption, Blob>> = {};

        for (const key of Object.keys(RenderingOption)) {
          if (imageSnapshotsMap[key as RenderingOption]) {
            try {
              const image = await getImperativeSnapshot3D(
                threeStateRef.current!.scene,
                cameraRoots[imageSetIndex].camera,
                {
                  postprocessing: key as RenderingOption,
                  width: imageSnapshotResolution,
                  height: imageSnapshotResolution,
                  quality: 50,
                  type: 'jpg',
                }
              );
              imageSet[key as RenderingOption] = image;
            } catch (e) {
              addToast(
                `An error occurred when rendering 3D frame (type: ${key}).`,
                {
                  type: 'danger',
                }
              );

              throw e;
            }
          }
        }

        imageSets[imageSetIndex] = imageSet;
      }

      return imageSets;
    });
  };

  const handleActionAndRequestRenders: SyncedStateActionHandler = (
    ...props: Parameters<SyncedStateActionHandler>
  ) => {
    setTimeout(() => {
      requestRenders();

      registerUpdateThumbnail();
    }, 300);

    return handleAction(...props);
  };

  if (error) {
    onExit();

    addToast(`An error occurred when loading the 3D scene.`, {
      type: 'danger',
    });

    return null;
  }

  if (!optimisticCompositeScene || fetching) {
    return <ToastIndicator type="loading" text="Loading 3D scene" />;
  }

  return (
    <HtmlOverlay noInjectThreeContext>
      <CompositeSceneEditorContextProvider>
        <ErrorBoundary
          fallback={
            <Modal
              isOpen
              setIsOpen={(isOpen) => {
                if (!isOpen) {
                  onExit();
                }
              }}
            >
              <ModalHeader>
                <ModalTitle>Something went wrong.</ModalTitle>
                <ModalCloseButton />
              </ModalHeader>
              <ModalContent>
                An error occured when attempting to start the 3D editor.
              </ModalContent>
            </Modal>
          }
        >
          <CompositeSceneEditor
            threeStateRef={threeStateRef}
            compositeScene={optimisticCompositeScene}
            handleAction={handleActionAndRequestRenders}
            requestPreview={requestRenders}
            onCreated={onEnter}
            onRerender={rerender}
          />

          <CompositeSceneAIOverlay
            renders={renders}
            compositeSceneDebug={compositeSceneDebug}
            compositeScene={optimisticCompositeScene}
            handleAction={handleActionAndRequestRenders}
          />

          <CompositeSceneEditorDropper
            handleAction={handleActionAndRequestRenders}
          />

          <CompositeSceneToolbar onExit={onExit} />

          <WorkbenchSceneOverlayWrapper
            $disabled={false}
            $state={sceneHasContents ? 'entered' : 'exited'}
            $position={WorkbenchSceneOverlayPosition.bottom}
            $width="100%"
            $height={120}
          >
            <bottomScreenTunnel.In>
              <CompositeSceneAIToolbar
                renders={renders}
                loading={rendersRequested}
                threeStateRef={threeStateRef}
                setActiveRenders={(renders) =>
                  setRenders(renders as (Uint8Array | null)[])
                }
                onAddToWorkbench={onAddPreviewToWorkbench}
              />
            </bottomScreenTunnel.In>
          </WorkbenchSceneOverlayWrapper>

          <WorkbenchSceneOverlayWrapper
            $disabled={false}
            $state={state}
            $position={WorkbenchSceneOverlayPosition.left}
            $width={250}
            $height="100%"
          >
            <SceneStructureTree
              compositeScene={optimisticCompositeScene}
              handleAction={handleActionAndRequestRenders}
            />
            <CompositeSceneAIDockedView
              renders={renders}
              compositeSceneDebug={compositeSceneDebug}
            />
          </WorkbenchSceneOverlayWrapper>

          <WorkbenchSceneOverlayWrapper
            $disabled={false}
            $state={state}
            $position={WorkbenchSceneOverlayPosition.right}
            $width={250}
            $height="100%"
          >
            <CompositeSceneMenu
              compositeScene={optimisticCompositeScene}
              handleAction={handleActionAndRequestRenders}
              threeStateRef={threeStateRef}
            />
          </WorkbenchSceneOverlayWrapper>

          <WorkbenchCompositeSceneMenu
            compositeScene={optimisticCompositeScene}
          />

          <div
            style={{
              position: 'fixed',
              pointerEvents: 'all',
              zIndex: zIndexOrder.DebugUiZIndex,
            }}
          >
            <Leva hidden={!debugMode} />
          </div>
        </ErrorBoundary>
      </CompositeSceneEditorContextProvider>
    </HtmlOverlay>
  );
};
