import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import { Canvas, RootState } from '@react-three/fiber';
import { KeyboardEvent, MutableRefObject, Suspense, useState } from 'react';
import { useCompositeSceneEditorContext } from './context';
import { Scene } from './Scene';
import { Container } from './style';
import { CompositeSceneElement } from './CompositeSceneElement';
import { Controls } from './Controls';
import { getCompositeSceneNodeUUID } from './utils/compositeSceneNodeUUID';
import { getRootElementForMesh } from '../../utils/getRootElementForMesh';
import { CompositeSceneElementLoader } from './CompositeSceneElementLoader';

export const CompositeSceneEditor = ({
  compositeScene,
  handleAction,
  requestPreview,
  threeStateRef,
  onCreated,
  onRerender,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
  requestPreview: () => void;
  threeStateRef: MutableRefObject<RootState | null>;
  onCreated: () => void;
  onRerender: () => void;
}) => {
  const [controlsReady, setControlsReady] = useState(false);
  const { selected, setSelected, setHovered, setEnableDnD, aiPreviewMode } =
    useCompositeSceneEditorContext();
  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (selected && ['Backspace', 'Delete'].includes(event.key)) {
      const root = getRootElementForMesh(compositeScene, selected);
      const uuid = getCompositeSceneNodeUUID(selected);

      if (!root) {
        return;
      }

      const currentState = root.meshes[uuid];
      const updatedState = {
        ...currentState,
        deleted: true,
      };

      handleAction({
        type: 'updateCompositeSceneElement',
        id: selected.userData.rootId,
        meshes: {
          ...root.meshes,
          [uuid]: updatedState,
        },
      });

      setSelected(null);
    }
  };

  const onPointerMissed = () => {
    setSelected(null);
    setHovered(null);
  };

  return (
    <Container
      onDragEnter={() => {
        setEnableDnD(true);
      }}
      onDragOver={() => {
        setEnableDnD(true);
      }}
      $width={
        aiPreviewMode.mode === 'docked'
          ? '100%'
          : `${Math.ceil((1.0 - aiPreviewMode.splitScreenRatio!) * 100)}%`
      }
      $background={compositeScene.sceneBackgroundColorHex}
    >
      <Canvas
        flat
        tabIndex={0}
        shadows={{
          autoUpdate: true,
        }}
        gl={{
          preserveDrawingBuffer: true,
          powerPreference: 'high-performance',
          antialias: false,
        }}
        onPointerMissed={onPointerMissed}
        onCreated={(state) => {
          threeStateRef.current = state;

          if (onCreated) {
            onCreated();
          }
        }}
        onKeyDown={onKeyDown}
        style={{
          // NOTE Offset canvas to the side, so that center is not covered by the left-side menu
          width: aiPreviewMode.mode === 'docked' ? '' : 'calc(100% + 265px)',
        }}
      >
        <Scene compositeScene={compositeScene}>
          {compositeScene.compositeSceneElements.nodes.map((element) => {
            if (!(element.modelPath || element.basicShape) || !controlsReady) {
              return (
                <CompositeSceneElementLoader key={element.id} radius={1.0} />
              );
            }

            return (
              <Suspense
                fallback={
                  <CompositeSceneElementLoader key={element.id} radius={1.0} />
                }
                key={element.id}
              >
                <CompositeSceneElement
                  compositeSceneElement={element}
                  onAddedToScene={onRerender}
                />
              </Suspense>
            );
          })}
          <Controls
            compositeScene={compositeScene}
            handleAction={handleAction}
            requestPreview={requestPreview}
            onControlsReady={() => setControlsReady(true)}
          />
        </Scene>
      </Canvas>
    </Container>
  );
};
