import styled from 'styled-components';
import {
  LoadingLogo,
  lastOrganizationIdStorageKeyForUserId,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { Navigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import {
  useCurrentUser,
  useOrganization,
} from '@vizcom/shared/data-access/graphql';

// This page is used to redirect to the latest used organization / team
// or if there's none, redirect to the "Create organization" page
export const RootRedirectorPage = () => {
  const { data: selectedOrganization, loading } = useSelectedOrganization();
  const { data: currentUser, fetching } = useCurrentUser();

  // if there's no selected organization, fallback to first available organization
  const availableOrganizations = currentUser?.organizations;
  const lastUsedOrganizationId = currentUser
    ? localStorage.getItem(
        lastOrganizationIdStorageKeyForUserId(currentUser.id)
      )
    : null;
  const fallbackOrganizationId =
    (availableOrganizations?.edges.some(
      (org) => org.node.id === lastUsedOrganizationId
    )
      ? lastUsedOrganizationId
      : availableOrganizations?.edges?.[0]?.node?.id) ?? undefined;

  const fallbackOrganization = useOrganization(
    // only load fallback team if no team has been selected
    fallbackOrganizationId && !loading && !fetching && !selectedOrganization
      ? fallbackOrganizationId
      : undefined
  );

  if (loading || fetching || fallbackOrganization.fetching) {
    return (
      <Container>
        <LoadingLogo />
      </Container>
    );
  }

  if (selectedOrganization) {
    return (
      <Container>
        <Navigate
          to={paths.files.organizationRecent(selectedOrganization.id)}
          replace
        />
      </Container>
    );
  }

  if (fallbackOrganization.data) {
    return (
      <Container>
        <Navigate
          to={paths.files.organizationRecent(fallbackOrganization.data.id)}
          replace
        />
      </Container>
    );
  }

  // User doesn't have any organization, redirect to create organization page
  return <Navigate replace to={paths.organization.create()} />;
};

const Container = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(p) => p.theme.surface.e0};
  display: flex;
  align-items: center;
  justify-content: center;
`;
