import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import {
  CreateWorkbenchElementsMutation,
  ImageInferenceType,
  UserClientStateKeys,
  urqlClient,
  useCurrentUserClientStateByKey,
} from '@vizcom/shared/data-access/graphql';
import { getElementSize } from '../../helpers';
import { CustomHtml } from '../../utils/CustomHtml';
import styled from 'styled-components';
import {
  Text,
  addToast,
  VizcomLogoAnimated,
} from '@vizcom/shared-ui-components';
import { useEffect, useRef } from 'react';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import {
  useCompleteOnboardingStep,
  OnboardingTooltip,
  OnboardingStepName,
} from '@vizcom/shared-ui-components';
import { HtmlErrorPlaceholder } from '../../utils/HtmlErrorPlaceholder';
import ImageEdit from '../../../icons/imageedit.svg?react';
import { ClientSideWorkbenchElementPlaceholder } from '../../../lib/clientState';
import { RawImage } from '../../../lib/RawImage';
import { ZoomFallbackGroup } from '../../utils/ZoomFallbackGroup';
import { filterChildByWorkbenchElementUserData } from '../../objectsUserdata';
import { useThree } from '@react-three/fiber';
import { v4 as uuidv4 } from 'uuid';
import { MIN_IMG_2_IMG_SIZE } from '../../utils/getContentSize';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';
import { getElementDefaultSize } from '../../utils/getElementDefaultSize';

interface WorkbenchElementPlaceholderProps {
  workbenchId: string;
  element: ClientSideWorkbenchElementPlaceholder;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}

export const WorkbenchElementPlaceholder = ({
  workbenchId,
  element,
  isDragging,
  isResizing,
  singleFocused,
  handleAction,
  setEditingElementId,
}: WorkbenchElementPlaceholderProps) => {
  const scene = useThree((s) => s.scene);
  const { width, height } = getElementSize(element);
  const loadingMeshRef = useRef(false);
  const isViewer = useIsWorkbenchViewer();

  useEffect(() => {
    if (loadingMeshRef.current || !element.meshOutputPath) {
      return;
    }
    loadingMeshRef.current = true;
    handleAction({
      type: 'convertPlaceholderMeshOutput',
      elementId: element.id,
    });
  }, [element.meshOutputPath, element.id, handleAction]);

  useEffect(() => {
    if (!element.failureReason) return;

    addToast('Error generating image', {
      type: 'danger',
      secondaryText: element.failureReason,
    });
  }, [element.failureReason]);

  const fallback = (
    <mesh scale={[width, height, 1]} position={[0, 0, 0.5]}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <meshBasicMaterial color="white" />
    </mesh>
  );

  if (isResizing) {
    return fallback;
  }

  const allPlaceholders = filterChildByWorkbenchElementUserData(
    scene,
    (userData) => userData.elementTypename === 'WorkbenchElementPlaceholder'
  );
  // Only show the tooltip on the last placeholder element instead of all placeholder elements
  const showOnboardingTooltip =
    allPlaceholders.length > 0
      ? allPlaceholders[allPlaceholders.length - 1].userData.elementId ===
        element.id
      : true;

  return (
    <>
      <ZoomFallbackGroup fallback={fallback}>
        <CustomHtml
          transform
          occlude="blending"
          position={[0, 0, 0.5]}
          contentSize={{ width, height }}
          visible={!isResizing}
        >
          <OnboardingTooltip
            name={OnboardingStepName.EnterSketch}
            previousStep={OnboardingStepName.InsertImage}
            title="Double click to begin sketching"
            show={showOnboardingTooltip}
          >
            <div
              style={{
                width,
                height,
                userSelect: 'none',
              }}
            >
              {element.type === 'drawing' && (
                <CreateDrawingPlaceholder
                  placeholder={element}
                  workbenchId={workbenchId}
                  handleAction={handleAction}
                  setEditingElementId={setEditingElementId}
                  singleFocused={singleFocused}
                />
              )}
              {element.failureReason ? (
                <div>
                  <HtmlErrorPlaceholder />
                </div>
              ) : (
                element.type !== 'drawing' && (
                  <WorkbenchPlaceholderLoading element={element} />
                )
              )}
            </div>
          </OnboardingTooltip>
        </CustomHtml>
      </ZoomFallbackGroup>
      {!isDragging && singleFocused && !isViewer && (
        <CustomHtml
          style={{ pointerEvents: 'none' }}
          position={[0, height / 2, 0.5]}
        >
          <WorkbenchElementExtra
            element={element}
            handleAction={handleAction}
          />
        </CustomHtml>
      )}
    </>
  );
};

const CreateDrawingPlaceholder = (props: {
  workbenchId: string;
  placeholder: ClientSideWorkbenchElementPlaceholder;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
  singleFocused: boolean;
}) => {
  const { workbenchId, placeholder, setEditingElementId } = props;
  const isViewer = useIsWorkbenchViewer();
  const completeOnboardingStep = useCompleteOnboardingStep();
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};

  const handleDoubleClick = async () => {
    if (isViewer) return;

    await urqlClient.mutation(CreateWorkbenchElementsMutation, {
      createDrawingsInput: [
        {
          id: placeholder.id,
          workbenchId,
          width: 1920,
          height: 1080,
          image: null,
        },
      ],
      createWorkbenchElementsPlaceholderInput: [],
      createWorkbenchElementsImg2ImgInput: [],
      createWorkbenchElementsTextInput: [],
      createWorkbenchElementsMixInput: [],
      createWorkbenchElementsSectionInput: [],
      createWorkbenchElementsPaletteInput: [],
      createCompositeScenesInput: [],
    });

    if (!onboardingStatus[OnboardingStepName.EnterSketch]) {
      const promptSize = getElementDefaultSize('WorkbenchElementImg2Img');

      await urqlClient.mutation(CreateWorkbenchElementsMutation, {
        createWorkbenchElementsImg2ImgInput: [
          {
            id: uuidv4(),
            x: placeholder.x + 450,
            y: placeholder.y,
            width: promptSize.x,
            height: promptSize.y,
            workbenchId,
            zIndex: placeholder.zIndex + 1,
            prompt: '',
            publicPaletteId: PublicPalette.generalV2,
            sourceImageInfluence: 1,
            imageInferenceType: ImageInferenceType.Render,
            sourceDrawingId: placeholder.id,
          },
        ],
        createDrawingsInput: [],
        createWorkbenchElementsPlaceholderInput: [],
        createWorkbenchElementsTextInput: [],
        createWorkbenchElementsMixInput: [],
        createWorkbenchElementsSectionInput: [],
        createWorkbenchElementsPaletteInput: [],
        createCompositeScenesInput: [],
      });
    }

    completeOnboardingStep(OnboardingStepName.EnterSketch);

    setEditingElementId(placeholder.id);
  };

  return (
    <CreateDrawingPlaceholderContainer onDoubleClick={handleDoubleClick}>
      <CreateImagePlaceholderIcon
        $singleFocused={props.singleFocused}
        onClick={handleDoubleClick}
      />
      <CreateImagePlaceholderText
        type="sh1"
        style={{ fontSize: 16, fontWeight: 500 }}
        $singleFocused={props.singleFocused}
      >
        Double-click to start sketching
      </CreateImagePlaceholderText>
    </CreateDrawingPlaceholderContainer>
  );
};

const CreateImagePlaceholderIcon = styled(ImageEdit)<{
  $singleFocused: boolean;
}>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${(p) =>
    p.$singleFocused ? p.theme.text.primary : p.theme.text.primaryAccent};
  transition: 0.2s color ease;
`;
const CreateImagePlaceholderText = styled(Text)<{ $singleFocused: boolean }>`
  color: ${(p) =>
    p.$singleFocused ? p.theme.text.primary : p.theme.text.primaryAccent};
  transition: 0.2s color ease;
`;

const CreateDrawingPlaceholderContainer = styled.div`
  background-color: white;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  user-select: none;
  &:hover ${CreateImagePlaceholderText}, &:hover ${CreateImagePlaceholderIcon} {
    color: ${(p) => p.theme.text.primary};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  position: relative;
  pointer-events: none;
`;

const WorkbenchPlaceholderLoadingImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.8) blur(5px);
`;

const WorkbenchPlaceholderLoading = (props: {
  element: ClientSideWorkbenchElementPlaceholder;
}) => {
  return (
    <>
      <LoadingContainer>
        <VizcomLogoAnimated
          style={{ width: 64, height: 64, zIndex: 10, position: 'relative' }}
          color="rgb(200, 200, 200)"
        />
        {props.element.loadingImagePath && (
          <WorkbenchPlaceholderLoadingImage
            crossOrigin="anonymous"
            src={props.element.loadingImagePath}
          />
        )}
        {props.element.rawLoadingImage && (
          <WorkbenchPlaceholderLoadingImage
            as={RawImage}
            data={props.element.rawLoadingImage}
          />
        )}
      </LoadingContainer>
    </>
  );
};
