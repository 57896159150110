import { useEffect } from 'react';
import { identifyUser } from './a_n_a_l_i_t_i_c_s';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';

export const useIdentifyMixpanelUser = () => {
  const user = useCurrentUser();
  useEffect(() => {
    if (!user.data) {
      return;
    }
    console.log('identifying user', user.data);
    identifyUser(user?.data.id, {
      $email: user?.data.email,
      $name: user?.data.name,
      userId: user?.data.id,
      email: user?.data.email,
      name: user?.data.name,
      occupation: user?.data.occupation,
      description: user?.data.description,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?.id]);
};
