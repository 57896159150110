/**
 * FIXME Move to utils / split into files
 */
import { Object3D } from 'three';
import { ClientSideWorkbenchElementData } from '../lib/clientState';

type CursorKeyword =
  | 'alias'
  | 'all-scroll'
  | 'auto'
  | 'cell'
  | 'col-resize'
  | 'context-menu'
  | 'copy'
  | 'crosshair'
  | 'default'
  | 'e-resize'
  | 'ew-resize'
  | 'grab'
  | 'grabbing'
  | 'help'
  | 'move'
  | 'n-resize'
  | 'ne-resize'
  | 'nesw-resize'
  | 'ns-resize'
  | 'nw-resize'
  | 'nwse-resize'
  | 'no-drop'
  | 'none'
  | 'not-allowed'
  | 'pointer'
  | 'progress'
  | 'row-resize'
  | 's-resize'
  | 'se-resize'
  | 'sw-resize'
  | 'text'
  | 'url'
  | 'w-resize'
  | 'wait'
  | 'zoom-in'
  | 'zoom-out';

export interface WorkbenchElementContainerUserData {
  workbenchObjectType: 'container';
  elementId: string;
  elementTypename: ClientSideWorkbenchElementData['__typename'];
  elementWidth: number;
  elementHeight: number;
  elementX: number;
  elementY: number;
  elementZIndex: number;

  cursor?: CursorKeyword;
  paletteSourceImageCount?: number;
  paletteStatus?: string;

  multiFocused: boolean;
  singleFocused: boolean;

  resizeWidth?: number;

  fixedAspectRatio?: boolean;
}
type WorkbenchElementContainerObject3D = Object3D & {
  userData: WorkbenchElementContainerUserData;
};

export interface WorkbenchMultiFocusedExtraUserData {
  workbenchObjectType: 'multi-focused-element-container';
  elementX: number;
  elementY: number;
  elementWidth: number;
  elementHeight: number;
}

export type WorkbenchObjectUserData =
  | WorkbenchElementContainerUserData
  | WorkbenchMultiFocusedExtraUserData;

export type WorkbenchObjectObject3D = Object3D & {
  userData: WorkbenchObjectUserData;
};

const isWorkbenchObjectContainerUserData = (
  userData: Record<string, any>
): userData is WorkbenchObjectUserData => {
  return !!userData?.workbenchObjectType;
};

// traverse the object parent until finding one with a workbench userdata and return this object
export const findNearestParentObjectWithWorkbenchElementUserData = (
  object: Object3D
) => {
  let target = object as Object3D | null;
  while (target) {
    if (
      'stopUserDataPropagation' in target.userData &&
      target.userData.stopUserDataPropagation
    ) {
      return null;
    }
    if (
      isWorkbenchObjectContainerUserData(target.userData) &&
      target.userData.workbenchObjectType === 'container'
    ) {
      return target as WorkbenchElementContainerObject3D;
    }
    target = target.parent;
  }
  return null;
};

// traverse object children until finding one which matches the userData provided
export const findChildByWorkbenchElementUserData = (
  object: Object3D,
  userDataFinder: (userData: WorkbenchElementContainerUserData) => boolean
): WorkbenchElementContainerObject3D | null => {
  if (
    isWorkbenchObjectContainerUserData(object.userData) &&
    object.userData.workbenchObjectType === 'container' &&
    userDataFinder(object.userData)
  ) {
    return object as WorkbenchElementContainerObject3D;
  }
  for (const child of object.children) {
    const childMatch = findChildByWorkbenchElementUserData(
      child,
      userDataFinder
    );
    if (childMatch) {
      return childMatch;
    }
  }
  return null;
};

export const filterChildByWorkbenchObjectUserData = (
  object: Object3D,
  userDataFinder: (userData: WorkbenchObjectUserData) => boolean
) => {
  const found = [] as WorkbenchObjectObject3D[];
  object.traverse((child) => {
    if (
      isWorkbenchObjectContainerUserData(child.userData) &&
      userDataFinder(child.userData)
    ) {
      found.push(child as WorkbenchObjectObject3D);
    }
  });

  return found;
};

export const filterChildByWorkbenchElementUserData = (
  object: Object3D,
  userDataFinder: (userData: WorkbenchElementContainerUserData) => boolean
) => {
  return filterChildByWorkbenchObjectUserData(object, (userData) => {
    if (userData.workbenchObjectType === 'container') {
      return userDataFinder(userData);
    }
    return false;
  }) as WorkbenchElementContainerObject3D[];
};

export const getBoundingBoxFromWorkbenchObjects = (
  objects: WorkbenchObjectObject3D[]
) => {
  return objects.reduce(
    (acc, object) => {
      const userData = object.userData as WorkbenchElementContainerUserData;
      const left = userData.elementX - userData.elementWidth / 2;
      const right = userData.elementX + userData.elementWidth / 2;
      const top = userData.elementY + userData.elementHeight / 2;
      const bottom = userData.elementY - userData.elementHeight / 2;

      return {
        left: Math.min(acc.left, left),
        right: Math.max(acc.right, right),
        top: Math.max(acc.top, top),
        bottom: Math.min(acc.bottom, bottom),
      };
    },
    {
      left: Infinity,
      right: -Infinity,
      top: -Infinity,
      bottom: Infinity,
    }
  );
};
