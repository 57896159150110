import { v4 as uuidv4 } from 'uuid';
import { addToast } from '@vizcom/shared-ui-components';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { assertExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { MultiDeleteAction } from './multiDeleteAction';
import {
  urqlClient,
  CreateWorkbenchElementsMutation,
  drawingsByIds,
  publishTrackingEvent,
  InsertImagesToPaletteMutation,
  WorkbenchElementDrawingData,
  WorkbenchElementPaletteData,
} from '@vizcom/shared/data-access/graphql';
import { getElementSize } from '../../../components/helpers';
import { omit } from 'lodash';
import { WorkbenchElementData } from '../../../components/WorkbenchKeyboardShortcuts';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import { WorkbenchEventName } from '@vizcom/shared/data-shape';

type NewElementData = (WorkbenchElementData & {
  newId: string;
  newX: number;
  newY: number;
  newZIndex: number;
})[];

export const MultiDuplicateAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'duplicateElements';
    elementIds: string[];
    newElementIds: string[];
  },
  {
    elementsByTypename: Record<
      ClientSideWorkbenchElementData['__typename'],
      NewElementData
    >;
  }
> = {
  type: 'duplicateElements',
  optimisticUpdater: ({ meta }, elements) => {
    assertExists(meta.custom);
    Object.values(meta.custom.elementsByTypename).forEach((elementsOfType) => {
      elementsOfType.forEach((newElement) => {
        const { newId, newX, newY, newZIndex } = newElement;
        const existingElement = elements.find(
          (element) => element.id === newId
        );
        if (existingElement) {
          return;
        }

        if (newElement.__typename === 'WorkbenchElementPalette') {
          elements.push({
            ...newElement,
            status: 'idle',
            id: newId,
            x: newX,
            y: newY,
            zIndex: newZIndex,
          });
          return;
        }

        elements.push({
          ...newElement,
          id: newId,
          x: newX,
          y: newY,
          zIndex: newZIndex,
        });
      });
    });
  },
  remoteUpdater: async ({ meta }, workbenchId) => {
    assertExists(meta.custom);
    const elementsByTypename = meta.custom.elementsByTypename;
    if (!elementsByTypename) {
      return;
    }

    const elementsIds = elementsByTypename['Drawing'].map((el) => el.id);
    const { data } = await urqlClient.query(drawingsByIds, {
      ids: elementsIds,
    });
    const drawings = data?.drawings?.nodes ?? [];

    const res = await urqlClient.mutation(CreateWorkbenchElementsMutation, {
      createDrawingsInput: elementsByTypename['Drawing'].map(
        ({ newId, newX, newY, newZIndex, ...data }) => {
          const sourceDrawing = drawings.find(
            (drawing) => drawing.id === data.id
          );
          assertExists(sourceDrawing, 'Source drawing cannot be found');

          const newLayersIds = Object.fromEntries(
            sourceDrawing.layers.nodes.map((layer) => [layer.id, uuidv4()])
          );
          return {
            ...omit(data, [
              '__typename',
              'updatedAt',
              'createdAt',
              'drawingHeight',
              'drawingWidth',
              'contentLayersCount',
            ]),
            width: sourceDrawing?.width,
            height: sourceDrawing?.height,
            layersOrder: (sourceDrawing.layersOrder as string[] | null)?.map(
              (layerId) => newLayersIds[layerId]
            ),
            layers: sourceDrawing.layers.nodes.map((layer) => ({
              ...layer,
              id: newLayersIds[layer.id],
              drawingId: newId,
            })),
            workbenchId,
            id: newId,
            x: newX || data.x,
            y: newY || data.y,
            zIndex: newZIndex || data.zIndex,
            thumbnailPath: sourceDrawing.thumbnailPath,
          };
        }
      ),
      createWorkbenchElementsPlaceholderInput: elementsByTypename[
        'WorkbenchElementPlaceholder'
      ].map(({ newId, newX, newY, newZIndex, ...data }) => ({
        ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
        workbenchId,
        id: newId,
        x: newX || data.x,
        y: newY || data.y,
        zIndex: newZIndex || data.zIndex,
        type: data.type || 'drawing',
      })),
      createWorkbenchElementsImg2ImgInput: elementsByTypename[
        'WorkbenchElementImg2Img'
      ].map(({ newId, newX, newY, newZIndex, ...data }) => ({
        ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
        workbenchId,
        id: newId,
        x: newX || data.x,
        y: newY || data.y,
        zIndex: newZIndex || data.zIndex,
      })),
      createWorkbenchElementsTextInput: elementsByTypename[
        'WorkbenchElementText'
      ].map(({ newId, newX, newY, newZIndex, ...data }) => ({
        ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
        workbenchId,
        id: newId,
        x: newX || data.x,
        y: newY || data.y,
        zIndex: newZIndex || data.zIndex,
      })),
      createWorkbenchElementsMixInput: elementsByTypename[
        'WorkbenchElementMix'
      ].map(({ newId, newX, newY, newZIndex, ...data }) => ({
        ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
        workbenchId,
        id: newId,
        x: newX || data.x,
        y: newY || data.y,
        zIndex: newZIndex || data.zIndex,
      })),
      createWorkbenchElementsPaletteInput: elementsByTypename[
        'WorkbenchElementPalette'
      ].map(({ newId, newX, newY, newZIndex, ...data }) => ({
        ...omit(data, [
          '__typename',
          'updatedAt',
          'createdAt',
          'sourceImages',
          'status',
          'failureReason',
        ]),
        workbenchId,
        id: newId,
        x: newX || data.x,
        y: newY || data.y,
        zIndex: newZIndex || data.zIndex,
        status: 'idle',
      })),
      createCompositeScenesInput: elementsByTypename['CompositeScene'].map(
        ({ newId, newX, newY, newZIndex, ...data }) => ({
          ...omit(data, [
            '__typename',
            'updatedAt',
            'createdAt',
            'thumbnailPath',
          ]),
          workbenchId,
          id: newId,
          x: newX || data.x,
          y: newY || data.y,
          zIndex: newZIndex || data.zIndex,
          thumbnailPath:
            'thumbnailPath' in data && typeof data.thumbnailPath === 'string'
              ? data.thumbnailPath
              : undefined,
        })
      ),
      createWorkbenchElementsSectionInput: [],
    });

    if (res?.error) {
      throw new Error(
        `Error while duplicating elements, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    if (elementsByTypename.WorkbenchElementPalette.length) {
      const paletteSourceImages =
        elementsByTypename.WorkbenchElementPalette.map((el) => {
          if (el.__typename !== 'WorkbenchElementPalette') {
            return [];
          }
          return el.sourceImages.nodes.map((image) => ({
            ...image,
            workbenchElementPaletteId: el.newId,
          }));
        }).filter((images) => images.length > 0);

      const responses = await Promise.all(
        paletteSourceImages.map((sourceImages) =>
          urqlClient.mutation(InsertImagesToPaletteMutation, {
            input: {
              id: sourceImages[0].workbenchElementPaletteId,
              sourceImages: sourceImages.map((image) => ({
                id: uuidv4(),
                image: image.imagePath,
              })),
              workbenchId,
            },
          })
        )
      );

      const errorResponses = responses.filter((res) => res.error);
      if (errorResponses.length > 0) {
        const errorMessages = errorResponses.map(
          (res) => res?.error?.graphQLErrors[0]?.message ?? res?.error?.message
        );
        throw new Error(
          `Error while duplicating images to palette(s), please retry. ${errorMessages.join(
            ', '
          )}`
        );
      }
    }
    trackEvent('Duplicate Elements');
    publishTrackingEvent({
      type: WorkbenchEventName.DUPLICATE_ELEMENTS,
      data: {
        workbenchIds: [workbenchId],
        elementIds: elementsIds,
      },
    });
  },
  metaConstructor: (payload, elements) => {
    // get offset for new elements
    const { minX, minY, maxX, maxY } = payload.elementIds.reduce(
      (acc, id) => {
        const el = elementById(elements, id);
        if (!el) {
          return acc;
        }
        const elSize = getElementSize(el);
        return {
          minX: Math.min(acc.minX, el.x),
          minY: Math.min(acc.minY, el.y),
          maxX: Math.max(acc.maxX, el.x + elSize.width),
          maxY: Math.max(acc.maxY, el.y + elSize.height),
        };
      },
      {
        minX: Infinity,
        minY: Infinity,
        maxX: -Infinity,
        maxY: -Infinity,
      }
    );
    const width = maxX - minX;
    const height = maxY - minY;
    const [xOffset, yOffset] = [
      width < height ? width + 10 : 0,
      width < height ? 0 : height + 10,
    ];

    const elementsByTypename = payload.elementIds.reduce(
      (acc, id, index) => {
        const el = elementById(elements, id);
        if (!el) {
          return acc;
        }
        if (
          el.__typename === 'WorkbenchElementPlaceholder' &&
          el.failureReason
        ) {
          addToast(
            `The image you're trying to duplicate has thrown an error, it will be ignored`,
            {
              type: 'danger',
            }
          );
          return acc;
        }

        if (
          el.__typename === 'WorkbenchElementPlaceholder' &&
          el.type !== 'drawing'
        ) {
          addToast(
            `The image you're trying to duplicate is still loading, it will be ignored`,
            {
              type: 'danger',
            }
          );
          return acc;
        }

        const newElement = {
          ...el,
          newId: payload.newElementIds[index],
          newX: el.x + xOffset,
          newY: el.y - yOffset,
          newZIndex: Math.max(...elements.map((el) => el.zIndex)) + index + 1,
        };

        if (
          el.__typename === 'Drawing' ||
          el.__typename === 'WorkbenchElementPalette'
        ) {
          (
            newElement as
              | WorkbenchElementDrawingData
              | WorkbenchElementPaletteData
          ).name = addDuplicateNumber(
            el.name,
            elements
              .filter((el) => el.__typename === newElement.__typename)
              .map((el) => (el as any).name)
          );
        }

        acc[el.__typename].push(newElement);
        return acc;
      },
      {
        Drawing: [] as NewElementData,
        WorkbenchElementPlaceholder: [] as NewElementData,
        WorkbenchElementImg2Img: [] as NewElementData,
        WorkbenchElementImg2Mesh: [] as NewElementData,
        WorkbenchElementPalette: [] as NewElementData,
        WorkbenchElementText: [] as NewElementData,
        CompositeScene: [] as NewElementData,
        WorkbenchElementMix: [] as NewElementData,
        WorkbenchElementSection: [] as NewElementData,
      }
    );

    return {
      custom: {
        elementsByTypename,
      },
    };
  },
  undoConstructor: ({ payload }) => {
    const undoPayloads: SyncedActionPayloadFromType<typeof MultiDeleteAction> =
      {
        type: 'deleteElements',
        elementIds: payload.newElementIds,
      };
    return undoPayloads;
  },
};

function addDuplicateNumber(name: string, names: string[]): string {
  const nameExists = (name: string) => names.some((n) => n === name);

  let count = 1;
  let newName = `${name} (${count})`;

  while (nameExists(newName)) {
    count++;
    newName = `${name} (${count})`;
  }

  return newName;
}
