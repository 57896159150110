import {
  Button,
  EmailMultiInput,
  LoadingLogo,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  RecoilPaywallModal,
  Surface,
  Text,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { FormEvent, useState } from 'react';
import { TeamVisibilityPicker } from './TeamVisibilityPicker';
import {
  useCreateTeam,
  useInviteToTeam,
  useOrganization,
} from '@vizcom/shared/data-access/graphql';
import { featureLimitForOrganizationSubscription } from '@vizcom/shared/plans-limit';

interface CreateTeamModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  autocompleteOptions: string[];
}

export const CreateTeamModal = (props: CreateTeamModalProps) => {
  const { data: organization, fetching } = useOrganization(
    props.organizationId
  );
  const [name, setName] = useState('');
  const [inviteEmailsValue, setInviteEmailsValue] = useState([] as string[]);
  const [publicInOrganization, setPublicInOrganization] = useState(true);
  const { value: enabledBySubscriptionPlan, loading: loadingFeatureLimit } =
    featureLimitForOrganizationSubscription(organization, 'teamCreation');
  const [inviteToTeamRes, inviteToTeam] = useInviteToTeam();

  const [createTeamRes, createTeam] = useCreateTeam();

  const close = () => {
    props.setOpen(false);
    setName('');
    setPublicInOrganization(true);
    setInviteEmailsValue([]);
  };

  const handleCreateTeam = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await createTeam({
      input: {
        team: {
          name,
          organizationId: props.organizationId,
          publicInOrganization,
        },
      },
    });
    if (res.error || !res.data?.createTeam?.team?.id) {
      return addToast('Error while creating team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast(`Team created successfully`);

    if (inviteEmailsValue.length) {
      const resTeam = await inviteToTeam({
        input: {
          emails: inviteEmailsValue,
          teamId: res.data?.createTeam?.team?.id,
        },
      });
      if (resTeam.error) {
        return addToast('Error while inviting users', {
          type: 'danger',
          secondaryText: formatErrorMessage(resTeam.error),
        });
      }
    }
    close();
  };

  if (fetching || loadingFeatureLimit) {
    return <LoadingLogo />;
  }

  if (enabledBySubscriptionPlan === false) {
    return (
      <RecoilPaywallModal
        isOpen={props.open}
        onClose={() => props.setOpen(false)}
      />
    );
  }

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.setOpen}
      loading={createTeamRes.fetching || fetching || inviteToTeamRes.fetching}
    >
      <ModalHeader>
        <ModalTitle>Create new team</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>

      <form onSubmit={handleCreateTeam}>
        <ModalContent>
          <Text block>
            A team is a space to organize files and permissions amongst specific
            members of your workspace
          </Text>

          <Text block type="sh2" style={{ marginBottom: 16, marginTop: 24 }}>
            Name
          </Text>
          <TextInput
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="New team"
            required
          />

          <Text block type="sh2" style={{ marginBottom: 16, marginTop: 24 }}>
            Invite members
          </Text>
          <Surface color="e0">
            <EmailMultiInput
              emails={inviteEmailsValue}
              setEmails={setInviteEmailsValue}
              autofocus={false}
              autoCompleteOptions={props.autocompleteOptions}
            />
          </Surface>

          <Text block type="sh2" style={{ marginBottom: 16, marginTop: 24 }}>
            Visibility
          </Text>
          <TeamVisibilityPicker
            publicInOrganization={publicInOrganization}
            setPublicInOrganization={setPublicInOrganization}
          />
        </ModalContent>
        <ModalActions>
          <Button variant="outline" type="button" onClick={close}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Create
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
