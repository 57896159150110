import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { useContextBridge } from '@react-three/drei';
import { PropsWithChildren } from 'react';
import {
  UNSAFE_LocationContext,
  UNSAFE_NavigationContext,
} from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { context as ThreeContext, useThree } from '@react-three/fiber';
import { Context as ClientContext } from 'urql';
import { LayersCompositorContext } from '../studio/DrawingCompositor/LayersCompositor/context';
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from 'recoil';
import { isViewerContext } from '../../lib/utils';
import { SelectionApiContext } from '../studio/selection/useSelectionApi';

// Very simple subset of CustomHtml to show a DOM element above the scene, without any position tracking
// this is very useful to keep being able to use the threejs context inside these elements while rendering dom (instead of threejs elements)
// compared to CustomHtml, it doesn't do any kind of tracking
export const HtmlOverlay = (
  props: PropsWithChildren<{
    noInjectThreeContext?: boolean;
  }>
) => {
  const { gl, events } = useThree();

  const [el] = React.useState(() => document.createElement('div'));

  // Stop propagation of wheel events to prevent leaking into the canvas controls
  el.onwheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
    }
    e.stopPropagation();
  };

  // Append to the connected element parent, this let's us keep the same parent div while separating the zIndex context from the rest of the canvas
  // and keeping events separated between the two
  const target = (events.connected?.parentNode ||
    gl.domElement.parentNode) as HTMLElement;

  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();

  const contexts = [
    ThemeContext,
    UNSAFE_LocationContext,
    UNSAFE_NavigationContext,
    isViewerContext,
    ClientContext,
    LayersCompositorContext,
    SelectionApiContext,
    props.noInjectThreeContext ? null : ThreeContext,
  ].filter(Boolean) as Array<React.Context<unknown>>;

  const ContextBridge = useContextBridge(...contexts);

  const root = React.useRef<ReactDOM.Root>();

  React.useLayoutEffect(() => {
    const currentRoot = ReactDOM.createRoot(el);
    root.current = currentRoot;
    target.appendChild(el);
    el.style.cssText = `position:absolute; top:0; left:0; width: 100vw; width: 100dvw; height: 100vh; height: 100dvh; pointer-events: none;`;
    return () => {
      if (target) target.removeChild(el);
      currentRoot.unmount();
    };
  }, [target]);

  React.useLayoutEffect(() => {
    root.current?.render(
      <RecoilBridge>
        <ContextBridge>{props.children}</ContextBridge>
      </RecoilBridge>
    );
  });

  return null;
};
