import { ContextMenuItem, addToast } from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import {
  handleAddImagesToPalette,
  handleDownloadPaletteImages,
} from '../../elements/palette/helpers';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';

export const WorkbenchElementPaletteContextMenuItems = ({
  element,
  handleAction,
}: {
  element: ClientSideWorkbenchElementPalette;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  return (
    <>
      {element.status === 'ready' && (
        <ContextMenuItem
          onClick={() => {
            navigator.clipboard.writeText(element.tags?.join(', ') || '');
            addToast('Tags copied to clipboard');
          }}
        >
          Copy tags
        </ContextMenuItem>
      )}
      <ContextMenuItem
        onClick={() =>
          handleAddImagesToPalette(
            element.id,
            element.sourceImages.nodes.length,
            handleAction
          )
        }
      >
        Upload images
      </ContextMenuItem>
      {element.sourceImages?.nodes.length > 0 && (
        <ContextMenuItem onClick={() => handleDownloadPaletteImages(element)}>
          Download images
        </ContextMenuItem>
      )}
    </>
  );
};
