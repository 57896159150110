import { useState } from 'react';

import {
  Button,
  CloseIcon,
  NumberInput,
  RangeInput,
} from '@vizcom/shared-ui-components';
import checkeredThumbnail from '../../studio/create/assets/checkeredThumbnail.png';
import styled from 'styled-components';
import { MixSourceDrawing } from './WorkbenchElementMix';

interface MixSliderProps {
  source: MixSourceDrawing;
  image?: string | null | undefined;
  setWeight: (weight: number, drawingId: string) => void;
  deleteDrawing: (drawingId: string) => void;
  setSelectedSourceId: (sourceId: string | null) => void;
}

const MixSlider = ({
  source,
  image,
  setWeight,
  deleteDrawing,
  setSelectedSourceId,
}: MixSliderProps) => {
  const [weightValue, setWeightValue] = useState<number>(
    Math.round(source.weight * 100)
  );

  return (
    <WeightContainer>
      <ThumbnailContainer>
        <Checker src={checkeredThumbnail} alt="" draggable={false} />
        {image && (
          <SourceThumbnail
            crossOrigin="anonymous"
            src={image}
            alt=""
            draggable={false}
          />
        )}
      </ThumbnailContainer>
      <RangeInput
        variant="primary"
        thick
        snapCenter
        value={weightValue}
        min={0}
        max={100}
        step={1}
        onChange={(e) => setWeightValue(+e.target.value)}
        onMouseDown={() => setSelectedSourceId(source.sourceDrawingId)}
        onMouseUp={() => setSelectedSourceId(null)}
        onPointerMove={(e) => e.stopPropagation()}
        onMouseLeave={() =>
          setWeight(
            parseFloat((weightValue / 100).toFixed(2)),
            source.sourceDrawingId
          )
        }
      />
      <NumberInput
        value={weightValue}
        setValue={setWeightValue}
        min={0}
        max={100}
        dragArrows={false}
      />
      <Button
        variant="transparent"
        size="iconSquared"
        onClick={() => deleteDrawing(source.sourceDrawingId)}
        style={{
          padding: '4px',
          width: '30px',
        }}
      >
        <DeleteDrawing />
      </Button>
    </WeightContainer>
  );
};

export const DisabledMixSlider = () => {
  return (
    <WeightContainer>
      <ThumbnailContainer>
        <Checker
          $disabled={true}
          src={checkeredThumbnail}
          alt=""
          draggable={false}
        />
      </ThumbnailContainer>
      <RangeInput
        variant="primary"
        thick
        snapCenter
        disabled
        value={50}
        min={0}
        max={100}
        step={1}
        onChange={() => {}}
      />
      <NumberInput
        disabled
        value={50}
        setValue={() => {}}
        min={0}
        max={100}
        dragArrows={false}
      />
      <Button
        disabled
        variant="transparent"
        size="iconSquared"
        style={{
          padding: '4px',
          width: '30px',
        }}
      >
        <DeleteDrawing />
      </Button>
    </WeightContainer>
  );
};
export default MixSlider;

const WeightContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 48px 1fr 55px 30px;
  gap: 10px;

  span {
    color: ${({ theme }) => theme.text.info};
  }
`;

const DeleteDrawing = styled(CloseIcon)`
  text-align: center;
  border-radius: 20px;
  padding: 4px 0px;
  width: 50px;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
`;

const Checker = styled.img<{ $disabled?: boolean }>`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  filter: ${({ $disabled }) => ($disabled ? 'brightness(0.3)' : 'none')};
`;

const SourceThumbnail = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 8px;
  position: relative;
  z-index: 1;
`;
