import {
  urqlClient,
  UpdateDrawingMutation,
} from '@vizcom/shared/data-access/graphql';
import { SyncedActionType } from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';

export const UpdateDrawingAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'updateDrawing';
    name: string;
  }
> = {
  type: 'updateDrawing',
  optimisticUpdater: ({ payload }, drawing) => {
    drawing.name = payload.name;
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    await urqlClient.mutation(UpdateDrawingMutation, {
      id: drawingId,
      patch: {
        name: payload.name,
      },
    });
  },
  undoConstructor: ({ payload }, drawing) => {
    return {
      type: 'updateDrawing',
      name: drawing.name,
    };
  },
};
