import { useTheme } from 'styled-components';
import { DoubleSide } from 'three';
import { PromptHistoryItem } from '../history/WorkbenchStudioHistory';
import { ActiveMask, MaskDisplayMode } from '../selection/ActiveMask';
import { useImageTextures } from '../../../lib/useImageTexture';

export const InferencePreview = ({
  imagePath,
  visible,
  scale,
  selectedPromptHistoryItem,
}: {
  imagePath?: string | null;
  visible: boolean;
  scale: [number, number];
  selectedPromptHistoryItem: PromptHistoryItem;
}) => {
  const theme = useTheme();
  const path = imagePath || selectedPromptHistoryItem?.output?.imagePath;
  const [image, maskImage] = useImageTextures([
    path,
    selectedPromptHistoryItem?.prompt.maskImagePath,
  ]);

  if (!image) {
    return null;
  }

  return (
    <group renderOrder={2} visible={visible}>
      {maskImage && (
        <group renderOrder={2}>
          <ActiveMask
            drawingSize={scale}
            maskTexture={maskImage}
            mode={MaskDisplayMode.MARCHING_ANTS}
          />
        </group>
      )}

      <group scale={[scale[0], scale[1], 1]} renderOrder={1}>
        <mesh>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            map={image}
            transparent
            depthTest={false}
            side={DoubleSide}
          />
        </mesh>
      </group>
      <group scale={[scale[0] + 4, scale[1] + 4, 1]} renderOrder={0}>
        <mesh>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            depthTest={false}
            color={theme.primary.default}
            side={DoubleSide}
          />
        </mesh>
      </group>
    </group>
  );
};
