import styled from 'styled-components';
import { zIndexOrder } from '../utils/consts';

export const ToolbarContainer = styled.div`
  z-index: ${zIndexOrder.UiZIndex};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 1rem;
  left: max(50%, 480px);
  transform: translateX(-50%);
  gap: 16px;
  pointer-events: none;
`;

export const Toolbar = styled.div<{ $disabled?: boolean }>`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  gap: 0.5rem;
  background: ${({ theme }) => theme.surface.e0};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};
  height: 48px;
`;

export const ToolbarDivider = styled.div`
  width: 1px;
  height: 16px;
  align-self: center;
  background-color: ${(p) => p.theme.surface.e2};
`;
