import {
  urqlClient,
  TriggerWorkbenchElementImg2ImgMutation,
} from '@vizcom/shared/data-access/graphql';
import { addToast } from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import { SyncedActionType } from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';

export const TriggerAiImg2ImgAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'triggerAiImg2Img';
    elementId: string;
    // id of newly created placeholder which will then be replaced by a drawing element with the image result
    placeholderId: string;
    x: number;
    y: number;
    width: number;
    height: number;
    zIndex: number;
  }
> = {
  type: 'triggerAiImg2Img',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementImg2Img') {
      if (elementById(elements, payload.placeholderId)) {
        // server already responded with this element, nothing to do locally
        return;
      }
      elements.push({
        __typename: 'WorkbenchElementPlaceholder',
        updatedAt: '0',
        id: payload.placeholderId,
        type: 'placeholder',
        x: payload.x,
        y: payload.y,
        width: payload.width,
        height: payload.height,
        zIndex: payload.zIndex,
      });
    }
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(
      TriggerWorkbenchElementImg2ImgMutation,
      {
        input: {
          id: payload.elementId,
          placeholderId: payload.placeholderId,
          height: payload.height,
          width: payload.width,
          x: payload.x,
          y: payload.y,
          zIndex: payload.zIndex,
        },
      }
    );
    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0].extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0].extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        addToast(
          `You have been generating too many images, please wait ${(
            rateLimitInfo.resetInMs / 1000
          ).toFixed(0)}s before trying again.`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'organization_forbidden_keyword'
      ) {
        addToast(
          `Your prompt contains a keyword forbidden by your workspace administrator. Please change it. If you believe it's a mistake, contact your workspace administrator.`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'trademarked_word'
      ) {
        addToast(
          `This prompt contains a trademarked word, if you think this is a mistake, contact us at support@vizcom.ai`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'offensive_word'
      ) {
        addToast(
          `This prompt contains offensive words, if you think this is a mistake, contact us at support@vizcom.ai`,
          {
            type: 'danger',
          }
        );
        return;
      }
      throw new Error(
        `Error using AI service, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Generate Image', {
      type: 'workbenchElementImg2Img',
    });
  },
};
