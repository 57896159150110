import React, { useEffect, useState } from 'react';
import { CustomText } from './CustomText';
import { useTheme } from 'styled-components';
import { useThree } from '@react-three/fiber';
import { MAX_Z_POSITION, screenPositionToWorld } from '../helpers';
import { OrthographicCamera } from 'three';

export const FloatingMouseText = ({ children }: { children: string }) => {
  const theme = useTheme();
  const camera = useThree((s) => s.camera);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const position = screenPositionToWorld(
        [e.clientX, e.clientY],
        camera as OrthographicCamera
      );
      setPosition({ x: position[0], y: position[1] });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <CustomText
      userData={{ globalCursor: 'crosshair' }}
      font="Inter"
      fontSize={16}
      position={[position.x, position.y, MAX_Z_POSITION]}
      color={theme.text.info}
      content={children}
      fontWeight={'medium'}
      anchorX="left"
      anchorY="top"
    />
  );
};
