import { StatsGl } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { button, useControls } from 'leva';

export const WorkbenchDebugger = () => {
  const camera = useThree((s) => s.camera);

  // const [, set, get] = useControls(() => ({
  //   camera: {
  //     value: { x: 0, y: 0 },
  //     // onChange: ({ x, y }) => {
  //     //   const controls = getState().controls as unknown as ControlsProto;
  //     //   controls.target.setX(x);
  //     //   controls.target.setY(y);
  //     //   controls.update();
  //     // },
  //   },
  //   cameraZoom: {
  //     value: 1,
  //   },
  //   logCamera: button(() => {
  //     console.log(camera);
  //   }),
  //   // resetCamera: button(() => {
  //   //   const controls = getState().controls as unknown as ControlsProto;
  //   //   camera.zoom = 1;
  //   //   camera.updateProjectionMatrix();
  //   //   controls.target.setX(0);
  //   //   controls.target.setY(0);
  //   //   controls.update();
  //   //   invalidate();
  //   // }),
  //   // resetCamera2: button(() => {
  //   //   const controls = getState().controls as unknown as ControlsProto;
  //   //   camera.zoom = 2.2;
  //   //   camera.updateProjectionMatrix();
  //   //   controls.target.setX(100);
  //   //   controls.target.setY(100);
  //   //   controls.update();
  //   //   invalidate();
  //   // }),
  // }));

  // useFrame((s) => {
  //   if (
  //     Math.abs(get('camera').x - s.camera.position.x) > 1 ||
  //     Math.abs(get('camera').y - s.camera.position.y) > 1
  //   ) {
  //     set({
  //       camera: {
  //         x: s.camera.position.x,
  //         y: s.camera.position.y,
  //       },
  //     });
  //   }
  //   if (Math.abs(get('cameraZoom') - s.camera.zoom) > 0.1) {
  //     set({
  //       cameraZoom: s.camera.zoom,
  //     });
  //   }
  // });

  return <StatsGl />;
};
