import styled from 'styled-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet-async';
import {
  FullPageDarkLoader,
  resizeImageToPixelCount,
  useCompleteOnboardingStep,
  useKeyboardShortcut,
  usePreventBrowserZoom,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from './lib/useWorkbenchSyncedState';
import { WorkbenchFileMenu } from './components/workbenchMenu/WorkbenchMenu';
import { WorkbenchToolbar } from './components/toolbar/WorkbenchToolbar';
import { RootState } from '@react-three/fiber';
import { useWorkbenchMultiplayer } from './lib/useWorkbenchMultiplayer';
import {
  WorkbenchContent as WorkbenchContentData,
  getSharingSecret,
  useRegisterViewedWorkbench,
} from '@vizcom/shared/data-access/graphql';
import { useSyncQueueSynchronizer } from './lib/SyncQueueSynchronizer';
import {
  MAX_Z_POSITION,
  getElementSize,
  getWorkbenchElementZPositionRange,
} from './components/helpers';
import {
  GENERATED_IMAGES_MARGIN,
  elementById,
  useIsWorkbenchViewer,
} from './lib/utils';
import { Toolbox } from './components/toolbar/toolbox';
import { WorkbenchCanvas } from './components/WorkbenchCanvas';
import { WorkbenchImageStudio } from './components/studio/WorkbenchImageStudio';
import { WorkbenchCompositeScene } from './components/compositeScene/workbenchCompositeScene/WorkbenchCompositeScene';
import WorkbenchKeyboardShortcuts from './components/WorkbenchKeyboardShortcuts';
import { WorkbenchCanvasContent } from './components/WorkbenchCanvasContent';
import { WorkbenchCollaborationToolbar } from './components/WorkbenchCollaborationToolbar/WorkbenchCollaborationToolbar';
import { MultiplayerCursorsSynchronizer } from './components/WorkbenchCollaborationToolbar/MultiplayerCursorsSynchronizer';
import { findFirstFreeSlotInElementCollection } from './components/utils/freeSlotFinders';
import { SyncErrorModal } from './components/SyncErrorModal';
import { WorkbenchOnboardingModal } from './components/modals/workbenchOnboardingModal';
import { OnboardingStepName } from '@vizcom/shared-ui-components';
import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from './constants';
import { WorkbenchToolProvider } from './components/toolbar/WorkbenchToolContext';
import {
  DeleteElementsModal,
  triggerDeleteElementsModal,
} from './components/modals/triggerDeleteElementsModal';
import { SignupCTABanner } from './components/SignupCTABanner';
import { useWorkbenchElementSelectionState } from './lib/elementSelectionState';
import { AreaSelector } from './components/AreaSelector';
import { useNavigate } from 'react-router-dom';
import { PublicFileSharingButton } from './components/WorkbenchCollaborationToolbar/publicFileSharing/PublicFileSharing';
import { paths } from '@vizcom/shared-utils-paths';
import { ScanWithPhoneModal } from './components/toolbar/ScanWithPhoneModal';
import { resetStudioToolState } from './components/studio/studioState';

export const WorkbenchContent = (props: {
  workbench: WorkbenchContentData;
  drawingId: string | null;
}) => {
  const { workbench, drawingId } = props;

  usePreventBrowserZoom();
  const threeState = useRef<RootState>(null!);
  const { focusedElementsId, setFocusedElementsId } =
    useWorkbenchElementSelectionState();

  const [toolboxOpen, setToolboxOpen] = useState(false);
  const [pauseWorkbenchRendering, setPauseWorkbenchRendering] = useState(false);
  const navigate = useNavigate();
  const [editingElementId, _setEditingElementId] = useState<string | null>(
    null
  );

  const [scanWithPhoneModalOpen, setScanWithPhoneModalOpen] = useState(false);

  const onOpenMobileUploadModal = () => setScanWithPhoneModalOpen(true);

  const isViewer = useIsWorkbenchViewer();

  useRegisterViewedWorkbench(workbench.id);

  const {
    multiplayerPresences,
    handleCursorMove,
    handleCameraMove,
    handleFocusedElementChange,
    selectedPresence,
    setSelectedPresenceId,
    selfPresence,
  } = useWorkbenchMultiplayer(workbench.id, editingElementId);

  const syncQueueSynchronizer = useSyncQueueSynchronizer([workbench.id]);

  const {
    elements,
    handleAction,
    fetching,
    hasUnsavedChanges,
    undoAction,
    redoAction,
    canUndo,
    canRedo,
  } = useWorkbenchSyncedState(workbench.id, syncQueueSynchronizer);

  useKeyboardShortcut(['delete', 'backspace'], async () => {
    if (isViewer) return;

    if (focusedElementsId && !editingElementId) {
      const elementIds = focusedElementsId.split('/').filter((id) => id);
      const deletedElements = elements.filter((e) => elementIds.includes(e.id));

      if (!deletedElements.length) return;

      if (
        deletedElements.some(
          (e) =>
            e.__typename === 'Drawing' ||
            e.__typename === 'CompositeScene' ||
            e.__typename === 'WorkbenchElementPalette'
        )
      ) {
        try {
          await triggerDeleteElementsModal(deletedElements);
        } catch {
          return;
        }
      }

      handleAction({
        type: 'deleteElements',
        elementIds,
      });
    }
  });

  const completeOnboardingStep = useCompleteOnboardingStep();
  const sharingSecret = getSharingSecret();

  const setEditingElementId = useStableCallback((id: string | null) => {
    let url = paths.workbench.file(workbench.id);
    if (id && elementById(elements, id)?.__typename === 'Drawing') {
      url = paths.workbench.drawing(workbench.id, id);
    }

    if (sharingSecret) {
      url += `#sharing_secret=${sharingSecret}`;
    }
    navigate(url, { replace: true });
    _setEditingElementId(id);

    setSelectedPresenceId(null);
  });

  // Select the element targeted by the sharing link
  useEffect(() => {
    if (!drawingId) return;
    _setEditingElementId(drawingId);
  }, [drawingId, setEditingElementId]);

  const handleSelectCreated = useCallback(
    (id: string, shouldEdit?: boolean) => {
      setFocusedElementsId(id);
      shouldEdit && setEditingElementId(id);
    },
    [setEditingElementId, setFocusedElementsId]
  );

  const isFirstElementRender = useRef(true);
  useEffect(() => {
    if (isFirstElementRender.current) {
      isFirstElementRender.current = false;

      if (elements.length === 1 && elements[0].__typename === 'Drawing') {
        setEditingElementId(elements[0].id);
      }
    }
  }, [elements]);

  const activeElement = useMemo(() => {
    const el = elementById(elements, editingElementId);

    if (el?.__typename === 'Drawing' || el?.__typename === 'CompositeScene') {
      return el;
    }
  }, [editingElementId, elements]);

  const handleToggleToolbox = useStableCallback(() => {
    setToolboxOpen((open) => !open);
    completeOnboardingStep(OnboardingStepName.ClickInsert);
  });

  useEffect(() => {
    handleFocusedElementChange(focusedElementsId);
  }, [focusedElementsId]);

  useEffect(() => {
    if (activeElement?.id) {
      setToolboxOpen(false);
    }

    if (!activeElement?.id) {
      setPauseWorkbenchRendering(false);
    }
  }, [activeElement?.id]);

  useEffect(() => {
    resetStudioToolState();
  }, [workbench.id]);

  const handleAddPreviewToWorkbench = useStableCallback(
    async (
      preview: ArrayBuffer | Blob,
      offset?: {
        x: number;
        y: number;
      },
      name?: string
    ) => {
      const sourceElement = elementById(elements, focusedElementsId);

      if (!sourceElement) {
        return;
      }

      const sourceElementSize = getElementSize(sourceElement);

      const { image, height, width } = await resizeImageToPixelCount(
        preview instanceof ArrayBuffer || ArrayBuffer.isView(preview)
          ? new Blob([preview])
          : preview,
        WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
      );
      const zPositions = elements.map((element) => element.zIndex);
      const zRange = [Math.min(...zPositions), Math.max(...zPositions)];
      const position = findFirstFreeSlotInElementCollection(elements, {
        firstSlotX:
          sourceElement.x +
          sourceElementSize.width +
          GENERATED_IMAGES_MARGIN +
          (offset?.x || 0),
        firstSlotY: sourceElement.y + (offset?.y || 0),
        slotWidth: sourceElementSize.width + GENERATED_IMAGES_MARGIN,
        slotHeight: sourceElementSize.height + GENERATED_IMAGES_MARGIN,
        maxElementPerLine: 3,
      });

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'Drawing',
            updatedAt: '0',
            id: uuidv4(),
            x: position[0],
            y: position[1],
            drawingWidth:
              sourceElement.__typename === 'Drawing'
                ? sourceElement.drawingWidth
                : width,
            drawingHeight:
              sourceElement.__typename === 'Drawing'
                ? sourceElement.drawingHeight
                : height,
            zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2,
            workbenchSizeRatio:
              sourceElement.__typename === 'Drawing'
                ? sourceElement.workbenchSizeRatio
                : 0.2,
            image,
            name: name || '',
          },
        ],
      });
    }
  );

  const resetEditingElementId = useCallback(() => {
    setEditingElementId(null);
  }, [setEditingElementId]);

  if (fetching) {
    return <FullPageDarkLoader />;
  }

  return (
    <>
      <Helmet>
        <title>{workbench.name} | Vizcom</title>
      </Helmet>
      <SyncErrorModal syncQueueSynchronizer={syncQueueSynchronizer} />
      <Container>
        <WorkbenchToolProvider>
          <WorkbenchCanvas
            onMissed={() => {
              if (!activeElement) {
                setFocusedElementsId('');
              }
            }}
            enableFileDrop={!isViewer && !activeElement}
            handleAction={handleAction}
            workbenchId={workbench.id}
            threeStateRef={threeState}
            multiplayerPresences={multiplayerPresences}
            pauseRendering={pauseWorkbenchRendering}
          >
            {!activeElement && (
              <>
                <WorkbenchCanvasContent
                  editingElementId={editingElementId}
                  setEditingElementId={setEditingElementId}
                  elements={elements}
                  handleAction={handleAction}
                  workbench={workbench}
                  multiplayerPresences={multiplayerPresences}
                />
                <WorkbenchCollaborationToolbar
                  workbenchId={workbench.id}
                  selfPresence={selfPresence}
                  selectedPresence={selectedPresence}
                  setSelectedPresenceId={setSelectedPresenceId}
                  multiplayerPresences={multiplayerPresences}
                >
                  <PublicFileSharingButton workbenchId={workbench.id} />
                </WorkbenchCollaborationToolbar>
                <WorkbenchFileMenu
                  workbenchId={workbench.id}
                  handleAction={handleAction}
                  hasUnsavedChanges={hasUnsavedChanges}
                  elements={elements}
                  undoAction={undoAction}
                  redoAction={redoAction}
                  canUndo={canUndo}
                  canRedo={canRedo}
                />
                <WorkbenchKeyboardShortcuts
                  handleAction={handleAction}
                  elements={elements}
                  undoAction={undoAction}
                  redoAction={redoAction}
                />
                <AreaSelector
                  setEditingElementId={setEditingElementId}
                  handleAction={handleAction}
                />
              </>
            )}
            <WorkbenchImageStudio
              activeElement={
                activeElement?.__typename === 'Drawing'
                  ? activeElement
                  : undefined
              }
              selfPresence={selfPresence}
              syncQueueSynchronizer={syncQueueSynchronizer}
              onExit={resetEditingElementId}
              selectedPresence={selectedPresence}
              setSelectedPresenceId={setSelectedPresenceId}
              multiplayerPresences={multiplayerPresences}
              onCreateDrawingFromImage={handleAddPreviewToWorkbench}
            />
            <WorkbenchCompositeScene
              activeElement={
                activeElement?.__typename === 'CompositeScene'
                  ? activeElement
                  : undefined
              }
              syncQueueSynchronizer={syncQueueSynchronizer}
              onEnter={() => {
                setPauseWorkbenchRendering(true);
              }}
              onExit={resetEditingElementId}
              onAddPreviewToWorkbench={handleAddPreviewToWorkbench}
            />
            <MultiplayerCursorsSynchronizer
              handleCameraMove={handleCameraMove}
              handleCursorMove={handleCursorMove}
              selectedPresence={selectedPresence}
              multiplayerPresences={multiplayerPresences}
              editingElementId={editingElementId}
              // the cursor synchronizer sets the editing element id when the followed user is editing an element,
              // so we bypass setting the selected presence to null as this isn't a manual selection
              setEditingElementId={setEditingElementId}
              setSelectedPresenceId={setSelectedPresenceId}
            />
          </WorkbenchCanvas>

          {!activeElement && (
            <>
              <WorkbenchToolbar
                handleToggleToolbox={handleToggleToolbox}
                handleUndo={undoAction}
                handleRedo={redoAction}
                canUndo={canUndo}
                canRedo={canRedo}
                onOpenMobileUploadModal={onOpenMobileUploadModal}
              />
              {!isViewer && <WorkbenchOnboardingModal />}
              <DeleteElementsModal handleUndo={undoAction} />
            </>
          )}
          <Toolbox
            open={toolboxOpen}
            setOpen={setToolboxOpen}
            handleAction={handleAction}
            threeState={threeState}
            handleSelectCreated={handleSelectCreated}
            onOpenMobileUploadModal={onOpenMobileUploadModal}
          />
          <ScanWithPhoneModal
            isOpen={scanWithPhoneModalOpen}
            onClose={() => setScanWithPhoneModalOpen(false)}
            workbenchId={workbench.id}
            threeState={threeState}
          />

          <SignupCTABanner />
        </WorkbenchToolProvider>
      </Container>
    </>
  );
};

const Container = styled.div`
  z-index: 2;
  position: relative;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;
