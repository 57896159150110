import { useSpring, animated } from '@react-spring/three';
import { useCameraZoom } from '../helpers';
import { MeshProps } from '@react-three/fiber';
import { memo } from 'react';
import { OutlinedSquareHandle, SegmentHandle } from './Handles';
import { Square } from './Shapes';
import { useTheme } from 'styled-components';
import { useWorkbenchStudioToolState } from '../studio/studioState';

const MARGIN = 0;
const HANDLES_CLICK_THRESHOLD_PIXELS = 20; // add a margin around every interactable to make it easier to click on
const ROTATION_HANDLE_AREA_SIZE = 150;
const SIDE_LINE_WIDTH = 3;

const AnimatedOutlinedSquareHandle = animated(OutlinedSquareHandle);
const AnimatedSegment = animated(SegmentHandle);

interface ResizerPresenterProps {
  width: number;
  height: number;
  active: boolean;
  resizeHandleMeshPropsGetter: (xDir: number, yDir: number) => any[];
  rotationHandleMeshPropsGetter?: (xDir: number, yDir: number) => MeshProps[];
  moveHandleMeshProps?: MeshProps[];
  renderOrder?: number;
  color?: string;
  rotation?: number;
  forceAspectRatio?: { x: boolean; y: boolean };
}

export const ResizePositionRotationPresenter = memo(
  ({
    width,
    height,
    active,
    resizeHandleMeshPropsGetter,
    rotationHandleMeshPropsGetter,
    moveHandleMeshProps,
    renderOrder,
    color,
    rotation = 0,
    forceAspectRatio,
  }: ResizerPresenterProps) => {
    const zoom = useCameraZoom();
    const t = useTheme();

    const { scale } = useSpring({
      scale: active ? 1 : 0,
    });

    //Determine if the drawing is flipped based on the matrix;
    const yFlipSign = useWorkbenchStudioToolState().flipped ? -1 : 1;

    return (
      <group renderOrder={renderOrder}>
        <Square
          depthWrite={false}
          size={1}
          opacity={0}
          scale={[
            width - (HANDLES_CLICK_THRESHOLD_PIXELS * 2) / zoom,
            height - (HANDLES_CLICK_THRESHOLD_PIXELS * 2) / zoom,
            1,
          ]}
          userData={{
            cursor: active ? 'move' : 'auto',
          }}
          {...(active ? moveHandleMeshProps : {})}
        />

        {[1, -1].flatMap((xDir) =>
          [1, -1].map((yDir) => {
            //apparent direction when the object is rotated or drawing flipped
            const screenXDir =
              yFlipSign *
              Math.sign(xDir * Math.cos(rotation) - yDir * Math.sin(rotation));
            const screenYDir = Math.sign(
              xDir * Math.sin(rotation) + yDir * Math.cos(rotation)
            );
            return (
              <group key={`${xDir}${yDir}`}>
                <AnimatedOutlinedSquareHandle
                  position={[
                    (width / 2 + MARGIN) * xDir,
                    (height / 2 + MARGIN) * yDir,
                    0,
                  ]}
                  scale={scale.to((s) => [s / zoom, s / zoom, 1])}
                  userData={{
                    cursor:
                      screenXDir + screenYDir !== 0
                        ? 'nesw-resize'
                        : 'nwse-resize',
                  }}
                  {...resizeHandleMeshPropsGetter(xDir, yDir)}
                  renderOrder={2}
                  color={color ?? t.primary.default}
                />

                {rotationHandleMeshPropsGetter && (
                  <Square
                    size={ROTATION_HANDLE_AREA_SIZE / zoom}
                    opacity={0}
                    position={[
                      (width / 2 +
                        MARGIN +
                        (Math.sign(width) * ROTATION_HANDLE_AREA_SIZE) /
                          2 /
                          zoom) *
                        xDir,
                      (height / 2 +
                        MARGIN +
                        (Math.sign(height) * ROTATION_HANDLE_AREA_SIZE) /
                          2 /
                          zoom) *
                        yDir,
                      0,
                    ]}
                    userData={{
                      cursor: ROTATION_CURSORS[`${screenXDir}/${screenYDir}`],
                    }}
                    {...(active
                      ? rotationHandleMeshPropsGetter(xDir, yDir)
                      : {})}
                  />
                )}
              </group>
            );
          })
        )}

        {[1, -1].map((xDir) => (
          <AnimatedSegment
            key={xDir}
            userData={{
              cursor: forceAspectRatio?.x
                ? xDir > 0
                  ? 'nesw-resize'
                  : 'nwse-resize'
                : 'ew-resize',
            }}
            color={color ?? t.primary.default}
            {...(active ? resizeHandleMeshPropsGetter(xDir, 0) : {})}
            from={[(width / 2) * xDir, -height / 2]}
            to={[(width / 2) * xDir, height / 2]}
            thickness={scale.to((s) => (s / zoom) * SIDE_LINE_WIDTH)}
          />
        ))}

        {[1, -1].map((yDir) => (
          <AnimatedSegment
            key={yDir}
            userData={{
              cursor: forceAspectRatio?.y
                ? yDir > 0
                  ? 'nesw-resize'
                  : 'nwse-resize'
                : 'ns-resize',
            }}
            color={color ?? t.primary.default}
            {...(active ? resizeHandleMeshPropsGetter(0, yDir) : {})}
            from={[-width / 2, (height / 2) * yDir]}
            to={[width / 2, (height / 2) * yDir]}
            thickness={scale.to((s) => (s / zoom) * SIDE_LINE_WIDTH)}
          />
        ))}
      </group>
    );
  }
);

const createRotationCursorSvg = (rotation: number) => {
  const svg = `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 32 32' fill='white' stroke='#0B0B0C' transform='rotate(${rotation})'>
      <path d='M13.1459 8.33672L13.9885 7.53423C18.6426 12.3039 18.6426 19.694 13.9885 24.4638L13.1459 23.6613C12.4727 23.0201 11.2952 23.2964 11.0499 24.2308L9.54165 29.9771C9.29575 30.9138 10.1824 31.6716 11.0575 31.4633L17.0908 30.0267C18.02 29.8054 18.393 28.6587 17.6709 27.971L16.8515 27.1906C23.0522 20.9093 23.0522 11.0887 16.8515 4.80747L17.6709 4.02703C18.393 3.33931 18.02 2.19259 17.0908 1.97132L11.0575 0.534763C10.1824 0.32639 9.29575 1.08419 9.54164 2.02097L11.0499 7.76721C11.2952 8.70161 12.4727 8.97793 13.1459 8.33672Z'/>
      </svg>
    `;
  return `url("data:image/svg+xml,${encodeURIComponent(svg)}") 12 12, auto`;
};
export const ROTATION_CURSORS = {
  '-1/-1': createRotationCursorSvg(135),
  '-1/1': createRotationCursorSvg(-135),
  '1/-1': createRotationCursorSvg(45),
  '1/1': createRotationCursorSvg(-45),
} as Record<string, string>;
