import { v4 as uuidv4 } from 'uuid';
import { ClientSideWorkbenchElementData } from '../../clientState';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  InsertImagesToPaletteMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { elementById } from '../../utils';
import { RemoveImagesFromPaletteAction } from './removeImagesFromPaletteAction';
import { assertExists, filterExists } from '@vizcom/shared/js-utils';
import { PaletteEventName } from '@vizcom/shared/data-shape';

export const InsertDrawingsToPaletteAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'insertDrawingsToPalette';
    id: string;
    sourceDrawingIds: string[];
  },
  {
    sourceImages: { id: string; image: string }[];
    status?: string;
  }
> = {
  type: 'insertDrawingsToPalette',
  optimisticUpdater: ({ payload, meta }, elements) => {
    assertExists(meta.custom);
    if (meta.custom.status !== 'idle') return;

    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette') return;

    meta.custom.sourceImages.forEach((sourceImage) => {
      if (
        element.sourceImages.nodes.some((node) => node.id === sourceImage.id)
      ) {
        return;
      }

      element.sourceImages.nodes.push({
        __typename: 'WorkbenchElementPaletteSourceImage',
        id: sourceImage.id,
        imagePath: sourceImage.image,
        workbenchElementPaletteId: payload.id,
      });
    });
  },
  remoteUpdater: async ({ payload, meta }, workbenchId) => {
    assertExists(meta.custom);
    if (meta.custom.status !== 'idle') return;

    const res = await urqlClient.mutation(InsertImagesToPaletteMutation, {
      input: {
        id: payload.id,
        sourceImages: meta.custom.sourceImages,
        workbenchId,
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while inserting drawings to palette, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Insert drawings to palette', {
      paletteId: payload.id,
      drawingsCount: meta.custom.sourceImages.length,
    });
    publishTrackingEvent({
      type: PaletteEventName.ADD_IMAGES,
      data: {
        paletteId: payload.id,
        imagesCount: meta.custom.sourceImages.length,
      },
    });
  },
  metaConstructor: ({ sourceDrawingIds, id }, elements) => {
    // get sourceImageInput from sourceDrawingIds
    const sourceImages = sourceDrawingIds
      .map((drawingId) => {
        const drawing = elementById(elements, drawingId);
        if (
          !drawing ||
          drawing.__typename !== 'Drawing' ||
          !drawing.thumbnailPath ||
          typeof drawing.thumbnailPath !== 'string'
        )
          return;

        return {
          id: uuidv4(),
          image: drawing.thumbnailPath,
        };
      })
      .filter(filterExists);

    const element = elementById(elements, id);

    return {
      custom: {
        sourceImages,
        status:
          element?.__typename === 'WorkbenchElementPalette'
            ? element.status
            : '',
      },
    };
  },
  undoConstructor: ({ payload, meta }) => {
    assertExists(meta.custom);
    const undoPayload: SyncedActionPayloadFromType<
      typeof RemoveImagesFromPaletteAction
    > = {
      type: 'removeImagesFromPalette',
      id: payload.id,
      imageIds: meta.custom.sourceImages.map((image) => image.id),
    };
    return undoPayload;
  },
};
