import { LayerMetadata3dView } from '@vizcom/shared/js-utils';

export const DEFAULT_LAYER_3D_CAMERA_DISTANCE = 4;

export const DEFAULT_LAYER_3D_CAMERA_VIEW: LayerMetadata3dView = {
  phi: 1,
  theta: 1,
  zoom: 1,
  x: 0,
  y: 0,
  environmentRotation: 0.0,
  environmentIntensity: 1.0,
  dropShadowOpacity: 0.0,
};
