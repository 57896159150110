import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { useSyncQueueSynchronizer } from '../../lib/SyncQueueSynchronizer';
import { importFilesToWorkbench } from '../utils/importFiles';
import {
  addToast,
  ImageBlockToolbarIcon,
  Text,
  ToastIndicator,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import { ToolboxButton } from '../toolbar/elementCreatorButtons';
import { useState } from 'react';

export const WorkbenchLikeImageUploader = (props: {
  workbenchId: string;
  workbenchName: string;
  zValue: number;
  targetPosition: [number, number];
}) => {
  const [loading, setLoading] = useState(false);

  const syncQueueSynchronizer = useSyncQueueSynchronizer([props.workbenchId]);
  const { handleAction } = useWorkbenchSyncedState(
    props.workbenchId,
    syncQueueSynchronizer
  );

  const handleImportFiles = useStableCallback(async () => {
    try {
      setLoading(true);

      await importFilesToWorkbench({
        handleAction,
        maxZIndex: props.zValue,
        targetPosition: props.targetPosition,
      });

      addToast('Files uploading to your workbench', {
        type: 'default',
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      addToast('Failed to upload files. Please try again.', { type: 'danger' });
    } finally {
      setLoading(false);
    }
  });

  const workbenchName = props.workbenchName;

  return (
    <FullPageContainer>
      <WorkbenchInfo>
        <WorkbenchName>{workbenchName}</WorkbenchName>
        <Description>
          Upload photos from your camera roll or take new ones
        </Description>
      </WorkbenchInfo>
      <UploadImageButton onClick={handleImportFiles}>
        <ImageBlockToolbarIcon
          style={{ width: '54px', height: '54px', padding: '12px' }}
        />
        <Text type="sh1">Upload photos</Text>
      </UploadImageButton>

      {loading && <ToastIndicator text="Importing files" type="loading" />}
    </FullPageContainer>
  );
};

const FullPageContainer = styled.div`
  background-color: ${(p) => p.theme.background.base};
  display: flex;
  flex-direction: column;
  padding: 64px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: ${(p) => p.theme.text.default};
`;

const WorkbenchInfo = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

const WorkbenchName = styled.h1`
  font-size: 20px;
  margin-bottom: 8px;
  color: ${(p) => p.theme.black};
`;

const Description = styled.p`
  color: ${(p) => p.theme.text.secondary};
`;

const UploadImageButton = styled(ToolboxButton)`
  flex-direction: column;
  cursor: pointer;
`;
