import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  useDeleteWorkbenchesAndFolders,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/react';
import {
  SelectedItem,
  SelectableItemType,
} from '../../Folder/SelectedItemsProvider';
import { FolderEventName, WorkbenchEventName } from '@vizcom/shared/data-shape';

type DeleteModalMultipleProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedItems: SelectedItem[];
  onComplete: () => void;
};

export const DeleteMultipleModal = (props: DeleteModalMultipleProps) => {
  const { isOpen, setIsOpen, selectedItems, onComplete } = props;
  const [deleteWorkbenchesAndFoldersRes, deleteWorkbenchesAndFolders] =
    useDeleteWorkbenchesAndFolders();

  const loading = deleteWorkbenchesAndFoldersRes.fetching;

  const getIdByType = (items: SelectedItem[], type: SelectableItemType) =>
    items.filter((item) => item.type === type).map(({ id }) => id);

  const handleDeleteClick = async () => {
    const workbenchIds = getIdByType(selectedItems, 'workbench');
    const folderIds = getIdByType(selectedItems, 'folder');

    const workbenchAndFolderRes = await deleteWorkbenchesAndFolders({
      deleteWorkbenchesInput: {
        ids: workbenchIds,
      },
      deleteFoldersInput: {
        ids: folderIds,
      },
    });

    if (workbenchAndFolderRes.error) {
      addToast('Error while deleting files & folders', {
        type: 'danger',
        secondaryText: formatErrorMessage(workbenchAndFolderRes.error),
      });

      Sentry.captureException(workbenchAndFolderRes.error);
      return;
    }

    if (workbenchIds.length > 0) {
      publishTrackingEvent({
        type: WorkbenchEventName.WORKBENCH_DELETED,
        data: {
          workbenchIds,
        },
      });
    }

    if (folderIds.length > 0) {
      trackEvent('Workbenches And Folders Deleted', {
        fileIds: workbenchIds,
        folderIds,
      });

      publishTrackingEvent({
        type: FolderEventName.FOLDER_DELETED,
        data: {
          folderIds,
        },
      });
    }

    setIsOpen(false);

    onComplete();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} loading={loading}>
      <ModalHeader>
        <ModalTitle>
          Delete ({selectedItems.length}) files and folders
        </ModalTitle>
        <ModalCloseButton />
      </ModalHeader>

      <ModalContent>
        <Text type="b1">
          Are you sure you want to delete these files and folders and all their
          content?
        </Text>
      </ModalContent>

      <ModalActions>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDeleteClick} disabled={loading}>
          Delete
        </Button>
      </ModalActions>
    </Modal>
  );
};
