import {
  Button,
  DownloadIcon,
  ImportImageIcon,
  Tooltip,
} from '@vizcom/shared-ui-components';

import { handleDownloadPaletteImages } from '../helpers';
import { useTheme } from 'styled-components';
import { ClientSideWorkbenchElementPalette } from '../../../../lib/clientState';

type WorkbenchElementPaletteDownloadImagesProps = {
  paletteData: ClientSideWorkbenchElementPalette;
};

export const WorkbenchElementPaletteDownloadImages = ({
  paletteData,
}: WorkbenchElementPaletteDownloadImagesProps) => {
  const theme = useTheme();
  return (
    <Tooltip tip="Download" position="top">
      <Button
        size="iconSquared"
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          handleDownloadPaletteImages(paletteData);
        }}
      >
        <DownloadIcon
          style={{ color: theme.icon.default, width: '22px', height: '22px' }}
        />
      </Button>
    </Tooltip>
  );
};
