import { createContext, useContext } from 'react';
import { Scene, WebGLRenderTarget } from 'three';

export interface LayersCompositorApi {
  scene: Scene;
  fbo: WebGLRenderTarget;
  drawingSize: [number, number];
  getCompositedImage: (options?: {
    onlyDisplayLayersIds?: string[];
    forceFullOpacityForLayersId?: string[]; // useful when merging down a layer, to keep the target layer to full opacity
  }) => ImageData;
  getPixelColor: (x: number, y: number) => number[];
  getLayerIdAt: (x: number, y: number) => string | null;
}

export const LayersCompositorContext = createContext<LayersCompositorApi>(
  null!
);

export const useLayersCompositor = () => {
  const context = useContext(LayersCompositorContext);
  if (!context) {
    throw new Error(
      'LayerContainer must be used within LayersCompositorContextProvider'
    );
  }
  return context;
};
