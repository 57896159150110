import { FC, useMemo } from 'react';
import { CustomImage } from '../../utils/CustomImage';
import { filterExists } from '@vizcom/shared/js-utils';
import { CustomHtml } from '../../utils/CustomHtml';
import { Extra } from '../../WorkbenchElementExtra';
import {
  Button,
  TrashIcon,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { FocusIndicator } from '../../utils/FocusIndicator';
import { useImageTextures } from '../../../lib/useImageTexture';
import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { calculateGridLayout } from '../../utils/layoutHelpers';
import { useWorkbenchElementSelectionState } from '../../../lib/elementSelectionState';

interface PaletteImagesProps {
  element: ClientSideWorkbenchElementPalette;
  width: number;
  height: number;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const PaletteImages: FC<PaletteImagesProps> = ({
  element,
  width,
  height,
  handleAction,
}) => {
  const images = element.sourceImages?.nodes ?? [];
  const textures = useImageTextures(images.map((image) => image.imagePath));

  const elements = images
    .map((image, index) => {
      const texture = textures[index];
      if (!texture) {
        return null;
      }

      return {
        id: image.id,
        width: texture.image.width,
        height: texture.image.height,
        aspectRatio: texture.image.width / texture.image.height,
      };
    })
    .filter(filterExists);

  const gridElementsData = useMemo(
    () => calculateGridLayout(elements, width, height),
    [elements, width, height]
  );

  return (
    <>
      {gridElementsData.map((data) => {
        const image = images.find((img) => img.id === data.id);
        if (!image) {
          return null;
        }

        return (
          <PaletteImage
            key={data.id}
            image={{
              id: data.id,
              imagePath: image.imagePath,
              x: data.x - width / 2,
              y: -data.y + height / 2,
              scale: [data.width, data.height],
            }}
            handleAction={handleAction}
            element={element}
          />
        );
      })}
    </>
  );
};

const PaletteImage = ({
  image,
  handleAction,
  element,
}: {
  image: {
    id: string;
    imagePath: ClientSideWorkbenchElementPalette['sourceImages']['nodes'][0]['imagePath'];
    x: number;
    y: number;
    scale: [number, number];
  };
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  element: ClientSideWorkbenchElementPalette;
}) => {
  const isViewer = useIsWorkbenchViewer();
  const singleFocused = useWorkbenchElementSelectionState(
    (s) => s.focusedElementsId === image.id
  );

  const handleRemoveImageFromPalette = () => {
    handleAction({
      type: 'removeImagesFromPalette',
      id: element.id,
      imageIds: [image.id],
    });
  };

  useKeyboardShortcut(['delete', 'backspace'], async () => {
    if (isViewer || !singleFocused || element.status !== 'idle') return;

    handleRemoveImageFromPalette();
  });

  return (
    <group position={[image.x, image.y, 0.5]}>
      <CustomImage
        scale={image.scale}
        url={image.imagePath}
        onPointerDown={(e) => {
          e.stopPropagation();
          useWorkbenchElementSelectionState
            .getState()
            .setFocusedElementsId(image.id);
        }}
      />
      {singleFocused && !isViewer && element.status === 'idle' && (
        <>
          <FocusIndicator
            active={singleFocused}
            width={image.scale[0]}
            height={image.scale[1]}
          />
          <CustomHtml
            style={{ pointerEvents: 'none' }}
            position={[0, image.scale[1] / 4, 0.5]}
          >
            <Extra onPointerDown={(e) => e.stopPropagation()}>
              <Button
                size="iconSquared"
                variant="transparent"
                onClick={handleRemoveImageFromPalette}
              >
                <TrashIcon />
              </Button>
            </Extra>
          </CustomHtml>
        </>
      )}
    </group>
  );
};
