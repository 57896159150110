import { useCallback, useEffect, useRef, useState } from 'react';
import { decode, encode } from '@msgpack/msgpack';
import { useLastValue } from './useLastValue';
import { sleep } from '../../../../../js-utils/src';

export enum WsReadyState {
  UNINSTANTIATED,
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

export const useWebsocket = <SentMessageType, ReceivedMessageType>(
  url: string,
  onMessage: (message: ReceivedMessageType) => void,
  onConnect?: () => void
) => {
  const [readyState, setReadyState] = useState<WsReadyState>(
    WsReadyState.UNINSTANTIATED
  );
  const websocketRef = useRef<WebSocket>();

  const sendMessage = useCallback((message: SentMessageType) => {
    if (websocketRef.current?.readyState === WebSocket.OPEN) {
      websocketRef.current.send(encode(message));
    }
  }, []);

  const onMessageRef = useLastValue(onMessage);
  const onConnectRef = useLastValue(onConnect);

  useEffect(() => {
    let reset = null as null | (() => void);
    let reconnectAttempts = 0;

    const start = () => {
      reset?.();
      const ws = new WebSocket(url);
      websocketRef.current = ws;
      setReadyState(WsReadyState.CONNECTING);

      const abortController = new AbortController();
      ws.binaryType = 'arraybuffer';
      ws.addEventListener(
        'message',
        (event) => {
          let body;
          if (typeof event.data === 'string') {
            body = JSON.parse(event.data);
          } else if (event.data instanceof ArrayBuffer) {
            body = decode(new Uint8Array(event.data));
          } else {
            throw new Error('Received invalid ws body');
          }

          onMessageRef.current(body);
        },
        {
          signal: abortController.signal,
        }
      );

      ws.addEventListener(
        'open',
        () => {
          setReadyState(WsReadyState.OPEN);
          onConnectRef.current?.();
          reconnectAttempts = 0;
        },
        {
          signal: abortController.signal,
        }
      );

      ws.addEventListener(
        'close',
        () => {
          setReadyState(WsReadyState.CLOSED);
          reconnectAttempts += 1;
          sleep(getReconnectDelay(reconnectAttempts)).then(start);
        },
        {
          signal: abortController.signal,
        }
      );

      reset = () => {
        setReadyState(WsReadyState.CLOSED);
        ws?.close();
        abortController.abort();
        websocketRef.current = undefined;
        reset = null;
      };
    };

    start();

    return () => {
      reset?.();
    };
  }, [url, onMessageRef, onConnectRef]);

  return {
    readyState,
    sendMessage,
  };
};

const getReconnectDelay = (retries: number) => {
  return Math.min(1 + Math.random() * 3 * retries, 60) * 1000;
};
