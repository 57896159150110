import { CSSReset, LoadingLogoInset } from '@vizcom/shared-ui-components';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'urql';
import App from './app/app';
import { VizcomThemeProvider } from '@vizcom/web/common/theme';
import { urqlClient } from '@vizcom/shared/data-access/graphql';
import { RecoilRoot } from 'recoil';
import {
  SentryErrorBoundary,
  initSentry,
} from '@vizcom/shared/data-access/sentry';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '../../../libs/shared/ui/components/src/fonts/inter/fonts.css';

initSentry();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <HelmetProvider>
      <Provider value={urqlClient}>
        <RecoilRoot>
          <Helmet>
            <title>Vizcom</title>
          </Helmet>
          <SentryErrorBoundary>
            <VizcomThemeProvider>
              <Suspense
                fallback={
                  <LoadingLogoInset active fade={false} opaqueBackdrop />
                }
              >
                <App />
              </Suspense>
              <CSSReset />
            </VizcomThemeProvider>
          </SentryErrorBoundary>
        </RecoilRoot>
      </Provider>
    </HelmetProvider>
  </StrictMode>
);
