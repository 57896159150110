import { useMemo, useRef } from 'react';
import { MathUtils, Vector2 } from 'three';
import { ToolSettings, Symmetry } from '../../studioState';
import { BrushSegment } from '../../types';
import {
  getCubicSplineSegmentCoefficients,
  computeCubicSegmentLength,
} from './BrushSegmentCurve';
import { useStore } from '@react-three/fiber';
import { useStableCallback } from '../../../../../../../../shared/ui/components/src';
import { useStrokeRenderer, BrushHardness } from './useStrokeRenderer';
import { symmetryTransform } from '../../lib/symmetry';

const auxA = new Vector2();
const auxB = new Vector2();
const auxC = new Vector2();
const auxD = new Vector2();

export const useBrushStroke = ({
  drawingSize,
  color,
  symmetry,
  toolSettings,
}: {
  drawingSize: [number, number];
  color: string;
  symmetry: Symmetry;
  toolSettings: ToolSettings;
}) => {
  const store = useStore();
  const { toolAngle, toolAspect, toolHardness, toolSize } = toolSettings;
  const angle = useMemo(() => MathUtils.degToRad(-toolAngle), [toolAngle]);
  const segmentsRef = useRef<BrushSegment[]>([]);

  const strokeRenderer = useStrokeRenderer(drawingSize);

  const getTexture = useStableCallback(() => strokeRenderer.getTexture());

  const addSegments = useStableCallback((segments, last) => {
    let i = Math.max(0, segmentsRef.current.length - 1);
    segmentsRef.current.push(...segments);

    if (!segmentsRef.current.length) return;

    const symmetryOrigin = new Vector2(
      symmetry.origin[0] * drawingSize[0],
      symmetry.origin[1] * drawingSize[1]
    );

    const symmetryDirection = new Vector2(
      Math.cos(Math.PI / 2 - symmetry.rotation),
      Math.sin(Math.PI / 2 - symmetry.rotation)
    );

    //`symmetryDirection` is used to determine the side of the symmetry axis we want to use when `allowCrossingAxis` is false.
    //Depending on the the side of the initial point, we invert the direction or not.
    const firstPoint = new Vector2(...segmentsRef.current[0].startPosition);
    if (firstPoint.clone().sub(symmetryOrigin).cross(symmetryDirection) < 0) {
      symmetryDirection.negate();
    }
    const negativeSymmetryDirection = symmetryDirection.clone().negate();

    while (segmentsRef.current[i]) {
      const segment = segmentsRef.current[i];
      const previousSegment = segmentsRef.current[i - 1];
      const nextSegment = segmentsRef.current[i + 1];
      if (!nextSegment && !last) {
        // do not use last segments if we don't have the next point yet, this is required to do the curve interpolation
        break;
      }

      const prev = auxA;
      const start = auxB;
      const end = auxC;
      const next = auxD;

      start.fromArray(segment.startPosition);
      end.fromArray(segment.endPosition);
      //A Catmull-Rom curve doesn't go through the first and last points.
      //We need to provide additional prev and next points when they are not defined.
      if (previousSegment?.startPosition) {
        prev.fromArray(previousSegment?.startPosition);
      } else {
        prev.subVectors(start, end).add(start);
      }
      if (nextSegment?.endPosition) {
        next.fromArray(nextSegment?.endPosition);
      } else {
        next.subVectors(end, start).add(end);
      }

      for (let s = 0; s < (symmetry.enabled ? 2 : 1); s++) {
        if (s === 1) {
          symmetryTransform(symmetry, drawingSize, start);
          symmetryTransform(symmetry, drawingSize, end);
          symmetryTransform(symmetry, drawingSize, prev);
          symmetryTransform(symmetry, drawingSize, next);
        }

        const { px, py } = getCubicSplineSegmentCoefficients(
          prev,
          start,
          end,
          next
        );
        const length = computeCubicSegmentLength(px, py, start, end);
        const count = Math.max(1.0, Math.ceil(length));
        strokeRenderer.render(
          toolAspect,
          color,
          count,
          toolHardness === 100 ? BrushHardness.hard : BrushHardness.soft,
          {
            start: segment.startPressure,
            end: segment.endPressure,
          },
          px,
          py,
          angle + store.getState().camera.rotation.z,
          toolSize,
          symmetry.allowCrossingAxis || !symmetry.enabled, //if symmetry is disabled we don't want to limit the stroke
          symmetryOrigin,
          s === 0 ? symmetryDirection : negativeSymmetryDirection
        );
      }
      i++;
    }
  });

  const reset = useStableCallback(() => {
    strokeRenderer.clear();
    segmentsRef.current = [];
  });

  return {
    getTexture,
    addSegments,
    reset,
    segmentsRef,
  };
};
