import {
  Button,
  EmailIcon,
  LoadingLogoInset,
  Surface,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  OrganizationSubscriptionData,
  useCreateOrganizationSubscriptionManagementPortal,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  AmexIcon,
  DinersIcon,
  DiscoverIcon,
  GenericIcon,
  JCBIcon,
  MaestroIcon,
  MasterCardIcon,
  PaypalIcon,
  UnionpayIcon,
  VisaIcon,
} from '@vizcom/shared-ui-components';

export const OrganizationPaymentMethod = ({
  subscriptionData,
  organizationId,
}: {
  subscriptionData: OrganizationSubscriptionData;
  organizationId: string;
}) => {
  const [res, createPortal] =
    useCreateOrganizationSubscriptionManagementPortal();

  const handleChangePaymentMethod = async () => {
    const res = await createPortal({
      input: {
        organizationId,
      },
    });
    if (res.error || !res.data) {
      return addToast('Error while changing payment method', {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
    window.location.assign(
      res.data.createOrganizationSubscriptionManagementPortal.url
    );
  };

  return (
    <Surface style={{ padding: 24 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Text style={{ marginBottom: 24 }} block type="sh1">
          Payment method
        </Text>
      </div>
      {subscriptionData.subscription.paymentMethodType === 'card' &&
        subscriptionData.subscription.paymentMethodCardBrand && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '59px', marginRight: '18px' }}>
                <CreditCardLogo
                  type={subscriptionData.subscription.paymentMethodCardBrand}
                />
              </div>
              <div>
                <Text block style={{ marginBottom: '8px' }}>
                  {capitalize(
                    subscriptionData.subscription.paymentMethodCardBrand
                  )}
                  {subscriptionData.subscription.paymentMethodCardLast4 && (
                    <>
                      {' '}
                      ending in{' '}
                      {subscriptionData.subscription.paymentMethodCardLast4 ||
                        1234}
                    </>
                  )}
                </Text>
                {subscriptionData.subscription.paymentMethodCardExpires && (
                  <Text block color="info" style={{ marginBottom: '19px' }}>
                    Expires{' '}
                    {subscriptionData.subscription.paymentMethodCardExpires}
                  </Text>
                )}
                <Text block color="info">
                  <EmailIcon /> {subscriptionData.subscription.billingEmail}
                </Text>
              </div>
            </div>
            {subscriptionData.subscription.paymentMethodType && (
              <Button variant="secondary" onClick={handleChangePaymentMethod}>
                Edit
              </Button>
            )}
          </div>
        )}
      {!subscriptionData.subscription.paymentMethodType && (
        <div>
          <Text block>No payment method stored for this workspace</Text>
        </div>
      )}
      <LoadingLogoInset active={res.fetching} />
    </Surface>
  );
};

const CreditCardLogo = ({ type }: { type?: string }) => {
  switch (type) {
    case 'amex':
      return <AmexIcon />;
    case 'diners':
      return <DinersIcon />;
    case 'discover':
      return <DiscoverIcon />;
    case 'jcb':
      return <JCBIcon />;
    case 'maestro':
      return <MaestroIcon />;
    case 'mastercard':
      return <MasterCardIcon />;
    case 'paypal':
      return <PaypalIcon />;
    case 'unionpay':
      return <UnionpayIcon />;
    case 'visa':
      return <VisaIcon />;
    default:
      return <GenericIcon />;
  }
};
