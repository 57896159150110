import {
  MeshBasicMaterialProps,
  MeshProps,
  Object3DProps,
} from '@react-three/fiber';
import { memoize } from 'lodash';
import { Fragment, forwardRef } from 'react';
import { DoubleSide, Object3D } from 'three';
import { SVGLoader } from 'three-stdlib';

const svgLoader = new SVGLoader();

export interface StaticSvgProps extends Omit<Object3DProps, 'ref'> {
  /** src can be a URL or SVG data */
  svg: string;
  fillMaterial?: MeshBasicMaterialProps;
  strokeMaterial?: MeshBasicMaterialProps;
  fillMeshProps?: MeshProps;
  strokeMeshProps?: MeshProps;
  anchorX?: 'middle' | 'left' | 'right';
  anchorY?: 'middle' | 'bottom' | 'top';
}

const parseSvg = memoize((svg: string) => {
  const parsed = svgLoader.parse(svg);
  const paths = parsed.paths.map((path) => {
    const fillShapes =
      (path.userData?.style.fill !== undefined &&
        path.userData.style.fill !== 'none' &&
        SVGLoader.createShapes(path)) ||
      [];
    const strokes =
      (path.userData?.style.stroke !== undefined &&
        path.userData.style.stroke !== 'none' &&
        path.subPaths.map((subPath) =>
          // This geometry is never disposed of, but because StaticSvg should only be used with static content, this should be fine
          // and the memory usage won't explode
          SVGLoader.pointsToStroke(subPath.getPoints(), path.userData!.style)
        )) ||
      [];

    return { fillShapes, strokes, userData: path.userData };
  });
  return { parsed, paths };
});

// Same as Drei's Svg component, but without the dynamic loading

export const StaticSvg = forwardRef<Object3D, StaticSvgProps>(
  function StaticSvg(
    {
      svg,
      fillMaterial,
      strokeMaterial,
      fillMeshProps,
      strokeMeshProps,
      anchorX = 'middle',
      anchorY = 'middle',
      ...props
    },
    ref
  ) {
    const { parsed, paths } = parseSvg(svg);

    const width = (parsed.xml as any).viewBox.animVal.width;
    const height = (parsed.xml as any).viewBox.animVal.height;

    const position = [
      anchorX === 'middle' ? -width / 2 : anchorX === 'right' ? -width : 0,
      anchorY === 'middle' ? height / 2 : anchorY === 'bottom' ? height : 0,
      0,
    ] as const;

    return (
      <object3D {...props} ref={ref}>
        <object3D scale={[1, -1, 1]} position={position}>
          {paths.map((path, i) => (
            <Fragment key={i}>
              {path.fillShapes.map((shape, s) => (
                <mesh key={s} {...fillMeshProps}>
                  <shapeGeometry args={[shape]} />
                  <meshBasicMaterial
                    color={path.userData!.style.fill}
                    opacity={path.userData!.style.fillOpacity}
                    transparent={true}
                    side={DoubleSide}
                    depthWrite={false}
                    {...fillMaterial}
                  />
                </mesh>
              ))}
              {path.strokes.map((strokeGeometry, s) => (
                <mesh key={s} geometry={strokeGeometry} {...strokeMeshProps}>
                  <meshBasicMaterial
                    color={path.userData!.style.stroke}
                    opacity={path.userData!.style.strokeOpacity}
                    transparent={true}
                    side={DoubleSide}
                    depthWrite={false}
                    {...strokeMaterial}
                  />
                </mesh>
              ))}
            </Fragment>
          ))}
        </object3D>
      </object3D>
    );
  }
);
