import { useFrame } from '@react-three/fiber';
import { Object3D } from 'three';
import { filterExists } from '@vizcom/shared/js-utils';
import { sortObjectsByHierarchicalRenderOrder } from './threeRenderingOrder';

const findAncestorWithCursorUserData = (object: Object3D) => {
  let current = object as Object3D | null;
  while (current) {
    if (current.userData.cursor) {
      return current;
    }
    current = current.parent;
  }
  return null;
};

// This updates the mouse cursor based on the "userData" property of the object3D under the mouse
// or the "globalCursor" property of any object visible in the scene
export const MouseCursorUpdater = () => {
  useFrame((state) => {
    if (!state.events.connected) {
      return;
    }
    let cursor = null as null | string;
    const objectWithCursors = [] as Object3D[]; // only check items with a cursor set, this highly reduces the amount of objects to check when intersecting
    state.scene.traverseVisible((o) => {
      if (cursor) {
        return; // early exit
      }
      if (o.userData.globalCursor) {
        cursor = o.userData.globalCursor;
      }
      if (o.userData.cursor) {
        objectWithCursors.push(o);
      }
    });

    if (!cursor) {
      state.raycaster.setFromCamera(state.mouse, state.camera);
      const objects = state.raycaster
        .intersectObjects(objectWithCursors)
        // need to find the closest ancestor because the cursor could be set on a group and we would only get the intersected child of this group
        .map((intersect) => findAncestorWithCursorUserData(intersect.object))
        .filter(filterExists);
      if (objects.length) {
        const sortedObjects = objects
          .map((o: Object3D) => ({ id: o.id, object: o }))
          .sort(sortObjectsByHierarchicalRenderOrder);
        cursor = sortedObjects[sortedObjects.length - 1].object.userData.cursor;
      }
    }
    if (state.events.connected.style.cursor !== cursor) {
      // small optimization to prevent style recalculation when updating the "style" property
      state.events.connected.style.cursor = cursor;
    }
  });

  return null;
};
