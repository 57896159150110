import { useEffect } from 'react';

export const useAlertOnUnload = (active: boolean) => {
  useEffect(() => {
    if (!active) {
      return;
    }

    const handler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      // Note: it's not possible to change the message displayed to the user before unloading
      // Source: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
      return (e.returnValue = '');
    };

    window.addEventListener('beforeunload', handler, { capture: true });

    return () =>
      window.removeEventListener('beforeunload', handler, { capture: true });
  }, [active]);
};
