import { useTheme } from 'styled-components';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useIsWorkbenchViewer } from '../lib/utils';
import { WorkbenchElementConnector } from './elementConnector/workbenchElementConnector';
import { MixSourceDrawing } from './elements/mix/WorkbenchElementMix';
import { WorkbenchElementMixData } from '@vizcom/shared/data-access/graphql';

type Props = {
  element: ClientSideWorkbenchElementData;
  focused: boolean;
  selectedSourceId: string | null;
  handleAction: (action: any) => void;
};

export const WorkbenchElementConnections = ({
  element,
  focused,
  selectedSourceId,
  handleAction,
}: Props) => {
  const isViewer = useIsWorkbenchViewer();
  const theme = useTheme();

  const deleteMixDrawing =
    (element: WorkbenchElementMixData) => (drawingId: string) => {
      const sourceDrawings = [...element.sourceDrawings];

      const newDrawings = sourceDrawings.filter(
        (source) => source.sourceDrawingId !== drawingId
      );
      handleAction({
        type: 'updateMix',
        elementId: element.id,
        sourceDrawings: newDrawings,
      });
    };

  const getLineOpacity = (sourceId: string) => {
    if (!selectedSourceId) {
      return 1;
    }

    return selectedSourceId === sourceId ? 1 : 0.4;
  };

  const getOrderOffset = (sourceId: string) => {
    if (!selectedSourceId) {
      return undefined;
    }

    return selectedSourceId === sourceId ? 1 : 0;
  };

  if (element.__typename === 'WorkbenchElementImg2Img') {
    return element.sourceDrawingId ? (
      <WorkbenchElementConnector
        sourceElementId={element.id}
        targetElementId={element.sourceDrawingId}
        focused={focused}
        onDelete={
          isViewer
            ? undefined
            : () => {
                handleAction({
                  type: 'updateAiImg2Img',
                  elementId: element.id,
                  sourceDrawingId: null,
                });
              }
        }
      />
    ) : null;
  }

  if (element.__typename === 'WorkbenchElementMix') {
    return (
      element.sourceDrawings &&
      element.sourceDrawings.map((source: MixSourceDrawing) => (
        <WorkbenchElementConnector
          key={source.sourceDrawingId}
          sourceElementId={element.id}
          targetElementId={source.sourceDrawingId}
          focused={focused}
          onDelete={isViewer ? undefined : deleteMixDrawing(element)}
          opacity={getLineOpacity(source.sourceDrawingId)}
          orderOffset={getOrderOffset(source.sourceDrawingId)}
          lineColor={selectedSourceId ? theme.primary.default : undefined}
        />
      ))
    );
  }

  return null;
};
