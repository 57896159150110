import { useForm, SubmitHandler } from 'react-hook-form';
import styled from 'styled-components';
import { CreateOrganizationHeader } from '../organization/CreateOrganizationPage/CreateOrganizationHeader';
import {
  Button,
  Text,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  useCurrentUser,
  useUpdateUser,
} from '@vizcom/shared/data-access/graphql';
import { useNavigate } from 'react-router-dom';

type OnboardingProfilePageInputs = {
  name: string;
};

export const OnboardingProfilePage = () => {
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUser();
  const [updateUserRef, updateUser] = useUpdateUser();
  const { register, handleSubmit, formState } =
    useForm<OnboardingProfilePageInputs>({
      values: {
        name: currentUser?.name ?? '',
      },
    });

  const onSubmit: SubmitHandler<OnboardingProfilePageInputs> = async (data) => {
    const res = await updateUser({
      id: currentUser!.id,
      patch: {
        name: data.name,
      },
    });
    if (res.error) {
      return addToast('Error while updating name', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    navigate('/');
  };

  return (
    <Container>
      <CreateOrganizationHeader />

      <Text type="t1" block>
        What is your name?
      </Text>
      <Text type="sh1" block style={{ marginTop: 8 }}>
        This is the name others will see in your shared workspace.
      </Text>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          marginTop: 40,
          width: 400,
        }}
      >
        <div>
          <Text block type="sh2" style={{ marginBottom: 8 }}>
            Full name*
          </Text>
          <TextInput
            required
            $background="e1"
            type="text"
            autoFocus
            {...register('name')}
          />
        </div>
        <Button type="submit" disabled={updateUserRef.fetching}>
          Continue
        </Button>
      </form>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(p) => p.theme.surface.e0};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 40px;
`;
