import styled from 'styled-components';

export const FileMenu = styled.div`
  position: absolute;
  top: 1rem;
  left: 14px;
  color: ${({ theme }) => theme.text.default};
  z-index: 10000000;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  padding: 4px 8px;
  border-radius: 16px;
  background: ${({ theme }) => theme.surface.e0};
  grid-template-areas: 'logo name loader';
  width: 250px;
  height: 48px;
  pointer-events: all;
`;
