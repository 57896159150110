import { useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.surface.e2};
  border-radius: 0.25rem;
  padding: 0.5rem;
  > canvas {
    width: 100px;
    height: 100px;
  }
`;

type BrushTransformInputProps = {
  aspect: number;
  setAspect: (aspect: number) => void;
  rotation: number;
  setRotation: (rotation: number) => void;
};

export const BrushTransformInput = ({
  aspect,
  setAspect,
  rotation,
  setRotation,
}: BrushTransformInputProps) => {
  const dom: React.MutableRefObject<HTMLCanvasElement> = useRef(null!);
  const state: React.MutableRefObject<{
    aspect: number;
    rotation: number;
    handlers: { operation: 'aspect' | 'rotation'; x: number; y: number }[];
    dragging: {
      x: number;
      y: number;
      operation: 'aspect' | 'rotation';
      value: number;
    };
    pointer: number;
  }> = useRef({
    aspect: 0,
    rotation: 0,
    handlers: [],
    dragging: { x: 0, y: 0, operation: 'aspect', value: 0 },
    pointer: -1,
  });

  useEffect(() => {
    const renderer: HTMLCanvasElement = dom.current;
    const ctx: CanvasRenderingContext2D = renderer.getContext('2d')!;
    const rect: DOMRect = renderer.getBoundingClientRect();
    const radians = (rotation * Math.PI) / 180;
    renderer.width = rect.width * devicePixelRatio;
    renderer.height = rect.height * devicePixelRatio;
    ctx.translate(renderer.width * 0.5, renderer.height * 0.5);
    ctx.rotate(radians);
    ctx.scale(devicePixelRatio, devicePixelRatio);
    ctx.lineWidth = 1 / devicePixelRatio;

    const w = (rect.width - 16) * 0.5;
    const h = (rect.height - 16) * aspect * 0.5;

    ctx.strokeStyle = '#4d4d4d';
    ctx.beginPath();
    ctx.moveTo(-w, 0);
    ctx.lineTo(w, 0);
    ctx.moveTo(0, -h);
    ctx.lineTo(0, h);
    ctx.stroke();
    ctx.strokeStyle = '#808080';
    ctx.beginPath();
    ctx.ellipse(0, 0, w, h, 0, 0, Math.PI * 2);
    ctx.stroke();

    ctx.fillStyle = '#808080';
    ctx.beginPath();
    ctx.arc(0, -h, 8, 0, Math.PI * 2);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(0, h, 8, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = '#d9d9d9';
    ctx.beginPath();
    ctx.moveTo(w + 1, -6);
    ctx.lineTo(w + 9, 0);
    ctx.lineTo(w + 1, 6);
    ctx.fill();

    state.current.aspect = aspect;
    state.current.rotation = radians;
    state.current.handlers = [
      { operation: 'aspect', x: 0, y: h },
      { operation: 'aspect', x: 0, y: -h },
      { operation: 'rotation', x: w + 5, y: 0 },
    ];
  }, [aspect, rotation]);

  const transformPointer = useCallback(
    (p: { x: number; y: number }, rotate = true) => {
      const renderer: HTMLCanvasElement = dom.current;
      const rect: DOMRect = renderer.getBoundingClientRect();
      const tp = {
        x: p.x - rect.x - rect.width * 0.5,
        y: p.y - rect.y - rect.height * 0.5,
      };
      if (!rotate) {
        return tp;
      }
      const cos = Math.cos(-state.current.rotation);
      const sin = Math.sin(-state.current.rotation);
      return {
        x: tp.x * cos - tp.y * sin,
        y: tp.y * cos + tp.x * sin,
      };
    },
    []
  );

  const getHandler = useCallback(
    (p: { x: number; y: number }) =>
      state.current.handlers.find(
        (h) => Math.sqrt((h.x - p.x) ** 2 + (h.y - p.y) ** 2) < 12
      ),
    []
  );

  const pointerdown: React.PointerEventHandler<HTMLCanvasElement> = useCallback(
    (e) => {
      dom.current!.setPointerCapture(e.pointerId);
      if (state.current.pointer !== -1) {
        return;
      }
      const p = transformPointer({ x: e.clientX, y: e.clientY });
      const handler = getHandler(p);
      if (handler) {
        state.current.pointer = e.pointerId;
        state.current.dragging.x = p.x;
        state.current.dragging.y = p.y;
        state.current.dragging.operation = handler.operation;
        state.current.dragging.value = state.current[handler.operation];
      }
    },
    []
  );
  const pointermove: React.PointerEventHandler<HTMLCanvasElement> = useCallback(
    (e) => {
      if (state.current.pointer !== e.pointerId) {
        const p = transformPointer({ x: e.clientX, y: e.clientY });
        dom.current!.style.cursor = getHandler(p) ? 'pointer' : 'default';
        return;
      }

      const p = transformPointer(
        { x: e.clientX, y: e.clientY },
        state.current.dragging.operation === 'aspect'
      );
      if (state.current.dragging.operation === 'aspect') {
        setAspect(
          Math.min(
            Math.max(
              (p.y * state.current.dragging.value) / state.current.dragging.y,
              0.01
            ),
            1
          )
        );
      } else {
        setRotation((Math.atan2(p.y, p.x) * 180) / Math.PI);
      }
    },
    []
  );
  const pointerup: React.PointerEventHandler<HTMLCanvasElement> = useCallback(
    (e) => {
      try {
        dom.current.releasePointerCapture(e.pointerId);
      } catch {}
      if (state.current.pointer === e.pointerId) {
        state.current.pointer = -1;
      }
    },
    []
  );

  return (
    <Container>
      <canvas
        ref={dom}
        onPointerDown={pointerdown}
        onPointerMove={pointermove}
        onPointerUp={pointerup}
      />
    </Container>
  );
};
