import { Fragment, useMemo, useRef, useState } from 'react';
import { DoubleSide, Mesh, OrthographicCamera } from 'three';
import { v4 as uuidv4 } from 'uuid';

import { EventMesh } from '../../DrawingCompositor/EventMesh';

import {
  BezierPoint,
  ControlPoint,
  useSelectionApiStore,
} from '../useSelectionApi';
import { useWorkbenchStudioToolState } from '../../studioState';
import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import {
  BOX_POINTS,
  MAX_Z_POSITION,
  screenPositionToLocal,
} from '../../../helpers';
import { CubicBezierLine, Line } from '@react-three/drei';
import {
  OperatingSystem,
  getOS,
  useDocumentEventListener,
} from '@vizcom/shared-ui-components';
import { ActiveMask, MaskDisplayMode } from '../ActiveMask';
import { useCanvasTexture } from '../../lib/useCanvasTexture';
import { useStore } from 'zustand';
import {
  OutlinedCircleHandle,
  OutlinedSquareHandle,
  SolidCircleHandle,
  SolidSquareHandle,
} from '../../../utils/Handles';
import { FixedSizeGroup } from '../../../utils/FixedSizeGroup';
import { Line2 } from 'three-stdlib';
import { Area } from '../../../AreaSelector';

import { useBindControl } from './useBindControl';
import { useBindPoint } from './useBindPoint';
import { useBindLine } from './useBindLine';
import { useBindCanvas } from './useBindCanvas';

import PenDefaultCursor from './cursors/pen-default.svg?url';
import PenSubtractCursor from './cursors/pen-subtract.svg?url';
import PenAddCursor from './cursors/pen-add.svg?url';
import PenCurveCursor from './cursors/pen-curve.svg?url';
import PenEditCursor from './cursors/pen-edit.svg?url';
import PenCloseCursor from './cursors/pen-close.svg?url';
import PenNewShapeCursor from './cursors/pen-new-shape.svg?url';

const cursors = {
  default: `url(${PenDefaultCursor}) 9 4, pointer`,
  subtract: `url(${PenSubtractCursor}) 9 4, pointer`,
  add: `url(${PenAddCursor}) 9 4, pointer`,
  curve: `url(${PenCurveCursor}) 9 4, pointer`,
  edit: `url(${PenEditCursor}) 9 4, pointer`,
  close: `url(${PenCloseCursor}) 9 4, pointer`,
  newShape: `url(${PenNewShapeCursor}) 9 4, pointer`,
};

export const BezierSelection = ({
  drawingSize,
}: {
  drawingSize: [number, number];
}) => {
  const selectionApiStore = useSelectionApiStore();
  const { isPrompting } = useWorkbenchStudioToolState((s) => ({
    isPrompting: s.isPrompting,
  }));
  const { canvasTexture } = useCanvasTexture(drawingSize);

  const eventPlaneRef = useRef<Mesh>(null!);
  const draggingLineRef = useRef<[number, number] | null>(null);
  const outlineRef = useRef<Line2>(null!);
  const areaRef = useRef<null | Area>(null);

  const [cursor, setCursor] = useState({
    canvas: cursors.default,
    point: cursors.subtract,
    command: cursors.default,
    line: cursors.add,
  });
  const [selectedPoints, setSelectedPoints] = useState<string[] | null>(null);
  const [selectedControl, setSelectedControl] = useState<{
    id: string;
    inner: boolean;
  } | null>(null);
  const camera = useThree((s) => s.camera) as OrthographicCamera;
  const isMac = getOS() === OperatingSystem.MacOS;

  const penState = useStore(selectionApiStore, (state) => state.penState);
  const { bezierPoints, closed } = penState;

  useDocumentEventListener('keydown', (e) => {
    if (e.altKey) {
      setCursor({
        canvas: cursors.edit,
        point: cursors.curve,
        command: cursors.curve,
        line: cursors.add,
      });
    }

    if ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) {
      setCursor({
        canvas: cursors.edit,
        point: cursors.edit,
        command: cursors.edit,
        line: cursors.edit,
      });
    }
  });

  useDocumentEventListener('keyup', () => {
    setCursor({
      canvas: cursors.default,
      point: cursors.subtract,
      command: cursors.default,
      line: cursors.add,
    });
  });

  const pointToLocal = (x: number, y: number) => {
    return screenPositionToLocal([x, y], camera, eventPlaneRef.current);
  };

  const addBezierPoint = (x: number, y: number, index?: number) => {
    const id = uuidv4() as string;
    if (index !== undefined) {
      selectionApiStore.getState().setPenState((state) => {
        const newPoints = [...state.bezierPoints];
        newPoints.splice(index, 0, {
          x,
          y,
          id,
          innerControl: {
            x,
            y,
            inner: true,
          },
          outerControl: {
            x,
            y,
            inner: false,
          },
        });
        return {
          ...state,
          bezierPoints: newPoints,
        };
      });
    } else {
      selectionApiStore.getState().setPenState((state) => ({
        ...state,
        bezierPoints: [
          ...state.bezierPoints,
          {
            x,
            y,
            id,
            innerControl: {
              x,
              y,
              inner: true,
            },
            outerControl: {
              x,
              y,
              inner: false,
            },
          },
        ],
      }));
    }
    setSelectedPoints([id]);

    return id;
  };

  const controlPointIsInteractable = (
    index: number,
    controlPoint: ControlPoint,
    newPoint?: boolean
  ) => {
    if (newPoint) return true;

    const nextPoint =
      selectionApiStore.getState().penState.bezierPoints[index + 1];

    return !controlPoint.inner || Boolean(nextPoint) || closed;
  };

  const controlPointIsVisible = (
    point: BezierPoint,
    controlPoint: ControlPoint
  ) => {
    if (draggingLineRef.current) {
      const points = selectionApiStore.getState().penState.bezierPoints;
      const previousPoint = points[draggingLineRef.current[0]];
      const nextPoint = points[draggingLineRef.current[1]];

      return nextPoint.id === point.id || previousPoint.id === point.id;
    }

    // no selected points, don't show any control points
    if (!selectedPoints) return false;

    const pointIndex = selectionApiStore
      .getState()
      .penState.bezierPoints.findIndex((p) => p.id === point.id);
    const previousPoint =
      closed && pointIndex === 0
        ? selectionApiStore.getState().penState.bezierPoints.at(-1)
        : selectionApiStore.getState().penState.bezierPoints[pointIndex - 1];
    const nextPoint =
      selectionApiStore.getState().penState.bezierPoints[pointIndex + 1];

    // if the control point's parent is selected, show the control point
    // if initialized, or if it's an inner control point or there is a next point

    if (!controlPoint.initialized) return false;

    if (selectedPoints.includes(point.id)) {
      return (
        controlPoint.initialized || controlPoint.inner || Boolean(nextPoint)
      );
    }

    if (selectedControl?.id === point.id) {
      return true;
    }

    // show if the control is an inner control and the next point is selected
    // or if the control is an outer control and the previous point is selected
    return (
      (selectedPoints.includes(nextPoint?.id) && !controlPoint.inner) ||
      (selectedPoints.includes(previousPoint?.id ?? '') && controlPoint.inner)
    );
  };

  const resetControls = (id: string) => {
    const index = selectionApiStore
      .getState()
      .penState.bezierPoints.findIndex((p) => p.id === id);
    const isLast =
      index === selectionApiStore.getState().penState.bezierPoints.length - 1;

    selectionApiStore.getState().setPenState((state) => {
      const newPoints = [...state.bezierPoints];
      const point = newPoints[index];
      if (!isLast || closed) {
        point.innerControl.x = point.x;
        point.innerControl.y = point.y;
        point.innerControl.initialized = false;
      }
      point.outerControl.x = point.x;
      point.outerControl.y = point.y;
      point.outerControl.initialized = false;

      newPoints[index] = point;

      return {
        ...state,
        bezierPoints: newPoints,
      };
    });
  };

  const drawSelection = () => {
    if (!selectionApiStore.getState().penState.closed) {
      selectionApiStore.getState().editSelectionCanvas((ctx) => {
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      });

      return;
    }

    selectionApiStore.getState().editSelectionCanvas((ctx) => {
      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = '#ffffff';
      ctx.beginPath();
      selectionApiStore.getState().penState.bezierPoints.forEach((point, i) => {
        const p = [
          (point.x + 0.5) * drawingSize[0],
          (0.5 - point.y) * drawingSize[1],
        ];
        if (i === 0) {
          ctx.moveTo(p[0], p[1]);
        }
        const nextPoint =
          selectionApiStore.getState().penState.bezierPoints[i + 1] ||
          selectionApiStore.getState().penState.bezierPoints[0];
        const p2 = [
          (nextPoint.x + 0.5) * drawingSize[0],
          (0.5 - nextPoint.y) * drawingSize[1],
        ];

        const firstControl = point.outerControl;
        const secondControl = nextPoint.innerControl;

        ctx.bezierCurveTo(
          (firstControl!.x + 0.5) * drawingSize[0],
          (0.5 - firstControl!.y) * drawingSize[1],
          (secondControl!.x + 0.5) * drawingSize[0],
          (0.5 - secondControl!.y) * drawingSize[1],
          p2[0],
          p2[1]
        );
      });
      ctx.closePath();
      ctx.fill();
    });
  };

  const adjustControl = ({
    event,
    point,
    control,
    x,
    y,
    secondary,
  }: {
    event: ThreeEvent<PointerEvent>;
    point: BezierPoint;
    control: ControlPoint;
    x: number;
    y: number;
    secondary?: boolean;
  }) => {
    const deltaX = x - point.x;
    const deltaY = y - point.y;

    let adjustedX = secondary ? point.x - deltaX : x;
    let adjustedY = secondary ? point.y - deltaY : y;

    if (event.shiftKey) {
      const angle = Math.atan2(deltaY, deltaX);
      const snapAngle = Math.round(angle / (Math.PI / 4)) * (Math.PI / 4);
      const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      adjustedX = point.x + length * Math.cos(snapAngle) * (secondary ? -1 : 1);
      adjustedY = point.y + length * Math.sin(snapAngle) * (secondary ? -1 : 1);
    }

    if (control.inner) {
      point.innerControl.x = adjustedX;
      point.innerControl.y = adjustedY;
      point.innerControl.initialized = true;
    } else {
      point.outerControl.x = adjustedX;
      point.outerControl.y = adjustedY;
      point.outerControl.initialized = true;
    }
  };

  function dragControls(
    id: string,
    event: ThreeEvent<PointerEvent>,
    newPoint?: boolean
  ) {
    setSelectedControl({
      id,
      inner: false,
    });

    selectionApiStore.getState().setPenState((state) => {
      const newPoints = [...state.bezierPoints];
      const index = newPoints.findIndex((p) => p.id === id);
      const point = newPoints[index];

      const [x, y] = screenPositionToLocal(
        [event.clientX, event.clientY],
        camera,
        eventPlaneRef.current
      );

      if (controlPointIsInteractable(index, point.outerControl, newPoint)) {
        adjustControl({
          event,
          point,
          control: point.outerControl,
          x,
          y,
        });
      }

      if (controlPointIsInteractable(index, point.innerControl, newPoint)) {
        adjustControl({
          event,
          point,
          control: point.innerControl,
          x,
          y,
          secondary: true,
        });
      }

      point.singleControlLock = false;

      newPoints[index] = point;
      return {
        ...state,
        bezierPoints: newPoints,
      };
    });
  }

  const bind = useBindCanvas({
    drawingSize,
    areaRef,
    setSelectedPoints,
    addBezierPoint,
    dragControls,
    drawSelection,
    pointToLocal,
  });

  const bindPoint = useBindPoint({
    selectedPoints,
    draggingLineRef,
    setSelectedPoints,
    resetControls,
    dragControls,
    drawSelection,
    pointToLocal,
  });

  const bindControl = useBindControl({
    setSelectedControl,
    adjustControl,
    drawSelection,
    pointToLocal,
  });

  const bindLine = useBindLine({
    selectedPoints,
    draggingLineRef,
    setSelectedPoints,
    addBezierPoint,
    dragControls,
    drawSelection,
    pointToLocal,
  });

  const renderLines = useMemo(() => {
    const lines = selectionApiStore
      .getState()
      .penState.bezierPoints.map((point, i) => {
        if (i === 0) return null;

        const previousPoint =
          selectionApiStore.getState().penState.bezierPoints[i - 1];
        const innerControl = point.innerControl;
        const outerControl = previousPoint.outerControl;

        return (
          <Fragment key={`line-${point.id}`}>
            <CubicBezierLine
              side={DoubleSide}
              start={[
                selectionApiStore.getState().penState.bezierPoints[i - 1].x *
                  drawingSize[0],
                selectionApiStore.getState().penState.bezierPoints[i - 1].y *
                  drawingSize[1],
                1,
              ]}
              end={[point.x * drawingSize[0], point.y * drawingSize[1], 1]}
              midA={[
                outerControl!.x * drawingSize[0],
                outerControl!.y * drawingSize[1],
                1,
              ]}
              midB={[
                innerControl!.x * drawingSize[0],
                innerControl!.y * drawingSize[1],
                1,
              ]}
              color="blue"
              lineWidth={1}
              // @ts-ignore
              segments={
                selectionApiStore.getState().penState.bezierPoints.length * 50
              }
            />
            {/* thicker transparent line for events */}
            <CubicBezierLine
              transparent
              opacity={0}
              start={[
                selectionApiStore.getState().penState.bezierPoints[i - 1].x *
                  drawingSize[0],
                selectionApiStore.getState().penState.bezierPoints[i - 1].y *
                  drawingSize[1],
                1,
              ]}
              end={[point.x * drawingSize[0], point.y * drawingSize[1], 1]}
              midA={[
                outerControl!.x * drawingSize[0],
                outerControl!.y * drawingSize[1],
                1,
              ]}
              midB={[
                innerControl!.x * drawingSize[0],
                innerControl!.y * drawingSize[1],
                1,
              ]}
              lineWidth={10}
              {...(bindLine({
                i,
              }) as any)}
              userData={{
                cursor: cursor.line,
              }}
            />
          </Fragment>
        );
      });

    if (closed) {
      const previousPoint =
        selectionApiStore.getState().penState.bezierPoints[
          selectionApiStore.getState().penState.bezierPoints.length - 1
        ];
      const point = selectionApiStore.getState().penState.bezierPoints[0];
      const innerControl = point.innerControl;
      const outerControl = previousPoint.outerControl;

      if (!innerControl || !outerControl) return null;

      lines.push(
        <Fragment key="end">
          <CubicBezierLine
            side={DoubleSide}
            start={[
              previousPoint.x * drawingSize[0],
              previousPoint.y * drawingSize[1],
              0.9,
            ]}
            end={[point.x * drawingSize[0], point.y * drawingSize[1], 0.9]}
            midA={[
              outerControl.x * drawingSize[0],
              outerControl.y * drawingSize[1],
              0.9,
            ]}
            midB={[
              innerControl.x * drawingSize[0],
              innerControl.y * drawingSize[1],
              0.9,
            ]}
            color="blue"
            lineWidth={1}
            // @ts-ignore
            segments={
              selectionApiStore.getState().penState.bezierPoints.length * 50
            }
          />
          {/* thicker transparent line for events */}
          <CubicBezierLine
            transparent
            opacity={0}
            start={[
              previousPoint.x * drawingSize[0],
              previousPoint.y * drawingSize[1],
              0.9,
            ]}
            end={[point.x * drawingSize[0], point.y * drawingSize[1], 1]}
            midA={[
              outerControl.x * drawingSize[0],
              outerControl.y * drawingSize[1],
              0.9,
            ]}
            midB={[
              innerControl.x * drawingSize[0],
              innerControl.y * drawingSize[1],
              0.9,
            ]}
            lineWidth={10}
            {...(bindLine({ i: 0 }) as any)}
            userData={{
              cursor: cursor.line,
            }}
          />
        </Fragment>
      );
    }

    return lines;
  }, [bezierPoints, closed, selectedPoints, cursor]);

  useFrame(() => {
    if (areaRef.current) {
      const [x1, y1] = areaRef.current[0];
      const [x2, y2] = areaRef.current[1];

      outlineRef.current.material.opacity = 1;
      const centerX = (x1 + x2) / 2;
      const centerY = (y1 + y2) / 2;

      const scaleX = Math.abs(x1 - x2);
      const scaleY = Math.abs(y1 - y2);

      outlineRef.current.position.set(centerX, centerY, MAX_Z_POSITION);
      outlineRef.current.scale.set(scaleX / 2, scaleY / 2, 1);
    } else {
      outlineRef.current.material.opacity = 0;
      outlineRef.current.position.set(0, 0, MAX_Z_POSITION);
      outlineRef.current.scale.set(0, 0, 1);
    }
  });

  const selectionTexture = useStore(selectionApiStore, (s) => s.texture);

  return (
    <>
      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={bind() as any}
        ref={eventPlaneRef}
        userData={{
          cursor: bezierPoints.length === 0 ? cursors.newShape : cursor.canvas,
        }}
      />
      <ActiveMask
        drawingSize={drawingSize}
        maskTexture={!closed ? canvasTexture : selectionTexture}
        mode={
          isPrompting ? MaskDisplayMode.MARCHING_ANTS : MaskDisplayMode.FILL
        }
      />

      <group renderOrder={1}>
        <Line
          ref={outlineRef}
          points={BOX_POINTS}
          color={0x7070f2}
          lineWidth={1.5}
          transparent
          side={DoubleSide}
        />
        {bezierPoints.map((point, i) => (
          <Fragment key={point.id}>
            <FixedSizeGroup
              position={[
                point.x * drawingSize[0],
                point.y * drawingSize[1],
                1.1,
              ]}
            >
              {selectedPoints?.includes(point.id) ? (
                <SolidSquareHandle
                  size={5}
                  handleMargin={10}
                  color="blue"
                  {...(bindPoint({ id: point.id }) as any)}
                  renderOrder={1}
                  userData={{
                    cursor: !closed && i === 0 ? cursors.close : cursor.point,
                  }}
                />
              ) : (
                <OutlinedSquareHandle
                  size={5}
                  handleMargin={10}
                  color="blue"
                  {...(bindPoint({ id: point.id }) as any)}
                  renderOrder={1}
                  userData={{
                    cursor: !closed && i === 0 ? cursors.close : cursor.point,
                  }}
                />
              )}
            </FixedSizeGroup>

            <FixedSizeGroup
              position={[
                point.innerControl.x * drawingSize[0],
                point.innerControl.y * drawingSize[1],
                1.1,
              ]}
            >
              {selectedControl &&
              selectedControl.id === point.id &&
              selectedControl.inner ? (
                <SolidCircleHandle
                  size={5}
                  handleMargin={10}
                  color="blue"
                  visible={controlPointIsVisible(point, point.innerControl)}
                  {...(controlPointIsVisible(point, point.innerControl) && {
                    ...(bindControl({ id: point.id, inner: true }) as any),
                  })}
                  userData={{
                    cursor: cursor.command,
                  }}
                />
              ) : (
                <OutlinedCircleHandle
                  size={5}
                  handleMargin={10}
                  color="blue"
                  visible={controlPointIsVisible(point, point.innerControl)}
                  {...(controlPointIsVisible(point, point.innerControl) && {
                    ...(bindControl({ id: point.id, inner: true }) as any),
                  })}
                  userData={{
                    cursor: cursor.command,
                  }}
                />
              )}
            </FixedSizeGroup>

            <Line
              side={DoubleSide}
              points={[
                point.innerControl.x * drawingSize[0],
                point.innerControl.y * drawingSize[1],
                0.9,
                point.x * drawingSize[0],
                point.y * drawingSize[1],
                0.9,
              ]}
              color="blue"
              lineWidth={1}
              visible={controlPointIsVisible(point, point.innerControl)}
            />

            <FixedSizeGroup
              position={[
                point.outerControl.x * drawingSize[0],
                point.outerControl.y * drawingSize[1],
                1.1,
              ]}
            >
              {selectedControl &&
              selectedControl.id === point.id &&
              !selectedControl.inner ? (
                <SolidCircleHandle
                  size={5}
                  handleMargin={10}
                  color="blue"
                  visible={controlPointIsVisible(point, point.outerControl)}
                  {...(controlPointIsVisible(point, point.outerControl) && {
                    ...(bindControl({ id: point.id, inner: false }) as any),
                  })}
                  userData={{
                    cursor: cursor.command,
                  }}
                />
              ) : (
                <OutlinedCircleHandle
                  size={5}
                  handleMargin={10}
                  color="blue"
                  visible={controlPointIsVisible(point, point.outerControl)}
                  {...(controlPointIsVisible(point, point.outerControl) && {
                    ...(bindControl({ id: point.id, inner: false }) as any),
                  })}
                  userData={{
                    cursor: cursor.command,
                  }}
                />
              )}
            </FixedSizeGroup>

            <Line
              side={DoubleSide}
              points={[
                point.outerControl.x * drawingSize[0],
                point.outerControl.y * drawingSize[1],
                0.9,
                point.x * drawingSize[0],
                point.y * drawingSize[1],
                0.9,
              ]}
              color="blue"
              lineWidth={1}
              visible={controlPointIsVisible(point, point.outerControl)}
            />
          </Fragment>
        ))}
        {renderLines}
      </group>
    </>
  );
};
