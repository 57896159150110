import { assertUnreachable } from '@vizcom/shared/js-utils';
import { ClientSideWorkbenchElementData } from '../../lib/clientState';

export const getElementDefaultSize = (
  type: ClientSideWorkbenchElementData['__typename']
) => {
  switch (type) {
    case 'WorkbenchElementImg2Img':
      return { x: 320, y: 103 };
    case 'WorkbenchElementPalette':
      return { x: 180, y: 180 };
    case 'WorkbenchElementText':
      return { x: 200, y: 30 };
    case 'CompositeScene':
    case 'WorkbenchElementPlaceholder':
    case 'WorkbenchElementMix':
    case 'Drawing':
      return { x: 320, y: 180 };
    case 'WorkbenchElementSection':
      return { x: 200, y: 200 };
  }
  assertUnreachable(type);
};
