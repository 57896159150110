/*
Implementation of a 2d Centripetal Catmull-Rom spline.
Original code from the threejs repo:
https://github.com/mrdoob/three.js/blob/7b1f2725fa39f97c26242744c0ebbf83ad595ae1/src/extras/curves/CatmullRomCurve3.js

Original source from:
https://stackoverflow.com/questions/9489736/catmull-rom-curve-with-no-cusps-and-no-self-intersections/
http://www.cemyuksel.com/research/catmullrom_param/catmullrom.pdf

A Hermite spline is a curve going through a set of ordered points. The tangents at the points must be provided with the points.
A Catmull-Rom spline is a special kind of Hermite spline where the tangents are determined from the points.
https://en.wikipedia.org/wiki/Centripetal_Catmull%E2%80%93Rom_spline
https://en.wikipedia.org/wiki/Cubic_Hermite_spline

Excellent introduction to splines:
https://www.youtube.com/watch?v=jvPPXbo87ds
*/

import { Vector2 } from 'three';

export type CubicCoefficients = {
  c0: number;
  c1: number;
  c2: number;
  c3: number;
};

export function evalCubicPoly(coefficients: CubicCoefficients, t: number) {
  const { c0, c1, c2, c3 } = coefficients;
  const t2 = t * t;
  const t3 = t2 * t;
  return c0 + c1 * t + c2 * t2 + c3 * t3;
}

//Auxiliary function for `computeCentripetalCatmullRomCoefficient`
function computeCubicCoefficients(
  x0: number,
  x1: number,
  x2: number,
  x3: number,
  dt0: number,
  dt1: number,
  dt2: number
): CubicCoefficients {
  /*
    Tangents of the cubic segment
    simplification of the Barry and Goldman recursive forumla.
    Figured out by some guy on StackOverflow using Mathematica: https://stackoverflow.com/a/23980479
    Seems legit.
  */
  let t1 = (x1 - x0) / dt0 - (x2 - x0) / (dt0 + dt1) + (x2 - x1) / dt1;
  let t2 = (x2 - x1) / dt1 - (x3 - x1) / (dt1 + dt2) + (x3 - x2) / dt2;
  t1 *= dt1;
  t2 *= dt1;

  //Hermite coefficients from points and tangents.
  //https://en.wikipedia.org/wiki/Cubic_Hermite_spline#Interpolation_on_a_single_interval
  return {
    c0: x1,
    c1: t1,
    c2: -3 * x1 + 3 * x2 - 2 * t1 - t2,
    c3: 2 * x1 - 2 * x2 + t1 + t2,
  };
}

/*
  Returns the coefficiens for a cubic segment of a spline.
  The segment starts at p1 and ends at p2. p0 and p3 are used to determine the tangents.
  The tangents are computed for a Centripetal Catmull-Rom spline
*/
export function getCubicSplineSegmentCoefficients(
  p0: Vector2,
  p1: Vector2,
  p2: Vector2,
  p3: Vector2
) {
  //computes shared values
  let dt0 = Math.pow(p0.distanceToSquared(p1), 0.25);
  let dt1 = Math.pow(p1.distanceToSquared(p2), 0.25);
  let dt2 = Math.pow(p2.distanceToSquared(p3), 0.25);
  if (dt1 < 1e-4) dt1 = 1.0;
  if (dt0 < 1e-4) dt0 = dt1;
  if (dt2 < 1e-4) dt2 = dt1;
  return {
    px: computeCubicCoefficients(p0.x, p1.x, p2.x, p3.x, dt0, dt1, dt2),
    py: computeCubicCoefficients(p0.y, p1.y, p2.y, p3.y, dt0, dt1, dt2),
  };
}

const auxA = new Vector2();
const auxB = new Vector2();
//Subdivides a cubic segment into straight segments, sums their lengths.
export function computeCubicSegmentLength(
  px: CubicCoefficients,
  py: CubicCoefficients,
  start: Vector2,
  end: Vector2
) {
  let length = 0;
  const divisions = Math.min(
    Math.max(Math.ceil(start.distanceTo(end) * 2), 1),
    200
  );
  const last = auxA;
  const current = auxB;
  last.set(evalCubicPoly(px, 0), evalCubicPoly(py, 0));
  for (let d = 1; d <= divisions; d++) {
    const t = d / divisions;
    current.set(evalCubicPoly(px, t), evalCubicPoly(py, t));
    length += current.distanceTo(last);
    last.copy(current);
  }
  return length;
}
