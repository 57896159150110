import { useEffect, useRef, useState, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { useIsWorkbenchViewer } from '../../lib/utils';

export const WorkbenchNameInput = ({
  handleUpdate,
  value,
  isEditing,
  setIsEditing,
}: {
  handleUpdate: (name: string) => void;
  value?: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  const [name, setName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const isViewer = useIsWorkbenchViewer();

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!e.repeat && e.key.toLowerCase() === 'enter') {
      setIsEditing(false);
      handleUpdate(name);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setName('');
      inputRef.current!.select();
    }
  }, [isEditing]);

  if (isEditing) {
    return (
      <Input
        ref={inputRef}
        type="text"
        onBlur={() => {
          setIsEditing(false);
          handleUpdate(name);
        }}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        onSubmit={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        value={name || value}
      />
    );
  }

  return (
    <TruncatedName
      onDoubleClick={() => {
        if (!isViewer) {
          setIsEditing(true);
        }
      }}
    >
      {value ?? 'Untitled'}
    </TruncatedName>
  );
};

const TruncatedName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-area: name;
`;

const Input = styled.input`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  height: 100%;
  appearance: none;
  padding: 6px 4px;
  border-radius: ${({ theme }) => theme.borderRadius.subtle};
  margin-left: -4px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.primary.default};
  }
`;
