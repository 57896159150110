import { useFrame } from '@react-three/fiber';
import { WORKBENCH_CONTENT_RENDERING_ORDER } from '../constants';

// NOTE Helper component allowing rendering additional layers underneath the primary
//      three.js scene (NOTE: This overrides the default rendering, https://docs.pmnd.rs/react-three-fiber/api/hooks#taking-over-the-render-loop)
//      Rendered as an overlay of <BackgroundLayer /> components
export const SceneLayer = () => {
  useFrame(({ gl, scene, camera }) => {
    gl.clearDepth();
    gl.clearStencil();

    gl.render(scene, camera);
  }, WORKBENCH_CONTENT_RENDERING_ORDER.indexOf('globalScene'));

  return null;
};
