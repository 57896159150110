import { createPortal } from 'react-dom';
import { TooltipMenuContainer, TooltipItem, TooltipWrapper } from './style';
import {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

// Constants for initial setup and layout adjustments
const INITIAL_POSITION = { left: 0, top: 0, height: 0, width: 0 };
const HEADER_HEIGHT = 55;
const VERTICAL_OFFSET = 10;
const HORIZONTAL_OFFSET = 16;
const ARROW_DISPLACE = 6;
const MIN_ARROW_HEIGHT = -9999;

type ItemProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  onClick: MouseEventHandler<HTMLDivElement>;
  style?: CSSProperties;
};

export const TooltipMenuItem = ({ icon, label, onClick, style }: ItemProps) => {
  return (
    <TooltipItem onClick={onClick} style={style}>
      <div
        style={{
          minWidth: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </div>
      <div>{label}</div>
    </TooltipItem>
  );
};

type TooltipProps = {
  isOpen: boolean;
  onClose: () => void;
  direction?: 'up' | 'down' | 'left' | 'right';
  customVerticalMargin?: number;
  customHorizontalMargin?: number;
  showArrow?: boolean;
};

type Position = {
  left: number;
  top: number;
  height: number;
  width: number;
};

type Direction = 'up' | 'down' | 'left' | 'right';

// Function to adjust the tooltip position
const adjustPosition = (
  parentRect: DOMRect,
  menuRect: DOMRect,
  direction: Direction,
  customHorizontalMargin = HORIZONTAL_OFFSET,
  customVerticalMargin = VERTICAL_OFFSET
): Position => {
  // Calculate vertical position and ensure it doesn't go off-screen
  const pos = parentRect.top - menuRect.height / 2 + customVerticalMargin;

  // Adjust vertical position to keep tooltip within viewport
  let top = Math.max(pos, HEADER_HEIGHT); // Ensure top is not above header or viewport top
  if (top + menuRect.height > window.innerHeight) {
    // Adjust top if tooltip extends beyond viewport bottom
    top = window.innerHeight - menuRect.height - customVerticalMargin;
  }

  // Additional check for 'up' direction
  if (direction === 'up') {
    top += ARROW_DISPLACE;
  }

  // Calculate arrow position
  let height = (menuRect.height + customVerticalMargin) / 2 - (top - pos);
  if (height > menuRect.height - 2 * customVerticalMargin) {
    // if the arrow would clip off the bound menu, dont bother showing it
    height = MIN_ARROW_HEIGHT;
  }

  // Calculate horizontal position
  const width = menuRect.width / 2 - customVerticalMargin;
  let left = 0;
  if (direction === 'right') {
    left = parentRect.left - menuRect.width - customHorizontalMargin;
  } else if (direction === 'up' || direction === 'down') {
    left = parentRect.left - menuRect.width / 2 - customHorizontalMargin;
  } else {
    left = parentRect.left + parentRect.width + customHorizontalMargin;
  }

  // Ensure the tooltip does not go off-screen horizontally
  top = Math.min(
    Math.max(top, HEADER_HEIGHT),
    window.innerHeight - menuRect.height - customVerticalMargin
  );
  left = Math.min(
    Math.max(left, 0),
    window.innerWidth - menuRect.width - customHorizontalMargin
  );

  return { top, left, height, width };
};

export const TooltipMenu = ({
  isOpen,
  onClose,
  children,
  direction = 'right',
  customHorizontalMargin,
  customVerticalMargin,
  showArrow,
}: PropsWithChildren<TooltipProps>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState(INITIAL_POSITION);

  const handleOutsideClickOrScroll = useCallback(
    (event: Event) => {
      // Cast the event target to Node to check if it's contained within menuRef
      if (menuRef.current && menuRef.current.contains(event.target as Node)) {
        return;
      }
      onClose();
    },
    [onClose]
  );

  useLayoutEffect(() => {
    const parentRect = containerRef.current?.getBoundingClientRect();
    const menuRect = menuRef.current?.getBoundingClientRect();

    if (!parentRect || !menuRect) {
      return;
    }

    setPosition(
      adjustPosition(
        parentRect,
        menuRect,
        direction,
        customHorizontalMargin,
        customVerticalMargin
      )
    );
  }, [isOpen, direction, customHorizontalMargin, customVerticalMargin]);

  // Event listeners for outside click, scroll, and Escape key
  useEffect(() => {
    const handleEscape = (
      event: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>
    ) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener('pointerdown', handleOutsideClickOrScroll);
      window.addEventListener('scroll', handleOutsideClickOrScroll, true);
      window.addEventListener('keydown', handleEscape);
    }

    return () => {
      window.removeEventListener('pointerdown', handleOutsideClickOrScroll);
      window.removeEventListener('scroll', handleOutsideClickOrScroll, true);
      window.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose, handleOutsideClickOrScroll]);

  // Adjust for window resize
  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        onClose();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpen, onClose]);

  return (
    <TooltipWrapper ref={containerRef}>
      {isOpen &&
        createPortal(
          <TooltipMenuContainer
            ref={menuRef}
            $left={position.left}
            $top={position.top}
            $height={position.height}
            $width={position.width}
            $direction={direction}
            $showArrow={showArrow !== false}
          >
            {children}
          </TooltipMenuContainer>,
          document.body
        )}
    </TooltipWrapper>
  );
};
