import styled from 'styled-components';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { TransitionStatus } from 'react-transition-group';
import { keyframes } from 'styled-components';
import { Create } from './create/Create';
import { SidebarHeader, SidebarHeaderContent } from './style';
import { InferenceSettings } from './useInference';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { animated, useSpring } from '@react-spring/web';
import { PromptHistoryItem } from './history/WorkbenchStudioHistory';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { Layout } from './types';

const slideInFromRight = keyframes`
  from {
    transform: translateX(calc(100% + 14px));
  }
  to {
    transform: translateX(0);
  }
`;

const StudioTabs = styled(animated.div)<{
  $state: TransitionStatus;
  $hidden: boolean;
  $layout: Layout;
}>`
  pointer-events: all;
  position: absolute;
  top: calc(1rem + 66px);
  right: 14px;
  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateX(0)'
      : 'translateX(calc(100% + 14px))'};
  animation: ${({ $state }) =>
      $state === 'entering' ? slideInFromRight : 'none'}
    0.5s ease;
  transition: transform 0.5s ease-in-out;
  ${({ $layout }) => {
    if ($layout === 'default') {
      return `
        bottom: 100px;
      `;
    } else if ($layout === 'stacked') {
      return `
        height: calc(calc(100% * 0.55) - 100px);
      `;
    }
  }}
  width: 250px;
  border-radius: 16px;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.text.info};
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'all')};
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  * {
    pointer-events: ${({ $hidden }) => ($hidden ? 'none !important' : 'all')};
  }
`;

type Props = {
  hidden: boolean;
  state: TransitionStatus;
  drawing: Drawing2dStudio;
  anyOutputLoading?: boolean;
  inferenceSettings: InferenceSettings;
  selectedPromptHistoryItem: PromptHistoryItem;
  uiRatio: number;
  isGeneratingPrompt: boolean;
  applyHistoryItem: (inferenceSettings: InferenceSettings) => void;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  triggerPaywallModal: () => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  trigger: () => void;
  triggerAutoPrompt: () => void;
  setIsPrompting: (value: boolean) => void;
  cancelAutoPrompt: () => void;
};

const WorkbenchStudioCreate = (props: Props) => {
  const [spring, api] = useSpring(() => ({}));
  const isViewer = useIsWorkbenchViewer();
  const ref = useRef<HTMLDivElement>(null);
  const userPreferedLayout = useCurrentUserClientStateByKey(
    UserClientStateKeys.StudioLayout
  );
  const layout: Layout = isViewer ? 'default' : userPreferedLayout || 'default';

  useEffect(() => {
    if (!props.selectedPromptHistoryItem?.output.id) return;

    // flash the create tab when a new history item is selected
    api.start({
      from: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 1)`,
      },
      to: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 0)`,
      },
      config: { duration: 700 },
    });
  }, [props.selectedPromptHistoryItem?.output.id]);

  useEffect(() => {
    const onWindowResize = () => {
      if (!ref.current) return;
      if (layout !== 'stacked') return;
      const newHeight = window.innerHeight * (1 - props.uiRatio) - 101;
      ref.current.style.height = `${newHeight}px`;
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [layout, props.uiRatio]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    if (layout !== 'stacked') {
      ref.current.style.height = 'auto';
      return;
    }

    const newHeight = window.innerHeight * (1 - props.uiRatio) - 101;
    ref.current.style.height = `${newHeight}px`;
  }, [props.uiRatio, layout]);

  if (isViewer) {
    return null;
  }

  return (
    <StudioTabs
      ref={ref}
      $state={props.state}
      style={spring}
      $hidden={props.hidden}
      $layout={layout}
      onFocus={() => props.setIsPrompting(true)}
      onBlur={() => props.setIsPrompting(false)}
    >
      <SidebarHeader>
        <SidebarHeaderContent>Create</SidebarHeaderContent>
      </SidebarHeader>
      <Create
        drawing={props.drawing}
        anyOutputLoading={props.anyOutputLoading}
        handleAction={props.handleAction}
        inferenceSettings={props.inferenceSettings}
        setInferenceSettings={props.setInferenceSettings}
        trigger={props.trigger}
        triggerAutoPrompt={props.triggerAutoPrompt}
        triggerPaywallModal={props.triggerPaywallModal}
        selectedHistoryPrompt={props.selectedPromptHistoryItem?.prompt}
        applyHistoryItem={props.applyHistoryItem}
        isGeneratingPrompt={props.isGeneratingPrompt}
        cancelAutoPrompt={props.cancelAutoPrompt}
      />
    </StudioTabs>
  );
};

export default WorkbenchStudioCreate;
