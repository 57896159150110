import { useDeferredValue } from 'react';
import { useImageDataTexture } from '../helpers';
import { Drawing2dStudio } from '../../lib/useDrawingSyncedState';
import { useImageTextures } from '../../lib/useImageTexture';
import { Texture } from 'three';

export const WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS = 1000;

export function swap(array: number[], moveIndex: number, toIndex: number) {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
}

export const useLayerTexture = (
  layer: Drawing2dStudio['layers']['nodes'][0]
) => {
  // When another client edit a layer, its URL will change, while loading this new image, we want to keep the old image
  // on the screen. To do this we use `useDeferredValue` to keep the old URL while the Suspense operation triggered by `useImageTextures`
  // finishes loading the new image. We only do this if `imagePath` is an URL, if it's an ImageData or a Blob, we always want to use the last version
  // to prevent any flashes of the old texture
  const deferredImagePath = useDeferredValue(layer.imagePath);

  const imageDataTexture = useImageDataTexture(
    layer.imagePath instanceof ImageData
      ? layer.imagePath
      : deferredImagePath instanceof ImageData &&
        typeof layer.imagePath === 'string'
      ? // when the user edited the layer and then another user edit the same layer, imagePath is then an URL but we want to keep the old ImageData
        // until the new image is loaded
        deferredImagePath
      : undefined
  );

  const [_image] = useImageTextures(
    layer.imagePath instanceof Blob
      ? [layer.imagePath]
      : typeof deferredImagePath === 'string'
      ? [deferredImagePath]
      : []
  );

  return (imageDataTexture ?? _image) as Texture | undefined;
};

export function imageDataIsBlank(imageData: ImageData) {
  for (let i = 0; i < imageData.data.length; i += 4) {
    if (
      imageData.data[i] !== 255 ||
      imageData.data[i + 1] !== 255 ||
      imageData.data[i + 2] !== 255
    ) {
      return false;
    }
  }
  return true;
}

export function imageDataIsTransparent(imageData: ImageData) {
  for (let i = 0; i < imageData.data.length; i += 4) {
    if (imageData.data[i + 3] !== 0) {
      return false;
    }
  }
  return true;
}
