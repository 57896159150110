import { useCallback, useRef, useState } from 'react';

// Similar hook as `useState` but also provides a ref to the current state
// this lets function have a simple way to always get the last version of this state, no matter when it was last updated
// or if a re-render already happened
export function useStateWithRef<T>(initialValue: T) {
  const [state, setState] = useState(initialValue);
  const ref = useRef(state);

  const setStateAndRef = useCallback<typeof setState>((newStateOrSetter) => {
    const newState =
      typeof newStateOrSetter === 'function'
        ? (newStateOrSetter as (prevState: T) => T)(ref.current)
        : newStateOrSetter;
    ref.current = newState;
    setState(newState);
  }, []);

  return [state, setStateAndRef, ref] as const;
}
