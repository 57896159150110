import { useWebConfig } from '@vizcom/shared/data-access/graphql';
import { GoogleButton } from './styled';
import googleLogo from '../assets/google-logo.png';

export const LogInWithGoogleButton = () => {
  const { data } = useWebConfig();

  if (!data?.loginWithGoogle) {
    return null;
  }

  return (
    <GoogleButton
      rel="noopener noreferrer"
      href={`${
        import.meta.env.NX_VIZCOM_API_BASE_URL || '/api/v1'
      }/auth/google`}
    >
      <img
        style={{ width: '12px', height: '12px' }}
        src={googleLogo}
        alt="Google Logo"
      />

      <span>Continue with Google</span>
    </GoogleButton>
  );
};
