import { OrganizationSubscriptionPlan } from './plans-types';

export const PLAN_PRICES_PER_EDITOR: Record<
  OrganizationSubscriptionPlan,
  number
> = {
  FREE: 0,
  PRO: 49,
  ORGANIZATION: 250,
  ENTERPRISE: 0,
};
