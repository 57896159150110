import { Vector3 } from 'three';
import { useSelectionApiStore } from '../useSelectionApi';
import { ThreeEvent } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { OperatingSystem, getOS } from '@vizcom/shared-ui-components';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { Area, getArea, getBounds } from '../../../AreaSelector';
import { useStore } from 'zustand';

const v3 = new Vector3();

type Props = {
  drawingSize: [number, number];
  areaRef: MutableRefObject<Area | null>;
  setSelectedPoints: Dispatch<SetStateAction<string[] | null>>;
  addBezierPoint: (x: number, y: number, index?: number) => void;
  dragControls: (
    id: string,
    event: ThreeEvent<PointerEvent>,
    newPoint?: boolean
  ) => void;
  drawSelection: () => void;
  pointToLocal: (x: number, y: number) => number[];
};

export const useBindCanvas = ({
  drawingSize,
  areaRef,
  setSelectedPoints,
  addBezierPoint,
  dragControls,
  drawSelection,
  pointToLocal,
}: Props) => {
  const selectionApiStore = useSelectionApiStore();
  const isMac = getOS() === OperatingSystem.MacOS;
  const bezierPoints = useStore(
    selectionApiStore,
    (state) => state.penState.bezierPoints
  );

  return useDrag<ThreeEvent<PointerEvent>>(
    ({ event, memo, last, movement }) => {
      event.stopPropagation();

      const point = pointToLocal(event.clientX, event.clientY);

      if ((isMac && event.metaKey) || (!isMac && event.ctrlKey) || memo?.area) {
        v3.set(point[0] * drawingSize[0], point[1] * drawingSize[1], 1);
        areaRef.current = getArea(v3, areaRef.current);

        if (last) {
          const [xBounds, yBounds] = getBounds(areaRef.current!);
          const selected = selectionApiStore
            .getState()
            .penState.bezierPoints.filter((point) => {
              const x = point.x * drawingSize[0];
              const y = point.y * drawingSize[1];
              return (
                x >= xBounds[0] &&
                x <= xBounds[1] &&
                y >= yBounds[0] &&
                y <= yBounds[1]
              );
            })
            .map((point) => point.id);
          setSelectedPoints(selected);

          areaRef.current = null;
        }

        return {
          area: true,
        };
      }

      if (memo?.created) {
        const bezierPoint = selectionApiStore
          .getState()
          .penState.bezierPoints.find((p) => p.id === memo.created);
        const hasMovedEnough =
          (bezierPoint?.innerControl.initialized &&
            bezierPoint?.outerControl.initialized) ||
          Math.abs(movement[0]) > 5 ||
          Math.abs(movement[1]) > 5;

        if (hasMovedEnough) {
          dragControls(memo.created, event, true);
        }

        if (last) {
          drawSelection();
        }
        return;
      }

      if (!memo?.created) {
        let id;

        // When shift is pressed, snap the orientation of the handles to 45deg increments
        if (event.shiftKey) {
          const lastPoint = bezierPoints[bezierPoints.length - 1];

          const deltaX = point[0] - lastPoint.x;
          const deltaY = point[1] - lastPoint.y;

          const angle = Math.atan2(deltaY, deltaX);
          const snapAngle = Math.round(angle / (Math.PI / 4)) * (Math.PI / 4);
          const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

          const adjustedX = lastPoint.x + length * Math.cos(snapAngle);
          const adjustedY = lastPoint.y + length * Math.sin(snapAngle);

          id = addBezierPoint(adjustedX, adjustedY);
        } else {
          id = addBezierPoint(point[0], point[1]);
        }

        return {
          created: id,
        };
      } else {
        return {
          created: memo.created,
        };
      }
    }
  );
};
