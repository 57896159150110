import { useNavigate } from 'react-router-dom';
import {
  Button,
  CarretDownIcon,
  HomeIcon,
  Menu,
  MenuDivider,
  MenuItem,
  Model3dIcon,
  Text,
  VizcomLogo,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';
import { FileMenu } from '../../workbenchMenu/styles';
import styled from 'styled-components';
import { CompositeSceneFullDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useWorkbench } from '@vizcom/shared/data-access/graphql';

export const WorkbenchCompositeSceneMenu = ({
  compositeScene,
}: {
  compositeScene: CompositeSceneFullDataFragment;
}) => {
  const navigate = useNavigate();
  const workbench = useWorkbench(compositeScene.workbenchId);

  return (
    <>
      <FileMenu
        style={{
          display: 'flex',
          gap: 4,
        }}
      >
        <Menu
          renderLabel={(props, interactionProps) => (
            <MenuButton
              variant="transparent"
              size="iconSquared"
              {...interactionProps}
            >
              <VizcomLogo />
              <CarretDownIcon />
            </MenuButton>
          )}
        >
          <MenuItem
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <HomeIcon /> Go home
              </div>
            }
            onClick={() =>
              navigate(
                workbench.data?.folderId
                  ? paths.files.folder(workbench.data.folderId)
                  : '/'
              )
            }
          />
          <MenuDivider />
          <MenuItem
            label="Documentation"
            onClick={() => window.open(`https://docs.vizcom.ai/`, '_blank')}
          />
          <MenuItem
            label="Account settings"
            onClick={() => navigate(paths.settings.account.profile())}
          />
        </Menu>
        <Model3dIcon style={{ color: 'white', margin: '0 8px' }} />
        <Text type="sh2">3D Scene</Text>
      </FileMenu>
    </>
  );
};

export const VerticalDivider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.surface.e2};
`;

const MenuButton = styled(Button)`
  &:not(:disabled):hover {
    box-shadow: none;
    background-color: transparent;
  }
`;
