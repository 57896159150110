import { CheckmarkIcon, MenuItem } from '@vizcom/shared-ui-components';
import {
  publishTrackingEvent,
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import styled from 'styled-components';
import { Layout } from '../types';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { StudioEventName } from '@vizcom/shared/data-shape';

export const WorkbenchStudioMenuLayoutItem = () => {
  const [, updateState] = useSetCurrentUserClientState();
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';

  const setLayout = (layout: Layout) => {
    updateState({
      input: {
        key: UserClientStateKeys.StudioLayout,
        value: layout,
      },
    });
    trackEvent('2D Studio Layout Changed', { layout });
    publishTrackingEvent({
      type: StudioEventName.LAYOUT_CHANGED,
      data: {
        layout,
      },
    });
  };

  return (
    <MenuItem label="Layout">
      <MenuItem
        prependLabel={<StyledCheckmark $visible={layout === 'default'} />}
        label="Default"
        onClick={() => setLayout('default')}
      />
      <MenuItem
        prependLabel={<StyledCheckmark $visible={layout === 'classic'} />}
        label="Classic"
        onClick={() => setLayout('classic')}
      />
      <MenuItem
        prependLabel={<StyledCheckmark $visible={layout === 'stacked'} />}
        label="Stacked"
        onClick={() => setLayout('stacked')}
      />
    </MenuItem>
  );
};

const StyledCheckmark = styled(CheckmarkIcon)<{ $visible: boolean }>`
  visibility: ${(p) => (p.$visible ? 'visible' : 'hidden')};
  // width: 8px;
  // height: 8px;
  margin-right: 10px;
  > path {
    stroke: ${(p) => p.theme.icon.default};
  }
`;
