import { useEffect, useRef } from 'react';
import { assertUnreachable } from '@vizcom/shared/js-utils';
import { useObjectURL } from '@vizcom/shared-ui-components';

export const RawImage = ({
  data,
  className,
}: {
  data: ImageData | Blob | string;
  className?: string;
}) => {
  if (data instanceof ImageData) {
    return <RawImageData data={data} className={className} />;
  } else if (data instanceof Blob) {
    return <RawImageBlob data={data} className={className} />;
  } else if (typeof data === 'string') {
    return <img crossOrigin="anonymous" src={data} className={className} />;
  } else {
    assertUnreachable(data);
  }
};

const RawImageData = ({
  data,
  className,
}: {
  data: ImageData;
  className?: string;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null!);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      throw new Error('Could not get 2d context from canvas');
    }
    canvasRef.current.width = data.width;
    canvasRef.current.height = data.height;
    ctx.clearRect(0, 0, data.width, data.height);
    ctx.putImageData(data, 0, 0);
  }, [data]);

  return <canvas ref={canvasRef} className={className} />;
};

const RawImageBlob = ({
  data,
  className,
}: {
  data: Blob;
  className?: string;
}) => {
  const url = useObjectURL(data);

  return <img src={url} className={className} />;
};
