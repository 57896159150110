import styled, { useTheme } from 'styled-components';
import {
  ArrowIcon,
  Button,
  FullPageDarkLoader,
  RedoIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  PaintBucketIcon,
  SymmetryIcon,
  UndoIcon,
  getShortcutKey,
  slideInFromTop,
  useKeyboardShortcut,
  Text,
  WorkbenchIcon,
  NewFeatureName,
  AutoplayVideo,
  NewFeatureButton,
  SelectIcon,
  RectangleIcon,
  BrushSelectionIcon,
  EllipseIcon,
  LassoIcon,
  LineIcon,
  BrushIcon,
  Convert2dTo3dIcon,
  EraserIcon,
  SkewIcon,
  BezierIcon,
  AutoSelectionIcon,
  OnboardingTourTooltipContent,
  useShouldDisplayOnboardingTour,
} from '@vizcom/shared-ui-components';
import {
  WorkbenchStudioTool,
  isSelectionTool,
  selectionTools,
  useWorkbenchStudioToolState,
} from '../studioState';
import { TransformSettings } from './TransformSettings';
import { BrushColorInput } from './BrushColorInput';
import { BrushSettings } from './BrushSettings';
import { EraserSettings } from './EraserSettings';
import { Overlay } from '../style';
import { InsertButton } from './InsertButton';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { ShapeSettings } from './ShapeSettings';
import { SymmetrySettings } from './SymmetrySettings';
import { ToolbarMenu } from './ToolbarMenu';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { TransitionStatus } from 'react-transition-group';
import { useEffect, useState } from 'react';
import { PromptHistoryItem } from '../history/WorkbenchStudioHistory';
import { SelectionSettings } from '../selection/SelectionSettings';
import { useSubscribeToSelectionApi } from '../selection/useSelectionApi';
import exit2dStudioVideo from '../../../assets/videos/exit-2d-studio.mp4';
import tooltip_2d_3d from '../../../assets/videos/tooltip_2d_3d.mp4';
import tooltip_bezier_selection from '../../../assets/videos/tooltip_bezier_selection.mp4';
import tooltip_brush_selection from '../../../assets/videos/tooltip_brush_selection.mp4';
import tooltip_lasso_selection from '../../../assets/videos/tooltip_lasso_selection.mp4';
import { Generate3dLayerMenu } from './Generate3dLayerMenu';
import {
  OnboardingStep,
  useOnboardingMultiStep,
} from '../../utils/OnboardingMultiStep';

import educationalTooltipVideo01 from '../../../assets/videos/educational-tooltip-01.mp4';
import educationalTooltipVideo04 from '../../../assets/videos/educational-tooltip-04.mp4';

const TRANSFORM_TOOLS = [
  {
    type: WorkbenchStudioTool.Move,
    icon: <SelectIcon />,
    tip: 'Move',
    hotkey: 'M',
  },
  {
    type: WorkbenchStudioTool.Transform,
    icon: <SkewIcon />,
    tip: 'Skew',
    hotkey: 'T',
  },
];

const BRUSH_TOOLS = [
  {
    type: WorkbenchStudioTool.Brush,
    icon: <BrushIcon />,
    tip: 'Brush',
    hotkey: 'B',
  },
  {
    type: WorkbenchStudioTool.PaintBucket,
    icon: <PaintBucketIcon />,
    tip: 'Paint Bucket',
    hotkey: '',
  },
];

const SHAPE_TOOLS = [
  {
    type: 'rectangle',
    icon: <RectangleIcon />,
    tip: 'Rectangle',
    hotkey: 'U',
  },
  {
    type: 'ellipse',
    icon: <EllipseIcon />,
    tip: 'Ellipse',
    hotkey: 'O',
  },
  {
    type: 'line',
    icon: <LineIcon />,
    tip: 'Line',
    hotkey: 'L',
  },
];

const SELECTION_TOOLS = [
  {
    type: WorkbenchStudioTool.Lasso,
    icon: <LassoIcon />,
    tip: 'Lasso selection',
    hotkey: 'G',
    tooltip: {
      text: 'Freely draw a selection area to isolate and regenerate specific parts of your image.',
      video: tooltip_lasso_selection,
    },
  },
  {
    type: WorkbenchStudioTool.BrushSelection,
    icon: <BrushSelectionIcon />,
    tip: 'Brush selection',
    hotkey: 'G',
    tooltip: {
      text: 'Paint over areas to create a selection mask for targeted edits or regeneration.',
      video: tooltip_brush_selection,
    },
  },
  {
    type: WorkbenchStudioTool.BezierSelection,
    icon: <BezierIcon />,
    tip: 'Bezier selection',
    hotkey: 'G',
    tooltip: {
      text: 'Create precise selections with smooth, curved paths using Bézier controls and regenerate specific areas within the selection.',
      video: tooltip_bezier_selection,
    },
  },
  {
    type: WorkbenchStudioTool.AutoSelection,
    icon: (
      <AutoSelectionIcon
        style={{
          width: '18px',
          height: '18px',
        }}
      />
    ),
    tip: 'Auto selection',
    hotkey: 'G',
  },
];

interface WorkbenchStudioToolbarProps {
  disabled: boolean;
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  state: TransitionStatus;
  activeLayer: Drawing2dStudio['layers']['nodes'][0] | undefined;
  setActiveLayerId: (id: string | undefined) => void;
  handleUndo: () => void;
  handleRedo: () => void;
  onExit: () => void;
  canRedo: boolean;
  canUndo: boolean;
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
  onClick: () => void;
}

export const WorkbenchStudioToolbar = (props: WorkbenchStudioToolbarProps) => {
  const toolState = useWorkbenchStudioToolState();
  const shouldDisplayTour = useShouldDisplayOnboardingTour();
  const { currentStep } = useOnboardingMultiStep();
  const theme = useTheme();
  const [showOverlay, setShowOverlay] = useState(false);
  const [isExitToWorkbenchTooltipOpen, setIsExitToWorkbenchTooltipOpen] =
    useState(false);

  const isLayer3d = props.activeLayer?.metadata3D;

  const layer3dTools = [...selectionTools, WorkbenchStudioTool.Move];
  const currentTool =
    isLayer3d && !layer3dTools.includes(toolState.tool)
      ? WorkbenchStudioTool.Move
      : toolState.tool;

  const isViewer = useIsWorkbenchViewer();

  const selectedTransform =
    TRANSFORM_TOOLS.find(({ type }) => type === toolState.tool) ||
    TRANSFORM_TOOLS[0];
  const selectedBrush =
    BRUSH_TOOLS.find(({ type }) => type === toolState.tool) || BRUSH_TOOLS[0];

  const selectedShape = SHAPE_TOOLS.find(
    ({ type }) => type === toolState.shapeSettings.type
  )!;

  const selectedSelection =
    SELECTION_TOOLS.find(
      ({ type }) => type === toolState.selectionSettings.lastSelectionTool
    ) || SELECTION_TOOLS[0];

  // Used to trigger a tooltip when the selection tool is changed
  // The tooltip will appear for 1000ms before being removed.
  const [selectionRecentlyChanged, setSelectionRecentlyChanged] =
    useState(false);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isSelectionTool(toolState.tool)) {
      setSelectionRecentlyChanged(true);
      timeout = setTimeout(() => setSelectionRecentlyChanged(false), 1000);
    } else {
      setSelectionRecentlyChanged(false);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [toolState.tool]);

  const [isSymmetrySettingsOpen, setIsSymmetrySettingsOpen] = useState(false);

  useKeyboardShortcut('w', props.onExit, {
    shift: true,
  });

  const workbenchButton = (
    <NewFeatureButton
      featureName={NewFeatureName.Exit2DStudioToWorkbench}
      onClick={() => {
        setIsExitToWorkbenchTooltipOpen(false);
        props.onExit();
      }}
      variant="secondary"
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 10,
      }}
    >
      <WorkbenchIcon />
      <Text>Workbench</Text>
    </NewFeatureButton>
  );

  const hasSelectionMask = useSubscribeToSelectionApi((state) => state.hasMask);

  const [selectionMenuOpen, setSelectionMenuOpen] = useState(false);

  const exit2DStudioToolbarButton =
    shouldDisplayTour && currentStep === 4 ? (
      <RichTooltip isOpen={true} placement="bottom-end" padding={88}>
        <RichTooltipTrigger>{workbenchButton}</RichTooltipTrigger>
        <OnboardingTourTooltipContent>
          <OnboardingStep
            video={educationalTooltipVideo04}
            title="Return to Workbench"
            content="Return to the infinite canvas to explore ideas and collaborate with others."
          />
        </OnboardingTourTooltipContent>
      </RichTooltip>
    ) : (
      <RichTooltip
        trigger="hover"
        padding={11}
        placement="bottom"
        delay={{
          open: 1500,
          close: 0,
        }}
        isOpen={isExitToWorkbenchTooltipOpen}
        onOpenChange={setIsExitToWorkbenchTooltipOpen}
      >
        <RichTooltipTrigger>{workbenchButton}</RichTooltipTrigger>
        <TooltipContent $backgroundSurface="e0">
          <TooltipTitle type="b2">
            Enter Workbench <Hotkey>⇧ W</Hotkey>
          </TooltipTitle>
          <TooltipDescription type="b2" color="info">
            Your infinite canvas to generate ideas & collaborate with others.
          </TooltipDescription>
          <TooltipVideo src={exit2dStudioVideo} />
        </TooltipContent>
      </RichTooltip>
    );

  const brushToolbarButton = (
    <ToolbarMenu
      enabled={
        currentTool === WorkbenchStudioTool.Brush ||
        currentTool === WorkbenchStudioTool.PaintBucket
      }
      menu={<BrushSettings />}
    >
      <ToolboxButton
        variant={
          currentTool === WorkbenchStudioTool.Brush ||
          currentTool === WorkbenchStudioTool.PaintBucket
            ? 'primary'
            : 'transparent'
        }
        size="iconSquared"
        onClick={() => toolState.setTool(selectedBrush.type)}
      >
        <ButtonContent
          style={{
            color:
              currentTool === WorkbenchStudioTool.Brush ||
              currentTool === WorkbenchStudioTool.PaintBucket
                ? theme.text.default
                : theme.icon.default,
          }}
        >
          {selectedBrush.icon}
          <DropdownArrowIcon />
        </ButtonContent>
      </ToolboxButton>
    </ToolbarMenu>
  );

  const tooltipProps = {
    padding: 12,
    delay: {
      open: 750,
      close: 0,
    },
  };

  if (isViewer) {
    return (
      <ToolbarContainer>
        <Toolbar $state={props.state}>
          <RichTooltip trigger="hover" {...tooltipProps}>
            <RichTooltipTrigger>
              <ToolboxButton variant="primary" size="iconSquared">
                <SelectIcon />
              </ToolboxButton>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              <div>Select</div>
            </RichTooltipContent>
          </RichTooltip>
          <ToolbarDivider />
          <div
            style={{
              display: 'flex',
              color: theme.text.info,
              alignItems: 'center',
              paddingRight: '8px',
            }}
          >
            View only
          </div>
          <ToolbarDivider />
          {exit2DStudioToolbarButton}
        </Toolbar>
      </ToolbarContainer>
    );
  }

  return (
    <>
      {showOverlay && <FullPageDarkLoader />}
      <ToolbarContainer onClick={props.onClick}>
        {props.disabled && (
          <Overlay
            style={{ borderRadius: '16px' }}
            onClick={() => {
              props.setSelectedPromptHistoryItem(undefined);
            }}
          />
        )}
        <Toolbar $state={props.state}>
          <InsertButton
            drawing={props.drawing}
            handleAction={props.handleAction}
            activeLayerId={props.activeLayer?.id}
            setActiveLayerId={props.setActiveLayerId}
            setShowOverlay={setShowOverlay}
          />
          <ToolbarDivider />
          <RichTooltip
            trigger="hover"
            key={WorkbenchStudioTool.Move}
            {...tooltipProps}
          >
            <RichTooltipTrigger>
              {isLayer3d ? (
                <ToolboxButton
                  variant={
                    currentTool === WorkbenchStudioTool.Move
                      ? 'primary'
                      : 'transparent'
                  }
                  size="iconSquared"
                  onClick={() => toolState.setTool(WorkbenchStudioTool.Move)}
                >
                  <ButtonContent
                    style={{
                      color: theme.icon.default,
                    }}
                  >
                    <SelectIcon />
                  </ButtonContent>
                </ToolboxButton>
              ) : (
                <ToolbarMenu
                  enabled={
                    currentTool === WorkbenchStudioTool.Move ||
                    currentTool === WorkbenchStudioTool.Transform
                  }
                  menu={<TransformSettings />}
                >
                  <ToolboxButton
                    variant={
                      currentTool === WorkbenchStudioTool.Move ||
                      currentTool === WorkbenchStudioTool.Transform
                        ? 'primary'
                        : 'transparent'
                    }
                    size="iconSquared"
                    onClick={() => toolState.setTool(selectedTransform.type)}
                  >
                    <ButtonContent
                      style={{
                        color: theme.icon.default,
                      }}
                    >
                      {selectedTransform.icon}
                      <DropdownArrowIcon
                        $offset={
                          currentTool === WorkbenchStudioTool.Move
                            ? '6px'
                            : '0px'
                        }
                      />
                    </ButtonContent>
                  </ToolboxButton>
                </ToolbarMenu>
              )}
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              <div>
                {selectedTransform.tip}{' '}
                <Hotkey>{selectedTransform.hotkey}</Hotkey>
              </div>
            </RichTooltipContent>
          </RichTooltip>
          <ToolbarDivider />
          {shouldDisplayTour && currentStep === 1 ? (
            <RichTooltip isOpen={true} placement="bottom-start" padding={88}>
              <RichTooltipTrigger>{brushToolbarButton}</RichTooltipTrigger>
              <OnboardingTourTooltipContent>
                <OnboardingStep
                  video={educationalTooltipVideo01}
                  title="Sketch your design"
                  content="Draw on the canvas, or upload a sketch using the insert button."
                />
              </OnboardingTourTooltipContent>
            </RichTooltip>
          ) : (
            <RichTooltip trigger="hover" {...tooltipProps}>
              <RichTooltipTrigger>{brushToolbarButton}</RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.white }}>
                <div>
                  {selectedBrush.tip} <Hotkey>{selectedBrush.hotkey}</Hotkey>
                </div>
              </RichTooltipContent>
            </RichTooltip>
          )}

          <RichTooltip trigger="hover" {...tooltipProps}>
            <RichTooltipTrigger>
              <ToolbarMenu
                enabled={currentTool === WorkbenchStudioTool.Eraser}
                menu={<EraserSettings />}
              >
                <ToolboxButton
                  variant={
                    currentTool === WorkbenchStudioTool.Eraser
                      ? 'primary'
                      : 'transparent'
                  }
                  size="iconSquared"
                  onClick={() => toolState.setTool(WorkbenchStudioTool.Eraser)}
                >
                  <ButtonContent
                    style={{
                      color: theme.icon.default,
                    }}
                  >
                    <EraserIcon />
                  </ButtonContent>
                </ToolboxButton>
              </ToolbarMenu>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              <div>
                Eraser <Hotkey>E</Hotkey>
              </div>
            </RichTooltipContent>
          </RichTooltip>
          <BrushColorInput
            color={toolState.color}
            setColor={toolState.setColor}
            onOpenCanvasColorPicker={() =>
              toolState.setPickingColorOnCanvas(true)
            }
          />
          <RichTooltip trigger="hover" {...tooltipProps}>
            <RichTooltipTrigger>
              <ToolbarMenu
                enabled={currentTool === WorkbenchStudioTool.Shape}
                menu={<ShapeSettings />}
              >
                <ToolboxButton
                  variant={
                    currentTool === WorkbenchStudioTool.Shape
                      ? 'primary'
                      : 'transparent'
                  }
                  size="iconSquared"
                  onClick={() => {
                    toolState.setTool(WorkbenchStudioTool.Shape);
                  }}
                >
                  <ButtonContent
                    style={{
                      color: theme.icon.default,
                    }}
                  >
                    {selectedShape.icon}
                    <DropdownArrowIcon />
                  </ButtonContent>
                </ToolboxButton>
              </ToolbarMenu>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              <div>
                {selectedShape.tip} <Hotkey>{selectedShape.hotkey}</Hotkey>
              </div>
            </RichTooltipContent>
          </RichTooltip>
          <RichTooltip
            trigger="hover"
            disabled={selectionMenuOpen}
            {...tooltipProps}
          >
            <RichTooltipTrigger>
              <ToolbarMenu
                enabled={isSelectionTool(currentTool)}
                menu={({ closeMenu }) => (
                  <SelectionSettings closeMenu={closeMenu} />
                )}
                onOpenChange={setSelectionMenuOpen}
              >
                <ToolboxButton
                  variant={
                    isSelectionTool(currentTool) ? 'primary' : 'transparent'
                  }
                  style={
                    !isSelectionTool(currentTool) && hasSelectionMask
                      ? { backgroundColor: theme.primary.disabled }
                      : undefined
                  }
                  size="iconSquared"
                  onClick={() => toolState.setTool(selectedSelection.type)}
                >
                  <ButtonContent
                    style={{
                      color: theme.icon.default,
                    }}
                  >
                    {selectedSelection.icon}
                    <DropdownArrowIcon />
                  </ButtonContent>
                </ToolboxButton>
              </ToolbarMenu>
            </RichTooltipTrigger>
            <TooltipContent>
              <TooltipTitle type="b2">
                {selectedSelection.tip}{' '}
                <Hotkey>{selectedSelection.hotkey}</Hotkey>
              </TooltipTitle>
              <TooltipDescription type="b2" color="info">
                {selectedSelection.tooltip?.text}
              </TooltipDescription>
              {selectedSelection.tooltip?.video && (
                <TooltipVideo src={selectedSelection.tooltip.video} />
              )}
            </TooltipContent>
          </RichTooltip>
          <ToolbarDivider />
          <RichTooltip trigger="hover" key="Symmetry" {...tooltipProps}>
            <RichTooltipTrigger>
              <ToolbarMenu
                menu={<SymmetrySettings />}
                enabled={toolState.symmetry.enabled}
                onOpenChange={setIsSymmetrySettingsOpen}
              >
                <ToolboxButton
                  variant={
                    toolState.symmetry.enabled &&
                    toolState.tool !== WorkbenchStudioTool.PaintBucket &&
                    !isSelectionTool(toolState.tool)
                      ? 'primary'
                      : 'transparent'
                  }
                  size="iconSquared"
                  disabled={
                    toolState.tool === WorkbenchStudioTool.PaintBucket ||
                    isSelectionTool(toolState.tool)
                  }
                  onClick={() => {
                    toolState.setSymmetry({
                      ...toolState.symmetry,
                      enabled: !(
                        isSymmetrySettingsOpen && toolState.symmetry.enabled
                      ),
                    });
                  }}
                >
                  <SymmetryIcon
                    style={{
                      color: theme.icon.default,
                    }}
                  />
                </ToolboxButton>
              </ToolbarMenu>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              <div>Symmetry</div>
            </RichTooltipContent>
          </RichTooltip>
          <ToolbarDivider />
          <RichTooltip trigger="click" {...tooltipProps}>
            <RichTooltip trigger="hover" {...tooltipProps}>
              <RichTooltipTrigger>
                <ToolboxButton variant={'transparent'} size="iconSquared">
                  <ButtonContent>
                    <Convert2dTo3dIcon style={{ color: theme.icon.default }} />
                    <DropdownArrowIcon />
                  </ButtonContent>
                </ToolboxButton>
              </RichTooltipTrigger>
              <TooltipContent>
                <TooltipTitle type="b2">Convert 2D to 3D</TooltipTitle>
                <TooltipDescription type="b2" color="info">
                  Transform your 2D rendering into a dynamic 3D model with a
                  single click.
                </TooltipDescription>
                <div style={{ textAlign: 'right', marginBottom: '12px' }}>
                  <a
                    href="https://youtu.be/bUF0CDeUT2E?si=cMEpIpiFMk3RQklA&t=217"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '10px',
                      color: theme.primary.default,
                      textDecoration: 'none',
                    }}
                  >
                    Learn more
                  </a>
                </div>
                <TooltipVideo src={tooltip_2d_3d} />
              </TooltipContent>
            </RichTooltip>
            <RichTooltipContent style={{ color: theme.white }}>
              <Generate3dLayerMenu
                drawing={props.drawing}
                handleAction={props.handleAction}
                setActiveLayerId={props.setActiveLayerId}
              />
            </RichTooltipContent>
          </RichTooltip>
          <ToolbarDivider />
          <div style={{ display: 'flex', gap: '8px' }}>
            <RichTooltip trigger="hover" {...tooltipProps}>
              <RichTooltipTrigger>
                <ToolboxButton
                  onClick={props.handleUndo}
                  variant="transparent"
                  size="iconSquared"
                  disabled={!props.canUndo}
                >
                  <UndoIcon
                    style={{
                      color: props.canUndo
                        ? theme.icon.default
                        : theme.text.disabled,
                    }}
                  />
                </ToolboxButton>
              </RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.white }}>
                Undo <Hotkey>{getShortcutKey()} + Z</Hotkey>
              </RichTooltipContent>
            </RichTooltip>

            <RichTooltip trigger="hover" {...tooltipProps}>
              <RichTooltipTrigger>
                <ToolboxButton
                  onClick={props.handleRedo}
                  variant="transparent"
                  size="iconSquared"
                  disabled={!props.canRedo}
                >
                  <RedoIcon
                    style={{
                      color: props.canRedo
                        ? theme.icon.default
                        : theme.text.disabled,
                    }}
                  />
                </ToolboxButton>
              </RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.white }}>
                Redo <Hotkey>{getShortcutKey()} + ⇧ + Z</Hotkey>
              </RichTooltipContent>
            </RichTooltip>
          </div>
          <ToolbarDivider />
          {exit2DStudioToolbarButton}
        </Toolbar>
      </ToolbarContainer>
    </>
  );
};

const ToolbarContainer = styled.div`
  z-index: 100000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 1rem;
  left: max(50%, 480px);
  transform: translateX(-50%);
  gap: 16px;
  pointer-events: all;
`;

const Toolbar = styled.div<{
  $state?: TransitionStatus;
}>`
  display: flex;
  padding: 8px;
  border-radius: 16px;
  gap: 8px;
  background: ${({ theme }) => theme.surface.e0};
  height: 48px;
  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateY(0)'
      : 'translateY(calc(-100% - 1rem))'};
  animation: ${({ $state }) =>
      $state === 'entering' ? slideInFromTop : 'none'}
    0.5s ease;
  transition: all 0.5s ease-in-out;
`;

const ToolbarDivider = styled.div`
  width: 1px;
  height: 16px;
  align-self: center;
  background-color: ${(p) => p.theme.surface.e2};
`;

const ToolboxButton = styled(Button)<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: ${(p) => (p.$active ? p.theme.text.primary : p.theme.text.info)};
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropdownArrowIcon = styled(ArrowIcon)<{ $offset?: string }>`
  margin-left: 0px;
  margin-right: -4px;
  margin-top: ${({ $offset }) => $offset ?? '0px'};
  width: 8px;
  height: 8px;
`;

const TooltipContent = styled(RichTooltipContent)`
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 240px;
  padding: 16px;
`;

const TooltipTitle = styled(Text)`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TooltipDescription = styled(Text)`
  margin-bottom: 12px;
  line-height: 1.5;
`;

const TooltipVideo = styled(AutoplayVideo)`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 6px;
`;

const Hotkey = styled.span`
  color: ${({ theme }) => theme.text.info};
  font-size: 12px;
  padding: 2px 6px;
  background-color: ${({ theme }) => theme.surface.e1};
  border-radius: 4px;
  margin-left: 8px;
`;
