import {
  Button,
  FullPageDarkLoader,
  OrganizationAvatar,
  Text,
  UserAvatar,
  addToast,
  formatErrorMessage,
  useImmer,
} from '@vizcom/shared-ui-components';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import {
  TeamRole,
  useCurrentUser,
  useOrganization,
  useOrganizationMembers,
  useSubscribeToPlan,
} from '@vizcom/shared/data-access/graphql';
import styled from 'styled-components';
import { PLAN_PRICES_PER_EDITOR } from '@vizcom/shared/plans-limit';

export const ReviewEditorsBeforePlanUpgradePage = () => {
  const [searchParams] = useSearchParams();
  const plan = searchParams.get('plan');
  const { organizationId } = useParams();
  const { data: currentUser } = useCurrentUser();
  const [res, subscribeToPlan] = useSubscribeToPlan();
  const [userRoleOverrides, setUserRoleOverrides] = useImmer<
    Record<string, TeamRole>
  >({});

  const { data: organization, fetching: fetchingOrganization } =
    useOrganization(organizationId);
  const { data: organizationMembers, fetching } =
    useOrganizationMembers(organizationId);

  if (fetching || fetchingOrganization || res.fetching) {
    return <FullPageDarkLoader />;
  }

  if (
    !organizationId ||
    !plan ||
    !organizationMembers ||
    !organization ||
    !currentUser
  ) {
    return <Navigate to="/" />;
  }

  const handleSubmit = async () => {
    const res = await subscribeToPlan({
      input: {
        organizationId,
        plan: plan as any,
        rolesToChange: Object.entries(userRoleOverrides).map(
          ([userId, newRole]) => ({
            userId,
            newRole,
          })
        ),
      },
    });
    if (res.error || !res.data?.subscribeToPlan) {
      return addToast('Error while redirecting to payment', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    window.location.href = res.data.subscribeToPlan.url;
  };

  const toggleMemberRole = (userId: string, defaultRole: TeamRole) => {
    setUserRoleOverrides((roleOverrides) => {
      if (userId in roleOverrides) {
        delete roleOverrides[userId];
      } else {
        roleOverrides[userId] =
          defaultRole === TeamRole.Viewer ? TeamRole.Editor : TeamRole.Viewer;
      }
    });
  };

  const pricePerEditor = (PLAN_PRICES_PER_EDITOR as any)[plan];
  const paidMembersCount = organizationMembers.members.edges.filter((m) =>
    [TeamRole.Admin, TeamRole.Editor].includes(
      userRoleOverrides[m.node.id] ?? m.role
    )
  ).length;

  return (
    <Container>
      <Text block type="t1">
        Adjust your editors
      </Text>
      <Text block type="sh1">
        Unchecked users will become free viewers which means they can still
        access the workspace and view files but won't be billed as part of this
        subscription.
      </Text>

      <MembersContainer>
        <MembersContainerHeader>
          <OrganizationAvatar organization={organization} />
          <Text block type="sh1" style={{ flex: 1 }}>
            {organization.name}
          </Text>
          <div>
            <Text type="sh2" block>
              {paidMembersCount} Editor{paidMembersCount > 1 ? 's' : ''}
            </Text>
            <Text block>${pricePerEditor}/editor/month</Text>
          </div>
        </MembersContainerHeader>
        <MembersList>
          {organizationMembers.members.edges.map((member) => (
            <Member key={member.node.id}>
              <UserAvatar
                name={member.node.name ?? member.node.email}
                userId={member.node.id}
              />
              <div style={{ flex: 1 }}>
                <Text block>{member.node.name ?? '-'}</Text>
                <Text block color="secondary">
                  {member.node.email}
                </Text>
              </div>
              <div>
                <EditorRoleCheckbox
                  type="checkbox"
                  checked={
                    (userRoleOverrides[member.node.id] || member.role) !==
                    TeamRole.Viewer
                  }
                  disabled={member.node.id === currentUser.id}
                  onChange={() => toggleMemberRole(member.node.id, member.role)}
                />
              </div>
            </Member>
          ))}
        </MembersList>
      </MembersContainer>

      <Button onClick={handleSubmit}>Continue to payment</Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  width: 550px;
  max-width: 100%;
  overflow-y: auto;
  flex: 1;
`;

const MembersContainer = styled.div`
  border: 1px solid ${(p) => p.theme.surface.e2};
  border-radius: ${(p) => p.theme.borderRadius.default};
  overflow-y: auto;
`;

const MembersContainerHeader = styled.div`
  padding: 14px 24px;
  display: flex;
  text-align: left;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.surface.e2};
`;

const MembersList = styled.div``;

const Member = styled.div`
  padding: 14px 24px;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 10px;
`;

const EditorRoleCheckbox = styled.input`
  width: 16px;
  height: 16px;
`;
