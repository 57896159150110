import { v4 as uuidv4 } from 'uuid';
import {
  MenuDivider,
  MenuItem,
  Text,
  addToast,
  getShortcutKey,
} from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import {
  copyWorkbenchElementsToClipboard,
  pasteWorkbenchElementsFromClipboard,
} from '../../lib/workbenchClipboardUtils';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { useStore } from '@react-three/fiber';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { triggerDeleteElementsModal } from '../modals/triggerDeleteElementsModal';
import { useWorkbenchElementSelectionState } from '../../lib/elementSelectionState';

interface WorkbenchMenuEditItemProps {
  canUndo: boolean;
  undoAction: () => any;
  canRedo: boolean;
  redoAction: () => any;
  elements: ClientSideWorkbenchElementData[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const WorkbenchMenuEditItem = ({
  canRedo,
  canUndo,
  redoAction,
  undoAction,
  elements,
  handleAction,
}: WorkbenchMenuEditItemProps) => {
  const store = useStore();
  const isViewer = useIsWorkbenchViewer();
  const hasFocusedElements = useWorkbenchElementSelectionState(
    (state) => state.focusedElementsId.split('/').filter(Boolean).length > 0
  );

  return (
    <MenuItem label="Edit">
      <MenuItem
        label="Undo"
        disabled={!canUndo || !isViewer}
        onClick={undoAction}
        appendLabel={<Text color="secondary">{getShortcutKey(true)}Z</Text>}
      />
      <MenuItem
        label="Redo"
        disabled={!canRedo}
        onClick={redoAction}
        appendLabel={<Text color="secondary">{getShortcutKey(true)}⇧Z</Text>}
      />
      <MenuDivider />
      <MenuItem
        label="Copy"
        disabled={!hasFocusedElements}
        onClick={() =>
          copyWorkbenchElementsToClipboard(
            elements,
            useWorkbenchElementSelectionState.getState().focusedElementsId
          )
        }
        appendLabel={<Text color="secondary">{getShortcutKey(true)}C</Text>}
      />
      <MenuItem
        disabled={isViewer}
        label="Paste"
        onClick={async () => {
          if (navigator.clipboard.readText === undefined) {
            return addToast(
              `The feature is not supported. Please use Chrome or Edge to use this feature.`,
              {
                type: 'danger',
              }
            );
          }

          const data = await navigator.clipboard.readText();
          pasteWorkbenchElementsFromClipboard(
            data,
            handleAction,
            useWorkbenchElementSelectionState.getState().setFocusedElementsId,
            store.getState()
          );
        }}
        appendLabel={<Text color="secondary">{getShortcutKey(true)}V</Text>}
      />
      <MenuItem
        label="Duplicate"
        appendLabel={<Text color="secondary">{getShortcutKey(true)}D</Text>}
        disabled={!hasFocusedElements || isViewer}
        onClick={() => {
          const focusedElementIds = useWorkbenchElementSelectionState
            .getState()
            .focusedElementsId.split('/')
            .filter(Boolean);
          const newElementIds = focusedElementIds.map(() => uuidv4());
          handleAction({
            type: 'duplicateElements',
            elementIds: focusedElementIds,
            newElementIds,
          });
          useWorkbenchElementSelectionState
            .getState()
            .setFocusedElementsId(newElementIds.join('/'));
        }}
      />
      <MenuDivider />
      <MenuItem
        label="Delete"
        disabled={!hasFocusedElements || isViewer}
        appendLabel={<Text color="secondary">DEL</Text>}
        onClick={async () => {
          const elementIds = useWorkbenchElementSelectionState
            .getState()
            .focusedElementsId.split('/')
            .filter((id) => id);
          const deletedElements = elements.filter((e) =>
            elementIds.includes(e.id)
          );

          if (
            deletedElements.some(
              (e) =>
                e.__typename === 'Drawing' ||
                e.__typename === 'CompositeScene' ||
                e.__typename === 'WorkbenchElementPalette'
            )
          ) {
            try {
              await triggerDeleteElementsModal(deletedElements);
            } catch {
              return;
            }
          }

          handleAction({
            type: 'deleteElements',
            elementIds,
          });
        }}
      />
    </MenuItem>
  );
};
