import { Camera, ThreeEvent, useThree } from '@react-three/fiber';

import {
  ClientSideWorkbenchElementData,
  ClientSideWorkbenchElementDrawing,
} from '../../../lib/clientState';
import { getElementsBoundingBox } from '../../helpers';
import { MapControlsProto, MoveToEvent } from './mapControls';
import { useEffect } from 'react';
import { Plane, Raycaster, Vector2, Vector3 } from 'three';
import { useStableCallback } from '@vizcom/shared-ui-components';

export const WORKBENCH_MODE_CAMERA_ZOOM_LIMITS = [0.01, 50]; // Min and max zoom for workbench mode
const DRAWING_MODE_CAMERA_ZOOM_LIMITS = [0.3, 20.0]; // Min and max zoom for drawing mode
const UI_MARGIN_FACTOR = 2.5; // Factor used to calculate extra space around elements for UI

export interface CameraLimits {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  zoomMin: number;
  zoomMax: number;
}

export const getCameraLimitsFromActiveDrawing = (
  drawing: ClientSideWorkbenchElementDrawing
) => {
  return {
    xMin: drawing.drawingWidth / -2 - drawing.drawingWidth / UI_MARGIN_FACTOR,
    xMax: drawing.drawingWidth / 2 + drawing.drawingWidth / UI_MARGIN_FACTOR,
    yMin: drawing.drawingHeight / -2,
    yMax: drawing.drawingHeight / 2,
    zoomMin: DRAWING_MODE_CAMERA_ZOOM_LIMITS[0],
    zoomMax: DRAWING_MODE_CAMERA_ZOOM_LIMITS[1],
  };
};

const WORKBENCH_CAMERA_LIMITS_MARGIN = 100;
export const getCameraLimitsFromWorkbenchElements = (
  elements: ClientSideWorkbenchElementData[]
): CameraLimits => {
  const { x, y, width, height } = getElementsBoundingBox(elements);

  if (elements.length === 0) {
    return {
      // If there are no elements, default to 100% zoom
      xMin: -window.innerWidth / 2,
      xMax: window.innerWidth / 2,
      yMin: -window.innerHeight / 2,
      yMax: window.innerHeight / 2,
      zoomMin: WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[0],
      zoomMax: WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[1],
    };
  }

  return {
    xMin: x - width / 2 - WORKBENCH_CAMERA_LIMITS_MARGIN,
    xMax: x + width / 2 + WORKBENCH_CAMERA_LIMITS_MARGIN,
    yMin: y - height / 2 - WORKBENCH_CAMERA_LIMITS_MARGIN,
    yMax: y + height / 2 + WORKBENCH_CAMERA_LIMITS_MARGIN,
    zoomMin: WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[0],
    zoomMax: WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[1],
  };
};

const MAX_ZOOM_STEP = 10;
const IS_DARWIN = /Mac|iPod|iPhone|iPad/.test(
  typeof window === 'undefined' ? 'node' : window.navigator.platform
);
// Adapted from https://stackoverflow.com/a/13650579
export function normalizeWheel(
  event: WheelEvent | React.WheelEvent<HTMLElement>
) {
  let { deltaY, deltaX } = event;
  let deltaZ = 0;

  if (event.ctrlKey || event.altKey || event.metaKey) {
    // Handle pinch-to-zoom gestures
    const signY = Math.sign(event.deltaY);
    const absDeltaY = Math.abs(event.deltaY);

    let dy = deltaY;

    if (absDeltaY > MAX_ZOOM_STEP) {
      dy = MAX_ZOOM_STEP * signY;
    }

    deltaZ = dy / 256;
  } else {
    // On non-Darwin systems, shift + scroll is treated as horizontal scroll
    if (event.shiftKey && !IS_DARWIN) {
      deltaX = deltaY;
      deltaY = 0;
    }
  }

  return { x: -deltaX, y: -deltaY, z: -deltaZ };
}

export const useMapControls = () => {
  const controls = useThree((s) => s.controls as any as MapControlsProto);
  return controls;
};

export const useOnMapControlsMove = (callback: (e: MoveToEvent) => void) => {
  const controls = useMapControls();
  const stableCallback = useStableCallback(callback);

  useEffect(() => {
    if (!controls) {
      return;
    }
    controls.addEventListener('moveTo', stableCallback);
    return () => {
      controls.removeEventListener('moveTo', stableCallback);
    };
  }, [controls, stableCallback]);
};

// Handles scrolling near canvas edges to move the camera
export function handleScrollCanvas(
  event: ThreeEvent<PointerEvent>,
  camera: Camera,
  controls: MapControlsProto
) {
  const { innerWidth, innerHeight } = window;
  const cameraMargin = 100;
  const cameraSpeed = 20 / Math.max(1, camera.zoom);
  let offsetX = 0;
  let offsetY = 0;

  // Calculate offsets based on cursor proximity to canvas edges
  if (event.clientX < cameraMargin) {
    const distance = cameraMargin - event.clientX;
    offsetX = -cameraSpeed * (distance / cameraMargin);
  } else if (event.clientX > innerWidth - cameraMargin) {
    const distance = event.clientX - (innerWidth - cameraMargin);
    offsetX = cameraSpeed * (distance / cameraMargin);
  }

  if (event.clientY < cameraMargin) {
    const distance = cameraMargin - event.clientY;
    offsetY = cameraSpeed * (distance / cameraMargin);
  } else if (event.clientY > innerHeight - cameraMargin) {
    const distance = event.clientY - (innerHeight - cameraMargin);
    offsetY = -cameraSpeed * (distance / cameraMargin);
  }

  controls.moveTo({
    x: camera.position.x + offsetX,
    y: camera.position.y + offsetY,
    skipAnimation: true,
    controlled: true,
  });
}

// Setup for raycasting to determine camera's view on the ground plane
const raycaster = new Raycaster();
const groundPlane = new Plane(new Vector3(0, 0, 1), 0);
const viewportCorners = [
  new Vector2(-1, -1),
  new Vector2(1, -1),
  new Vector2(-1, 1),
  new Vector2(1, 1),
];
const intersectionPoint = new Vector3();

// assume that ground is at z=0
// Calculates the bounding box of the camera's view on the ground plane
export function getCameraBoundingBox(camera: Camera) {
  const intersectionPoints: Vector3[] = [];

  // Cast rays from viewport corners to find intersection with ground plane
  viewportCorners.forEach((corner) => {
    raycaster.setFromCamera(corner, camera);
    raycaster.ray.intersectPlane(groundPlane, intersectionPoint);

    if (intersectionPoint) {
      intersectionPoints.push(intersectionPoint.clone());
    }
  });

  // If we don't have at least 3 intersection points, we can't form a bounding box
  if (intersectionPoints.length < 3) {
    // Camera is looking straight up, down, or parallel to the ground
    console.warn('Not enough intersection points to form a bounding box');
    return null;
  }

  // Calculate the bounding box
  const minX = Math.min(...intersectionPoints.map((p) => p.x));
  const maxX = Math.max(...intersectionPoints.map((p) => p.x));
  const minY = Math.min(...intersectionPoints.map((p) => p.y));
  const maxY = Math.max(...intersectionPoints.map((p) => p.y));

  return {
    left: minX,
    right: maxX,
    top: maxY,
    bottom: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}
