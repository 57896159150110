import { SyncedActionPayloadFromType } from '../../SyncedAction';
import { MultiUpdateElementsAction } from './multiUpdateElementsAction';
import { ConvertPlaceholderMeshOutputAction } from './convertPlaceholderMeshOutput';
import { CreateMultiViewsFromDrawingAction } from './createMultiViewsFromDrawingAction';
import { MultiCreateAction } from './multiCreateAction';
import { MultiDeleteAction } from './multiDeleteAction';
import { MultiDuplicateAction } from './multiDuplicateAction';
import { PositionAction } from './positionAction';
import { TrainPaletteAction } from './trainPaletteAction';
import { TriggerAiImg2ImgAction } from './triggerAiImg2ImgAction';
import { CreateWorkbenchMeshFromDrawingAction } from './createWorkbenchMeshFromImageAction';
import { UpdateAiImg2ImgAction } from './updateAiImg2ImgAction';
import { UpdatePaletteAction } from './updatePaletteAction';
import { UpdatePlaceholderAction } from './updatePlaceholderAction';
import { UpdateTextAction } from './updateTextAction';
import { UpdateMixAction } from './updateMixAction';
import { TriggerMixAction } from './triggerMixAction';
import { UpdateSectionAction } from './updateSectionAction';
import { UpdateDrawingAction } from './updateDrawingAction';
import { InsertImagesToPaletteAction } from './insertImagesToPaletteAction';
import { RemoveImagesFromPaletteAction } from './removeImagesFromPaletteAction';
import { InsertDrawingsToPaletteAction } from './insertDrawingsToPaletteAction';
import { CancelPaletteTrainingAction } from './cancelPaletteTrainingAction';

export const WorkbenchActionTypes = [
  PositionAction,
  MultiDeleteAction,
  MultiDuplicateAction,
  MultiCreateAction,

  UpdateAiImg2ImgAction,
  TriggerAiImg2ImgAction,

  ConvertPlaceholderMeshOutputAction,
  CreateWorkbenchMeshFromDrawingAction,
  CreateMultiViewsFromDrawingAction,

  UpdateMixAction,
  TriggerMixAction,

  UpdatePlaceholderAction,

  UpdateTextAction,

  MultiUpdateElementsAction,

  UpdatePaletteAction,
  TrainPaletteAction,
  CancelPaletteTrainingAction,

  UpdateSectionAction,
  UpdateDrawingAction,

  InsertImagesToPaletteAction,
  RemoveImagesFromPaletteAction,
  InsertDrawingsToPaletteAction,
];

export type WorkbenchActionPayload = SyncedActionPayloadFromType<
  typeof WorkbenchActionTypes[number]
>;
