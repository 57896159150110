import { useForm, SubmitHandler } from 'react-hook-form';
import {
  Button,
  LoadingLogoInset,
  Text,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  OrganizationData,
  useUpdateOrganization,
} from '@vizcom/shared/data-access/graphql';
import { useCanEditOrganization } from '../useCanEditOrganization';

type OrganizationNameInputs = {
  name: string;
};

export const OrganizationNameSetting = ({
  organization,
}: {
  organization: OrganizationData;
}) => {
  const [updateOrganizationRes, updateOrganization] = useUpdateOrganization();
  const canEdit = useCanEditOrganization(organization.id);

  const { register, handleSubmit, reset, formState } =
    useForm<OrganizationNameInputs>({
      values: {
        name: organization.name,
      },
    });

  const onSubmit: SubmitHandler<OrganizationNameInputs> = async (data) => {
    const res = await updateOrganization({
      input: {
        id: organization.id,
        patch: {
          name: data.name,
        },
      },
    });
    if (res.error) {
      return addToast('Error while updating name', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('The name has been updated');
  };

  return (
    <div style={{ position: 'relative' }}>
      <Text block type="sh1" style={{ marginBottom: 24 }}>
        Workspace name
      </Text>

      <Text block type="b1" style={{ marginBottom: 8 }}>
        Workspace name
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          $background="e2"
          style={{ marginBottom: 24, maxWidth: 540 }}
          disabled={!canEdit}
          {...register('name')}
        />
        {canEdit && (
          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
            <Button
              type="submit"
              disabled={updateOrganizationRes.fetching || !formState.isDirty}
            >
              Save
            </Button>
            <Button
              type="button"
              variant="transparent"
              onClick={() => reset()}
              disabled={!formState.isDirty}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>
      <LoadingLogoInset active={updateOrganizationRes.fetching} />
    </div>
  );
};
