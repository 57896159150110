import type { Layer } from 'ag-psd';

export interface Options {
  background?: string;
  mirrored?: boolean;
  width: number;
  height: number;
}

export interface Source {
  blendMode: 'source-over' | 'multiply';
  blob: Blob;
  opacity: number;
  name: string;
  isMask?: boolean;
}

function filterExists<T>(val: T | null | undefined): val is T {
  return val !== null && val !== undefined;
}

const blendModeToPSDBlendMode = (blendMode: string) => {
  switch (blendMode) {
    case 'source-over':
      return 'normal';
    case 'multiply':
      return 'multiply';
    default:
      return 'normal';
  }
};

export const psdImplementation = async (
  sources: Source[],
  options: Options,
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D | null
) => {
  if (ctx === null) {
    throw new Error('failed to create ctx');
  }

  const images = await Promise.all(
    sources.map(async (source) => {
      const bitmap = await createImageBitmap(source.blob);
      return { bitmap, source };
    })
  );

  const layers = images
    .map(({ bitmap, source }, i) => {
      ctx.clearRect(0, 0, options.width, options.height);

      if (options.background) {
        ctx.fillStyle = options.background;
        ctx.fillRect(0, 0, options.width, options.height);
      }

      ctx.imageSmoothingQuality = 'high';

      if (options.mirrored) {
        ctx.translate(options.width, 0);
        ctx.scale(-1, 1);
      }

      ctx.drawImage(
        bitmap,
        0,
        0,
        bitmap.width,
        bitmap.height,
        0,
        0,
        options.width,
        options.height
      );
      if (source.isMask) {
        ctx.globalCompositeOperation = 'source-in';
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, options.width, options.height);
        ctx.globalCompositeOperation = 'destination-atop';
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, options.width, options.height);
      }

      return {
        top: 0,
        left: 0,
        width: options.width,
        height: options.height,
        imageData: ctx.getImageData(0, 0, options.width, options.height),
        opacity: source.opacity,
        blendMode: blendModeToPSDBlendMode(source.blendMode),
        name: source.name,
      };
    })
    .filter(filterExists) as Layer[];

  const psdData = {
    width: options.width,
    height: options.height,
    children: layers,
  };

  const { writePsd } = await import('ag-psd');

  const buffer = writePsd(psdData);

  const blob = new Blob([buffer], { type: 'application/octet-stream' });

  return blob;
};
