import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AutoplayVideo,
  Button,
  Text,
  useShouldDisplayOnboardingTour,
} from '@vizcom/shared-ui-components';

type OnboardingMultiStepContextProps = {
  currentStep: number;
  totalSteps: number;
  goToNextStep: () => void;
};

const OnboardingMultiStepContext = createContext<
  OnboardingMultiStepContextProps | undefined
>(undefined);

type OnboardingMultiStepProviderProps = {
  active: boolean;
  totalSteps: number;
  onLastStep: () => void;
  children: React.ReactNode;
};

export const OnboardingMultiStepProvider: React.FC<
  OnboardingMultiStepProviderProps
> = ({ active, totalSteps, onLastStep, children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const shouldDisplayTour = useShouldDisplayOnboardingTour();

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
    if (currentStep >= totalSteps) {
      onLastStep();
    }
  };

  useEffect(() => {
    if (shouldDisplayTour) {
      // reset the current step to 1 when the tour is displayed
      setCurrentStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplayTour]);

  return (
    <OnboardingMultiStepContext.Provider
      value={{
        currentStep: active ? currentStep : -1,
        totalSteps,
        goToNextStep,
      }}
    >
      {children}
    </OnboardingMultiStepContext.Provider>
  );
};

export const useOnboardingMultiStep = () => {
  const context = useContext(OnboardingMultiStepContext);
  if (!context) {
    throw new Error('useMultiStep must be used within a MultiStepProvider');
  }
  return context;
};

type OnboardingStepProps = {
  video: string;
  title: string;
  content: string;
};

export const OnboardingStep: React.FC<OnboardingStepProps> = ({
  video,
  title,
  content,
}) => {
  const { currentStep, totalSteps, goToNextStep } = useOnboardingMultiStep();

  return (
    <StepContainer>
      <LeftSection>
        <AutoplayVideo
          src={video}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '14px 0 0 14px',
          }}
        />
      </LeftSection>
      <RightSection>
        <Text type="sh2">{title}</Text>
        <Text type="b1">{content}</Text>
        <Footer>
          <Text type="b3" color="info">
            {currentStep} of {totalSteps}
          </Text>
          <Button onClick={goToNextStep}>
            {currentStep === totalSteps ? 'Got it' : 'Next'}
          </Button>
        </Footer>
      </RightSection>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const LeftSection = styled.div`
  width: 190px;
`;

const RightSection = styled.div`
  width: 264px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
