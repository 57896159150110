import { Object3D } from 'three';

const getHierarchicalRenderOrder = (a: Object3D) => {
  const hierarchicalOrder = [a.renderOrder ?? 0];
  a.traverseAncestors((ancestor) => {
    if ('isScene' in ancestor && ancestor.isScene) {
      return;
    }
    if (ancestor.renderOrder !== undefined) {
      hierarchicalOrder.push(ancestor.renderOrder);
    }
  });
  return hierarchicalOrder.reverse();
};

// This function is used to render object based on their renderOrder + ancestors renderOrder
// This is different from the default sorting function of three.js which only takes the direct group parent renderOrder into account
// and not the full hierarchy
// More info here:
// https://github.com/mrdoob/three.js/issues/15561#issuecomment-531715127
// https://discourse.threejs.org/t/help-understanding-group-renderorder-and-mesh-renderorder/10790/2

// it starts from the top-most ancestor and compare the renderOrder of each ancestor until the renderOrder
// is different, then it returns the comparison result

// For example, if we have a graph like this:
// Group A (renderOrder: 1)
//  - Group AA (no-renderOrder)
//    - Mesh AAA (renderOrder: 10)
// Group B (renderOrder: 2)
//   - Mesh BA (renderOrder: 0)
// We would render Mesh AAA first because Group A has a lower renderOrder then Group B even if Mesh AAA has a higher renderOrder than Mesh BA

export const sortObjectsByHierarchicalRenderOrder = (
  a: { id: number; object: Object3D },
  b: { id: number; object: Object3D }
) => {
  const aHierarchicalOrder = getHierarchicalRenderOrder(a.object);
  const bHierarchicalOrder = getHierarchicalRenderOrder(b.object);

  for (
    let i = 0;
    i < Math.max(aHierarchicalOrder.length, bHierarchicalOrder.length);
    i++
  ) {
    const orderA = aHierarchicalOrder[i] ?? 0;
    const orderB = bHierarchicalOrder[i] ?? 0;
    if (orderA !== orderB) {
      return orderA - orderB;
    }
  }
  return a.id - b.id;
};
