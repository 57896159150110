import styled from 'styled-components';
import { Button, FullPageDarkLoader, Text } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useOrganization } from '@vizcom/shared/data-access/graphql';

export const OrganizationSubscriptionSuccessPage = () => {
  const { organizationId } = useParams();

  const { data: organization, fetching } = useOrganization(organizationId);

  if (fetching) {
    return <FullPageDarkLoader />;
  }
  if (!organization) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Text block type="t1">
        Thank you for your purchase
      </Text>
      <Text block type="sh1">
        We sent you an email to help you get started. It contains helpful video
        tutorials and links to download our Vizcom desktop app.
      </Text>
      <Button as={Link} to={paths.files.team(organization.globalTeam!.id)}>
        Continue to workspace
      </Button>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  width: 400px;
  max-width: 100%;
`;
