import { assertExists } from '@vizcom/shared/js-utils';
import {
  Texture,
  Scene,
  OrthographicCamera,
  WebGLRenderer,
  UnsignedByteType,
  NearestFilter,
  Color,
} from 'three';
import { useCallback, useMemo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useFBO } from '@react-three/drei';
import { yFlipImageBuffer } from '../../../helpers';
import { SelectionApi } from '../../selection/useSelectionApi';

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d', { willReadFrequently: true });
assertExists(ctx);

type BoundingBox = {
  maxX: number;
  maxY: number;
  minX: number;
  minY: number;
  width: number;
  height: number;
  centerDeltaX: number;
  centerDeltaY: number;
};

const computeTextureBoundingBox = (
  image: ImageData | ImageBitmap,
  imageComponentOffset: number = 3
): BoundingBox | undefined => {
  if (!image || ('data' in image && image.data === null)) {
    return undefined;
  }

  let data;
  if (image instanceof ImageData) {
    data = image.data;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);
    data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
  }

  // From https://stackoverflow.com/questions/9852159/calculate-bounding-box-of-arbitrary-pixel-based-drawing
  // goal is to reduce the amount of pixels to check by starting to find maxY, then maxX, then minX, then minY
  // this way we reduce the area to check step by step and can break out of the loops early
  // I tested multiple version of this code and this one is the fastest, especially on fully filled layers where it's almost instant
  let minY, maxY, minX, maxX;
  let y, x;

  for (y = image.height - 1; y >= 0; y--) {
    for (x = image.width - 1; x >= 0; x--) {
      if (data[(image.width * y + x) * 4 + imageComponentOffset] > 0) {
        maxY = y + 1;
        break;
      }
    }
    if (maxY !== undefined) {
      break;
    }
  }
  if (maxY === undefined) {
    // image is fully transparent, no need to check anything else
    return;
  }
  for (x = image.width - 1; x >= 0; x--) {
    for (y = maxY + 1; y >= 0; y--) {
      if (data[(image.width * y + x) * 4 + imageComponentOffset] > 0) {
        maxX = x + 1;
        break;
      }
    }
    if (maxX !== undefined) {
      break;
    }
  }
  for (x = 0; x <= maxX!; x++) {
    for (y = maxY + 1; y >= 0; y--) {
      if (data[(image.width * y + x) * 4 + imageComponentOffset] > 0) {
        minX = x;
        break;
      }
    }
    if (minX !== undefined) {
      break;
    }
  }
  for (y = 0; y <= maxY; y++) {
    for (x = minX!; x <= maxX!; x++) {
      if (data[(image.width * y + x) * 4 + imageComponentOffset] > 0) {
        minY = y;
        break;
      }
    }
    if (minY !== undefined) {
      break;
    }
  }
  assertExists(maxX);
  assertExists(minY);
  assertExists(minX);

  return {
    maxX,
    maxY,
    minX,
    minY,
    width: maxX - minX,
    height: maxY - minY,
    centerDeltaX: (maxX + minX) / 2 - image.width / 2,
    centerDeltaY: image.height / 2 - (maxY + minY) / 2,
  };
};

/*
Returns a new Texture object based on an existing texture and a bounding box.
The new texture has its `offset` and `repeat` properties adjusted to the bounding box.
*/
export function getCroppedTexture(
  texture: Texture,
  drawingSize: [number, number],
  boundingBox: BoundingBox
) {
  const cloned = texture.clone();
  cloned.offset.set(
    boundingBox.minX / drawingSize[0],
    (drawingSize[1] - boundingBox.maxY) / drawingSize[1]
  );
  cloned.repeat.set(
    boundingBox.width / drawingSize[0],
    boundingBox.height / drawingSize[1]
  );
  return cloned;
}

const auxColor = new Color();
const clearColor = new Color(0, 0, 0);

export const useSelectionTransform = (drawingSize: [number, number]) => {
  const selectionFBO = useFBO(drawingSize[0], drawingSize[1], {
    samples: 0,
    type: UnsignedByteType,
    minFilter: NearestFilter,
    magFilter: NearestFilter,
  });

  const scene = useMemo(() => new Scene(), []);

  const camera = useMemo(() => {
    const camera = new OrthographicCamera(
      (drawingSize[0] / 2) * -1,
      drawingSize[0] / 2,
      drawingSize[1] / 2,
      (drawingSize[1] / 2) * -1,
      0.1,
      1000
    );
    camera.position.z = 10;
    return camera;
  }, [drawingSize]);

  const { gl } = useThree((s) => s);
  const render = useCallback(
    (gl: WebGLRenderer) => {
      const currentAutoClear = gl.autoClear;
      const currentClearAlpha = gl.getClearAlpha();
      const currentClearColor = gl.getClearColor(auxColor);
      const currentRenderTarget = gl.getRenderTarget();
      gl.autoClear = true;
      gl.setRenderTarget(selectionFBO);
      gl.setClearColor(clearColor);
      gl.setClearAlpha(1);
      gl.render(scene, camera);
      gl.autoClear = currentAutoClear;
      gl.setRenderTarget(currentRenderTarget);
      gl.setClearColor(currentClearColor);
      gl.setClearAlpha(currentClearAlpha);
    },
    [scene, camera, selectionFBO]
  );

  const selectionToImageData = useCallback(() => {
    render(gl);

    const imageData = new ImageData(drawingSize[0], drawingSize[1]); // we want exportTexture to be synchronous to simplify the rest of the logic of the brush handling
    // to do so, we read the pixels and store them in an image that then get asynchrounously converted to a png blob
    // just before sending to the server
    gl.readRenderTargetPixels(
      selectionFBO,
      0,
      0,
      drawingSize[0],
      drawingSize[1],
      imageData.data
    );
    // flip the image upside down because readRenderTargetPixels read pixels from the bottom left corner
    yFlipImageBuffer(imageData.data, drawingSize[0]);
    return imageData;
  }, [render, gl, selectionFBO, drawingSize]);

  useFrame(() => {
    render(gl);
  });

  return { scene, selectionFBO, selectionToImageData };
};

export function initializeTransformTextures(
  layerImage: Texture | undefined,
  selectionMaskImage: ImageData | undefined,
  selectionMaskTexture: Texture,
  drawingSize: [number, number]
) {
  const boundingBox = selectionMaskImage
    ? computeTextureBoundingBox(selectionMaskImage, 0)
    : computeTextureBoundingBox(layerImage?.image);
  if (!boundingBox) {
    return undefined;
  }
  return {
    texture: layerImage
      ? getCroppedTexture(layerImage, drawingSize, boundingBox)
      : undefined,
    boundingBox,
    alphaMap: selectionMaskImage
      ? getCroppedTexture(selectionMaskTexture, drawingSize, boundingBox)
      : undefined,
  };
}
