import { FormEventHandler, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  LoadingLogo,
  Surface,
  Text,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  useDeleteOrganization,
  useOrganization,
} from '@vizcom/shared/data-access/graphql';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CONFIRMATION_TEXT_EXPECTED_VALUE = `permanently delete this workspace and all its files`;

export const OrganizationConfirmDelete = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [confirmationText, setConfirmationText] = useState('');
  const token = hash.startsWith('#token=')
    ? hash.slice('#token='.length)
    : null;

  const tokenBody = JSON.parse(atob(token?.split('.')?.[1] || '{}')) as {
    organizationId: string;
    exp: number;
  };
  const { data: organization, fetching } = useOrganization(
    tokenBody.organizationId
  );

  const [res, deleteOrganization] = useDeleteOrganization();

  if (!token) {
    return <Navigate to={'/'} />;
  }
  if (fetching) {
    return <LoadingLogo />;
  }

  if (
    !tokenBody.organizationId ||
    !tokenBody.exp ||
    tokenBody.exp < new Date().getTime() / 1000 ||
    !organization
  ) {
    return (
      <Surface style={{ maxWidth: 600, margin: '48px auto auto auto' }}>
        <Text block type="h1">
          This link is invalid or expired. Please verify the email you received
          and try requesting another email from the Workspace settings page.
        </Text>
      </Surface>
    );
  }

  const handleConfirmDelete: FormEventHandler = async (e) => {
    e.preventDefault();

    const res = await deleteOrganization({
      input: {
        deleteConfirmationToken: token,
      },
    });

    if (res.error) {
      return addToast('Error while deleting workspace', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Your workspace has been deleted');
    navigate('/');
  };

  return (
    <Wrapper>
      <Surface
        style={{
          marginTop: 48,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          maxWidth: 400,
          gap: 12,
        }}
        loading={res.fetching}
      >
        <Text block type="h1">
          Delete workspace "{organization.name}"
        </Text>
        <Text block>
          Deleting this workspace will delete all teams, folders, files and
          images contained in it for you and all other members. This action is
          permanent and cannot be undone.
        </Text>
        <form
          onSubmit={handleConfirmDelete}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            alignItems: 'stretch',
          }}
        >
          <Text block>
            Type "{CONFIRMATION_TEXT_EXPECTED_VALUE}" if you're sure you want to
            delete this workspace
          </Text>

          <TextInput
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />

          <Button
            disabled={
              res.fetching ||
              confirmationText !== CONFIRMATION_TEXT_EXPECTED_VALUE
            }
            type="submit"
          >
            Delete workspace
          </Button>
        </form>
      </Surface>
    </Wrapper>
  );
};
