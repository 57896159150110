// CarouselComponent.tsx
import Carousel, { Media } from './Carousel';
import image1 from '../assets/image1.jpg';
import image2 from '../assets/image2.jpg';
import image3 from '../assets/image3.jpg';
import image4 from '../assets/image4.jpg';
import image5 from '../assets/image5.jpg';

const carouselMedia: Media[] = [
  {
    src: image1,
    type: 'image',
    title: 'Ben Nuñez',
    description: 'Concept Artist',
    link: 'https://www.instagram.com/p/CxgyGybRiTg/?img_index=1',
  },
  {
    src: image2,
    type: 'image',
    title: 'Akos Szaz',
    description: 'Car Designer',
    link: 'https://www.instagram.com/p/CwsWVUWoN7m/?next=/&img_index=1',
  },
  {
    src: image3,
    type: 'image',
    title: 'Scott Robertson',
    description: 'Author/artist of the books "How To Draw" and "How To Render"',
    link: 'https://www.instagram.com/p/Cy9DRQoP1VV/?img_index=1',
  },
  {
    src: image4,
    title: 'Dennis Johann Mueller',
    type: 'image',
    description: 'Industrial designer, art direction and color enthusiast',
    link: 'https://www.instagram.com/dennis_johann_mueller',
  },
  {
    src: image5,
    title: 'Eric Stoddard',
    type: 'image',
    description: 'CARDESIGN.ACADEMY Founder and President',
    link: 'https://www.instagram.com/p/CzlvXzgLRsk/?img_index=2',
  },
];

export function CarasoulContainer() {
  return <Carousel data={carouselMedia} />;
}
