import { useEffect, useState } from 'react';
import {
  FormattedDate,
  LoadingLogo,
  RecoilPaywallModal,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  TextInput,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { useOrganizationAccessLogs } from '@vizcom/shared/data-access/graphql';
import { useDebouncedValue } from '../../../../../../libs/shared/utils/hooks';
import {
  SettingsPageContainer,
  SettingsPageHeader,
  SettingsPageDivider,
} from './components/SettingsPageLayout';
import { featureLimitForOrganizationSubscription } from '@vizcom/shared/plans-limit';
import { useNavigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';

const ENTRIES_PER_PAGE = 10;

export const OrganizationAccess = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [debouncedFilter, value, setDebouncedFilter] = useDebouncedValue(
    '',
    500
  );

  const { data: organization, loading } = useSelectedOrganization();

  const { value: enabledBySubscriptionPlan, loading: loadingFeatureLimit } =
    featureLimitForOrganizationSubscription(organization, 'accessLogs');

  const access = useOrganizationAccessLogs(
    enabledBySubscriptionPlan ? organization?.id : undefined,
    offset,
    debouncedFilter
  );

  const currentPage = Math.ceil((offset + 1) / ENTRIES_PER_PAGE);
  const totalPages = Math.ceil(access.count / ENTRIES_PER_PAGE);

  useEffect(() => {
    setOffset(0);
  }, [debouncedFilter]);

  const nextPage = () => {
    setOffset((o) => o + ENTRIES_PER_PAGE);
  };

  const prevPage = () => {
    setOffset((o) => o - ENTRIES_PER_PAGE);
  };

  if (access.fetching || loading || loadingFeatureLimit) {
    return <LoadingLogo />;
  }

  if (!enabledBySubscriptionPlan) {
    return (
      <RecoilPaywallModal
        isOpen
        onClose={() =>
          navigate(paths.settings.organization.general(organization!.id))
        }
      />
    );
  }

  return (
    <SettingsPageContainer>
      <SettingsPageHeader>
        <Text style={{ fontSize: 18, fontWeight: 600 }}>Access logs</Text>
        <Text color="info" type="b1">
          List all workspace users logins
        </Text>
      </SettingsPageHeader>
      <SettingsPageDivider />
      <Table
        cols={3}
        currentPage={currentPage}
        totalPages={totalPages}
        total={access.count}
        onNextPage={nextPage}
        onPrevPage={prevPage}
        filters={
          <div style={{ width: '400px' }}>
            <TextInput
              placeholder="Email"
              type="text"
              name="filter-email"
              value={value || ''}
              onChange={(e) => setDebouncedFilter(e.target.value)}
            />
          </div>
        }
      >
        <TableHeader>
          <TableCell>Email</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>IP Address</TableCell>
          <TableCell />
        </TableHeader>
        {access.data?.map((accessLog) => (
          <TableRow key={accessLog.eventDate}>
            <TableCell>{accessLog.userEmail}</TableCell>
            <TableCell>
              <FormattedDate date={accessLog.eventDate} />
            </TableCell>
            <TableCell>{accessLog.ipAddress}</TableCell>
            <TableCell />
          </TableRow>
        ))}
      </Table>
    </SettingsPageContainer>
  );
};
