import styled from 'styled-components';
import QRCode from 'react-qr-code';
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalContent,
  ModalActions,
  Button,
  Text,
  addToast,
} from '@vizcom/shared-ui-components';
import { RootState } from '@react-three/fiber';
import { getWorkbenchElementZPositionRange } from '../helpers';
import { paths } from '@vizcom/shared-utils-paths';

interface ScanWithPhoneModalProps {
  isOpen: boolean;
  onClose: () => void;
  workbenchId: string;
  threeState: React.MutableRefObject<RootState>;
}

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
`;

export const ScanWithPhoneModal = ({
  isOpen,
  onClose,
  workbenchId,
  threeState,
}: ScanWithPhoneModalProps) => {
  if (!threeState.current) return null;

  const zRange = getWorkbenchElementZPositionRange(threeState.current.scene);
  const { position } = threeState.current.camera;
  const targetPosition = [position.x, position.y].map((p) => p.toFixed(2));

  const url =
    // 'http://nik.local:4200' + // Note: for testing local personal mobile only; feel free to use your own local mobile url
    window.location.origin +
    paths.mobileWorkbench.upload(workbenchId) +
    `?z=${zRange[1]}&target=${targetPosition[0]},${targetPosition[1]}`;

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(url);
      addToast(
        'URL copied to clipboard. Open on your phone to upload images.',
        {
          type: 'default',
          duration: 3000,
        }
      );
    } catch (err) {
      console.error('Failed to copy URL: ', err);
      addToast('Failed to copy URL. Please try again.', {
        type: 'danger',
        duration: 3000,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={onClose} style={{ width: '400px' }}>
      <ModalHeader>
        <ModalTitle>Scan with Phone</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Text block>
          Scan the QR code or copy the URL to upload images from your phone.
        </Text>
        <QRCodeContainer>
          <QRCode value={url} size={128} />
        </QRCodeContainer>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleCopyUrl} variant="secondary">
          Copy URL
        </Button>
        <Button onClick={onClose} variant="primary">
          Close
        </Button>
      </ModalActions>
    </Modal>
  );
};
