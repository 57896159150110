import React, { useState } from 'react';
import {
  CloseIcon,
  FileDropper,
  NumberInput,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import checkeredThumbnail from './assets/checkeredThumbnail.png';
import { VerticalDivider } from '../WorkbenchStudioMenu/WorkbenchStudioMenu';

type ReferenceImageChipProps = {
  onFileSelected: (file: File) => void;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onResetClick: (e: React.MouseEvent) => void;
  onDropImage: (file: File) => void;
  styleReferenceStrength: number;
  setStyleReferenceStrength: (value: number) => void;
  activeReference:
    | {
        id: string;
        imagePath: string;
        imageName?: string | null;
      }
    | undefined;
  isActive: boolean;
};

const ReferenceImageChip = ({
  onFileSelected,
  onClick,
  onResetClick,
  onDropImage,
  setStyleReferenceStrength,
  styleReferenceStrength,
  activeReference,
  isActive,
}: ReferenceImageChipProps) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      onDropImage(file);
    }
  };

  return (
    <ChipContainer
      as={FileDropper}
      browseForFileOnClick
      onFileSelected={onFileSelected}
      onClick={onClick}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      $isDraggingOver={isDraggingOver}
      $isActive={isActive}
    >
      {activeReference?.id ? (
        <>
          <ThumbnailWrapper>
            <Thumbnail
              src={activeReference.imagePath}
              alt={activeReference.imageName || 'Reference'}
            />
          </ThumbnailWrapper>
          <FileName>{activeReference.imageName || 'Image'}</FileName>
          <VerticalDivider />

          <NumberInputWrapper
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <RichTooltip
              placement="bottom"
              trigger="hover"
              padding={8}
              delay={{
                open: 500,
                close: 0,
              }}
            >
              <RichTooltipTrigger>
                <div>
                  <NumberInput
                    value={styleReferenceStrength * 100}
                    min={0}
                    max={100}
                    unit="%"
                    setValue={(value: number) => {
                      setStyleReferenceStrength(
                        (Math.round(value / 5) * 5) / 100
                      );
                    }}
                    dragArrows={false}
                    enableGestureSlider
                  />
                </div>
              </RichTooltipTrigger>
              <RichTooltipContent>
                <Text>Adjust Strength of Reference Image</Text>
              </RichTooltipContent>
            </RichTooltip>
          </NumberInputWrapper>
          <CloseIconWrapper>
            <CloseIconStyled onClick={onResetClick} />
          </CloseIconWrapper>
        </>
      ) : (
        <>
          <CheckeredImage src={checkeredThumbnail} alt="Add" />
          <span>Add...</span>
        </>
      )}
    </ChipContainer>
  );
};

const ChipContainer = styled.div<{
  $isDraggingOver: boolean;
  $isActive: boolean;
}>`
  display: flex;
  width: 168px;
  height: 32px;
  padding: 4px 2px 4px 0px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, $isDraggingOver, $isActive }) =>
      $isDraggingOver
        ? theme.primary.default
        : $isActive
        ? theme.primary.default
        : theme.secondary.default};
  background: ${({ theme }) => theme.surface.e1};
  cursor: pointer;
  overflow: hidden;
  &:hover {
    border: ${({ theme, $isActive }) =>
      $isActive
        ? `1px solid ${theme.primary.default}`
        : `1px solid ${theme.secondary.hover}`};
  }
`;

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 4px;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const FileName = styled.span`
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: 64px;
  margin-right: 8px;
  margin-left: 8px;
`;

const NumberInputWrapper = styled.div`
  width: 48px;
  flex-shrink: 0;
`;

const CheckeredImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 4px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  width: 16px;
`;

const CloseIconStyled = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.secondary.hover};
  flex-shrink: 0;
  cursor: pointer;
`;

export default ReferenceImageChip;
