import styled from 'styled-components';

export const WorkbenchToolbarContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 16px;
  gap: 0.5rem;
  background: ${({ theme }) => theme.surface.e0};
  pointer-events: all;
  height: 48px;
`;

export const WorkbenchToolbarDivider = styled.div`
  width: 2px;
  height: 16px;
  align-self: center;
  background-color: ${(p) => p.theme.surface.e2};
`;
