import {
  urqlClient,
  UpdateDrawingMutation,
} from '@vizcom/shared/data-access/graphql';
import { SyncedActionType } from '../../SyncedAction';
import { imageDataToBlob } from '@vizcom/shared-ui-components';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { elementById } from '../../utils';

export const UpdateDrawingThumbnailAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'updateDrawingThumbnail';
    thumbnail: ImageData | (() => ImageData);
    final?: boolean;
  }
> = {
  type: 'updateDrawingThumbnail',
  metaConstructor: (payload) => {
    return {
      debounceId: 'updateDrawingThumbnail',
      delay: payload.final ? undefined : 500,
      pure: true,
    };
  },
  optimisticUpdater: () => {
    // no-op
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    const thumbnail = await imageDataToBlob(
      typeof payload.thumbnail === 'function'
        ? payload.thumbnail()
        : payload.thumbnail
    );
    await urqlClient.mutation(UpdateDrawingMutation, {
      id: drawingId,
      patch: {
        thumbnailPath: thumbnail,
      },
    });
  },
  sideEffects: (action, drawingId) => [
    {
      syncQueueName: 'workbench',
      optimisticUpdater(elements) {
        const sourceElement = elementById(elements, drawingId);
        if (
          sourceElement?.__typename === 'Drawing' &&
          typeof action.payload.thumbnail !== 'function'
        ) {
          sourceElement.thumbnailPath = action.payload.thumbnail;
        }
      },
    },
  ],
};
