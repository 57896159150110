import { SelectIcon, SkewIcon } from '@vizcom/shared-ui-components';
import {
  WorkbenchStudioTool,
  useWorkbenchStudioToolState,
} from '../studioState';
import styled from 'styled-components';

export const TransformSettings = () => {
  const { tool, setTool } = useWorkbenchStudioToolState();

  return (
    <>
      <TransformMode
        $active={tool === WorkbenchStudioTool.Move}
        onClick={() => {
          setTool(WorkbenchStudioTool.Move);
        }}
      >
        <SelectIcon />
        <div>Move</div>
        <div>M</div>
      </TransformMode>
      <TransformMode
        $active={tool === WorkbenchStudioTool.Transform}
        onClick={() => {
          setTool(WorkbenchStudioTool.Transform);
        }}
      >
        <SkewIcon />
        <div>Skew</div>
        <div>T</div>
      </TransformMode>
    </>
  );
};

const Tool = styled.button<{ $active?: boolean }>`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ $active, theme }) =>
    $active ? theme.text.default : theme.text.info};
  &:disabled {
    color: ${({ theme }) => theme.text.disabled};
    cursor: default;
  }
`;

const TransformMode = styled(Tool)`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0.75rem 12px;
  gap: 12px;
  text-align: left;
`;
