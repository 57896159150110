import { createCanvas } from '@vizcom/shared-ui-components';
import { useEffect, useMemo } from 'react';
import { CanvasTexture } from 'three';

export const useCanvasTexture = (size: [number, number]) => {
  const { canvas, ctx, canvasTexture } = useMemo(() => {
    const { canvas, ctx } = createCanvas(size[0], size[1]);
    ctx.fillStyle = 'transparent';
    ctx.fillRect(0, 0, size[0], size[1]);

    return {
      canvas,
      ctx,
      canvasTexture: new CanvasTexture(canvas),
    };
  }, [size[0], size[1]]);

  useEffect(() => {
    return () => {
      if (canvasTexture) {
        canvasTexture.dispose();
      }
    };
  }, []);

  return { canvas, ctx, canvasTexture };
};
