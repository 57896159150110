import { LoggedInAuthGuard } from '@vizcom/auth/ui';
import { Navigate, Route, Routes } from 'react-router-dom';
import { OnboardingProfilePage } from './onboardingProfilePage';

export const OnboardingRoutes = () => {
  return (
    <LoggedInAuthGuard>
      <Routes>
        <Route path="profile" element={<OnboardingProfilePage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </LoggedInAuthGuard>
  );
};
