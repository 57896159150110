import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  TextInput,
  addToast,
} from '@vizcom/shared-ui-components';
import { FormEventHandler, useCallback, useState } from 'react';
import {
  useCreateFolder,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { useNavigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import { FolderEventName } from '@vizcom/shared/data-shape';

type CreateFolderModalProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  folderId: string;
  redirect?: boolean;
};

export const CreateFolderModal = ({
  redirect = false,
  folderId,
  isOpen,
  setIsOpen,
}: CreateFolderModalProps) => {
  const [name, setName] = useState('');
  const [res, createFolder] = useCreateFolder();
  const navigate = useNavigate();

  const CreateFolder = useCallback(async () => {
    await createFolder({
      input: {
        folder: {
          name: name,
          parentId: folderId,
        },
      },
    });

    trackEvent('Folder Created');

    publishTrackingEvent({
      type: FolderEventName.FOLDER_CREATED,
      data: {
        folderIds: [folderId],
      },
    });
  }, [createFolder, name, folderId, publishTrackingEvent]);

  const loading = res.fetching;

  const handleCancel = () => {
    setIsOpen(false);
    setName('');
  };
  const handleConfirm: FormEventHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!name) {
      return;
    }

    await CreateFolder();
    setName('');
    setIsOpen(false);

    addToast('Folder created.');

    if (redirect) {
      navigate(paths.files.folder(folderId));
    }
  };

  return (
    <Modal style={{ minWidth: 400 }} isOpen={isOpen} setIsOpen={setIsOpen}>
      <ModalHeader>
        <ModalTitle>Name your new folder</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>
      <form onSubmit={handleConfirm}>
        <ModalContent>
          <TextInput
            required
            type="text"
            value={name}
            placeholder={'New Folder'}
            onChange={(e) => setName(e.target.value)}
            autoFocus
          />
        </ModalContent>
        <ModalActions>
          <Button type="button" onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Create
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
