import { ReactNode, useEffect } from 'react';
import {
  getSharingSecret,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import { MobileRedirect } from '@vizcom/web/features/mobile-redirect';
import { LoadingLogo } from '@vizcom/shared-ui-components';

const REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY = 'vizcom_redirect_after_login_url';

const isSmallScreen = window.innerWidth < 480;

export const LoggedInAuthGuard = (props: {
  children: ReactNode;
  acceptSharingSecret?: boolean;
  isMobileAccessible?: boolean;
}) => {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const sharingSecret = getSharingSecret();

  useEffect(() => {
    const redirectAfterLogin = sessionStorage.getItem(
      REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY
    );

    if (!currentUser.fetching && currentUser.data && redirectAfterLogin) {
      sessionStorage.removeItem(REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY);
      navigate(redirectAfterLogin, { replace: true });
    }
  }, [currentUser, navigate]);

  if (currentUser.fetching) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: '#151517',
        }}
      >
        <LoadingLogo />
      </div>
    );
  }

  if (!currentUser.data?.id && !(props.acceptSharingSecret && sharingSecret)) {
    sessionStorage.setItem(
      REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY,
      location.pathname + location.search + location.hash
    );
    return (
      <Navigate
        to={{ pathname: paths.auth.home(), hash: location.hash }}
        replace
      />
    );
  }

  if (!props.isMobileAccessible && isSmallScreen) {
    return <MobileRedirect />;
  }

  return <>{props.children}</>;
};

export const LoggedOutAuthGuard = (props: { children: ReactNode }) => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    // This needs to be in an effect and not return <Navigate> because the render method should be pure
    // and here we need to mutation the sessionStorage
    if (!currentUser.fetching && currentUser.data?.id) {
      const redirectAfterLogin = sessionStorage.getItem(
        REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY
      );
      if (redirectAfterLogin) {
        sessionStorage.removeItem(REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY);
        navigate(redirectAfterLogin, { replace: true });
      } else {
        navigate(paths.files.dashboard(), { replace: true });
      }
    }
  }, [currentUser, navigate]);

  if (currentUser.fetching) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: '#151517',
        }}
      >
        <LoadingLogo />
      </div>
    );
  }

  if (currentUser.data?.id) {
    return null;
  }

  return <>{props.children}</>;
};

export const AdminAuthGuard = (props: { children: ReactNode }) => {
  const currentUser = useCurrentUser();

  if (currentUser.fetching) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: '#151517',
        }}
      >
        <LoadingLogo />
      </div>
    );
  }

  if (!currentUser.data?.id || currentUser.data.isAdmin !== true) {
    return <Navigate to={paths.auth.home()} replace />;
  }

  if (isSmallScreen) {
    return <MobileRedirect />;
  }

  return <>{props.children}</>;
};
