import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { CustomHtml } from '../../utils/CustomHtml';
import { Suspense, useEffect, useState } from 'react';
import { getElementSize, useCameraZoom } from '../../helpers';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';

import { WorkbenchElementPaletteAddImages } from './extra/WorkbenchElementPaletteAddImages';
import { PaletteImages } from './PaletteImages';
import { PaletteStatusButton } from './PaletteStatusButton';
import { PaletteUploadButton } from './PaletteUploadButton';
import { WorkbenchElementPaletteContextMenuItems } from '../../workbenchContextMenu/contextMenuItemsPerType/WorkbenchElementPaletteContextMenuItems';
import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import {
  PALETTE_NAME_MAX_WIDTH,
  PALETTE_NAME_MIN_WIDTH,
  PaletteName,
} from './PaletteName';
import { boundNumber } from '@vizcom/shared/js-utils';
import DashedRoundedRectangle from '../../utils/DashedRoundedRectangle';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import { addToast } from '@vizcom/shared-ui-components';
import { WorkbenchElementPaletteRename } from './extra/WorkbenchElementPaletteRename';
import { WorkbenchElementPaletteDownloadImages } from './extra/WorkbenchElementPaletteDownloadImages';
import { WorkbenchElementPaletteDuplicate } from './extra/WorkbenchElementPaletteDuplicate';

export const PALETTE_COLOR = '#FF5106';
export const PALETTE_COLOR_DISABLED = '#591C02';

interface WorkbenchElementPaletteProps {
  element: ClientSideWorkbenchElementPalette;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const WorkbenchElementPalette = ({
  element,
  singleFocused,
  isDragging,
  isResizing,
  handleAction,
}: WorkbenchElementPaletteProps) => {
  const zoom = useCameraZoom();
  const { width, height } = getElementSize(element);
  const [editingName, setEditingName] = useState(false);
  const [paletteNameWidth, setPaletteNameWidth] = useState(
    PALETTE_NAME_MIN_WIDTH
  );

  useEffect(() => {
    if (!element.failureReason) {
      return;
    }
    addToast(`${element.name} Training failed. (${element.failureReason})`, {
      ctaText: 'Retry',
      ctaAction: () => handleAction({ type: 'trainPalette', id: element.id }),
    });
  }, [element.failureReason]);

  const handleNameTextSync = (troika: any) => {
    const textWidth = Math.abs(
      troika.textRenderInfo.blockBounds[0] -
        troika.textRenderInfo.blockBounds[2]
    );
    setPaletteNameWidth(
      boundNumber(
        PALETTE_NAME_MIN_WIDTH,
        textWidth + 10,
        PALETTE_NAME_MAX_WIDTH
      )
    );
  };

  const fallback = (
    <>
      <mesh>
        <RoundedPlaneGeometry width={width} height={height} radius={6} />
        <meshBasicMaterial color="#FFF" />
      </mesh>
      {!singleFocused && (
        <DashedRoundedRectangle
          width={width}
          height={height}
          radius={6}
          color="#CBCBCD"
          dashSize={4 - zoom}
          gapSize={
            element.status === 'ready' || element.status === 'training'
              ? 0
              : 0.5 * (4 - zoom)
          }
        />
      )}
    </>
  );

  if (isResizing) {
    return fallback;
  }

  return (
    <>
      <PaletteName
        width={width}
        height={height}
        id={element.id}
        name={element.name}
        paletteNameWidth={paletteNameWidth}
        handleAction={handleAction}
        onSync={handleNameTextSync}
        editingName={editingName}
        setEditingName={setEditingName}
      />
      <PaletteStatusButton
        width={width}
        height={height}
        element={element}
        handleAction={handleAction}
        paletteNameWidth={paletteNameWidth}
      />
      {fallback}
      {element.sourceImages.nodes.length === 0 ? (
        <PaletteUploadButton
          paletteData={element}
          handleAction={handleAction}
        />
      ) : (
        <Suspense fallback={null}>
          <PaletteImages
            width={width}
            height={height}
            handleAction={handleAction}
            element={element}
          />
        </Suspense>
      )}
      {!isDragging && singleFocused && (
        <CustomHtml
          style={{ pointerEvents: 'none' }}
          position={[0, height / 2 + 14, 0.5]}
        >
          <WorkbenchElementExtra
            element={element}
            handleAction={handleAction}
            menuItems={
              <WorkbenchElementPaletteContextMenuItems
                handleAction={handleAction}
                element={element}
              />
            }
          >
            <WorkbenchElementPaletteRename
              handleRenamePalette={() => setEditingName(true)}
            />
            {element.status === 'idle' && (
              <WorkbenchElementPaletteAddImages
                paletteData={element}
                handleAction={handleAction}
              />
            )}
            {element.sourceImages.nodes.length > 0 && (
              <WorkbenchElementPaletteDownloadImages paletteData={element} />
            )}
            {['training', 'ready'].includes(element.status) && (
              <WorkbenchElementPaletteDuplicate
                paletteData={element}
                handleAction={handleAction}
              />
            )}
          </WorkbenchElementExtra>
        </CustomHtml>
      )}
    </>
  );
};
