import { Text, HomeIcon as HomeIconBase } from '@vizcom/shared-ui-components';
import styled from 'styled-components';

const HomeIcon = styled(HomeIconBase)`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

interface HeaderTitleProps {
  isTeamPage?: boolean;
  name: string;
}

export const HeaderTitle = ({ isTeamPage, name }: HeaderTitleProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 12,
      }}
    >
      {isTeamPage ? <HomeIcon style={{ flexShrink: 0 }} /> : null}
      <Text
        color="default"
        style={{
          fontSize: 22,
          gridArea: 'title',
          fontWeight: 600,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          padding: '10px 0',
        }}
        type="h1"
      >
        {name}
      </Text>
    </div>
  );
};
