import { sortBy } from 'lodash';
import { Line } from '@react-three/drei';
import { WorkbenchObjectObject3D } from '../objectsUserdata';
import { DEFAULT_GAP } from './layoutHelpers';
import { useTheme } from 'styled-components';
import { Fragment } from 'react';
import { MAX_Z_POSITION } from '../helpers';
import { filterExists } from '@vizcom/shared/js-utils';

export type SnapGuide = {
  type: 'vertical' | 'horizontal';
  position: number;
  start: number;
  end: number;
  startMarker: number;
  endMarker: number;
};

/**
 * Finds the snap guides for aligning elements within a bounding box.
 *
 * @param elements - The list of elements to be aligned.
 * @param boundingBox - The bounding box that defines the alignment area.
 * @returns An object containing the snap guides, as well as the bestSnapX and bestSnapY values for snapping elements.
 */
export function findSnapGuides(
  elements: WorkbenchObjectObject3D[],
  boundingBox: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  }
) {
  // Initialize the bestGuide object to store the best snap guides for each type
  const bestGuide = {
    vertical: null as SnapGuide | null,
    verticalLeft: null as SnapGuide | null,
    verticalRight: null as SnapGuide | null,
    horizontal: null as SnapGuide | null,
    horizontalTop: null as SnapGuide | null,
    horizontalBottom: null as SnapGuide | null,
  };

  // Calculate the center coordinates of the bounding box
  const boundingBoxCenterX = (boundingBox.left + boundingBox.right) / 2;
  const boundingBoxCenterY = (boundingBox.top + boundingBox.bottom) / 2;

  // Sort elements from closest to furthest from the center of the bounding box
  sortBy(elements, (e) =>
    Math.hypot(
      e.userData.elementX - boundingBoxCenterX,
      e.userData.elementY - boundingBoxCenterY
    )
  ).forEach((element) => {
    // Calculate the left and right edges of the element
    const elementLeft =
      element.userData.elementX - element.userData.elementWidth / 2;
    const elementRight =
      element.userData.elementX + element.userData.elementWidth / 2;

    // Calculate the top and bottom edges of the element
    const elementTop =
      element.userData.elementY + element.userData.elementHeight / 2;
    const elementBottom =
      element.userData.elementY - element.userData.elementHeight / 2;

    // Calculate the center coordinates of the element
    const elementCenterX = element.userData.elementX;
    const elementCenterY = element.userData.elementY;

    // Check if the element is above or below the bounding box
    const isAbove = elementCenterY > boundingBox.top;

    // Calculate the properties for the vertical alignment line
    const verticalLineProperties = {
      start: isAbove
        ? Math.min(boundingBox.bottom, elementBottom)
        : Math.max(boundingBox.top, elementTop),
      end: isAbove
        ? Math.max(elementTop, boundingBox.top)
        : Math.min(elementBottom, boundingBox.bottom),
      startMarker: isAbove ? elementBottom : elementTop,
      endMarker: isAbove ? elementTop : elementBottom,
    };

    // Check if the element is to the left or right of the bounding box
    const isLeft = elementCenterX < boundingBox.left;

    // Calculate the properties for the horizontal alignment line
    const horizontalLineProperties = {
      start: isLeft
        ? Math.max(boundingBox.right, elementRight)
        : Math.min(boundingBox.left, elementLeft),
      end: isLeft
        ? Math.min(elementLeft, boundingBox.left)
        : Math.max(elementRight, boundingBox.right),
      startMarker: isLeft ? elementRight : elementLeft,
      endMarker: isLeft ? elementLeft : elementRight,
    };

    // Check and update vertical alignments
    updateBestGuide(
      'verticalLeft',
      boundingBox.left,
      elementLeft,
      verticalLineProperties
    );
    updateBestGuide(
      'verticalLeft',
      boundingBox.left,
      elementRight,
      verticalLineProperties
    );
    updateBestGuide(
      'verticalRight',
      boundingBox.right,
      elementLeft,
      verticalLineProperties
    );
    updateBestGuide(
      'verticalRight',
      boundingBox.right,
      elementRight,
      verticalLineProperties
    );
    updateBestGuide(
      'vertical',
      boundingBoxCenterX,
      elementCenterX,
      verticalLineProperties
    );

    // Check and update horizontal alignments
    updateBestGuide(
      'horizontalTop',
      boundingBox.top,
      elementTop,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontalTop',
      boundingBox.top,
      elementBottom,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontal',
      boundingBox.bottom,
      elementTop,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontalBottom',
      boundingBox.bottom,
      elementBottom,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontalBottom',
      boundingBoxCenterY,
      elementCenterY,
      horizontalLineProperties
    );
  });

  // Get the closest snap guides for each type (vertical, horizontal)
  const verticalGuides = [
    bestGuide.vertical,
    bestGuide.verticalLeft,
    bestGuide.verticalRight,
  ];
  const horizontalGuides = [
    bestGuide.horizontal,
    bestGuide.horizontalTop,
    bestGuide.horizontalBottom,
  ];

  // Find the best snap positions for X and Y coordinates
  const { bestSnapX, bestSnapY } = getBestSnapXY(
    verticalGuides.filter(filterExists),
    horizontalGuides.filter(filterExists),
    boundingBoxCenterX,
    boundingBoxCenterY
  );

  // Return the snap guides and bestSnapX and bestSnapY values
  return {
    guides: Object.values(bestGuide).filter(filterExists),
    bestSnapX,
    bestSnapY,
  };

  /**
   * Finds the best snap positions for X and Y coordinates based on the given snap guides.
   *
   * @param verticalGuides - The vertical snap guides.
   * @param horizontalGuides - The horizontal snap guides.
   * @param boundingBoxCenterX - The X coordinate of the bounding box center.
   * @param boundingBoxCenterY - The Y coordinate of the bounding box center.
   * @returns An object containing the bestSnapX and bestSnapY values.
   */
  function getBestSnapXY(
    verticalGuides: SnapGuide[],
    horizontalGuides: SnapGuide[],
    boundingBoxCenterX: number,
    boundingBoxCenterY: number
  ) {
    let bestSnapX = null;
    let bestSnapY = null;

    // Find the closest snap position for X coordinate
    bestSnapX = verticalGuides.reduce((acc, guide) => {
      if (guide === null) return acc;
      if (acc === null) return guide.position;
      return Math.abs(guide.position - boundingBoxCenterX) <
        Math.abs(acc - boundingBoxCenterX)
        ? guide.position
        : acc;
    }, null as number | null);

    // Find the closest snap position for Y coordinate
    bestSnapY = horizontalGuides.reduce((acc, guide) => {
      if (guide === null) return acc;
      if (acc === null) return guide.position;
      return Math.abs(guide.position - boundingBoxCenterY) <
        Math.abs(acc - boundingBoxCenterY)
        ? guide.position
        : acc;
    }, null as number | null);

    return { bestSnapX, bestSnapY };
  }

  /**
   * Updates the bestGuide object with the closest snap guide for the given type.
   *
   * @param side - The type of the snap guide (vertical, verticalLeft, verticalRight, horizontal, horizontalTop, horizontalBottom).
   * @param boxPosition - The position of the bounding box edge.
   * @param elementPosition - The position of the element edge.
   * @param lineProperties - The properties for the alignment line.
   */
  function updateBestGuide(
    side:
      | 'vertical'
      | 'verticalLeft'
      | 'verticalRight'
      | 'horizontal'
      | 'horizontalTop'
      | 'horizontalBottom',
    boxPosition: number,
    elementPosition: number,
    lineProperties: {
      start: number;
      end: number;
      startMarker: number;
      endMarker: number;
    }
  ) {
    // Calculate the distance between the boxPosition and elementPosition
    const distance = Math.abs(boxPosition - elementPosition);

    // Check if the element is within the default gap
    if (distance < DEFAULT_GAP) {
      // Create a new snap guide object
      const newGuide = {
        type: (side.startsWith('vertical')
          ? 'vertical'
          : 'horizontal') as SnapGuide['type'],
        position: elementPosition,
        ...lineProperties,
      };

      // Check if the new snap guide is closer than the existing snap guide for the given type
      if (
        bestGuide[side] === null ||
        distance < Math.abs(boxPosition - bestGuide[side]!.position)
      ) {
        // Update the bestGuide object with the new snap guide
        bestGuide[side] = newGuide;
      }
    }
  }
}

export function SnapGuideLines({ snapGuides }: { snapGuides: SnapGuide[] }) {
  const theme = useTheme();
  const markerSize = 3;

  const createXMarker = (position: [number, number, number]) => {
    const [x, y, z] = position;
    return (
      <>
        <Line
          points={[
            [x - markerSize, y - markerSize, z],
            [x + markerSize, y + markerSize, z],
          ]}
          color={theme.text.danger}
          lineWidth={2}
        />
        <Line
          points={[
            [x - markerSize, y + markerSize, z],
            [x + markerSize, y - markerSize, z],
          ]}
          color={theme.text.danger}
          lineWidth={2}
        />
      </>
    );
  };

  return (
    <>
      {snapGuides.map((guide) => {
        return (
          <Fragment
            key={`${guide.position}-${guide.start}-${guide.end}-${guide.type}`}
          >
            <group position={[0, 0, MAX_Z_POSITION]}>
              <Line
                points={
                  guide.type === 'vertical'
                    ? [
                        [guide.position, guide.start, 0],
                        [guide.position, guide.end, 0],
                      ]
                    : [
                        [guide.start, guide.position, 0],
                        [guide.end, guide.position, 0],
                      ]
                }
                color={theme.text.danger}
                lineWidth={1}
              />

              {createXMarker(
                guide.type === 'vertical'
                  ? [guide.position, guide.startMarker, 0]
                  : [guide.startMarker, guide.position, 0]
              )}
              {createXMarker(
                guide.type === 'vertical'
                  ? [guide.position, guide.endMarker, 0]
                  : [guide.endMarker, guide.position, 0]
              )}
            </group>
          </Fragment>
        );
      })}
    </>
  );
}
