import { useEffect, useRef, useState } from 'react';

const useDebouncedValue = <T>(
  initialValue: T,
  time: number
): [T, T, React.Dispatch<T>] => {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setDebouncedValue(value);
      timeout.current = null;
    }, time);
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [value, time]);

  return [debouncedValue, value, setValue];
};

export default useDebouncedValue;
