import { useTheme } from 'styled-components';
import styled from 'styled-components';
import { ImageInferenceType } from '@vizcom/shared/data-access/graphql';
import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  AutoplayVideo,
} from '@vizcom/shared-ui-components';

import renderVideo from './assets/tooltip_render.mp4';
import refineVideo from './assets/tooltip_refine.mp4';

type ImageInferenceTypeToggleProps = {
  value: ImageInferenceType;
  disabled: boolean;
  onToggle: () => void;
};

export const ImageInferenceTypeToggle = ({
  value,
  disabled,
  onToggle,
}: ImageInferenceTypeToggleProps) => {
  const theme = useTheme();

  return (
    <div
      style={{
        padding: '12px 20px',
        pointerEvents: disabled ? 'none' : 'all',
        maskImage: disabled
          ? 'linear-gradient( to bottom, rgba(21,21,23,1) -50%, transparent 100% )'
          : 'none',
      }}
    >
      <ToggleWrapper onClick={onToggle} $enabled={!disabled}>
        <RichTooltip
          trigger="hover"
          placement="bottom"
          delay={{
            open: 1500,
            close: 0,
          }}
          padding={12}
        >
          <RichTooltipTrigger>
            <div
              style={{
                color:
                  value === ImageInferenceType.Render
                    ? theme.text.default
                    : 'inherit',
              }}
            >
              Render
            </div>
          </RichTooltipTrigger>
          <TooltipContent>
            <TooltipTitle type="b2">Render from sketch</TooltipTitle>
            <TooltipDescription type="b2" color="info">
              Create realistic renderings from a sketch or line drawing.
            </TooltipDescription>
            <div style={{ textAlign: 'right', marginBottom: '12px' }}>
              <a
                href="https://youtu.be/ZH_s91eEF1M?si=BkZb_xu_0HEJQFV4&t=71"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: '10px',
                  color: theme.primary.default,
                  textDecoration: 'none',
                }}
              >
                Learn more
              </a>
            </div>
            <TooltipVideo src={renderVideo} />
          </TooltipContent>
        </RichTooltip>
        <RichTooltip
          trigger="hover"
          placement="bottom"
          delay={{
            open: 1500,
            close: 0,
          }}
          padding={12}
        >
          <RichTooltipTrigger>
            <div
              style={{
                color:
                  value === ImageInferenceType.Refine
                    ? theme.text.default
                    : 'inherit',
              }}
            >
              Refine
            </div>
          </RichTooltipTrigger>
          <TooltipContent>
            <TooltipTitle type="b2">Refine existing image</TooltipTitle>
            <TooltipDescription type="b2" color="info">
              Add detail to your renderings.
            </TooltipDescription>
            <div style={{ textAlign: 'right', marginBottom: '12px' }}>
              <a
                href="https://youtu.be/ZH_s91eEF1M?si=B5r9MojgwU1XTtud&t=266"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: '10px',
                  color: theme.primary.default,
                  textDecoration: 'none',
                }}
              >
                Learn more
              </a>
            </div>
            <TooltipVideo src={refineVideo} />
          </TooltipContent>
        </RichTooltip>
        <Toggle $imageInferenceType={value} />
      </ToggleWrapper>
    </div>
  );
};

const Toggle = styled.span<{ $imageInferenceType: ImageInferenceType }>`
  box-sizing: content-box;
  color: #151515;
  border-radius: 8px;
  background-color: white;
  position: absolute;
  left: ${({ $imageInferenceType }) =>
    $imageInferenceType === ImageInferenceType.Refine
      ? ['calc(50% - 4px)']
      : '2px'};
  padding: ${({ $imageInferenceType }) =>
    $imageInferenceType === ImageInferenceType.Refine
      ? '0 0 0 2px'
      : '0 2px 0 0'};
  top: 2px;
  width: 50%;
  height: calc(100% - 4px);
  z-index: -1;
  transition: 0.3s ease-out all;
  background-color: ${({ theme }) => theme.surface.e2};
`;

const ToggleWrapper = styled.div<{ $enabled?: boolean }>`
  width: 100%;
  text-align: center;
  will-change: transform;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.surface.e1};

  > div {
    display: inline-block;
    background: none;
    width: 49%;
    height: 32px;
    padding: 10px 0 10px 0;
    position: relative;
    border: none;
    transition: 0.3s ease all;
    font-weight: 600;

    &:focus {
      outline: none;
    }
  }
`;

const TooltipContent = styled(RichTooltipContent)`
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 240px;
  padding: 16px;
  background-color: ${({ theme }) => theme.surface.e0};
`;

const TooltipTitle = styled(Text)`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TooltipDescription = styled(Text)`
  margin-bottom: 12px;
  line-height: 1.5;
`;

const TooltipVideo = styled(AutoplayVideo)`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 6px;
`;
