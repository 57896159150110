import { Button } from '@vizcom/shared-ui-components';
import styled from 'styled-components';

export const WorkbenchMenuButton = styled(Button)`
  display: flex;
  font-size: 12px;
  gap: 7px;
  padding: 10px 16px;
  text-align: left;
  align-items: flex-start;
  justify-content: space-between;
`;
