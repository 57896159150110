import {
  eventTargetIsInput,
  useDocumentEventListener,
} from '@vizcom/shared-ui-components';

import {
  isPaintingTool,
  useWorkbenchStudioToolState,
} from '../studio/studioState';
import { CSSProperties } from 'styled-components';
import { Drawing2dStudio } from '../../lib/useDrawingSyncedState';
import { ROTATION_CURSORS } from './ResizePositionRotationPresenter';
import { EventMesh } from '../studio/DrawingCompositor/EventMesh';

export const GlobalCursor = (props: {
  activeLayer: Drawing2dStudio['layers']['nodes'][0] | undefined;
  drawingSize: [number, number];
}) => {
  const toolState = useWorkbenchStudioToolState();

  useDocumentEventListener('keydown', (e) => {
    if (eventTargetIsInput(e)) {
      return;
    }
    if (e.key?.toLowerCase() === 'r') {
      toolState.setRotating(true);
    }
    if (e.key === ' ') {
      toolState.setPanning(true);
    }
  });

  useDocumentEventListener('keyup', (e) => {
    if (e.key?.toLowerCase() === 'r') {
      toolState.setRotating(false);
    }
    if (e.key === ' ') {
      toolState.setPanning(false);
    }
  });

  let cursor: CSSProperties['cursor'] | null = null;
  const canUseBrushOrEraser = props.activeLayer && props.activeLayer.visible;
  if (!canUseBrushOrEraser && isPaintingTool(toolState.tool)) {
    cursor = 'not-allowed';
  } else if (toolState.resizing) {
    cursor = 'ew-resize';
  } else if (toolState.rotating) {
    cursor = ROTATION_CURSORS['-1/1'];
  } else if (toolState.panning) {
    cursor = 'grabbing';
  } else {
    cursor = null;
  }

  return (
    <group>
      <EventMesh
        drawingSize={props.drawingSize}
        eventMeshProps={{
          userData: {
            globalCursor: cursor,
          },
          renderOrder: 11,
        }}
      />
    </group>
  );
};
