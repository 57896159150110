import * as Three from 'three';
import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { WORKBENCH_CONTENT_RENDERING_ORDER } from '../constants';

const gridConfiguration = {
  resolutionScaling: {
    x: 2048.0,
    y: 2048.0,
  },
};

const vertexShader = `
void main() {
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

const fragmentShader = `
uniform vec2 vOffset;
uniform vec2 vOrigin;
uniform vec3 vResolution;
uniform float fZoom;

uniform vec3 vColorPrimary;
uniform vec3 vColorSecondary;

void getGridInfluence(
  inout vec4 vOutputColor,
  in vec2 vPosition,
  in float fSpacing,
  in float fMinZoom,
  in float fMaxZoom
) {
  float fSharpness = 0.00075 * vResolution.z;         // NOTE Adjust dot blending / sharpness
  float fRadius = 0.0000005 * vResolution.z * fZoom;  //      Scale by zoom
  float fZoomedSpacing = fSpacing * fZoom;
  float fContrast = 2.5;                              // NOTE Purely for artistic purposes adjust grid contrast
                                                      //      when zooming in and out

  vec2 vSample = vPosition - fZoomedSpacing * round(vPosition / fZoomedSpacing);
  float fGridColorInfluence = clamp(fZoom * (fZoom - fMinZoom) / (fMaxZoom - fMinZoom), 0.0, 1.0);

  vOutputColor.rgb = mix(
    mix(vColorSecondary, vColorPrimary, 1.0 - fGridColorInfluence * fContrast),
    vOutputColor.rgb,
    smoothstep(fRadius, fRadius + fSharpness, length(vSample) - fRadius)
  );
}

void main() {
  vec2 vUv = gl_FragCoord.xy / vResolution.xy;                // NOTE Use screen-space coordinates
  vec2 vPosition = vUv                                        // NOTE Calculate position
    - (vOrigin * vResolution.z)                               //      Move to the centre of the viewport
    + ((vOffset / vResolution.xy) * vResolution.z) * fZoom;   //      Adjust for zoom transform

  gl_FragColor = vec4(vColorPrimary, 1.0);

  getGridInfluence(gl_FragColor, vPosition, 0.005 * vResolution.z, 0.7, 2.5);
  getGridInfluence(gl_FragColor, vPosition, 0.025 * vResolution.z, 0.15, 1.0);
  getGridInfluence(gl_FragColor, vPosition, 0.125 * vResolution.z, 0.05, 0.1);
  getGridInfluence(gl_FragColor, vPosition, 0.5 * vResolution.z, 0.01, 0.05);

  gl_FragColor = linearToOutputTexel(gl_FragColor);
}
`;

// NOTE Helper component allowing rendering shader as a scene background of the
//      scene (NOTE: This overrides the default rendering, https://docs.pmnd.rs/react-three-fiber/api/hooks#taking-over-the-render-loop)
//      Rendered underneath <SceneLayer /> components
export const BackgroundLayer = () => {
  const theme = useTheme();

  const {
    backgroundUniforms,
    backgroundPlane,
    backgroundCamera,
    backgroundScene,
  } = useMemo(() => {
    const backgroundUniforms = {
      fZoom: { value: 1.0 },
      vOffset: {
        value: new Three.Vector2(0.0, 0.0),
      },
      vResolution: {
        value: new Three.Vector3(
          gridConfiguration.resolutionScaling.x,
          gridConfiguration.resolutionScaling.y,
          window.devicePixelRatio || 1.0
        ),
      },
      vOrigin: {
        value: new Three.Vector2(1.0, 1.0),
      },
      vColorPrimary: {
        value: new Three.Color(0xffffff),
      },
      vColorSecondary: {
        value: new Three.Color(0x000000),
      },
    };
    const backgroundMaterial = new Three.ShaderMaterial({
      uniforms: backgroundUniforms,
      vertexShader,
      fragmentShader,
    });
    const backgroundPlane = new Three.Mesh(
      new Three.PlaneGeometry(1.0, 1.0),
      backgroundMaterial
    );
    const backgroundCamera = new Three.OrthographicCamera(-0.5, 0.5, 0.5, -0.5);
    const backgroundScene = new Three.Scene();

    backgroundScene.add(backgroundPlane);
    backgroundPlane.position.z = backgroundCamera.near * -1.1;

    return {
      backgroundUniforms,
      backgroundPlane,
      backgroundCamera,
      backgroundScene,
    };
  }, []);

  useFrame(({ gl, camera }) => {
    gl.autoClear = false;
    gl.clear();

    backgroundUniforms.vOffset.value.x = camera.position.x;
    backgroundUniforms.vOffset.value.y = camera.position.y;

    backgroundUniforms.fZoom.value = camera.zoom || 1.0;

    gl.render(backgroundScene, backgroundCamera);
  }, WORKBENCH_CONTENT_RENDERING_ORDER.indexOf('dotBackground'));

  useEffect(() => {
    // TODO Replace with light/dark mode theme values
    backgroundUniforms.vColorPrimary.value.set(theme.background.base);
    backgroundUniforms.vColorSecondary.value.set(theme.background.pattern);
  }, [theme]);

  useEffect(() => {
    const onWindowResize = () => {
      backgroundUniforms.vOrigin.value.set(
        window.innerWidth / gridConfiguration.resolutionScaling.x / 2.0,
        window.innerHeight / gridConfiguration.resolutionScaling.y / 2.0
      );

      backgroundUniforms.vResolution.value.z = window.devicePixelRatio || 1.0;

      backgroundPlane.scale.set(
        window.innerHeight * window.devicePixelRatio,
        window.innerWidth * window.devicePixelRatio,
        1.0
      );
    };

    onWindowResize();

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);

      if (backgroundPlane) {
        backgroundScene.remove(backgroundPlane);

        backgroundPlane.geometry.dispose();
        backgroundPlane.material.dispose();
      }
    };
  }, []);

  return null;
};
