import { v4 as uuidv4 } from 'uuid';
import { DoubleSide, Group, Texture, Vector2, Matrix3 } from 'three';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import { initializeTransformTextures, useSelectionTransform } from './helpers';
import {
  useLayoutEffect,
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { TransformEnveloppeHelper } from '../../../utils/TransformEnveloppeHelper';
import { WarpGeometry, WarpPoint } from './warp';
import {
  LayerTextureRenderer,
  LayerTextureRendererRef,
} from '../LayerTextureRenderer';
import {
  LayerTranslation,
  useLayerTranslationTransform,
} from './useLayerTranslationTransform';
import {
  initialWarpPointDirections,
  useLayerWarpTransform,
} from './useLayerWarpTransform';
import { LayerContent } from '../LayersCompositor/LayerContent';
import { LayerStaticView } from '../CompositeLayer';
import { ActiveMask, MaskDisplayMode } from '../../selection/ActiveMask';
import { createPortal } from '@react-three/fiber';
import { MutateLocalStateAction } from '../../../../lib/actions/drawing/mutateLocalStateAction';
import { SyncedActionPayloadFromType } from '../../../../lib/SyncedAction';
import { useLastValue, useStableCallback } from '@vizcom/shared-ui-components';
import {
  useOnBeforeSelectionChange,
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from '../../selection/useSelectionApi';

interface LayerTransformProps {
  drawingSize: [number, number];
  layer: Drawing2dStudio['layers']['nodes'][0];
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
}

export const LayerWarpControls = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
  filterHistory,
}: LayerTransformProps) => {
  const layerTextureRendererRef = useRef<LayerTextureRendererRef>(null!);
  const resizerGroupRef = useRef<Group>(null!);
  const lastLayerImageRef = useRef(layerImage?.image);

  const [hasLayerChanged, setHasLayerChanged] = useState(false);

  const selectionApiStore = useSelectionApiStore();
  const selectionTexture_treatEmptyMaskAsFull = useSubscribeToSelectionApi(
    (state) => state.texture_treatEmptyMaskAsFull
  );
  const selectionTextureVersion = useSubscribeToSelectionApi(
    (state) => state.version
  );

  const { scene, selectionFBO, selectionToImageData } =
    useSelectionTransform(drawingSize);

  const croppedTexture = useMemo(
    () =>
      initializeTransformTextures(
        layerImage,
        selectionApiStore.getState().getSelectionImage(),
        selectionApiStore.getState().texture,
        drawingSize
      ),
    [layerImage, drawingSize, selectionTextureVersion, selectionApiStore]
  );

  const updateLayerImage = useCallback(
    (undoGroupId?: string) => {
      handleAction(
        {
          type: 'updateLayer',
          id: layer.id,
          data: {
            image: layerTextureRendererRef.current.exportTexture(),
          },
        },
        { undoGroupId }
      );
      setHasLayerChanged(false);
    },
    [handleAction, layer.id]
  );

  //These are the transforms applied to the layer at rest.
  //These values are then composed with the transfom from the controls.
  const [basePosition, setBasePosition] = useState<[number, number]>([0, 0]);
  const [baseWarpPoints, setBaseWarpPoints] = useState<WarpPoint[]>(
    initialWarpPointDirections.map((p) => [
      (p[0] * (croppedTexture?.boundingBox.width ?? 0)) / 2,
      (p[1] * (croppedTexture?.boundingBox.height ?? 0)) / 2,
    ])
  );

  // expose a stable ref to the last value of the transform state, used when generating the undo/redo actions
  const transformStateRef = useLastValue({
    basePosition,
    baseWarpPoints,
  });

  const getMutateLocalStateAction = useStableCallback(
    (editionFunction: () => void) => {
      const snapshot = {
        translation: [0, 0] as [number, number],
        warpPoints: [] as WarpPoint[],
      };

      const action = {
        type: 'mutateLocalState',
        actionId: `warp_${uuidv4()}`,
        onExecute: () => {
          snapshot.translation = transformStateRef.current.basePosition;
          snapshot.warpPoints = transformStateRef.current.baseWarpPoints;
          editionFunction();
        },
        undoConstructor: () =>
          getMutateLocalStateAction!(() => {
            setBasePosition(snapshot.translation);
            setBaseWarpPoints(snapshot.warpPoints);
          }),
      } as SyncedActionPayloadFromType<typeof MutateLocalStateAction>;
      return action;
    }
  );

  const cleanupLocalHistory = () => {
    filterHistory((action) =>
      Boolean(
        action.payload.type === 'mutateLocalState' &&
          action.payload.actionId?.startsWith('warp_')
      )
    );
  };

  const {
    bindTranslationHandle,
    resetTranslationTransform,
    translationTransform,
  } = useLayerTranslationTransform(
    useStableCallback((translation: [number, number]) => {
      handleAction(
        getMutateLocalStateAction!(() => {
          setBasePosition([
            basePosition[0] + translation[0],
            basePosition[1] + translation[1],
          ]);
          resetTranslationTransform();
          setHasLayerChanged(true);
        })
      );
    }),
    resizerGroupRef
  );

  const { bindWarpHandle, resetWarp, warpPoints } = useLayerWarpTransform(
    baseWarpPoints,
    useStableCallback((warpPoints: WarpPoint[]) => {
      handleAction(
        getMutateLocalStateAction!(() => {
          setBaseWarpPoints(warpPoints);
          setHasLayerChanged(true);
        })
      );
    }),
    resizerGroupRef,
    croppedTexture?.boundingBox.width ?? 0,
    croppedTexture?.boundingBox.height ?? 0
  );

  // using `useLayoutEffect` instead of `useEffect` to make sure we can export the texture before LayerTextureRenderer is unmounted
  // and we lose the reference to the updated texture
  useLayoutEffect(() => {
    return () => {
      if (hasLayerChanged) {
        cleanupLocalHistory();
        const undoGroupId = uuidv4();
        updateLayerImage(undoGroupId);
        selectionApiStore.getState().editSelectionCanvas((ctx) => {
          ctx.putImageData(selectionToImageData(), 0, 0);
        }, undoGroupId);
      }
    };
  }, [layer.id, hasLayerChanged]); // commit update when unmounting or when layer changes

  useOnBeforeSelectionChange(() => {
    if (hasLayerChanged) {
      cleanupLocalHistory();
      updateLayerImage();
    }
  });

  useEffect(() => {
    resetWarp();
    resetTranslationTransform();
  }, [selectionTextureVersion]);

  if (lastLayerImageRef.current !== layerImage?.image) {
    // when layer image changes, reset the transform
    // this let us keep the transforms while the new image is being passed through handleAction
    // and only reset the transform when the new image is ready
    // this synchronize both actions to prevent flickering
    lastLayerImageRef.current = layerImage?.image;
    resetWarp();
    resetTranslationTransform();
    setBasePosition([0, 0]);
    setBaseWarpPoints(
      initialWarpPointDirections.map((p) => [
        (p[0] * (croppedTexture?.boundingBox.width ?? 0)) / 2,
        (p[1] * (croppedTexture?.boundingBox.height ?? 0)) / 2,
      ])
    );
  }

  if (!croppedTexture?.texture) {
    return (
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
      >
        <LayerStaticView layer={layer} drawingSize={drawingSize} />
      </LayerContent>
    );
  }
  const textureMatrix = new Matrix3().setUvTransform(
    croppedTexture?.texture.offset?.x || 0,
    croppedTexture?.texture.offset.y || 0,
    croppedTexture?.texture.repeat.x || 1,
    croppedTexture?.texture.repeat.y || 1,
    0,
    0,
    0
  );

  const computedTransform = {
    translation: [
      croppedTexture.boundingBox.centerDeltaX +
        basePosition[0] +
        translationTransform[0],
      croppedTexture.boundingBox.centerDeltaY +
        basePosition[1] +
        translationTransform[1],
    ] as LayerTranslation,
  };

  return (
    <>
      <group
        ref={resizerGroupRef}
        renderOrder={3}
        position={[
          computedTransform.translation[0],
          computedTransform.translation[1],
          0,
        ]}
      >
        <TransformEnveloppeHelper
          points={warpPoints}
          warpHandleMeshPropsGetter={bindWarpHandle as any}
          moveHandleMeshProps={bindTranslationHandle() as any}
        />
      </group>
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
      >
        <LayerTextureRenderer
          width={drawingSize[0]}
          height={drawingSize[1]}
          ref={layerTextureRendererRef}
        >
          {croppedTexture?.alphaMap && (
            <mesh renderOrder={2}>
              <planeGeometry args={[drawingSize[0], drawingSize[1]]} />
              <maskedWarpMaterial
                u_points={warpPoints.map((p) => new Vector2(p[0], p[1]))}
                u_textureMatrix={textureMatrix}
                u_map={layerImage!}
                u_mask={selectionTexture_treatEmptyMaskAsFull}
                u_translation={computedTransform.translation}
                side={DoubleSide}
                transparent
                depthTest={false}
              />
            </mesh>
          )}
          {!croppedTexture?.alphaMap && (
            <group
              renderOrder={2}
              position={[
                computedTransform.translation[0],
                computedTransform.translation[1],
                0,
              ]}
            >
              <mesh renderOrder={2}>
                <WarpGeometry points={warpPoints} />
                <warpMaterial
                  u_points={warpPoints.map((p) => new Vector2(p[0], p[1]))}
                  u_textureMatrix={textureMatrix}
                  u_map={croppedTexture?.texture}
                  side={DoubleSide}
                  transparent
                  depthTest={false}
                />
              </mesh>
            </group>
          )}
        </LayerTextureRenderer>
      </LayerContent>

      {croppedTexture.alphaMap && (
        <>
          {/*render transformed selection*/}
          {createPortal(
            <>
              <group
                position={[
                  computedTransform.translation[0],
                  computedTransform.translation[1],
                  0,
                ]}
              >
                <mesh renderOrder={2}>
                  <WarpGeometry points={warpPoints} />
                  <warpMaterial
                    u_points={warpPoints.map((p) => new Vector2(p[0], p[1]))}
                    u_textureMatrix={textureMatrix}
                    u_map={
                      croppedTexture?.alphaMap ||
                      selectionTexture_treatEmptyMaskAsFull
                    }
                    side={DoubleSide}
                  />
                </mesh>
              </group>
            </>,
            scene
          )}
          {/*display transformed selection*/}
          <ActiveMask
            drawingSize={drawingSize}
            maskTexture={selectionFBO.texture}
            mode={MaskDisplayMode.MARCHING_ANTS}
          />
        </>
      )}
    </>
  );
};
