import { MouseEventHandler, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, TextInput, Text } from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { CustomHtml } from '../../utils/CustomHtml';
import {
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../helpers';

import styled, { useTheme } from 'styled-components';
import { useThree } from '@react-three/fiber';
import { GENERATED_IMAGES_MARGIN, isDraggingContext } from '../../../lib/utils';

import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';

import MixSlider, { DisabledMixSlider } from './MixSlider';
import { WorkbenchElementMixData } from '@vizcom/shared/data-access/graphql';
import { addToast } from '@vizcom/shared-ui-components';
import { findFirstFreeSlotInScene } from '../../utils/freeSlotFinders';
import { WorkbenchElementDragConnector } from '../../elementConnector/workbenchElementDragConnector';
import { CustomText } from '../../utils/CustomText';
import { getMixElementHeight } from '../../utils/getContentSize';

interface WorkbenchElementMixProps {
  element: WorkbenchElementMixData;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  showSingleFocusedControls: boolean;
  sourceDrawingsThumbnails: Record<string, SourceDrawingData> | undefined;
  selectedSourceId: string | null;
  setSelectedSourceId: (sourceId: string | null) => void;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export interface MixSourceDrawing {
  sourceDrawingId: string;
  weight: number;
}

export type SourceDrawingData = {
  thumbnail: string | null | undefined;
  height: number;
  width: number;
};

export const WorkbenchElementMix = ({
  element,
  singleFocused,
  isDragging,
  isResizing,
  showSingleFocusedControls,
  sourceDrawingsThumbnails,
  selectedSourceId,
  setSelectedSourceId,
  handleAction,
}: WorkbenchElementMixProps) => {
  const scene = useThree((s) => s.scene);
  const isDraggingRef = useContext(isDraggingContext);
  const theme = useTheme();

  const { width, height } = getElementSize(element);
  const contentHeight = getMixElementHeight(element.sourceDrawings);
  const contentScale = height / contentHeight;

  const hasDrawings =
    element.sourceDrawings && element.sourceDrawings.length > 0;

  const handleSubmit: MouseEventHandler = async (e) => {
    if (isDraggingRef.current) {
      return;
    }

    if (!hasDrawings || element.sourceDrawings.length < 2) {
      return addToast('Connect two images to mix');
    }

    e.preventDefault();
    const width =
      sourceDrawingsThumbnails?.[element.sourceDrawings[0].sourceDrawingId]
        .width || 300;
    const height =
      sourceDrawingsThumbnails?.[element.sourceDrawings[0].sourceDrawingId]
        .height || 300;
    const position = findFirstFreeSlotInScene(scene, {
      firstSlotX:
        element.x + element.width / 2 + width / 2 + GENERATED_IMAGES_MARGIN,
      firstSlotY: element.y,
      slotWidth: width + GENERATED_IMAGES_MARGIN,
      slotHeight: height + GENERATED_IMAGES_MARGIN,
      maxElementPerLine: 4,
    });
    const zRange = getWorkbenchElementZPositionRange(scene);

    handleAction({
      type: 'triggerMix',
      elementId: element.id,
      placeholderId: uuidv4(),
      sourceDrawings: element.sourceDrawings,
      x: position[0],
      y: position[1],
      width: Math.trunc(width),
      height: Math.trunc(height),
      zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : element.zIndex + 1,
    });
  };

  const deleteDrawing = (drawingId: string) => {
    const sourceDrawings = [...element.sourceDrawings];

    const newDrawings = sourceDrawings.filter(
      (source) => source.sourceDrawingId !== drawingId
    );
    handleAction({
      type: 'updateMix',
      elementId: element.id,
      sourceDrawings: newDrawings,
    });
  };
  const setWeight = (weight: number, drawingId: string) => {
    const sourceDrawings: MixSourceDrawing[] = JSON.parse(
      JSON.stringify(element.sourceDrawings)
    );

    const sourceIndex = sourceDrawings.findIndex(
      (obj) => obj.sourceDrawingId === drawingId
    );
    sourceDrawings[sourceIndex].weight = weight;
    handleAction({
      type: 'updateMix',
      elementId: element.id,
      sourceDrawings,
    });
  };

  if (isResizing) {
    return (
      <mesh>
        <RoundedPlaneGeometry width={width} height={height} radius={16} />
        <meshBasicMaterial color={theme.surface.e0} />
      </mesh>
    );
  }

  return (
    <>
      <CustomText
        color={singleFocused ? theme.primary.default : theme.text.info}
        position={[-width / 2, height / 2 + 14, 0.5]}
        fontSize={12}
        font={'Inter'}
        fontWeight={'semiBold'}
        anchorX={'left'}
        whiteSpace="nowrap"
        content={'Image Mix'}
      />
      {showSingleFocusedControls && (
        <WorkbenchElementDragConnector
          element={element}
          handleAction={handleAction}
          disabled={element.sourceDrawings.length === 5}
        />
      )}
      <CustomHtml
        transform
        occlude="blending"
        position={[0, 0, 0.5]}
        scaleOcclusionGeometry={false}
        scale={[contentScale, contentScale, 1]}
        contentSize={{ width, height }}
        geometry={
          <RoundedPlaneGeometry
            radius={16}
            width={width / contentScale}
            height={contentHeight}
          />
        }
      >
        <div
          style={{
            width: width / contentScale,
            height: contentHeight,
            userSelect: 'none',
          }}
        >
          <Mix>
            <MixList>
              {hasDrawings ? (
                <>
                  {element.sourceDrawings.map((source: MixSourceDrawing) => (
                    <MixSlider
                      key={source.sourceDrawingId}
                      source={source}
                      image={
                        sourceDrawingsThumbnails?.[source.sourceDrawingId]
                          ?.thumbnail
                      }
                      setWeight={setWeight}
                      deleteDrawing={deleteDrawing}
                      setSelectedSourceId={setSelectedSourceId}
                    />
                  ))}
                  {element.sourceDrawings.length < 5 && <DisabledMixSlider />}
                </>
              ) : (
                <>
                  <EmptyText>Connect 2-5 images to Mix</EmptyText>
                  <DisabledMixSlider />
                </>
              )}
              <GenerateButton onClick={handleSubmit} variant="primary" size="M">
                <span>Mix Images</span>
              </GenerateButton>
            </MixList>
          </Mix>
        </div>
      </CustomHtml>
      {!isDragging && singleFocused && (
        <CustomHtml
          style={{ pointerEvents: 'none' }}
          position={[0, height / 2, 0.5]}
        >
          <WorkbenchElementExtra
            element={element}
            handleAction={handleAction}
          />
        </CustomHtml>
      )}
    </>
  );
};

const Mix = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  background: ${({ theme }) => theme.surface.e0};
  border-radius: ${(p) => p.theme.borderRadius.default};
  color: ${({ theme }) => theme.text.default};
  ${TextInput} {
    flex: 1;
  }
`;

const MixList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow-y: auto;
  justify-content: space-between;
`;

const EmptyText = styled(Text)`
  font-size: 18px;
  color: ${({ theme }) => theme.text.info};
`;

const GenerateButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 0px;

  > span {
    font-size: 16px;
  }
`;
