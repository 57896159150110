import { create as _create } from 'zustand';
import type { StateCreator } from 'zustand';
import { MaskOperation } from './selection/utils';

export enum WorkbenchStudioTool {
  Move,
  Transform,
  Brush,
  Eraser,
  Shape,
  BezierSelection,
  Lasso,
  BrushSelection,
  AutoSelection,
  PaintBucket,
}

const transformTools = [
  WorkbenchStudioTool.Move,
  WorkbenchStudioTool.Transform,
];

const paintingTools = [
  WorkbenchStudioTool.Brush,
  WorkbenchStudioTool.PaintBucket,
  WorkbenchStudioTool.Eraser,
  WorkbenchStudioTool.Shape,
];

export const selectionTools = [
  WorkbenchStudioTool.Lasso,
  WorkbenchStudioTool.BrushSelection,
  WorkbenchStudioTool.BezierSelection,
  WorkbenchStudioTool.AutoSelection,
];

export const isTransformTool = (
  tool: WorkbenchStudioTool
): tool is typeof transformTools[number] => selectionTools.includes(tool);

export const isPaintingTool = (
  tool: WorkbenchStudioTool
): tool is typeof paintingTools[number] => paintingTools.includes(tool);

export const isSelectionTool = (
  tool: WorkbenchStudioTool
): tool is typeof selectionTools[number] => selectionTools.includes(tool);

export interface ToolSettings {
  toolSize: number;
  setToolSize: (size: number) => void;

  toolOpacity: number;
  setToolOpacity: (opacity: number) => void;

  toolAngle: number;
  setToolAngle: (angle: number) => void;

  toolHardness: number;
  setToolHardness: (hardness: number) => void;

  toolAspect: number;
  setToolAspect: (aspect: number) => void;

  toolPressureSize: boolean;
  setToolPressureSize: (toolPressureSize: boolean) => void;

  toolStabilizer: number;
  setToolStabilizer: (toolStabilizer: number) => void;
}

export interface ShapeSettings {
  type: 'line' | 'ellipse' | 'rectangle';
  setType: (type: 'line' | 'ellipse' | 'rectangle') => void;

  size: number;
  setSize: (size: number) => void;

  opacity: number;
  setOpacity: (opacity: number) => void;

  fill: boolean;
  setFill: (fill: boolean) => void;
}

export interface Symmetry {
  enabled: boolean;
  allowCrossingAxis: boolean;
  origin: [number, number];
  rotation: number;
}

type SelectionTool =
  | WorkbenchStudioTool.BezierSelection
  | WorkbenchStudioTool.Lasso
  | WorkbenchStudioTool.BrushSelection
  | WorkbenchStudioTool.AutoSelection;

export interface SelectionSettings {
  operation: MaskOperation;
  setOperation: (operation: MaskOperation) => void;
  lastSelectionTool: SelectionTool;
  setlastSelectionTool: (tool: SelectionTool) => void;

  brushSettings: ToolSettings;
}

export interface WorkbenchStudioToolStateState {
  tool: WorkbenchStudioTool;
  setTool: (tool: WorkbenchStudioTool) => void;

  flipped: boolean;
  setFlipped: (flipped: boolean) => void;

  pickingColorOnCanvas: boolean;
  setPickingColorOnCanvas: (pickingColorOnCanvas: boolean) => void;

  color: string;
  setColor: (color: string) => void;

  resizing: boolean;
  setResizing: (resizing: boolean) => void;

  panning: boolean;
  setPanning: (panning: boolean) => void;

  rotating: boolean;
  setRotating: (rotating: boolean) => void;

  symmetry: Symmetry;
  setSymmetry: (symmetry: Symmetry) => void;

  brushSettings: ToolSettings;
  eraserSettings: ToolSettings;
  shapeSettings: ShapeSettings;
  selectionSettings: SelectionSettings;

  isPrompting: boolean;
  setIsPrompting: (isPrompoting: boolean) => void;

  getToolSettings: () => ToolSettings;
}

let storeResetFn = () => {};

export const resetStudioToolState = () => {
  storeResetFn();
};

const create = (<T extends WorkbenchStudioToolStateState>() => {
  return (stateCreator: StateCreator<T>) => {
    const store = _create(stateCreator);
    const initialState = store.getState();
    storeResetFn = () => {
      store.setState(initialState, true);
    };
    return store;
  };
}) as typeof _create;

export const useWorkbenchStudioToolState =
  create<WorkbenchStudioToolStateState>()((set, get) => ({
    tool: WorkbenchStudioTool.Brush,
    setTool: (tool) => {
      if (isSelectionTool(tool)) {
        if (!isSelectionTool(get().tool)) {
          set((state) => ({
            tool: tool,
            selectionSettings: {
              ...state.selectionSettings,
              lastSelectionTool: tool as SelectionTool,
              operation: MaskOperation.Replace,
            },
          }));
        } else {
          set((state) => ({
            tool: tool,
            selectionSettings: {
              ...state.selectionSettings,
              lastSelectionTool: tool as SelectionTool,
            },
          }));
        }
      } else {
        set({ tool });
      }
    },

    flipped: false,
    setFlipped: (flipped) => set({ flipped }),

    color: '#000000',
    setColor: (color) => set({ color }),

    pickingColorOnCanvas: false,
    setPickingColorOnCanvas: (pickingColorOnCanvas: boolean) =>
      set({ pickingColorOnCanvas }),

    resizing: false,
    setResizing: (resizing) => set({ resizing }),

    panning: false,
    setPanning: (panning) => set({ panning }),

    rotating: false,
    setRotating: (rotating) => set({ rotating }),

    getToolSettings: () => {
      const state = get();
      if (isSelectionTool(state.tool)) {
        return state.selectionSettings.brushSettings;
      } else if (state.tool === WorkbenchStudioTool.Eraser) {
        return state.eraserSettings;
      } else {
        return state.brushSettings;
      }
    },

    isPrompting: false,
    setIsPrompting: (isPrompting: boolean) => set({ isPrompting }),

    symmetry: {
      enabled: false,
      allowCrossingAxis: true,
      origin: [0.5, 0.5],
      rotation: 0,
    },
    setSymmetry: (symmetry: Symmetry) => set({ symmetry }),

    brushSettings: {
      toolSize: 4,
      setToolSize: (toolSize) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolSize },
        })),

      toolOpacity: 1,
      setToolOpacity: (toolOpacity) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolOpacity },
        })),

      toolAngle: 0,
      setToolAngle: (toolAngle) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolAngle },
        })),

      toolAspect: 1,
      setToolAspect: (toolAspect) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolAspect },
        })),

      toolHardness: 100,
      setToolHardness: (toolHardness) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolHardness },
        })),

      toolPressureSize: true,
      setToolPressureSize: (toolPressureSize) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolPressureSize },
        })),

      toolStabilizer: 1,
      setToolStabilizer: (toolStabilizer) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolStabilizer },
        })),
    },

    eraserSettings: {
      toolSize: 10,
      setToolSize: (toolSize) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolSize },
        })),

      toolOpacity: 1,
      setToolOpacity: (toolOpacity) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolOpacity },
        })),

      toolAngle: 0,
      setToolAngle: (toolAngle) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolAngle },
        })),

      toolAspect: 1,
      setToolAspect: (toolAspect) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolAspect },
        })),

      toolHardness: 100,
      setToolHardness: (toolHardness) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolHardness },
        })),

      toolPressureSize: false,
      setToolPressureSize: (toolPressureSize) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolPressureSize },
        })),

      toolStabilizer: 1,
      setToolStabilizer: (toolStabilizer) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolStabilizer },
        })),
    },

    shapeSettings: {
      type: 'rectangle',
      setType: (type) =>
        set((state) => ({ shapeSettings: { ...state.shapeSettings, type } })),

      size: 8,
      setSize: (size) =>
        set((state) => ({ shapeSettings: { ...state.shapeSettings, size } })),

      opacity: 1,
      setOpacity: (opacity) =>
        set((state) => ({
          shapeSettings: { ...state.shapeSettings, opacity },
        })),

      fill: false,
      setFill: (fill) =>
        set((state) => ({ shapeSettings: { ...state.shapeSettings, fill } })),
    },

    selectionSettings: {
      operation: MaskOperation.Replace,
      setOperation: (operation) =>
        set((state) => ({
          selectionSettings: { ...state.selectionSettings, operation },
        })),

      lastSelectionTool: WorkbenchStudioTool.Lasso,
      setlastSelectionTool: (tool: SelectionTool) =>
        set((state) => ({
          selectionSettings: {
            ...state.selectionSettings,
            lastSelectionTool: tool,
          },
        })),

      brushSettings: {
        toolSize: 40,
        setToolSize: (toolSize) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolSize,
              },
            },
          })),

        toolOpacity: 1,
        setToolOpacity: (toolOpacity) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolOpacity,
              },
            },
          })),

        toolAngle: 0,
        setToolAngle: (toolAngle) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolAngle,
              },
            },
          })),

        toolAspect: 1,
        setToolAspect: (toolAspect) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolAspect,
              },
            },
          })),

        toolHardness: 100,
        setToolHardness: (toolHardness) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolHardness,
              },
            },
          })),

        toolPressureSize: false,
        setToolPressureSize: (toolPressureSize) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolPressureSize,
              },
            },
          })),

        toolStabilizer: 1,
        setToolStabilizer: (toolStabilizer) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolStabilizer,
              },
            },
          })),
      },
    },
  }));
