import {
  ContextMenuItem,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  handleCopyDrawingImage,
  handleCopyLayersToWorkbench,
} from '../../../lib/drawingUtils';
import { ContextMenuItemsProps } from '../WorkbenchContextMenuByElementType';
import {
  useCreateDrawingStyleReference,
  useDeleteStyleReference,
  useUpdateWorkbench,
  useWorkbenchStyleReferences,
} from '@vizcom/shared/data-access/graphql';
import { useThree } from '@react-three/fiber';
import { ClientSideWorkbenchElementDrawing } from '../../../lib/clientState';

export const DrawingContextMenuItems = ({
  element,
  workbenchId,
  handleAction,
}: Pick<ContextMenuItemsProps, 'workbenchId' | 'handleAction'> & {
  element: ClientSideWorkbenchElementDrawing;
}) => {
  const scene = useThree((state) => state.scene);
  const [, updateWorkbench] = useUpdateWorkbench();
  const [createStyleReferenceRef, createStyleReference] =
    useCreateDrawingStyleReference();
  const [deleteStyleReferenceRef, deleteStyleReference] =
    useDeleteStyleReference();
  const styleReferences = useWorkbenchStyleReferences(workbenchId);

  const existingStyleReferences =
    !!element.thumbnailPath &&
    styleReferences.data?.nodes.find(
      (styleReference) => styleReference.imagePath === element.thumbnailPath
    );

  const handleSetAsWorkbenchThumbnail = async (
    element: ClientSideWorkbenchElementDrawing | null
  ) => {
    const drawingId = element?.id || null;
    updateWorkbench({
      id: workbenchId,
      patch: {
        thumbnailDrawingId: drawingId,
      },
    });
  };

  const handleAddToReferenceImages = async () => {
    if (element.thumbnailPath instanceof ImageData) {
      return; // we need to wait for the image to be uploaded first, let the user try again
    }
    const res = await createStyleReference({
      input: {
        styleReference: {
          workbenchId,
          imageName: element.name,
          imagePath: element.thumbnailPath,
        },
      },
    });

    if (res.error) {
      return addToast('Error while creating style reference.', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast(
      'Added to reference image library; it is only available to use in this file'
    );
  };

  const handleRemoveReferenceImage = async () => {
    if (!existingStyleReferences) {
      throw new Error('No existing style reference to delete found');
    }
    const res = await deleteStyleReference({
      id: existingStyleReferences.id,
    });

    if (res.error) {
      return addToast('Error while deleting style reference.', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Image removed from reference images library');
  };

  return (
    <>
      {element.thumbnailPath ? (
        <ContextMenuItem onClick={() => handleCopyDrawingImage(element)}>
          Copy raw image
        </ContextMenuItem>
      ) : null}
      {element.thumbnailPath && typeof element.thumbnailPath === 'string' ? (
        <ContextMenuItem
          disabled={
            createStyleReferenceRef.fetching || deleteStyleReferenceRef.fetching
          }
          onClick={
            existingStyleReferences
              ? handleRemoveReferenceImage
              : handleAddToReferenceImages
          }
        >
          {existingStyleReferences
            ? 'Remove as reference image in 2D Studio'
            : 'Add as reference image in 2D Studio'}
        </ContextMenuItem>
      ) : null}
      <ContextMenuItem onClick={() => handleSetAsWorkbenchThumbnail(element)}>
        Set as thumbnail
      </ContextMenuItem>
      <ContextMenuItem onClick={() => handleSetAsWorkbenchThumbnail(null)}>
        Restore default thumbnail
      </ContextMenuItem>
      <ContextMenuItem
        onClick={() =>
          handleCopyLayersToWorkbench(element, handleAction, scene)
        }
      >
        Copy all layers to workbench
      </ContextMenuItem>
    </>
  );
};
