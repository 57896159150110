import { useRef } from 'react';
import { Texture, Mesh } from 'three';
import { useCanDrawWithFinger } from '@vizcom/shared-ui-components';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import {
  WorkbenchStudioTool,
  useWorkbenchStudioToolState,
} from '../../studioState';
import { useBrushSegments } from '../../lib/useBrushSegments';
import { useBrushStroke } from './useBrushStroke';
import {
  BrushTextureRenderer,
  BrushTextureRendererRef,
} from './BrushTextureRenderer';
import { EventMesh } from '../EventMesh';
import { LayerContent } from '../LayersCompositor/LayerContent';
import { useSubscribeToSelectionApi } from '../../selection/useSelectionApi';
import { BrushCursorPreview } from './BrushCursorPreview';

interface BrushRendererProps {
  drawingSize: [number, number];
  layer: Drawing2dStudio['layers']['nodes'][0];
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
}

export const BrushRenderer = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
}: BrushRendererProps) => {
  const { color, getToolSettings, symmetry, tool } =
    useWorkbenchStudioToolState();
  const toolSettings = getToolSettings();
  const eventPlaneRef = useRef<Mesh>(null!);
  const brushTextureRendererRef = useRef<BrushTextureRendererRef>(null!);
  const { canDrawWithFinger } = useCanDrawWithFinger();
  const brushStroke = useBrushStroke({
    drawingSize,
    color,
    symmetry,
    toolSettings,
  });

  const layerFill =
    layer.fill && layer.fill.match(/^#([0-9a-f]{6})$/i)
      ? layer.fill
      : undefined;

  const resetBrushStrokeOnNextRenderRef = useRef(false);
  if (resetBrushStrokeOnNextRenderRef.current) {
    brushStroke.reset();
    resetBrushStrokeOnNextRenderRef.current = false;
  }

  const callback = () => {
    brushStroke.addSegments([], true);
    if (brushStroke.segmentsRef.current.length === 0) {
      // nothing was painted, nothing to update
      return;
    }
    handleAction({
      type: 'updateLayer',
      id: layer.id,
      data: {
        image: brushTextureRendererRef.current.exportTexture(),
      },
    });
    // only reset the brush stroke on next render, when we'll get the updated texture of the layer from `layerImage`
    // this prevents a flash of the old texture
    resetBrushStrokeOnNextRenderRef.current = true;
  };

  const { bind } = useBrushSegments({
    drawingSize,
    eventPlaneRef,
    onNewSegments: (event, segments) => {
      brushStroke.addSegments(segments, false);
    },
    onStrokeEnd: callback,
    preventFingerInteractions: !canDrawWithFinger,
  });

  const selectionTexture = useSubscribeToSelectionApi(
    (state) => state.texture_treatEmptyMaskAsFull
  );

  return (
    <>
      <BrushCursorPreview
        drawingSize={drawingSize}
        toolSize={toolSettings.toolSize}
        toolAspect={toolSettings.toolAspect}
        toolAngle={toolSettings.toolAngle}
        color={tool === WorkbenchStudioTool.Eraser ? '#000000' : color}
      />

      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
      >
        <BrushTextureRenderer
          size={drawingSize}
          layerFill={layerFill}
          layerTexture={layerImage}
          brushTexture={brushStroke.getTexture()}
          maskTexture={selectionTexture}
          brushOpacity={toolSettings.toolOpacity}
          ref={brushTextureRendererRef}
          isEraser={tool === WorkbenchStudioTool.Eraser}
        />
      </LayerContent>
      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={bind() as any}
        ref={eventPlaneRef}
      />
    </>
  );
};
