import { Line } from '@react-three/drei';
import { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { Vector3 } from 'three';
import { Line2, LineSegments2 } from 'three-stdlib';

export const ArrowTip = forwardRef<Line2 | LineSegments2>((props, ref) => {
  const theme = useTheme();

  return (
    <Line
      ref={ref}
      color={theme.primary.default}
      scale={[5, 5, 1]}
      lineWidth={2}
      points={[
        new Vector3(-1, -1, 0),
        new Vector3(0, 0, 0),
        new Vector3(-1, 1, 0),
      ]}
      renderOrder={-100}
      depthTest={false}
      depthWrite={false}
      transparent
    />
  );
});
