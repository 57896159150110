import { v4 as uuidv4 } from 'uuid';
import {
  addToast,
  applyMaskToImage,
  canvasToBlob,
  downloadFile,
  imageToBlob,
  imageToCanvas,
} from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from './clientState';
import { drawingById, urqlClient } from '@vizcom/shared/data-access/graphql';
import { useWorkbenchSyncedState } from './useWorkbenchSyncedState';
import { masonryLayoutBestFit } from '../components/utils/layoutHelpers';
import { findFirstFreeSlotInScene } from '../components/utils/freeSlotFinders';
import { getElementSize } from '../components/helpers';
import { GENERATED_IMAGES_MARGIN } from './utils';
import { Object3D } from 'three';
import * as Sentry from '@sentry/react';

export const handleCopyLayersToWorkbench = async (
  element: ClientSideWorkbenchElementData,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  scene: Object3D
) => {
  if (element.__typename !== 'Drawing') return;
  const { data } = await urqlClient.query(drawingById, {
    id: element.id,
  });
  const layers = data?.drawing?.layers.nodes ?? [];

  if (!layers.length) return;

  const columns = layers.length > 8 ? 4 : 2;

  const drawingSize = getElementSize(element);

  // create a new drawing for each layer
  const newElements = layers
    .map((layer) => {
      return {
        layers: { nodes: [{ ...layer, id: uuidv4() }] },
        thumbnailPath: layer.imagePath,
        id: uuidv4(),
        x: 0,
        y: 0,
        zIndex: 0,
        __typename: 'Drawing' as const,
        workbenchSizeRatio: element.workbenchSizeRatio,
        drawingHeight: element.drawingHeight,
        drawingWidth: element.drawingWidth,
        name: '',
        updatedAt: '',
      };
    })
    .filter((el) => el.thumbnailPath);

  if (!newElements.length) return;

  const rows = Math.ceil(newElements.length / columns);
  const initialPosition = findFirstFreeSlotInScene(scene, {
    firstSlotX: element.x,
    firstSlotY: element.y,
    slotWidth: (columns / 2) * (drawingSize.width + GENERATED_IMAGES_MARGIN),
    slotHeight: rows * (drawingSize.height + GENERATED_IMAGES_MARGIN),
    maxElementPerLine: 4,
  });
  // position in a grid next to the drawing
  const positions = masonryLayoutBestFit(
    newElements.map(({ id }) => ({
      width: drawingSize.width,
      height: drawingSize.height,
      id,
      x: initialPosition[0],
      y: initialPosition[1],
    })),
    columns
  );

  handleAction({
    type: 'createElements',
    newElements: newElements.map((el, index) => {
      const { x, y } = positions[index];
      return {
        ...el,
        x,
        y,
        zIndex: element.zIndex + index + 1,
      };
    }),
  });

  return;
};

export const handleCopyDrawingImage = async (
  element: ClientSideWorkbenchElementData
) => {
  if (element.__typename !== 'Drawing') return;
  if (!element.thumbnailPath) return;

  return copyImageToClipboard(element.thumbnailPath);
};

export const copyImageToClipboard = async (
  imageData: ImageData | Blob | string | null | undefined,
  maskData?: ImageData
) => {
  if (!imageData) {
    return;
  }

  // Function to create the blob - this is async but not in a Promise constructor
  const createBlob = async (): Promise<Blob> => {
    let blob: Blob;
    if (maskData) {
      // If a mask is provided, we need to apply it to the image
      const { ctx, canvas } = await imageToCanvas(imageData);
      const maskedImageData = ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      applyMaskToImage(maskedImageData.data, maskData.data);
      ctx.putImageData(maskedImageData, 0, 0);
      blob = await canvasToBlob(canvas, 'image/png');
    } else if (imageData instanceof Blob) {
      blob = imageData;
    } else {
      blob = await imageToBlob(imageData);
    }

    // Ensure the blob has the correct MIME type
    if (blob.type !== 'image/png') {
      const arrayBuffer = await blob.arrayBuffer();
      blob = new Blob([arrayBuffer], { type: 'image/png' });
    }

    return blob;
  };

  try {
    if (navigator.clipboard && 'write' in navigator.clipboard) {
      // Create a ClipboardItem with the blob promise
      // This is key for Safari compatibility - it allows Safari to handle the async operation
      const item = new ClipboardItem({
        'image/png': createBlob(), // Note: This is a Promise, not a Blob
      });

      // Write the ClipboardItem to the clipboard
      // This works in Safari because the async operation is handled by the browser
      await navigator.clipboard.write([item]);
      addToast('Image copied to clipboard');
    } else {
      throw new Error('Clipboard API not supported');
    }
  } catch (error) {
    console.error('Failed to copy image to clipboard:', error);
    Sentry.captureException(error);
    // Fallback mechanism for when clipboard operations fail
    try {
      // Provide user with a download option instead
      addToast(
        'Unable to copy to clipboard. Click here to download the image instead.',
        {
          type: 'default',
          ctaText: 'Download image',
          ctaAction: async () => {
            const blob = await createBlob();
            downloadFile(blob, 'image', 'png');
          },
        }
      );
    } catch (fallbackError) {
      Sentry.captureException(fallbackError);
      throw fallbackError;
    }
  }
};
