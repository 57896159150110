import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  TargetFolderSelector,
  addToast,
  formatErrorMessage,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import {
  publishTrackingEvent,
  useUpdateWorkbenchesAndFolders,
} from '@vizcom/shared/data-access/graphql';
import { Dispatch, SetStateAction, useState } from 'react';
import * as Sentry from '@sentry/react';
import { SelectedItem } from '../../Folder/SelectedItemsProvider';
import { useNavigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import { FolderEventName, WorkbenchEventName } from '@vizcom/shared/data-shape';

type MoveModalMultipleProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedItems: SelectedItem[];
  onComplete: () => void;
};

export const MoveMultipleModal = (props: MoveModalMultipleProps) => {
  const { isOpen, setIsOpen, selectedItems, onComplete } = props;
  const navigate = useNavigate();
  const [moveWorkbenchesAndFoldersRes, moveWorkbenchesAndFolders] =
    useUpdateWorkbenchesAndFolders();
  const selectedOrganization = useSelectedOrganization();
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const loading = moveWorkbenchesAndFoldersRes.fetching;

  const handleMoveClick = async () => {
    if (!selectedFolderId) {
      return;
    }
    const workbenchIds = selectedItems
      .filter(({ type }) => type === 'workbench')
      .map(({ id }) => id);
    const folderIds = selectedItems
      .filter(({ type }) => type === 'folder')
      .map(({ id }) => id);
    const workbenchRes = await moveWorkbenchesAndFolders({
      updateWorkbenchesInput: {
        ids: workbenchIds,
        patch: {
          folderId: selectedFolderId,
        },
      },
      updateFoldersInput: {
        ids: folderIds,
        patch: {
          parentId: selectedFolderId,
        },
      },
    });
    if (workbenchRes.error) {
      addToast('Error while moving files & folders', {
        type: 'danger',
        secondaryText: formatErrorMessage(workbenchRes.error),
      });

      Sentry.captureException(workbenchRes.error);
      return;
    }

    trackEvent('Workbenches And Folders Moved', {
      fileIds: workbenchIds,
      folderIds,
    });

    if (workbenchIds.length > 0) {
      publishTrackingEvent({
        type: WorkbenchEventName.WORKBENCH_MOVED,
        data: {
          workbenchIds,
        },
      });
    }

    if (folderIds.length > 0) {
      publishTrackingEvent({
        type: FolderEventName.FOLDER_MOVED,
        data: {
          folderIds,
        },
      });
    }

    if (selectedFolderId) {
      navigate(paths.files.folder(selectedFolderId));
    }

    setIsOpen(false);

    onComplete();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  if (!selectedOrganization.data) {
    return null;
  }

  return (
    <Modal
      style={{ minWidth: 430 }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
    >
      <ModalHeader>
        <ModalTitle>Move ({selectedItems.length}) files and folders</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>

      <ModalContent style={{ marginTop: 0 }}>
        <TargetFolderSelector
          organizationId={selectedOrganization.data.id}
          selectedFolderId={selectedFolderId}
          setSelectedFolderId={setSelectedFolderId}
        />
      </ModalContent>

      <ModalActions>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleMoveClick}
          disabled={!selectedFolderId || loading}
        >
          Move
        </Button>
      </ModalActions>
    </Modal>
  );
};
