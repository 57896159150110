import { useMemo } from 'react';
import { debounceAsyncFunction } from '@vizcom/shared/js-utils';
import { RootState } from '@react-three/fiber';
import {
  UpdateCompositeSceneMutation,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { ACESFilmicToneMapping, PerspectiveCamera, WebGLRenderer } from 'three';
import { memoize } from 'lodash';
import { hideVizcomUiObjects } from '../components/compositeScene/compositeSceneEditor/utils/hideVizcomUiObjects';

const THUMBNAIL_SIZE = [960, 540];

const getRenderer = memoize(() => {
  const renderer = new WebGLRenderer({
    preserveDrawingBuffer: true,
    alpha: true,
  });
  renderer.setSize(THUMBNAIL_SIZE[0], THUMBNAIL_SIZE[1]);
  renderer.toneMapping = ACESFilmicToneMapping;
  return renderer;
});

export const useRegisterUpdateCompositeSceneThumbnail = (
  compositeSceneId: string,
  threeStateRef: React.MutableRefObject<RootState | null>
) => {
  return useMemo(
    () =>
      debounceAsyncFunction(async () => {
        if (!threeStateRef.current) {
          return;
        }

        if (threeStateRef.current.scene.children.length === 0) {
          // Scene has already been destroyed, nothing to do
          return;
        }
        const hiddenObjects = hideVizcomUiObjects(threeStateRef.current.scene);

        // Setting the aspect ratio of the camera to match the thumbnail aspect ratio
        const previousAspect = (
          threeStateRef.current.camera as PerspectiveCamera
        ).aspect;
        (threeStateRef.current.camera as PerspectiveCamera).aspect =
          THUMBNAIL_SIZE[0] / THUMBNAIL_SIZE[1];
        threeStateRef.current.camera.updateProjectionMatrix();

        getRenderer().render(
          threeStateRef.current.scene,
          threeStateRef.current.camera
        );

        // Restoring camera aspect ratio
        (threeStateRef.current.camera as PerspectiveCamera).aspect =
          previousAspect;
        threeStateRef.current.camera.updateProjectionMatrix();

        for (const element of hiddenObjects) {
          element.visible = true;
        }

        const blob = await new Promise<Blob>((resolve, reject) => {
          getRenderer().domElement.toBlob(
            (blob) => {
              if (!blob) {
                reject('No blob generated');
                return;
              }
              resolve(blob);
            },
            'png',
            50
          );
        });

        await urqlClient.mutation(UpdateCompositeSceneMutation, {
          input: {
            id: compositeSceneId,
            patch: {
              thumbnailPath: blob,
            },
          },
        });
      }, 2000),
    [compositeSceneId, threeStateRef.current]
  );
};
