import {
  Modal,
  Tabs,
  Tab,
  TextInput,
  Dropdown,
  MagnifyingGlassIcon,
} from '@vizcom/shared-ui-components';
import { Hotkey, Hotkey as HotkeyType } from './types';
import { styled } from '@vizcom/web/common/theme';
import { useShortcuts } from './hooks';
import { KeysRow } from './components';
import { useState, useEffect } from 'react';

export const ShortcutList = (props: { shortcuts: HotkeyType[] }) => {
  const [currentHoveredHotkey, setCurrentHoveredHotkey] =
    useState<Hotkey | null>(null);

  return (
    <>
      <List>
        {props.shortcuts.map((hotkey) => (
          <HotkeyRow
            key={hotkey.id}
            hotkey={hotkey}
            onMouseEnter={() => setCurrentHoveredHotkey(hotkey)}
            onMouseLeave={() => setCurrentHoveredHotkey(null)}
          />
        ))}
      </List>
      {currentHoveredHotkey?.hoverPreview && (
        <HotkeyPreview>
          <currentHoveredHotkey.hoverPreview />
        </HotkeyPreview>
      )}
    </>
  );
};

export const HotkeyRow = ({
  hotkey,
  onMouseEnter,
  onMouseLeave,
}: {
  hotkey: HotkeyType;
  onMouseEnter: (value: HotkeyType) => void;
  onMouseLeave: () => void;
}) => {
  return (
    <HotkeyRowContainer
      key={hotkey.id}
      onMouseOver={() => onMouseEnter(hotkey)}
      onMouseLeave={onMouseLeave}
    >
      <Label>{hotkey.description}</Label>
      <KeysRow>{hotkey.triggerPreview}</KeysRow>
    </HotkeyRowContainer>
  );
};

const SearchRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* 0.5rem */
`;

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const MagnifyingGlassIconStyled = styled(MagnifyingGlassIcon)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const SearchInput = styled(TextInput)`
  background: ${({ theme }) => theme.surface.e2};
  height: 38.4px;
  padding-left: 30px;
`;

export const SearchShortcuts = (props: {
  onNewSearchQuery: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <SearchRow>
      <SearchInputContainer>
        <MagnifyingGlassIconStyled />
        <SearchInput
          placeholder={'Search shortcuts'}
          value={props.searchQuery}
          onChange={props.onNewSearchQuery}
          onKeyDown={handleKeyDown}
        />
      </SearchInputContainer>
      <Dropdown
        options={[
          {
            data: {
              name: 'Mouse',
              value: 'mouse',
            },
          },
        ]}
        value={'mouse'}
        setValue={(v) => console.log(v)}
        optionToValueMapper={({ value }: { value: string }) => value}
        OptionComponent={({ option: { name } }) => <div>{name}</div>}
        disabled
      >
        Mouse
      </Dropdown>
    </SearchRow>
  );
};

type DrawingStudioHotkeysModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  defaultTab?: 'Drawing' | 'CompositeScene' | 'Workbench';
};

export const DrawingStudioHotkeysModal: React.FC<
  DrawingStudioHotkeysModalProps
> = ({ isOpen, setIsOpen, defaultTab = 'Drawing' }) => {
  const shortcuts = useShortcuts();

  // State to manage the active tab
  const [activeTab, setActiveTab] = useState<string | null>(
    // Initialize the active tab based on the defaultTab prop
    defaultTab === 'Drawing' ? '.$2dStudio' : '.$general'
  );

  useEffect(() => {
    // Update the active tab when the defaultTab prop changes
    const tab = defaultTab === 'Drawing' ? '.$2dStudio' : '.$general';
    setActiveTab(tab);
  }, [defaultTab]);

  return (
    <Modal
      style={{
        maxHeight: '50vh',
        height: 473,
        width: 548,
        overflowY: 'hidden',
        display: 'flex',
      }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Container>
        <Header>Keyboard shortcuts</Header>
        <SearchShortcuts
          onNewSearchQuery={shortcuts.search.onNewSearchQuery}
          searchQuery={shortcuts.search.searchQuery}
        />
        {activeTab && (
          <StyledTabs
            activeKey={activeTab}
            onChange={(key) => {
              setActiveTab(key as string);
            }}
          >
            <Tab key="general" title="General">
              <ShortcutList shortcuts={shortcuts.getCategory('general')} />
            </Tab>
            <Tab key="workbench" title="Workbench">
              <ShortcutList shortcuts={shortcuts.getCategory('workbench')} />
            </Tab>
            <Tab key="2dStudio" title="2D Studio">
              <ShortcutList shortcuts={shortcuts.getCategory('2dStudio')} />
            </Tab>
          </StyledTabs>
        )}
      </Container>
    </Modal>
  );
};

const StyledTabs = styled(Tabs)`
  flex: 1;
  overflow-y: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  max-height: calc(100vh - 200px);
`;

const HotkeyPreview = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  max-height: 50vh;
  padding-bottom: 32px;
`;

const HotkeyRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.subtle};
  transition: all 0.1s;
  &:hover {
    background: ${({ theme }) => theme.surface.e2};
  }
`;

const Label = styled.div`
  color: ${({ theme }) => theme.text.default};
`;

const Header = styled.div`
  color: ${({ theme }) => theme.text.default};
  font-size: 14px;
  font-weight: 600;
  padding: 0 0;
`;
