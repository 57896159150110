import { CloseIcon, LineIcon, Text } from '@vizcom/shared-ui-components';
import { useWorkbenchStudioToolState } from '../studioState';
import styled from 'styled-components';

export const SymmetrySettings = () => {
  const { symmetry, setSymmetry } = useWorkbenchStudioToolState();

  return (
    <>
      <Axis
        $active={!symmetry.enabled}
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: false,
          });
        }}
      >
        <CloseIcon />
        <Text>None</Text>
      </Axis>
      <Axis
        $active={
          symmetry.enabled && Math.abs(symmetry.rotation % Math.PI) === 0
        }
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: true,
            origin: [0.5, 0.5],
            rotation: 0,
          });
        }}
      >
        <VerticalAxis />
        <div>Vertical</div>
      </Axis>
      <Axis
        $active={
          symmetry.enabled &&
          Math.abs(symmetry.rotation % Math.PI) === Math.PI * 0.5
        }
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: true,
            origin: [0.5, 0.5],
            rotation: Math.PI * -0.5,
          });
        }}
      >
        <HorizontalAxis />
        <div>Horizontal</div>
      </Axis>

      {symmetry.enabled && (
        <div>
          <CheckboxLabel>
            <Checkbox
              $checked={symmetry.allowCrossingAxis}
              onClick={() =>
                setSymmetry({
                  ...symmetry,
                  allowCrossingAxis: !symmetry.allowCrossingAxis,
                })
              }
            />
            <Text>
              Allow crossing <br /> symmetry axis
            </Text>
          </CheckboxLabel>
        </div>
      )}
    </>
  );
};

const HorizontalAxis = styled(LineIcon)`
  transform: rotate(45deg);
`;

const VerticalAxis = styled(LineIcon)`
  transform: rotate(-45deg);
`;

const Axis = styled.button<{ $active?: boolean }>`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ $active, theme }) =>
    $active ? theme.text.default : theme.text.info};
  &:disabled {
    color: ${({ theme }) => theme.text.disabled};
    cursor: default;
  }
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0.75rem 12px;
  gap: 12px;
  text-align: left;
`;

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text.info};
  font-weight: 600;
  gap: 12px;
  margin-top: 6px;
`;

const Checkbox = styled.div<{ $checked: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid
    ${({ $checked, theme }) =>
      $checked ? theme.primary.default : theme.secondary.disabled};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.text.default};
  ::after {
    content: '${({ $checked }) => ($checked ? '✔' : '')}';
  }
`;
