import { useStore, useThree } from '@react-three/fiber';
import { useLayoutEffect, useRef } from 'react';
import { TransitionStatus } from 'react-transition-group';
import { OrthographicCamera } from 'three';
import { CameraLimits } from '../../utils/mapControls/utils';
import { useLastValue } from '@vizcom/shared-ui-components';
import { useMapControls } from '../../utils/mapControls/utils';

// This camera is used to isolate 2D studio from the rest of the workbench
// by using this camera, we make sure the drawing element is always centered and its size doesn't depends on workbenchSizeRatio
// This simplifies a lot of logic in 2D studio, and make sure 2D studio isn't affected by the drawing being moved or resized by another user
// This camera is only the default when entering / entered in 2D studio mode, else the normal workbench camera is used
export const WorkbenchStudioCamera = ({
  state,
  cameraLimits,
  drawingPosition,
  drawingWorkbenchSizeRatio,
  offsetX,
}: {
  state: TransitionStatus;
  cameraLimits: CameraLimits;
  drawingPosition: [number, number];
  drawingWorkbenchSizeRatio: number;
  offsetX: number;
}) => {
  const store = useStore();
  const controls = useMapControls();
  const set = useThree(({ set }) => set);
  const size = useThree(({ size }) => size);
  const workbenchCameraRef = useRef<OrthographicCamera>(null!);
  if (!workbenchCameraRef.current) {
    workbenchCameraRef.current = store.getState().camera as OrthographicCamera;
  }

  const studioCameraRef = useRef<OrthographicCamera>(null!);
  if (!studioCameraRef.current) {
    studioCameraRef.current = new OrthographicCamera(
      size.width / -2,
      size.width / 2,
      size.height / 2,
      size.height / -2,
      workbenchCameraRef.current.near,
      workbenchCameraRef.current.far
    );
    studioCameraRef.current.position.z = workbenchCameraRef.current.position.z;
    if (workbenchCameraRef.current.userData.vizcomCameraWasInitialized) {
      // Start from the same position as the workbench camera, then animate to the studio camera position in the `useLayoutEffect` below
      // but only if the camera was initialized (not navigating directly to 2D studio)
      studioCameraRef.current.zoom =
        workbenchCameraRef.current.zoom * drawingWorkbenchSizeRatio;
      studioCameraRef.current.position.x =
        (workbenchCameraRef.current.position.x - drawingPosition[0]) /
        drawingWorkbenchSizeRatio;
      studioCameraRef.current.position.y =
        (workbenchCameraRef.current.position.y - drawingPosition[1]) /
        drawingWorkbenchSizeRatio;
    } else {
      const zoomForWidth =
        (studioCameraRef.current.right - studioCameraRef.current.left) /
        (cameraLimits.xMax - cameraLimits.xMin);
      const zoomForHeight =
        (studioCameraRef.current.top - studioCameraRef.current.bottom) /
        (cameraLimits.yMax - cameraLimits.yMin);
      studioCameraRef.current.zoom = Math.min(zoomForWidth, zoomForHeight);
    }
  }

  useLayoutEffect(() => {
    studioCameraRef.current.left = size.width / -2;
    studioCameraRef.current.right = size.width / 2;
    studioCameraRef.current.top = size.height / 2;
    studioCameraRef.current.bottom = size.height / -2;
    studioCameraRef.current.updateProjectionMatrix();
    // When the workbench camera is unmounted, it will not be updated
    // so we need to manually update it when the window gets resized
    // to keep the care up-to-date when the user resize the window while in studio mode
    workbenchCameraRef.current.left = size.width / -2;
    workbenchCameraRef.current.right = size.width / 2;
    workbenchCameraRef.current.top = size.height / 2;
    workbenchCameraRef.current.bottom = size.height / -2;
    workbenchCameraRef.current.updateProjectionMatrix();
  }, [size]);

  const shouldBeDefault = state === 'entering' || state === 'entered';

  const lastDrawingPosition = useLastValue(drawingPosition);
  const lastDrawingWorkbenchSizeRatio = useLastValue(drawingWorkbenchSizeRatio);
  useLayoutEffect(() => {
    if (shouldBeDefault) {
      set(() => ({
        camera: studioCameraRef.current!,
      }));
      const zoomForWidth =
        (studioCameraRef.current.right - studioCameraRef.current.left) /
        (cameraLimits.xMax - cameraLimits.xMin);
      const zoomForHeight =
        (studioCameraRef.current.top - studioCameraRef.current.bottom) /
        (cameraLimits.yMax - cameraLimits.yMin);
      const zoomTarget = Math.min(zoomForWidth, zoomForHeight);
      controls.moveTo({
        x: offsetX ? offsetX / zoomTarget : 0,
        y: 0,
        zoom: zoomTarget,
        controlled: true,
      });

      return () => {
        const workbenchCameraPosition = {
          x: workbenchCameraRef.current.position.x,
          y: workbenchCameraRef.current.position.y,
          zoom: workbenchCameraRef.current.zoom,
        };

        if (!workbenchCameraRef.current.userData.vizcomCameraWasInitialized) {
          // workbench camera was never initialized from the position of elements in the workbench
          // in this case, just unzoom the workbench camera position from the current drawing instead of using the last position of the workbench camera
          workbenchCameraPosition.x = lastDrawingPosition.current[0];
          workbenchCameraPosition.y = lastDrawingPosition.current[1];

          workbenchCameraRef.current.userData.vizcomCameraWasInitialized = true;
        }

        // move workbench camera to the drawing position to let animate back when exiting 2D studio mode
        workbenchCameraRef.current.position.x =
          lastDrawingPosition.current[0] +
          studioCameraRef.current.position.x *
            lastDrawingWorkbenchSizeRatio.current;
        workbenchCameraRef.current.position.y =
          lastDrawingPosition.current[1] +
          studioCameraRef.current.position.y *
            lastDrawingWorkbenchSizeRatio.current;
        workbenchCameraRef.current.zoom =
          zoomTarget / lastDrawingWorkbenchSizeRatio.current;

        set(() => ({ camera: workbenchCameraRef.current }));
        controls.moveTo({
          x: workbenchCameraPosition.x,
          y: workbenchCameraPosition.y,
          zoom: workbenchCameraPosition.zoom,
          controlled: true,
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldBeDefault,
    set,
    lastDrawingPosition,
    lastDrawingWorkbenchSizeRatio,
    offsetX,
    JSON.stringify(cameraLimits),
  ]);

  return <primitive object={studioCameraRef.current} />;
};
