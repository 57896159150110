import { useParams, useSearchParams } from 'react-router-dom';
import { MobileWorkbenchActionErrorPage } from './ErrorActionPage';
import { useWorkbenchContent } from '@vizcom/shared/data-access/graphql';
import { WorkbenchLikeImageUploader } from './WorkbenchLikeImageUploader';

export const MobileImageUpload = () => {
  const { workbenchId } = useParams();
  const [searchParams] = useSearchParams();
  const { data: workbench, error: workbenchError } =
    useWorkbenchContent(workbenchId);

  // Parse and validate zRange
  const zParam = searchParams.get('z');
  const zValue = zParam ? Number(zParam) : Infinity;

  // Parse and validate targetPosition
  const targetPositionParam = searchParams.get('target');
  const targetPosition = targetPositionParam
    ? targetPositionParam.split(',').map(Number)
    : null;
  const isValidTargetPosition =
    targetPosition &&
    targetPosition.length === 2 &&
    targetPosition.every((n) => !isNaN(n));

  // Error handling
  if (!workbenchId || !isValidTargetPosition || workbenchError) {
    const errorMessage =
      'Invalid parameters: ' +
      (!workbenchId ? 'Missing workbench ID. ' : '') +
      (!isValidTargetPosition ? 'Invalid target position. ' : '') +
      (workbenchError ? 'Error fetching workbench content. ' : '');

    return (
      <MobileWorkbenchActionErrorPage
        title="Unable to Upload Image"
        description="We encountered an issue while trying to set up the image upload. This could be due to invalid parameters or a temporary system error. Please try again, and if the problem persists, contact our support team for assistance."
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <WorkbenchLikeImageUploader
      workbenchId={workbenchId}
      workbenchName={workbench?.name ?? 'Untitled workbench'}
      zValue={zValue as number}
      targetPosition={targetPosition as [number, number]}
    />
  );
};
