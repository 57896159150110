import {
  LeftAlign,
  CenterAlign,
  RightAlign,
} from '@vizcom/shared-ui-components';
import { ExtraPopup, Field, Input, InputOption } from '../../style';
import { useState } from 'react';
import { useTheme } from 'styled-components';

const alignmentToIcon = {
  left: <LeftAlign />,
  center: <CenterAlign />,
  right: <RightAlign />,
};

type WorkbenchElementTextAlignment = {
  textAlign: 'left' | 'center' | 'right';
  handleSetTextAlign: (textAlign: 'left' | 'center' | 'right') => void;
};

export const WorkbenchElementTextAlignment = ({
  textAlign,
  handleSetTextAlign,
}: WorkbenchElementTextAlignment) => {
  const [isSelectingAlignment, setIsSelectingAlignment] = useState(false);
  const theme = useTheme();

  return (
    <Field>
      <InputOption
        onClick={() => setIsSelectingAlignment(!isSelectingAlignment)}
        style={{ color: theme.icon.default }}
      >
        {alignmentToIcon[textAlign]}
      </InputOption>
      {isSelectingAlignment && (
        <ExtraPopup $top={-45} $right={14}>
          <Input>
            <InputOption>
              <LeftAlign
                onClick={() => handleSetTextAlign('left')}
                style={{ color: theme.icon.default }}
              />
            </InputOption>
            <InputOption>
              <CenterAlign
                onClick={() => handleSetTextAlign('center')}
                style={{ color: theme.icon.default }}
              />
            </InputOption>
            <InputOption>
              <RightAlign
                onClick={() => handleSetTextAlign('right')}
                style={{ color: theme.icon.default }}
              />
            </InputOption>
          </Input>
        </ExtraPopup>
      )}
    </Field>
  );
};
