import { assertExists, filterExists } from '@vizcom/shared/js-utils';
import {
  SyncQueueAction,
  SyncQueueMeta,
  SyncedActionBasePayloadType,
  SyncedActionType,
} from './SyncedAction';
import type { SyncQueueSynchronizer } from './SyncQueueSynchronizer';
import produce from 'immer';
import { differenceWith, isEqual, takeRightWhile } from 'lodash';

export class SyncQueue<TState, TPayload extends SyncedActionBasePayloadType> {
  public history: SyncQueueAction<TPayload>[] = [];
  public redoHistory: SyncQueueAction<TPayload>[] = [];

  constructor(
    private syncQueueSynchronizer: SyncQueueSynchronizer,
    private actionTypes: readonly SyncedActionType<TState, any>[],
    private getState: () => TState,
    private rootObjectId: string,
    private name: string
  ) {}

  listen = (listener: () => void) => {
    return this.syncQueueSynchronizer.listen(listener);
  };

  // we memoize the queue here to make sure that we return the same array by reference if there's new actions
  // in the SyncQueueSynchronizer that are not related to this SyncQueue. In this case the listener will be called but
  // this.getQueue() will return the same value as previously which won't trigger a re-render from react
  private memoizedQueue: (
    | SyncQueueAction<TPayload>
    | ((state: TState) => void)
  )[] = [];
  // returns the list of actions that are related to this SyncQueue
  // getQueue can also return a function, this is an optimistic updater from another syncQueue action side effect
  getQueue = (): (SyncQueueAction<TPayload> | ((state: TState) => void))[] => {
    const currentQueue = this.syncQueueSynchronizer
      .getQueue()
      .map((item) => {
        if (item.syncQueue === this) {
          return item.action;
        }
        const sideEffectOptimisticUpdater = item.sideEffects?.find(
          (updater) => updater.syncQueueName === this.name
        );
        return sideEffectOptimisticUpdater?.optimisticUpdater;
      })
      .filter(filterExists);

    if (
      currentQueue.length !== this.memoizedQueue.length ||
      differenceWith(currentQueue, this.memoizedQueue, isEqual).length !== 0
    ) {
      this.memoizedQueue = currentQueue;
    }

    return this.memoizedQueue;
  };

  push(payload: TPayload, metaOveride: SyncQueueMeta = {}) {
    const actionType = this.actionTypes.find(
      ({ type }) => type === payload.type
    );
    assertExists(
      actionType,
      `Action type definition for "${payload.type}" is not defined`
    );

    const meta = actionType.metaConstructor?.(payload, this.getState());
    let action: SyncQueueAction<TPayload> = {
      payload,
      meta: {
        ...(meta || {}),
        ...metaOveride,
      },
    };

    const invertedPayload = actionType.undoConstructor?.(
      action,
      this.getState()
    );

    let canceledAction = null as null | SyncQueueAction<TPayload>;
    if (action.meta.debounceId) {
      canceledAction = this.syncQueueSynchronizer.cancel(
        action.meta.debounceId
      ) as null | SyncQueueAction<TPayload>;
      if (canceledAction) {
        action = (actionType.actionMerger || defaultActionMerger)(
          canceledAction,
          action
        );
      }
    }

    actionType.onAddedToQueue?.(action);

    this.syncQueueSynchronizer.push({
      action,
      syncQueue: this,
      sideEffects: actionType.sideEffects?.(action, this.rootObjectId),
    });

    if (!action.meta.redo && !action.meta.undo) {
      this.redoHistory = [];
    }

    if (
      invertedPayload &&
      (!canceledAction ||
        canceledAction.meta.keepCanceledDebounceActionInHistory) &&
      !action.meta.skipHistory
    ) {
      // can undo/redo this action and this action didn't replace another action that was queued and we canceled successfully
      if (action.meta.undo) {
        this.redoHistory = produce(this.redoHistory, (draft) => {
          draft.push({
            payload: invertedPayload,
            meta: {
              undo: false,
              redo: true,
              delay: undefined,
              undoGroupId: action.meta.undoGroupId,
            },
          });
        });
        // setting the id of the undo/redo action to undefined to make sure it's treated as a separate user interaction
        // and we don't try to debounce it, which would mess up the history state (merging two undo operations together if pressing ctrl+z too fast)
      } else {
        this.history = produce(this.history, (draft) => {
          draft.push({
            payload: invertedPayload,
            meta: {
              undo: true,
              delay: undefined,
              undoGroupId: action.meta.undoGroupId,
            },
          });
        });
      }
    }
  }

  async executeRemoteUpdater(action: SyncQueueAction<TPayload>) {
    const actionType = this.actionTypes.find(
      ({ type }) => type === action.payload.type
    );
    assertExists(
      actionType,
      `Action type definition for "${action.payload.type}" is not defined`
    );

    await actionType.remoteUpdater(action, this.rootObjectId);
  }

  //extract actions to undo
  undoAction() {
    const lastItem = this.history.at(-1);
    if (!lastItem) {
      return;
    }
    const groupId = lastItem.meta.undoGroupId;
    const actionsToUndo =
      groupId !== undefined
        ? takeRightWhile(
            this.history,
            (action) => action.meta.undoGroupId === groupId
          )
        : [lastItem];

    this.history = produce(this.history, (draft) => {
      return draft.slice(0, this.history.length - actionsToUndo.length);
    });

    for (let i = actionsToUndo.length - 1; i >= 0; i--) {
      const action = actionsToUndo[i];
      this.push(action.payload, action.meta);
    }
  }

  redoAction() {
    const lastItem = this.redoHistory.at(-1);
    if (!lastItem) {
      return;
    }
    const groupId = lastItem.meta.undoGroupId;
    const actionsToRedo =
      groupId !== undefined
        ? takeRightWhile(
            this.redoHistory,
            (action) => action.meta.undoGroupId === groupId
          )
        : [lastItem];

    //extract actions to redo
    this.redoHistory = produce(this.redoHistory, (draft) => {
      return draft.slice(0, this.redoHistory.length - actionsToRedo.length);
    });

    for (let i = actionsToRedo.length - 1; i >= 0; i--) {
      const action = actionsToRedo[i];
      this.push(action.payload, action.meta);
    }
  }

  filterHistory(callback: (action: SyncQueueAction<TPayload>) => boolean) {
    this.history = this.history.filter((action) => !callback(action));
    this.redoHistory = this.redoHistory.filter((action) => !callback(action));
  }
}

const defaultActionMerger = <TPayloadType extends SyncedActionBasePayloadType>(
  previousAction: SyncQueueAction<TPayloadType>,
  nextAction: SyncQueueAction<TPayloadType>
): SyncQueueAction<TPayloadType> => ({
  payload: {
    ...previousAction.payload,
    ...nextAction.payload,
  },
  meta: {
    ...previousAction.meta,
    ...nextAction.meta,
  },
});
