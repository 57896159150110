import { useRef, useState } from 'react';
import { ArrowIcon, Button } from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import { ExtraPopup } from '../style';
import { Palette, PaletteSelector } from '../../modals/PaletteSelector';

type Props = {
  availablePalettes: Palette[];
  selectedPalette: Palette;
  selectedPaletteId: string;
  handleSelectPalette: (paletteId: string, type: string) => void;
};

export const Img2ImgPaletteSelector = ({
  availablePalettes,
  selectedPalette,
  selectedPaletteId,
  handleSelectPalette,
}: Props) => {
  const [showPaletteSelector, setShowPaletteSelector] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        variant="transparent"
        onClick={() => setShowPaletteSelector(true)}
        ref={ref}
      >
        <SelectionContainer>
          <PaletteName>{selectedPalette.name}</PaletteName>
          <DropdownArrowIcon />
        </SelectionContainer>
      </Button>
      {showPaletteSelector && (
        <ExtraPopup $top={-120} $right={ref.current!.clientWidth - 45}>
          <PaletteSelector
            size="small"
            availablePalettes={availablePalettes}
            selectedPaletteId={selectedPaletteId}
            handleSelectPalette={(paletteId, type) => {
              setShowPaletteSelector(false);
              handleSelectPalette(paletteId, type);
            }}
          />
        </ExtraPopup>
      )}
    </>
  );
};

const SelectionContainer = styled.div`
  white-space: nowrap;
  display: flex;
  gap: 8px;
`;

const PaletteName = styled.div`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownArrowIcon = styled(ArrowIcon)`
  display: block;
  margin-left: 0px;
  margin-right: -4px;
  margin-top: 1px;
  width: 12px;
  height: 12px;
`;
