import {
  SettingsPageContainer,
  SettingsPageHeader,
  SettingsPageDivider,
} from '../components/SettingsPageLayout';
import {
  FullPageDarkLoader,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { OrganizationSubscriptionCurrentPlan } from './OrganizationSubscriptionCurrentPlan';
import { OrganizationPaymentMethod } from './OrganizationPaymentMethod';
import { useOrganizationSubscription } from '@vizcom/shared/data-access/graphql';
import { OrganizationInvoices } from './OrganizationInvoices';
import { OrganizationPlansTable } from 'libs/shared/ui/components/src/lib/OrganizationPlansTable';

export const OrganizationSubscription = () => {
  const { data: organization, loading } = useSelectedOrganization();
  const { data: organizationSubscription, fetching } =
    useOrganizationSubscription(organization?.id);

  if (fetching || loading) {
    return <FullPageDarkLoader />;
  }
  if (!organization || !organizationSubscription) {
    return (
      <Text block style={{ textAlign: 'center', padding: '60px 10px' }}>
        There was an error fetching this page. Please check the link and retry.
      </Text>
    );
  }
  if (organization.currentUserRole !== 'ADMIN') {
    return (
      <Text block>
        You need to be an administrator of this workspace to access this page.
      </Text>
    );
  }

  return (
    <SettingsPageContainer>
      <SettingsPageHeader>
        <Text style={{ fontSize: 18, fontWeight: 600 }}>Plans & Billing</Text>
        <Text color="info" type="b1">
          Manage your workspace subscription plan
        </Text>
      </SettingsPageHeader>
      <SettingsPageDivider />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 32,
          alignItems: 'stretch',
        }}
      >
        <OrganizationSubscriptionCurrentPlan
          subscriptionData={organizationSubscription}
          organizationId={organization.id}
        />
        {!organizationSubscription.manualSubscription && (
          <OrganizationPaymentMethod
            subscriptionData={organizationSubscription}
            organizationId={organization.id}
          />
        )}
      </div>

      <SettingsPageDivider />

      {!organizationSubscription.manualSubscription && (
        <>
          <Text block type="sh2" style={{ marginBottom: 24 }}>
            Billing history
          </Text>

          <OrganizationInvoices organizationId={organization.id} />

          <SettingsPageDivider />
        </>
      )}

      <Text block type="sh2">
        Plans
      </Text>

      <div style={{ marginTop: '32px' }}>
        <OrganizationPlansTable
          currentPlan={organizationSubscription.subscriptionPlan}
          organizationId={organization.id}
          organizationPoc={organization.poc}
        />
      </div>
    </SettingsPageContainer>
  );
};
