import {
  BezierPoint,
  ControlPoint,
  useSelectionApiStore,
} from '../useSelectionApi';
import { ThreeEvent } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { OperatingSystem, getOS } from '@vizcom/shared-ui-components';

type Props = {
  setSelectedControl: (args: { id: string; inner: boolean }) => void;
  adjustControl: (args: {
    event: ThreeEvent<PointerEvent>;
    point: BezierPoint;
    control: ControlPoint;
    x: number;
    y: number;
  }) => void;
  drawSelection: () => void;
  pointToLocal: (x: number, y: number) => number[];
};

export const useBindControl = ({
  setSelectedControl,
  adjustControl,
  drawSelection,
  pointToLocal,
}: Props) => {
  const selectionApiStore = useSelectionApiStore();
  const isMac = getOS() === OperatingSystem.MacOS;

  return useDrag<ThreeEvent<PointerEvent>>(
    ({ event, args, last }) => {
      const id = args[0].id as string;
      const inner = args[0].inner as boolean;

      const point = selectionApiStore
        .getState()
        .penState.bezierPoints.find((p) => p.id === id);
      if (!point) return;

      setSelectedControl({
        id,
        inner,
      });

      if (event.altKey) {
        event.stopPropagation();

        const pos = pointToLocal(event.clientX, event.clientY);
        const x = pos[0];
        const y = pos[1];

        selectionApiStore.getState().setPenState((state) => {
          const newPoints = [...state.bezierPoints];
          const pointIndex = newPoints.findIndex((p) => p.id === id);

          newPoints[pointIndex].singleControlLock = true;
          adjustControl({
            event,
            point: newPoints[pointIndex],
            control: inner
              ? newPoints[pointIndex].innerControl
              : newPoints[pointIndex].outerControl,
            x,
            y,
          });

          return {
            ...state,
            bezierPoints: newPoints,
          };
        });

        if (last) {
          drawSelection();
        }
      }

      if ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) {
        event.stopPropagation();

        const pos = pointToLocal(event.clientX, event.clientY);
        const x = pos[0];
        const y = pos[1];

        selectionApiStore.getState().setPenState((state) => {
          const newPoints = [...state.bezierPoints];
          const pointIndex = newPoints.findIndex((p) => p.id === id);

          const deltaX = x - newPoints[pointIndex].x;
          const deltaY = y - newPoints[pointIndex].y;

          adjustControl({
            event,
            point: newPoints[pointIndex],
            control: inner
              ? newPoints[pointIndex].innerControl
              : newPoints[pointIndex].outerControl,
            x,
            y,
          });

          if (!newPoints[pointIndex].singleControlLock) {
            adjustControl({
              event,
              point: newPoints[pointIndex],
              control: inner
                ? newPoints[pointIndex].outerControl
                : newPoints[pointIndex].innerControl,
              x: newPoints[pointIndex].x - deltaX,
              y: newPoints[pointIndex].y - deltaY,
            });
          }

          return {
            ...state,
            bezierPoints: newPoints,
          };
        });

        if (last) {
          drawSelection();
        }
      }
    },
    {
      pointer: {
        capture: false,
      },
    }
  );
};
