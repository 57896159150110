import { Object3D } from 'three';
import { ClientSideWorkbenchElementData } from '../../../lib/clientState';
import { getElementSize } from '../../helpers';
import { filterChildByWorkbenchElementUserData } from '../../objectsUserdata';
/**
 * Retrieves the section elements that intersect with the given section data.
 * @param sectionData - The section data containing x, y, width, and height.
 * @returns An array of section elements that intersect with the given section data.
 */
export const getSectionElements = (
  scene: Object3D,
  sectionData: ClientSideWorkbenchElementData
) => {
  const objects = filterChildByWorkbenchElementUserData(scene, () => true);

  return objects.filter((el) => {
    if (el.userData.elementTypename === 'WorkbenchElementSection') {
      return false;
    }
    const xStart = el.position.x - el.userData.elementWidth / 2;
    const xEnd = el.position.x + el.userData.elementWidth / 2;
    const yStart = el.position.y - el.userData.elementHeight / 2;
    const yEnd = el.position.y + el.userData.elementHeight / 2;
    const size = getElementSize(sectionData);
    if (
      xEnd <= sectionData.x + size.width / 2 &&
      xStart >= sectionData.x - size.width / 2 &&
      yEnd <= sectionData.y + size.height / 2 &&
      yStart >= sectionData.y - size.height / 2
    ) {
      return el;
    }
    return false;
  });
};
