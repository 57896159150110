import { useNavigate } from 'react-router-dom';
import {
  Button,
  CarretDownIcon,
  HomeIcon,
  ImageIcon,
  Import3DModelIcon,
  ImportImageIcon,
  Menu,
  MenuDivider,
  MenuItem,
  Text,
  VizcomLogo,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';
import { HtmlOverlay } from '../../utils/HtmlOverlay';
import { FileMenu } from '../../workbenchMenu/styles';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import styled from 'styled-components';
import { WorkbenchStudioMenuLayoutItem } from './WorkbenchStudioMenuLayoutItem';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { useState } from 'react';
import { WorkbenchNameInput } from '../../workbenchMenu/WorkbenchNameInput';
import {
  useLastNonNullValue,
  addToast,
  browseForFile,
  resizeImageToCoverSize,
} from '@vizcom/shared-ui-components';
import { useOrganization } from '@vizcom/shared/data-access/graphql';
import { useLayersCompositor } from '../DrawingCompositor/LayersCompositor/context';
import { v4 as uuid } from 'uuid';
import { getLayerOrderKey } from '@vizcom/shared/js-utils';
import { prepare3DLayerActionPayload } from '../../../lib/actions/drawing/addLayer';
import { useExportUtils } from '../lib/useExportUtils';
import { WorkbenchSettingsMenu } from '../Toolbar/WorkbenchSettingsMenu';

interface WorkbenchStudioMenuProps {
  drawing: Drawing2dStudio;
  onExit: () => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  triggerPaywallModal: () => void;
  setShowOverlay: (show: boolean) => void;
  setActiveLayerId: (id: string) => void;
  activeLayerId: string;
}

export const WorkbenchStudioMenu = (props: WorkbenchStudioMenuProps) => {
  const navigate = useNavigate();
  const isViewer = useIsWorkbenchViewer();
  const [isEditingName, setIsEditingName] = useState(false);
  const [exporting, setExporting] = useState(false);
  const organizationId = useLastNonNullValue(
    props.drawing.workbench?.team?.organization?.id
  );
  const { data: organization } = useOrganization(organizationId);
  const layersCompositor = useLayersCompositor();
  const isFreePlan = organization?.subscriptionPlan === 'FREE';
  const [loadingInsertMesh, setLoadingInsertMesh] = useState(false);

  const { handleExportToVideo, handleExportToPng, exportPSD, exportAllModels } =
    useExportUtils(props.drawing, isFreePlan);

  const updateDrawingName = (name: string) => {
    if (!name || name === props.drawing.name) return;
    props.handleAction({
      type: 'updateDrawing',
      name,
    });
  };

  const handleExportToVideoWrapper = () => handleExportToVideo(setExporting);
  const handleExportToPngWrapper = (upscale: boolean) =>
    handleExportToPng(
      upscale,
      setExporting,
      props.triggerPaywallModal,
      layersCompositor
    );
  const exportPSDWrapper = () => exportPSD(setExporting);
  const exportAllModelsWrapper = () =>
    exportAllModels(setExporting, props.triggerPaywallModal);

  const handleUploadFile = async () => {
    const file = await browseForFile({
      accept: 'image/*',
    });
    if (!file) {
      return;
    }
    props.setShowOverlay(true);

    const image = await resizeImageToCoverSize(
      file,
      props.drawing.width,
      props.drawing.height
    );
    const id = uuid();
    props.handleAction({
      type: 'addLayer',
      layer: {
        id: id,
        name: file.name,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        orderKey: getLayerOrderKey(
          props.drawing.layers.nodes,
          props.activeLayerId
        ),
        image,
      },
    });
    props.setActiveLayerId(id);
    props.setShowOverlay(false);
  };

  const handleUpload3dModel = async () => {
    const file = await browseForFile();

    if (!file) return;

    props.setShowOverlay(true);
    setLoadingInsertMesh(true);

    try {
      const orderKey = getLayerOrderKey(
        props.drawing.layers.nodes,
        props.activeLayerId
      );

      const payload = await prepare3DLayerActionPayload(
        file,
        orderKey,
        props.drawing.width,
        props.drawing.height
      );
      props.handleAction(payload);
      props.setActiveLayerId(payload.layer.id);
    } catch (e) {
      const message = e instanceof Error ? e.message : '';
      addToast(`Error while importing 3D model ${file.name}: ${message}`, {
        type: 'danger',
      });
    } finally {
      setLoadingInsertMesh(false);
      props.setShowOverlay(false);
    }
  };

  return (
    <HtmlOverlay>
      <FileMenu
        style={{
          display: 'flex',
          gap: 4,
        }}
      >
        <Menu
          renderLabel={(props, interactionProps) => (
            <MenuButton
              variant="transparent"
              size="iconSquared"
              {...interactionProps}
            >
              <VizcomLogo />
              <CarretDownIcon />
            </MenuButton>
          )}
        >
          <MenuItem
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <HomeIcon /> Back to workspace
              </div>
            }
            onClick={() =>
              navigate(
                props.drawing.workbench?.folderId
                  ? paths.files.folder(props.drawing.workbench?.folderId)
                  : '/'
              )
            }
          />
          <MenuItem label="File">
            <MenuItem label="Open / Import">
              <MenuItem
                label={
                  <OptionContainer>
                    <ImportImageIcon />
                    <Text>Upload an image</Text>
                    <Text color="info">I</Text>
                  </OptionContainer>
                }
                onClick={handleUploadFile}
              />
              <MenuItem
                label={
                  <OptionContainer>
                    <Import3DModelIcon />
                    <Text>Upload a 3D model</Text>
                    <Text color="info">P</Text>
                  </OptionContainer>
                }
                onClick={handleUpload3dModel}
                disabled={loadingInsertMesh}
              />
            </MenuItem>
            <MenuDivider />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export Image · 1x</Text>
                  <Size>
                    {props.drawing.width} x {props.drawing.height}
                  </Size>
                </OptionContainer>
              }
              onClick={() => handleExportToPngWrapper(false)}
              disabled={exporting}
            />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export Image · 2x</Text>
                  {isFreePlan && <ProPill>PRO</ProPill>}
                  <Size>
                    {props.drawing.width * 2} x {props.drawing.height * 2}
                  </Size>
                </OptionContainer>
              }
              onClick={() => handleExportToPngWrapper(true)}
              disabled={exporting}
            />
            <MenuDivider />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>
                    Export Video ·{' '}
                    <span style={{ color: '#7070F2' }}>Layer time-lapse</span>
                  </Text>
                  <Size>
                    {props.drawing.width} x {props.drawing.height}
                  </Size>
                </OptionContainer>
              }
              onClick={handleExportToVideoWrapper}
              disabled={exporting}
            />
            <MenuDivider />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export PSD · 1x</Text>
                  <Size>
                    {props.drawing.width} x {props.drawing.height}
                  </Size>
                </OptionContainer>
              }
              onClick={exportPSDWrapper}
              disabled={exporting}
            />
            <MenuDivider />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export 3D · All models</Text>
                  {isFreePlan && <ProPill>PRO</ProPill>}
                  <Size>ZIP</Size>
                </OptionContainer>
              }
              onClick={exportAllModelsWrapper}
              disabled={exporting}
            />
          </MenuItem>

          <WorkbenchSettingsMenu />

          <MenuDivider />
          <MenuItem
            label="Documentation"
            onClick={() => window.open(`https://docs.vizcom.ai/`, '_blank')}
          />
          <MenuItem
            label="Account settings"
            onClick={() => navigate(paths.settings.account.profile())}
          />

          {!isViewer && (
            <>
              <MenuDivider />
              <WorkbenchStudioMenuLayoutItem />
            </>
          )}
        </Menu>
        <VerticalDivider />
        <ImageIcon style={{ color: 'white', margin: '0 8px' }} />
        <WorkbenchNameInput
          handleUpdate={updateDrawingName}
          value={props.drawing.name}
          isEditing={isEditingName}
          setIsEditing={setIsEditingName}
        />
      </FileMenu>
    </HtmlOverlay>
  );
};

export const VerticalDivider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.surface.e2};
`;

const MenuButton = styled(Button)`
  &:not(:disabled):hover {
    box-shadow: none;
    background-color: transparent;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Size = styled(Text)`
  color: ${({ theme }) => theme.text.info};
  text-align: right;
  font-feature-settings: 'calt';
`;

const ProPill = styled.div`
  width: 33px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.chip.primary.text};
  border: 0.5px solid ${({ theme }) => theme.chip.primary.text};
  background: ${({ theme }) => theme.chip.primary.background};
  font-size: 10px;
  font-weight: 600;
`;
