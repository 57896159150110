/**
 * NOTE Instaces of reusable Three.js imperative components.
 */
import * as THREE from 'three';
import { UltraHDRLoader } from 'three/examples/jsm/loaders/UltraHDRLoader';

export const ultraHdrLoader = new UltraHDRLoader();
export const box3 = new THREE.Box3();

/**
 * NOTE Reusable blank Camera. Be sure to copy or reset this camera before reusing.
 */
export const perspectiveCamera = new THREE.PerspectiveCamera();

/**
 * NOTE Do not use these for calculations, just as placeholders for Matrix4.decompose etc.
 */
export const emptyVector2 = new THREE.Vector2();
export const emptyVector3 = new THREE.Vector3();
export const emptyQuaternion = new THREE.Quaternion();
export const emptyMatrix4 = new THREE.Matrix4();
export const emptyEuler = new THREE.Euler();
