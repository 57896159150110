import { Dispatch, SetStateAction } from 'react';
import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  AutoplayVideo,
  OnboardingTourTooltipContent,
  useShouldDisplayOnboardingTour,
} from '@vizcom/shared-ui-components';
import { ImageInferenceType } from '@vizcom/shared/data-access/graphql';
import { StyleReferenceImageSelector } from './StyleReferenceImageSelector';
import { InferenceSettings } from '../useInference';
import { Field, Header, HoverableHeaderSecondary } from '../style';
import { Palette, PaletteSelector } from '../../modals/PaletteSelector';
import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import { FloatingTree } from '@floating-ui/react';
import paletteSliderInfo from './assets/palette_slider_info.gif';
import referenceImageSliderInfo from './assets/tooltip_reference_image.mp4';
import educationalTooltipVideo03 from '../../../assets/videos/educational-tooltip-03.mp4';
import { useTheme } from 'styled-components';
import PaletteChip from './StylePaletteChip';
import {
  OnboardingStep,
  useOnboardingMultiStep,
} from '../../utils/OnboardingMultiStep';

type StyleSettingsProps = {
  drawing: Drawing2dStudio;
  historyPreviewActive: boolean;
  availablePalettes: Palette[];
  selectedPaletteId: string;
  styleReferenceId?: string;
  styleReferenceStrength: number;
  imageInferenceType: ImageInferenceType;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  handleSelectPalette: (paletteId: string, type: string) => void;
  paletteInfluence: number;
  onPaletteInfluenceChange: (value: number) => void;
};

export const StyleSettings = ({
  drawing,
  historyPreviewActive,
  availablePalettes,
  selectedPaletteId,
  styleReferenceId,
  styleReferenceStrength,
  imageInferenceType,
  setInferenceSettings,
  handleSelectPalette,
  paletteInfluence,
  onPaletteInfluenceChange,
}: StyleSettingsProps) => {
  const shouldDisplayTour = useShouldDisplayOnboardingTour();
  const { currentStep } = useOnboardingMultiStep();
  const theme = useTheme();

  const palette =
    (availablePalettes.find((p) => p.value === selectedPaletteId) ||
      availablePalettes.find(
        (palette) =>
          palette.versions &&
          palette.versions.find((v) => v.value === selectedPaletteId)
      )) ??
    availablePalettes[0];

  return (
    <div
      style={{
        width: '100%',
        pointerEvents: historyPreviewActive ? 'none' : 'all',
        maskImage: historyPreviewActive
          ? 'linear-gradient( to bottom, rgba(21,21,23,1) -50%, transparent 100% )'
          : 'none',
      }}
    >
      <Field>
        <Header>Style</Header>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '40px 1fr',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          {shouldDisplayTour && currentStep === 3 ? (
            <RichTooltip isOpen={true} placement="left" padding={88}>
              <RichTooltipTrigger>
                <HoverableHeaderSecondary>Palette</HoverableHeaderSecondary>
              </RichTooltipTrigger>
              <OnboardingTourTooltipContent>
                <OnboardingStep
                  video={educationalTooltipVideo03}
                  title="Select a Palette"
                  content="Choose from a number of styles to see your idea rendered in different ways."
                />
              </OnboardingTourTooltipContent>
            </RichTooltip>
          ) : (
            <RichTooltip
              trigger="hover"
              placement="left"
              padding={16}
              delay={{
                open: 750,
                close: 0,
              }}
            >
              <RichTooltipTrigger>
                <HoverableHeaderSecondary>Palette</HoverableHeaderSecondary>
              </RichTooltipTrigger>
              <RichTooltipContent style={{ maxWidth: '200px' }}>
                <Text>Palette</Text>
                <Text
                  type="b2"
                  color="info"
                  block
                  style={{
                    marginTop: '6px',
                    marginBottom: '8px',
                    lineHeight: '1.4',
                  }}
                >
                  Select a rendering style and adjust the detail level for your
                  generation
                </Text>
                <div style={{ textAlign: 'right', marginBottom: '12px' }}>
                  <a
                    href="https://docs.vizcom.ai/render-styles"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '10px',
                      color: theme.primary.default,
                      textDecoration: 'none',
                    }}
                  >
                    Learn more
                  </a>
                </div>
                <img
                  src={paletteSliderInfo}
                  alt="Palette Slider Info"
                  style={{ borderRadius: '4px', width: '100%' }}
                />
              </RichTooltipContent>
            </RichTooltip>
          )}

          <FloatingTree>
            <RichTooltip
              noParentIntegration
              trigger="click"
              padding={64}
              placement="left"
            >
              <RichTooltipTrigger>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <PaletteChip
                    palette={palette}
                    paletteInfluence={paletteInfluence}
                    onPaletteInfluenceChange={onPaletteInfluenceChange}
                    showInfluenceSlider={
                      imageInferenceType !== ImageInferenceType.Refine
                    }
                  />
                </div>
              </RichTooltipTrigger>
              <RichTooltipContent
                hideArrow
                style={{ padding: 0, borderRadius: '16px', overflow: 'hidden' }}
              >
                <PaletteSelector
                  size="large"
                  availablePalettes={availablePalettes}
                  selectedPaletteId={selectedPaletteId}
                  handleSelectPalette={handleSelectPalette}
                />
              </RichTooltipContent>
            </RichTooltip>
          </FloatingTree>

          {imageInferenceType !== ImageInferenceType.Refine && (
            <>
              <RichTooltip
                trigger="hover"
                placement="left"
                padding={16}
                delay={{
                  open: 750,
                  close: 0,
                }}
              >
                <RichTooltipTrigger>
                  <HoverableHeaderSecondary>Image</HoverableHeaderSecondary>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ maxWidth: '200px' }}>
                  <Text>Reference Image</Text>
                  <Text
                    type="b2"
                    color="info"
                    block
                    style={{
                      marginTop: '6px',
                      marginBottom: '8px',
                      lineHeight: '1.4',
                    }}
                  >
                    Use reference images as style guides to influence the look
                    and feel of your renders.
                  </Text>
                  <div style={{ textAlign: 'right', marginBottom: '12px' }}>
                    <a
                      href="https://youtu.be/bUF0CDeUT2E?si=0-GULYgo8IPa24Bb&t=78"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: '10px',
                        color: theme.primary.default,
                        textDecoration: 'none',
                      }}
                    >
                      Learn more
                    </a>
                  </div>
                  <AutoplayVideo
                    src={referenceImageSliderInfo}
                    style={{
                      width: '100%',
                      height: 'auto',
                      aspectRatio: '16 / 9',
                      objectFit: 'cover',
                      borderRadius: '6px',
                    }}
                  />
                </RichTooltipContent>
              </RichTooltip>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StyleReferenceImageSelector
                  workbenchId={drawing.workbenchId}
                  references={drawing?.workbench?.styleReferences?.nodes || []}
                  styleReferenceId={styleReferenceId}
                  setStyleReferenceId={(id) =>
                    setInferenceSettings((prev) => ({
                      ...prev,
                      styleReferenceId: id,
                    }))
                  }
                  styleReferenceStrength={styleReferenceStrength}
                  setStyleReferenceStrength={(value) =>
                    setInferenceSettings((prev) => ({
                      ...prev,
                      styleReferenceStrength: value,
                    }))
                  }
                />
              </div>
            </>
          )}
        </div>
      </Field>
    </div>
  );
};
