import {
  Button,
  EmailMultiInput,
  Surface,
  Text,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';
import { Dispatch, SetStateAction } from 'react';

interface CreateOrganizationInvitesPageProps {
  emails: string[];
  onSetEmails: Dispatch<SetStateAction<string[]>>;
  onSubmit: () => void;
}

export const CreateOrganizationInvitesPage = ({
  emails,
  onSetEmails,
  onSubmit,
}: CreateOrganizationInvitesPageProps) => {
  return (
    <Container>
      <Text block type="t1">
        Invite members
      </Text>
      <Text>
        To understand the full scope of Vizcom, try inviting a few members.
      </Text>

      <div style={{ textAlign: 'left' }}>
        <Text block style={{ marginBottom: 8 }} type="sh2">
          Email addresses
        </Text>
        <Surface>
          <EmailMultiInput emails={emails} setEmails={onSetEmails} />
        </Surface>
      </div>

      <Text block>
        You can update workspace roles on the members page after setting up.
      </Text>

      <Button onClick={onSubmit} disabled={!emails.length}>
        Invite members
      </Button>

      <Text
        onClick={onSubmit}
        style={{
          textDecoration: 'underline',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        Skip for now
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 32px;
  text-align: center;
`;
