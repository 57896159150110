import {
  urqlClient,
  UpdateWorkbenchElementSectionMutation,
} from '@vizcom/shared/data-access/graphql';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';

export const UpdateSectionAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updateSection';
    elementId: string;
    color?: string;
  }
> = {
  type: 'updateSection',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementSection') {
      sourceElement.color = payload.color ?? sourceElement.color;
    }
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(
      UpdateWorkbenchElementSectionMutation,
      {
        input: {
          id: payload.elementId,
          patch: {
            color: payload.color,
          },
        },
      }
    );
    if (res?.error) {
      throw new Error(
        `Error while updating element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload) => ({
    delay: 500,
    debounceId: payload.elementId,
  }),
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element || element.__typename !== 'WorkbenchElementSection') {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof UpdateSectionAction> =
      {
        type: 'updateSection',
        elementId: payload.elementId,
        color: element.color,
      };

    return undoPayload;
  },
};
