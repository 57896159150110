import { ArrowOptions } from 'perfect-arrows';
import { QuadraticBezierCurve3, Vector3 } from 'three';

export const Z_POSITION_CONNECTOR_AREA = 0.9;
export const LINE_SEGMENTS = 12;
export const ZERO_SEGMENTS = new Array(LINE_SEGMENTS + 1)
  .fill(1)
  .flatMap(() => [0, 0, 0]);

export const ARROW_OPTIONS: ArrowOptions = {
  bow: 0.05,
  padStart: 0,
  padEnd: 0,
  flip: false,
  straights: true,
};

const ANCHOR_WEIGHT_MIN = 10;
const ANCHOR_WEIGHT_MAX = 40;
const ANCHOR_ENDPOINT_WEIGHT = 25;

export const getSmoothLinePoints = (
  sy: number,
  sx: number,
  cy: number,
  ex: number,
  ey: number
) => {
  if (sx < ex) {
    // If the source is to the left of the target, the connector has 4 vertices

    // for flipping the curve control points based on if the source is above or below the target
    const dir = Math.sign(sy - ey);

    // dynamic anchor weight based on distance for the curve control points
    const anchorWeightX = Math.min(ANCHOR_WEIGHT_MIN, Math.abs(sx - ex) / 2);
    const anchorWeightY = Math.min(ANCHOR_WEIGHT_MIN, Math.abs(sy - ey) / 2);

    // the mid point of the line, but not further than 40px from the source for consistent lines
    const midX = Math.min((sx + ex) / 2, sx + 40);

    return [
      new Vector3(sx - ANCHOR_WEIGHT_MIN, sy, 0),
      ...new QuadraticBezierCurve3(
        new Vector3(
          sx + Math.min(ANCHOR_ENDPOINT_WEIGHT, Math.abs(sx - ex) / 2),
          sy,
          0
        ),
        new Vector3(midX, sy, 0),
        new Vector3(midX, sy - anchorWeightY * dir, 0)
      ).getPoints(8),
      ...new QuadraticBezierCurve3(
        new Vector3(midX, ey + anchorWeightY * dir, 0),
        new Vector3(midX, ey, 0),
        new Vector3(midX + anchorWeightX, ey, 0)
      ).getPoints(8),
      new Vector3(ex + ANCHOR_WEIGHT_MIN, ey, 0),
    ];
  } else {
    // If the source is to the right of the target, the connector has 6 vertices

    // for flipping the curve control points based on if the source is above or below the target,
    // with 6 control points, the first two curves are based on the distance from the start to the center
    // and the next two curves are based on the distance from the start to the end
    const dir1 = Math.sign(sy - cy);
    const dir2 = Math.sign(sy - ey);

    // dynamic anchor weight based on distance for the curve control points
    // with 6 vertices, the first two curves are based on the distance from the start to the center
    // and the next two curves are based on the distance from the center to the end
    const anchorWeightX = Math.min(ANCHOR_WEIGHT_MIN, Math.abs(sx - ex) / 2);
    const anchorWeightYStart = Math.min(
      ANCHOR_WEIGHT_MIN,
      Math.abs(sy - cy) / 2
    );
    const anchorWeightYEnd = Math.min(ANCHOR_WEIGHT_MIN, Math.abs(cy - ey) / 2);

    // right extension of the start vertice to the second vertice, but not further than 40px
    const pushX = Math.min(40, sx - ex);

    return [
      new Vector3(sx - ANCHOR_WEIGHT_MIN, sy, 0),
      ...new QuadraticBezierCurve3(
        new Vector3(
          sx + Math.min(ANCHOR_ENDPOINT_WEIGHT, Math.abs(sx - ex) / 2),
          sy,
          0
        ),
        new Vector3(sx + pushX, sy, 0),
        new Vector3(sx + pushX, sy - anchorWeightYStart * dir1, 0)
      ).getPoints(8),
      ...new QuadraticBezierCurve3(
        new Vector3(sx + pushX, cy + anchorWeightYStart * dir1, 0),
        new Vector3(sx + pushX, cy, 0),
        new Vector3(sx + pushX - anchorWeightX, cy, 0)
      ).getPoints(8),
      ...new QuadraticBezierCurve3(
        new Vector3(ex - ANCHOR_WEIGHT_MAX + anchorWeightX, cy, 0),
        new Vector3(ex - ANCHOR_WEIGHT_MAX, cy, 0),
        new Vector3(ex - ANCHOR_WEIGHT_MAX, cy - anchorWeightYEnd * dir2, 0)
      ).getPoints(8),
      ...new QuadraticBezierCurve3(
        new Vector3(ex - ANCHOR_WEIGHT_MAX, ey + anchorWeightYEnd * dir2, 0),
        new Vector3(ex - ANCHOR_WEIGHT_MAX, ey, 0),
        new Vector3(ex - ANCHOR_ENDPOINT_WEIGHT, ey, 0)
      ).getPoints(8),
      new Vector3(ex + ANCHOR_WEIGHT_MIN, ey, 0),
    ];
  }
};
