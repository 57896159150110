import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useRef, useState } from 'react';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import {
  BoundingBox,
  MAX_Z_POSITION,
  getElementSize,
  getElementsBoundingBox,
  screenPositionToWorld,
} from './helpers';
import { ResizePositionRotationPresenter } from './utils/ResizePositionRotationPresenter';
import {
  getElementMinimumSize,
  getTextContentSize,
} from './utils/getContentSize';
import {
  filterChildByWorkbenchElementUserData,
  findChildByWorkbenchElementUserData,
} from './objectsUserdata';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { OrthographicCamera, Vector2 } from 'three';
import { PALETTE_COLOR } from './elements/palette/WorkbenchElementPalette';

export const ResizerControls = ({
  active,
  elements,
  handleAction,
  setIsResizing,
}: {
  elements: ClientSideWorkbenchElementData[];
  active: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setIsResizing: (value: boolean) => void;
}) => {
  const boundingBox = getElementsBoundingBox(elements);
  const boundingBoxRef = useRef(JSON.stringify(boundingBox));

  const { bindScaleHandle, resetScale, scaledBoundingBox } = useScaleTransform(
    elements,
    boundingBox,
    setIsResizing,
    (newBoundingBox) => {
      setIsResizing(false);
      if (
        elements.length === 1 &&
        elements[0].__typename === 'WorkbenchElementText' &&
        newBoundingBox.width !== elements[0].width
      ) {
        const [element] = elements;

        const { newContentHeight } = getTextContentSize({
          ...element,
          lockWidth: true,
          width: newBoundingBox.width,
        });

        handleAction({
          type: 'updateText',
          elementId: element.id,
          contentHeight: newContentHeight,
          height:
            newContentHeight * (newBoundingBox.height / element.contentHeight),
          width: newBoundingBox.width,
          lockWidth: true,
          x: newBoundingBox.x,
          y: newBoundingBox.y,
          final: true,
        });

        return;
      }
      handleAction({
        type: 'multiPosition',
        newElementData: elements.map((element) => {
          return calculateNewElementData(
            { ...element, ...getElementSize(element) },
            boundingBox,
            newBoundingBox
          );
        }),
      });
    }
  );

  if (boundingBoxRef.current !== JSON.stringify(boundingBox)) {
    boundingBoxRef.current = JSON.stringify(boundingBox);
    resetScale();
  }

  const resizerBoundingBox = scaledBoundingBox ?? boundingBox;

  // Hide the resizer if there is only one empty text element
  if (
    elements.length === 1 &&
    elements[0].__typename === 'WorkbenchElementText' &&
    elements[0].content.length === 0
  ) {
    return null;
  }

  return (
    <group
      visible={active}
      position={[resizerBoundingBox.x, resizerBoundingBox.y, MAX_Z_POSITION]}
      userData={{
        workbenchObjectType: 'multi-focused-element-container',
        elementX: resizerBoundingBox.x,
        elementY: resizerBoundingBox.y,
        elementWidth: resizerBoundingBox.width,
        elementHeight: resizerBoundingBox.height,
      }}
    >
      <ResizePositionRotationPresenter
        active={active}
        width={resizerBoundingBox.width}
        height={resizerBoundingBox.height}
        resizeHandleMeshPropsGetter={bindScaleHandle as any}
        forceAspectRatio={
          elements.length > 1 ||
          (elements.length === 1 && elements[0].__typename === 'Drawing')
            ? { x: true, y: true }
            : elements.length === 1 &&
              (elements[0].__typename === 'WorkbenchElementMix' ||
                elements[0].__typename === 'WorkbenchElementText')
            ? { x: false, y: true }
            : { x: false, y: false }
        }
        color={
          elements.length === 1 &&
          elements[0].__typename === 'WorkbenchElementPalette'
            ? PALETTE_COLOR
            : undefined
        }
      />
    </group>
  );
};

interface UseScaleTransformDragMemo {
  initialPointerPosition: Vector2;
  dragDirection: [number, number];
  initialBoundingBox: BoundingBox;
  selectedObjects: ReturnType<typeof filterChildByWorkbenchElementUserData>;
  elementMinSizes: { [id: string]: { minWidth: number; minHeight: number } };
  scaleFromCenter: boolean;
}

const v2 = new Vector2();
const useScaleTransform = (
  elements: ClientSideWorkbenchElementData[],
  initialBoundingBox: BoundingBox,
  setIsResizing: (value: boolean) => void,
  onResize: (newBoundingBox: BoundingBox) => void
) => {
  const scene = useThree((s) => s.scene);
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const [scaledBoundingBox, setScaledBoundingBox] =
    useState(initialBoundingBox);

  const bindScaleHandle = useDrag<ThreeEvent<PointerEvent>>(
    (gesture): UseScaleTransformDragMemo => {
      gesture.event.stopPropagation();

      if (gesture.intentional) {
        setIsResizing(true);
      }
      const pointerPosition = screenPositionToWorld(
        [gesture.event.clientX, gesture.event.clientY],
        camera
      );

      v2.set(pointerPosition[0], pointerPosition[1]);

      const memo = gesture.memo as UseScaleTransformDragMemo;
      if (!memo) {
        const [xDir, yDir] = gesture.args as [number, number];
        const elementMinSizes = elements.reduce((acc, element) => {
          const [minWidth, minHeight] = getElementMinimumSize(element);
          acc[element.id] = { minWidth, minHeight };
          return acc;
        }, {} as { [id: string]: { minWidth: number; minHeight: number } });

        return {
          initialPointerPosition: v2.clone(),
          dragDirection: [xDir, yDir],
          initialBoundingBox,
          selectedObjects: filterChildByWorkbenchElementUserData(
            scene,
            ({ elementId }) => elements.some((e) => e.id === elementId)
          ),
          elementMinSizes,
          scaleFromCenter: gesture.event.altKey || gesture.event.metaKey,
        };
      }

      const deltaX = v2.x - memo.initialPointerPosition.x;
      const deltaY = v2.y - memo.initialPointerPosition.y;

      const forceAspectRatio =
        elements.length === 1
          ? elements[0].__typename === 'Drawing' ||
            (elements[0].__typename === 'WorkbenchElementText' &&
              memo.dragDirection[1] !== 0) ||
            (elements[0].__typename === 'WorkbenchElementMix' &&
              memo.dragDirection[1] !== 0)
          : true;

      const newBoundingBox = calculateNewBoundingBox(
        memo.initialBoundingBox,
        deltaX,
        deltaY,
        { x: memo.dragDirection[0], y: memo.dragDirection[1] },
        elements,
        memo.elementMinSizes,
        forceAspectRatio,
        memo.scaleFromCenter
      );

      setScaledBoundingBox(newBoundingBox);

      // Update the positions of the selected objects in real-time
      memo.selectedObjects.forEach((object) => {
        const element = elements.find(
          (e) => e.id === object.userData.elementId
        );
        if (!element) return;

        const newElementData = calculateNewElementData(
          {
            __typename: object.userData.elementTypename,
            x: object.userData.elementX,
            y: object.userData.elementY,
            id: object.userData.elementId,
            width: object.userData.elementWidth,
            height: object.userData.elementHeight,
          },
          memo.initialBoundingBox,
          newBoundingBox
        );

        object.position.set(
          newElementData.x,
          newElementData.y,
          object.position.z
        );

        object.scale.set(
          newElementData.width / object.userData.elementWidth,
          newElementData.height / object.userData.elementHeight,
          1
        );

        // WorkbenchElementText edge case
        if (
          object.userData.elementTypename === 'WorkbenchElementText' &&
          memo.dragDirection[1] === 0
        ) {
          const text = findChildByWorkbenchElementUserData(
            object,
            (userData) => !!userData.fixedAspectRatio
          );

          // inverse the scale so we maintain the aspect ratio
          text?.scale.set(
            1 / (newElementData.width / object.userData.elementWidth),
            1,
            1
          );
        }
      });

      if (gesture.last) {
        onResize(newBoundingBox);
      }

      return memo;
    }
  );

  return {
    bindScaleHandle,
    scaledBoundingBox,
    resetScale: () => {
      // reset scale of the selected objects
      const selectedObjects = filterChildByWorkbenchElementUserData(
        scene,
        ({ elementId }) => elements.some((e) => e.id === elementId)
      );
      selectedObjects.forEach((object) => {
        object.scale.set(1, 1, 1);
        object.position.set(
          object.userData.elementX,
          object.userData.elementY,
          object.userData.elementZIndex
        );

        // WorkbenchElementText edge case
        if (object.userData.elementTypename === 'WorkbenchElementText') {
          const text = findChildByWorkbenchElementUserData(
            object,
            (userData) => !!userData.fixedAspectRatio
          );
          text?.scale.set(1, 1, 1);
        }
      });
      setScaledBoundingBox(initialBoundingBox);
    },
  };
};

export function calculateNewBoundingBox(
  initialBox: BoundingBox,
  deltaX: number,
  deltaY: number,
  dragDirection: { x: number; y: number },
  elements: ClientSideWorkbenchElementData[],
  elementMinSizes: { [id: string]: { minWidth: number; minHeight: number } },
  forceAspectRatio: boolean,
  scaleFromCenter = false
) {
  const { x, y, width, height } = initialBox;

  // Find the maximum scale factor that doesn't violate the minimum size constraint
  let maxScaleX = -Infinity;
  let maxScaleY = -Infinity;
  elements.forEach((element) => {
    const { minWidth, minHeight } = elementMinSizes[element.id];
    const currentSize = getElementSize(element);
    maxScaleX = Math.max(maxScaleX, minWidth / currentSize.width);
    maxScaleY = Math.max(maxScaleY, minHeight / currentSize.height);
  });

  // Calculate the change based on drag direction
  const changeX = dragDirection.x * deltaX * (scaleFromCenter ? 2 : 1);
  const changeY = dragDirection.y * deltaY * (scaleFromCenter ? 2 : 1);

  let scaleX = Math.max((width + changeX) / width, maxScaleX);
  let scaleY = Math.max((height + changeY) / height, maxScaleY);

  if (forceAspectRatio) {
    if (dragDirection.x === 0) {
      scaleX = scaleY;
    } else if (dragDirection.y === 0) {
      scaleY = scaleX;
    } else {
      const maxScale = Math.max(scaleX, scaleY);
      scaleX = maxScale;
      scaleY = maxScale;
    }
  }

  const newWidth = width * scaleX;
  const newHeight = height * scaleY;
  let newX = x;
  let newY = y;

  if (!scaleFromCenter) {
    const heightChange = (height - newHeight) / 2;
    const widthChange = (width - newWidth) / 2;

    newX = x - widthChange * dragDirection.x;
    newY = y - heightChange * dragDirection.y;
  }

  return { x: newX, y: newY, width: newWidth, height: newHeight };
}

export function calculateNewElementData(
  element: {
    __typename: string;
    x: number;
    y: number;
    width: number;
    height: number;
    id: string;
  },
  initialBox: BoundingBox,
  newBox: BoundingBox
) {
  const relativeX = (element.x - initialBox.x) / initialBox.width;
  const relativeY = (element.y - initialBox.y) / initialBox.height;

  const scaleX = newBox.width / initialBox.width;
  const scaleY = newBox.height / initialBox.height;

  const newWidth = element.width * scaleX;
  const newHeight = element.height * scaleY;

  const newX = newBox.x + relativeX * newBox.width;
  const newY = newBox.y + relativeY * newBox.height;

  return {
    x: newX,
    y: newY,
    id: element.id,
    width: newWidth,
    height: newHeight,
  };
}
