import * as Comlink from 'comlink';

import { AutoSelectModelScale, AutoSelectInputPoint } from './types';

type TWorker = typeof import('./onnx.worker');
let _worker: Comlink.Remote<TWorker> | null = null;

const worker = () => {
  if (_worker === null) {
    _worker = Comlink.wrap<TWorker>(
      new Worker(new URL('./onnx.worker.ts', import.meta.url), {
        type: 'module',
      })
    );
  }

  return _worker;
};

// memoize the last embedding used to only send it to the worker if it has changed
let lastEmbedding = null as null | ArrayBuffer;

export const warmUpAutoSelectWorker = async () => worker().initOnnx();

export const setAutoselectWorkerEmbedding = async (
  embedding: ArrayBuffer,
  modelScale: AutoSelectModelScale
) => {
  if (embedding !== lastEmbedding) {
    lastEmbedding = embedding;
    await worker().initiateModel(embedding, modelScale);
  }
};

export const autoSelect = async (points: AutoSelectInputPoint[]) => {
  return worker().runModel(points);
};

export const initiateAutoSelectWorker = async () => {
  worker();
};
