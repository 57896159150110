import {
  Button,
  Chip,
  FormattedDate,
  LoadingLogoInset,
  Surface,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import {
  OrganizationSubscriptionData,
  useCreateOrganizationSubscriptionManagementPortal,
  OrganizationSubscriptionPlan,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';

export const OrganizationSubscriptionCurrentPlan = ({
  subscriptionData,
  organizationId,
}: {
  subscriptionData: OrganizationSubscriptionData;
  organizationId: string;
}) => {
  const [res, createPortal] =
    useCreateOrganizationSubscriptionManagementPortal();

  const handleManageSubscription = async () => {
    const res = await createPortal({
      input: {
        organizationId,
      },
    });
    if (res.error || !res.data) {
      return addToast('Error while changing payment method', {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
    window.location.assign(
      res.data.createOrganizationSubscriptionManagementPortal.url
    );
  };

  return (
    <Surface style={{ padding: 24 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Text block type="sh1" style={{ marginBottom: 24 }}>
            {capitalize(subscriptionData.subscriptionPlan)} Plan
          </Text>
          <div style={{ marginLeft: '8px' }}>
            <Chip variant="primary">Active</Chip>
          </div>
        </div>
        {subscriptionData.subscriptionPlan !==
          OrganizationSubscriptionPlan.Free &&
          !subscriptionData.manualSubscription && (
            <Button variant="secondary" onClick={handleManageSubscription}>
              Manage plan
            </Button>
          )}
      </div>
      {subscriptionData.subscription.subscriptionBaseAmount !== undefined &&
        subscriptionData.subscription.subscriptionBaseAmount !== null && (
          <>
            <div>
              <Text type="t1" style={{ marginRight: 5 }}>
                ${subscriptionData.subscription.subscriptionBaseAmount}
              </Text>
              <Text color="info">per month</Text>
            </div>

            <Text style={{ marginTop: '13px' }} block color="info">
              {subscriptionData.subscription.seats} paid seat
              {subscriptionData.subscription.seats > 1 ? 's' : ''}
              {subscriptionData.subscription.nextInvoiceAmount !== undefined &&
                subscriptionData.subscription.nextInvoiceAmount !== null &&
                subscriptionData.subscription.nextInvoiceBillingDate &&
                Math.abs(
                  subscriptionData.subscription.nextInvoiceAmount -
                    (subscriptionData.subscription.subscriptionBaseAmount || 0)
                ) > 1 && (
                  <>
                    - next invoice{' '}
                    <FormattedDate
                      date={
                        subscriptionData.subscription.nextInvoiceBillingDate ||
                        new Date().getTime()
                      }
                    />
                  </>
                )}
            </Text>
          </>
        )}
      <LoadingLogoInset active={res.fetching} />
    </Surface>
  );
};
