import {
  Button,
  Text,
  TooltipMenu,
  TooltipMenuItem,
} from '@vizcom/shared-ui-components';
import {
  Actions,
  Container,
  Content,
  Divider,
  Frame,
  HeaderPrimary,
  Section,
} from '../compositeSceneMenu/style';
import {
  CompositeSceneElement,
  CompositeSceneFullData,
} from '@vizcom/shared/data-access/graphql';
import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { SceneStructureNode } from './SceneStructureNode';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Object3D, Vector3 } from 'three';
import CameraIcon from '../../../assets/icons/camera.svg?react';

export const SceneStructureTree = ({
  compositeScene,
  handleAction,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const [showAddContextMenu, setShowAddContextMenu] = useState(false);

  return (
    <Container>
      <Frame $actions="top">
        <HeaderPrimary>
          <Text type="sh1">Layers</Text>
          <Actions>
            <Button
              size="S"
              variant="tertiary"
              onClick={() => {
                setShowAddContextMenu(true);
              }}
            >
              Add +
            </Button>
          </Actions>
          <TooltipMenu
            direction="left"
            isOpen={showAddContextMenu}
            onClose={() => setShowAddContextMenu(false)}
            customHorizontalMargin={30}
            customVerticalMargin={-5}
          >
            <TooltipMenuItem
              icon={<CameraIcon style={{ width: 14, height: 14 }} />}
              label="Camera"
              onClick={() => {
                const uuid = uuidv4();

                const currentCameraPosition = new Vector3(
                  compositeScene.cameraPositionX,
                  compositeScene.cameraPositionY,
                  compositeScene.cameraPositionZ
                );

                const mock = new Object3D();
                mock.position.copy(currentCameraPosition);
                mock.lookAt(
                  compositeScene.cameraTargetX,
                  compositeScene.cameraTargetY,
                  compositeScene.cameraTargetZ
                );
                mock.updateMatrix();
                mock.rotateY(Math.PI);

                const mockDirection = mock.getWorldDirection(new Vector3());
                mock.position.add(mockDirection.multiplyScalar(-0.5));

                handleAction({
                  type: 'createCompositeSceneElement',
                  id: uuid,
                  name: 'Camera',
                  basicShape: 'Camera',
                });
                handleAction({
                  type: 'updateCompositeSceneElement',
                  id: uuid,
                  meshes: {
                    root: {
                      position: mock.position.toArray(),
                      quaternion: mock.quaternion.toArray(),
                      scale: [1.0, 1.0, 1.0],
                    },
                  },
                });

                setShowAddContextMenu(false);
              }}
            />
          </TooltipMenu>
        </HeaderPrimary>
        <Divider />
        <Content>
          <Section>
            {compositeScene.compositeSceneElements.nodes.map((element) => (
              <SceneStructureNode
                key={element.id}
                compositeSceneElement={element as CompositeSceneElement}
                handleAction={handleAction}
              />
            ))}
          </Section>
        </Content>
      </Frame>
    </Container>
  );
};
