export type FileToZip = {
  name: string;
  data: Blob;
};

export const zipFiles = async (files: FileToZip[]): Promise<Blob> => {
  const zip = await import('@zip.js/zip.js');

  const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'));

  await Promise.all(
    files.map(async (file) => {
      await zipWriter.add(file.name, new zip.BlobReader(file.data));
    })
  );

  return await zipWriter.close();
};
