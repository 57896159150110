import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { MultiDeleteAction, deletedDrawingHistory } from './multiDeleteAction';
import {
  urqlClient,
  CreateWorkbenchElementsMutation,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { omit } from 'lodash';
import { WorkbenchElementData } from '../../../components/WorkbenchKeyboardShortcuts';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { WorkbenchEventName } from '@vizcom/shared/data-shape';
import { cloneDeep } from 'lodash';
import { imageDataToBlob } from '@vizcom/shared-ui-components';
import { elementIsDrawing } from '../../utils';
import { filterExists } from '@vizcom/shared/js-utils';

export const MultiCreateAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'createElements';
    newElements: WorkbenchElementData[];
  }
> = {
  type: 'createElements',
  optimisticUpdater: ({ payload }, elements) => {
    payload.newElements.forEach((el) => {
      if (elements.find((element) => element.id === el.id)) {
        return;
      }
      if (el.__typename === 'Drawing') {
        // When creating a drawing, we first start by creating a placeholder that will be displayed until we get a response from the server
        // this is required to prevent the user from entering 2D studio before the drawing is created on the server
        elements.push({
          __typename: 'WorkbenchElementPlaceholder',
          id: el.id,
          type: 'loading',
          loadingImagePath:
            typeof el.thumbnailPath === 'string' ? el.thumbnailPath : undefined,
          width: el.drawingWidth * el.workbenchSizeRatio,
          height: el.drawingHeight * el.workbenchSizeRatio,
          updatedAt: '0',
          x: el.x,
          y: el.y,
          zIndex: el.zIndex,
          rawLoadingImage: el.image,
        });
      } else {
        elements.push(cloneDeep(el));
      }
    });
  },
  remoteUpdater: async ({ payload }, workbenchId) => {
    // if deletedDrawingHistory has a reference to the drawing being created,
    // it is an undo/redo action and the information should be referenced
    const drawings = payload.newElements.map((el) =>
      deletedDrawingHistory.get(el.id)
    );

    const res = await urqlClient.mutation(CreateWorkbenchElementsMutation, {
      createDrawingsInput: (
        await Promise.all(
          payload.newElements.map(async (data) => {
            if (!elementIsDrawing(data)) {
              return;
            }
            const drawing = drawings.find((d) => d?.id === data.id);
            const thumbnail = drawing?.thumbnailPath ?? data.thumbnailPath;
            return {
              ...omit(data, [
                '__typename',
                'updatedAt',
                'createdAt',
                'drawingWidth',
                'drawingHeight',
                'contentLayersCount',
              ]),
              workbenchId,
              width: drawing?.width ?? data.drawingWidth,
              height: drawing?.height ?? data.drawingHeight,
              layers: drawing
                ? drawing?.layers.nodes?.map((l) => ({
                    ...l,
                    drawingId: drawing?.id,
                  }))
                : data.layers?.nodes?.map((l) => ({
                    ...l,
                    drawingId: data.id,
                  })),
              layersOrder: drawing?.layersOrder ?? data.layersOrder,
              thumbnailPath:
                thumbnail instanceof ImageData
                  ? await imageDataToBlob(thumbnail)
                  : thumbnail,
            };
          })
        )
      ).filter(filterExists),
      createWorkbenchElementsPlaceholderInput: payload.newElements
        .filter((el) => el.__typename === 'WorkbenchElementPlaceholder')
        .map((data) => ({
          ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
          workbenchId,
          type: data.type || 'drawing',
        })),
      createWorkbenchElementsImg2ImgInput: payload.newElements
        .filter((el) => el.__typename === 'WorkbenchElementImg2Img')
        .map((data) => ({
          ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
          workbenchId,
        })),
      createWorkbenchElementsTextInput: payload.newElements
        .filter((el) => el.__typename === 'WorkbenchElementText')
        .map((data) => ({
          ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
          workbenchId,
        })),
      createWorkbenchElementsMixInput: payload.newElements
        .filter((el) => el.__typename === 'WorkbenchElementMix')
        .map((data) => ({
          ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
          workbenchId,
        })),
      // @ts-ignore
      createWorkbenchElementsSectionInput: payload.newElements
        .filter((el) => el.__typename === 'WorkbenchElementSection')
        .map((data) => ({
          ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
          workbenchId,
        })),
      createWorkbenchElementsPaletteInput: payload.newElements
        .filter((el) => el.__typename === 'WorkbenchElementPalette')
        .map((data) => ({
          ...omit(data, [
            '__typename',
            'updatedAt',
            'createdAt',
            'sourceImages',
            'status',
            'failureReason',
          ]),
          status: 'idle',
          workbenchId,
        })),
      createCompositeScenesInput: payload.newElements
        .filter((el) => el.__typename === 'CompositeScene')
        .map((data) => ({
          influence: 0.5,
          cameraPositionX: 5.0,
          cameraPositionY: 5.0,
          cameraPositionZ: -5.0,
          cameraTargetX: 0.0,
          cameraTargetY: 0.0,
          cameraTargetZ: 0.0,
          sceneBackgroundColorHex: '#cccccc',
          sceneEnvironmentMapUrl: 'Warehouse',
          cameraFov: 50.0,
          sceneEnvironmentGroundPlane: true,
          sceneEnvironmentAngle: 0.0,
          prompt: '',
          ...omit(data, ['__typename', 'updatedAt', 'createdAt']),
          thumbnailPath: '',
          workbenchId,
        })),
    });

    if (res?.error) {
      throw new Error(
        `Error while creating elements, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Create Elements');
    publishTrackingEvent({
      type: WorkbenchEventName.CREATE_ELEMENTS,
      data: {
        workbenchIds: [workbenchId],
        elementIds: payload.newElements.map((el) => el.id),
      },
    });
  },
  undoConstructor: ({ payload }) => {
    const undoPayloads: SyncedActionPayloadFromType<typeof MultiDeleteAction> =
      {
        type: 'deleteElements',
        elementIds: payload.newElements.map((el) => el.id),
      };
    return undoPayloads;
  },
};
