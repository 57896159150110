import { useFBO } from '@react-three/drei';
import {
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import {
  Event as ThreeEvent,
  Object3D,
  AddEquation,
  CustomBlending,
  DstColorFactor,
  MaxEquation,
  MeshBasicMaterial,
  OneFactor,
  OneMinusSrcAlphaFactor,
  OrthographicCamera,
  Scene,
  UnsignedByteType,
} from 'three';
import { LayersCompositorApi, LayersCompositorContext } from './context';
import { useFrame, useThree } from '@react-three/fiber';
import {
  object3dIsMesh,
  unPreMultiplyAlpha,
  yFlipImageBuffer,
} from '../../../helpers';
import { useStableCallback } from '../../../../../../../../shared/ui/components/src';
import { WORKBENCH_CONTENT_RENDERING_ORDER } from '../../../../constants';
import { CompositedLayerContentUserData } from './types';

// The layerCompositor is a component that renders a scene with multiple layers and returns a composited image
// the layers are rendered in an isolated scene, to clip them to the drawing size and allow setting blending modes for each of them
// This also allow us to render the layers in a portal without being affeted by the renderOrder of parent components, this way, the UI is always rendered on top of the layers
export const LayersCompositor = forwardRef<
  LayersCompositorApi,
  PropsWithChildren<{ drawingSize: [number, number]; layersOrder: string[] }>
>(({ children, drawingSize, layersOrder }, ref) => {
  const gl = useThree((s) => s.gl);
  const width = drawingSize[0];
  const height = drawingSize[1];

  const fbo = useFBO(width, height, {
    samples: 0,
    stencilBuffer: false,
    depthBuffer: false,
    type: UnsignedByteType,
  });

  const scene = useMemo(() => new Scene(), []);
  const camera = useMemo(() => {
    const camera = new OrthographicCamera(
      (width / 2) * -1,
      width / 2,
      height / 2,
      (height / 2) * -1,
      0.1,
      1000
    );
    camera.position.z = 10;
    return camera;
  }, [width, height]);

  const renderLayers = (
    ...params: Parameters<LayersCompositorApi['getCompositedImage']>
  ) => {
    const [options] = params;
    const onlyDisplayLayersIds = options?.onlyDisplayLayersIds;
    const fullOpacityForLayersIds = options?.forceFullOpacityForLayersId;

    const oldAutoClear = gl.autoClear;
    gl.autoClear = true;

    gl.setRenderTarget(fbo);

    scene.children.forEach((group) => {
      const layerInfo = group.userData as CompositedLayerContentUserData;
      group.visible = onlyDisplayLayersIds
        ? onlyDisplayLayersIds.includes(layerInfo.id)
        : layerInfo.visible;
      if (!group.visible) {
        return;
      }
      group.traverse((obj) => {
        if (object3dIsMesh(obj)) {
          obj.renderOrder = layersOrder.indexOf(layerInfo.id);
          const material = obj.material as MeshBasicMaterial;
          material.transparent = true;
          material.opacity =
            fullOpacityForLayersIds &&
            fullOpacityForLayersIds.includes(layerInfo.id)
              ? 1
              : layerInfo.opacity;

          // This is blending the layer with the background in a way that still works if the background is transparent
          // More info here: https://registry.khronos.org/OpenGL-Refpages/es2.0/xhtml/glBlendFunc.xml
          // https://apoorvaj.io/alpha-compositing-opengl-blending-and-premultiplied-alpha/
          // https://www.realtimerendering.com/blog/gpus-prefer-premultiplication/
          // http://www.adriancourreges.com/blog/2017/05/09/beware-of-transparent-pixels/
          material.premultipliedAlpha = true;
          material.blending = CustomBlending;
          material.blendEquation = AddEquation;
          material.blendEquationAlpha = MaxEquation; // same behavior as photoshop, only using the max opacity of any layer
          if (layerInfo.blendMode === 'multiply') {
            material.blendSrc = DstColorFactor;
          } else {
            material.blendSrc = OneFactor;
          }
          material.blendDst = OneMinusSrcAlphaFactor;

          material.blendSrcAlpha = OneFactor;
          material.blendDstAlpha = OneFactor;
        }
      });
    });

    gl.render(scene, camera);

    gl.setRenderTarget(null);
    gl.autoClear = oldAutoClear;
  };

  const getCompositedImage = useStableCallback<
    Parameters<LayersCompositorApi['getCompositedImage']>,
    ReturnType<LayersCompositorApi['getCompositedImage']>
  >((options) => {
    renderLayers(options);
    const imageData = new ImageData(width, height); // we want exportTexture to be synchronous to simplify the rest of the logic of the brush handling
    // to do so, we read the pixels and store them in an image that then get asynchrounously converted to a png blob
    // just before sending to the server
    gl.readRenderTargetPixels(fbo, 0, 0, width, height, imageData.data);
    // flip the image upside down because readRenderTargetPixels read pixels from the bottom left corner
    yFlipImageBuffer(imageData.data, width);
    unPreMultiplyAlpha(imageData.data);
    return imageData;
  });

  const getPixelColor = useStableCallback((x: number, y: number) => {
    const pixelBuffer = new Uint8ClampedArray(4);
    gl.readRenderTargetPixels(fbo, x, drawingSize[1] - y, 1, 1, pixelBuffer);
    unPreMultiplyAlpha(pixelBuffer);
    return Array.from(pixelBuffer);
  });

  const layerPickerFbo = useFBO(1, 1, {
    samples: 0,
    stencilBuffer: false,
    depthBuffer: false,
    type: UnsignedByteType,
  });

  const layerPickerCamera = useMemo(() => {
    return camera.clone();
  }, [camera]);

  const getLayerIdAt = useStableCallback((x: number, y: number) => {
    //We will render the layers one by one, so hide all layers
    //but keep initial visibilty to restore it later.
    const initialVisibilities = scene.children.map((group) => group.visible);
    scene.children.forEach((group) => (group.visible = false));

    //Collect visible groups. This will allow us to sort them by renderOrder
    const visibleLayers: {
      group: Object3D;
      renderOrder: number;
    }[] = [];
    scene.children.forEach((group) => {
      const layerInfo = group.userData as CompositedLayerContentUserData;
      const renderOrder = layersOrder.indexOf(layerInfo.id);
      if (layerInfo.visible) {
        visibleLayers.push({ group, renderOrder });
      }
    });

    //Sort layers by renderOrder
    visibleLayers.sort(({ renderOrder: a }, { renderOrder: b }) => b - a);

    //save initial WebGL state
    const oldAutoClear = gl.autoClear;
    gl.autoClear = true;

    layerPickerCamera.setViewOffset(
      width,
      height,
      Math.floor(x),
      Math.floor(y),
      1,
      1
    );
    gl.setRenderTarget(layerPickerFbo);

    //Render and find first opaque layers at given point.
    const firstOpaqueLayer = visibleLayers.find(({ group }) => {
      group.visible = true;
      const layerInfo = group.userData as CompositedLayerContentUserData;

      group.traverse((obj) => {
        if (object3dIsMesh(obj)) {
          const material = obj.material as MeshBasicMaterial;
          material.transparent = true;
          material.opacity = layerInfo.opacity;
        }
      });

      gl.render(scene, layerPickerCamera);

      //read pixel
      const pixelBuffer = new Uint8ClampedArray(4);
      gl.readRenderTargetPixels(layerPickerFbo, 0, 0, 1, 1, pixelBuffer);
      group.visible = false;
      return pixelBuffer[3] !== 0;
    });

    //Restore initial WebGL state
    gl.setRenderTarget(null);
    gl.autoClear = oldAutoClear;

    //Restore visibility
    scene.children.map((group, i) => (group.visible = initialVisibilities[i]));

    return firstOpaqueLayer
      ? (firstOpaqueLayer.group.userData as CompositedLayerContentUserData).id
      : null;
  });

  const value = useMemo<LayersCompositorApi>(
    () => ({
      scene,
      drawingSize,
      fbo,
      getCompositedImage,
      getPixelColor,
      getLayerIdAt,
    }),
    [fbo, scene, getCompositedImage, drawingSize, getPixelColor, getLayerIdAt]
  );

  useImperativeHandle(ref, () => value, [value]);

  useFrame(() => {
    renderLayers();
  }, WORKBENCH_CONTENT_RENDERING_ORDER.indexOf('layersComposition'));

  return (
    <LayersCompositorContext.Provider value={value}>
      {children}
    </LayersCompositorContext.Provider>
  );
});
