export const downloadCSV = (
  arrayOfData: Record<string, any>[],
  filename: string
) => {
  if (!Array.isArray(arrayOfData) || arrayOfData.length === 0) {
    console.error('Invalid data provided for CSV download');
    return;
  }

  try {
    const csvRows = [];
    const headers = Object.keys(arrayOfData[0]);
    csvRows.push(headers.join(','));

    arrayOfData.forEach((item) => {
      const values = headers.map(
        (header) => `"${item[header]?.toString().replace(/"/g, '""') || ''}"`
      );
      csvRows.push(values.join(','));
    });

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error occurred during CSV download:', error);
  }
};
