import { Text } from '@vizcom/shared-ui-components';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';

type WorkbenchToolType = 'select' | 'section' | 'text';

type WorkbenchToolContextValue = {
  tool: WorkbenchToolType;
  setTool: Dispatch<SetStateAction<WorkbenchToolType>>;
};

export const workbenchToolContext = createContext<
  WorkbenchToolContextValue | undefined
>(undefined);

export const WorkbenchToolProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [tool, setTool] = useState<WorkbenchToolType>('select');

  return (
    <workbenchToolContext.Provider value={{ tool, setTool }}>
      {children}
    </workbenchToolContext.Provider>
  );
};

export const useWorkbenchToolState = () => {
  const state = useContext(workbenchToolContext);

  if (!state) {
    throw new Error('useWorkbenchToolState must be used within a Workbench');
  }
  return state;
};
