import { useState } from 'react';
import { DrawingStudioHotkeysModal } from './DrawingStudioHotkeysModal';
import { WorkbenchMenuButton } from '../workbenchMenu/WorkbenchMenuButton';

export const HotkeysMenuButton = ({
  defaultTab,
}: {
  defaultTab?: 'Drawing' | 'CompositeScene' | 'Workbench';
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <WorkbenchMenuButton
        variant="transparent"
        onClick={() => setIsOpen(true)}
      >
        Keyboard shortcuts
      </WorkbenchMenuButton>
      <DrawingStudioHotkeysModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        defaultTab={defaultTab}
      />
    </>
  );
};
