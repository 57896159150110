import { createCanvas } from '@vizcom/shared-ui-components';

export const cropBlobImage = async ({
  blob,
  width,
  height,
}: {
  blob: Blob;
  width: number;
  height: number;
}): Promise<Blob> => {
  const image = await new Promise<HTMLImageElement>((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve(image);
    };
    image.src = URL.createObjectURL(blob);
  });

  const { canvas, ctx } = createCanvas(width, height);

  let downscaleRatio = 1.0;

  if (width >= height) {
    downscaleRatio = width / image.width;
  } else {
    downscaleRatio = height / image.height;
  }

  const scaledWidth = image.width * downscaleRatio;
  const scaledHeight = image.height * downscaleRatio;

  ctx.imageSmoothingQuality = 'high';
  ctx.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    width / 2 - scaledWidth / 2,
    height / 2 - scaledHeight / 2,
    scaledWidth,
    scaledHeight
  );

  const result = await new Promise<Blob | null>((resolve) =>
    canvas.toBlob(resolve)
  );

  URL.revokeObjectURL(image.src);

  if (!result) {
    throw new Error('Could not resize image');
  }

  return result;
};
