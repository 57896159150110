import { ClientSideWorkbenchElementData } from '../../clientState';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  TrainPaletteMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { elementById } from '../../utils';
import { PaletteEventName } from '@vizcom/shared/data-shape';
import { CancelPaletteTrainingAction } from './cancelPaletteTrainingAction';
import { assertExists } from '@vizcom/shared/js-utils';

export const TrainPaletteAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'trainPalette';
    id: string;
  },
  {
    imagesCount: number;
  }
> = {
  type: 'trainPalette',
  optimisticUpdater: ({ payload }, elements) => {
    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette') return;

    element.status = 'training';
  },
  remoteUpdater: async ({ payload, meta }) => {
    assertExists(meta.custom);
    const res = await urqlClient.mutation(TrainPaletteMutation, {
      input: {
        id: payload.id,
      },
    });
    if (res?.error) {
      throw new Error(
        `Error while training palette, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Train palette');
    publishTrackingEvent({
      type: PaletteEventName.TRAIN,
      data: {
        paletteId: payload.id,
        imagesCount: meta.custom.imagesCount,
      },
    });
  },
  metaConstructor: (payload, elements) => {
    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette')
      return { custom: { imagesCount: 0 } };

    return {
      custom: {
        imagesCount: element.sourceImages.nodes.length,
      },
    };
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<
      typeof CancelPaletteTrainingAction
    > = {
      type: 'cancelPaletteTraining',
      id: payload.id,
    };
    return undoPayload;
  },
};
