import { OperatingSystem, getOS } from '@vizcom/shared-ui-components';
import { styled } from '@vizcom/web/common/theme';

export const KeysRow = styled.div`
  display: flex;
  gap: 8px;
`;

export const KbdKey = styled.div<{ $width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-width: 24px;
  height: 24px;
  color: white;
  background: ${({ theme }) => theme.surface.e2};
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  padding: 0 4px;
`;

export const KbdPlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-width: 24px;
  height: 24px;
  color: white;
  padding: 0 4px;
`;

export const KbdText = styled.div<{ $width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-width: 24px;
  height: 24px;
  color: white;
  padding: 0 4px;
`;

export const ControlCmdKey = () => {
  const isMac = getOS() === OperatingSystem.MacOS;

  return <KbdKey>{isMac ? '⌘' : 'Ctrl'}</KbdKey>;
};
