import { ChangelogModal } from './ChangelogModal';
import { useEffect, useState } from 'react';
import {
  useCurrentUser,
  useUnseenChangelogs,
  useUpdateUser,
} from '@vizcom/shared/data-access/graphql';

export const GlobalChangelogModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const currentUser = useCurrentUser();
  const changelogs = useUnseenChangelogs({ count: 3 });
  const [, updateUser] = useUpdateUser();

  useEffect(() => {
    if (!currentUser.data?.id || changelogs.data?.length === 0) {
      return;
    }

    setModalOpen(true);
  }, [currentUser.data?.id, changelogs.data?.length]);

  return (
    <ChangelogModal
      changelogs={changelogs.data}
      isOpen={modalOpen}
      onClose={async () => {
        if (currentUser.data) {
          await updateUser({
            id: currentUser.data.id,
            patch: {
              lastChangelogSeenAt: new Date().toUTCString(),
            },
          });
        }

        setModalOpen(false);
      }}
    />
  );
};
