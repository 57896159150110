import React from 'react';
import { Team, useDeleteTeam } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

export const ConfirmDeleteTeamModal = (props: {
  team: Team & { usersOnTeamsByTeamId: { totalCount: number } };
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { team, isOpen, setIsOpen } = props;
  const [deleteTeamRes, deleteTeam] = useDeleteTeam();

  const handleDeleteTeam = async () => {
    const res = await deleteTeam({
      id: team.id,
    });
    if (res.error) {
      return addToast('There was an error while deleting the team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast('Team deleted');
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={deleteTeamRes.fetching}
    >
      <ModalHeader>
        <ModalTitle>Delete "{team.name}"</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>
      <ModalContent>
        <Text block>
          Do you really want to delete the team "{team.name}"? All of the files
          and folders contained in it will be permanently deleted.
        </Text>
        {team.usersOnTeamsByTeamId.totalCount === 0 ? (
          <Text block>This team doesn't have any member.</Text>
        ) : (
          <Text block>
            All members ({team.usersOnTeamsByTeamId.totalCount}) of this team
            will be removed from it.
          </Text>
        )}
        <Text block>This action cannot be reverted.</Text>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => setIsOpen(false)} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleDeleteTeam} variant="danger">
          Delete team
        </Button>
      </ModalActions>
    </Modal>
  );
};
