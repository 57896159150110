import styled, { useTheme } from 'styled-components';
import {
  Button,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { TransitionStatus } from 'react-transition-group';
import { keyframes } from 'styled-components';
import { Create } from './create/Create';
import { Hotkey, Overlay } from './style';
import { InferenceSettings } from './useInference';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { PromptHistoryItem } from './history/WorkbenchStudioHistory';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { Layout } from './types';
import Layers from './Layers';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconPlus } from '@vizcom/shared-utils-assets';
import { getLayerOrderKey } from '@vizcom/shared/js-utils';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';

const slideInFromRight = keyframes`
  from {
    transform: translateX(calc(100% + 14px));
  }
  to {
    transform: translateX(0);
  }
`;

const StudioTabs = styled(animated.div)<{
  $state: TransitionStatus;
  $hidden: boolean;
}>`
  pointer-events: all;
  position: absolute;
  top: calc(1rem + 66px);
  right: 14px;
  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateX(0)'
      : 'translateX(calc(100% + 14px))'};
  animation: ${({ $state }) =>
      $state === 'entering' ? slideInFromRight : 'none'}
    0.5s ease;
  transition: transform 0.5s ease-in-out;
  bottom: 100px;
  width: 250px;
  border-radius: 16px;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.text.info};
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'all')};
  overflow: hidden;
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  * {
    pointer-events: ${({ $hidden }) => ($hidden ? 'none !important' : 'all')};
  }
`;

const PanelSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 40px 1fr;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text.default};
  width: 100%;
  padding: 12px 26px 0px;
  border-bottom: 2px solid ${(p) => p.theme.surface.e2};
  gap: 16px;
`;

const PanelSectionPicker = styled(Text)<{ $active?: boolean }>`
  cursor: pointer;
  position: relative;
  padding: 12px 0;
  font-weight: 600;
  color: ${({ $active, theme }) =>
    $active ? theme.text.button : theme.text.secondary};

  &:after {
    cursor: auto;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(p) => (p.$active ? p.theme.white : 'transparent')};
    bottom: -2px;
  }
`;

const StyledPlusIcon = styled(IconPlus)`
  fill: ${({ theme }) => theme.text.default};
  width: 16px;
  height: 16px;
`;

const StyledButton = styled(Button)`
  padding: 0;
  background: transparent;
  justify-self: end;

  &:hover {
    background: ${({ theme }) => theme.surface.e2};
  }
`;

type Props = {
  hidden: boolean;
  state: TransitionStatus;
  drawing: Drawing2dStudio;
  anyOutputLoading?: boolean;
  inferenceSettings: InferenceSettings;
  selectedPromptHistoryItem: PromptHistoryItem;
  uiRatio: number;
  activeLayerId: string | undefined;
  viewerVisibility: Record<string, boolean>;
  layersDisabled: boolean;
  isGeneratingPrompt: boolean;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setActiveLayerId: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  onCreateDrawingFromImage: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    },
    name?: string
  ) => void;
  applyHistoryItem: (inferenceSettings: InferenceSettings) => void;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  triggerPaywallModal: () => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  trigger: () => void;
  triggerAutoPrompt: () => void;
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
  cancelAutoPrompt: () => void;
};

const WorkbenchStudioCombinedUI = (props: Props) => {
  const theme = useTheme();
  const [spring, api] = useSpring(() => ({}));
  const isViewer = useIsWorkbenchViewer();
  const ref = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState<'create' | 'layers'>('create');
  const userPreferredLayout = useCurrentUserClientStateByKey(
    UserClientStateKeys.StudioLayout
  );
  const layout: Layout = isViewer
    ? 'default'
    : userPreferredLayout || 'default';

  useEffect(() => {
    if (!props.selectedPromptHistoryItem?.output.id) return;

    // flash the create tab when a new history item is selected
    api.start({
      from: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 1)`,
      },
      to: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 0)`,
      },
      config: { duration: 700 },
    });
  }, [props.selectedPromptHistoryItem?.output.id]);

  useEffect(() => {
    if (!ref.current) return;
    if (layout !== 'stacked') {
      ref.current.style.height = 'auto';
      return;
    }

    const newHeight = window.innerHeight * (1 - props.uiRatio) - 100;
    ref.current.style.height = `${newHeight}px`;
  }, [props.uiRatio, layout]);

  const addLayer = useStableCallback(() => {
    const id = uuid();
    const selectedIds = props.activeLayerId?.split('/');
    const anchorId =
      selectedIds && selectedIds.length > 1
        ? selectedIds[selectedIds.length - 1]
        : props.activeLayerId;
    props.handleAction({
      type: 'addLayer',
      layer: {
        id: id,
        name: `Layer ${props.drawing.layers.nodes.length + 1}`,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        orderKey: getLayerOrderKey(props.drawing.layers.nodes, anchorId),
      },
    });
    props.setActiveLayerId(id);
  });

  useHotkeys(
    'n',
    ({ repeat }) => {
      if (!repeat && !isViewer) {
        addLayer();
      }
    },
    [isViewer]
  );

  if (isViewer) {
    return null;
  }

  return (
    <StudioTabs
      ref={ref}
      $state={props.state}
      style={spring}
      $hidden={props.hidden}
    >
      <PanelSectionContainer>
        <PanelSectionPicker
          $active={tab === 'create'}
          onClick={() => setTab('create')}
        >
          Create
        </PanelSectionPicker>
        <PanelSectionPicker
          $active={tab === 'layers'}
          onClick={() => setTab('layers')}
        >
          Layers
        </PanelSectionPicker>
        {!isViewer && tab === 'layers' && (
          <RichTooltip trigger="hover" placement="right" padding={29}>
            <RichTooltipTrigger>
              <StyledButton
                size="iconSquared"
                variant="secondary"
                onClick={(e) => {
                  (e.target as HTMLButtonElement).blur();
                  addLayer();
                }}
              >
                <StyledPlusIcon />
              </StyledButton>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              <div>
                New layer <Hotkey>N</Hotkey>
              </div>
            </RichTooltipContent>
          </RichTooltip>
        )}
      </PanelSectionContainer>
      {tab === 'create' && (
        <Create
          drawing={props.drawing}
          anyOutputLoading={props.anyOutputLoading}
          handleAction={props.handleAction}
          inferenceSettings={props.inferenceSettings}
          setInferenceSettings={props.setInferenceSettings}
          trigger={props.trigger}
          triggerAutoPrompt={props.triggerAutoPrompt}
          triggerPaywallModal={props.triggerPaywallModal}
          selectedHistoryPrompt={props.selectedPromptHistoryItem?.prompt}
          applyHistoryItem={props.applyHistoryItem}
          isGeneratingPrompt={props.isGeneratingPrompt}
          cancelAutoPrompt={props.cancelAutoPrompt}
        />
      )}
      {tab === 'layers' && (
        <div style={{ paddingTop: 12, overflow: 'hidden' }}>
          {props.layersDisabled && (
            <Overlay
              style={{ borderRadius: '16px' }}
              onClick={() => props.setSelectedPromptHistoryItem(undefined)}
            />
          )}
          <Layers
            drawing={props.drawing}
            handleAction={props.handleAction}
            activeLayer={props.activeLayerId}
            setActiveLayer={props.setActiveLayerId}
            viewerVisibility={props.viewerVisibility}
            setViewerVisibility={props.setViewerVisibility}
            onCreateDrawingFromImage={props.onCreateDrawingFromImage}
          />
        </div>
      )}
    </StudioTabs>
  );
};

export default WorkbenchStudioCombinedUI;
