import { OrganizationSubscriptionPlan } from './plans-types';

interface PlanFeaturesLimits {
  editorsCountInOrganization: number;
  multiParallelInference: number;
  accessLogs: boolean;
  activityLogs: boolean;
  securitySettings: boolean;
  teamCreation: boolean;
  highDefinitionExport: boolean;
  teamArchival: boolean;
  highQuality3dGenerationLimitPerMonth: number;
}

export const SUBSCRIPTION_PLANS_LIMIT: Record<
  OrganizationSubscriptionPlan,
  PlanFeaturesLimits
> = {
  FREE: {
    accessLogs: false,
    activityLogs: false,
    securitySettings: false,
    editorsCountInOrganization: 2,
    highDefinitionExport: false,
    multiParallelInference: 1,
    teamCreation: false,
    teamArchival: false,
    highQuality3dGenerationLimitPerMonth: 0,
  },
  PRO: {
    accessLogs: false,
    activityLogs: false,
    securitySettings: false,
    editorsCountInOrganization: Infinity,
    highDefinitionExport: true,
    multiParallelInference: 4,
    teamCreation: false,
    teamArchival: false,
    highQuality3dGenerationLimitPerMonth: 50,
  },
  ORGANIZATION: {
    accessLogs: true,
    activityLogs: true,
    securitySettings: true,
    editorsCountInOrganization: Infinity,
    highDefinitionExport: true,
    multiParallelInference: 4,
    teamCreation: true,
    teamArchival: true,
    highQuality3dGenerationLimitPerMonth: 150,
  },
  ENTERPRISE: {
    accessLogs: true,
    activityLogs: true,
    securitySettings: true,
    editorsCountInOrganization: Infinity,
    highDefinitionExport: true,
    multiParallelInference: 4,
    teamCreation: true,
    teamArchival: true,
    highQuality3dGenerationLimitPerMonth: 150,
  },
};

export const featureLimitForOrganizationSubscription = function <
  Feature extends keyof PlanFeaturesLimits
>(
  organization:
    | { subscriptionPlan: OrganizationSubscriptionPlan }
    | null
    | undefined,
  feature: Feature
):
  | { value: PlanFeaturesLimits[Feature]; loading: false }
  | { value: undefined; loading: true } {
  if (organization === null || organization === undefined) {
    return { value: undefined, loading: true };
  }
  return {
    value: SUBSCRIPTION_PLANS_LIMIT[organization.subscriptionPlan][feature],
    loading: false,
  };
};
