import {
  canvasToBlob,
  createCanvas,
  imageDataToBlob,
  imageUrlToBlob,
} from '@vizcom/shared-ui-components';
import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import { BlendMode } from '../../types';
import { psd } from './psd';
import { assertUnreachable, sortByOrderKey } from '@vizcom/shared/js-utils';

function generateBlankImageBlob(
  width: number,
  height: number,
  fill?: string
): Promise<Blob> {
  const { canvas, ctx } = createCanvas(width, height);

  if (fill?.length) {
    ctx.fillStyle = fill;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  } else {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  return canvasToBlob(canvas, 'image/png');
}

export const getLayeredPSD = async (drawing: Drawing2dStudio) => {
  let width: number, height: number;
  if (drawing.width > drawing.height) {
    width = drawing.width;
    height = (drawing.height * width) / drawing.width;
  } else {
    height = drawing.height;
    width = (drawing.width * height) / drawing.height;
  }
  const blendModes = {
    [BlendMode.normal]: 'source-over',
    [BlendMode.multiply]: 'multiply',
  };
  const images = await Promise.all(
    sortByOrderKey(drawing.layers.nodes)
      .reverse()
      .map(async (layer: Drawing2dStudio['layers']['nodes'][0]) => {
        let blob;
        if (!layer.imagePath) {
          blob = await generateBlankImageBlob(width, height, layer.fill);
        } else if (layer.imagePath instanceof Blob) {
          blob = layer.imagePath;
        } else if (layer.imagePath instanceof ImageData) {
          blob = await imageDataToBlob(layer.imagePath);
        } else if (typeof layer.imagePath === 'string') {
          blob = await imageUrlToBlob(layer.imagePath);
        } else {
          assertUnreachable(layer.imagePath);
        }

        return {
          blendMode: blendModes[layer.blendMode as BlendMode] as
            | 'source-over'
            | 'multiply',
          blob,
          opacity: layer.opacity,
          name: layer.name,
        };
      })
  );

  return psd(images, {
    mirrored: false,
    width,
    height,
  });
};
