import styled from 'styled-components';
import { Button, CloseIcon, Text } from '@vizcom/shared-ui-components';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';

export const SignupCTABanner = () => {
  const nodeRef = useRef(null);
  const [isOpen, setIsOpen] = useState(true);

  const { data: currentUser, fetching } = useCurrentUser();

  if (currentUser || fetching) return null;

  return (
    <Transition
      nodeRef={nodeRef}
      in={isOpen}
      timeout={2000}
      unmountOnExit
      appear
    >
      {(state) => (
        <BannerContainer state={state}>
          <span>👋</span>
          <Text>Sign up or log into Vizcom for full access</Text>
          <InlineFlex $gap={4}>
            <CustomButtonHover variant="primary" size="S" as={Link} to="/auth">
              Log in
            </CustomButtonHover>
            <CustomButtonHover variant="primary" size="S" as={Link} to="/auth">
              Sign up
            </CustomButtonHover>
          </InlineFlex>
          <CloseIcon
            color="white"
            onClick={() => setIsOpen(false)}
            style={{
              cursor: 'pointer',
            }}
          />
        </BannerContainer>
      )}
    </Transition>
  );
};

const CustomButtonHover = styled(Button)`
  :hover {
    background: ${({ theme }) => theme.chip.primary.background};
  }
`;

const InlineFlex = styled.div<{ $gap: number }>`
  display: inline-flex;
  gap: ${({ $gap }) => $gap}px;
`;

const transitionStyles: { [key: string]: string } = {
  entering: 'translateY(-75px)',
  entered: 'translateY(-75px)',
  exiting: 'translateY(75px)',
  exited: 'translateY(75px)',
};

const BannerContainer = styled.div<{ state: string }>`
  bottom: -65px; // Starting position to animate from

  color: ${({ theme }) => theme.text.default};
  background: ${({ theme }) => theme.primary.hover};

  // Transition depending on the state
  transform: ${({ state }) => transitionStyles[state]};
  transition: transform 0.4s ease-in-out;

  position: absolute;
  border-radius: 16px;
  max-width: fit-content;
  padding: 8px 12px;
  font-size: 14px;

  // Make sure the banner is on top of everything and clickable
  z-index: 10000000;
  pointer-events: all;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  // Center the banner horizontally
  margin: 0 auto;
  left: 0;
  right: 0;
`;
