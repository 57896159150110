import {
  urqlClient,
  TriggerWorkbenchElementMixMutation,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { addToast, formatErrorMessage } from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { SyncedActionType } from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  RateLimitQuotaDetails,
  WorkbenchEventName,
} from '@vizcom/shared/data-shape';
import { elementById } from '../../utils';
import { MixSourceDrawing } from '../../../components/elements/mix/WorkbenchElementMix';

export const TriggerMixAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'triggerMix';
    elementId: string;
    placeholderId: string;
    sourceDrawings: MixSourceDrawing[];
    x: number;
    y: number;
    width: number;
    height: number;
    zIndex: number;
  }
> = {
  type: 'triggerMix',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementMix') {
      if (elementById(elements, payload.placeholderId)) {
        // server already responded with this element, nothing to do locally
        return;
      }
      elements.push({
        __typename: 'WorkbenchElementPlaceholder',
        updatedAt: '0',
        id: payload.placeholderId,
        type: 'placeholder',
        x: payload.x,
        y: payload.y,
        width: payload.width,
        height: payload.height,
        zIndex: payload.zIndex,
      });
    }
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(TriggerWorkbenchElementMixMutation, {
      input: {
        sourceDrawings: payload.sourceDrawings,
        placeholderId: payload.placeholderId,
        elementId: payload.elementId,
        height: payload.height,
        width: payload.width,
        x: payload.x,
        y: payload.y,
        zIndex: payload.zIndex,
      },
    });
    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0].extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0].extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        addToast(
          `You have been generating too many mix images, please wait ${(
            rateLimitInfo.resetInMs / 1000
          ).toFixed(0)}s before trying again.`,
          {
            type: 'danger',
          }
        );
      } else {
        addToast(`Error using AI service, please retry.`, {
          secondaryText: formatErrorMessage(res.error),
          type: 'danger',
        });
      }
      return;
    }
    trackEvent('Mix Images', {
      type: 'triggerMix',
    });

    publishTrackingEvent({
      type: WorkbenchEventName.MIX_IMAGES,
      data: {
        workbenchIds: [],
      },
    });
  },
};
