import { canvasToBlob, loadBlobToImage } from './resizeImage';
import { assertExists, assertUnreachable } from '@vizcom/shared/js-utils';

export const imageDataToBlob = async (
  imageData: ImageData,
  options: { maxPixels?: number; format?: string } = {}
) => {
  let canvas: HTMLCanvasElement | OffscreenCanvas;
  if (typeof document !== 'undefined') {
    canvas = document.createElement('canvas');
  } else if (typeof OffscreenCanvas !== 'undefined') {
    canvas = new OffscreenCanvas(1, 1);
  } else {
    throw new Error('No canvas implementation available');
  }

  const ctx = canvas.getContext('2d') as
    | OffscreenCanvasRenderingContext2D
    | CanvasRenderingContext2D;
  assertExists(ctx);
  if (
    options.maxPixels &&
    options.maxPixels < imageData.width * imageData.height
  ) {
    const scale = Math.sqrt(
      options.maxPixels / (imageData.width * imageData.height)
    );
    canvas.width = Math.floor(imageData.width * scale);
    canvas.height = Math.floor(imageData.height * scale);
    const imageBitmap = await window.createImageBitmap(
      imageData,
      0,
      0,
      imageData.width,
      imageData.height,
      {
        resizeWidth: canvas.width,
        resizeHeight: canvas.height,
      }
    );
    ctx.drawImage(imageBitmap, 0, 0);
  } else {
    canvas.width = imageData.width;
    canvas.height = imageData.height;
    ctx.putImageData(imageData, 0, 0, 0, 0, canvas.width, canvas.height);
  }

  return await canvasToBlob(canvas, options.format ?? 'image/png');
};

export function createCanvas(
  width: number,
  height: number,
  ctxOptions: CanvasRenderingContext2DSettings = {}
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', ctxOptions);
  if (!ctx) {
    throw new Error('Could not get 2d context from canvas');
  }
  canvas.width = width;
  canvas.height = height;
  return { canvas, ctx };
}

//Apply the alpha values of the mask to the alpha values of the image.
//Mutates the image data to avoid expensive copy.
export function applyMaskToImage(
  image: Uint8ClampedArray,
  mask: Uint8ClampedArray
) {
  for (let i = 0; i < image.length; i += 4) {
    const alpha = mask[i] / 255;
    image[i + 3] = image[i + 3] * alpha;
  }
  return image;
}

export async function imageToCanvas(imageData: ImageData | Blob | string) {
  if (imageData instanceof ImageData) {
    const { canvas, ctx } = createCanvas(imageData.width, imageData.height);
    ctx.putImageData(imageData, 0, 0);
    return { canvas, ctx };
  }

  //Load the image as a blob or use the blob directly
  let blob;
  if (imageData instanceof Blob) {
    blob = imageData;
  } else if (typeof imageData === 'string') {
    const response = await fetch(imageData);
    blob = await response.blob();
  } else {
    assertUnreachable(imageData);
  }
  //Put the blob into a canvas
  const { image, unload } = await loadBlobToImage(blob);
  const { canvas, ctx } = createCanvas(image.width, image.height);
  ctx.drawImage(image, 0, 0);
  unload();
  return { canvas, ctx };
}

export async function imageToBlob(imageData: ImageData | Blob | string) {
  let blob: Blob;
  if (imageData instanceof Blob) {
    blob = imageData;
  } else if (imageData instanceof ImageData) {
    blob = await imageDataToBlob(imageData);
  } else if (typeof imageData === 'string') {
    const response = await fetch(imageData);
    blob = await response.blob();
  } else {
    assertUnreachable(imageData);
  }
  return blob;
}

export async function imageUrlToBlob(url: string) {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  const loadPromise = new Promise<void>((resolve, reject) => {
    image.onerror = reject;
    image.onload = () => resolve();
  });
  image.src = url;
  await loadPromise;
  const { canvas, ctx } = createCanvas(image.width, image.height);
  ctx.drawImage(image, 0, 0);
  return canvasToBlob(canvas, 'image/png');
}
