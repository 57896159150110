import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  color: ${(p) => p.theme.text.default};
  user-select: none;
`;

export const Frame = styled.div<{
  $actions?: 'top' | 'bottom';
}>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ $actions }) =>
    $actions === 'top' ? 'auto auto 1fr' : 'auto 1fr auto'};
  background: ${({ theme }) => theme.surface.e0};
  overflow: hidden;
  border-right: 1px solid ${({ theme }) => theme.surface.e2};
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const HeaderPrimary = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 0.5rem;

  & > * {
    white-space: nowrap;
  }
`;

export const HeaderSecondary = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: auto;
  }
`;

export const Section = styled.div`
  align-items: center;
`;

export const Field = styled.div<{
  $color?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 16px;
  background-color: ${({ theme, $color }) => $color ?? theme.surface.e0};
  z-index: 100;
`;

export const HeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.tertiary.default};
  border-radius: 4px;
`;

export const Content = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 100%;
  min-height: 1px;
  height: 1px;
  background: ${({ theme }) => theme.surface.e2};
`;

export const MissingLabel = styled.i`
  opacity: 0.3;
`;
