export function isOutdatedElectron() {
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.includes('electron/0.4.6')
  ) {
    // this electron app is an old version that doesn't support auto-update, in this case we want to display
    // a message to ask the user to download the latest version
    return true;
  }

  return false;
}
