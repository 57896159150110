import {
  urqlClient,
  UpdateLayerMutation,
  DeleteLayerMutation,
} from '@vizcom/shared/data-access/graphql';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { UnmergeLayersAction } from './unMergeLayers';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

export const MergeLayersAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'mergeLayers';
    targetLayerId: string;
    sourceLayersId: string[];
    mergedImage: ImageData;
  }
> = {
  type: 'mergeLayers',
  optimisticUpdater: ({ payload }, drawing) => {
    const targetLayer = drawing.layers.nodes.find(
      (layer) => layer.id === payload.targetLayerId
    );
    if (!targetLayer) {
      return;
    }
    targetLayer.imagePath = payload.mergedImage;
    drawing.layers.nodes = drawing.layers.nodes.filter(
      (layer) => !payload.sourceLayersId.includes(layer.id)
    );
  },
  remoteUpdater: async ({ payload, meta }, drawingId) => {
    const resUpdateLayer = await urqlClient.mutation(UpdateLayerMutation, {
      id: payload.targetLayerId,
      patch: {
        imagePath: await imageDataToBlob(payload.mergedImage),
      },
    });
    if (resUpdateLayer?.error) {
      throw new Error(
        `Error while merging layers, please retry. ${
          resUpdateLayer.error.graphQLErrors[0]?.message ??
          resUpdateLayer.error.message
        }`
      );
    }

    const deletes = payload.sourceLayersId.map((id) =>
      urqlClient.mutation(DeleteLayerMutation, {
        id,
      })
    );

    const res = await Promise.all(deletes);

    if (res.some((r) => r?.error)) {
      throw new Error(
        `Error while merging layers, please retry. ${res.map(
          (r) => r?.error?.graphQLErrors[0]?.message ?? r?.error?.message
        )}`
      );
    }
  },
  undoConstructor: ({ payload }, drawing) => {
    const sourceLayers = drawing.layers.nodes
      .filter((layer) => payload.sourceLayersId.includes(layer.id))
      .map((layer) => ({ ...layer, anchor: payload.targetLayerId }));

    const targetLayer = drawing.layers.nodes.find(
      (layer) => layer.id === payload.targetLayerId
    );
    if (!targetLayer) {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof UnmergeLayersAction> =
      {
        type: 'unmergeLayers',
        targetLayerId: payload.targetLayerId,
        sourceLayers,
        mergedImage: payload.mergedImage,
        originalImage: targetLayer.imagePath,
      };

    return undoPayload;
  },
};
