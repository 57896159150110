import { useRef } from 'react';

export const useLastNonNullValue = <T>(value: T | null): T | null => {
  const lastNonNullValue = useRef<T | null>(value);

  if (value) {
    lastNonNullValue.current = value;
  }
  return lastNonNullValue.current;
};
