import {
  OrganizationData,
  OrganizationSubscriptionPlan,
  Team,
  TeamRole,
  useCurrentUser,
  useDeleteUsersOnTeam,
  useJoinTeam,
  useUpdateTeam,
} from '@vizcom/shared/data-access/graphql';
import {
  TableRow,
  TableCell,
  TeamAvatar,
  Chip,
  CheckIcon,
  ContextMenu,
  ContextMenuItem,
  ContextMenuDivider,
  MenuHorizontalIcon,
  addToast,
  LoadingLogoInset,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { Link } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import { useCanEditOrganization } from '../useCanEditOrganization';
import { useState } from 'react';
import { ConfirmDeleteTeamModal } from './ConfirmDeleteTeamModal';
import { ConfirmArchiveTeamModal } from './ConfirmArchiveTeamModal';
import { SUBSCRIPTION_PLANS_LIMIT } from '@vizcom/shared/plans-limit';

export const OrganizationTeamRow = (props: {
  team: Team & {
    usersOnTeamsByTeamId: { totalCount: number };
    invites: {
      totalCount: number;
    };
  };
  organization: OrganizationData;
}) => {
  const { team, organization } = props;
  const canEdit = useCanEditOrganization(props.organization.id);
  const { data: currentUser } = useCurrentUser();
  const [joinTeamRes, joinTeam] = useJoinTeam();
  const [deleteUsersOnTeamRes, deleteUsersOnTeam] = useDeleteUsersOnTeam();
  const [updateTeamRes, updateTeam] = useUpdateTeam();

  const handleJoin = async () => {
    const res = await joinTeam({
      input: {
        teamId: team.id,
      },
    });

    if (res.error) {
      return addToast('Error while joining team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Successfully joined team');
  };

  const handleLeaveTeam = async () => {
    const res = await deleteUsersOnTeam({
      input: {
        teamId: team.id,
        userId: currentUser!.id,
      },
    });
    if (res.error) {
      return addToast('Error while leaving team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast(`Successfully left team`);
  };
  const handleUnarchiveTeam = async () => {
    const res = await updateTeam({
      input: {
        id: team.id,
        patch: {
          archived: false,
        },
      },
    });
    if (res.error) {
      return addToast('There was an error while un-archiving the team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Team restored');
  };

  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [showArchiveTeamModal, setShowArchiveTeamModal] = useState(false);

  const memberCount =
    team.usersOnTeamsByTeamId.totalCount + team.invites.totalCount;

  return (
    <TableRow
      style={{
        opacity: team.archived ? 0.7 : 1,
        position: 'relative',
      }}
    >
      <TableCell
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
        }}
      >
        <TeamAvatar size="medium" name={team.name} teamId={team.id} />
        <p
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            flex: 1,
            flexBasis: 0,
          }}
        >
          {team.name}
        </p>
        {team.currentUserRole && (
          <Chip>
            <CheckIcon style={{ marginRight: 4 }} />
            Joined
          </Chip>
        )}

        {team.archived && <Chip>Archived</Chip>}
      </TableCell>

      {team.globalInOrganization ? (
        <TableCell>All workspace members</TableCell>
      ) : (
        <TableCell>
          {memberCount} member
          {memberCount > 1 ? 's' : ''}
        </TableCell>
      )}

      <TableCell>
        {team.publicInOrganization ? 'All Workspace' : 'Private'}
      </TableCell>

      <TableCell>
        <ContextMenu
          buttonProps={{
            variant: 'transparent',
            size: 'icon',
          }}
          items={
            <>
              {!team.globalInOrganization && canEdit && (
                <>
                  <ContextMenuItem
                    as={Link}
                    to={paths.settings.organization.teamInvite(
                      organization.id,
                      team.id
                    )}
                  >
                    Add members
                  </ContextMenuItem>
                  <ContextMenuDivider />
                </>
              )}
              <ContextMenuItem
                as={Link}
                to={paths.settings.organization.teamSettings(
                  organization.id,
                  team.id
                )}
              >
                Team Settings
              </ContextMenuItem>
              {team.currentUserRole && !team.globalInOrganization && (
                <ContextMenuItem onClick={handleLeaveTeam}>
                  Leave Team
                </ContextMenuItem>
              )}
              {!team.currentUserRole && (
                <ContextMenuItem onClick={handleJoin}>
                  Join Team
                </ContextMenuItem>
              )}
              {organization.currentUserRole === TeamRole.Admin && (
                <>
                  {SUBSCRIPTION_PLANS_LIMIT[
                    team.organization?.subscriptionPlan ??
                      OrganizationSubscriptionPlan.Free
                  ].teamArchival &&
                    (team.archived ? (
                      <ContextMenuItem onClick={handleUnarchiveTeam}>
                        Unarchive team
                      </ContextMenuItem>
                    ) : (
                      <ContextMenuItem
                        onClick={() => setShowArchiveTeamModal(true)}
                        color="danger"
                      >
                        Archive team...
                      </ContextMenuItem>
                    ))}
                  {!team.globalInOrganization && (
                    <ContextMenuItem
                      color="danger"
                      onClick={() => setShowDeleteTeamModal(true)}
                    >
                      Delete Team...
                    </ContextMenuItem>
                  )}
                </>
              )}
            </>
          }
        >
          <MenuHorizontalIcon />
        </ContextMenu>
      </TableCell>
      <LoadingLogoInset
        active={
          joinTeamRes.fetching ||
          updateTeamRes.fetching ||
          deleteUsersOnTeamRes.fetching
        }
      />
      <ConfirmDeleteTeamModal
        isOpen={showDeleteTeamModal}
        setIsOpen={setShowDeleteTeamModal}
        team={team}
      />
      <ConfirmArchiveTeamModal
        isOpen={showArchiveTeamModal}
        setIsOpen={setShowArchiveTeamModal}
        team={team}
      />
    </TableRow>
  );
};
