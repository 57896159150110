import { useLocation } from 'react-router-dom';

export const useRegisterToken = () => {
  const { hash } = useLocation();

  if (!hash.startsWith('#token=')) {
    return null;
  }

  const token = hash.slice('#token='.length);

  let body = null as null | { email: string; teamId?: string };

  try {
    body = JSON.parse(decodeURIComponent(window.atob(token.split('.')[1])));
    // eslint-disable-next-line no-empty
  } catch {}

  return { token, body };
};
