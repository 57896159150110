import { paths } from '@vizcom/shared-utils-paths';
import { MouseEventHandler, MutableRefObject, useRef, useState } from 'react';
import { WorkbenchBasicData } from '@vizcom/shared/data-access/graphql';
import { Content, Thumbnail, ThumbnailContainer, Wrapper } from './File.styled';

import {
  ContextMenu,
  ContextMenuDivider,
  ContextMenuItem,
  copySharedLinkToClipboard,
  DuplicateWorkbenchModal,
  isPublicSharingEnabled,
  MenuHorizontalIcon,
  Text,
} from '@vizcom/shared-ui-components';
import { RenameModal } from './RenameModal';
import { RelativeDate } from '../../Dates';
import styled from 'styled-components';
import {
  SelectableItem,
  useSelectedItems,
} from '../../Folder/SelectedItemsProvider';
import { Link } from 'react-router-dom';

const MenuVerticalIcon = styled(MenuHorizontalIcon)`
  transform: rotate(90deg);
  opacity: 0.5;
`;

const FileName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  height: 18px;
`;

const Delimiter = styled.span`
  margin: 0 0.2rem;
`;

type FileProps = {
  file: WorkbenchBasicData;
  index: number;
};

export const File = (props: FileProps) => {
  const { file } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const {
    selectedItems,
    setSelectedItems,
    setMultipleDeleteModalOpen,
    setMultipleMoveModalOpen,
  } = useSelectedItems();

  const editorUrl = paths.workbench.file(file.id);
  const handleOpenInNewTab = () => {
    window.open(editorUrl, '_blank');
  };

  const handleDeleteClick: MouseEventHandler = (e) => {
    e.preventDefault();
    if (!hasSelectedItems) {
      setSelectedItems([
        { type: 'workbench', id: file.id, index: props.index },
      ]);
    }
    setMultipleDeleteModalOpen(true);
  };

  const isSelected = !!selectedItems.find(({ id }) => id === file.id);
  const hasSelectedItems = selectedItems.length > 1;
  const publicSharingEnabled = isPublicSharingEnabled(file);

  return (
    <>
      <SelectableItem
        type="workbench"
        id={file.id}
        index={props.index}
        ref={containerRef}
      >
        <Wrapper $selected={isSelected} $logoBackground>
          <ThumbnailContainer>
            {file.thumbnail && (
              <Thumbnail crossOrigin="anonymous" src={file.thumbnail} />
            )}
          </ThumbnailContainer>

          <Content>
            <FileName type="sh1" color="default" style={{ marginBottom: 4 }}>
              {file.name}
            </FileName>
            <Text type="b2" color="info">
              {file.lastUpdatedBy?.name ??
                file.lastUpdatedBy?.email ??
                'Edited'}
              <Delimiter>&#183;</Delimiter>
              <RelativeDate date={file.updatedAt} />
            </Text>
          </Content>

          {(!hasSelectedItems || isSelected) && (
            <ContextMenu
              buttonProps={{
                variant: 'transparentNoHover',
                size: 'icon',
                style: {
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  zIndex: 3,
                  display: hasSelectedItems && 'none',
                },
              }}
              items={
                <>
                  {!hasSelectedItems && (
                    <>
                      <ContextMenuItem
                        as={Link}
                        to={editorUrl}
                        onClick={handleOpenInNewTab}
                      >
                        Open
                      </ContextMenuItem>
                      <ContextMenuItem onClick={handleOpenInNewTab}>
                        Open in new tab
                      </ContextMenuItem>
                      {publicSharingEnabled && (
                        <ContextMenuItem
                          onClick={() => copySharedLinkToClipboard(file)}
                        >
                          Copy public link
                        </ContextMenuItem>
                      )}
                      <ContextMenuDivider />
                      <ContextMenuItem
                        onClick={() => {
                          setDuplicateModalOpen(true);
                        }}
                      >
                        Duplicate
                      </ContextMenuItem>
                    </>
                  )}
                  {file.isEditable && (
                    <>
                      <ContextMenuItem
                        onClick={() => {
                          if (!hasSelectedItems) {
                            setSelectedItems([
                              {
                                type: 'workbench',
                                id: file.id,
                                index: props.index,
                              },
                            ]);
                          }
                          setMultipleMoveModalOpen(true);
                        }}
                      >
                        Move
                      </ContextMenuItem>
                      {!hasSelectedItems && (
                        <ContextMenuItem
                          onClick={() => setRenameModalOpen(true)}
                        >
                          Rename
                        </ContextMenuItem>
                      )}
                      <ContextMenuDivider />
                      <ContextMenuItem onClick={handleDeleteClick}>
                        Delete
                      </ContextMenuItem>
                    </>
                  )}
                  {hasSelectedItems && !file.isEditable && (
                    <ContextMenuItem>No editorial permissions</ContextMenuItem>
                  )}
                </>
              }
              parentRef={containerRef as MutableRefObject<HTMLElement>}
            >
              <MenuVerticalIcon />
            </ContextMenu>
          )}
        </Wrapper>
      </SelectableItem>
      <DuplicateWorkbenchModal
        isOpen={duplicateModalOpen && !hasSelectedItems}
        setIsOpen={setDuplicateModalOpen}
        workbenchId={file.id}
      />
      <RenameModal
        isOpen={renameModalOpen}
        setIsOpen={setRenameModalOpen}
        workbenchId={file.id}
        previousName={file.name || ''}
      />
    </>
  );
};
