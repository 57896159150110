import styled from 'styled-components';
import { HtmlOverlay } from '../utils/HtmlOverlay';
import { PropsWithChildren } from 'react';
import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { SelectedPresenceWindowIndicator } from './SelectedPresenceWindowIndicator';
import {
  WorkbenchToolbarDivider,
  WorkbenchToolbarContainer,
} from '../toolbar/styles';
import { LargeListMultiplayerPresencesButton } from './LargeListMultiplayerPresencesButton';
import {
  MultiplayerPresenceIndicator,
  OwnPresenceIndicator,
} from './MultiplayerPresenceIndicator';
import { CollaborationToolbarSubscriptionPlanIndicator } from './CollaborationToolbarSubscriptionPlanIndicator';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';

export const WorkbenchCollaborationToolbar = (
  props: PropsWithChildren<{
    workbenchId: string;
    selectedPresence: MultiplayerPresence | undefined;
    setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
    selfPresence: MultiplayerPresence | null;
    multiplayerPresences: MultiplayerPresence[];
  }>
) => {
  const { data: user } = useCurrentUser();

  return (
    <>
      <HtmlOverlay>
        {props.selectedPresence && (
          <SelectedPresenceWindowIndicator presence={props.selectedPresence} />
        )}
        <ToolbarContainer>
          <PresenceContainer>
            {props.multiplayerPresences.length > 4 ? (
              <LargeListMultiplayerPresencesButton
                selfPresence={props.selfPresence}
                multiplayerPresences={props.multiplayerPresences}
                setSelectedPresenceId={props.setSelectedPresenceId}
                selectedPresence={props.selectedPresence}
              />
            ) : (
              <>
                {props.selfPresence && (
                  <OwnPresenceIndicator selfPresence={props.selfPresence} />
                )}
                {props.multiplayerPresences.slice(0, 4).map((presence) => (
                  <MultiplayerPresenceIndicator
                    key={presence.id}
                    presence={presence}
                    onSelect={() => props.setSelectedPresenceId(presence.id)}
                    selected={presence === props.selectedPresence}
                  />
                ))}
              </>
            )}
          </PresenceContainer>

          {user && (
            <>
              <Spacer />
              <WorkbenchToolbarDivider />
              <CollaborationToolbarSubscriptionPlanIndicator
                workbenchId={props.workbenchId}
              />
            </>
          )}
          {props.children}
        </ToolbarContainer>
      </HtmlOverlay>
    </>
  );
};

const PresenceContainer = styled.div`
  min-width: 100px;
  display: flex;
  gap: 4px;
`;

const ToolbarContainer = styled(WorkbenchToolbarContainer)`
  position: absolute;
  top: 1rem;
  right: 14px;
  color: ${({ theme }) => theme.text.default};
  z-index: 10000000;
  display: flex;
  align-items: center;
  width: 250px;
  justify-content: space-between;
`;
const Spacer = styled.div`
  flex: 1;
`;
