import {
  urqlClient,
  CreateWorkbenchMeshFromDrawingMutation,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { addToast } from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { SyncedActionType } from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  RateLimitQuotaDetails,
  Inference3dEventName,
} from '@vizcom/shared/data-shape';
import { elementById } from '../../utils';

export const CreateWorkbenchMeshFromDrawingAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'createWorkbenchMeshFromDrawingAction';
    drawingId: string;
    placeholderId: string;
    x: number;
    y: number;
    width: number;
    height: number;
    zIndex: number;
  }
> = {
  type: 'createWorkbenchMeshFromDrawingAction',
  optimisticUpdater: ({ payload }, elements) => {
    const drawing = elementById(elements, payload.drawingId);
    if (!drawing || drawing.__typename !== 'Drawing') {
      return;
    }

    elements.push({
      __typename: 'WorkbenchElementPlaceholder',
      updatedAt: '0',
      id: payload.placeholderId,
      type: 'placeholder',
      x: payload.x,
      y: payload.y,
      width: payload.width,
      height: payload.height,
      zIndex: payload.zIndex,
      loadingImagePath:
        typeof drawing.thumbnailPath === 'string'
          ? drawing.thumbnailPath
          : undefined,
    });
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(
      CreateWorkbenchMeshFromDrawingMutation,
      {
        input: {
          placeholderId: payload.placeholderId,
          drawingId: payload.drawingId,
          height: payload.height,
          width: payload.width,
          x: payload.x,
          y: payload.y,
          zIndex: payload.zIndex,
        },
      }
    );
    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0].extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0].extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        addToast(
          `You have been generating too many 3D models, please wait ${(
            rateLimitInfo.resetInMs / 1000
          ).toFixed(0)}s before trying again.`,
          {
            type: 'danger',
          }
        );
      } else {
        throw new Error(
          `Error using AI service, please retry. ${
            res.error.graphQLErrors[0]?.message ?? res.error.message
          }`
        );
      }
      return;
    }
    trackEvent('Generate Mesh', {
      type: 'createWorkbenchMeshFromDrawing',
    });
    publishTrackingEvent({
      type: Inference3dEventName.GENERATE_MESH_FROM_DRAWING,
      data: {
        source: 'WORKBENCH',
        drawingId: payload.drawingId,
        qualityType: 'BASIC',
      },
    });
  },
};
