export const textColors = [
  '#000000',
  '#FE4747',
  '#FEB547',
  '#60C03E',
  '#167EF9',
  '#9644FF',
  '#FE47CB',
  '#B3B3B3',
  '#E6E6E6',
  '#757575',
  '#BEEF8D',
  '#8BBFFD',
  '#DABCFF',
];

export const workbenchCameraPositionStorageKey = (workbenchId: string) =>
  `wb-cam-${workbenchId}`;

export const activeElementTypes = [
  'Drawing',
  'CompositeScene',
  'WorkbenchElementPlaceholder',
];

// This defined the rendering order of each WebGL render pass
// this is used for some `useFrame` hooks
export const WORKBENCH_CONTENT_RENDERING_ORDER = [
  // 2D studio:
  'individualLayerTexture', // first render each layer in their own texture with <LayerTextureRenderer />
  'layersComposition', // then compose all layers together with <LayersCompositor />

  // Global workbench
  'dotBackground', // render the dot background with <BackgroundLayer />
  'globalScene', // render the global scene on top of the background layer with <SceneLayer />
] as const;

export const WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT = 1920 * 1080;
