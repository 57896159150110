import { Navigate, Route, Routes } from 'react-router-dom';

import { Team } from './components/Team/Team';

import { Folder } from './components/Folder/Folder';
import { Layout } from '@vizcom/shared-ui-components';
import { DraftsPage } from './components/Drafts/DraftsPage';
import { RecentFilesPage } from './components/RecentFilesPage/RecentFilesPage';
import { PocBanner } from './components/Banners/PocBanner';

export const Dashboard = () => {
  return (
    <Layout>
      <PocBanner />
      <Routes>
        <Route path="team/:team_id" element={<Team />} />
        <Route
          path="organization/:organizationId/drafts"
          element={<DraftsPage />}
        />
        <Route path="folder/:folderId" element={<Folder />} />
        <Route path=":organizationId/recent" element={<RecentFilesPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Layout>
  );
};

export default Dashboard;
