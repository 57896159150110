// @dani
// 2D transformation

export const rotate = /* glsl */ `
vec2 rotate(const in vec2 p, const in float r) {
  if (r == 0.0) {
    return p;
  }
  float c = cos(r);
  float s = sin(r);
  return mat2(c, s, -s, c) * p;
}
`;

// @dani
// SDF primitives from: https://iquilezles.org/articles/distfunctions2d/

export const sdBox = /* glsl */ `
float sdBox(const in vec2 p, const in vec2 b) {
  vec2 d = abs(p)-b;
  return length(max(d,0.0)) + min(max(d.x,d.y),0.0);
}
`;

export const sdCircle = /* glsl */ `
float sdCircle(const in vec2 p, const in float r) {
  return length(p) - r;
}
`;

export const sdCross = /* glsl */ `
float sdCross(in vec2 p, const in vec2 b, const float r) {
  p = abs(p); p = (p.y>p.x) ? p.yx : p.xy;
  vec2  q = p - b;
  float k = max(q.y,q.x);
  vec2  w = (k>0.0) ? q : vec2(b.y-p.x,-k);
  return sign(k)*length(max(w,0.0)) + r;
}
`;

export const sdEllipse = /* glsl */ `
float sdEllipse(in vec2 p, in vec2 ab) {
  p = abs(p); if (p.x > p.y) {p=p.yx;ab=ab.yx;}
  float l = ab.y*ab.y - ab.x*ab.x;
  float m = ab.x*p.x/l;      float m2 = m*m;
  float n = ab.y*p.y/l;      float n2 = n*n;
  float c = (m2+n2-1.0)/3.0; float c3 = c*c*c;
  float q = c3 + m2*n2*2.0;
  float d = c3 + m2*n2;
  float g = m + m*n2;
  float co;
  if (d < 0.0) {
    float h = acos(q/c3)/3.0;
    float s = cos(h);
    float t = sin(h)*sqrt(3.0);
    float rx = sqrt(-c*(s + t + 2.0) + m2);
    float ry = sqrt(-c*(s - t + 2.0) + m2);
    co = (ry+sign(l)*rx+abs(g)/(rx*ry)- m)/2.0;
  } else {
    float h = 2.0*m*n*sqrt(d);
    float s = sign(q+h)*pow(abs(q+h), 1.0/3.0);
    float u = sign(q-h)*pow(abs(q-h), 1.0/3.0);
    float rx = -s - u - c*4.0 + 2.0*m2;
    float ry = (s - u)*sqrt(3.0);
    float rm = sqrt(rx*rx + ry*ry);
    co = (ry/sqrt(rm-rx)+2.0*g/rm-m)/2.0;
  }
  vec2 r = ab * vec2(co, sqrt(1.0-co*co));
  return length(r-p) * sign(p.y-r.y);
}
`;

export const sdSegment = /* glsl */ `
float sdSegment(const in vec2 p, const in vec2 a, const in vec2 b) {
  vec2 pa = p-a, ba = b-a;
  float h = clamp(dot(pa,ba)/dot(ba,ba), 0.0, 1.0);
  return length(pa - ba*h);
}
`;

export const straightAlphaBlending = /*glsl */ `
  vec4 straightAlphaBlending(vec4 dst, vec4 src ) {
    float alpha = 1.0 - (1.0 - dst.a) * (1.0 - src.a);
    if(alpha == 0.0){
      return vec4(0.0);
    }
    return vec4(
      (dst.rgb * dst.a * (1.0 - src.a) + src.rgb * src.a) / alpha,
      alpha
    );
  }
`;

export const cross2d = /*glsl */ `
  float cross2d(vec2 a, vec2 b ) { return a.x*b.y - a.y*b.x; }
`;
