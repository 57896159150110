import {
  DeleteLayerMutation,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { AddLayerAction } from './addLayer';

export const DeleteLayerAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'deleteLayer';
    id: string;
  }
> = {
  type: 'deleteLayer',
  optimisticUpdater: ({ payload }, drawing) => {
    drawing.layers.nodes = drawing.layers.nodes.filter(
      (l) => payload.id !== l.id
    );
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(DeleteLayerMutation, {
      id: payload.id,
    });
    if (res?.error) {
      if (
        res.error.graphQLErrors[0]?.message?.includes(
          'No values were deleted in collection'
        )
      ) {
        // layer was already deleted by another user, nothing to do here
        return;
      }
      throw new Error(
        `Error while deleting layer, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  undoConstructor: ({ payload }, drawing) => {
    const layer = drawing.layers.nodes.find((l) => l.id === payload.id);

    if (!layer) {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof AddLayerAction> = {
      type: 'addLayer',
      layer: {
        ...layer,
        image: layer.imagePath,
      },
    };

    return undoPayload;
  },
};
