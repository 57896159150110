import {
  OrganizationInvitesData,
  OrganizationMembersData,
  TeamRole,
  useCreateOrganizationInvite,
  useOrganizationMembers,
  useOrganizationTeamsUsers,
} from '@vizcom/shared/data-access/graphql';
import styled from 'styled-components';
import {
  Button,
  EmailMultiInput,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  addToast,
  styledScrollbar,
  Table,
  TableHeader,
  Text,
  Surface,
  useSelectedOrganization,
  FullPageDarkLoader,
  Tooltip,
  TableCell,
  InfoIcon,
  downloadCSV,
  Dropdown,
  TextInput,
  Checkbox,
  SelectionCell,
  useCrisp,
  bottomScreenTunnel,
} from '@vizcom/shared-ui-components';
import { useState } from 'react';
import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from '../components/SettingsPageLayout';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { InvitedMemberRow } from './invitedMemberRow';
import { OrganizationMemberRow } from './OrganizationMemberRow';
import { useCanEditOrganization } from '../useCanEditOrganization';
import { MembersListFilters } from './MembersListFilters';
import { MemberFiltersType, MemberOrderBy } from './memberFiltersType';
import { assertUnreachable } from '@vizcom/shared/js-utils';
import { paths } from '@vizcom/shared-utils-paths';
import { isEqual, lowerCase, without } from 'lodash';
import { SUBSCRIPTION_PLANS_LIMIT } from '@vizcom/shared/plans-limit';
import { useDebouncedValue } from '@vizcom/shared-utils-hooks';
import { TableSelectionToolbar } from './TableSelectionToolbar';
import { showLimitationErrorToast } from './limitationToastMessage';

export const MEMBERS_TABLE_SIZING = {
  totalColumns: 16,
  name: 4,
  dateAdded: 3,
  lastActive: 3,
  teams: 3,
  role: 3,
};

const EmailInputContainer = styled.div`
  padding: 11px 13px;
  border-radius: 8px;
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  background-color: ${(p) => p.theme.surface.e0};
  ${styledScrollbar}
`;

export type OrganizationMember = OrganizationMembersData['members']['edges'][0];
export type OrganizationInvite =
  OrganizationInvitesData['organizationInvites']['nodes'][0];
export type OrganizationInviteOrMember =
  | OrganizationInvite
  | OrganizationMember;

const teamRoleCanEdit = (role: TeamRole) =>
  role === TeamRole.Editor || role === TeamRole.Admin;

export const isElemMember = (
  elem: OrganizationMember | OrganizationInvite
): elem is OrganizationMember => {
  return 'node' in elem;
};

export const isElemInvite = (
  elem: OrganizationMember | OrganizationInvite
): elem is OrganizationInvite => {
  return 'email' in elem;
};

export const getEmailFromInviteOrMember = (
  inviteOrMember: OrganizationInviteOrMember
) => {
  return isElemMember(inviteOrMember)
    ? inviteOrMember.node.email
    : inviteOrMember.email;
};

const defaultFilters: MemberFiltersType = {
  orderBy: MemberOrderBy.createdAtAsc,
  role: undefined,
  status: undefined,
};

export const OrganizationMembers = (props: { openInviteMembers: boolean }) => {
  const navigate = useNavigate();
  const crisp = useCrisp();

  const { data: organization, loading } = useSelectedOrganization();

  const canEdit = useCanEditOrganization(organization?.id);
  const { data, fetching } = useOrganizationMembers(organization?.id);
  const { data: organizationTeamsUsers } = useOrganizationTeamsUsers(
    organization?.id
  );
  const [createInviteRes, createInvite] = useCreateOrganizationInvite();

  const [inviteEmailsValue, setInviteEmailsValue] = useState([] as string[]);
  const [inviteRole, setInviteRole] = useState<TeamRole>(TeamRole.Viewer);
  const [filters, setFilters] = useState<MemberFiltersType>(defaultFilters);
  const [selection, setSelection] = useState<string[]>([]);

  const [debouncedSearchText, searchText, setSearchText] = useDebouncedValue(
    '',
    500
  );

  const includeJoined = !(filters.status === 'pending');
  const includeInvited = !(filters.status === 'joined');

  const mixedInvitesAndMembers = [
    ...((includeJoined && data?.members.edges) || []),
    ...((includeInvited && data?.organizationInvites?.nodes) || []),
  ];

  const filteredInvitesOrMembers = mixedInvitesAndMembers
    .filter((inviteOrMember) => {
      const searchActive = debouncedSearchText !== '';
      const isMember = isElemMember(inviteOrMember);

      const filterRole = inviteOrMember.role === filters.role;

      const email = isMember ? inviteOrMember.node.email : inviteOrMember.email;
      const name = isMember
        ? inviteOrMember.node.name?.toLowerCase()
        : undefined;

      const lowerCaseSearch = debouncedSearchText.toLowerCase();
      const filterSearchEmail = searchActive && email.includes(lowerCaseSearch);
      const filterSearchName =
        searchActive && isMember && name?.includes(lowerCaseSearch);

      return (
        (filters.role ? filterRole : true) &&
        (searchActive ? filterSearchEmail || filterSearchName : true)
      );
    })
    .sort((a, b) => {
      const aIsMember = isElemMember(a);
      const bIsMember = isElemMember(b);

      const { createdAt: aCreatedAt, email: aEmail } = aIsMember ? a.node : a;
      const { createdAt: bCreatedAt, email: bEmail } = bIsMember ? b.node : b;

      const undefinedLastActive = '2000-01-01'; // Make sure undefined is sorted last
      const aLastActiveTimestamp = new Date(
        (aIsMember && a.node.lastActive) || undefinedLastActive
      ).getTime();
      const bLastActiveTimestamp = new Date(
        (bIsMember && b.node.lastActive) || undefinedLastActive
      ).getTime();

      switch (filters.orderBy) {
        case MemberOrderBy.createdAtAsc:
          return aCreatedAt.localeCompare(bCreatedAt);
        case MemberOrderBy.createdAtDesc:
          return bCreatedAt.localeCompare(aCreatedAt);
        case MemberOrderBy.emailAsc:
          return aEmail.localeCompare(bEmail);
        case MemberOrderBy.emailDesc:
          return bEmail.localeCompare(aEmail);
        case MemberOrderBy.lastActiveAsc:
          return aLastActiveTimestamp - bLastActiveTimestamp;
        case MemberOrderBy.lastActiveDesc:
          return bLastActiveTimestamp - aLastActiveTimestamp;
        default:
          assertUnreachable(filters.orderBy);
      }
    });

  const filteredSelection = selection.filter((email) =>
    filteredInvitesOrMembers.some(
      (inviteOrMember) => getEmailFromInviteOrMember(inviteOrMember) === email
    )
  );

  const filtersAreEmpty =
    isEqual(filters, defaultFilters) && debouncedSearchText === '';

  const handleConfirm = async () => {
    const erroredEmails = [];
    const successfulEmails = [];

    let limitationReachedError: string | undefined = undefined;

    for (const email of inviteEmailsValue) {
      // Could be improved to only use one mutation for everything instead of a loop here
      const res = await createInvite({
        input: {
          organizationInvite: {
            email,
            organizationId: organization!.id,
            role: inviteRole,
          },
        },
      });

      if (res.error) {
        const graphQLError = res.error.graphQLErrors[0];
        const errorMessage = graphQLError.message || 'Unknown error';
        const errorCode = (
          graphQLError.extensions.exception as { code?: string }
        )?.code;

        if (errorCode === 'NUNIQ') {
          addToast(`${email} already has been invited to this workspace`, {
            type: 'danger',
          });
        } else if (
          errorCode === 'MANUAL_LIMITATION_ERROR' ||
          errorCode === 'PLAN_LIMITATION_ERROR'
        ) {
          limitationReachedError = errorCode;
        } else {
          addToast(`Error while inviting ${email}`, {
            secondaryText: errorMessage,
            type: 'danger',
          });
        }

        erroredEmails.push(email);
      } else {
        successfulEmails.push(email);
      }
    }

    setInviteEmailsValue(erroredEmails);

    if (erroredEmails.length === 0) {
      navigate(paths.settings.organization.members(organization!.id), {
        replace: true,
      });

      if (successfulEmails.length > 0) {
        // if tried to send an invite to an existing member, we shouldn't show anything here
        addToast('Invites sent');
      }
    }

    if (limitationReachedError) {
      showLimitationErrorToast(
        limitationReachedError,
        organization!.id,
        navigate,
        crisp.openChat
      );
    }
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const handleExportButtonClick = () => {
    const nonNullMembersData = filteredInvitesOrMembers.map(
      (inviteOrMember) => {
        const isMember = isElemMember(inviteOrMember);
        const role = inviteOrMember.role.toString();

        return isMember
          ? {
              role,
              name: inviteOrMember.node.name || '',
              dateAdded: inviteOrMember.node.createdAt,
              lastActive: inviteOrMember.node.lastActive,
              email: inviteOrMember.node.email,
              status: 'joined',
            }
          : {
              role,
              name: inviteOrMember.email,
              dateAdded: inviteOrMember.createdAt,
              lastActive: '',
              email: inviteOrMember.email,
              status: 'pending',
            };
      }
    );

    downloadCSV(nonNullMembersData, 'Members');
  };

  if (fetching || loading) {
    return <FullPageDarkLoader />;
  }

  if (!organization) {
    return <Navigate to="/" replace />;
  }

  const getRoleCountFromSource = (source: TeamRole[]) => ({
    [TeamRole.Admin]: source.filter((r) => r === TeamRole.Admin).length,
    [TeamRole.Editor]: source.filter((r) => r === TeamRole.Editor).length,
    [TeamRole.Viewer]: source.filter((r) => r === TeamRole.Viewer).length,
  });

  const membersSources = data?.members.edges.map((e) => e.role) ?? [];
  const invitationsSources =
    data?.organizationInvites?.nodes.map((e) => e.role) ?? [];
  const roleCountFromMembers = getRoleCountFromSource(membersSources);
  const roleCountFromInvitations = getRoleCountFromSource(invitationsSources);

  const totalEditorCount =
    roleCountFromMembers[TeamRole.Editor] +
    roleCountFromInvitations[TeamRole.Editor];
  const totalAdminCount =
    roleCountFromMembers[TeamRole.Admin] +
    roleCountFromInvitations[TeamRole.Admin];
  const nonViewerCount = totalEditorCount + totalAdminCount;

  const editorsCountLimit =
    organization.paidSeatsCount ||
    SUBSCRIPTION_PLANS_LIMIT[organization.subscriptionPlan]
      .editorsCountInOrganization;

  const remainingEditorSeats = Math.max(0, editorsCountLimit - nonViewerCount);

  const allSelected =
    Object.values(filteredSelection).length === filteredInvitesOrMembers.length;

  const onRowSelectionChange = (inviteOrMember: OrganizationInviteOrMember) => {
    const currentEmail = getEmailFromInviteOrMember(inviteOrMember);
    const isSelected = selection.includes(currentEmail);

    isSelected
      ? setSelection(without(selection, currentEmail))
      : setSelection([...selection, currentEmail]);
  };

  return (
    <SettingsPageContainer>
      {/* Header */}
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <SettingsPageHeader>
          <Text style={{ fontSize: 18, fontWeight: 600 }}>Members</Text>
          <Text color="info" type="b1">
            Manage who has access to this workspace
          </Text>
        </SettingsPageHeader>
        <div>
          {canEdit ? (
            <Button
              variant="primary"
              as={Link}
              to={paths.settings.organization.membersInvite(organization!.id)}
              replace={true}
            >
              Invite members
            </Button>
          ) : (
            <Tooltip tip="Only workspace admins can invite new members">
              <Button variant="primary" disabled>
                Invite members
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      <SettingsPageDivider />

      <MemberRoleStatContainer>
        <MemberRoleStat>
          <Text
            block
            style={{
              fontSize: 24,
              lineHeight: '17px',
              fontWeight: 600,
              marginBottom: 16,
            }}
          >
            {roleCountFromMembers[TeamRole.Admin]}
          </Text>
          <Text block>
            Admin{roleCountFromMembers[TeamRole.Admin] > 1 ? 's' : ''}
            <Tooltip
              tip="Have full edit access to content in this workspace and can manage all workspace settings"
              position="bottom"
            >
              <InfoIcon
                style={{
                  marginLeft: 4,
                  verticalAlign: 'middle',
                  width: 10,
                  height: 10,
                }}
              />
            </Tooltip>
          </Text>
        </MemberRoleStat>
        <MemberRoleStat>
          <Text
            block
            style={{
              fontSize: 24,
              lineHeight: '17px',
              fontWeight: 600,
              marginBottom: 16,
            }}
          >
            {roleCountFromMembers[TeamRole.Editor]}
          </Text>
          <Text block>
            Editor{roleCountFromMembers[TeamRole.Editor] > 1 ? 's' : ''}
            <Tooltip
              tip="Have full edit access to content in this workspace"
              position="bottom"
            >
              <InfoIcon
                style={{
                  marginLeft: 4,
                  verticalAlign: 'middle',
                  width: 10,
                  height: 10,
                }}
              />
            </Tooltip>
          </Text>
        </MemberRoleStat>
        <MemberRoleStat>
          <Text
            block
            style={{
              fontSize: 24,
              lineHeight: '17px',
              fontWeight: 600,
              marginBottom: 16,
            }}
          >
            {roleCountFromMembers[TeamRole.Viewer]}
          </Text>
          <Text block>
            Viewer{roleCountFromMembers[TeamRole.Viewer] > 1 ? 's' : ''}
            <Tooltip
              tip="Have view-only access to content in this workspace"
              position="bottom"
            >
              <InfoIcon
                style={{
                  marginLeft: 4,
                  verticalAlign: 'middle',
                  width: 10,
                  height: 10,
                }}
              />
            </Tooltip>
          </Text>
        </MemberRoleStat>
      </MemberRoleStatContainer>

      <Text type="sh2">Members</Text>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 16,
          marginTop: 16,
          width: '100%',
        }}
      >
        {/* Search Input */}
        <div
          style={{
            width: 'fit-content',
          }}
        >
          <TextInput
            style={{
              minWidth: 200,
              padding: '8px 16px',
            }}
            $background="e1"
            placeholder="Search members..."
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
          }}
        >
          <Button
            variant="secondary"
            onClick={handleExportButtonClick}
            size="M"
          >
            CSV Export
          </Button>
          <MembersListFilters filters={filters} setFilters={setFilters} />{' '}
        </div>
      </div>

      {/* Table  */}
      <Table cols={MEMBERS_TABLE_SIZING.totalColumns} addSelectionColumn={true}>
        <TableHeader>
          <SelectionCell>
            <Checkbox
              icon="line"
              checked={allSelected}
              onClick={() => {
                allSelected
                  ? setSelection([])
                  : setSelection(
                      filteredInvitesOrMembers.map(getEmailFromInviteOrMember)
                    );
              }}
            />
          </SelectionCell>
          <TableCell size={MEMBERS_TABLE_SIZING.name}>Name</TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.dateAdded}>
            Date added
          </TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.lastActive}>
            Last active
          </TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.teams}>Teams</TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.role}>Role</TableCell>
        </TableHeader>

        {filteredInvitesOrMembers.map((inviteOrMember) => {
          return isElemMember(inviteOrMember) ? (
            <OrganizationMemberRow
              key={inviteOrMember.node.id}
              memberEdge={inviteOrMember}
              organizationId={organization.id}
              selected={selection.includes(inviteOrMember.node.email)}
              teams={organizationTeamsUsers}
              onSelectedChange={() => onRowSelectionChange(inviteOrMember)}
            />
          ) : (
            <InvitedMemberRow
              key={inviteOrMember.email}
              invite={inviteOrMember}
              organizationId={organization.id}
              canEdit={canEdit}
              selected={selection.includes(inviteOrMember.email)}
              teams={organizationTeamsUsers}
              onSelectedChange={() => onRowSelectionChange(inviteOrMember)}
            />
          );
        })}

        {/* Empty state */}
        {filteredInvitesOrMembers.length === 0 && (
          <div
            style={{
              padding: '30px',
              display: 'flex',
              gridColumn: '11 span',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 16,
            }}
          >
            <Text type="sh1" style={{ width: '100%', textAlign: 'center' }}>
              No members or invitations found
            </Text>
            <div style={{ display: 'flex', gap: 10 }}>
              <Button
                size="S"
                as={Link}
                to={paths.settings.organization.membersInvite(organization.id)}
              >
                Invite members
              </Button>
              {!filtersAreEmpty && (
                <Button variant="secondary" size="S" onClick={resetFilters}>
                  Reset filters
                </Button>
              )}
            </div>
          </div>
        )}
      </Table>

      {/* Members Invitation Modal */}
      <Modal
        isOpen={props.openInviteMembers}
        setIsOpen={(open) => {
          if (!open) {
            navigate(paths.settings.organization.members(organization.id), {
              replace: true,
            });
          }
        }}
      >
        <ModalHeader>
          <ModalTitle>Invite members</ModalTitle>
          <ModalCloseButton />
        </ModalHeader>
        <ModalContent>
          <EmailInputContainer>
            <EmailMultiInput
              emails={inviteEmailsValue}
              setEmails={setInviteEmailsValue}
            />
          </EmailInputContainer>
          <Dropdown
            buttonProps={{
              style: {
                marginTop: 16,
                width: '100%',
              },
            }}
            options={Object.entries(TeamRole).map(([key, value]) => ({
              data: { value, label: key },
            }))}
            OptionComponent={({ option }) => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '8px',
                }}
              >
                <Text color="info">
                  Invite members as{' '}
                  <Text color="default">{lowerCase(option.label)}s</Text>
                </Text>
                {teamRoleCanEdit(option.value) &&
                  Number.isInteger(remainingEditorSeats) && (
                    <Text
                      type="b1"
                      color={remainingEditorSeats === 0 ? 'warning' : 'primary'}
                    >
                      {remainingEditorSeats} seat
                      {+remainingEditorSeats > 1 ? 's' : ''} left
                    </Text>
                  )}
              </div>
            )}
            optionToValueMapper={(option) => option.value}
            setValue={(value) => setInviteRole(value as TeamRole)}
            value={inviteRole}
          >
            Invite members as {lowerCase(inviteRole)}s{' '}
          </Dropdown>
        </ModalContent>

        <ModalActions>
          <Button
            variant="secondary"
            as={Link}
            to={paths.settings.organization.members(organization.id)}
            replace={true}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={createInviteRes.fetching}
          >
            Invite
          </Button>
        </ModalActions>
      </Modal>
      {/* END -  Members Invitation Modal */}

      <bottomScreenTunnel.In>
        <TableSelectionToolbar
          data={filteredInvitesOrMembers}
          selection={filteredSelection}
          organizationId={organization.id}
          onResetSelection={() => setSelection([])}
        />
      </bottomScreenTunnel.In>
    </SettingsPageContainer>
  );
};

const MemberRoleStatContainer = styled(Surface)`
  display: flex;
  justify-content: space-around;
  padding: 16px;
  margin: 40px 0;
`;

const MemberRoleStat = styled.div`
  padding: 8px;
`;
