import {
  Button,
  FullPageDarkLoader,
  OrganizationAvatar,
  Text,
} from '@vizcom/shared-ui-components';
import {
  OrganizationSubscriptionPlan,
  useOrganizations,
} from '@vizcom/shared/data-access/graphql';
import styled, { css } from 'styled-components';
import { capitalize } from '@vizcom/shared/js-utils';
import { useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';

export const SelectOrganizationForPlanUpgradePage = () => {
  const navigate = useNavigate();
  const { data: organizations, fetching } = useOrganizations();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
  const [searchParams] = useSearchParams();
  const plan = searchParams.get('plan');

  if (fetching) {
    return <FullPageDarkLoader />;
  }

  if (!plan) {
    return <Navigate to="/" />;
  }

  const availableOrganizations = organizations.filter(
    (org) =>
      org.currentUserRole === 'ADMIN' &&
      org.subscriptionPlan !== plan &&
      org.subscriptionPlan !== OrganizationSubscriptionPlan.Enterprise &&
      org.subscriptionPlan !== OrganizationSubscriptionPlan.Organization &&
      !org.manualSubscription
  );

  const handleSubmit = () => {
    navigate(
      paths.upgradeSubscription.organizationSelectEditor(
        selectedOrganizationId,
        plan
      )
    );
  };

  return (
    <Container>
      <Text block type="t1">
        Which workspace would you like to upgrade?
      </Text>
      <Text block type="sh1">
        The workspaces displayed below are workspaces you are an admin of. The
        subscription will be linked to this workspace only.
      </Text>

      <OrganizationsContainer>
        {availableOrganizations.length === 0 && (
          <Text block>
            You're not an admin of any workspace. Either create a new workspace
            or ask the admin of you workspace to subscribe to a paid plan.
          </Text>
        )}

        {availableOrganizations.map((organization) => (
          <Organization
            key={organization.id}
            onClick={() => setSelectedOrganizationId(organization.id)}
          >
            <OrganizationAvatar organization={organization} />
            <div style={{ flex: 1 }}>
              <Text block type="sh2">
                {organization.name}
              </Text>
              <Text block color="info">
                {capitalize(organization.subscriptionPlan)}
              </Text>
            </div>
            <Picker $selected={selectedOrganizationId === organization.id} />
          </Organization>
        ))}
      </OrganizationsContainer>

      <Button
        variant="primary"
        disabled={!selectedOrganizationId}
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  text-align: center;
  width: 400px;
  max-width: 100%;
  flex: 1;
  overflow-y: auto;
`;

const OrganizationsContainer = styled.div`
  overflow-y: auto;
`;

const Organization = styled.div`
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.surface.e2};
  border-radius: ${(p) => p.theme.borderRadius.default};
  margin-bottom: 16px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  transition: 0.2s all ease;
  :hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Picker = styled.div<{ $selected: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 1000px;
  border: 2px solid ${(p) => p.theme.surface.e2};
  position: relative;
  transition: 0.5s all ease;
  background-color: ${(p) =>
    p.$selected ? p.theme.primary.default : 'transparent'};
`;
