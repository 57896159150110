export const arrayBufferSha256 = async (arrayBuffer: ArrayBuffer) => {
  if (!crypto.subtle) {
    console.warn(
      'Crypto subtle not available, this can happen on old browser or when using an insecure origin'
    );
    return null;
  }

  const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
  // convert to hex
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray
    .map((item) => item.toString(16).padStart(2, '0'))
    .join('');
  return hash;
};
