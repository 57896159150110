import { useCallback, useEffect, useMemo, useState } from 'react';

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

export function getLocalStorage<T>(key: string, defaultValue: T) {
  return tryJsonParse(localStorage.getItem(key)) ?? defaultValue;
}

// This hooks allows reading and editing the localStorage and sync all hooks on the same page and on multiple tabs
// to re-render when the value change
export function useLocalStorage<T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] {
  // using a useState to not rerender is the state is not changed
  const [localStorageValue, setLocalStorageValue] = useState(
    localStorage.getItem(key)
  );
  const value = useMemo<T>(() => {
    return tryJsonParse(localStorageValue) ?? defaultValue;
  }, [localStorageValue, defaultValue]);

  const setValue = useCallback(
    (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
      setLocalStorageValue(JSON.stringify(value));
      setTimeout(() => {
        // trigger event for other hooks in the same context
        window.dispatchEvent(new Event('local-storage'));
      });
    },
    [key]
  );

  useEffect(() => {
    const listener = () => {
      setLocalStorageValue(localStorage.getItem(key));
    };
    // this event is manually triggered above when setting localStorage key on the same page context
    window.addEventListener('local-storage', listener);
    // this event is triggered when localStorage is changed by another page in another tab
    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('local-storage', listener);
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  useEffect(() => {
    setLocalStorageValue(localStorage.getItem(key));
  }, [key]);

  return [value, setValue];
}

const tryJsonParse = (str: string | null | undefined) => {
  try {
    if (!str) {
      return undefined;
    }
    return JSON.parse(str);
  } catch (e) {
    console.warn('Error parsing value from localstorage:', str, e);
    return undefined;
  }
};
