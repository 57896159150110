import {
  urqlClient,
  UpdateWorkbenchElementImg2ImgMutation,
  ImageInferenceType,
} from '@vizcom/shared/data-access/graphql';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { promptLegacyTypeToModeAndPalette } from '@vizcom/shared/inference-worker-queues';
import { VariablesOf } from '@graphql-typed-document-node/core';

export const UpdateAiImg2ImgAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updateAiImg2Img';
    elementId: string;
    prompt?: string;
    sourceDrawingId?: string | null;
    imageInferenceType?: ImageInferenceType;
    publicPaletteId?: string | null;
    workbenchPaletteId?: string | null;
    customModelId?: string | null;
    sourceImageInfluence?: number | null;
  }
> = {
  type: 'updateAiImg2Img',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementImg2Img') {
      sourceElement.sourceImageInfluence =
        payload.sourceImageInfluence ?? sourceElement.sourceImageInfluence;
      sourceElement.prompt = payload.prompt ?? sourceElement.prompt;
      sourceElement.sourceDrawingId =
        payload.sourceDrawingId !== undefined
          ? payload.sourceDrawingId
          : sourceElement.sourceDrawingId;

      if (sourceElement.legacyType) {
        const convertedLegacyType = promptLegacyTypeToModeAndPalette(
          sourceElement.legacyType
        );
        sourceElement.imageInferenceType =
          convertedLegacyType.mode.toUpperCase() as ImageInferenceType;
        sourceElement.publicPaletteId = convertedLegacyType.publicPaletteId;
        sourceElement.legacyType = null;
      }

      sourceElement.imageInferenceType =
        payload.imageInferenceType ?? sourceElement.imageInferenceType;
      if (
        payload.publicPaletteId ||
        payload.workbenchPaletteId ||
        payload.customModelId
      ) {
        // when changing a palette, we need to reset all other palette types
        sourceElement.customModelId = payload.customModelId ?? null;
        sourceElement.workbenchPaletteId = payload.workbenchPaletteId ?? null;
        sourceElement.publicPaletteId = payload.publicPaletteId ?? null;
      }
    }
  },
  remoteUpdater: async ({ payload }) => {
    const mutationVariables: VariablesOf<
      typeof UpdateWorkbenchElementImg2ImgMutation
    > = {
      input: {
        id: payload.elementId,
        patch: {
          prompt: payload.prompt,
          sourceDrawingId: payload.sourceDrawingId,
          imageInferenceType: payload.imageInferenceType,
          sourceImageInfluence: payload.sourceImageInfluence,
        },
      },
    };
    if (
      payload.publicPaletteId ||
      payload.workbenchPaletteId ||
      payload.customModelId
    ) {
      mutationVariables.input.patch.legacyType = null;
      mutationVariables.input.patch.publicPaletteId =
        payload.publicPaletteId ?? null;
      mutationVariables.input.patch.workbenchPaletteId =
        payload.workbenchPaletteId ?? null;
      mutationVariables.input.patch.customModelId =
        payload.customModelId ?? null;
    }

    const res = await urqlClient.mutation(
      UpdateWorkbenchElementImg2ImgMutation,
      mutationVariables
    );

    if (res?.error) {
      throw new Error(
        `Error while updating element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload) => ({
    delay: 500,
    debounceId: payload.elementId,
  }),
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element || element.__typename !== 'WorkbenchElementImg2Img') {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<
      typeof UpdateAiImg2ImgAction
    > = {
      type: 'updateAiImg2Img',
      elementId: payload.elementId,
      prompt: element.prompt,
      imageInferenceType: element.imageInferenceType,
      sourceImageInfluence: element.sourceImageInfluence,
      sourceDrawingId: element.sourceDrawingId,
      publicPaletteId: element.publicPaletteId,
      workbenchPaletteId: element.workbenchPaletteId,
      customModelId: element.customModelId,
    };

    return undoPayload;
  },
};
