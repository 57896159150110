import React, { useState } from 'react';
import {
  TooltipMenu,
  addToast,
  formatErrorMessage,
  resizeImageToPixelCount,
} from '@vizcom/shared-ui-components';
import {
  useCreateDrawingStyleReference,
  useDeleteStyleReference,
} from '@vizcom/shared/data-access/graphql';
import { StyleReferenceImageModal } from './StyleReferenceImageModal';
import StyleReferenceImageChip from './StyleReferenceImageChip';

type Props = {
  workbenchId: string;
  references: {
    id: string;
    imagePath: string;
    imageName?: string | null;
  }[];
  styleReferenceId?: string;
  setStyleReferenceId: (id: string | undefined) => void;
  styleReferenceStrength: number;
  setStyleReferenceStrength: (value: number) => void;
};

export const StyleReferenceImageSelector = ({
  workbenchId,
  references,
  styleReferenceId,
  setStyleReferenceId,
  styleReferenceStrength,
  setStyleReferenceStrength,
}: Props) => {
  const [, createDrawingStyleReference] = useCreateDrawingStyleReference();
  const [, deleteStyleReference] = useDeleteStyleReference();

  const handleDeleteStyleReferenceClick =
    (id: string) => async (e: React.MouseEvent) => {
      e.stopPropagation();
      const res = await deleteStyleReference({ id });
      if (res.error) {
        return addToast(`Error while deleting image`, {
          type: 'danger',
          secondaryText: formatErrorMessage(res.error),
        });
      }
      const currentIndex = references.findIndex((ref) => ref.id === id);
      const nextIndex = (currentIndex + 1) % references.length;
      setStyleReferenceId(references[nextIndex]?.id || undefined);
    };

  const uploadStyleReference = async (file: File) => {
    const name = file.name;
    let resizedImage;
    try {
      resizedImage = await resizeImageToPixelCount(
        file,
        512 * 512,
        'image/webp'
      );
    } catch (e) {
      return addToast(
        `This image is not supported, please try with another image`,
        {
          type: 'danger',
        }
      );
    }
    const res = await createDrawingStyleReference({
      input: {
        styleReference: {
          workbenchId,
          imageName: name,
          imagePath: resizedImage.image,
        },
      },
    });
    if (res.error || !res.data?.createStyleReference?.styleReference) {
      return addToast(`Error while uploading image`, {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
    setStyleReferenceId(res.data.createStyleReference.styleReference.id);
  };

  const handleSelectReference = (id: string) => {
    setStyleReferenceId(id);
  };

  const [popoverOpen, setPopoverOpen] = useState(false);
  const handleThumbnailClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setPopoverOpen((value) => !value);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };
  const activeReference = references.find((ref) => ref.id === styleReferenceId);

  const handleDropImage = (file: File) => {
    uploadStyleReference(file);
  };

  return (
    <div>
      <StyleReferenceImageChip
        setStyleReferenceStrength={setStyleReferenceStrength}
        styleReferenceStrength={styleReferenceStrength}
        activeReference={activeReference ?? undefined}
        onFileSelected={uploadStyleReference}
        onClick={handleThumbnailClick}
        onResetClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          if (activeReference?.id) {
            setStyleReferenceId(undefined);
          }
        }}
        onDropImage={handleDropImage}
        isActive={popoverOpen}
      />

      <TooltipMenu
        direction="right"
        isOpen={popoverOpen}
        onClose={handleClosePopover}
        customHorizontalMargin={80}
        customVerticalMargin={-30}
        showArrow={false}
      >
        <StyleReferenceImageModal
          workbenchId={workbenchId}
          setStyleReferenceId={setStyleReferenceId}
          activeReference={activeReference ?? undefined}
          onExitClick={handleClosePopover}
          styleReferenceStrength={styleReferenceStrength ?? 1}
          setStyleReferenceStrength={setStyleReferenceStrength}
          references={references}
          handleSelectReference={handleSelectReference}
          onDeleteClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            if (activeReference?.id) {
              handleDeleteStyleReferenceClick(activeReference.id)(e);
            }
          }}
        />
      </TooltipMenu>
    </div>
  );
};
