import { create } from 'zustand';
import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { elementIsDrawing } from '../../lib/utils';

interface WorkbenchFiltersState {
  drawingWithMultipleLayers: boolean;
}

export const useWorkbenchFilters = create<
  WorkbenchFiltersState & {
    setFilter: <Key extends keyof WorkbenchFiltersState>(
      filter: Key,
      value: WorkbenchFiltersState[Key]
    ) => void;
  }
>((set) => ({
  drawingWithMultipleLayers: false,
  setFilter: (filter, value) => {
    set({ [filter]: value });
  },
}));

export const useIsElementFilteredOut = (
  element: ClientSideWorkbenchElementData
) => {
  return useWorkbenchFilters((s) => {
    if (s.drawingWithMultipleLayers) {
      if (!elementIsDrawing(element)) {
        return false;
      }
      return (element.contentLayersCount ?? 0) < 2;
    }
    return false;
  });
};
