import React from 'react';
import styled from 'styled-components';

type BrushSizePreviewProps = {
  size: number;
  opacity: number;
};

const PreviewContainer = styled.div`
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.surface.e2};
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 118px;
  height: 118px;
  position: relative;
`;

const Circle = styled.div<{ size: number; opacity: number }>`
  width: ${(props) => props.size / 2}px;
  height: ${(props) => props.size / 2}px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => props.opacity};
`;

export const BrushSizePreview: React.FC<BrushSizePreviewProps> = ({
  size,
  opacity,
}) => {
  return (
    <PreviewContainer>
      <Circle size={size} opacity={opacity} />
    </PreviewContainer>
  );
};
