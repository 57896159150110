import { PALETTE_COLOR } from './WorkbenchElementPalette';
import { Button, ImportImageIcon, Text } from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { handleAddImagesToPalette } from './helpers';
import { CustomHtml } from '../../utils/CustomHtml';
import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';

export const PaletteUploadButton = ({
  paletteData,
  handleAction,
}: {
  paletteData: ClientSideWorkbenchElementPalette;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  return (
    <CustomHtml transform>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Button
          variant="primary"
          onPointerDown={(e) => {
            e.stopPropagation();
            handleAddImagesToPalette(
              paletteData.id,
              paletteData.sourceImages.nodes.length,
              handleAction
            );
          }}
          style={{
            backgroundColor: PALETTE_COLOR,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '20px',
            fontSize: '16px',
            fontWeight: 600,
            padding: '8px 16px',
            height: '45px',
          }}
        >
          <ImportImageIcon style={{ marginRight: '10px', color: '#FFF' }} />
          Upload
        </Button>
        <Text style={{ fontSize: '16px', marginTop: '16px' }} color="info">
          or drag in photos
        </Text>
      </div>
    </CustomHtml>
  );
};
