import { addToast } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';
import { NavigateFunction } from 'react-router-dom';

export const showLimitationErrorToast = (
  errorCode: string,
  organizationId: string,
  navigate: NavigateFunction,
  openChat: () => void
) => {
  addToast("You don't have any editor seats available", {
    duration: 15_000,
    type: 'warning',
    ctaText:
      errorCode === 'PLAN_LIMITATION_ERROR'
        ? 'Upgrade workspace'
        : 'Contact support',
    ctaAction: () => {
      errorCode === 'PLAN_LIMITATION_ERROR'
        ? navigate(paths.settings.organization.subscription(organizationId))
        : openChat();
    },
  });
};
