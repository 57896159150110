import {
  urqlClient,
  CreateLayer3dFromDrawingMutation,
  publishTrackingEvent,
  ImageTo3dQualityType,
} from '@vizcom/shared/data-access/graphql';
import { FormattedDate, addToast } from '@vizcom/shared-ui-components';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import {
  genTopOrderKey,
  LayerMetadata3d,
} from '../../../../../../../shared/js-utils/src';
import { DeleteLayerAction } from './deleteLayer';
import { formatDistanceToNow } from 'date-fns';

export const CreateLayer3dFromDrawing: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'createLayer3dFromDrawing';
    id: string;
    name: string;
    sourceLayerId?: string;
    qualityType: ImageTo3dQualityType;
  },
  {
    orderKey: string;
  }
> = {
  type: 'createLayer3dFromDrawing',
  optimisticUpdater: ({ payload }, drawing) => {
    const existingLayer = drawing.layers.nodes.find((l) => l.id === payload.id);
    if (existingLayer) {
      existingLayer.name = payload.name;
      existingLayer.metadata3D = {
        generatedFrom2dTo3d: true,
      } as LayerMetadata3d;
    } else {
      drawing.layers.nodes.push({
        id: payload.id,
        name: payload.name,
        blendMode: 'normal',
        fill: '',
        opacity: 1,
        visible: true,
        metadata3D: {
          generatedFrom2dTo3d: true,
        },
        updatedAt: '0',
        createdAt: '0',
        meshPath: null,
        orderKey: genTopOrderKey(drawing.layers.nodes),
        drawingId: drawing.id,
      });
    }
  },
  remoteUpdater: async ({ payload, meta }, drawingId) => {
    const res = await urqlClient.mutation(CreateLayer3dFromDrawingMutation, {
      input: {
        drawingId,
        layer: {
          blendMode: 'normal',
          id: payload.id,
          drawingId,
          fill: '',
          name: payload.name,
          opacity: 1,
          visible: true,
          orderKey: meta.custom!.orderKey,
        },
        sourceLayerId: payload.sourceLayerId,
        qualityType: payload.qualityType,
      },
    });
    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0].extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0].extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        if (payload.qualityType === ImageTo3dQualityType.High) {
          addToast(
            `You’ve reached your Detailed 3D Generation Limit. Don't worry, your limit will refresh ${formatDistanceToNow(
              new Date(Date.now() + rateLimitInfo.resetInMs),
              {
                addSuffix: true,
              }
            )}. You can still generate models using Standard 3D until then.`,
            {
              type: 'danger',
            }
          );
        } else {
          addToast(
            `You have been generating too many 3D models, please wait ${(
              rateLimitInfo.resetInMs / 1000
            ).toFixed(0)}s before trying again.`,
            {
              type: 'danger',
            }
          );
        }
      } else {
        throw new Error(
          `Error using AI service, please retry. ${
            res.error.graphQLErrors[0]?.message ?? res.error.message
          }`
        );
      }
      return;
    }
    trackEvent('Generate Mesh', {
      type: 'createLayer3dFromDrawing',
    });
  },
  metaConstructor: (_, state) => {
    return {
      custom: {
        orderKey: genTopOrderKey(state.layers.nodes),
      },
    };
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<typeof DeleteLayerAction> = {
      type: 'deleteLayer',
      id: payload.id,
    };

    return undoPayload;
  },
};
