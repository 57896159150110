import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import {
  ContextMenu,
  ContextMenuDivider,
  MenuHorizontalIcon,
} from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../lib/utils';
import { SharedContextMenuItems } from './workbenchContextMenu/contextMenuItemsPerType/Shared';

export const Extra = styled.div`
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.icon.default};
  border-radius: 16px;
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  transform: translate3d(-50%, calc(-42px - 100%), 0);
  pointer-events: all;
`;

export const ExtraHorizontalDelimiter = styled.div`
  width: 1px;
  background-color: ${(p) => p.theme.surface.e2};
  align-self: stretch;
`;

type WorkbenchElementExtraProps = PropsWithChildren<{
  element: ClientSideWorkbenchElementData;
  menuItems?: ReactNode;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}>;

export const WorkbenchElementExtra = ({
  children,
  element,
  menuItems,
  handleAction,
}: WorkbenchElementExtraProps) => {
  const isViewer = useIsWorkbenchViewer();

  return (
    <Extra onPointerDown={(e) => e.stopPropagation()}>
      {children}
      {children && !isViewer && <ExtraHorizontalDelimiter />}
      <div
        style={{
          alignSelf: 'center',
        }}
      >
        {!isViewer && (
          <ContextMenu
            buttonProps={{
              variant: 'transparent',
              size: 'iconSquared',
            }}
            items={
              <>
                {menuItems ? (
                  <>
                    {menuItems}
                    <ContextMenuDivider />
                  </>
                ) : null}
                <SharedContextMenuItems
                  element={element}
                  handleAction={handleAction}
                />
              </>
            }
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        )}
      </div>
    </Extra>
  );
};
