import { useEffect, useState } from 'react';

export const useFirstValue = <T>(value: T) => {
  const [firstValue, setFirstValue] = useState<T>(value);

  useEffect(() => {
    if (firstValue) {
      return;
    }
    setFirstValue(value);
  }, [value, firstValue]);

  return firstValue;
};
