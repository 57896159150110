import { useEffect } from 'react';

export const usePreventBrowserZoom = () => {
  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        ['-', '=', '_', '+'].includes(e.key?.toLowerCase())
      ) {
        // Only prevent default if it's not our custom zoom functionality
        if (!e.shiftKey) {
          e.preventDefault();
        }
      }
    };
    const wheel = (e: WheelEvent) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', keydown);
    document.addEventListener('wheel', wheel, { passive: false });
    return () => {
      document.removeEventListener('keydown', keydown);
      document.removeEventListener('wheel', wheel);
    };
  }, []);
};
