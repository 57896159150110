import { v4 as uuidv4 } from 'uuid';
import { Matrix3, DoubleSide, Group, Texture } from 'three';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import { initializeTransformTextures, useSelectionTransform } from './helpers';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ResizePositionRotationPresenter } from '../../../utils/ResizePositionRotationPresenter';
import {
  LayerTextureRenderer,
  LayerTextureRendererRef,
} from '../LayerTextureRenderer';
import { useLayerRotationTransform } from './useLayerRotationTransform';
import {
  LayerTranslation,
  useLayerTranslationTransform,
} from './useLayerTranslationTransform';
import { LayerScale, useLayerResizeTransform } from './useLayerResizeTransform';
import { LayerContent } from '../LayersCompositor/LayerContent';
import { LayerStaticView } from '../CompositeLayer';
import {
  useOnBeforeSelectionChange,
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from '../../selection/useSelectionApi';
import { MutateLocalStateAction } from '../../../../lib/actions/drawing/mutateLocalStateAction';
import { SyncedActionPayloadFromType } from '../../../../lib/SyncedAction';
import { useLastValue, useStableCallback } from '@vizcom/shared-ui-components';
import { createPortal } from '@react-three/fiber';
import { ActiveMask, MaskDisplayMode } from '../../selection/ActiveMask';

//force an import from MaskedTransformMaterial to have maskedTransformMaterial
import { MaskedTransformMesh } from './MaskedTransformMaterial';

interface LayerTransformProps {
  drawingSize: [number, number];
  layer: Drawing2dStudio['layers']['nodes'][0];
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
}

export const LayerTransformControls = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
  filterHistory,
}: LayerTransformProps) => {
  const layerTextureRendererRef = useRef<LayerTextureRendererRef>(null!);
  const resizerGroupRef = useRef<Group>(null!);
  const lastLayerImageRef = useRef(layerImage?.image);

  const [hasLayerChanged, setHasLayerChanged] = useState(false);

  const selectionApiStore = useSelectionApiStore();
  const selectionTexture = useSubscribeToSelectionApi((state) => state.texture);
  const selectionTexture_treatEmptyMaskAsFull = useSubscribeToSelectionApi(
    (state) => state.texture_treatEmptyMaskAsFull
  );
  const selectionTextureVersion = useSubscribeToSelectionApi(
    (state) => state.version
  );

  const croppedTexture = useMemo(
    () =>
      initializeTransformTextures(
        layerImage,
        selectionApiStore.getState().getSelectionImage(),
        selectionApiStore.getState().texture,
        drawingSize
      ),
    [layerImage, drawingSize, selectionTextureVersion, selectionApiStore]
  );

  const updateLayerImage = useCallback(
    (undoGroupId?: string) => {
      handleAction(
        {
          type: 'updateLayer',
          id: layer.id,
          data: {
            image: layerTextureRendererRef.current.exportTexture(),
          },
        },
        { undoGroupId }
      );
      setHasLayerChanged(false);
    },
    [handleAction, layer.id]
  );

  //These are the transforms applied to the layer at rest.
  //These values are then composed with the transfom from the controls.
  const [baseRotation, setBaseRotation] = useState(0);
  const [basePosition, setBasePosition] = useState<LayerTranslation>([0, 0]);
  const [baseScale, setBaseScale] = useState<LayerScale>([1, 1]);

  // expose a stable ref to the last value of the transform state, used when generating the undo/redo actions
  const transformStateRef = useLastValue({
    baseRotation,
    basePosition,
    baseScale,
  });
  const getMutateLocalStateAction = useStableCallback(
    (editionFunction: () => void) => {
      const snapshot = {
        rotation: 0,
        translation: [0, 0] as LayerTranslation,
        scale: [1, 1] as LayerScale,
      };

      const action = {
        type: 'mutateLocalState',
        actionId: `transform_${uuidv4()}`,
        onExecute: () => {
          snapshot.rotation = transformStateRef.current.baseRotation;
          snapshot.translation = transformStateRef.current.basePosition;
          snapshot.scale = transformStateRef.current.baseScale;
          editionFunction();
        },
        undoConstructor: () =>
          getMutateLocalStateAction!(() => {
            setBaseRotation(snapshot.rotation);
            setBasePosition(snapshot.translation);
            setBaseScale(snapshot.scale);
          }),
      } as SyncedActionPayloadFromType<typeof MutateLocalStateAction>;
      return action;
    }
  );

  const { bindRotationHandle, resetRotationTransform, rotationTransform } =
    useLayerRotationTransform(
      useStableCallback((rotation: number) => {
        handleAction(
          getMutateLocalStateAction!(() => {
            setBaseRotation(baseRotation + rotation);
            resetRotationTransform();
            setHasLayerChanged(true);
          })
        );
      }),
      resizerGroupRef
    );
  const {
    bindTranslationHandle,
    resetTranslationTransform,
    translationTransform,
  } = useLayerTranslationTransform(
    useStableCallback((translation: LayerTranslation) => {
      handleAction(
        getMutateLocalStateAction!(() => {
          setBasePosition([
            basePosition[0] + translation[0],
            basePosition[1] + translation[1],
          ]);
          resetTranslationTransform();
          setHasLayerChanged(true);
        })
      );
    }),
    resizerGroupRef
  );
  const {
    bindResizeHandle,
    resetResizeTransform,
    scaleTransform,
    translationTransform: resizeTranslationTransform,
  } = useLayerResizeTransform(
    useStableCallback((scale: LayerScale, translation: LayerTranslation) => {
      handleAction(
        getMutateLocalStateAction!(() => {
          setBaseScale([baseScale[0] * scale[0], baseScale[1] * scale[1]]);
          setBasePosition([
            basePosition[0] + translation[0],
            basePosition[1] + translation[1],
          ]);
          resetResizeTransform();
          setHasLayerChanged(true);
        })
      );
    }),
    resizerGroupRef,
    (croppedTexture?.boundingBox.width ?? 0) * baseScale[0],
    (croppedTexture?.boundingBox.height ?? 0) * baseScale[1],
    baseRotation + rotationTransform
  );

  // using `useLayoutEffect` instead of `useEffect` to make sure we can export the texture before LayerTextureRenderer is unmounted
  // and we lose the reference to the updated texture
  useLayoutEffect(() => {
    return () => {
      if (hasLayerChanged) {
        filterHistory((action) =>
          Boolean(
            action.payload.type === 'mutateLocalState' &&
              action.payload.actionId?.startsWith('transform_')
          )
        );
        const undoGroupId = uuidv4();
        updateLayerImage(undoGroupId);
        selectionApiStore.getState().editSelectionCanvas((ctx) => {
          ctx.putImageData(selectionToImageData(), 0, 0);
        }, undoGroupId);
      }
    };
  }, [layer.id, hasLayerChanged]); // commit update when unmounting or when layer changes

  useOnBeforeSelectionChange(() => {
    if (hasLayerChanged) {
      filterHistory((action) =>
        Boolean(
          action.payload.type === 'mutateLocalState' &&
            action.payload.actionId?.startsWith('transform_')
        )
      );
      updateLayerImage();
    }
  });

  useEffect(() => {
    resetRotationTransform();
    resetTranslationTransform();
    resetResizeTransform();
  }, [selectionTextureVersion]);

  const { scene, selectionFBO, selectionToImageData } =
    useSelectionTransform(drawingSize);

  if (lastLayerImageRef.current !== layerImage?.image) {
    // when layer image changes, reset the transform
    // this let us keep the transforms while the new image is being passed through handleAction
    // and only reset the transform when the new image is ready
    // this synchronize both actions to prevent flickering
    lastLayerImageRef.current = layerImage?.image;
    resetResizeTransform();
    resetTranslationTransform();
    resetRotationTransform();
  }

  if (!croppedTexture?.texture) {
    return (
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
      >
        <LayerStaticView layer={layer} drawingSize={drawingSize} />
      </LayerContent>
    );
  }

  const computedTransform = {
    rotation: baseRotation + rotationTransform,
    translation: [
      croppedTexture.boundingBox.centerDeltaX +
        basePosition[0] +
        translationTransform[0] +
        resizeTranslationTransform[0],
      croppedTexture.boundingBox.centerDeltaY +
        basePosition[1] +
        translationTransform[1] +
        resizeTranslationTransform[1],
    ],
    scale: [baseScale[0] * scaleTransform[0], baseScale[1] * scaleTransform[1]],
  };

  const inverseTransform = new Matrix3();
  inverseTransform
    .translate(
      -croppedTexture.boundingBox.centerDeltaX,
      -croppedTexture.boundingBox.centerDeltaY
    )
    .scale(computedTransform.scale[0], computedTransform.scale[1])
    .rotate(-computedTransform.rotation)
    .translate(
      computedTransform.translation[0],
      computedTransform.translation[1]
    )
    .invert();

  return (
    <>
      <group
        ref={resizerGroupRef}
        renderOrder={3}
        position={[
          computedTransform.translation[0],
          computedTransform.translation[1],
          0,
        ]}
      >
        {/* using another child group for the rotation to keep the parent group stable when rotation and prevent flickering of the pointer position because of worldToLocal conversion  */}
        <group rotation={[0, 0, computedTransform.rotation]}>
          <ResizePositionRotationPresenter
            active
            width={
              croppedTexture?.boundingBox.width * computedTransform.scale[0]
            }
            height={
              croppedTexture?.boundingBox.height * computedTransform.scale[1]
            }
            moveHandleMeshProps={bindTranslationHandle() as any}
            resizeHandleMeshPropsGetter={bindResizeHandle as any}
            rotationHandleMeshPropsGetter={bindRotationHandle as any}
            rotation={computedTransform.rotation}
          />
        </group>
      </group>
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
      >
        <LayerTextureRenderer
          width={drawingSize[0]}
          height={drawingSize[1]}
          ref={layerTextureRendererRef}
        >
          {croppedTexture?.alphaMap && (
            <MaskedTransformMesh
              drawingSize={drawingSize}
              selectionTexture={selectionTexture}
              layerImage={layerImage}
              boundingBox={croppedTexture?.boundingBox}
              inverseTransform={inverseTransform}
            />
          )}
          {!croppedTexture?.alphaMap && (
            <group
              position={[
                computedTransform.translation[0],
                computedTransform.translation[1],
                0,
              ]}
              rotation={[0, 0, computedTransform.rotation]}
            >
              <mesh
                renderOrder={1}
                scale={[
                  computedTransform.scale[0],
                  computedTransform.scale[1],
                  1,
                ]}
              >
                <planeGeometry
                  args={[
                    croppedTexture?.boundingBox.width,
                    croppedTexture?.boundingBox.height,
                  ]}
                />
                <meshBasicMaterial
                  map={croppedTexture?.texture}
                  transparent
                  depthTest={false}
                  side={DoubleSide}
                />
              </mesh>
            </group>
          )}
        </LayerTextureRenderer>
      </LayerContent>

      {croppedTexture.alphaMap && (
        <>
          {/*render transformed selection*/}
          {createPortal(
            <>
              <group
                position={[
                  computedTransform.translation[0],
                  computedTransform.translation[1],
                  0,
                ]}
                rotation={[0, 0, computedTransform.rotation]}
              >
                <mesh
                  renderOrder={2}
                  scale={[
                    computedTransform.scale[0],
                    computedTransform.scale[1],
                    1,
                  ]}
                >
                  <planeGeometry
                    args={[
                      croppedTexture?.boundingBox.width,
                      croppedTexture?.boundingBox.height,
                    ]}
                  />
                  <meshBasicMaterial
                    map={
                      croppedTexture?.alphaMap ||
                      selectionTexture_treatEmptyMaskAsFull
                    }
                    transparent
                    depthTest={false}
                    side={DoubleSide}
                  />
                </mesh>
              </group>
            </>,
            scene
          )}
          {/*display transformed selection*/}
          <ActiveMask
            drawingSize={drawingSize}
            maskTexture={selectionFBO.texture}
            mode={MaskDisplayMode.MARCHING_ANTS}
          />
        </>
      )}
    </>
  );
};
