import { useState } from 'react';
import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { assertUnreachable, sortByOrderKey } from '@vizcom/shared/js-utils';

export type ActiveLayerChangeOperation = 'set' | 'add' | 'addTo' | 'remove';

// Auto select first visible layer if none are selected or if the selected layer
// doesn't exist anymore (because it was deleted)
export const useActiveLayer = (drawing: Drawing2dStudio | undefined | null) => {
  const [_activeLayerId, _setActiveLayerId] = useState<string | undefined>(
    undefined
  );
  const [selectionOrigin, setSelectionOrigin] = useState<string | undefined>(
    undefined
  );
  const isViewer = useIsWorkbenchViewer();

  if (isViewer) {
    return {
      activeLayer: undefined,
      setActiveLayerId: (
        id?: string,
        operation?: ActiveLayerChangeOperation
      ) => {},
    };
  }

  if (!drawing) {
    return {
      activeLayer: undefined,
      setActiveLayerId: (
        id?: string,
        operation?: ActiveLayerChangeOperation
      ) => {
        _setActiveLayerId(id);
      },
    };
  }

  // check that the layer still exists, could have been deleted by the user and `_activeLayerId` would be invalid
  const activeLayerId = drawing.layers.nodes.find(
    ({ id }) => id === _activeLayerId
  )
    ? _activeLayerId
    : _activeLayerId
        ?.split('/')
        .filter((id) => drawing.layers.nodes.some((layer) => layer.id === id))
        .join('/');

  if (!activeLayerId) {
    // if no active layer id, auto-select the first visible layer if there's one
    const firstVisibleLayerId = sortByOrderKey(drawing.layers.nodes).find(
      (layer) => layer.visible
    )?.id;
    if (firstVisibleLayerId) {
      _setActiveLayerId(firstVisibleLayerId);
    }
  }
  const activeLayer = drawing.layers.nodes.find(
    ({ id }) => id === activeLayerId
  );

  const setActiveLayerId = (
    id?: string,
    // 'set' sets the active layer to the given id
    // 'add' adds the given id to the active layer id
    // 'addTo' adds the given id to the active layer id and all layers between the active layer and the given id
    operation: ActiveLayerChangeOperation = 'set'
  ) => {
    if (operation === 'addTo') {
      let newActiveLayerId = activeLayerId;
      if (selectionOrigin === undefined) {
        setSelectionOrigin(activeLayerId);
      } else {
        newActiveLayerId = selectionOrigin.split('/')[0];
        setSelectionOrigin(selectionOrigin.split('/')[0]);
      }

      const sorted = sortByOrderKey(drawing.layers.nodes);
      const index = sorted.findIndex((layer) => layer.id === newActiveLayerId);
      const newIndex = sorted.findIndex((layer) => layer.id === id);

      let included = sorted.slice(index + 1, newIndex + 1);
      if (newIndex < index) {
        included = sorted.slice(newIndex, index);
      }

      included.forEach((layer) => {
        newActiveLayerId += `/${layer.id}`;
      });

      _setActiveLayerId(newActiveLayerId);
      return;
    } else if (operation === 'add') {
      const ordered = sortByOrderKey(drawing.layers.nodes);
      const ids = [...(activeLayerId?.split('/') || []), id];

      const orderdIds = ids.sort(
        (a, b) =>
          ordered.findIndex((layer) => layer.id === a) -
          ordered.findIndex((layer) => layer.id === b)
      );

      _setActiveLayerId(orderdIds.join('/'));
      return;
    } else if (operation === 'set') {
      setSelectionOrigin(undefined);
      _setActiveLayerId(id);
    } else if (operation === 'remove') {
      if (!id) return;

      const ids = activeLayerId?.split('/') || [];
      const index = ids.indexOf(id);
      if (index !== -1) {
        ids.splice(index, 1);
        _setActiveLayerId(ids.join('/'));
      }
    } else {
      assertUnreachable(operation);
    }
  };

  return {
    activeLayer,
    activeLayerId,
    setActiveLayerId,
  };
};
