import { useForm, SubmitHandler } from 'react-hook-form';

import {
  Button,
  LoadingLogoInset,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { useChangePassword } from '@vizcom/shared/data-access/graphql';

type ChangePasswordInputs = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export const ChangePassword = () => {
  const [changePasswordRes, changePassword] = useChangePassword();

  const { register, handleSubmit, reset, formState } =
    useForm<ChangePasswordInputs>();

  const handleChangePassword: SubmitHandler<ChangePasswordInputs> = async (
    data
  ) => {
    if (changePasswordRes.fetching) {
      return;
    }
    if (data.newPassword !== data.newPasswordConfirmation) {
      return addToast(`Password confirm doesn't match the new password`, {
        type: 'danger',
      });
    }
    const res = await changePassword({
      input: {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    });

    if (res.error) {
      return addToast('Error while changing password', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    } else {
      addToast(`Password changed successfully`);
      reset();
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(handleChangePassword)}
        style={{
          display: 'flex',
          marginTop: 24,
          flexDirection: 'column',
          width: '100%',
          gap: 12,
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 16,
          }}
        >
          <TextInput
            style={{ gridColumn: '1/3' }}
            required
            type="password"
            placeholder="Current password"
            disabled={changePasswordRes.fetching}
            $background="e1"
            {...register('currentPassword')}
          />

          <TextInput
            required
            type="password"
            placeholder="New password"
            disabled={changePasswordRes.fetching}
            $background="e1"
            {...register('newPassword')}
          />
          <TextInput
            required
            type="password"
            placeholder="Confirm new password"
            disabled={changePasswordRes.fetching}
            $background="e1"
            {...register('newPasswordConfirmation')}
          />
        </div>

        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Button
            type="submit"
            disabled={changePasswordRes.fetching || !formState.isDirty}
          >
            Save
          </Button>
          <Button
            type="button"
            variant="transparent"
            onClick={() => reset()}
            disabled={!formState.isDirty}
          >
            Cancel
          </Button>
        </div>
      </form>
      <LoadingLogoInset active={changePasswordRes.fetching} />
    </div>
  );
};
