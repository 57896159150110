import {
  Button,
  HelpIcon,
  OnboardingStepName,
  OnboardingTooltip,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  useCompleteOnboardingStep,
  useCrisp,
  useResetOnboardingStatus,
  useShouldDisplayOnboardingTooltip,
} from '@vizcom/shared-ui-components';
import styled from 'styled-components';

import { useState } from 'react';
import { WorkbenchMenuButton } from './workbenchMenu/WorkbenchMenuButton';
import { HotkeysMenuButton } from './hotkeys';

type Props = {
  context: 'Drawing' | 'CompositeScene' | 'Workbench';
  fullscreen?: boolean;
};

export const WorkbenchHelpMenu = ({ context, fullscreen = false }: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const crisp = useCrisp();

  const resetOnboardingStatus = useResetOnboardingStatus();
  const completeOnboardingStep = useCompleteOnboardingStep();
  const shouldDisplayOnboardingTooltip = useShouldDisplayOnboardingTooltip(
    OnboardingStepName.AnyQuestions,
    OnboardingStepName.EnterSketch
  );

  const handleTooltipOpen = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  return (
    <>
      <VerticalDivider />

      {shouldDisplayOnboardingTooltip && !fullscreen ? (
        <OnboardingTooltip
          name={OnboardingStepName.AnyQuestions}
          previousStep={OnboardingStepName.BackInfiniteCanvas}
          title="If you have any questions, refer here"
          durationVisible={10000}
        >
          <Button
            size="iconSquared"
            variant="transparent"
            onClick={async () => {
              completeOnboardingStep(OnboardingStepName.AnyQuestions);
              setIsTooltipOpen(true);
            }}
          >
            <HelpIcon />
          </Button>
        </OnboardingTooltip>
      ) : (
        <RichTooltip manualOpen={isTooltipOpen}>
          <RichTooltipTrigger>
            <Button
              onClick={handleTooltipOpen}
              size="iconSquared"
              variant="transparent"
            >
              <HelpIcon />
            </Button>
          </RichTooltipTrigger>

          <RichTooltipContent
            hideArrow
            style={{ overflow: 'hidden', padding: 0 }}
          >
            <Container>
              <WorkbenchMenuButton
                variant="transparent"
                onClick={async () => {
                  await resetOnboardingStatus();
                  if (context === 'Workbench') {
                    await completeOnboardingStep(
                      OnboardingStepName.BackInfiniteCanvas
                    );
                  }
                  setIsTooltipOpen(false);
                }}
              >
                Get started
              </WorkbenchMenuButton>
              <WorkbenchMenuButton
                variant="transparent"
                as="a"
                target="_blank"
                href="https://www.youtube.com/channel/UCU-XOa74z1aGdZRyvRBQ6UQ/videos"
                onClick={() => setIsTooltipOpen(false)}
              >
                Tutorials
              </WorkbenchMenuButton>
              <WorkbenchMenuButton
                variant="transparent"
                as="a"
                target="_blank"
                href="https://discord.gg/aJZZaJpPSZ"
                onClick={() => setIsTooltipOpen(false)}
              >
                Join Discord
              </WorkbenchMenuButton>
              <Divider />
              <WorkbenchMenuButton
                variant="transparent"
                as="a"
                target="_blank"
                href="https://www.vizcom.ai/terms-of-service"
                onClick={() => setIsTooltipOpen(false)}
              >
                Legal summary
              </WorkbenchMenuButton>
              <Divider />
              <WorkbenchMenuButton
                variant="transparent"
                onClick={() => {
                  crisp.openChat();
                  setIsTooltipOpen(false);
                }}
              >
                Contact support
              </WorkbenchMenuButton>
              <WorkbenchMenuButton
                variant="transparent"
                as="a"
                target="_blank"
                href="https://forms.gle/LJZzUfHDWwiZeHBW6"
                onClick={() => setIsTooltipOpen(false)}
              >
                Send feedback
              </WorkbenchMenuButton>

              <Divider />
              <HotkeysMenuButton defaultTab={context} />
            </Container>
          </RichTooltipContent>
        </RichTooltip>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 4px;
  width: 170px;
`;

const VerticalDivider = styled.div`
  width: 2px;
  height: 16px;
  align-self: center;
  background-color: ${(p) => p.theme.surface.e2};
`;

const Divider = styled.div`
  background-color: ${(p) => p.theme.surface.e2};
  height: 1px;
`;
