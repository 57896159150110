import * as THREE from 'three';

export const sobelShaderMaterial = new THREE.ShaderMaterial({
  uniforms: {
    tDiffuse: {
      value: new THREE.Texture(),
    },
  },
  vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  fragmentShader: `
    uniform sampler2D tDiffuse;

    varying vec2 vUv;

    float sobel(in vec4 color){
      return sqrt(
        (color.x * color.x) +
        (color.y * color.y) +
        (color.z * color.z)
      );
    }

    void main() {
      float fUvStep = 0.001;

      vec3 top = vec3(
        sobel(texture2D(tDiffuse, vUv + vec2(-fUvStep, fUvStep))),
        sobel(texture2D(tDiffuse, vUv + vec2(0.0, fUvStep))),
        sobel(texture2D(tDiffuse, vUv + vec2(fUvStep, fUvStep)))
      );
      vec3 center = vec3(
        sobel(texture2D(tDiffuse, vUv + vec2(-fUvStep, fUvStep))),
        0.0,
        sobel(texture2D(tDiffuse, vUv + vec2(fUvStep, fUvStep)))
      );
      vec3 bottom = vec3(
        sobel(texture2D(tDiffuse, vUv + vec2(-fUvStep, -fUvStep))),
        sobel(texture2D(tDiffuse, vUv + vec2(0.0, -fUvStep))),
        sobel(texture2D(tDiffuse, vUv + vec2(fUvStep, -fUvStep)))
      );

      float x = top.x + 2.0 * center.x + bottom.x - top.z - 2.0 * center.z - bottom.z;
      float y = -top.x - 2.0 * top.y - top.z + bottom.x + 2.0 * bottom.y + bottom.z;

      float step = sqrt(x * x + y * y);

      gl_FragColor = vec4(step, step, step, 1.0);
    }
  `,
});
