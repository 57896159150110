import { useCallback, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';

import {
  OnboardingStepName,
  imageDataToBlob,
  useCompleteOnboardingStep,
  useStableCallback,
} from '@vizcom/shared-ui-components';

import { LayersCompositorApi } from './DrawingCompositor/LayersCompositor/context';
import {
  autoPrompt,
  useWorkbenchPalette,
} from '@vizcom/shared/data-access/graphql';
import { InferenceSettings, useInference } from './useInference';
import { useAvailablePalettes } from '../modals/PaletteSelector';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';
import { imageDataIsBlank, imageDataIsTransparent } from './utils';

type Props = {
  inferenceSettings: InferenceSettings;
  getCompositeImage: LayersCompositorApi['getCompositedImage'];
  setInferenceSettings: ReturnType<typeof useInference>['setInferenceSettings'];
};

export const useAutoPrompt = ({
  inferenceSettings,
  getCompositeImage,
  setInferenceSettings,
}: Props) => {
  const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const completeOnboardingStep = useCompleteOnboardingStep();

  const { data: palette } = useWorkbenchPalette(
    inferenceSettings.workbenchPaletteId
  );

  const cancelAutoPrompt = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setIsGeneratingPrompt(false);
  }, []);

  const generate = useStableCallback(async () => {
    setIsGeneratingPrompt(true);

    const imageData = getCompositeImage();
    if (imageDataIsTransparent(imageData) || imageDataIsBlank(imageData)) {
      setIsGeneratingPrompt(false);
      return;
    }

    const compositeImage = await imageDataToBlob(imageData, {
      maxPixels: 512 * 512,
      format: 'image/jpg',
    });

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const tags = palette?.tags?.join(', ') ?? '';

    try {
      const text = await autoPrompt(
        { data: compositeImage },
        {
          fetch: (input, init) => {
            init = init || {};
            init.signal = abortController.signal;
            return fetch(input, init);
          },
        }
      );

      setInferenceSettings((settings) => ({
        ...settings,
        prompt: text + ' ' + tags,
      }));
    } catch (e: any) {
      if (!abortController.signal.aborted) {
        console.error(e);
        Sentry.captureException(e);
      }
    } finally {
      setIsGeneratingPrompt(false);
      completeOnboardingStep(OnboardingStepName.NewDrawingAutoPrompt);
    }
  });

  const triggerAutoPrompt = useCallback(
    async (delay?: number) => {
      if (delay) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          generate();
        }, delay);
      } else {
        generate();
      }
    },
    [getCompositeImage, setInferenceSettings, inferenceSettings]
  );

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { triggerAutoPrompt, cancelAutoPrompt, isGeneratingPrompt };
};
