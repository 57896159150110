import { useEffect } from 'react';
import { useLastValue } from './useLastValue';

export const useWindowEventListener = <K extends keyof WindowEventMap>(
  event: K,
  listener: (e: WindowEventMap[K]) => void,
  options?: AddEventListenerOptions
) => {
  const listenerRef = useLastValue(listener);

  useEffect(() => {
    const l = (e: WindowEventMap[K]) => {
      listenerRef.current(e);
    };
    window.addEventListener(event, l, options);
    return () => {
      window.removeEventListener(event, l, options);
    };
  }, [event, listenerRef, options]);
};
