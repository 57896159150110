import { Team, useJoinTeam } from '@vizcom/shared/data-access/graphql';
import {
  TableRow,
  TableCell,
  TeamAvatar,
  Chip,
  ContextMenu,
  ContextMenuItem,
  MenuHorizontalIcon,
  addToast,
  LoadingLogoInset,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

export const OrganizationInvitedTeamRow = (props: {
  team: Pick<Team, 'id' | 'name'>;
}) => {
  const { team } = props;
  const [joinTeamRes, joinTeam] = useJoinTeam();

  const handleJoin = async () => {
    const res = await joinTeam({
      input: {
        teamId: team.id,
      },
    });
    if (res.error) {
      return addToast('Error while joining team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast(`Successfully joined team`);
  };

  return (
    <TableRow>
      <TableCell
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'flex-start',
        }}
      >
        <TeamAvatar size="medium" name={team.name} teamId={team.id} />
        {team.name}
        <Chip>Pending invite</Chip>
      </TableCell>

      <TableCell />

      <TableCell />

      <TableCell>
        <ContextMenu
          buttonProps={{
            variant: 'transparent',
            size: 'icon',
          }}
          items={
            <ContextMenuItem onClick={handleJoin}>Join Team</ContextMenuItem>
          }
        >
          <MenuHorizontalIcon />
        </ContextMenu>
      </TableCell>
      <LoadingLogoInset active={joinTeamRes.fetching} />
    </TableRow>
  );
};
