import { useEffect } from 'react';
import { useLastValue } from './useLastValue';

export const useDocumentEventListener = <K extends keyof DocumentEventMap>(
  event: K,
  listener: (e: DocumentEventMap[K]) => void,
  options?: AddEventListenerOptions
) => {
  const listenerRef = useLastValue(listener);

  useEffect(() => {
    const l = (e: DocumentEventMap[K]) => {
      listenerRef.current(e);
    };
    document.addEventListener(event, l, options);
    return () => {
      document.removeEventListener(event, l, options);
    };
  }, [event, listenerRef, options]);
};
