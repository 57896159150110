import {
  PageContainer,
  SectionContainer,
  FormContainer,
  AuthLinkContainer,
  Input,
  InputGroup,
  InputWrapper,
  ResetInputIcon,
} from '../styles';
import { useGenerateRegisterToken } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { Link, Navigate } from 'react-router-dom';
import { LogInWithGoogleButton } from '../LogInWithGoogleButton';
import { useRegisterToken } from '../useRegisterToken';
import { paths } from '@vizcom/shared-utils-paths';

interface SignupFormProps {
  email: string;
  onEmailReset: () => void;
}

export const SignupForm = ({ email, onEmailReset }: SignupFormProps) => {
  const [generateRegisterTokenRes, generateRegisterToken] =
    useGenerateRegisterToken();
  const registerToken = useRegisterToken();

  if (registerToken && registerToken.body?.email === email) {
    return <Navigate to={paths.auth.registerWithToken(registerToken.token)} />;
  }

  const handleGenerateRegisterToken = async () => {
    await generateRegisterToken({
      input: {
        email,
      },
    });

    if (generateRegisterTokenRes.error) {
      addToast(formatErrorMessage(generateRegisterTokenRes.error), {
        type: 'danger',
      });
    }
  };

  if (generateRegisterTokenRes.data?.generateRegisterToken.sentByEmail) {
    return (
      <PageContainer>
        <SectionContainer>
          <Text type="t1" block color={'black'} style={{ textAlign: 'left' }}>
            Verify your email
          </Text>
          <Text type="b1" color={'black'} style={{ textAlign: 'left' }}>
            We've sent you an email with a link that you'll need to click to
            verify your email.
          </Text>
        </SectionContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <SectionContainer>
        <Text type="t1" color={'black'} style={{ textAlign: 'left' }}>
          Get started
        </Text>
        <Text type="sh1" color={'black'}>
          You don't have an account yet. Please continue to create it now.
        </Text>
      </SectionContainer>

      <FormContainer as="div">
        <InputGroup>
          <Text type="sh2" color={'black'}>
            Email:
          </Text>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              readOnly
              disabled
              autoFocus
            />
            <ResetInputIcon onClick={onEmailReset} />
          </InputWrapper>
        </InputGroup>

        <Button
          disabled={generateRegisterTokenRes.fetching}
          variant="fullBlack"
          style={{
            width: '100%',
            marginTop: 40,
          }}
          onClick={handleGenerateRegisterToken}
        >
          {generateRegisterTokenRes.fetching
            ? 'Sending verification email...'
            : 'Create account'}
        </Button>
      </FormContainer>

      <LogInWithGoogleButton />

      <AuthLinkContainer>
        <Text
          type="b3"
          color={'info'}
          style={{ textAlign: 'center', marginTop: 8 }}
        >
          By clicking "Create account" or "Continue with Google", you agree to
          Vizcom's{' '}
          <Text
            type="b3"
            color="primary"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            as={Link}
            to="https://vizcom.ai/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </Text>{' '}
          and{' '}
          <Text
            type="b3"
            color="primary"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            as={Link}
            to="https://vizcom.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Text>
        </Text>
      </AuthLinkContainer>
    </PageContainer>
  );
};
