import {
  CompositeSceneElement,
  CompositeSceneFullData,
} from '@vizcom/shared/data-access/graphql';
import { Object3D } from 'three';

export const getRootElementForMesh = (
  compositeScene: CompositeSceneFullData,
  mesh: Object3D | undefined | null
): CompositeSceneElement | undefined | null => {
  if (!mesh || !compositeScene) {
    return null;
  }

  return compositeScene.compositeSceneElements.nodes.find(
    (match) => match.id === mesh.userData.rootId
  ) as CompositeSceneElement;
};
