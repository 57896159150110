import { Link } from 'react-router-dom';
import { Button, LoadingLogoInset, Text } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';
import styled from 'styled-components';
import { usePendingInvitedOrganizations } from '@vizcom/shared/data-access/graphql';
import { OrganizationAvatar } from '@vizcom/shared-ui-components';

export const CreateOrganizationJoinExistingPage = () => {
  const { data: pendingInvitedOrganizations, fetching } =
    usePendingInvitedOrganizations();

  if (fetching) {
    return (
      <Container>
        <LoadingLogoInset />
      </Container>
    );
  }

  if (!pendingInvitedOrganizations) {
    throw new Error('Cannot fetch pending organization invites');
  }

  if (pendingInvitedOrganizations.length > 0) {
    return (
      <Container>
        <Text block type="t1">
          Join an existing workspace
        </Text>

        <Text block>
          The email address you used to sign up is registered to the following
          workspaces. You can choose to join these or continue creating your
          own.
        </Text>

        {pendingInvitedOrganizations.map((pendingInvite) => (
          <Button
            as={Link}
            to={paths.organization.acceptInvite(pendingInvite.id)}
            key={pendingInvite.id}
            variant="bordered"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 12,
            }}
          >
            <OrganizationAvatar organization={pendingInvite} />
            <Text block>{pendingInvite.name}</Text>
          </Button>
        ))}

        <Text>Or</Text>

        <Button
          variant="outline"
          as={Link}
          to={paths.organization.create_name()}
        >
          Create a new workspace
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      <Text block type="t1">
        Join an existing workspace
      </Text>
      <Text>
        To join an existing workspace please contact the admin of the workspace
        you would like to join and ask them to invite you.
      </Text>
      <Text block>
        Once the invitation has been sent, please check your email inbox and use
        the link to continue.
      </Text>

      <Button
        variant="transparent"
        as={Link}
        to={paths.organization.create_name()}
      >
        Back
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 32px;
  text-align: center;
`;
