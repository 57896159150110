import { eventTargetIsInput } from '../helpers';
import { OperatingSystem, getOS } from '../userAgent';
import { useDocumentEventListener } from './useDocumentEventListener';

export type KeyboardShortcutModifiers = {
  shift?: boolean;
  ctrl?: boolean;
  capture?: boolean;
};

export const useKeyboardShortcut = (
  key: string | string[],
  callback: (e: KeyboardEvent) => void,
  {
    shift = false,
    ctrl = false,
    capture = false,
  }: KeyboardShortcutModifiers = {}
) => {
  const isMac = getOS() === OperatingSystem.MacOS;

  const keys = Array.isArray(key)
    ? key.map((k) => k.toLowerCase())
    : [key.toLowerCase()];

  useDocumentEventListener(
    'keydown',
    (e) => {
      if (
        keys.includes(e.key?.toLowerCase()) &&
        !eventTargetIsInput(e) &&
        shift === e.shiftKey &&
        ctrl === (isMac ? e.metaKey : e.ctrlKey)
      ) {
        e.stopPropagation();
        e.preventDefault();
        callback(e);
      }
    },
    {
      capture,
    }
  );
};
