import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import {
  Container,
  Frame,
  Section,
  Divider,
  Content,
  Field,
  HeaderPrimary,
  HeaderSecondary,
  HeaderIcon,
} from './style';
import {
  Dropdown,
  InferenceStyleOption,
  RangeInput,
  Text,
  Tooltip,
} from '@vizcom/shared-ui-components';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { ObjectTransform } from '../objectTransform/ObjectTransform';
import { MaterialsList } from '../materialsList/MaterialsList';
import { MutableRefObject, useMemo } from 'react';
import { RootState } from '@react-three/fiber';
import { SceneConfiguration } from '../sceneConfiguration/SceneConfiguration';
import { CameraConfiguration } from '../cameraConfiguration/CameraConfiguration';
import { PromptInput } from '../../studio/create/PromptInput';
import { CustomInputRow } from './CustomInputRow';
import { useTheme } from 'styled-components';
import { EnvironmentConfiguration } from '../environmentConfiguration/EnvironmentConfiguration';
import { Icon } from '../ui/Icon/Icon';

import CameraIcon from '../../../assets/icons/camera.svg';

// const WITH_STYLE_SECTION = false;

export const CompositeSceneMenu = ({
  compositeScene,
  handleAction,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
  threeStateRef: MutableRefObject<RootState | null>;
}) => {
  const theme = useTheme();
  const { selected, activeCamera } = useCompositeSceneEditorContext();
  // const renderStyle = useMemo(() => {
  //   return (
  //     INFERENCE_RENDER_STYLE_CATEGORY_RENDER.renderStyles.find(
  //       (style) => style.value === compositeScene.renderStyle
  //     )?.value || INFERENCE_RENDER_STYLE_CATEGORY_RENDER.renderStyles[0].value
  //   );
  // }, [compositeScene.renderStyle]);

  return (
    <Container>
      <Frame $actions="top">
        <HeaderPrimary>
          <Text type="sh1">Create</Text>
        </HeaderPrimary>
        <Divider />
        <Content>
          <Section>
            <Field style={{ maxHeight: 204 }}>
              <HeaderSecondary>
                <Text type="sh2">Prompt</Text>
              </HeaderSecondary>
              <PromptInput
                placeholder="What are you creating?"
                value={compositeScene.prompt}
                variant="tertiary"
                onChange={(prompt) => {
                  handleAction({
                    type: 'updateCompositeScene',
                    prompt,
                  });
                }}
              />
              <CustomInputRow
                before={
                  <span style={{ color: theme.text.info }}>3D influence</span>
                }
                after={
                  <span style={{ color: '#D5D5DD' }}>
                    {`${(compositeScene.influence * 100.0).toFixed(0)}%`}
                  </span>
                }
              >
                <RangeInput
                  variant="tertiary"
                  min={0}
                  max={100}
                  step={5}
                  value={compositeScene.influence * 100.0}
                  onChange={(e) => {
                    handleAction({
                      type: 'updateCompositeScene',
                      influence: parseFloat(e.target.value) / 100.0,
                    });
                  }}
                />
              </CustomInputRow>
            </Field>
          </Section>
          <Divider />
          {/* {WITH_STYLE_SECTION && (
            <>
              <Section>
                <Field style={{ maxHeight: 204 }}>
                  <HeaderSecondary>
                    <Text type="sh2">Style</Text>
                  </HeaderSecondary>
                  <Dropdown
                    options={INFERENCE_RENDER_STYLE_CATEGORY_RENDER.renderStyles.map(
                      (style) => ({
                        data: style,
                      })
                    )}
                    value={renderStyle}
                    variant="tertiary"
                    setValue={(value) => {
                      handleAction({
                        type: 'updateCompositeScene',
                        renderStyle: value,
                      });
                    }}
                    buttonProps={{
                      style: {
                        backgroundColor: theme.surface.e1,
                        fontSize: 12,
                      },
                    }}
                    optionToValueMapper={({ value }) => value}
                    OptionComponent={InferenceStyleOption}
                  >
                    {renderStyleToName(
                      renderStyle,
                      INFERENCE_RENDER_STYLE_CATEGORY_RENDER.renderStyles
                    )}
                  </Dropdown>
                </Field>
              </Section>
              <Divider />
            </>
          )} */}
          <Section>
            <Field style={{ maxHeight: 204 }}>
              <HeaderSecondary>
                <Text type="sh2">Materials</Text>
              </HeaderSecondary>
              <MaterialsList
                compositeScene={compositeScene}
                handleAction={handleAction}
              />
            </Field>
          </Section>
          <Divider />
          {selected ? (
            <>
              <Section>
                <Field>
                  <HeaderSecondary>
                    <Text type="sh2">Transformation</Text>
                  </HeaderSecondary>
                  {!!selected && (
                    <ObjectTransform
                      handleAction={handleAction}
                      compositeScene={compositeScene}
                    />
                  )}
                </Field>
              </Section>
            </>
          ) : (
            <>
              <Section>
                <Field>
                  <HeaderSecondary>
                    <Text type="sh2">Scene</Text>
                  </HeaderSecondary>
                  <SceneConfiguration
                    handleAction={handleAction}
                    compositeScene={compositeScene}
                  />
                </Field>
              </Section>
              <Divider />
              <Section>
                <Field>
                  <HeaderSecondary>
                    <Text type="sh2">Environment</Text>
                  </HeaderSecondary>
                  <EnvironmentConfiguration
                    handleAction={handleAction}
                    compositeScene={compositeScene}
                  />
                </Field>
              </Section>
              <Divider />
              <Section>
                <Field $color={activeCamera ? theme.surface.e1 : undefined}>
                  <HeaderSecondary>
                    <Text type="sh2">Camera</Text>
                    {activeCamera && (
                      <Tooltip tip="Only selected camera" position="left">
                        <HeaderIcon>
                          <Icon
                            icon={CameraIcon}
                            size={12}
                            color={theme.surface.e2}
                          />
                        </HeaderIcon>
                      </Tooltip>
                    )}
                  </HeaderSecondary>
                  <CameraConfiguration
                    handleAction={handleAction}
                    compositeScene={compositeScene}
                  />
                </Field>
              </Section>
            </>
          )}
        </Content>
      </Frame>
    </Container>
  );
};
