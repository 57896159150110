import { LoggedInAuthGuard } from '@vizcom/auth/ui';
import { Layout } from '@vizcom/shared-ui-components';
import { Navigate, Route, Routes } from 'react-router-dom';
import { OrganizationTeams } from './OrganizationTeams/OrganizationTeams';
import { OrganizationMembers } from './OrganizationMembers/OrganizationMembers';
import { OrganizationAccess } from './OrganizationAccess';
import { OrganizationActivity } from './OrganizationActivity';
import { OrganizationSubscription } from './OrganizationSubscription/OrganizationSubscription';
import { OrganizationGeneralSettings } from './OrganizationGeneralSettings';
import { SettingsSidebar } from './components/SettingsSidebar';
import { ProfileSettingsPage } from './ProfileSettings/ProfileSettings';
import { ConfirmDeleteAccountPage } from './ConfirmDeleteAccountPage';
import { ChangelogPage } from './App/ChangelogPage';
import { OrganizationConfirmDelete } from './OrganizationConfirmDelete/OrganizationConfirmDelete';
import { OrganizationSecurityPage } from './OrganizationSecurity/OrganizationSecurityPage';

export const SettingsPage = () => {
  return (
    <LoggedInAuthGuard>
      <Layout sidebar={<SettingsSidebar />}>
        <Routes>
          <Route
            path="organization/:organizationId/general"
            element={<OrganizationGeneralSettings />}
          />
          <Route
            path="organization/:organizationId/members"
            element={<OrganizationMembers openInviteMembers={false} />}
          />
          <Route
            path="organization/:organizationId/members/invite"
            element={<OrganizationMembers openInviteMembers={true} />}
          />
          <Route
            path="organization/:organizationId/confirm_delete"
            element={<OrganizationConfirmDelete />}
          />
          <Route
            path="organization/:organizationId/teams"
            element={<OrganizationTeams />}
          />
          <Route
            path="organization/:organizationId/teams/create"
            element={<OrganizationTeams createTeamOpen />}
          />
          <Route
            path="organization/:organizationId/teams/:teamId/settings"
            element={<OrganizationTeams openSettingsForSelectedTeam />}
          />
          <Route
            path="organization/:organizationId/teams/:teamId/invite"
            element={<OrganizationTeams openAddMembersForSelectedTeam />}
          />
          <Route
            path="organization/:organizationId/teams/:teamId/join"
            element={<OrganizationTeams autoJoinSelectedTeam />}
          />
          <Route
            path="organization/:organizationId/subscription"
            element={<OrganizationSubscription />}
          />
          <Route
            path="organization/:organizationId/security"
            element={<OrganizationSecurityPage />}
          />
          <Route
            path="organization/:organizationId/access"
            element={<OrganizationAccess />}
          />
          <Route
            path="organization/:organizationId/activity"
            element={<OrganizationActivity />}
          />

          <Route path="account/profile" element={<ProfileSettingsPage />} />
          <Route
            path="account/confirm_delete"
            element={<ConfirmDeleteAccountPage />}
          />

          <Route path="app/changelog" element={<ChangelogPage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </LoggedInAuthGuard>
  );
};
