import { useStringHashNumber } from '@vizcom/shared-ui-components';
import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { useSpring, animated } from '@react-spring/three';
import { MAX_Z_POSITION } from '../helpers';
import cursorSrc from '../../icons/cursor.svg?raw';
import { StaticSvg } from '../utils/StaticSvg';
import { TextWithBackground } from '../utils/TextWithBackground';
import { FixedSizeGroup } from '../utils/FixedSizeGroup';
import {
  getMultiplayerDisplayConfig,
  getMultiplayerUserId,
} from './multiplayerDisplayConfig';

export const MultiplayerPresenceCursor = (props: {
  presence: MultiplayerPresence;
  editingElementId: string | null;
}) => {
  const { presence, editingElementId } = props;

  const userId = getMultiplayerUserId(presence.id);
  const hash = useStringHashNumber(userId);
  const { position } = useSpring({
    position: [presence.cursor?.x ?? 0, presence.cursor?.y ?? 0],
  });

  if (!presence.cursor || !presence.id) return null;
  if (editingElementId !== presence.editingElementId) return null;

  const displayConfig = getMultiplayerDisplayConfig(hash, presence.user);

  return (
    <animated.group
      position={position.to((x, y) => [x, y, MAX_Z_POSITION + 1])}
    >
      <FixedSizeGroup>
        <StaticSvg
          fillMaterial={{ color: displayConfig.color }}
          svg={cursorSrc}
        />
        <TextWithBackground
          position={[15, -15, 0]}
          padding={[18, 10]}
          color="white"
          backgroundColor={displayConfig.color}
          fontWeight={500}
        >
          {displayConfig.name}
        </TextWithBackground>
      </FixedSizeGroup>
    </animated.group>
  );
};
