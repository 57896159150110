import {
  ExportIcon,
  TooltipMenu,
  TooltipMenuItem,
  addToast,
  downloadFile,
  formatErrorMessage,
  useTriggerPaywallModalOpen,
} from '@vizcom/shared-ui-components';
import {
  OrganizationSubscriptionPlan,
  SetCurrentUserClientStateMutation,
  UserClientStateKeys,
  meshByIdQuery,
  publishTrackingEvent,
  urqlClient,
  useCurrentUserClientStateByKey,
} from '@vizcom/shared/data-access/graphql';
import { Layout } from './types';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';
import { Drawing2dStudio } from '../../lib/useDrawingSyncedState';
import { StudioEventName } from '@vizcom/shared/data-shape';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  ExportFormat,
  exportObject3d,
  loadGltfFromUrl,
} from '../utils/meshHelpers';
import styled from 'styled-components';
import { useState } from 'react';

const FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS = 3;

const Layer3dExportItem = ({
  exportFormat,
  handleExportLayer3D,
  isPendingExport,
}: {
  exportFormat: ExportFormat;
  handleExportLayer3D: (args: { exportFormat: ExportFormat }) => void;
  isPendingExport: ExportFormat | null;
}) => (
  <TooltipMenuItem
    icon={<ExportIcon />}
    label={
      isPendingExport === exportFormat ? (
        <ExportLabelPrefix>Exporting...</ExportLabelPrefix>
      ) : (
        <>
          <ExportLabelPrefix>Export to </ExportLabelPrefix>
          <span>.{exportFormat}</span>
        </>
      )
    }
    onClick={() => handleExportLayer3D({ exportFormat })}
  />
);

export const Layer3dMeshExportMenu = ({
  isOpen,
  onClose,
  layer,
  subscriptionPlan,
}: {
  isOpen: boolean;
  onClose: () => void;
  layer: Drawing2dStudio['layers']['nodes'][0];
  subscriptionPlan: OrganizationSubscriptionPlan;
}) => {
  const [isPendingExport, setIsPendingExport] = useState<ExportFormat | null>(
    null
  );
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const layer3dExportCount =
    useCurrentUserClientStateByKey(UserClientStateKeys.Layer_3DExportCount) ??
    0;
  const triggerPaywallModal = useTriggerPaywallModalOpen();
  const metadata3D = layer.metadata3D as LayerMetadata3d | undefined;

  const handleExportLayer3D = async ({
    exportFormat,
  }: {
    exportFormat: ExportFormat;
  }) => {
    const isFreePlan = subscriptionPlan === OrganizationSubscriptionPlan.Free;

    if (metadata3D && isFreePlan) {
      if (layer3dExportCount >= FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS) {
        addToast(
          `You already exported ${FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS} 3D models. Please upgrade to a paid plan to export more.`,
          {
            type: 'danger',
          }
        );
        triggerPaywallModal();

        onClose();

        return;
      }

      await urqlClient.mutation(SetCurrentUserClientStateMutation, {
        input: {
          key: UserClientStateKeys.Layer_3DExportCount,
          value: layer3dExportCount + 1,
        },
      });
    }

    setIsPendingExport(exportFormat);

    let downloadPath: string;

    if (layer.meshPath) {
      downloadPath =
        layer.meshPath instanceof Blob
          ? URL.createObjectURL(layer.meshPath)
          : layer.meshPath;
    } else if (metadata3D?.mesh) {
      const res = await urqlClient
        .query(meshByIdQuery, {
          id: metadata3D.mesh,
        })
        .toPromise();

      if (res.error || !res.data?.mesh?.path) {
        addToast(`Error while exporting 3D model`, {
          secondaryText: formatErrorMessage(res.error),
          type: 'danger',
        });

        setIsPendingExport(null);
        onClose();

        return;
      }

      downloadPath = res.data.mesh.path;
    } else {
      addToast(`Error while exporting 3D model: unable to download the model`, {
        type: 'danger',
      });

      setIsPendingExport(null);
      onClose();

      return;
    }

    const gltf = await loadGltfFromUrl(downloadPath);
    const model = await exportObject3d(gltf, exportFormat);
    const blob = new Blob([model.result], { type: model.resultType });

    downloadFile(blob, layer.name, exportFormat);

    if (layer.meshPath instanceof Blob) {
      URL.revokeObjectURL(downloadPath);
    }

    publishTrackingEvent({
      type: StudioEventName.EXPORT,
      data: {
        exportType: '3D',
      },
    });
    trackEvent('3D Export', { type: 'exportLayers3D' });

    setIsPendingExport(null);
    onClose();
  };

  return (
    <TooltipMenu
      direction={layout === 'default' ? 'left' : 'right'}
      isOpen={isOpen}
      onClose={onClose}
      customHorizontalMargin={30}
      customVerticalMargin={-5}
    >
      {isPendingExport && <ExportPendingOverlay />}
      {['glb', 'obj', 'stl', 'usdz'].map((exportFormat) => (
        <Layer3dExportItem
          key={exportFormat}
          exportFormat={exportFormat as ExportFormat}
          isPendingExport={isPendingExport}
          handleExportLayer3D={handleExportLayer3D}
        />
      ))}
    </TooltipMenu>
  );
};

const ExportLabelPrefix = styled.span`
  color: ${({ theme }) => theme.text.info};
`;

const ExportPendingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
