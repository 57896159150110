import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Dispatch,
  SetStateAction,
  useMemo,
  MouseEvent,
  useState,
  useEffect,
} from 'react';
import {
  RangeInput,
  FeatureFlagged,
  useFeatureFlag,
  useIsPro,
  Button,
  NumberInput,
  Text,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  AutoplayVideo,
  OnboardingTooltip,
  OnboardingStepName,
  useCompleteOnboardingStep,
  Spinner,
  OnboardingTourTooltipContent,
  useShouldDisplayOnboardingTour,
} from '@vizcom/shared-ui-components';
import {
  ImageInferenceType,
  PromptData,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { Choice } from './Choice';
import {
  Container,
  Field,
  Header,
  Divider,
  Generate,
  ScrollableContent,
  HoverableHeaderSecondary,
  AutoPrompt,
} from '../style';
import { FidelityArea } from './FidelityArea';
import { ImageInferenceTypeToggle } from './ImageInferenceTypeToggle';
import {
  PublicPalette,
  promptLegacyTypeToModeAndPalette,
} from '../../../../../../../shared/inference-worker-queues/src';
import { PromptInput } from './PromptInput';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { InferenceSettings } from '../useInference';
import { StyleSettings } from './StyleSettings';
import { useAvailablePalettes } from '../../modals/PaletteSelector';
import { PaywallEventName } from '@vizcom/shared/data-shape';
import { getNewPromptFromTags } from '../../elements/palette/helpers';
import drawingSliderInfo from './assets/tooltip_drawing_influence.mp4';
import { useTheme } from 'styled-components';
import {
  OnboardingStep,
  useOnboardingMultiStep,
} from '../../utils/OnboardingMultiStep';
import educationalTooltipVideo02 from '../../../assets/videos/educational-tooltip-02.mp4';

type Props = {
  drawing: Drawing2dStudio;
  anyOutputLoading?: boolean;
  inferenceSettings: InferenceSettings;
  selectedHistoryPrompt?: PromptData;
  isGeneratingPrompt: boolean;
  applyHistoryItem: (inferenceSettings: InferenceSettings) => void;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  triggerPaywallModal: () => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  trigger: () => void;
  triggerAutoPrompt: () => void;
  cancelAutoPrompt: () => void;
};

export const Create = ({
  drawing,
  anyOutputLoading,
  inferenceSettings,
  selectedHistoryPrompt,
  isGeneratingPrompt,
  applyHistoryItem,
  setInferenceSettings,
  trigger,
  triggerAutoPrompt,
  triggerPaywallModal,
  cancelAutoPrompt,
}: Props) => {
  const { currentStep } = useOnboardingMultiStep();
  const isPro = useIsPro(drawing.id);
  const availablePalettes = useAvailablePalettes(drawing.workbench?.id);
  const historyPreviewActive = Boolean(selectedHistoryPrompt?.id);
  const theme = useTheme();
  const [showCancelAutoPrompt, setShowCancelAutoPrompt] = useState(false);
  const [cancelTimeout, setCancelTimeout] = useState<
    NodeJS.Timeout | undefined
  >();
  const completeOnboardingStep = useCompleteOnboardingStep();
  const shouldDisplayTour = useShouldDisplayOnboardingTour();

  const {
    outputsCount,
    sourceImageInfluence,
    prompt,
    fidelity,
    paletteInfluence,
    publicPaletteId,
    customModelId,
    workbenchPaletteId,
    imageInferenceType,
    negativePrompt,
    styleReferenceId,
    styleReferenceStrength,
  } = useMemo(() => {
    if (!selectedHistoryPrompt?.id) return inferenceSettings;

    let imageInferenceType = selectedHistoryPrompt.imageInferenceType;
    let publicPaletteId = selectedHistoryPrompt.publicPaletteId;
    if (selectedHistoryPrompt.legacyType) {
      const legacyType = promptLegacyTypeToModeAndPalette(
        selectedHistoryPrompt.legacyType
      );
      imageInferenceType =
        legacyType.mode === 'render'
          ? ImageInferenceType.Render
          : ImageInferenceType.Refine;
      publicPaletteId = legacyType.publicPaletteId;
    }

    const savedSettings = {
      outputsCount: selectedHistoryPrompt.outputs.nodes.length > 1 ? 4 : 1,
      sourceImageInfluence: selectedHistoryPrompt.sourceImageInfluence,
      paletteInfluence: selectedHistoryPrompt.paletteInfluence,
      prompt: selectedHistoryPrompt.text,
      publicPaletteId,
      customModelId: selectedHistoryPrompt.customModelId,
      workbenchPaletteId: selectedHistoryPrompt.workbenchPaletteId,
      imageInferenceType,
      fidelity: inferenceSettings.fidelity,
      negativePrompt: inferenceSettings.negativePrompt,
      styleReferenceId: undefined,
      styleReferenceStrength: 1,
    } as InferenceSettings;

    if (selectedHistoryPrompt.styleReferenceImagePath) {
      const styleReference = drawing?.workbench?.styleReferences?.nodes.find(
        (ref) => ref.imagePath === selectedHistoryPrompt.styleReferenceImagePath
      );
      if (styleReference) {
        savedSettings.styleReferenceId = styleReference.id;
        savedSettings.styleReferenceStrength = 1;
      }
    }

    return savedSettings;
  }, [selectedHistoryPrompt, inferenceSettings, drawing]);

  const selectedPaletteId =
    publicPaletteId ??
    customModelId ??
    workbenchPaletteId ??
    PublicPalette.generalV2;

  const onOutputsCountChange = (value: string) => {
    setInferenceSettings((prev) => ({
      ...prev,
      outputsCount: Number(value),
    }));
  };

  const onSourceImageInfluenceChange = (sourceImageInfluence: number) => {
    setInferenceSettings((prev) => ({
      ...prev,
      sourceImageInfluence: sourceImageInfluence / 100.0,
    }));
  };

  const onPaletteInfluenceChange = (paletteInfluence: number) => {
    setInferenceSettings((prev) => ({
      ...prev,
      paletteInfluence: paletteInfluence,
    }));
  };
  const handleLocked = () => {
    trackEvent('Paywall', {
      action: 'Prompt Count',
      location: 'Prompt',
    });
    publishTrackingEvent({
      type: PaywallEventName.PAYWALL_DISPLAYED,
      data: {
        action: 'prompt-count',
        location: 'prompt',
      },
    });
    triggerPaywallModal();
  };

  const handleSelectPalette = (paletteId: string, type: string) => {
    const tags =
      availablePalettes.find((p) => p.value === paletteId)?.tags ??
      availablePalettes.find(
        (p) => p.versions && p.versions.find((v) => v.value === paletteId)
      )?.tags ??
      [];
    setInferenceSettings((prev) => ({
      ...prev,
      publicPaletteId: undefined,
      customModelId: undefined,
      workbenchPaletteId: undefined,
      [type]: paletteId,
      prompt:
        type === 'workbenchPaletteId'
          ? getNewPromptFromTags(tags, prev.prompt)
          : prev.prompt,
    }));
  };

  const applySettings = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    applyHistoryItem({
      ...inferenceSettings,
      outputsCount,
      sourceImageInfluence,
      paletteInfluence,
      prompt,
      publicPaletteId,
      customModelId,
      workbenchPaletteId,
      imageInferenceType,
      fidelity,
      negativePrompt,
      styleReferenceId,
      styleReferenceStrength,
    });
  };

  const { flagEnabled: fidelityFlagEnabled } = useFeatureFlag('FIDELITY');

  useEffect(() => {
    if (!isGeneratingPrompt) {
      clearTimeout(cancelTimeout);
      setShowCancelAutoPrompt(false);
    }
  }, [isGeneratingPrompt]);

  if (!drawing) {
    return null;
  }

  return (
    <Container>
      <ImageInferenceTypeToggle
        value={imageInferenceType}
        disabled={historyPreviewActive}
        onToggle={() => {
          setInferenceSettings((prev) => ({
            ...prev,
            imageInferenceType:
              prev.imageInferenceType === ImageInferenceType.Render
                ? ImageInferenceType.Refine
                : ImageInferenceType.Render,
          }));
        }}
      />
      <Divider />
      <ScrollableContent>
        <Field>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <RichTooltip
              isOpen={shouldDisplayTour && currentStep === 2}
              placement="left"
              padding={88}
            >
              <RichTooltipTrigger>
                <Header>Prompt</Header>
              </RichTooltipTrigger>
              <OnboardingTourTooltipContent>
                <OnboardingStep
                  video={educationalTooltipVideo02}
                  title="Prompt"
                  content="Describe your designs form, materials, and background, or have Vizcom do it for you."
                />
              </OnboardingTourTooltipContent>
            </RichTooltip>
            {!isGeneratingPrompt && !historyPreviewActive && (
              <OnboardingTooltip
                previousStep={OnboardingStepName.NewDrawingAutoPrompt}
                name={OnboardingStepName.AutoPrompt}
                durationVisible={3000}
                title={
                  <span>
                    Click to generate a prompt
                    <br />
                    from canvas content
                  </span>
                }
              >
                <AutoPrompt
                  onClick={() => {
                    triggerAutoPrompt();
                    completeOnboardingStep(OnboardingStepName.AutoPrompt);
                  }}
                >
                  Describe
                </AutoPrompt>
              </OnboardingTooltip>
            )}
            {isGeneratingPrompt && !showCancelAutoPrompt && (
              <div
                onPointerEnter={() => {
                  const timeout = setTimeout(
                    () => setShowCancelAutoPrompt(true),
                    300
                  );
                  setCancelTimeout(timeout);
                }}
                onPointerLeave={() => {
                  clearTimeout(cancelTimeout);
                  setCancelTimeout(undefined);
                }}
                style={{
                  marginRight: '20px',
                }}
              >
                <Spinner
                  size={{
                    width: 14,
                    height: 14,
                  }}
                />
              </div>
            )}
            {isGeneratingPrompt && showCancelAutoPrompt && (
              <AutoPrompt
                onPointerOut={() => setShowCancelAutoPrompt(false)}
                onClick={() => {
                  setShowCancelAutoPrompt(false);
                  cancelAutoPrompt();
                }}
              >
                Cancel?
              </AutoPrompt>
            )}
          </div>
          <PromptInput
            placeholder={
              !prompt.length && isGeneratingPrompt
                ? 'Describing the canvas'
                : 'What are you creating?'
            }
            value={prompt}
            disabled={historyPreviewActive}
            onChange={(v) =>
              setInferenceSettings((prev) => ({ ...prev, prompt: v }))
            }
            onEnter={trigger}
            onFocus={() => {
              cancelAutoPrompt();
            }}
          />
        </Field>
        <FeatureFlagged flag="NEGATIVE_PROMPT">
          <Field>
            <Header>Negative Prompt</Header>
            <PromptInput
              disabled={historyPreviewActive}
              placeholder="What should be excluded from the image?"
              value={negativePrompt}
              onChange={(v) =>
                setInferenceSettings((prev) => ({
                  ...prev,
                  negativePrompt: v,
                }))
              }
              onEnter={trigger}
            />
          </Field>
        </FeatureFlagged>
        <Divider />
        <StyleSettings
          drawing={drawing}
          historyPreviewActive={historyPreviewActive}
          availablePalettes={availablePalettes}
          selectedPaletteId={selectedPaletteId}
          styleReferenceId={styleReferenceId}
          styleReferenceStrength={styleReferenceStrength}
          imageInferenceType={imageInferenceType}
          setInferenceSettings={setInferenceSettings}
          handleSelectPalette={handleSelectPalette}
          paletteInfluence={paletteInfluence}
          onPaletteInfluenceChange={onPaletteInfluenceChange}
        />
        <Divider />
        {fidelityFlagEnabled ? (
          <Field>
            <div
              style={{
                position: 'relative',
                padding: '20px',
                pointerEvents: historyPreviewActive ? 'none' : 'all',
                maskImage: historyPreviewActive
                  ? 'linear-gradient( to bottom, rgba(21,21,23,1) -50%, transparent 100% )'
                  : 'none',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  transformOrigin: 'top left',
                  transform: 'rotate(-90deg) translate(-100%)',
                }}
              >
                <span>{Math.round(fidelity / 5) * 5}%</span> Fidelity
              </div>
              <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <span>{Math.round(sourceImageInfluence * 100)}%</span> Influence
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FidelityArea
                  setFidelity={(value) =>
                    setInferenceSettings((prev) => ({
                      ...prev,
                      fidelity: value,
                    }))
                  }
                  setInfluence={(value) =>
                    setInferenceSettings((prev) => ({
                      ...prev,
                      sourceImageInfluence: value,
                    }))
                  }
                />
              </div>
            </div>
          </Field>
        ) : (
          <Field
            style={{
              pointerEvents: historyPreviewActive ? 'none' : 'all',
              maskImage: historyPreviewActive
                ? 'linear-gradient( to bottom, rgba(21,21,23,1) -50%, transparent 100% )'
                : 'none',
            }}
          >
            <Header>Drawing</Header>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr auto',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <RichTooltip
                trigger="hover"
                placement="left"
                delay={{
                  open: 750,
                  close: 0,
                }}
                padding={16}
              >
                <RichTooltipTrigger>
                  <HoverableHeaderSecondary>Influence</HoverableHeaderSecondary>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ maxWidth: '201px' }}>
                  <Text>Drawing Influence</Text>
                  <Text
                    type="b2"
                    color="info"
                    block
                    style={{
                      marginTop: '6px',
                      marginBottom: '8px',
                      lineHeight: '1.4',
                    }}
                  >
                    Adjust how much the AI should consider your drawing when
                    generating the image
                  </Text>
                  <div style={{ textAlign: 'right', marginBottom: '12px' }}>
                    <a
                      href="https://docs.vizcom.ai/drawing-influence"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: '10px',
                        color: theme.primary.default,
                        textDecoration: 'none',
                      }}
                    >
                      Learn more
                    </a>
                  </div>
                  <AutoplayVideo
                    src={drawingSliderInfo}
                    style={{
                      width: '100%',
                      height: 'auto',
                      aspectRatio: '16 / 9',
                      objectFit: 'cover',
                      borderRadius: '6px',
                    }}
                  />
                </RichTooltipContent>
              </RichTooltip>
              <RangeInput
                value={Math.floor(sourceImageInfluence * 100)}
                min={0}
                max={100}
                step={5}
                onChange={(e) =>
                  onSourceImageInfluenceChange(parseInt(e.target.value))
                }
              />
              <Text>
                <NumberInput
                  value={Math.floor(sourceImageInfluence * 100)}
                  min={0}
                  max={100}
                  unit="%"
                  setValue={(v) => onSourceImageInfluenceChange(v)}
                  dragArrows={false}
                />
              </Text>
            </div>
          </Field>
        )}
        <Divider />

        <Field
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Header>Images</Header>
          <Choice
            choices={[
              { name: '1', value: '1' },
              { name: '4', value: '4', locked: !isPro },
            ]}
            value={outputsCount}
            onChange={onOutputsCountChange}
            onLocked={handleLocked}
            disabled={historyPreviewActive}
          />
        </Field>
      </ScrollableContent>
      <Generate>
        {historyPreviewActive ? (
          <Button
            variant="secondary"
            disabled={anyOutputLoading}
            onClick={applySettings}
          >
            Apply Settings
          </Button>
        ) : (
          <Button
            variant={'primary'}
            disabled={anyOutputLoading}
            onClick={trigger}
          >
            {anyOutputLoading ? 'Generating...' : 'Generate'}
          </Button>
        )}
      </Generate>
    </Container>
  );
};
