//Ready to use r3f components for 2D shapes

import { MeshProps } from '@react-three/fiber';
import { DoubleSide, MeshBasicMaterial, ColorRepresentation } from 'three';
import { useEffect, useRef } from 'react';
import { sdBox, sdCircle } from '../../lib/glsl';

export function Square({
  size,
  color = 'red',
  opacity = 1,
  depthWrite = true,
  ...meshProps
}: {
  size: number;
  color?: ColorRepresentation;
  opacity?: number;
  depthWrite?: boolean;
} & MeshProps) {
  return (
    <mesh scale={[size, size, 1]} {...meshProps}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <meshBasicMaterial
        transparent
        opacity={opacity}
        color={color}
        side={DoubleSide}
        depthWrite={depthWrite}
      />
    </mesh>
  );
}

export function OutlinedRoundedSquare({
  size,
  color = 'red',
  opacity = 1,
  ...meshProps
}: {
  size: number;
  color?: ColorRepresentation;
  opacity?: number;
} & MeshProps) {
  //box and ring from https://iquilezles.org/articles/distfunctions2d/
  //antialias from http://www.numb3r23.net/2015/08/17/using-fwidth-for-distance-based-anti-aliasing/
  const matRef = useRef<MeshBasicMaterial | null>(null);
  useEffect(() => {
    if (matRef.current) {
      matRef.current.onBeforeCompile = (shader) => {
        //enable uvs in vertex and fragment shaders
        shader.vertexShader = `#define USE_UV true\n` + shader.vertexShader;
        shader.fragmentShader = `#define USE_UV true\n` + shader.fragmentShader;

        //add box SDF function
        shader.fragmentShader = shader.fragmentShader.replace(
          `void main() {`,
          `
          ${sdBox}
          void main() {
            `
        );

        //apply sdf
        shader.fragmentShader = shader.fragmentShader.replace(
          `vec4 diffuseColor = vec4( diffuse, opacity );`,
          `
            float boxRadius = 0.5;
            float outerBoxRadius = 0.5;
            float thickness = 0.25;

            vec2 p = vUv * 2.0 - 1.0;
            float box = sdBox(p, vec2(boxRadius));

            float alpha = box > outerBoxRadius ? 0.0 : 1.0;
            float dst = abs(box - boxRadius - thickness/2.0) - thickness/2.0;

            //antialiasing
            float aaf = fwidth(box);
            float outerAA = 1.0 - smoothstep(outerBoxRadius - aaf, outerBoxRadius, box);
            float innerAA = smoothstep(thickness - aaf, thickness, box);

            vec4 diffuseColor = vec4(
              mix(vec3(1.0), diffuse, innerAA * (1.0 - dst)),
              outerAA * alpha * opacity
            );
        `
        );
      };
      matRef.current.needsUpdate = true;
    }
  }, []);
  return (
    <mesh scale={[size, size, 1]} {...meshProps}>
      <planeGeometry />
      <meshBasicMaterial
        ref={matRef}
        transparent
        opacity={opacity}
        side={DoubleSide}
        color={color}
      />
    </mesh>
  );
}

export function Circle({
  radius,
  color = 'red',
  opacity = 1,
  ...meshProps
}: {
  radius: number;
  color?: ColorRepresentation;
  opacity?: number;
} & MeshProps) {
  return (
    <mesh scale={[0.5 * radius, 0.5 * radius, 1]} {...meshProps}>
      <circleGeometry args={[1, 32]} />
      <meshBasicMaterial
        transparent
        opacity={opacity}
        color={color}
        side={DoubleSide}
      />
    </mesh>
  );
}

export function OutlinedCircle({
  radius,
  color = 'red',
  opacity = 1,
  ...meshProps
}: {
  radius: number;
  color?: ColorRepresentation;
  opacity?: number;
} & MeshProps) {
  const matRef = useRef<MeshBasicMaterial | null>(null);
  useEffect(() => {
    if (matRef.current) {
      matRef.current.onBeforeCompile = (shader) => {
        //enable uvs in vertex and fragment shaders
        shader.vertexShader = `#define USE_UV true\n` + shader.vertexShader;
        shader.fragmentShader = `#define USE_UV true\n` + shader.fragmentShader;

        //add box SDF function
        shader.fragmentShader = shader.fragmentShader.replace(
          `void main() {`,
          `
          ${sdCircle}
          void main() {
            `
        );

        //apply sdf
        shader.fragmentShader = shader.fragmentShader.replace(
          `vec4 diffuseColor = vec4( diffuse, opacity );`,
          `
            float radius = 0.5;
            float outerBoxRadius = 0.5;
            float thickness = 0.25;

            vec2 p = vUv * 2.0 - 1.0;
            float circle = sdCircle(p, radius);

            float dst = abs(circle - radius - thickness/2.0) - thickness/2.0;

            //antialiasing
            float aaf = fwidth(circle);
            float innerAA = smoothstep(thickness - aaf, thickness, circle);

            vec4 diffuseColor = vec4(
              mix(vec3(1.0), diffuse, innerAA * (1.0 - dst)),
              opacity
            );
        `
        );
      };
      matRef.current.needsUpdate = true;
    }
  }, []);
  return (
    <mesh scale={[0.5 * radius, 0.5 * radius, 1]} {...meshProps}>
      <circleGeometry args={[1, 32]} />
      <meshBasicMaterial
        ref={matRef}
        transparent
        opacity={opacity}
        side={DoubleSide}
        color={color}
      />
    </mesh>
  );
}

type Point = [number, number];

export function Segment({
  from,
  to,
  thickness,
  color = 'red',
  opacity = 1,
  depthWrite = true,
  ...meshProps
}: {
  from: Point;
  to: Point;
  thickness: number;
  color?: ColorRepresentation;
  opacity?: number;
  depthWrite?: boolean;
} & MeshProps) {
  const dx = to[0] - from[0];
  const dy = to[1] - from[1];
  const angle = Math.atan2(dy, dx);
  const dist = Math.hypot(dx, dy);
  return (
    <mesh
      scale={[dist, thickness, 1]}
      rotation={[0, 0, angle]}
      position={[from[0] + dx / 2, from[1] + dy / 2, 0]}
      {...meshProps}
    >
      <planeGeometry args={[1, 1, 1, 1]} />
      <meshBasicMaterial
        transparent
        opacity={opacity}
        color={color}
        side={DoubleSide}
        depthWrite={depthWrite}
      />
    </mesh>
  );
}
