import { KeyboardEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

type VectorInputProps = {
  length: number;
  label?: string;
  fieldLabels?: {
    x?: string;
    y?: string;
    z?: string;
  };
  value: [number, number, number];
  allowReset?: boolean;
  resetValue?: [number, number, number];
  onChange: ([x, y, z]: [number, number, number]) => void;
};

export const VectorInput = ({
  length,
  label,
  fieldLabels,
  value,
  allowReset,
  resetValue,
  onChange,
}: VectorInputProps) => {
  const [tempValue, setTempValue] = useState<[string, string, string]>([
    '0.0',
    '0.0',
    '0.0',
  ]);
  const [focused, setFocused] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (focused || dirty) {
      // NOTE Prevent poor UX when user modifies the value while an update is received from the backend

      return;
    }

    setTempValue(
      value.map((item) => item.toFixed(6)) as [string, string, string]
    );
  }, [value, focused, dirty]);

  useEffect(() => {
    const update = setTimeout(() => {
      if (focused || !dirty) {
        return;
      }

      commitValue();
    }, 300);

    return () => clearTimeout(update);
  }, [focused, dirty, value]);

  const commitValue = () => {
    const result = tempValue
      .map((value) => parseFloat(value))
      .map((value) => (isNaN(value) ? 0.0 : value)) as [number, number, number];

    onChange(result);
    setDirty(false);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      commitValue();
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
    setDirty(true);
  };

  const onReset = () => {
    if (!allowReset) {
      return;
    }

    setTempValue(
      (resetValue ?? [0.0, 0.0, 0.0]).map((item) => item.toFixed(6)) as [
        string,
        string,
        string
      ]
    );
    commitValue();
  };

  return (
    <InputWrapper>
      {!!label && (
        <InputLabel
          onClick={(event) => {
            if (event.altKey || event.metaKey) {
              onReset();
            }
          }}
        >
          {label}
        </InputLabel>
      )}
      <InputFieldsList>
        {length >= 1 && (
          <InputFieldWrapper>
            <InputFieldLabel>{fieldLabels?.x ?? 'X'}</InputFieldLabel>
            <InputFieldValue
              value={tempValue[0]}
              onChange={(event) =>
                setTempValue((v) => [event.target.value, v[1], v[2]])
              }
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          </InputFieldWrapper>
        )}
        {length >= 2 && (
          <InputFieldWrapper>
            <InputFieldLabel>{fieldLabels?.y ?? 'Y'}</InputFieldLabel>
            <InputFieldValue
              value={tempValue[1]}
              onChange={(event) =>
                setTempValue((v) => [v[0], event.target.value, v[2]])
              }
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          </InputFieldWrapper>
        )}
        {length >= 3 && (
          <InputFieldWrapper>
            <InputFieldLabel>{fieldLabels?.z ?? 'Z'}</InputFieldLabel>
            <InputFieldValue
              value={tempValue[2]}
              onChange={(event) =>
                setTempValue((v) => [v[0], v[1], event.target.value])
              }
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          </InputFieldWrapper>
        )}
      </InputFieldsList>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: inline-flex;
  gap: 8px;
  width: 100%;
  align-items: start;
`;

const InputLabel = styled.div`
  margin-top: 6px;
  width: 60px;
  flex-shrink: 0;
`;

const InputFieldsList = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
`;

const InputFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.surface.e1};
  &:hover {
    background-color: ${({ theme }) => theme.surface.e2};
  }
`;

const InputFieldLabel = styled.div`
  padding: 6px;
  border-right: solid 1px ${({ theme }) => theme.black}66;
  color: ${({ theme }) => theme.text.tertiary};
  white-space: nowrap;
`;

const InputFieldValue = styled.input`
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;
  white-space: nowrap;
  width: 100%;
  padding: 6px;
  background: transparent;
  outline: none;
  border: none;
  border-left: solid 1px ${({ theme }) => theme.surface.e2};
  color: ${({ theme }) => theme.text.default};
  text-align: center;
`;
