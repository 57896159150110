import styled from 'styled-components';
import {
  FeatureFlagged,
  ImageBlockToolbarIcon,
  ImageIcon,
  ModalCloseButton,
  Text,
  useCompleteOnboardingStep,
} from '@vizcom/shared-ui-components';
import { ComponentType, MutableRefObject, memo, useState } from 'react';
import { RootState } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import {
  MAX_Z_POSITION,
  getWorkbenchElementZPositionRange,
  screenPositionToWorld,
} from '../helpers';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { createPortal } from 'react-dom';
import { elementCreators, ToolboxButton } from './elementCreatorButtons';
import { OrthographicCamera } from 'three';
import { handleImportFilesWithSceneContext } from '../utils/importFiles';

export const Toolbox = memo(
  (props: {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
    threeState: MutableRefObject<RootState>;
    handleSelectCreated: (id: string) => void;
    onOpenMobileUploadModal: () => void;
  }) => {
    const {
      open,
      setOpen,
      handleAction,
      threeState,
      handleSelectCreated,
      onOpenMobileUploadModal,
    } = props;

    const [draggingPlaceholder, setDraggingPlaceholder] = useState<null | {
      Button: ComponentType;
      x: number;
      y: number;
      width: number;
      height: number;
    }>(null);

    const completeOnboardingStep = useCompleteOnboardingStep();

    const bind = useDrag(
      ({ event, down, last, currentTarget, args, tap }) => {
        const clientX = 'clientX' in event ? event.clientX : 0;
        const clientY = 'clientY' in event ? event.clientY : 0;

        const creator = args[0] as typeof elementCreators[0];
        if (down) {
          setDraggingPlaceholder({
            Button: creator.Button,
            x: clientX,
            y: clientY,
            width: (currentTarget as HTMLDivElement).offsetWidth,
            height: (currentTarget as HTMLDivElement).offsetHeight,
          });
        } else {
          setDraggingPlaceholder(null);
        }

        const position = screenPositionToWorld(
          [clientX, clientY],
          threeState.current.camera as OrthographicCamera
        );

        const x = tap ? threeState.current.camera.position.x : position[0];
        const y = tap ? threeState.current.camera.position.y : position[1];
        if (last || tap) {
          const zRange = getWorkbenchElementZPositionRange(
            threeState.current.scene
          );

          const zIndex = isFinite(zRange[1])
            ? zRange[1] + 1
            : MAX_Z_POSITION / 2;

          const elementId = creator.action(
            {
              x,
              y,
              zIndex,
            },
            handleAction,
            completeOnboardingStep
          );

          handleSelectCreated(elementId);
          setOpen(false);
          setDraggingPlaceholder(null);
        }
      },
      {
        preventDefault: true,
        filterTaps: true,
      }
    );

    return (
      <>
        <ToolboxContainer $open={open && !draggingPlaceholder}>
          <ToolboxHeader>
            <ModalCloseButton onClick={() => setOpen(false)} />
          </ToolboxHeader>
          <ToolboxSectionPickerContainer>
            <ToolboxSectionPicker $active block type="button2">
              Blocks
            </ToolboxSectionPicker>
          </ToolboxSectionPickerContainer>
          <ToolboxContent>
            {elementCreators.map((creator, i) =>
              creator.featureFlag ? (
                <FeatureFlagged key={i} flag={creator.featureFlag}>
                  <creator.Button
                    key={i}
                    toolboxOpen={open}
                    {...bind(creator)}
                  />
                </FeatureFlagged>
              ) : (
                <creator.Button key={i} toolboxOpen={open} {...bind(creator)} />
              )
            )}
            <UploadImageButton
              onClick={async () => {
                await handleImportFilesWithSceneContext({
                  scene: threeState.current.scene,
                  camera: threeState.current.camera,
                  handleAction,
                });
                setOpen(false);
              }}
            >
              <ImageBlockToolbarIcon
                style={{ width: '24px', height: '24px' }}
              />
              <Text type="sh1">Upload image</Text>
            </UploadImageButton>
            <FeatureFlagged flag="WORKBENCH_PHONE_REDIRECT_ELEMENT">
              <UploadImageButton onClick={onOpenMobileUploadModal}>
                <ImageIcon style={{ width: '24px', height: '24px' }} />
                <Text type="sh1">Scan with phone</Text>
              </UploadImageButton>
            </FeatureFlagged>
          </ToolboxContent>
        </ToolboxContainer>
        {draggingPlaceholder &&
          createPortal(
            <DraggingIndicator
              style={{
                left: draggingPlaceholder.x,
                top: draggingPlaceholder.y,
                width: draggingPlaceholder.width,
                height: draggingPlaceholder.height,
              }}
            >
              {<draggingPlaceholder.Button />}
            </DraggingIndicator>,
            document.body
          )}
      </>
    );
  }
);

const UploadImageButton = styled(ToolboxButton)`
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
`;

const ToolboxContainer = styled.div<{ $open: boolean }>`
  background-color: ${(p) => p.theme.surface.e0};
  border-radius: ${(p) => p.theme.borderRadius.default};
  padding: 8px;
  pointer-events: all;
  position: absolute;
  top: calc(1rem + 66px);
  transform: translateX(
    ${({ $open }) => ($open ? '1rem' : 'calc(-100% - 1rem)')}
  );
  bottom: 100px;
  transition: all 0.3s ease-in-out;
  z-index: 100000000;
  width: 300px;
  color: ${({ theme }) => theme.text.info};
  gap: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
  pointer-events: all;
`;

const ToolboxHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ToolboxSectionPickerContainer = styled.div`
  display: flex;
  margin: -16px 0 12px; // -16px to account for the close icon
  border-bottom: 2px solid ${(p) => p.theme.surface.e2};
  gap: 8px;
`;

const ToolboxSectionPicker = styled(Text)<{ $active?: boolean }>`
  cursor: pointer;
  position: relative;
  padding: 19px 16px 13px;
  &:after {
    cursor: auto;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(p) => (p.$active ? p.theme.white : 'transparent')};
    bottom: -2px;
  }
`;

const ToolboxContent = styled.div`
  padding-top: 12px;
  display: grid;
  gap: 12px;
  max-height: calc(100% - 60px);
  overflow-y: auto;
`;

const DraggingIndicator = styled.div`
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100000000;
`;
