import { useCallback, useEffect } from 'react';
import { useLocalStorage } from './useLocalStorage';

const CAN_DRAW_WITH_FINGER_LOCALSTORAGE_KEY = 'vizcom:can_draw_with_finger';

// Return true if we received a stylus event at least once on this device
export const useCanDrawWithFinger = () => {
  const [value, setter] = useLocalStorage<null | boolean>(
    CAN_DRAW_WITH_FINGER_LOCALSTORAGE_KEY,
    null
  );

  const canDrawWithFinger = value !== false;
  const wasDrawWithFingerUpdated = value !== null;
  const setDrawWithFinger = useCallback(
    (value: boolean) => {
      setter(value);
    },
    [setter]
  );

  return {
    canDrawWithFinger,
    setDrawWithFinger,
    wasDrawWithFingerUpdated,
  };
};

// By default, disable finger drawing once we detect a stylus
export const useInitDrawWithFinger = ({
  hasStylus,
}: {
  hasStylus: boolean;
}) => {
  const { setDrawWithFinger, wasDrawWithFingerUpdated } =
    useCanDrawWithFinger();

  useEffect(() => {
    if (hasStylus && !wasDrawWithFingerUpdated) {
      setDrawWithFinger(false);
    }
  }, [hasStylus, wasDrawWithFingerUpdated, setDrawWithFinger]);
};
