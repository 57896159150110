import { ContextMenuDivider } from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { PropsWithChildren } from 'react';
import { DrawingContextMenuItems } from './contextMenuItemsPerType/Drawing';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { SharedContextMenuItems } from './contextMenuItemsPerType/Shared';
import { WorkbenchElementPaletteContextMenuItems } from './contextMenuItemsPerType/WorkbenchElementPaletteContextMenuItems';

export type ContextMenuItemsProps = {
  element: ClientSideWorkbenchElementData;
  workbenchId: string;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

const ContextMenuItems = ({
  element,
  children,
  handleAction,
}: PropsWithChildren<
  Pick<ContextMenuItemsProps, 'element' | 'handleAction'>
>) => {
  return (
    <>
      {children && (
        <>
          {children}
          <ContextMenuDivider />
        </>
      )}
      <SharedContextMenuItems element={element} handleAction={handleAction} />
    </>
  );
};

export const WorkbenchContextMenuByElementType = ({
  element,
  workbenchId,
  handleAction,
}: ContextMenuItemsProps) => {
  const commonProps = {
    element,
    workbenchId,
    handleAction,
  };

  // NOTE Add remaining element types and their custom context menus below
  return (
    <ContextMenuItems {...commonProps}>
      {element.__typename === 'Drawing' && (
        <DrawingContextMenuItems {...commonProps} element={element} />
      )}
      {element.__typename === 'WorkbenchElementPalette' && (
        <WorkbenchElementPaletteContextMenuItems
          {...commonProps}
          element={element}
        />
      )}
    </ContextMenuItems>
  );
};
