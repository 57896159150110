import styled from 'styled-components';
import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { useStringHashNumber } from '@vizcom/shared-ui-components';
import {
  getMultiplayerDisplayConfig,
  getMultiplayerUserId,
} from './multiplayerDisplayConfig';

const FullWindowBorderIndicator = styled.div<{ $color: string }>`
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: inset 0 0 0 5px ${(p) => p.$color};
  z-index: 10000000;
`;

const FollowingIndicatorDetail = styled.p<{ $color: string }>`
  background-color: ${(p) => p.$color};
  border-radius: 1000px;
  position: absolute;
  padding: 5px 10px;
  bottom: 20px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
`;

export const SelectedPresenceWindowIndicator = (props: {
  presence: MultiplayerPresence;
}) => {
  const { presence } = props;

  const userId = getMultiplayerUserId(presence.id);
  const hash = useStringHashNumber(userId);
  const displayConfig = getMultiplayerDisplayConfig(hash, presence.user);

  return (
    <FullWindowBorderIndicator $color={displayConfig.color}>
      {presence.user && (
        <FollowingIndicatorDetail $color={displayConfig.color}>
          Following {displayConfig.name}
        </FollowingIndicatorDetail>
      )}
    </FullWindowBorderIndicator>
  );
};
