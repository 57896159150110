import { produce, Draft, freeze } from 'immer';
import { useState, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type DraftFunction<S> = (draft: Draft<S>) => void;
export type Updater<S> = (updater: S | DraftFunction<S>) => void;

export function useImmer<S extends object>(initialValue: S | (() => S)) {
  const [val, updateValue] = useState<S>(() =>
    freeze(
      typeof initialValue === 'function' ? initialValue() : initialValue,
      true
    )
  );
  return [
    val,
    useCallback<Updater<S>>((updater) => {
      if (typeof updater === 'function') {
        updateValue(produce(updater));
      } else {
        updateValue(freeze(updater));
      }
    }, []),
  ] as const;
}
