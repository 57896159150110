import { SyncedActionPayloadFromType } from '../../SyncedAction';
import { CreateCompositeSceneElementAction } from './createCompositeSceneElement';
import { DeleteCompositeSceneElementAction } from './deleteCompositeSceneElement';
import { UpdateCompositeSceneAction } from './updateCompositeScene';
import { UpdateCompositeSceneElementAction } from './updateCompositeSceneElement';

export const CompositeSceneActionTypes = [
  UpdateCompositeSceneAction,
  CreateCompositeSceneElementAction,
  UpdateCompositeSceneElementAction,
  DeleteCompositeSceneElementAction,
];

export type CompositeSceneActionPayload = SyncedActionPayloadFromType<
  typeof CompositeSceneActionTypes[number]
>;
