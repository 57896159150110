import styled from 'styled-components';
import {
  ArrowIcon,
  BlendIcon,
  Dropdown,
  InferenceStyleOption,
  InvisibleIcon,
  MenuEllipsesIcon,
  OpacityIcon,
  RangeInput,
  VisibleIcon,
  useDocumentEventListener,
  SortableList,
  useKeyboardShortcut,
  NumberInput,
  addToast,
  getOS,
  OperatingSystem,
} from '@vizcom/shared-ui-components';
import { LoaderIcon } from './style';
import { useState } from 'react';
import { LayerName } from './LayerName';
import { BlendMode, Layout } from './types';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { RawImage } from '../../lib/RawImage';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { useIsTouchScreen } from '../../lib/touchState';
import { copyImageToClipboard } from '../../lib/drawingUtils';
import { useSelectionApiStore } from './selection/useSelectionApi';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';
import { LayerContextMenu } from './LayerContextMenu';

const LayerContainer = styled.div<{ $active: boolean; $is3D: boolean }>`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  background: ${({ $active, $is3D, theme }) =>
    $active ? ($is3D ? '#3A4724' : theme.primary.disabled) : '#19191b'};
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  min-height: 64px;

  &:hover {
    // TODO: change to theme color once we refactor color theme
    border-color: ${({ $is3D, theme }) =>
      $is3D ? '#A8E14B' : theme.primary.default};
    background: ${({ $active, $is3D, theme }) =>
      $active ? ($is3D ? '#3A4724' : theme.primary.disabled) : 'inherit'};
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  min-height: 64px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    border: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px 3px;
  background: ${({ theme }) => theme.surface.e1};
  border-radius: 8px;
  margin: 0 4px;
  width: 58px;
  height: 58px;
`;

const Image = styled.div<{ $width: number; $height: number }>`
  background: repeating-conic-gradient(#d7d7d7 0% 25%, #ffffff 0% 50%) 50% / 8px
    8px;
  overflow: hidden;
  cursor: pointer;
  align-items: center;
  display: flex;

  > div {
    width: ${({ $width }) => $width}px;
    height: ${({ $height }) => $height}px;
  }

  &,
  > img,
  > canvas {
    aspect-ratio: ${({ $width, $height }) => $width / $height};
    border-radius: 0.25rem;
    max-width: 58px;
    max-height: 58px;
  }
  > img,
  > canvas {
    pointer-events: none;
    object-fit: contain;
    width: 100%;
  }
`;

const LayerLoader = styled.div<{ $width: number; $height: number }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    aspect-ratio: ${({ $width, $height }) => $width / $height};
    max-width: 58px;
    max-height: 58px;
    backdrop-filter: blur(2px) brightness(80%);

    > svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const Settings = styled.div`
  display: flex;
  gap: 0.5rem;
  color: ${({ theme }) => theme.text.info};
  max-width: 70px;
  cursor: pointer;
  > div {
    display: flex;
  }
`;

const Meta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 6px;
`;

const Edit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  background: ${({ theme }) => theme.surface.e0};
  width: 100%;
  border-radius: 0px 0px 8px 8px;
`;

const EditRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  > * {
    flex-grow: 1;
  }

  > svg {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const Value = styled.div`
  margin-left: auto;
  color: ${({ theme }) => theme.text.default};
  font-weight: 400;
`;

const BlendModes = {
  [BlendMode.normal]: 'Normal',
  [BlendMode.multiply]: 'Multiply',
};

const Layer = ({
  drawing,
  layer,
  isLast,
  viewerVisibility,
  setViewerVisibility,
  setTogglingMultiLayerVisibility,
  isTogglingMultiLayerVisibility,
  isolateLayer,
  activeLayer,
  setActiveLayer,
  editingLayer,
  setEditingLayer,
  handleAction,
  onCreateDrawingFromImage,
}: {
  drawing: Drawing2dStudio;
  layer: Drawing2dStudio['layers']['nodes'][0];
  isLast: boolean;
  viewerVisibility: Record<string, boolean>;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setTogglingMultiLayerVisibility: (v: boolean | undefined) => void;
  isTogglingMultiLayerVisibility: boolean | undefined;
  isolateLayer: (id: string) => void;
  activeLayer: string | undefined;
  setActiveLayer: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  editingLayer: string | null;
  setEditingLayer: (id: string | null) => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  onCreateDrawingFromImage: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    },
    name?: string
  ) => void;
}) => {
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const [isEditingName, setIsEditingName] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const isViewer = useIsWorkbenchViewer();
  const isTouchContext = useIsTouchScreen();
  const selectionApiStore = useSelectionApiStore();
  const isMac = getOS() === OperatingSystem.MacOS;

  const id = layer.id;

  useDocumentEventListener('pointerup', () => {
    setTogglingMultiLayerVisibility(undefined);
  });

  const toggleLayer = () => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        visible: !layer.visible,
      },
    });
  };

  const toggleVisibility = () => {
    setTogglingMultiLayerVisibility(!layer.visible);
  };

  const setVisible = (visible: boolean) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        visible,
      },
    });
  };

  const setLayerName = (name: string) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        name,
      },
    });
  };

  const setLayerBlendMode = (blendMode: BlendMode) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        blendMode,
      },
    });
  };

  const setLayerOpacity = (opacity: number) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        opacity,
      },
    });
  };

  const handleCopyRawLayerImage = () => {
    if (!layer.imagePath) {
      addToast('This layer is empty');
    }
    copyImageToClipboard(
      layer.imagePath,
      selectionApiStore.getState().getSelectionImage()
    );
  };

  useKeyboardShortcut(
    'c',
    () => {
      if (activeLayer === id) {
        handleCopyRawLayerImage();
      }
    },
    {
      ctrl: true,
    }
  );

  if (!layer) return null;

  const is3D = Boolean(layer.metadata3D);

  return (
    <LayerContainer
      onClick={(e) => {
        if (!drawing?.isEditable) return;
        if (e.shiftKey) {
          setActiveLayer(id, 'addTo');
          return;
        }
        if ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) {
          if (activeLayer?.split('/').includes(id)) {
            setActiveLayer(id, 'remove');
          } else {
            setActiveLayer(id, 'add');
          }
          return;
        }
        setActiveLayer(id);
      }}
      $active={
        activeLayer === id || Boolean(activeLayer?.split('/').includes(id))
      }
      $is3D={is3D}
      onContextMenu={(e) => {
        if (!drawing?.isEditable) return;

        e.preventDefault();
        setShowContextMenu(true);
      }}
    >
      <Content>
        {layout !== 'default' && (
          <LayerContextMenu
            drawing={drawing}
            layer={layer}
            isLast={isLast}
            activeLayer={activeLayer}
            showContextMenu={showContextMenu}
            setViewerVisibility={setViewerVisibility}
            setActiveLayer={setActiveLayer}
            handleAction={handleAction}
            onCreateDrawingFromImage={onCreateDrawingFromImage}
            setIsEditingName={setIsEditingName}
            setShowContextMenu={setShowContextMenu}
          />
        )}
        {isTouchContext && !isViewer && <SortableList.DragHandle />}
        <Actions>
          <button
            style={{
              touchAction: 'none',
            }}
            onPointerDown={(e) => {
              if (isViewer) {
                setViewerVisibility((prev) => ({
                  ...prev,
                  [id]: prev[id] !== undefined ? !prev[id] : !layer.visible,
                }));
                return;
              }
              e.stopPropagation();
              try {
                (e.target as HTMLButtonElement).releasePointerCapture(
                  e.pointerId
                );
              } catch {}

              e.preventDefault();
              if (e.altKey) {
                isolateLayer(id);
                return;
              }

              toggleVisibility();
              toggleLayer();
            }}
            onPointerEnter={() => {
              if (isTogglingMultiLayerVisibility !== undefined) {
                setVisible(isTogglingMultiLayerVisibility);
              }
            }}
          >
            {viewerVisibility[id] ?? layer.visible ? (
              <VisibleIcon style={{ pointerEvents: 'none' }} />
            ) : (
              <InvisibleIcon style={{ pointerEvents: 'none' }} />
            )}
          </button>
        </Actions>
        <ImageWrapper>
          <Image
            $width={drawing.width}
            $height={drawing.height}
            style={
              layer.fill.length === 7 && !layer.imagePath
                ? { background: layer.fill }
                : undefined
            }
            onClick={() => {
              setShowContextMenu(!showContextMenu);
            }}
          >
            {layer.imagePath ? <RawImage data={layer.imagePath} /> : <div />}
          </Image>
          {layer.metadata3D?.generatedFrom2dTo3d && !layer.meshPath && (
            <LayerLoader $width={drawing.width} $height={drawing.height}>
              <div>
                <LoaderIcon />
              </div>
            </LayerLoader>
          )}
        </ImageWrapper>
        <Meta>
          <LayerName
            disabled={!drawing?.isEditable}
            onChange={(value) => setLayerName(value)}
            value={layer.name}
            isEditing={isEditingName}
            setIsEditing={setIsEditingName}
          />
          {drawing?.isEditable && (
            <Settings
              onClick={() => setEditingLayer(editingLayer === id ? '' : id)}
            >
              <div>{BlendModes[layer.blendMode as BlendMode].charAt(0)}</div>
              <div>{(layer.opacity * 100).toFixed(0)}%</div>
              <ArrowIcon />
            </Settings>
          )}
        </Meta>
        <>
          <Actions
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              onClick={() => {
                setShowContextMenu((show) => !show);
              }}
            >
              <MenuEllipsesIcon />
            </button>
          </Actions>
        </>
        {layout === 'default' && (
          <LayerContextMenu
            drawing={drawing}
            layer={layer}
            isLast={isLast}
            activeLayer={activeLayer}
            showContextMenu={showContextMenu}
            setViewerVisibility={setViewerVisibility}
            setActiveLayer={setActiveLayer}
            handleAction={handleAction}
            onCreateDrawingFromImage={onCreateDrawingFromImage}
            setIsEditingName={setIsEditingName}
            setShowContextMenu={setShowContextMenu}
          />
        )}
      </Content>
      {editingLayer === id && (
        <Edit onPointerDown={(e) => e.stopPropagation()}>
          <EditRow>
            <BlendIcon />
            <Dropdown
              options={Object.entries(BlendModes).map(([value, name]) => ({
                data: {
                  name,
                  value,
                },
              }))}
              value={layer.blendMode}
              setValue={(v) => setLayerBlendMode(v as BlendMode)}
              buttonProps={{
                style: {
                  backgroundColor: '#242425',
                },
              }}
              optionToValueMapper={({ value }: { value: string }) => value}
              OptionComponent={InferenceStyleOption}
            >
              {BlendModes[layer.blendMode as BlendMode]}
            </Dropdown>
          </EditRow>
          <EditRow>
            <OpacityIcon />
            <RangeInput
              value={layer.opacity}
              min={0}
              max={1}
              step={0.01}
              onChange={({ target: { value } }) => setLayerOpacity(+value)}
              onMouseDown={(e) => e.stopPropagation()}
            />
            <Value>
              <NumberInput
                value={layer.opacity * 100}
                min={0}
                max={100}
                unit="%"
                setValue={(v) => setLayerOpacity(v / 100)}
                dragArrows={false}
              />
            </Value>
          </EditRow>
        </Edit>
      )}
    </LayerContainer>
  );
};

export default Layer;
