import styled from 'styled-components';
import AzureBaseLogo from './azure-logo.svg?react';
import { useWebConfig } from '@vizcom/shared/data-access/graphql';

export const AzureButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: #0078d4;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
  margin: 20px 0 12px 0;
  padding: 0;
  text-decoration: none;

  span {
    margin-left: 8px;
  }

  &:hover {
    background-color: #106ebe;
  }
`;

export const AzureLogo = styled(AzureBaseLogo)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: white;
`;

export const LogInWithAzureButton = () => {
  const { data } = useWebConfig();

  if (!data?.loginWithAzure) {
    return null;
  }
  return (
    <AzureButton
      rel="noopener noreferrer"
      href={`${import.meta.env.NX_VIZCOM_API_BASE_URL || '/api/v1'}/auth/azure`}
    >
      <span>Sign In With SSO</span>
    </AzureButton>
  );
};
