import {
  OrganizationTeamsUsers,
  useDeleteOrganizationInvite,
  useResendOrganizationInvite,
  useUpdateOrganizationInvite,
} from '@vizcom/shared/data-access/graphql';
import {
  TableRow,
  TableCell,
  ContextMenu,
  ContextMenuItem,
  MenuHorizontalIcon,
  Text,
  LoadingLogoInset,
  triggerConfirmModal,
  Button,
  BlankAvatarIcon,
  FormattedDate,
  Checkbox,
  SelectionCell,
  ActionsCell,
  useCrisp,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { MemberRoleSelect } from './styles';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  MEMBERS_TABLE_SIZING,
  OrganizationInvite,
} from './OrganizationMembers';
import { showLimitationErrorToast } from './limitationToastMessage';
import { useNavigate } from 'react-router-dom';
import { UserTeamsComboBox } from './UserTeamsComboBox';

export const InvitedMemberRow = (props: {
  invite: OrganizationInvite;
  organizationId: string;
  canEdit: boolean;
  selected: boolean;
  teams: OrganizationTeamsUsers;
  onSelectedChange: () => void;
}) => {
  const { invite, organizationId, canEdit, teams } = props;

  const navigate = useNavigate();
  const crisp = useCrisp();

  const [deleteRes, deleteInvite] = useDeleteOrganizationInvite();
  const [updateRes, updateOrganizationInvite] = useUpdateOrganizationInvite();
  const [resendRes, resendOrganizationInvite] = useResendOrganizationInvite();

  const handleInviteChangeRole = async (role: string) => {
    const res = await updateOrganizationInvite({
      input: {
        organizationId: organizationId,
        email: invite.email,
        patch: {
          role: role as any,
        },
      },
    });

    if (res.error) {
      const graphQLError = res.error.graphQLErrors[0];
      const errorCode = (graphQLError.extensions.exception as { code?: string })
        ?.code;

      if (
        errorCode === 'MANUAL_LIMITATION_ERROR' ||
        errorCode === 'PLAN_LIMITATION_ERROR'
      ) {
        return showLimitationErrorToast(
          errorCode,
          organizationId,
          navigate,
          crisp.openChat
        );
      }

      return addToast('Error changing role', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    return addToast('Role changed');
  };

  const handleDeleteInvite = async () => {
    try {
      await triggerConfirmModal({
        title: `Are you sure you want to delete the invite for ${invite.email}?`,
        confirmAction: (
          <Button style={{ flex: 1 }} variant="danger">
            Delete invite
          </Button>
        ),
      });
    } catch {
      return;
    }

    const res = await deleteInvite({
      input: {
        organizationId: organizationId,
        email: invite.email,
      },
    });

    if (res.error) {
      return addToast('Error while deleting invitation', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Invitation deleted');
  };

  const handleResendInvite = async () => {
    const res = await resendOrganizationInvite({
      input: {
        organizationId: organizationId,
        invitesEmails: [invite.email],
        usersIds: [],
      },
    });

    if (res.error) {
      addToast(`Error while resending invitation`, {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    } else {
      addToast('Invitation resent');
    }
  };

  return (
    <TableRow style={{ position: 'relative' }}>
      <SelectionCell>
        <Checkbox
          icon="check"
          checked={props.selected}
          onClick={props.onSelectedChange}
        />
      </SelectionCell>

      <TableCell
        size={MEMBERS_TABLE_SIZING.name}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'flex-start',
        }}
      >
        <BlankAvatarIcon />
        <div
          style={{
            flex: 1,
            flexBasis: 0,
            overflow: 'hidden',
          }}
        >
          <Text block type="b2" ellipsis>
            Pending
          </Text>
          <Text block type="button3" color="info" ellipsis title={invite.email}>
            {invite.email}
          </Text>
        </div>
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.dateAdded}>
        <FormattedDate date={invite.createdAt} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.lastActive}>-</TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.teams}>
        <UserTeamsComboBox orgTeams={teams} user={invite} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.role}>
        {canEdit ? (
          <MemberRoleSelect
            value={invite.role}
            onChange={(e) => handleInviteChangeRole(e.target.value)}
          >
            <option value="ADMIN">Admin</option>
            <option value="EDITOR">Editor</option>
            <option value="VIEWER">Viewer</option>
          </MemberRoleSelect>
        ) : (
          capitalize(invite.role)
        )}
      </TableCell>
      <ActionsCell>
        {canEdit && (
          <ContextMenu
            buttonProps={{
              variant: 'transparent',
              size: 'icon',
            }}
            items={
              <>
                <ContextMenuItem onClick={() => handleDeleteInvite()}>
                  Delete invite
                </ContextMenuItem>
                <ContextMenuItem onClick={() => handleResendInvite()}>
                  Resend invite
                </ContextMenuItem>
              </>
            }
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        )}
      </ActionsCell>
      <LoadingLogoInset
        active={deleteRes.fetching || updateRes.fetching || resendRes.fetching}
      />
    </TableRow>
  );
};
