import * as THREE from 'three';

/**
 * NOTE Hides all children with userData.vizcomUI set to true.
 *      Allows capturing the canvas without 3D UI elements in it.
 */
export const hideVizcomUiObjects = (
  group: THREE.Object3D
): THREE.Object3D[] => {
  const changedObjects: THREE.Object3D[] = [];

  group.traverse((child) => {
    if (child.userData.vizcomUI && child.visible) {
      child.visible = false;

      changedObjects.push(child);
    }
  });

  return changedObjects;
};
