import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const CustomInputRow = ({
  children,
  before,
  after,
}: PropsWithChildren<{
  before?: JSX.Element | string | null;
  after?: JSX.Element | string | null;
}>) => {
  const fieldGridSpan = [before ? 6 : 1, after ? 10 : 13];

  return (
    <Wrapper>
      {before && <BeforeWrapper>{before}</BeforeWrapper>}
      <FieldWrapper style={{ gridColumn: fieldGridSpan.join(' / ') }}>
        {children}
      </FieldWrapper>
      {after && <AfterWrapper>{after}</AfterWrapper>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  align-items: center;
`;

const BeforeWrapper = styled.div`
  grid-column: 1 / 6;
  white-space: nowrap;
`;

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;
`;

const AfterWrapper = styled.div`
  grid-column: 10 / 13;
  display: flex;
  justify-content: flex-end;
`;
