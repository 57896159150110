import { Color, ColorRepresentation } from 'three';
import { KeyboardEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColorPicker } from '@vizcom/shared-ui-components';

type ColorInputProps = {
  label?: string;
  value: ColorRepresentation | undefined | null;
  onChange: (hex: string | null) => void;
  withPreview?: boolean;
};

const color = new Color();

export const ColorInput = ({
  label,
  value,
  onChange,
  withPreview,
}: ColorInputProps) => {
  const [tempValue, setTempValue] = useState<string | null>(null);
  const [focused, setFocus] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);

  useEffect(() => {
    if (focused || !value) {
      return;
    }

    color.set(value);

    setTempValue(`#${color.getHexString()}`);
  }, [value, focused]);

  const commitValue = () => {
    onChange(`#${color.set(tempValue || 0).getHexString()}`);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      commitValue();
    }
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    commitValue();
    setFocus(false);
  };

  return (
    <>
      <InputWrapper>
        {!!label && <InputLabel>{label}</InputLabel>}
        <InputFieldWrapper style={{ gridColumn: label ? '4 / 13' : '1 / 13' }}>
          {withPreview && (
            <InputFieldPreview
              $color={value ? `#${color.set(value).getHexString()}` : null}
              onClick={() => setColorPickerOpen((value) => !value)}
            />
          )}
          <InputFieldValue
            value={tempValue || ''}
            placeholder="Add..."
            onChange={(event) => setTempValue(event.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        </InputFieldWrapper>
      </InputWrapper>
      {colorPickerOpen && (
        <ModalAddon>
          <ColorPicker
            color="#ff00ff"
            onChange={(color) => {
              setTempValue(color);

              commitValue();
            }}
          />
        </ModalAddon>
      )}
    </>
  );
};

const ModalAddon = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(calc(-100% - 8px));
`;

const InputWrapper = styled.div`
  position: relative;
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  width: 100%;
  align-items: center;
`;

const InputLabel = styled.div`
  grid-column: 1 / 4;
`;

const InputFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.surface.e1};

  &:hover {
    background-color: ${({ theme }) => theme.surface.e2};
  }
`;

const InputFieldPreview = styled.div<{
  $color: string | null;
}>`
  position: relative;
  width: 32px;
  height: 32px;
  padding: 6px;
  flex-shrink: 0;

  &:after {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);

    ${({ theme, $color }) =>
      $color
        ? `background-color: ${$color};`
        : `
        background-color: ${theme.white};
        opacity: 0.8;
        background-image:
        repeating-linear-gradient(
          45deg, ${theme.background.base} 25%, transparent 25%, transparent 75%, ${theme.background.base} 75%, ${theme.background.base}
        ),
        repeating-linear-gradient(
          45deg, ${theme.background.base} 25%, ${theme.background.pattern} 25%, ${theme.background.pattern} 75%, ${theme.background.base} 75%, ${theme.background.base}
        );
        background-position: 0 0, 7.25px 7.25px;
        background-size: 15px 15px;
    `}

    border-radius: 3px;
    overflow: hidden;
    content: '';
    z-index: 2;
  }
`;

const InputFieldValue = styled.input`
  display: inline-block;
  appearance: none;
  -webkit-appearance: none;
  white-space: nowrap;
  width: 100%;
  flex: 1;
  padding: 6px;
  background: transparent;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.text.default};
  text-align: left;
`;
