import {
  BoldIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from '@vizcom/shared-ui-components';
import { Field, Input, InputOption } from '../../style';
import { useTheme } from 'styled-components';

type WorkbenchElementTextDecorationProps = {
  textDecoration: string;
  fontWeight: number;
  setIsBold: (isBold: boolean) => void;
  handleSetTextDecoration: (
    textDecoration: 'underline' | 'line-through' | 'none'
  ) => void;
};

export const WorkbenchElementTextDecoration = ({
  textDecoration,
  fontWeight,
  setIsBold,
  handleSetTextDecoration,
}: WorkbenchElementTextDecorationProps) => {
  const theme = useTheme();
  return (
    <Field>
      <Input>
        <InputOption
          $selected={fontWeight === 600}
          onClick={() => setIsBold(fontWeight !== 600)}
        >
          <BoldIcon
            style={{
              color: fontWeight === 600 ? theme.white : theme.icon.default,
            }}
          />
        </InputOption>
        <InputOption
          $selected={textDecoration === 'underline'}
          onClick={() =>
            handleSetTextDecoration(
              textDecoration === 'underline' ? 'none' : 'underline'
            )
          }
        >
          <UnderlineIcon
            style={{
              color:
                textDecoration === 'underline'
                  ? theme.white
                  : theme.icon.default,
            }}
          />
        </InputOption>
        <InputOption
          $selected={textDecoration === 'line-through'}
          onClick={() =>
            handleSetTextDecoration(
              textDecoration === 'line-through' ? 'none' : 'line-through'
            )
          }
        >
          <StrikethroughIcon
            style={{
              color:
                textDecoration === 'line-through'
                  ? theme.white
                  : theme.icon.default,
            }}
          />
        </InputOption>
      </Input>
    </Field>
  );
};
