import { Line } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef, useState } from 'react';
import * as THREE from 'three';
import { Line2, LineGeometry, LineSegments2 } from 'three-stdlib';
import { getBoxToBoxArrow } from 'perfect-arrows';
import connectorDisabledSvg from './remove_connection.svg?raw';

import { findChildByWorkbenchElementUserData } from '../objectsUserdata';
import {
  ARROW_OPTIONS,
  ZERO_SEGMENTS,
  Z_POSITION_CONNECTOR_AREA,
  getSmoothLinePoints,
} from './utils';
import { useTheme } from 'styled-components';
import { StaticSvg } from '../utils/StaticSvg';
import { FixedSizeGroup } from '../utils/FixedSizeGroup';

const v = new THREE.Vector3();
const geom = new LineGeometry();

export const WorkbenchElementConnector = ({
  sourceElementId,
  targetElementId,
  focused,
  lineColor,
  opacity = 1,
  orderOffset = 0,
  onDelete,
}: {
  sourceElementId: string;
  targetElementId: string;
  focused: boolean;
  lineColor?: string;
  opacity?: number;
  orderOffset?: number;
  onDelete?: (targetId: string) => void;
}) => {
  const theme = useTheme();
  const rootGroupRef = useRef<THREE.Group>(null!);
  const connectingLineRef = useRef<Line2 | LineSegments2>(null!);
  const lastTargetPosition = useRef<THREE.Vector3>(new THREE.Vector3());
  const removeConnectionRef = useRef<THREE.Group>(null!);
  const lastPosition = useRef<THREE.Vector3>(new THREE.Vector3());
  const [isDeleted, setIsDeleted] = useState(false);
  const [targetFocused, setTargetFocused] = useState(false);

  useFrame(({ scene }) => {
    const targetObject = findChildByWorkbenchElementUserData(
      scene,
      (userData) => userData.elementId === targetElementId
    );
    const sourceObject = findChildByWorkbenchElementUserData(
      scene,
      (userData) => userData.elementId === sourceElementId
    );

    if (!isDeleted && onDelete && !targetObject) {
      setIsDeleted(true);
      onDelete(targetElementId);
    }

    if (!targetObject || !sourceObject) {
      connectingLineRef.current?.geometry.setPositions(ZERO_SEGMENTS);
      return;
    }

    if (targetFocused !== targetObject.userData.singleFocused) {
      setTargetFocused(targetObject.userData.singleFocused);
    }

    // skip if the target position and the source position didn't change
    rootGroupRef.current.getWorldPosition(v);
    if (
      targetObject.position.equals(lastTargetPosition.current) &&
      sourceObject.position.equals(lastPosition.current) &&
      lastPosition.current.equals(v)
    ) {
      return;
    }

    lastTargetPosition.current.set(...targetObject.position.toArray());
    lastPosition.current.set(...v.toArray());

    rootGroupRef.current.position.set(
      sourceObject.position.x,
      sourceObject.position.y,
      sourceObject.position.z
    );

    v.copy(targetObject.position);
    rootGroupRef.current.worldToLocal(v);

    const targetUserData = targetObject.userData;
    const targetWidth = targetUserData.elementWidth * targetObject.scale.x;
    const sourceWidth =
      sourceObject.userData.elementWidth * sourceObject.scale.x;

    const arrow = getBoxToBoxArrow(
      10 + v.x + targetWidth / 2, // origin is the node connector of the target, which is always mid right
      v.y,
      1,
      1,
      -10 - sourceWidth / 2, // end origin is the node connector of the source
      0,
      1,
      1,
      {
        ...ARROW_OPTIONS,
      }
    );
    const [sx, sy, cx, cy, ex, ey, ae, as, ec] = arrow;

    const points = getSmoothLinePoints(sy, sx, cy, ex, ey);

    geom.setPositions(points.flatMap((p) => [p.x, p.y, p.z]));
    connectingLineRef.current.geometry = geom.clone();

    const midPoints = points.slice(
      points.length / 2 - 1,
      points.length / 2 + 1
    );
    const midPoint = midPoints[0].clone().add(midPoints[1]).divideScalar(2);

    removeConnectionRef.current.position.set(
      midPoint.x,
      midPoint.y,
      midPoint.z
    );
  });

  return (
    <group
      position={[0, 0, Z_POSITION_CONNECTOR_AREA]}
      ref={rootGroupRef}
      // indicate that this element shouldn't be detected as "part" of the parent object
      // this is used in the dragging connector, to not attach to the img2img element if hovering the connecting line
      userData={{ stopUserDataPropagation: true }}
    >
      <Line
        color={
          lineColor ? lineColor : focused ? theme.primary.default : 'black'
        }
        ref={connectingLineRef}
        lineWidth={2}
        points={ZERO_SEGMENTS}
        renderOrder={100 + orderOffset}
        depthTest={false}
        depthWrite={false}
        opacity={opacity}
      />
      <group ref={removeConnectionRef}>
        <FixedSizeGroup
          scale={[1.5, 1.5, 1]}
          maxScale={6}
          userData={{
            cursor: 'grab',
          }}
        >
          <StaticSvg
            scale={0.5}
            svg={connectorDisabledSvg}
            visible={Boolean(onDelete) && (focused || targetFocused)}
            userData={{
              cursor: 'pointer',
            }}
            onPointerDown={(e) => {
              e.stopPropagation();
              if (focused || targetFocused) {
                onDelete?.(targetElementId);
              }
            }}
          />
        </FixedSizeGroup>
      </group>
    </group>
  );
};
