import { PointerEvent, Suspense, useEffect, useRef } from 'react';

import { WorkbenchElementTextData } from '@vizcom/shared/data-access/graphql';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { TextInput } from '../style';
import { getElementSize } from '../../helpers';
import { CustomHtml } from '../../utils/CustomHtml';
import { getContentSize } from '../../utils/getContentSize';
import {
  useDocumentEventListener,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';
import { WorkbenchElementTextColor } from './extra/WorkbenchElementTextColor';
import { WorkbenchElementTextFont } from './extra/WorkbenchElementTextFont';
import { WorkbenchElementTextDecoration } from './extra/WorkbenchElementTextDecoration';
import { WorkbenchElementTextAlignment } from './extra/WorkbenchElementTextAlignment';
import { debounce } from 'lodash-es';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { CustomText } from '../../utils/CustomText';
import { useContentEditableWithStableSelection } from '../../../lib/stableSelectionTextInputHooks';
import { useTheme } from 'styled-components';
import { WorkbenchElementTextSize } from './extra/WorkbenchElementTextSize';

interface WorkbenchElementTextProps {
  workbenchId: string;
  element: WorkbenchElementTextData;
  singleFocused: boolean;
  isEditing: boolean;
  isDragging: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}

export const WORKBENCH_ELEMENT_TEXT_PLACEHOLDER = 'Add text';

export const WorkbenchElementText = ({
  element,
  singleFocused,
  isEditing,
  isDragging,
  handleAction,
  setEditingElementId,
}: WorkbenchElementTextProps) => {
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const { width, height } = getElementSize(element);
  const { contentHeight, id, content, lockWidth, y, style = {} } = element;
  const previouslyEditing = useRef<boolean>(isEditing);
  const textScale = height / contentHeight;

  useKeyboardShortcut(
    'escape',
    (e) => {
      if (isEditing) {
        e.stopPropagation();
        setEditingElementId(null);
      }
    },
    {
      capture: true,
    }
  );

  const { bind, ref: textInputRef } = useContentEditableWithStableSelection(
    content,
    (content) => {
      const { newContentHeight, newContentWidth } = getContentSize(
        textInputRef.current
      );

      handleAction({
        type: 'updateText',
        elementId: id,
        content,
        contentHeight: newContentHeight,
        height: newContentHeight * textScale,
        width: lockWidth ? width : newContentWidth * textScale + 10, // +10 to prevent text from being cut off
        final: false,
      });
    },
    {
      selectAllContentOnMount: true,
    }
  );

  const handleChangeColor = debounce((color: string) => {
    handleAction(
      {
        type: 'updateText',
        elementId: id,
        final: true,
        style: {
          ...style,
          color,
        },
      },
      {
        skipHistory: true,
      }
    );
  }, 200);

  const updateFont = (font: string) => {
    textInputRef.current!.style.setProperty('font-family', font);

    const { newContentHeight, newContentWidth } = getContentSize(
      textInputRef.current
    );

    handleAction(
      {
        type: 'updateText',
        elementId: id,
        contentHeight: newContentHeight,
        height: newContentHeight * textScale,
        width: lockWidth ? width : newContentWidth * textScale,
        y: y + (height - newContentHeight * textScale) / 2,
        final: true,
        style: {
          ...style,
          fontFamily: font,
        },
      },
      {
        skipHistory: true,
      }
    );

    setEditingElementId(null);
  };

  const handleSetFontFamily = (font: string) => {
    if (font === style?.fontFamily) {
      return;
    }
    updateFont(font);
  };

  const handleSetTextSize = (size: number) => {
    if (size === textScale) {
      return;
    }

    handleAction({
      type: 'updateText',
      elementId: id,
      final: true,
      width: width * (size / textScale),
      height: height * (size / textScale),
    });
  };

  const handleSetTextAlign = (align: 'left' | 'center' | 'right') => {
    handleAction(
      {
        type: 'updateText',
        elementId: id,
        final: true,
        style: {
          ...style,
          textAlign: align,
        },
      },
      {
        skipHistory: true,
      }
    );
  };

  const setIsBold = (bold: boolean) => {
    handleAction(
      {
        type: 'updateText',
        elementId: id,
        final: true,
        style: {
          ...style,
          fontWeight: bold ? 600 : 400,
        },
      },
      {
        skipHistory: true,
      }
    );
  };

  const handleSetTextDecoration = (
    decoration: 'underline' | 'line-through' | 'none'
  ) => {
    handleAction(
      {
        type: 'updateText',
        elementId: id,
        final: true,
        style: {
          ...style,
          textDecoration: decoration,
        },
      },
      {
        skipHistory: true,
      }
    );
  };

  const handlePointerDown = (e: PointerEvent<HTMLDivElement>) => {
    if (isEditing) {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (!isEditing && previouslyEditing.current && content.trim() === '') {
      handleAction({
        type: 'deleteElements',
        elementIds: [element.id],
      });
    }

    previouslyEditing.current = singleFocused;
  }, [isEditing, element.id, content, handleAction]);

  useDocumentEventListener(
    'keydown',
    (e) => {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        e.stopPropagation();
      }
    },
    {
      capture: true,
    }
  );

  const textAlign = (style?.textAlign ?? 'center') as
    | 'left'
    | 'center'
    | 'right';

  return (
    <group>
      {isEditing && (
        <CustomHtml transform center scale={[textScale, textScale, 1]}>
          <div
            style={{
              width: lockWidth ? width / textScale : 'fit-content',
              height: height / textScale,
              userSelect: 'none',
            }}
          >
            <TextInput
              // ID id required for selection by the resizer to get the size of the content
              // while mid-resize
              id={`text-${id}`}
              contentEditable
              onPointerDown={handlePointerDown}
              tabIndex={-1}
              style={{
                height: contentHeight,
                minWidth: lockWidth ? width / textScale : '30px',
                ...style,
              }}
              placeholder={WORKBENCH_ELEMENT_TEXT_PLACEHOLDER}
              onBlur={() => {
                if (isEditing) {
                  setEditingElementId(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setEditingElementId(null);
                }
              }}
              {...bind()}
            />
          </div>
        </CustomHtml>
      )}
      <group
        userData={{ workbenchObjectType: 'container', fixedAspectRatio: true }}
      >
        <Suspense fallback={null}>
          <CustomText
            content={
              content.length ? content : WORKBENCH_ELEMENT_TEXT_PLACEHOLDER
            }
            scale={[textScale, textScale, 1]}
            position={[0, height / 2, 0.5]}
            onDoubleClick={(e) => {
              e.stopPropagation();
              if (!singleFocused) {
                return;
              }
              setEditingElementId(element.id);
            }}
            visible={!isEditing}
            color={
              content.length === 0 ? theme.text.info : style?.color ?? '#000'
            }
            fontSize={style?.fontSize ?? 24}
            anchorX="center"
            anchorY="top"
            letterSpacing={-0.02}
            overflowWrap="break-word"
            whiteSpace="overflowWrap"
            maxWidth={lockWidth ? width / textScale : undefined}
            textAlign={textAlign}
            font={style?.fontFamily ?? 'Inter'}
            fontWeight={style?.fontWeight === 600 ? 'bold' : 'medium'}
            textDecoration={style?.textDecoration}
          />
        </Suspense>
      </group>
      {!isDragging && singleFocused && !isViewer && (
        <CustomHtml
          style={{ pointerEvents: 'none' }}
          position={[0, height / 2, 1]}
        >
          <WorkbenchElementExtra element={element} handleAction={handleAction}>
            <WorkbenchElementTextColor
              color={style?.color}
              handleChangeColor={handleChangeColor}
            />
            <WorkbenchElementTextFont
              font={style?.fontFamily}
              handleSetFontFamily={handleSetFontFamily}
              setEditing={() => setEditingElementId(id)}
            />
            <WorkbenchElementTextSize
              size={textScale}
              handleSetTextSize={handleSetTextSize}
            />
            <WorkbenchElementTextDecoration
              textDecoration={style?.textDecoration}
              fontWeight={style?.fontWeight}
              setIsBold={setIsBold}
              handleSetTextDecoration={handleSetTextDecoration}
            />
            <WorkbenchElementTextAlignment
              textAlign={textAlign}
              handleSetTextAlign={handleSetTextAlign}
            />
          </WorkbenchElementExtra>
        </CustomHtml>
      )}
    </group>
  );
};
