import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { CustomHtml } from '../../utils/CustomHtml';
import { CustomText } from '../../utils/CustomText';
import { PALETTE_COLOR } from './WorkbenchElementPalette';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';

const PALETTE_NAME_HEIGHT = 22;
export const PALETTE_NAME_MIN_WIDTH = 40;
export const PALETTE_NAME_MAX_WIDTH = 100;

export const PaletteName = ({
  id,
  name,
  width,
  height,
  paletteNameWidth,
  handleAction,
  onSync,
  editingName,
  setEditingName,
}: {
  id: string;
  name: string;
  width: number;
  height: number;
  paletteNameWidth: number;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  onSync: (troika: any) => void;
  editingName: boolean;
  setEditingName: (editingName: boolean) => void;
}) => {
  const nameInputRef = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    if (editingName) {
      nameInputRef.current.innerText = name || '';
      setTimeout(function () {
        nameInputRef.current?.focus();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingName]);

  const updatePaletteName = (name: string) => {
    handleAction({
      type: 'updatePalette',
      elementId: id,
      name,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      updatePaletteName(e.currentTarget.innerText);
      setEditingName(false);
      e.preventDefault();
    }
  };

  return (
    <group
      userData={{
        cursor: 'text',
      }}
    >
      <mesh
        scale={[width, PALETTE_NAME_HEIGHT, 1]}
        position={[0, height / 2 + PALETTE_NAME_HEIGHT / 2, 0.5]}
        onDoubleClick={(e) => {
          e.stopPropagation();
          setEditingName(true);
        }}
      >
        <planeGeometry />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>
      <CustomHtml
        position={[-width / 2 + paletteNameWidth / 2 - 5, height / 2 + 14, 0.5]}
        contentSize={{ width: paletteNameWidth, height: PALETTE_NAME_HEIGHT }}
        transform
      >
        <div
          style={{
            width: paletteNameWidth,
          }}
        >
          <NameInput
            ref={nameInputRef}
            contentEditable
            onFocus={(e) => {
              window.getSelection()?.selectAllChildren(e.target);
            }}
            onBlur={(e) => {
              updatePaletteName(e.target.innerText);
              setEditingName(false);
            }}
            onKeyDown={handleKeyDown}
            style={{
              display: editingName ? 'inline-flex' : 'none',
            }}
          />
        </div>
      </CustomHtml>
      {!editingName && (
        <CustomText
          color={PALETTE_COLOR}
          position={[-width / 2, height / 2 + 14, 0.5]}
          fontSize={12}
          font={'Inter'}
          fontWeight={'semiBold'}
          anchorX={'left'}
          maxWidth={PALETTE_NAME_MAX_WIDTH}
          whiteSpace="nowrap"
          clipRect={[0, -10, PALETTE_NAME_MAX_WIDTH, 10]}
          content={name}
          onSync={onSync}
        />
      )}
    </group>
  );
};

const NameInput = styled.div`
  display: inline-flex;
  min-width: 20px;
  max-width: ${PALETTE_NAME_MAX_WIDTH}px;
  height: 26px;
  background: white;
  color: #ff5106;
  border: 1px solid #ff5106;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-size: 12px;
  letter-spacing: 0.1px;
  font-weight: 600;
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  align-items: center;
`;
