import styled from 'styled-components';

export const StructureTreeWrapper = styled.div`
  display: block;
  width: 100%;
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.tertiary.default}11;
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const StructureTreeItem = styled.div<{
  $active?: boolean;
  $depthLevel: number;
}>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  padding-left: ${({ $depthLevel }) => $depthLevel * 18 + 8}px;
  color: ${({ theme, $active }) =>
    $active ? theme.text.black : theme.text.info};
  background-color: ${({ theme, $active }) =>
    $active ? theme.tertiary.hover : 'transparent'};
  cursor: pointer;
  font-weight: 400;

  ${({ theme, $active }) =>
    !$active
      ? `
  &:hover {
    background-color: ${theme.tertiary.default}22;
  }
  `
      : ''}
`;

export const ModelName = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Actions = styled.div<{
  $active: boolean;
}>`
  & * {
    fill: ${({ theme, $active }) =>
      $active ? theme.text.black : theme.text.info};
    color: ${({ theme, $active }) =>
      $active ? theme.text.black : theme.text.info};
  }
`;
