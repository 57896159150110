import {
  OrganizationTeamsUsers,
  useCurrentUser,
  useDeleteUserOrganization,
  useUpdateUserOrganization,
} from '@vizcom/shared/data-access/graphql';
import {
  TableRow,
  TableCell,
  UserAvatar,
  ContextMenu,
  ContextMenuItem,
  MenuHorizontalIcon,
  Text,
  LoadingLogoInset,
  triggerConfirmModal,
  Button,
  FormattedDate,
  ContextMenuDivider,
  Checkbox,
  SelectionCell,
  useCrisp,
  RelativeDate,
  ActionsCell,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { MemberRoleSelect } from './styles';
import { capitalize } from '@vizcom/shared/js-utils';
import { useNavigate } from 'react-router-dom';
import { useCanEditOrganization } from '../useCanEditOrganization';
import {
  MEMBERS_TABLE_SIZING,
  type OrganizationMember,
} from './OrganizationMembers';
import { showLimitationErrorToast } from './limitationToastMessage';
import { UserTeamsComboBox } from './UserTeamsComboBox';

export const OrganizationMemberRow = (props: {
  memberEdge: OrganizationMember;
  organizationId: string;
  selected: boolean;
  teams: OrganizationTeamsUsers;
  onSelectedChange: () => void;
}) => {
  const { memberEdge, organizationId, teams } = props;
  const member = memberEdge.node;

  const { data: currentUser } = useCurrentUser();
  const canEdit = useCanEditOrganization(organizationId);
  const navigate = useNavigate();
  const isCurrentUser = currentUser?.id === member.id;
  const crisp = useCrisp();

  const [updateUserOrganizationRes, updateUserOrganization] =
    useUpdateUserOrganization();
  const [deleteUserOrganizationRes, deleteUserOrganization] =
    useDeleteUserOrganization();

  const handleChangeRole = async (userId: string, role: string) => {
    const res = await updateUserOrganization({
      input: {
        organizationId: organizationId,
        userId: userId,
        patch: {
          role: role as any,
        },
      },
    });

    if (res.error) {
      const graphQLError = res.error.graphQLErrors[0];
      const errorCode = (graphQLError.extensions.exception as { code?: string })
        ?.code;

      if (
        errorCode === 'MANUAL_LIMITATION_ERROR' ||
        errorCode === 'PLAN_LIMITATION_ERROR'
      ) {
        return showLimitationErrorToast(
          errorCode,
          organizationId,
          navigate,
          crisp.openChat
        );
      }

      return addToast('Error changing role', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    return addToast('Member role changed');
  };

  const handleRemoveFromOrganization = async (
    userId: string,
    userEmail: string
  ) => {
    try {
      await triggerConfirmModal({
        title: isCurrentUser
          ? `Are you sure you want to quit this workspace?`
          : `Are you sure you want to remove ${userEmail} from the workspace?`,
        content: isCurrentUser ? (
          <Text>
            You won't be able to join it back without being invited again
          </Text>
        ) : undefined,
        confirmAction: (
          <Button style={{ flex: 1 }} variant="danger">
            {isCurrentUser ? (
              <span>Quit workspace</span>
            ) : (
              <span>Remove {userEmail} from the workspace</span>
            )}
          </Button>
        ),
      });
    } catch {
      return;
    }
    const res = await deleteUserOrganization({
      input: {
        organizationId: organizationId,
        userId: userId,
      },
    });
    if (res.error) {
      return addToast('Error while removing user', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    if (isCurrentUser) {
      navigate('/');
    } else {
      addToast('User removed from workspace');
    }
  };

  return (
    <TableRow>
      <SelectionCell>
        <Checkbox
          icon="check"
          checked={props.selected}
          onClick={props.onSelectedChange}
        />
      </SelectionCell>

      <TableCell
        size={MEMBERS_TABLE_SIZING.name}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'flex-start',
        }}
      >
        <UserAvatar userId={member.id} name={member.name || member.email} />
        <div
          style={{
            flex: 1,
            flexBasis: 0,
            overflow: 'hidden',
          }}
        >
          <Text block type="b2" ellipsis title={member.name || '-'}>
            {member.name || '-'}
          </Text>
          <Text block type="button3" color="info" ellipsis title={member.email}>
            {member.email}
          </Text>
        </div>
      </TableCell>

      <TableCell size={MEMBERS_TABLE_SIZING.dateAdded}>
        <FormattedDate date={member.createdAt} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.lastActive}>
        {member.lastActive && <RelativeDate date={member.lastActive} />}
        {!member.lastActive && '-'}
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.teams}>
        <UserTeamsComboBox orgTeams={teams} user={memberEdge} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.role}>
        {canEdit && !isCurrentUser ? (
          <MemberRoleSelect
            value={memberEdge.role}
            onChange={(e) => handleChangeRole(member.id, e.target.value)}
          >
            <option value="ADMIN">Admin</option>
            <option value="EDITOR">Editor</option>
            <option value="VIEWER">Viewer</option>
          </MemberRoleSelect>
        ) : (
          capitalize(memberEdge.role)
        )}
      </TableCell>
      <ActionsCell>
        {isCurrentUser ? (
          <ContextMenu
            buttonProps={{
              variant: 'transparent',
              size: 'icon',
            }}
            items={
              <ContextMenuItem
                onClick={() =>
                  handleRemoveFromOrganization(member.id, member.email)
                }
              >
                Quit workspace
              </ContextMenuItem>
            }
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        ) : canEdit ? (
          <ContextMenu
            buttonProps={{
              variant: 'transparent',
              size: 'icon',
            }}
            items={
              <>
                <ContextMenuItem
                  onClick={() => {
                    navigator.clipboard.writeText(member.email);
                    addToast('Email copied to clipboard');
                  }}
                >
                  Copy Email
                </ContextMenuItem>

                <ContextMenuDivider />
                <ContextMenuItem
                  onClick={() =>
                    handleRemoveFromOrganization(member.id, member.email)
                  }
                >
                  Remove from workspace
                </ContextMenuItem>
              </>
            }
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        ) : null}
      </ActionsCell>
      <LoadingLogoInset
        active={
          updateUserOrganizationRes.fetching ||
          deleteUserOrganizationRes.fetching
        }
      />
    </TableRow>
  );
};
