import { MeshPhysicalMaterial } from 'three';
import { MaterialOverrides } from '../materialsList/types';

export type CompositeSceneMesh = {
  position: [number, number, number];
  quaternion: [number, number, number, number];
  scale: [number, number, number];
  deleted: boolean;
  material: Pick<MeshPhysicalMaterial, keyof MaterialOverrides>;
};

export enum RenderingOption {
  color = 'color',
  depth = 'depth',
  normal = 'normal',
  depthPlusAO = 'depthPlusAO',
  sobel = 'sobel',
  sobelDepth = 'sobelDepth',
}

export type AIPreviewMode = {
  mode: 'docked' | 'splitscreen';
  splitScreenRatio?: number;
};
