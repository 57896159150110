import {
  WorkbenchStudioTool,
  isSelectionTool,
  useWorkbenchStudioToolState,
} from './studioState';
import {
  useDocumentEventListener,
  useWindowEventListener,
  useKeyboardShortcut,
  useStableCallback,
} from '@vizcom/shared-ui-components';
import { copyImageToClipboard } from '../../lib/drawingUtils';
import { useState } from 'react';
import { MaskOperation } from './selection/utils';
import { useRegister2dStudioHotkeys } from '../hotkeys/hooks';
import { SelectionApi } from './selection/useSelectionApi';
import { StoreApi } from 'zustand';

type Props = {
  readOnly: boolean;
  getCompositedImage: (() => ImageData) | undefined;
  selectionApi: StoreApi<SelectionApi>;
};

export const useImageStudioHotkeys = ({
  readOnly,
  getCompositedImage,
  selectionApi,
}: Props) => {
  const toolState = useWorkbenchStudioToolState();
  useRegister2dStudioHotkeys({ toolState });

  useKeyboardShortcut(
    'c',
    () => {
      if (getCompositedImage) {
        copyImageToClipboard(
          getCompositedImage(),
          selectionApi.getState().getSelectionImage()
        );
      }
    },
    {
      ctrl: true,
      shift: true,
    }
  );

  const [initialSelectionState, setInitialSelectionState] =
    useState<MaskOperation>(toolState.selectionSettings.operation);

  useDocumentEventListener('keydown', (e) => {
    if (isSelectionTool(toolState.tool)) {
      if (e.altKey) {
        setInitialSelectionState(toolState.selectionSettings.operation);
        toolState.selectionSettings.setOperation(MaskOperation.Remove);
      }
      if (e.shiftKey) {
        setInitialSelectionState(toolState.selectionSettings.operation);
        toolState.selectionSettings.setOperation(MaskOperation.Add);
      }
    } else if (e.altKey) {
      if (
        toolState.tool === WorkbenchStudioTool.Brush ||
        toolState.tool === WorkbenchStudioTool.Shape ||
        toolState.tool === WorkbenchStudioTool.PaintBucket
      ) {
        toolState.setPickingColorOnCanvas(true);
      }
    }

    if (e.key === 's') {
      if (
        toolState.tool === WorkbenchStudioTool.Brush ||
        toolState.tool === WorkbenchStudioTool.Eraser ||
        toolState.tool === WorkbenchStudioTool.BrushSelection
      ) {
        toolState.setResizing(true);
      }
    }
  });

  useDocumentEventListener('keyup', (e) => {
    if (isSelectionTool(toolState.tool)) {
      if (e.key === 'Alt' || e.key === 'Shift') {
        toolState.selectionSettings.setOperation(initialSelectionState);
      }
    } else if (e.key === 'Alt') {
      toolState.setPickingColorOnCanvas(false);
    }

    if (e.key === 's') {
      toolState.setResizing(false);
    }
  });

  useWindowEventListener('blur', () => {
    toolState.setPickingColorOnCanvas(false);
    toolState.setResizing(false);
  });

  useDocumentEventListener('pointermove', (e) => {
    if (toolState.resizing && e.buttons === 1) {
      toolState
        .getToolSettings()
        .setToolSize(
          Math.max(1, toolState.getToolSettings().toolSize + e.movementX)
        );
    }
  });
};
