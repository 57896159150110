import styled from 'styled-components';
import { zIndexOrder } from '../utils/consts';

export const Container = styled.div<{
  $width: number | string;
}>`
  position: fixed;
  display: flex;
  top: 50%;
  right: 0;
  width: ${({ $width }) => $width};
  height: 100dvh;
  transform: translateY(-50%);
  z-index: ${zIndexOrder.AIOverlayZIndex};
  overflow: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.surface.e0};
  border-left: solid 2px ${({ theme }) => theme.surface.e0};
  pointer-events: all;
`;

export const PreviewList = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: calc(100% - 275px);
  gap: 8px;
  padding: 8px 0;
  margin-left: 4px;
  z-index: 3;
`;

export const PreviewItem = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.surface.e1};

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: contain;
  }
`;

export const PreviewItemButton = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: dashed 4px ${({ theme }) => theme.surface.e2};
  background-color: ${({ theme }) => theme.surface.e0};
  color: ${({ theme }) => theme.surface.e2};
  font-size: 20px;
  cursor: pointer;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
  }

  &:hover {
    border-color: ${({ theme }) => theme.tertiary.default};
    color: ${({ theme }) => theme.tertiary.default};
    background-color: ${({ theme }) => theme.tertiary.disabled};
    cursor: pointer;

    .minified-ui-icon {
      background-color: ${({ theme }) => theme.tertiary.default};
      cursor: pointer;
    }
  }
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110dvmax;
  height: 110dvmax;
  transform: translateY(calc(-50% - 5dvmax)) translateX(calc(-50% - 5dvmax));
  background-color: ${({ theme }) => theme.surface.e0};
  z-index: 2;
`;

export const Resizer = styled.div<{
  $offset: number;
}>`
  position: fixed;
  top: 0;
  right: calc(${({ $offset }) => $offset}dvw - 10px);
  width: 20px;
  height: 100dvh;
  z-index: ${zIndexOrder.AIOverlayZIndex + 1};
  pointer-events: all;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 100%;
    background-color: ${({ theme }) => theme.tertiary.default};
    z-index: 2;
    content: '';
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;
