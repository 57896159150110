import { PropsWithChildren, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import {
  ArrangeIcon,
  Button,
  DownloadIcon,
  DuplicateIcon,
  FeatureFlagged,
  FileToZip,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  SectionIcon,
  addToast,
  downloadFile,
  imageToBlob,
  useKeyboardShortcut,
  zipFiles,
} from '@vizcom/shared-ui-components';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { Extra } from './WorkbenchElementExtra';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import {
  MAX_Z_POSITION,
  getElementSize,
  getElementsBoundingBox,
  getWorkbenchElementZPositionRange,
} from './helpers';
import { masonryLayoutBestFit } from './utils/layoutHelpers';
import { elementIsDrawing, useIsWorkbenchViewer } from '../lib/utils';
import { WorkbenchContentFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useTheme } from 'styled-components';
import { useThree } from '@react-three/fiber';
import {
  SECTION_DEFAULT_COLOR,
  SECTION_DEFAULT_TITLE,
  SECTION_PADDING,
} from './elements/section/WorkbenchElementSection';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { publishTrackingEvent } from '@vizcom/shared/data-access/graphql';
import { WorkbenchEventName } from '@vizcom/shared/data-shape';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { limitSourceImages } from './elements/palette/helpers';

type WorkbenchElementsExtraProps = PropsWithChildren<{
  workbench: WorkbenchContentFragment;
  elements: ClientSideWorkbenchElementData[];
  isDragging: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}>;

export const WorkbenchElementsExtra = ({
  workbench,
  elements,
  isDragging,
  handleAction,
}: WorkbenchElementsExtraProps) => {
  const isViewer = useIsWorkbenchViewer();
  const theme = useTheme();
  const scene = useThree((s) => s.scene);
  const setFocusedElementsId = useWorkbenchElementSelectionState(
    (state) => state.setFocusedElementsId
  );

  const selectedDrawings = elements.filter(elementIsDrawing);

  const handleDuplicate = useCallback(() => {
    const newElementIds = elements.map(() => uuid());

    handleAction({
      type: 'duplicateElements',
      elementIds: elements.map((el) => el.id),
      newElementIds,
    });

    setFocusedElementsId(newElementIds.join('/'));
  }, [elements, handleAction, setFocusedElementsId]);

  const handleArrange = useCallback(async () => {
    handleAction({
      type: 'multiPosition',
      newElementData: getNewElementData(elements),
    });
  }, [elements, handleAction]);

  const handleCreateSection = useCallback(async () => {
    const { x, y, width, height } = getElementsBoundingBox(elements);
    const minZIndex = Math.min(...elements.map((e) => e.zIndex));
    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndexFallback = isFinite(zRange[1]) ? zRange[1] - 1 : 250000;

    const sectionId = uuid();
    handleAction({
      type: 'createElements',
      newElements: [
        {
          id: sectionId,
          updatedAt: '0',
          x: x,
          y: y,
          width: width + SECTION_PADDING,
          height: height + SECTION_PADDING,
          zIndex: minZIndex === Infinity ? zIndexFallback : minZIndex - 1,
          __typename: 'WorkbenchElementSection',
          color: SECTION_DEFAULT_COLOR,
          title: SECTION_DEFAULT_TITLE,
        },
      ],
    });

    setFocusedElementsId(sectionId);
  }, [elements, handleAction, scene, setFocusedElementsId]);

  const handleCreatePalette = useCallback(() => {
    const { x, y, width, height } = getElementsBoundingBox(selectedDrawings);
    const workbenchElementPaletteId = uuid();

    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[0]) ? zRange[0] - 1 : MAX_Z_POSITION / 4;

    handleAction({
      type: 'createElements',
      newElements: [
        {
          id: workbenchElementPaletteId,
          __typename: 'WorkbenchElementPalette',
          x: x + width + 30,
          y,
          zIndex,
          width: width + 20,
          height: height + 20,
          status: 'idle',
          tags: [],
          name: '',
          sourceImages: {
            nodes: [],
          },
        },
      ],
    });

    const sourceDrawingIds = limitSourceImages(selectedDrawings, 0).map(
      ({ id }) => id
    );

    handleAction({
      type: 'insertDrawingsToPalette',
      id: workbenchElementPaletteId,
      sourceDrawingIds,
    });

    setFocusedElementsId(workbenchElementPaletteId);
  }, [handleAction, scene, selectedDrawings, setFocusedElementsId]);

  const handleExportImages = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedDrawings.length === 0) {
      return addToast('Please select at least one image to export', {
        type: 'danger',
      });
    }

    try {
      const files: FileToZip[] = await Promise.all(
        selectedDrawings
          .map(async (element, index) => {
            if (!element.thumbnailPath) return null;

            return {
              name: `${workbench.name}-${index}.png`,
              data: await imageToBlob(element.thumbnailPath),
            };
          })
          .filter(Boolean) as Promise<FileToZip>[]
      );

      const zipFile = await zipFiles(files);

      downloadFile(zipFile, workbench.name, 'zip');

      trackEvent('Image Export', { type: 'exportZipImages' });

      publishTrackingEvent({
        type: WorkbenchEventName.ZIP_EXPORT,
        data: {
          workbenchIds: [workbench.id],
        },
      });
    } catch {
      return addToast('An error happened exporting drawing files', {
        type: 'danger',
      });
    }

    (e.target as HTMLButtonElement).blur();
  };

  useKeyboardShortcut(
    's',
    () => {
      handleCreateSection();
    },
    {
      shift: true,
    }
  );

  if (isDragging) {
    return null;
  }

  return (
    <Extra onPointerDown={(e) => e.stopPropagation()}>
      <RichTooltip trigger="hover" padding={11}>
        <RichTooltipTrigger>
          <Button
            variant="transparent"
            size="iconSquared"
            onClick={handleArrange}
            disabled={isViewer}
          >
            <ArrangeIcon style={{ color: theme.icon.default }} />
          </Button>
        </RichTooltipTrigger>
        <RichTooltipContent style={{ color: theme.white }}>
          <div>Arrange</div>
        </RichTooltipContent>
      </RichTooltip>

      <FeatureFlagged flag="WORKBENCH_SECTION_ELEMENT">
        <RichTooltip trigger="hover" padding={11}>
          <RichTooltipTrigger>
            <Button
              variant="transparent"
              size="iconSquared"
              onClick={handleCreateSection}
              disabled={isViewer}
            >
              <SectionIcon style={{ color: theme.icon.default }} />
            </Button>
          </RichTooltipTrigger>
          <RichTooltipContent style={{ color: theme.white }}>
            <div>Section</div>
          </RichTooltipContent>
        </RichTooltip>
      </FeatureFlagged>

      {selectedDrawings.length > 0 && (
        <RichTooltip trigger="hover" padding={11}>
          <RichTooltipTrigger>
            <Button
              variant="transparent"
              size="iconSquared"
              onClick={handleExportImages}
            >
              <DownloadIcon />
            </Button>
          </RichTooltipTrigger>
          <RichTooltipContent style={{ color: theme.white }}>
            <div>Download images</div>
          </RichTooltipContent>
        </RichTooltip>
      )}

      {selectedDrawings.length === elements.length &&
        selectedDrawings.length > 0 && (
          <FeatureFlagged flag="WORKBENCH_PALETTE_TRAINING">
            <RichTooltip trigger="hover" padding={11}>
              <RichTooltipTrigger>
                <Button
                  variant="transparent"
                  size="iconSquared"
                  onClick={handleCreatePalette}
                >
                  P
                </Button>
              </RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.white }}>
                <div>Create palette</div>
              </RichTooltipContent>
            </RichTooltip>
          </FeatureFlagged>
        )}

      <RichTooltip trigger="hover" padding={11}>
        <RichTooltipTrigger>
          <Button
            variant="transparent"
            size="iconSquared"
            onClick={handleDuplicate}
            disabled={isViewer}
          >
            <DuplicateIcon
              style={{
                color: theme.icon.default,
                width: '16px',
                height: '16px',
              }}
            />
          </Button>
        </RichTooltipTrigger>
        <RichTooltipContent style={{ color: theme.white }}>
          <div>Duplicate</div>
        </RichTooltipContent>
      </RichTooltip>
    </Extra>
  );
};

/**
 * Calculates new positions for a set of workbench elements.
 * @param elements - The workbench elements to position.
 * @returns The new positions for the elements.
 */
function getNewElementData(elements: ClientSideWorkbenchElementData[]) {
  const formattedElements = elements.map((e) => ({
    ...e,
    ...getElementSize(e),
  }));
  const columns = formattedElements.length > 8 ? 4 : 2;
  return masonryLayoutBestFit(formattedElements, columns);
}
