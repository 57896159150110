import { useEffect } from 'react';
import { globalOrganizationIdAtom } from './globalOrganizationAtom';
import { useRecoilState } from 'recoil';

export const useRegisterCurrentOrganization = (
  organizationId: string | undefined
) => {
  const [globalOrganizationId, setGlobalOrganizationId] = useRecoilState(
    globalOrganizationIdAtom
  );
  useEffect(() => {
    if (organizationId && globalOrganizationId !== organizationId) {
      setGlobalOrganizationId(organizationId);
    }
  }, [organizationId, globalOrganizationId, setGlobalOrganizationId]);
};
