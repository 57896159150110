import styled from 'styled-components';
import {
  Button,
  Checkbox,
  NumberInput,
  RangeInput,
  Text,
} from '@vizcom/shared-ui-components';
import { expScale, logScale } from './mathHelpers';
import { useState } from 'react';
import { BrushTransformInput } from './BrushTransformInput';
import { BrushSizePreview } from './BrushSizePreview';
import BrushHardIcon from './brush-hard-icon.svg?react';
import BrushSoftIcon from './brush-soft-icon.svg?react';
import { useWorkbenchStudioToolState } from '../studioState';

export const ToolSettings = ({ className }: { className?: string }) => {
  const [isSliding, setIsSliding] = useState(false);
  const { getToolSettings } = useWorkbenchStudioToolState();

  const {
    toolOpacity,
    toolSize,
    toolHardness,
    toolAspect,
    toolAngle,
    toolPressureSize,
    toolStabilizer,
    setToolAspect,
    setToolHardness,
    setToolOpacity,
    setToolSize,
    setToolAngle,
    setToolPressureSize,
    setToolStabilizer,
  } = getToolSettings();

  return (
    <Content className={className}>
      <InputGroup style={{ flex: 1 }}>
        <div>
          <InputHeader>
            <Text style={{ flex: 1 }} type="b1">
              Size
            </Text>
            <NumberInput
              value={toolSize}
              min={1}
              max={250}
              setValue={setToolSize}
              unit="px"
              dragArrows={false}
              enableGestureSlider
            />
          </InputHeader>

          <RangeInput
            variant="secondary"
            value={logScale(toolSize, 1, 250)}
            min={0}
            max={1}
            step={0.01}
            onChange={({ target: { value } }) =>
              setToolSize(Math.round(expScale(+value, 1, 250)))
            }
            onMouseDown={() => setIsSliding(true)}
            onMouseUp={() => setIsSliding(false)}
            onMouseLeave={() => setIsSliding(false)}
          />
        </div>
        <div>
          <InputHeader>
            <Text style={{ flex: 1 }} type="b1">
              Opacity
            </Text>
            <NumberInput
              value={toolOpacity * 100}
              min={1}
              max={100}
              setValue={(v) => setToolOpacity(v / 100)}
              dragArrows={false}
              enableGestureSlider
            />
          </InputHeader>
          <RangeInput
            variant="secondary"
            value={toolOpacity}
            min={0}
            max={1}
            step={0.01}
            onChange={({ target: { value } }) => setToolOpacity(+value)}
            onMouseDown={() => setIsSliding(true)}
            onMouseUp={() => setIsSliding(false)}
            onMouseLeave={() => setIsSliding(false)}
          />
        </div>
        <div>
          <InputHeader>
            <Text style={{ flex: 1 }} type="b1">
              Stabilizer
            </Text>
            <NumberInput
              value={toolStabilizer}
              min={0}
              max={4}
              unit="x"
              setValue={(v) => setToolStabilizer(v)}
              dragArrows={false}
              enableGestureSlider
            />
          </InputHeader>
          <RangeInput
            variant="secondary"
            value={toolStabilizer}
            min={0}
            max={4}
            step={1}
            onChange={({ target: { value } }) => setToolStabilizer(+value)}
            onMouseDown={() => setIsSliding(true)}
            onMouseUp={() => setIsSliding(false)}
            onMouseLeave={() => setIsSliding(false)}
          />
        </div>
        <div>
          <InputHeader>
            <Text style={{ flex: 1 }} type="b1">
              Hardness
            </Text>
          </InputHeader>
          <div style={{ display: 'flex', gap: 12 }}>
            <Button
              variant={toolHardness === 100 ? 'primary' : 'transparent'}
              size="iconSquared"
              onClick={() => setToolHardness(100)}
            >
              <BrushHardIcon style={{ color: 'white' }} />
            </Button>
            <Button
              variant={toolHardness === 0 ? 'primary' : 'transparent'}
              size="iconSquared"
              onClick={() => setToolHardness(0)}
            >
              <BrushSoftIcon />
            </Button>
          </div>
        </div>
        <div>
          <CheckboxLabel>
            Use pressure for size
            <Checkbox
              checked={toolPressureSize}
              onClick={() => setToolPressureSize(!toolPressureSize)}
            />
          </CheckboxLabel>
        </div>
      </InputGroup>
      {isSliding ? (
        <BrushSizePreview size={toolSize} opacity={toolOpacity} />
      ) : (
        <BrushTransformInput
          aspect={toolAspect}
          setAspect={setToolAspect}
          rotation={toolAngle}
          setRotation={setToolAngle}
        />
      )}
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  padding: 6px;
  gap: 6px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const InputHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text.default};
  gap: 12px;
  margin-top: 6px;
`;
