/**
 * TODO Replace with simple-dropzone used in CompositeSceneEditor
 *      https://linear.app/vizcom/issue/VIZ-492/replace-filedropper-with-simple-dropzone-for-consistency
 */
import {
  DragEventHandler,
  MutableRefObject,
  PropsWithChildren,
  forwardRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { RootState } from '@react-three/fiber';
import {
  FullPageDarkLoader,
  Text,
  addToast,
  resizeImageToPixelCount,
  useDocumentEventListener,
} from '@vizcom/shared-ui-components';
import { filterExists } from '@vizcom/shared/js-utils';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import {
  MAX_Z_POSITION,
  getWorkbenchElementZPositionRange,
  screenPositionToWorld,
} from './helpers';
import { masonryLayoutBestFit } from './utils/layoutHelpers';
import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../constants';
import { OrthographicCamera } from 'three';

const Container = styled.div<{ $active: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
  z-index: 10000000;
  transition: 0.5s opacity ease;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const FileDropper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
    state: MutableRefObject<RootState>;
    className?: string;
    workbenchId: string;
    enabled: boolean;
  }>
>((props, ref) => {
  const { state, handleAction, children, className, enabled } = props;
  const [isDroppingFiles, setIsDroppingFiles] = useState(false);
  const [loading, setLoading] = useState(false);

  const createDrawingsFromImageFiles = async (
    files: File[],
    position?: { x: number; y: number }
  ) => {
    setLoading(true);
    try {
      const filesData = (
        await Promise.all(
          files.map(async (file) => {
            try {
              const resized = await resizeImageToPixelCount(
                file,
                WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
              );
              return resized;
            } catch (e) {
              addToast(
                `The file you're trying to upload is not a valid image: ${file.name}`,
                {
                  type: 'danger',
                }
              );
            }
          })
        )
      ).filter(filterExists);
      if (filesData.length === 0) {
        return;
      }

      const zRange = getWorkbenchElementZPositionRange(
        props.state.current.scene
      );

      const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;

      const workbenchSizeRatio = 0.2;

      const targetPosition = position ?? state.current.camera.position;

      const positions = masonryLayoutBestFit(
        filesData.map(({ width, height }) => ({
          width: width * workbenchSizeRatio,
          height: height * workbenchSizeRatio,
          id: uuidv4(),
          x: targetPosition.x,
          y: targetPosition.y,
        })),
        2
      );

      handleAction({
        type: 'createElements',
        newElements: filesData.map((file, index) => {
          const { id, x, y } = positions[index];
          return {
            __typename: 'Drawing',
            updatedAt: '0',
            id,
            x,
            y,
            drawingWidth: file.width,
            drawingHeight: file.height,
            zIndex: zIndex + index,
            workbenchSizeRatio,
            image: file.image,
            name: '',
          };
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  useDocumentEventListener('paste', (event) => {
    if (!enabled) {
      return;
    }
    const items = event.clipboardData?.items;

    if (!items) return;

    const files = [...items]
      .filter((item) => item.kind === 'file')
      .map((item) => item.getAsFile())
      .filter(filterExists);
    createDrawingsFromImageFiles(files);
  });

  const handleDrop: DragEventHandler = async (e) => {
    setIsDroppingFiles(false);
    if (!enabled) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer.items
      ? [...e.dataTransfer.items]
          .map((item) => {
            if (item.kind === 'file') {
              const file = item.getAsFile();
              return file;
            }
          })
          .filter(filterExists)
      : [...e.dataTransfer.files];

    const position = screenPositionToWorld(
      [e.clientX, e.clientY],
      state.current.camera as OrthographicCamera
    );

    createDrawingsFromImageFiles(files, { x: position[0], y: position[1] });
  };

  const handleDragOver: DragEventHandler = (e) => {
    if (enabled) {
      setIsDroppingFiles(true);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  // we need to send a wrapper element and bind the drag events there
  // it's the easiest solution, trying to bind listeners to document is finicky on different browsers
  return (
    <div
      className={className}
      style={{ position: 'relative' }}
      ref={ref}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragOver}
      onDragLeave={() => setIsDroppingFiles(false)}
    >
      {children}
      <Container $active={enabled && isDroppingFiles}>
        <Text type="h1">Drop file</Text>
      </Container>
      {loading && <FullPageDarkLoader />}
    </div>
  );
});
