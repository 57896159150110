import { ClientSideWorkbenchElementData } from '../../clientState';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  CancelPaletteTrainingMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { elementById } from '../../utils';
import { PaletteEventName } from '@vizcom/shared/data-shape';
import { TrainPaletteAction } from './trainPaletteAction';

export const CancelPaletteTrainingAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'cancelPaletteTraining';
    id: string;
  }
> = {
  type: 'cancelPaletteTraining',
  optimisticUpdater: ({ payload }, elements) => {
    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette') return;

    element.status = 'idle';
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(CancelPaletteTrainingMutation, {
      input: {
        id: payload.id,
      },
    });
    if (res?.error) {
      throw new Error(
        `Error while cancelling palette training, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Cancel palette training');
    publishTrackingEvent({
      type: PaletteEventName.CANCEL_TRAINING,
      data: {
        paletteId: payload.id,
      },
    });
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<typeof TrainPaletteAction> =
      {
        type: 'trainPalette',
        id: payload.id,
      };
    return undoPayload;
  },
};
