import { Button, Text } from '@vizcom/shared-ui-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { paths } from '@vizcom/shared-utils-paths';

interface CreateOrganizationSuccessPageProps {
  organizationGlobalTeamId: string;
}

export const CreateOrganizationSuccessPage = ({
  organizationGlobalTeamId,
}: CreateOrganizationSuccessPageProps) => {
  return (
    <Container>
      <Text block type="t1">
        You’re all set
      </Text>
      <Text>
        We sent you an email to help you get started. It contains helpful video
        tutorials and links to download our Vizcom desktop app.
      </Text>

      <Text block>
        You can update workspace roles on the members page after setting up.
      </Text>

      <Button as={Link} to={paths.files.team(organizationGlobalTeamId)}>
        Continue to Workspace
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 32px;
  text-align: center;
`;
