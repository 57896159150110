import {
  Button,
  CarretDownIcon,
  DownloadIcon,
  HistoryIcon,
  downloadFile,
  styledScrollbar,
  useLastNonNullValue,
  ArrowLeftIcon,
  ArrowRightIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  slideInFromLeft,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';
import { TransitionStatus } from 'react-transition-group';
import styled, { css, keyframes, useTheme } from 'styled-components';
import {
  HorizontalDivider,
  Overlay,
  SidebarHeader,
  SidebarHeaderContent,
} from '../style';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import {
  PromptData,
  PromptOutput,
  publishTrackingEvent,
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { StudioHistoryPage } from './StudioHistoryPage';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { Layout } from '../types';
import { genTopOrderKey } from '@vizcom/shared/js-utils';
import { InferenceEventName } from '@vizcom/shared/data-shape';

export type PromptHistoryItem =
  | {
      output: PromptOutput;
      prompt: PromptData;
    }
  | undefined;

type Props = {
  drawing: Drawing2dStudio;
  state: TransitionStatus;
  open: boolean;
  selectedPromptHistoryItem: PromptHistoryItem;
  disabled: boolean;
  setOpen: (open: boolean) => void;
  handleAction?: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
  setActiveLayerId: (id: string) => void;
};

export const WorkbenchStudioHistory = ({
  drawing,
  state,
  open,
  selectedPromptHistoryItem,
  disabled,
  setOpen,
  handleAction,
  setSelectedPromptHistoryItem,
  setActiveLayerId,
}: Props) => {
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const prevOpen = useLastNonNullValue(open);
  const [pageCursors, setPageCursors] = useState<Array<string | null>>([null]);
  const [prompts, setPrompts] = useState<Record<string, PromptData[]>>({});
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';

  const history = useMemo(
    () =>
      Object.values(prompts)
        .flat()
        .flatMap((prompt) => prompt?.outputs.nodes),
    [prompts]
  );

  useEffect(() => {
    if (open && history.length > 0 && !selectedPromptHistoryItem) {
      setSelectedPromptHistoryItem({
        output: history[0],
        prompt: Object.values(prompts)
          .flat()
          .find((p) => p.id === history[0].promptId)!,
      });
    }
  }, [open, history.length]);

  useEffect(() => {
    setOpen(false);
    setSelectedPromptHistoryItem(undefined);
  }, [layout]);

  useKeyboardShortcut('ArrowLeft', () => {
    if (!open || disabled) {
      return;
    }
    selectPreviousHistoryItem();
  });
  useKeyboardShortcut('ArrowRight', () => {
    if (!open || disabled) {
      return;
    }
    selectNextHistoryItem();
  });
  useKeyboardShortcut('Escape', () => {
    if (!open || disabled) {
      return;
    }
    setSelectedPromptHistoryItem(undefined);
  });

  const selectNextHistoryItem = () => {
    const nextIndex = history.findIndex(
      (item) => item.id === selectedPromptHistoryItem?.output.id
    );
    if (nextIndex === history.length - 1) return;

    const nextItem = !selectedPromptHistoryItem?.output.id
      ? history[0]
      : history[nextIndex + 1];
    if (!nextItem) return;

    setSelectedPromptHistoryItem({
      output: nextItem,
      prompt: Object.values(prompts)
        .flat()
        .find((p) => p.id === nextItem.promptId)!,
    });
  };

  const selectPreviousHistoryItem = () => {
    const previousIndex = history.findIndex(
      (item) => item.id === selectedPromptHistoryItem?.output.id
    );
    if (previousIndex === -1) return;

    const previousItem = history[previousIndex - 1];
    if (!previousItem) return;

    setSelectedPromptHistoryItem({
      output: previousItem,
      prompt: Object.values(prompts)
        .flat()
        .find((p) => p.id === previousItem.promptId)!,
    });
  };

  const addItem = () => {
    const id = uuid();
    handleAction?.({
      type: 'addLayer',
      layer: {
        id: id,
        name:
          selectedPromptHistoryItem?.prompt.text ||
          `Layer ${drawing?.layers.nodes.length + 1}`,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        image: selectedPromptHistoryItem?.output.imagePath,
        orderKey: genTopOrderKey(drawing?.layers.nodes),
      },
    });
    setSelectedPromptHistoryItem(undefined);
    setActiveLayerId(id);
  };

  const handleDownload = () => {
    if (!selectedPromptHistoryItem?.output.imagePath) return;

    const name = selectedPromptHistoryItem?.prompt.text || 'generated image';
    downloadFile(selectedPromptHistoryItem.output.imagePath, name);
    trackEvent('Image Export', { type: 'historyDownloadImage' });
    publishTrackingEvent({
      type: InferenceEventName.EXPORT_HISTORY_IMAGE,
      data: {
        promptId: selectedPromptHistoryItem.prompt.id,
        imagePaths: [selectedPromptHistoryItem.output.imagePath],
      },
    });
  };

  if (isViewer) {
    return null;
  }

  const historyPages =
    open &&
    pageCursors.map((cursor, index) => (
      <StudioHistoryPage
        key={index}
        selectedPromptHistoryItem={selectedPromptHistoryItem}
        setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
        drawingId={drawing.id}
        cursor={cursor}
        setNextPageCursor={(cursor) =>
          setPageCursors((cursors) => [...cursors, cursor])
        }
        updatePrompts={(cursor: string, prompts: PromptData[]) =>
          setPrompts((prev) => ({
            ...prev,
            [cursor]: prompts,
          }))
        }
      />
    ));

  return (
    <Container $state={state}>
      <Content $open={open} $shouldAnimate={!!prevOpen}>
        {disabled && <Overlay style={{ borderRadius: '0.5rem' }} />}

        <SidebarHeader>
          <SidebarHeaderContent>Generation History</SidebarHeaderContent>
          <Button
            size="S"
            variant="transparent"
            onClick={() => {
              setSelectedPromptHistoryItem(undefined);
              setOpen(false);
            }}
          >
            <CarretDownIcon />
          </Button>
        </SidebarHeader>

        <HistoryContainer>{historyPages}</HistoryContainer>

        <ActionBar $open={open}>
          <RichTooltip padding={11} placement="top">
            <RichTooltipTrigger>
              <Button
                onClick={() => {
                  if (disabled) return;
                  setOpen(!open);
                  setSelectedPromptHistoryItem(undefined);
                }}
                variant="transparent"
                size="iconSquared"
              >
                <HistoryIcon color={theme.text.default} />
              </Button>
            </RichTooltipTrigger>

            <RichTooltipContent style={{ color: theme.white }}>
              Generation History
            </RichTooltipContent>
          </RichTooltip>

          <HorizontalDivider />

          <RichTooltip padding={11} placement="top">
            <RichTooltipTrigger>
              <Button
                onClick={() => selectPreviousHistoryItem()}
                variant="transparent"
                size="icon"
              >
                <ArrowLeftIcon
                  width={24}
                  height={24}
                  fill={theme.icon.default}
                />
              </Button>
            </RichTooltipTrigger>

            <RichTooltipContent style={{ color: theme.white }}>
              Previous
            </RichTooltipContent>
          </RichTooltip>

          <RichTooltip padding={11} placement="top">
            <RichTooltipTrigger>
              <Button
                onClick={() => selectNextHistoryItem()}
                variant="transparent"
                size="icon"
              >
                <ArrowRightIcon
                  width={24}
                  height={24}
                  fill={theme.icon.default}
                />
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Next
            </RichTooltipContent>
          </RichTooltip>

          <RichTooltip padding={11} placement="top">
            <RichTooltipTrigger>
              <Button
                onClick={handleDownload}
                variant="transparent"
                size="icon"
              >
                <DownloadIcon />
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.white }}>
              Download
            </RichTooltipContent>
          </RichTooltip>

          <Button disabled={!selectedPromptHistoryItem} onClick={addItem}>
            Add
          </Button>
        </ActionBar>
      </Content>
    </Container>
  );
};

const ActionBar = styled.div<{ $open?: boolean }>`
  display: flex;
  position: absolute;
  bottom: 0;
  gap: 9px;
  align-items: center;
  bottom: 0;
  border-radius: 8px;
  z-index: 2;
  width: 100%;
  overflow: hidden;
  min-width: 48px;
  background: ${({ theme }) => theme.surface.e0};
  height: ${({ $open }) => ($open ? 'auto' : '48px')};
  padding: ${({ $open }) => ($open ? '8px 14px' : '8px')};
  transition: padding 0.3s ease-in-out;
`;

const HistoryContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;

  mask-image: linear-gradient(
    to bottom,
    rgba(21, 21, 23, 1) 75%,
    transparent 100%
  );

  ${styledScrollbar}
`;

const Container = styled.div<{ $state?: TransitionStatus }>`
  z-index: 10000000;
  background: ${({ theme }) => theme.surface.e0};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 1rem;
  left: 14px;
  gap: 16px;
  pointer-events: all;
  transition: all 0.5s ease-in-out;
  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateX(0)'
      : 'translateX(calc(-100% - 14px))'};
  animation: ${({ $state }) =>
    $state === 'entering' ? slideInFromLeft : 'none'};
`;

const slideAndExpand = keyframes`
  0% {
    width: 48px;
    height: 48px;
  }
  50% {
    width: 250px;
    height: 48px;
  }
  100% {
    width: 250px;
    height: 306px;
  }
`;

const slideAndShrink = keyframes`
  0% {
    width: 250px;
    height: 306px;
  }
  50% {
    width: 250px;
    height: 48px;
  }
  100% {
    width: 48px;
    height: 48px;
  }
`;

const Content = styled.div<{ $open: boolean; $shouldAnimate: boolean }>`
  display: grid;
  grid-template-rows: auto 1fr 53px;
  grid-template-columns: 1fr;
  gap: 8px;
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  width: 48px;
  height: 48px;
  animation: ${({ $open, $shouldAnimate }) =>
    $shouldAnimate
      ? $open
        ? css`
            ${slideAndExpand} 0.8s ease-in-out 1 forwards
          `
        : css`
            ${slideAndShrink} 0.8s ease-in-out 1 forwards
          `
      : 'none'};
`;
