import {
  Button,
  ImportImageIcon,
  addToast,
  browseForFiles,
  formatErrorMessage,
  resizeImageToPixelCount,
} from '@vizcom/shared-ui-components';
import { useStore } from '@react-three/fiber';
import { v4 as uuidv4 } from 'uuid';
import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../../../../constants';
import { useWorkbenchSyncedState } from '../../../../lib/useWorkbenchSyncedState';
import { assertExists, filterExists } from '@vizcom/shared/js-utils';
import {
  MAX_Z_POSITION,
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../../helpers';
import { WorkbenchElementSectionDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { ClientSideWorkbenchElementData } from '../../../../lib/clientState';
import { getSectionElements } from '../helpers';
import { findFirstFreeSlotFromElements } from '../../../utils/freeSlotFinders';
import { GENERATED_IMAGES_MARGIN } from '../../../../lib/utils';
import { useWorkbenchElementSelectionState } from '../../../../lib/elementSelectionState';
import { useTheme } from 'styled-components';

type WorkbenchElementSectionAddImagesProps = {
  sectionData: WorkbenchElementSectionDataFragment;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

export const WorkbenchElementSectionAddImages = ({
  sectionData,
  handleAction,
}: WorkbenchElementSectionAddImagesProps) => {
  const theme = useTheme();
  const store = useStore();
  const setFocusedElementsId = useWorkbenchElementSelectionState(
    (state) => state.setFocusedElementsId
  );

  const handleAddImages = async () => {
    const files = await browseForFiles({
      accept: 'image/*',
    });

    const filesData = (
      await Promise.all(
        files.map(async (file) => {
          let resized;
          try {
            resized = await resizeImageToPixelCount(
              file,
              WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
            );
          } catch (e: any) {
            addToast(
              `Error while importing file "${file.name}", please check it's a valid image.`,
              {
                type: 'danger',
                secondaryText: formatErrorMessage(e),
              }
            );
          }
          assertExists(resized);
          return { ...resized, name: file.name };
        })
      )
    ).filter(filterExists);

    if (!filesData.length) {
      return;
    }

    const zRange = getWorkbenchElementZPositionRange(store.getState().scene);
    let zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;
    const workbenchSizeRatio = 0.2;

    const newElements: ClientSideWorkbenchElementData[] = filesData.map(
      (file) => {
        return {
          __typename: 'Drawing',
          updatedAt: '0',
          id: uuidv4(),
          x: sectionData.x,
          y: sectionData.y,
          zIndex: zIndex++,
          drawingWidth: file.width,
          drawingHeight: file.height,
          workbenchSizeRatio,
          image: file.image,
          name: file.name ?? '',
        };
      }
    );

    const sectionElements = getSectionElements(
      store.getState().scene,
      sectionData
    );

    const slotWidth = Math.max(
      ...sectionElements.map((el) => el.userData.elementWidth),
      ...newElements.map((el) => getElementSize(el).width)
    );
    const slotHeight =
      Math.max(
        ...sectionElements.map((el) => el.userData.elementHeight),
        ...newElements.map((el) => getElementSize(el).height)
      ) + GENERATED_IMAGES_MARGIN;

    const firstSlotX = sectionElements.length
      ? Math.min(...sectionElements.map((el) => el.userData.elementX))
      : slotWidth / 2 + sectionData.x - sectionData.width / 2;
    const firstSlotY = sectionElements.length
      ? Math.max(...sectionElements.map((el) => el.userData.elementY))
      : sectionData.y;

    const finalElements = sectionElements.map((el) => ({
      id: el.userData.elementId,
      x: el.userData.elementX,
      y: el.userData.elementY,
      width: el.userData.elementWidth,
      height: el.userData.elementHeight,
    }));

    const maxElementPerLine = Math.max(
      1,
      Math.floor(sectionData.width / slotWidth)
    );
    let index = finalElements.length;
    // for each new element find a free slot and add it to the final elements
    newElements.forEach((element) => {
      const slot = findFirstFreeSlotFromElements(finalElements, {
        firstSlotX,
        firstSlotY,
        slotWidth,
        slotHeight,
        maxElementPerLine,
      });

      const size = getElementSize(element);
      finalElements.push({
        x:
          slot[0] +
          (index !== 0 && index++ % maxElementPerLine === 0
            ? 0
            : GENERATED_IMAGES_MARGIN),
        y: slot[1],
        width: size.width,
        height: size.height,
        id: element.id,
      });
    });

    // Calculate the maximum x and y coordinates from the final positions
    const { maxX, maxY, minX, minY } = finalElements.reduce(
      (acc, el) => {
        const x = el.x - el.width / 2;
        const y = el.y - el.height / 2;
        return {
          maxX: Math.max(acc.maxX, el.x + el.width / 2),
          maxY: Math.max(acc.maxY, el.y + el.height / 2),
          minX: Math.min(acc.minX, x),
          minY: Math.min(acc.minY, y),
        };
      },
      {
        maxX: Number.NEGATIVE_INFINITY,
        maxY: Number.NEGATIVE_INFINITY,
        minX: Number.POSITIVE_INFINITY,
        minY: Number.POSITIVE_INFINITY,
      }
    );

    // Check if there is enough space in the section
    if (
      maxX > sectionData.x + sectionData.width / 2 ||
      minY < sectionData.y - sectionData.height / 2
    ) {
      const newWidth = maxX - minX + GENERATED_IMAGES_MARGIN;
      const newHeight = maxY - minY + GENERATED_IMAGES_MARGIN;
      // Update the section's width and height
      handleAction({
        type: 'multiPosition',
        newElementData: [
          {
            id: sectionData.id,
            x:
              newWidth > sectionData.width
                ? minX - GENERATED_IMAGES_MARGIN / 2 + newWidth / 2
                : sectionData.x,
            y:
              newHeight > sectionData.height
                ? minY - GENERATED_IMAGES_MARGIN / 2 + newHeight / 2
                : sectionData.y,
            width: newWidth > sectionData.width ? newWidth : sectionData.width,
            height:
              newHeight > sectionData.height ? newHeight : sectionData.height,
          },
        ],
      });
    }

    handleAction({
      type: 'createElements',
      newElements: newElements.map((element) => ({
        ...element,
        x: finalElements.find((el) => el.id === element.id)?.x ?? element.x,
        y: finalElements.find((el) => el.id === element.id)?.y ?? element.y,
      })),
    });

    setFocusedElementsId(newElements.map(({ id }) => id).join('/'));
  };

  return (
    <Button size="iconSquared" variant="transparent" onClick={handleAddImages}>
      <ImportImageIcon style={{ color: theme.icon.default }} />
    </Button>
  );
};
