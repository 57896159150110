import {
  BOX_POINTS,
  getElementSize,
  isDarkColor,
  screenPositionToWorld,
  useCameraZoom,
} from '../../helpers';

import { WorkbenchElementSectionData } from '@vizcom/shared/data-access/graphql';

import { Line, Text } from '@react-three/drei';
import { useContext, useRef } from 'react';
import { getSelectionRects } from '../../utils/troikaText';
import { Group, OrthographicCamera } from 'three';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import { CustomHtml } from '../../utils/CustomHtml';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { getBoundingBoxSize } from '../../utils/getBoundingBoxSize';
import { WorkbenchElementSectionColor } from './extra/WorkbenchElementSectionColor';
import { useDrag } from '@use-gesture/react';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { isDraggingContext, useIsWorkbenchViewer } from '../../../lib/utils';
import { filterChildByWorkbenchObjectUserData } from '../../objectsUserdata';
import {
  handleScrollCanvas,
  useMapControls,
} from '../../utils/mapControls/utils';
import { textColors } from '../../../constants';
import { WorkbenchElementSectionAddImages } from './extra/WorkbenchElementSectionAddImages';
import { getSectionElements } from './helpers';
import { useWorkbenchElementSelectionState } from '../../../lib/elementSelectionState';

interface WorkbenchElementSectionProps {
  element: WorkbenchElementSectionData;
  isDragging: boolean;
  isResizing: boolean;
  focused: boolean;
  singleFocused: boolean;
  elementIsActive: boolean;
  setIsDragging: (value: boolean) => void;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string) => void;
}

export const SECTION_PADDING = 20;
export const SECTION_DEFAULT_COLOR = textColors[7];
export const SECTION_DEFAULT_TITLE = 'Section Title';

export const WorkbenchElementSection = ({
  element,
  isDragging,
  isResizing,
  elementIsActive,
  focused,
  singleFocused,
  setIsDragging,
  handleAction,
  setEditingElementId,
}: WorkbenchElementSectionProps) => {
  const titleRef = useRef<typeof Text>(null!);
  const groupRef = useRef<Group>(null!);
  const scene = useThree((state) => state.scene);
  const camera = useThree((state) => state.camera);
  const controls = useMapControls();
  const zoom = useCameraZoom();
  const isViewer = useIsWorkbenchViewer();
  const isDraggingRef = useContext(isDraggingContext);
  const { width, height } = getElementSize(element);

  const rects = getSelectionRects(
    (titleRef.current as any)?._textRenderInfo,
    0,
    element.title.length
  );

  const handleChangeColor = (color: string) => {
    handleAction({
      type: 'updateSection',
      elementId: element.id,
      color,
    });
  };

  const size = getBoundingBoxSize(groupRef.current);

  const bind = useDrag<ThreeEvent<PointerEvent>>(
    ({ event, intentional, down, last, memo }) => {
      // Stop the event from propagating to parent elements
      event.stopPropagation();

      // Handle the logic when the mouse button is pressed down
      if (down) {
        // Check if the shift key is pressed
        if (event.shiftKey) {
          // If the element is already in the focusedElementsId, remove it
          if (focused) {
            useWorkbenchElementSelectionState
              .getState()
              .removeElementFromFocus(element.id);
            return;
          } else {
            // If the element is not in the focusedElementsId, add it
            useWorkbenchElementSelectionState.getState().addToFocus(element.id);
            return;
          }
        } else {
          // If the shift key is not pressed, set the focusedElementsId to the current element
          if (!focused) {
            useWorkbenchElementSelectionState
              .getState()
              .setFocusedElementsId(element.id);
            setEditingElementId('');
            return;
          }
        }
      }

      // If the element is active or in viewer mode, return
      if (elementIsActive || isViewer) {
        return;
      }

      // Convert the pointer position from screen coordinates to world coordinates
      const pointerPosition = screenPositionToWorld(
        [event.clientX, event.clientY],
        camera as OrthographicCamera
      );

      // If it's the first click, compute the initial position and section children ids
      if (!memo) {
        return {
          initialPosition: pointerPosition,
          sectionChildrenIds: getSectionElements(scene, element).map(
            (el) => el.userData.elementId
          ),
        };
      }

      // On subsequent clicks, compute the delta between the current position and the initial position
      const { initialPosition, sectionChildrenIds } = memo;
      const delta = [
        pointerPosition[0] - initialPosition[0],
        pointerPosition[1] - initialPosition[1],
      ];

      if (intentional) {
        // Prevent elements actions firing when dragging
        isDraggingRef.current = down;
        setIsDragging(true);

        // Filter the matching children based on the workbench object user data
        const matchingChildren = filterChildByWorkbenchObjectUserData(
          scene,
          (userData) =>
            userData.workbenchObjectType ===
              'multi-focused-element-container' ||
            focused ||
            sectionChildrenIds.includes(userData.elementId)
        );

        if (last) {
          // If it's the last click, update the position of the elements
          const elements = matchingChildren.filter(
            ({ userData }) =>
              userData.workbenchObjectType !== 'multi-focused-element-container'
          );
          handleAction({
            type: 'multiPosition',
            newElementData: elements.map((element) => ({
              id: element.userData.elementId,
              x: element.position.x,
              y: element.position.y,
              zIndex: element.position.z,
              width: element.userData.elementWidth,
              height: element.userData.elementHeight,
            })),
          });
          setIsDragging(false);
          // we intentionnal don't revert the position back on the group element
          // to let React do it on the next render loop when the client state has been updated correctly
          // this is required to prevent a one-frame flash of the old position
        } else {
          matchingChildren.forEach((child) => {
            child.position.set(
              child.userData.elementX + delta[0],
              child.userData.elementY + delta[1],
              child.userData.elementZIndex
            );
          });
          // move camera if dragging element to edge of screen
          handleScrollCanvas(event, camera, controls);
        }
      }
      return memo;
    },
    {
      threshold: 5,
      triggerAllEvents: true,
      pointer: {
        keys: false,
      },
    }
  );

  const fallback = (
    <>
      <mesh scale={[width, height, 1]}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial color={element.color} opacity={0.2} transparent />
      </mesh>
      <mesh scale={[width, height, 1]}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial color="white" />
      </mesh>
      <Line
        points={BOX_POINTS}
        color={element.color}
        lineWidth={1.5}
        scale={[width / 2, height / 2, 1]}
      />
    </>
  );

  if (isResizing) {
    return fallback;
  }

  return (
    <>
      <group
        ref={groupRef}
        position={
          rects?.length
            ? [-element.width / 2 + size.x / 2, element.height / 2 + size.y, 0]
            : [0, 0, 0]
        }
        {...(bind() as any)}
      >
        <Text
          ref={titleRef}
          color={isDarkColor(element.color) ? '#FFFFFF' : '#000000'}
          fontSize={4 * (4 - zoom)}
          anchorX="center"
          anchorY="middle"
          fontWeight={600}
        >
          {element.title}
        </Text>
        <mesh>
          <RoundedPlaneGeometry
            radius={1.5 * (4 - zoom)}
            width={
              rects?.length
                ? Math.abs(rects[0].left + (rects[0].right - rects[0].left)) *
                  2.5
                : 0
            }
            height={
              rects?.length
                ? Math.abs(rects[0].top + (rects[0].bottom - rects[0].top)) * 3
                : 0
            }
          />
          <meshBasicMaterial color={element.color} />
        </mesh>
      </group>
      {fallback}
      {!isDragging && singleFocused && (
        <CustomHtml
          style={{ pointerEvents: 'none' }}
          position={[0, height / 2 + 30, 0.5]}
        >
          <WorkbenchElementExtra element={element} handleAction={handleAction}>
            <WorkbenchElementSectionColor
              color={element.color}
              handleChangeColor={handleChangeColor}
            />
            <WorkbenchElementSectionAddImages
              sectionData={element}
              handleAction={handleAction}
            />
          </WorkbenchElementExtra>
        </CustomHtml>
      )}
    </>
  );
};
