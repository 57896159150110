import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-height: 250px;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;

export const GenerationPreview = styled.div`
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.surface.e1};
`;
