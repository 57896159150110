import { Matrix3, Vector2 } from 'three';
import { Symmetry } from '../studioState';

const origin = new Vector2();
export const symmetryTransform = (
  axis: Exclude<Symmetry, false>,
  scale: [number, number],
  point: Vector2
) => {
  origin.set(axis.origin[0] * scale[0], axis.origin[1] * scale[1]);
  point.rotateAround(origin, axis.rotation);
  point.x += (origin.x - point.x) * 2;
  point.rotateAround(origin, -axis.rotation);
};

export function getSymmetryMatrix(
  axis: Exclude<Symmetry, false>,
  scale: [number, number]
) {
  origin.set(axis.origin[0] * scale[0], axis.origin[1] * scale[1]);
  return new Matrix3()
    .translate(-origin.x, -origin.y)
    .rotate(-axis.rotation)
    .scale(-1, 1)
    .rotate(axis.rotation)
    .translate(origin.x, origin.y);
}
