import {
  urqlClient,
  UpdateWorkbenchElementTextMutation,
} from '@vizcom/shared/data-access/graphql';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { CSSProperties } from 'react';

export const UpdateTextAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updateText';
    elementId: string;
    content?: string;
    height?: number;
    width?: number;
    contentHeight?: number;
    lockWidth?: boolean;
    x?: number;
    y?: number;
    style?: Partial<CSSProperties>;

    final: boolean;
  }
> = {
  type: 'updateText',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementText') {
      sourceElement.content = payload.content ?? sourceElement.content;
      sourceElement.height = payload.height ?? sourceElement.height;
      sourceElement.width = payload.width ?? sourceElement.width;
      sourceElement.contentHeight =
        payload.contentHeight ?? sourceElement.contentHeight;
      sourceElement.lockWidth = payload.lockWidth ?? sourceElement.lockWidth;
      sourceElement.x = payload.x ?? sourceElement.x;
      sourceElement.y = payload.y ?? sourceElement.y;
      sourceElement.style = payload.style ?? sourceElement.style;
    }
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(UpdateWorkbenchElementTextMutation, {
      input: {
        id: payload.elementId,
        patch: {
          content: payload.content,
          contentHeight: payload.contentHeight,
          height: payload.height,
          width: payload.width,
          lockWidth: payload.lockWidth,
          style: payload.style,
          x: payload.x,
          y: payload.y,
        },
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while updating element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload) => ({
    debounceId: `updateText|${payload.elementId}`,
    delay: payload.final ? undefined : 2000,
  }),
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element || element.__typename !== 'WorkbenchElementText') {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof UpdateTextAction> = {
      type: 'updateText',
      elementId: payload.elementId,
      final: true,
      content: element.content,
      contentHeight: element.contentHeight,
      lockWidth: element.lockWidth,
      style: element.style,
      height: element.height,
      width: element.width,
      x: element.x,
      y: element.y,
    };

    return undoPayload;
  },
};
