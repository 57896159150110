import styled from 'styled-components';
import {
  Dropdown,
  FormattedDate,
  LoadingLogoInset,
  RelativeDate,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TeamAvatar,
  Text,
  UserAvatar,
} from '@vizcom/shared-ui-components';
import {
  RecentWorkbenchOrdering,
  WorkbenchBasicDataFragment,
} from 'libs/shared/data-access/graphql/src/gql/graphql';
import { paths } from '@vizcom/shared-utils-paths';
import { Link } from 'react-router-dom';

const AllFilesSection: React.FC<{
  orderBy: RecentWorkbenchOrdering;
  setOrderBy: (order: RecentWorkbenchOrdering) => void;
  fetching: boolean;
  loading: boolean;
  workbenches: WorkbenchBasicDataFragment[];
}> = ({ orderBy, setOrderBy, fetching, loading, workbenches }) => {
  return (
    <>
      <AllFilesSectionHeader>
        <AllFilesTitle block type="h1">
          All files
        </AllFilesTitle>
        <Dropdown
          options={[
            {
              data: {
                value: RecentWorkbenchOrdering.UpdatedAt,
                label: 'Last edited',
              },
            },
            {
              data: {
                value: RecentWorkbenchOrdering.CreatedAt,
                label: 'Last created',
              },
            },
          ]}
          value={orderBy}
          setValue={(v) => setOrderBy(v as RecentWorkbenchOrdering)}
          optionToValueMapper={(option) => option.value}
          OptionComponent={({ option }) => <Text>{option.label}</Text>}
        >
          {orderBy === RecentWorkbenchOrdering.UpdatedAt
            ? 'Last edited'
            : 'Last created'}
        </Dropdown>
      </AllFilesSectionHeader>
      <TableContainer>
        {/* Loading indicator overlay */}
        <LoadingLogoInset active={fetching || loading} />
        <Table cols={4} addMenuColumn={false}>
          <TableHeader>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Edited by</TableCell>
            <TableCell>Location</TableCell>
          </TableHeader>
          {workbenches?.map((workbench) => (
            <RecentFileRow
              key={workbench.id}
              as={Link}
              to={paths.workbench.file(workbench.id)}
            >
              {/* File name and thumbnail */}
              <TableCell>
                <FileInfo>
                  <ThumbnailContainer>
                    {workbench.thumbnail && (
                      <ThumbnailImage
                        crossOrigin="anonymous"
                        src={workbench.thumbnail}
                        alt=""
                      />
                    )}
                  </ThumbnailContainer>
                  <div>
                    <FileName block type="sh2">
                      {workbench.name}
                    </FileName>
                    <FileEditInfo block type="b2" color="info">
                      Edited <RelativeDate date={workbench.updatedAt} />
                    </FileEditInfo>
                  </div>
                </FileInfo>
              </TableCell>
              {/* Creator information */}
              <TableCell>
                {workbench.creator && (
                  <CreatorInfo>
                    <UserAvatar
                      name={workbench.creator.name ?? workbench.creator.email}
                      userId={workbench.creator.id}
                      size="small"
                    />
                    <CreatorName>
                      {workbench.creator.name ?? workbench.creator.email}
                    </CreatorName>
                  </CreatorInfo>
                )}
                <CreationDate type="b2" color="info">
                  <FormattedDate date={workbench.createdAt} />
                </CreationDate>
              </TableCell>
              {/* Last updated by information */}
              <TableCell>
                {workbench.lastUpdatedBy ? (
                  <LastUpdatedInfo>
                    <UserAvatar
                      name={
                        workbench.lastUpdatedBy.name ??
                        workbench.lastUpdatedBy.email
                      }
                      userId={workbench.lastUpdatedBy.id}
                      size="small"
                    />
                    <LastUpdatedName type="b2">
                      {workbench.lastUpdatedBy.name ??
                        workbench.lastUpdatedBy.email}
                    </LastUpdatedName>
                  </LastUpdatedInfo>
                ) : (
                  <NotAvailableText type="b2" color="info">
                    Not available
                  </NotAvailableText>
                )}
              </TableCell>
              {/* File location (team or personal files) */}
              <TableCell>
                <LocationLink
                  to={paths.files.folder(workbench.folderId)}
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                >
                  {workbench.team ? (
                    <>
                      <TeamAvatar
                        name={workbench.team.name}
                        teamId={workbench.team.id}
                        size="small"
                      />
                      <LocationLabel>{workbench.team.name}</LocationLabel>
                    </>
                  ) : (
                    <LocationLabel>My files</LocationLabel>
                  )}
                </LocationLink>
              </TableCell>
            </RecentFileRow>
          ))}
        </Table>
      </TableContainer>
    </>
  );
};

export default AllFilesSection;

const AllFilesSectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 35px 0;
`;

const TableContainer = styled.div`
  position: relative;
`;

const ThumbnailContainer = styled.div`
  width: 50px;
  height: 32px;
  border-radius: ${(p) => p.theme.borderRadius.default};
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecentFileRow = styled(TableRow)`
  transition: 0.2s all ease;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: ${(p) => p.theme.surface.e1};
  }
`;

const AllFilesTitle = styled(Text)`
  flex: 1;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FileName = styled(Text)`
  margin-bottom: 6px;
`;

const FileEditInfo = styled(Text)``;

const CreatorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const CreatorName = styled.span``;

const CreationDate = styled(Text)``;

const LastUpdatedInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LastUpdatedName = styled(Text)``;

const NotAvailableText = styled(Text)``;

const LocationLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: inherit;
`;

const LocationLabel = styled.span`
  &:hover {
    text-decoration: underline;
  }
`;

const ThumbnailImage = styled.img``;
