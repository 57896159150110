export enum BlendMode {
  normal = 'normal',
  multiply = 'multiply',
}

export interface BrushSegment {
  startPosition: [number, number];
  startPressure: number;
  endPosition: [number, number];
  endPressure: number;
  isLast?: boolean;
}

export type Layout = 'default' | 'classic' | 'stacked';
