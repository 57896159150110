import { v4 as uuidv4 } from 'uuid';
import { FC } from 'react';
import {
  addToast,
  eventTargetIsInput,
  useDocumentEventListener,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { LayerDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import {
  copyWorkbenchElementsToClipboard,
  pasteWorkbenchElementsFromClipboard,
  workbenchElementClipboardDataFromString,
} from '../lib/workbenchClipboardUtils';
import { useStore } from '@react-three/fiber';
import { filterExists } from '@vizcom/shared/js-utils';
import { handleCopyDrawingImage } from '../lib/drawingUtils';
import { elementById, useIsWorkbenchViewer } from '../lib/utils';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { useWorkbenchToolState } from './toolbar/WorkbenchToolContext';

interface WorkbenchKeyboardShortcutsProps {
  elements: ClientSideWorkbenchElementData[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  undoAction: () => void;
  redoAction: () => void;
}

export type WorkbenchElementData = ClientSideWorkbenchElementData & {
  layersOrder?: string[];
  layers?: { nodes: LayerDataFragment[] };
  image?: Blob;
  type?: string;
};

const WorkbenchKeyboardShortcuts: FC<WorkbenchKeyboardShortcutsProps> = ({
  elements,
  handleAction,
  undoAction,
  redoAction,
}) => {
  const store = useStore();
  const isViewer = useIsWorkbenchViewer();
  const { setTool } = useWorkbenchToolState();

  // useDocumentEventListener('cut', async () => {
  //   const data = await getElementData();
  //   await navigator.clipboard.writeText(
  //     JSON.stringify({ type: ELEMENTS_CLIPBOARD_KEY, data })
  //   );
  //   handleAction({
  //     type: 'deleteElements',
  //     elementIds: focusedElementIds,
  //   });
  // });

  useKeyboardShortcut(
    'escape',
    (e) => {
      if (isViewer) return;
      setTool('select');
    },
    {
      capture: true, // needed to capture the event before it propagates
    }
  );

  useKeyboardShortcut('t', () => {
    if (isViewer) return;

    setTool('text');
  });

  useDocumentEventListener('copy', (e) => {
    if (eventTargetIsInput(e)) {
      return;
    }

    const focusedElementsId =
      useWorkbenchElementSelectionState.getState().focusedElementsId;

    const data = focusedElementsId
      .split('/')
      .filter(Boolean)
      .map((id) => elementById(elements, id))
      .filter(filterExists);

    if (!data.length) {
      return false;
    }
    e.stopPropagation();
    e.preventDefault();
    copyWorkbenchElementsToClipboard(elements, focusedElementsId);
  });

  useDocumentEventListener('paste', async (event) => {
    if (isViewer) return;

    const data = event.clipboardData?.getData('text/plain');

    if (workbenchElementClipboardDataFromString(data)) {
      event.preventDefault();
      event.stopPropagation();
      await pasteWorkbenchElementsFromClipboard(
        data!,
        handleAction,
        useWorkbenchElementSelectionState.getState().setFocusedElementsId,
        store.getState()
      );
    }
  });

  useKeyboardShortcut(
    'd',
    (e) => {
      e.preventDefault();

      if (isViewer) return;

      const focusedElementIds = useWorkbenchElementSelectionState
        .getState()
        .focusedElementsId.split('/')
        .filter(Boolean);

      const newElementIds = focusedElementIds.map(() => uuidv4());

      handleAction({
        type: 'duplicateElements',
        elementIds: focusedElementIds,
        newElementIds,
      });
      useWorkbenchElementSelectionState
        .getState()
        .setFocusedElementsId(newElementIds.join('/'));
    },
    { ctrl: true }
  );

  useKeyboardShortcut(
    'c',
    (e) => {
      e.preventDefault();

      const focusedElementIds = useWorkbenchElementSelectionState
        .getState()
        .focusedElementsId.split('/')
        .filter(Boolean);

      const drawings = elements.filter(
        (element) =>
          element.__typename === 'Drawing' &&
          focusedElementIds.includes(element.id)
      );

      if (drawings.length > 1) {
        addToast('Only one image can be copied at a time', {
          type: 'danger',
        });
        return;
      }

      if (drawings.length === 0) return;
      handleCopyDrawingImage(drawings[0]);
    },
    { shift: true, ctrl: true }
  );

  useKeyboardShortcut('z', undoAction, {
    ctrl: true,
  });

  useKeyboardShortcut('z', redoAction, {
    ctrl: true,
    shift: true,
  });

  return null;
};

export default WorkbenchKeyboardShortcuts;
