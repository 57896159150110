/**
 * NOTE Icon component relying on CSS masking - allows to create single-color icons out of any image format
 * (making it a bit easier to control SVG color etc.)
 */
import styled from 'styled-components';

export const Icon = ({
  icon,
  color,
  size,
  ...props
}: {
  icon: string;
  color: string;
  size?: number;
  [key: string]: any;
}) => {
  return (
    <MaskWrapper {...props} $color={color} $mask={icon} $size={size ?? 24} />
  );
};

const MaskWrapper = styled.div<{
  $color: string;
  $mask: string;
  $size: number;
}>`
  position: relative;
  display: inline-block;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  mask-image: url(\"${({ $mask }) => $mask}\");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: ${({ $color }) => $color};
`;
