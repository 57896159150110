import { v4 as uuidv4 } from 'uuid';
import { Button, DuplicateIcon, Tooltip } from '@vizcom/shared-ui-components';
import { useWorkbenchSyncedState } from '../../../../lib/useWorkbenchSyncedState';

import { useTheme } from 'styled-components';
import { ClientSideWorkbenchElementPalette } from '../../../../lib/clientState';

type WorkbenchElementPaletteDuplicateProps = {
  paletteData: ClientSideWorkbenchElementPalette;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

export const WorkbenchElementPaletteDuplicate = ({
  paletteData,
  handleAction,
}: WorkbenchElementPaletteDuplicateProps) => {
  const theme = useTheme();
  return (
    <Tooltip tip="Duplicate" position="top">
      <Button
        size="iconSquared"
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          handleAction({
            type: 'duplicateElements',
            elementIds: [paletteData.id],
            newElementIds: [uuidv4()],
          });
        }}
      >
        <DuplicateIcon
          style={{ color: theme.icon.default, width: '24px', height: '24px' }}
        />
      </Button>
    </Tooltip>
  );
};
