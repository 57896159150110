import { useMemo, forwardRef, useImperativeHandle } from 'react';
import { createPortal, useFrame, useThree } from '@react-three/fiber';
import { useFBO } from '@react-three/drei';
import {
  Color,
  ColorRepresentation,
  DoubleSide,
  NearestFilter,
  OrthographicCamera,
  Scene,
  Texture,
  UnsignedByteType,
  WebGLRenderer,
} from 'three';
import { yFlipImageBuffer } from '../../../helpers';
import { WORKBENCH_CONTENT_RENDERING_ORDER } from '../../../../constants';
import { useStableCallback } from '../../../../../../../../shared/ui/components/src';
import { BrushCompositor } from './BrushCompositor';

const auxColor = new Color();
const clearColor = new Color(0);
const camera = new OrthographicCamera(-1, 1, 1, -1, -1, 1);

export interface BrushTextureRendererRef {
  exportTexture: () => ImageData;
}

export const BrushTextureRenderer = forwardRef<
  BrushTextureRendererRef,
  {
    size: [number, number];
    isEraser?: boolean;
    layerFill?: ColorRepresentation;
    layerTexture?: Texture;
    brushTexture?: Texture;
    maskTexture?: Texture;
    brushOpacity: number;
  }
>(
  (
    {
      size,
      isEraser,
      layerFill,
      layerTexture,
      brushTexture,
      maskTexture,
      brushOpacity,
    },
    ref
  ) => {
    const fbo = useFBO(size[0], size[1], {
      samples: 0,
      type: UnsignedByteType,
      minFilter: NearestFilter,
      magFilter: NearestFilter,
    });
    const gl = useThree((s) => s.gl);
    const scene = useMemo(() => new Scene(), []);

    const render = useStableCallback((gl: WebGLRenderer) => {
      const currentAutoClear = gl.autoClear;
      const currentClearAlpha = gl.getClearAlpha();
      const currentClearColor = gl.getClearColor(auxColor);
      const currentRenderTarget = gl.getRenderTarget();
      gl.autoClear = true;
      gl.setRenderTarget(fbo);
      gl.setClearColor(clearColor);
      gl.setClearAlpha(0);
      gl.render(scene, camera);
      gl.autoClear = currentAutoClear;
      gl.setRenderTarget(currentRenderTarget);
      gl.setClearColor(currentClearColor);
      gl.setClearAlpha(currentClearAlpha);
    });

    const exportTexture = useStableCallback(() => {
      render(gl);

      const imageData = new ImageData(size[0], size[1]); // we want exportTexture to be synchronous to simplify the rest of the logic of the brush handling
      // to do so, we read the pixels and store them in an image that then get asynchrounously converted to a png blob
      // just before sending to the server
      gl.readRenderTargetPixels(fbo, 0, 0, size[0], size[1], imageData.data);
      // flip the image upside down because readRenderTargetPixels read pixels from the bottom left corner
      yFlipImageBuffer(imageData.data, size[0]);
      return imageData;
    });

    useImperativeHandle(
      ref,
      () => ({
        exportTexture,
      }),
      [exportTexture]
    );

    useFrame(
      (state) => render(state.gl),
      WORKBENCH_CONTENT_RENDERING_ORDER.indexOf('individualLayerTexture')
    );

    return (
      <>
        <mesh scale={[size[0], size[1], 1]}>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial transparent side={DoubleSide} map={fbo.texture} />
        </mesh>
        {createPortal(
          <BrushCompositor
            opacity={brushOpacity}
            srcTexture={brushTexture}
            maskTexture={maskTexture}
            dstFill={layerFill}
            dstTexture={layerTexture}
            isEraser={isEraser}
          />,
          scene
        )}
      </>
    );
  }
);
