import { ReactNode } from 'react';
import { useKeyboardShortcut } from '@vizcom/shared-ui-components';
import { useWorkbenchStudioToolState } from './studioState';

export const FlippableGroup = (props: { children?: ReactNode | undefined }) => {
  const { flipped, setFlipped } = useWorkbenchStudioToolState();

  useKeyboardShortcut('f', () => setFlipped(!flipped));
  useKeyboardShortcut('escape', () => setFlipped(false));

  return <group scale={[flipped ? -1 : 1, 1, 1]}>{props.children}</group>;
};
