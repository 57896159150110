import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Layer from './Layer';

import {
  SortableList,
  styledScrollbarDark,
  useWindowEventListener,
} from '@vizcom/shared-ui-components';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { useIsTouchScreen } from '../../lib/touchState';
import { filterExists } from '../../../../../../shared/js-utils/src';
import { base62 } from 'mudder';
import { sortByOrderKey } from '@vizcom/shared/js-utils';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';

const Content = styled.div`
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 0 8px 0 8px;
  height: 100%;
  width: 100%;
  position: relative;
  margin-bottom: 100px;
  ${styledScrollbarDark}
`;

const Frame = styled.div`
  width: 250px;
  height: 100%;
  display: flex;
  color: ${({ theme }) => theme.text.default};
  overflow: hidden;
  border-radius: 0 0 16px 16px;
`;

const Layers = ({
  activeLayer,
  drawing,
  viewerVisibility,
  setActiveLayer,
  setViewerVisibility,
  handleAction,
  onCreateDrawingFromImage,
}: {
  activeLayer: string | undefined;
  drawing: Drawing2dStudio;
  viewerVisibility: Record<string, boolean>;
  setActiveLayer: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  onCreateDrawingFromImage: (
    preview: ArrayBuffer | Blob,
    offset?: {
      x: number;
      y: number;
    },
    name?: string
  ) => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null!);
  const [editingLayer, setEditingLayer] = useState<string | null>(null);
  const [isTogglingMultiLayerVisibility, setTogglingMultiLayerVisibility] =
    useState<boolean | undefined>(undefined);
  const [storedVisibilities, setStoredVisibilities] = useState<Record<
    string,
    boolean
  > | null>(null);
  const isViewer = useIsWorkbenchViewer();
  const isTouchContext = useIsTouchScreen();
  const sortedLayers = sortByOrderKey(drawing.layers.nodes);

  // maintain and reference a local state of the layers order to prevent a flash when order changes
  const [layerOrder, setLayerOrder] = useState(sortedLayers.map((l) => l.id));

  const isolateLayer = (id: string) => {
    if (!drawing) return;
    const layers = drawing?.layers.nodes.reduce((acc, layer) => {
      acc[layer.id] = layer;
      return acc;
    }, {} as Record<string, typeof drawing.layers.nodes[0]>);

    if (storedVisibilities) {
      if (!layers[id].visible) {
        handleAction?.({
          type: 'updateBulkLayers',
          layerUpdates: Object.values(layers).map((layer) => ({
            id: layer.id,
            visible: layer.id === id,
          })),
        });
        return;
      } else {
        handleAction?.({
          type: 'updateBulkLayers',
          layerUpdates: Object.entries(storedVisibilities).map(([k, v]) => ({
            id: k,
            visible: v,
          })),
        });
        setStoredVisibilities(null);
        return;
      }
    }

    const currentVisibilities = drawing?.layers.nodes.reduce((acc, layer) => {
      acc[layer.id] = layer.visible;
      return acc;
    }, {} as Record<string, boolean>);
    setStoredVisibilities(currentVisibilities);

    handleAction?.({
      type: 'updateBulkLayers',
      layerUpdates: drawing?.layers.nodes.map((layer) => ({
        id: layer.id,
        visible: layer.id === id,
      })),
    });
  };

  const onChange = (
    newOrder: string[],
    nextItem: Drawing2dStudio['layers']['nodes'][0] | undefined,
    previousItem: Drawing2dStudio['layers']['nodes'][0] | undefined
  ) => {
    if (!activeLayer) return;

    const movedLayerCount = activeLayer.split('/').length;
    const orderKeys = previousItem?.orderKey
      ? base62.mudder(
          nextItem?.orderKey,
          previousItem?.orderKey,
          movedLayerCount
        )
      : base62.mudder(nextItem?.orderKey, undefined, movedLayerCount);
    const existingOrderKeys = drawing.layers.nodes.map((l) => l.orderKey);
    const movedLayers = activeLayer.split('/').map((id, i) => {
      let orderKey = orderKeys[i];
      if (existingOrderKeys.includes(orderKeys[i])) {
        orderKey = base62.mudder(
          orderKeys[i],
          orderKeys[i + 1] ?? undefined
        )[0];
      }
      return {
        id,
        orderKey,
      };
    });

    handleAction?.({
      type: 'updateBulkLayers',
      layerUpdates: movedLayers,
    });

    setLayerOrder(newOrder);
  };

  useEffect(() => {
    setLayerOrder(sortedLayers.map((l) => l.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedLayers.map((l) => l.id).join(',')]);

  useWindowEventListener('keydown', (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  });

  const orderedLayers = layerOrder
    .map((id) => drawing.layers.nodes.find((l) => l.id === id))
    .filter(filterExists);

  return (
    <Frame>
      <Content ref={contentRef}>
        <SortableList
          activeLayerIds={activeLayer?.split('/') ?? []}
          items={orderedLayers}
          onChange={onChange}
          setActiveLayer={setActiveLayer}
          renderItem={(item, i) => (
            <SortableList.Item
              id={item.id}
              draggable={!isTouchContext && !isViewer}
              activeLayerIds={activeLayer?.split('/') ?? []}
            >
              <Layer
                drawing={drawing}
                layer={item}
                isLast={i === drawing.layers.nodes.length - 1}
                setTogglingMultiLayerVisibility={
                  setTogglingMultiLayerVisibility
                }
                isTogglingMultiLayerVisibility={isTogglingMultiLayerVisibility}
                isolateLayer={isolateLayer}
                activeLayer={activeLayer}
                setActiveLayer={setActiveLayer}
                editingLayer={editingLayer}
                setEditingLayer={setEditingLayer}
                handleAction={handleAction}
                viewerVisibility={viewerVisibility}
                setViewerVisibility={setViewerVisibility}
                onCreateDrawingFromImage={onCreateDrawingFromImage}
              />
            </SortableList.Item>
          )}
        />
      </Content>
    </Frame>
  );
};

export default Layers;
