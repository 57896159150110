import { MathUtils } from 'three';

export const stabilizePoint = (
  point: {
    stabilizedScreenPosition: [number, number];
    pressure: number;
  },
  previous: {
    stabilizedScreenPosition: [number, number];
    pressure: number;
  }[],
  maxPoints: number
) => {
  if (maxPoints === 0) {
    return;
  }

  const { length } = previous;
  // Iterate through previous points going backwards
  for (let i = 1; i <= Math.min(length, maxPoints); i++) {
    const other = previous[length - i];
    // Lerp the point position into the previous point.
    // Descreasing the interpolation factor on every iteration.
    // (Each previous point influence descreases as it gets further down the chain)
    const t = 1 - i / (maxPoints + 1);
    point.stabilizedScreenPosition[0] = MathUtils.lerp(
      point.stabilizedScreenPosition[0],
      other.stabilizedScreenPosition[0],
      t
    );
    point.stabilizedScreenPosition[1] = MathUtils.lerp(
      point.stabilizedScreenPosition[1],
      other.stabilizedScreenPosition[1],
      t
    );
    point.pressure = MathUtils.lerp(point.pressure, other.pressure, t);
  }
};
