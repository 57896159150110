import { Texture } from 'three';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import {
  WorkbenchStudioTool,
  isSelectionTool,
  useWorkbenchStudioToolState,
} from '../studioState';
import { BrushRenderer } from './BrushEngine/BrushRenderer';
import { LayerTransformControls } from './LayerTransform/LayerTransformControls';
import { LayerWarpControls } from './LayerTransform/LayerWarpControls';
import { Layer3dEditor } from './Layer3D/Layer3dEditor';
import { PaintBucketRenderer } from './PaintBucketRenderer';
import { ShapeRenderer } from './ShapeRenderer';
import { assertUnreachable } from '../../../../../../../shared/js-utils/src';
import { LayerStaticView } from './CompositeLayer';
import { LayerContent } from './LayersCompositor/LayerContent';
import { useKeyboardShortcut } from '@vizcom/shared-ui-components';
import { useEraseSelectedRegion } from './eraseSelectedRegion';
import { useSelectionApiStore } from '../selection/useSelectionApi';

interface LayerEditorProps {
  layer: Drawing2dStudio['layers']['nodes'][0];
  drawingSize: [number, number];
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
  layerImage: Texture | undefined;
}

export const LayerEditor = ({
  layer,
  drawingSize,
  handleAction,
  filterHistory: filterHistory,
  layerImage,
}: LayerEditorProps) => {
  const toolState = useWorkbenchStudioToolState();
  const selectionApiStore = useSelectionApiStore();

  const { eraseSelectedRegion } = useEraseSelectedRegion();

  useKeyboardShortcut(['Delete', 'Backspace'], () => {
    if (!selectionApiStore.getState().hasMask) {
      return;
    }

    let imageToErase: Texture | string | undefined = layerImage;
    if (!imageToErase) {
      if (layer.fill.length === 7) {
        imageToErase = layer.fill;
      } else {
        return;
      }
    }
    const erasedImage = eraseSelectedRegion(
      imageToErase,
      selectionApiStore.getState().texture,
      drawingSize
    );

    handleAction({
      type: 'updateLayer',
      id: layer.id,
      data: { image: erasedImage },
    });
  });

  const layerFallback = (
    <LayerContent
      id={layer.id}
      opacity={layer.opacity}
      blendMode={layer.blendMode}
      visible={layer.visible}
    >
      <LayerStaticView layer={layer} drawingSize={drawingSize} />
    </LayerContent>
  );

  if (layer.metadata3D) {
    return (
      <Layer3dEditor
        drawingSize={drawingSize}
        layer={layer}
        active={!isSelectionTool(toolState.tool)}
        handleAction={handleAction}
        layerFallback={layerFallback}
      />
    );
  }

  if (
    toolState.tool === WorkbenchStudioTool.Brush ||
    toolState.tool === WorkbenchStudioTool.Eraser
  ) {
    return (
      <BrushRenderer
        drawingSize={drawingSize}
        layer={layer}
        layerImage={layerImage}
        handleAction={handleAction}
      />
    );
  }

  if (toolState.tool === WorkbenchStudioTool.Shape) {
    return (
      <ShapeRenderer
        drawingSize={drawingSize}
        layer={layer}
        layerImage={layerImage}
        handleAction={handleAction}
      />
    );
  }

  if (toolState.tool === WorkbenchStudioTool.PaintBucket) {
    return (
      <PaintBucketRenderer
        drawingSize={drawingSize}
        layer={layer}
        layerImage={layerImage}
        handleAction={handleAction}
      />
    );
  }

  if (toolState.tool === WorkbenchStudioTool.Move) {
    return (
      <LayerTransformControls
        drawingSize={drawingSize}
        layer={layer}
        layerImage={layerImage}
        handleAction={handleAction}
        filterHistory={filterHistory}
      />
    );
  }

  if (toolState.tool === WorkbenchStudioTool.Transform) {
    return (
      <LayerWarpControls
        drawingSize={drawingSize}
        layer={layer}
        layerImage={layerImage}
        handleAction={handleAction}
        filterHistory={filterHistory}
      />
    );
  }

  if (isSelectionTool(toolState.tool)) {
    return layerFallback;
  }

  assertUnreachable(toolState.tool);
};
