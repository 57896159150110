import styled from 'styled-components';
import { Palette } from '.';
import { Button, CheckIcon, MenuDivider } from '@vizcom/shared-ui-components';

export type VersionedPalette = Palette & {
  versions: { value: string; version: number }[];
};

type Props = {
  selectedPaletteId: string;
  palette: VersionedPalette;
  handleSelectPalette: (
    paletteId: string,
    version: number,
    paletteValue: string,
    type: string
  ) => void;
};

export const PaletteVersions = ({
  selectedPaletteId,
  palette,
  handleSelectPalette,
}: Props) => {
  return (
    <Container>
      {palette.versions.map(({ value, version }, i) => (
        <Button
          key={version}
          variant="transparent"
          onClick={(e) => {
            e.stopPropagation();
            handleSelectPalette(palette.id, version, value, palette.type);
          }}
        >
          {selectedPaletteId === value && <StyledCheckIcon />} v{version}{' '}
          {i === 0 ? <New>NEW</New> : ''}
        </Button>
      ))}
      <Button
        variant="transparent"
        onClick={(e) => {
          e.stopPropagation();
          handleSelectPalette(palette.id, 1, palette.value, palette.type);
        }}
      >
        {selectedPaletteId === palette.value && <StyledCheckIcon />}v1
      </Button>
      <MenuDivider />
      <Button
        variant="transparent"
        as="a"
        target="_blank"
        href="https://docs.vizcom.ai/render-styles"
      >
        View Details
      </Button>
    </Container>
  );
};

const Container = styled.div`
  color: ${(p) => p.theme.text.default};
  max-width: 120px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  > button,
  a {
    font-size: 12px;
  }
`;

const New = styled.span`
  color: ${(p) => p.theme.text.primaryAccent};
  position: absolute;
  right: 22px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  left: 30px;
  height: 12px;
  width: 12px;
`;
