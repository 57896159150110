import { LoggedInAuthGuard } from '@vizcom/auth/ui';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { SelectOrganizationForPlanUpgradePage } from './SelectOrganizationForPlanUpgradePage';
import { ReviewEditorsBeforePlanUpgradePage } from './ReviewEditorsBeforePlanUpgradePage';
import { CreateOrganizationHeader } from '../organization/CreateOrganizationPage/CreateOrganizationHeader';
import styled from 'styled-components';
import { StepIndicator } from '../organization/CreateOrganizationPage/stepIndicator';
import { OrganizationSubscriptionSuccessPage } from './OrganizationSubscriptionSuccessPage';

export const UpgradeSubscriptionRoutes = () => {
  const routesState = [
    useMatch('upgrade/select-organization'),
    useMatch('/upgrade/:organizationId/review-editors'),
  ];

  const currentStep = routesState.findIndex((active) => active);

  return (
    <LoggedInAuthGuard>
      <Container>
        <CreateOrganizationHeader />
        <Routes>
          <Route
            path=":organizationId/review-editors"
            element={<ReviewEditorsBeforePlanUpgradePage />}
          />
          <Route
            path=":organizationId/success"
            element={<OrganizationSubscriptionSuccessPage />}
          />
          <Route
            path="/select-organization"
            element={<SelectOrganizationForPlanUpgradePage />}
          />
          <Route path="*" element={<Navigate to="select-organization" />} />
        </Routes>
        <StepIndicator count={3} currentStep={currentStep} />
      </Container>
    </LoggedInAuthGuard>
  );
};

const Container = styled.div`
  height: 100vh;
  min-width: 100vw;
  background-color: ${(p) => p.theme.surface.e0};
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 40px;
`;
