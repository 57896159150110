import {
  urqlClient,
  CreateLayerMutation,
} from '@vizcom/shared/data-access/graphql';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';
import { DeleteLayerAction } from './deleteLayer';
import { omit } from 'lodash';
import { cachedLayerImagesByUrl } from './updateLayer';
import { imageDataToBlob } from '@vizcom/shared-ui-components';
import {
  checkImportedMeshFileSize,
  createObject3dThumbnail,
  exportObject3d,
  loadObject3DFromFile,
} from '../../../components/utils/meshHelpers';
import {
  DEFAULT_LAYER_3D_CAMERA_DISTANCE,
  DEFAULT_LAYER_3D_CAMERA_VIEW,
} from '../../../components/studio/DrawingCompositor/Layer3D/types';
import { v4 as uuid } from 'uuid';

// When we upload a mesh, we store the `Blob` in `meshPath` with the optimisticUpdater to show the mesh without waiting for the server response
// but when we receive the server response, `meshPath` gets replaced by the storage URL of the mesh
// this would cause a re-fetch of the mesh file, so we store a mapping of the `meshPath` URL -> Blob here to prevent that
// when needing to consume the mesh, we check if the URL is in this map and use the Blob instead
export const cachedLayerMeshByUrl = {} as Record<string, Blob>;

export type LayerPayload = {
  id: string;
  name: string;
  visible: boolean;
  opacity: number;
  blendMode: string;
  fill: string;
  orderKey: string;
  image?: ImageData | string | Blob | null;
  meshPath?: Blob | string | null;
  metadata3D?: LayerMetadata3d;
};

type AddLayerActionPayload = {
  type: 'addLayer';
  layer: LayerPayload;
};

export async function prepare3DLayerActionPayload(
  file: File,
  orderKey: string,
  width: number,
  height: number
): Promise<AddLayerActionPayload> {
  checkImportedMeshFileSize(file);

  const layerId = uuid();
  const mesh = await loadObject3DFromFile(file);
  const gltfBinary = (await exportObject3d(mesh)).result;
  const blob = new Blob([gltfBinary]);
  const thumbnail = await createObject3dThumbnail(mesh, width, height, [
    DEFAULT_LAYER_3D_CAMERA_DISTANCE,
    DEFAULT_LAYER_3D_CAMERA_VIEW.phi,
    DEFAULT_LAYER_3D_CAMERA_VIEW.theta,
  ]);

  return {
    type: 'addLayer',
    layer: {
      id: layerId,
      name: file.name,
      visible: true,
      opacity: 1,
      blendMode: 'normal',
      fill: '',
      orderKey,
      metadata3D: {
        view: DEFAULT_LAYER_3D_CAMERA_VIEW,
      },
      image: thumbnail,
      meshPath: blob,
    },
  };
}

export const AddLayerAction: SyncedActionType<
  Drawing2dStudio,
  AddLayerActionPayload
> = {
  type: 'addLayer',
  optimisticUpdater: ({ payload }, drawing) => {
    const existingLayer = drawing.layers.nodes.find(
      (l) => l.id === payload.layer.id
    );

    if (!existingLayer) {
      const newLayer: typeof drawing.layers.nodes[0] = {
        ...omit(payload.layer, 'image'),
        drawingId: drawing.id,
        updatedAt: '0',
        createdAt: '0',
        meshPath: payload.layer.meshPath,
        imagePath: payload.layer.image ?? undefined,
      };

      drawing.layers.nodes.push(newLayer);
    }
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    const res = await urqlClient.mutation(CreateLayerMutation, {
      input: {
        layer: {
          ...omit(payload.layer, 'image'),
          drawingId,
          imagePath:
            payload.layer.image instanceof ImageData
              ? await imageDataToBlob(payload.layer.image)
              : payload.layer.image,
        },
      },
    });

    if (
      res.data?.createLayer?.layer?.imagePath &&
      (payload.layer.image instanceof ImageData ||
        payload.layer.image instanceof Blob)
    ) {
      cachedLayerImagesByUrl[res.data?.createLayer?.layer?.imagePath] =
        payload.layer.image;
    }

    if (
      res.data?.createLayer?.layer?.meshPath &&
      payload.layer.meshPath instanceof Blob
    ) {
      cachedLayerMeshByUrl[res.data?.createLayer?.layer?.meshPath] =
        payload.layer.meshPath;
    }

    if (res?.error) {
      throw new Error(
        `Error while creating layer, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<typeof DeleteLayerAction> = {
      type: 'deleteLayer',
      id: payload.layer.id,
    };

    return undoPayload;
  },
};
