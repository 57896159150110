import { useParams, useNavigate } from 'react-router-dom';
import { paths } from '@vizcom/shared-utils-paths';
import { useCreateWorkbench } from '@vizcom/shared/data-access/graphql';
import { useState } from 'react';
import { LoadingLogo } from '@vizcom/shared-ui-components';
import { WelcomeModal } from './components/welcomeModal/WelcomeModal';
import { Canvas } from '@react-three/fiber';
import { BackgroundLayer } from './components/BackgroundLayer';

export const NewWorkbenchPage = () => {
  const { folderId } = useParams<{ folderId: string }>();
  const navigate = useNavigate();
  const [, createWorkbench] = useCreateWorkbench();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateWorkbench = async (
    name: string,
    startInStudio: boolean
  ) => {
    if (isLoading || !folderId) {
      return;
    }
    setIsLoading(true);
    const res = await createWorkbench({
      input: {
        workbench: {
          folderId,
          name,
        },
        startInStudio,
      },
    });

    if (res.data?.createWorkbench?.workbench?.id) {
      navigate(paths.workbench.file(res.data?.createWorkbench?.workbench?.id), {
        replace: true,
      });
    }

    setIsLoading(false);
  };

  if (!folderId) {
    return <></>;
  }

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Canvas>
        <BackgroundLayer />
      </Canvas>
      <WelcomeModal onExit={handleCreateWorkbench} />
    </div>
  );
};
