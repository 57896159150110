import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Text, addToast } from '@vizcom/shared-ui-components';
import { Link } from 'react-router-dom';
import {
  AuthLinkContainer,
  ErrorMessage,
  FormContainer,
  Input,
  InputGroup,
  PageContainer,
  SectionContainer,
} from '../styles';
import { useForgotPassword } from '@vizcom/shared/data-access/graphql';

type FormData = {
  email: string;
};

export function ForgotPasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [res, forgotPassword] = useForgotPassword();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onSubmit = async (data: FormData) => {
    const response = await forgotPassword(data);
    if (response.data?.forgotPassword?.status) {
      setShowSuccessMessage(true);
    } else if (response.error) {
      const errorMessage =
        response.error.graphQLErrors?.[0]?.message ||
        'Unable to reset password. Does this account exist?';
      addToast(errorMessage, { type: 'danger' });
    } else {
      addToast('Unable to reset password. Does this account exist?', {
        type: 'danger',
      });
    }
  };

  return (
    <PageContainer>
      {showSuccessMessage ? (
        <SectionContainer>
          <Text type="t1" block color={'black'}>
            Check your email
          </Text>
          <Text type="sh1" color={'black'} style={{ textAlign: 'left' }}>
            We've sent you an email with a link that you'll need to click to
            reset your password.
          </Text>
        </SectionContainer>
      ) : (
        <>
          <SectionContainer>
            <Text type="t1" block color={'black'}>
              Reset Password
            </Text>
            <Text type="sh1" color={'black'} style={{ textAlign: 'left' }}>
              Enter your login email to receive a password reset link
            </Text>
          </SectionContainer>

          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <Text type="sh2" color={'black'}>
                Email:
              </Text>
              <Input
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: 'Please enter a valid email',
                  },
                })}
                type="email"
                placeholder="Enter your email"
                name="email"
              />
              {errors.email && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )}
            </InputGroup>

            <Button
              disabled={res.fetching}
              variant="fullBlack"
              style={{
                marginTop: '40px',
                width: '100%',
              }}
            >
              {res.fetching ? 'Sending email...' : 'Send link'}
            </Button>
          </FormContainer>
        </>
      )}
      <AuthLinkContainer>
        <Text
          type="b1"
          color="primary"
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: '24px',
          }}
          as={Link}
          to="/auth"
        >
          Back to login
        </Text>
      </AuthLinkContainer>
    </PageContainer>
  );
}
