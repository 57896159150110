import {
  Color,
  DoubleSide,
  Mesh,
  MeshBasicMaterial,
  NearestFilter,
  OrthographicCamera,
  PlaneGeometry,
  Texture,
  UnsignedByteType,
  WebGLRenderTarget,
} from 'three';
import { useThree } from '@react-three/fiber';
import { yFlipImageBuffer } from '../../helpers';
import { useEffect, useRef } from 'react';
import { useStableCallback } from '@vizcom/shared-ui-components';

const camera = new OrthographicCamera(-1, 1, 1, -1, -1, 1);

export function useEraseSelectedRegion() {
  const { gl } = useThree((s) => s);

  const eraseFBO = useRef<WebGLRenderTarget | undefined>();
  const eraseMaterial = useRef<MeshBasicMaterial | undefined>();

  const debounceTime = useRef<number>(0);

  const eraseSelectedRegion = useStableCallback(
    (
      layerImage: Texture | string,
      selectionTexture: Texture,
      drawingSize: [number, number]
    ) => {
      const prevDebounceTime = debounceTime.current;
      //Always reset the debounce time to prevent multiple calls if 'delete' key is maintained.
      debounceTime.current = Date.now();
      if (Date.now() - prevDebounceTime < 500) {
        return;
      }

      //Create the material if missing or re-create if the type of the image changed.
      if (
        !eraseMaterial.current ||
        (eraseMaterial.current.map !== null &&
          typeof layerImage === 'string') ||
        (eraseMaterial.current.map === null && typeof layerImage !== 'string')
      ) {
        if (eraseMaterial.current) {
          eraseMaterial.current.dispose();
        }
        eraseMaterial.current = new MeshBasicMaterial({
          depthTest: false,
          side: DoubleSide,
        });
        eraseMaterial.current.onBeforeCompile = (shader) => {
          //Does the opposite of an alpha map to remove the selected part.
          //Regular alpha map would remove the unselected part.
          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <alphamap_fragment>`,
            `diffuseColor.a *= (1.0 - texture2D( alphaMap, vAlphaMapUv ).r);`
          );

          //Opaque fragment sets alpha to 1.0 if `material.transparent = false`.
          //We need `material.transparent = false` to prevent incorrect alpha blending.
          shader.fragmentShader = shader.fragmentShader.replace(
            `#include <opaque_fragment>`,
            `gl_FragColor = vec4( outgoingLight, diffuseColor.a );`
          );
        };
      }

      if (typeof layerImage === 'string') {
        eraseMaterial.current.color = new Color(layerImage);
      } else {
        eraseMaterial.current.map = layerImage;
      }
      eraseMaterial.current.alphaMap = selectionTexture;

      if (!eraseFBO.current) {
        eraseFBO.current = new WebGLRenderTarget(
          drawingSize[0],
          drawingSize[1],
          {
            samples: 0,
            type: UnsignedByteType,
            minFilter: NearestFilter,
            magFilter: NearestFilter,
          }
        );
      }

      //Render the layer to the fbo with the selection as an alpha map.
      const currentAutoClear = gl.autoClear;
      const currentRenderTarget = gl.getRenderTarget();

      gl.autoClear = true;
      gl.setRenderTarget(eraseFBO.current);

      gl.render(
        new Mesh(new PlaneGeometry(2, 2), eraseMaterial.current),
        camera
      );
      gl.autoClear = currentAutoClear;
      gl.setRenderTarget(currentRenderTarget);

      //extract the pixels
      const width = drawingSize[0];
      const height = drawingSize[1];
      const imageData = new ImageData(width, height);
      gl.readRenderTargetPixels(
        eraseFBO.current,
        0,
        0,
        width,
        height,
        imageData.data
      );
      yFlipImageBuffer(imageData.data, width);
      return imageData;
    }
  );

  useEffect(() => {
    return () => {
      eraseFBO.current?.dispose();
    };
  }, []);
  return { eraseSelectedRegion };
}
