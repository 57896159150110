import styled from 'styled-components';
import { Text, PaintBucketIcon, BrushIcon } from '@vizcom/shared-ui-components';
import {
  useWorkbenchStudioToolState,
  WorkbenchStudioTool,
} from '../studioState';
import { ToolSettings } from './ToolSettings';

export const BrushSettings = () => {
  const { tool, setTool } = useWorkbenchStudioToolState();

  return (
    <Container>
      <BrushTool
        $active={tool === WorkbenchStudioTool.Brush}
        onClick={() => {
          setTool(WorkbenchStudioTool.Brush);
        }}
      >
        <BrushIcon />
        <div>Brush</div>
        <div>B</div>
      </BrushTool>
      <BrushTool
        $active={tool === WorkbenchStudioTool.PaintBucket}
        onClick={() => {
          setTool(WorkbenchStudioTool.PaintBucket);
        }}
      >
        <PaintBucketIcon />
        <div>Paint Bucket</div>
        <div></div>
      </BrushTool>
      {tool === WorkbenchStudioTool.Brush && (
        <Settings>
          <Text
            style={{
              padding: '12px 12px 6px',
            }}
            type="sh2"
            as="div"
          >
            Brush settings
          </Text>
          <StyledToolSettings />
        </Settings>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 360px;
`;

const StyledToolSettings = styled(ToolSettings)`
  padding: 12px;
`;

const Tool = styled.button<{ $active?: boolean }>`
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ $active, theme }) =>
    $active ? theme.text.default : theme.text.info};
  &:disabled {
    color: ${({ theme }) => theme.text.disabled};
    cursor: default;
  }
`;

const BrushTool = styled(Tool)`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 0.75rem 12px;
  gap: 12px;
  text-align: left;
`;

const Settings = styled.div`
  border-top: 1px solid ${({ theme }) => theme.surface.e2};
`;
