export interface AutoSelectModelScale {
  samScale: number;
  height: number;
  width: number;
}

export enum AutoSelectInputPointType {
  INCLUDE = 0,
  EXCLUDE = 1,
}

export interface AutoSelectInputPoint {
  x: number;
  y: number;
  type: AutoSelectInputPointType;
}
