import * as THREE from 'three';
import { useFirstValue } from '@vizcom/shared-utils-hooks';
import { CompositeSceneMesh } from './types';
import { useGLTF } from '@react-three/drei';
import { useCompositeSceneEditorContext } from './context';
import { useEffect, useMemo, useState } from 'react';
import { isPointerIntersectingControls } from './utils/isPointerIntersectingControls';
import { getCompositeSceneNodeUUID } from './utils/compositeSceneNodeUUID';
import { ThreeEvent } from '@react-three/fiber';
import { CompositeSceneElement as CompositeSceneElementType } from '@vizcom/shared/data-access/graphql';
import { useTheme } from 'styled-components';
import { GLTF } from 'three-stdlib';

// @ts-ignore
import BasicShapeCamera from '../../../assets/basicShapes/camera.glb';

const ElementAsBasicShape = ({
  compositeSceneElement,
  onAddedToScene,
}: {
  compositeSceneElement: Partial<CompositeSceneElementType>;
  onAddedToScene: () => void;
}) => {
  const theme = useTheme();
  const { activeCamera, setActiveCamera } = useCompositeSceneEditorContext();
  const rootCompositeSceneElement = useMemo(() => {
    if (
      !compositeSceneElement.meshes ||
      Object.keys(compositeSceneElement.meshes).length === 0
    ) {
      return;
    }

    return compositeSceneElement.meshes[
      Object.keys(compositeSceneElement.meshes)[0]
    ];
  }, [compositeSceneElement]);
  const basicShapeModel = useGLTF(
    {
      Camera: BasicShapeCamera,
    }[compositeSceneElement.basicShape!]!
  ) as GLTF;
  const normalizedModel = useMemo(() => {
    const cloned = basicShapeModel.scene.clone(true);

    cloned.scale.set(1.0, 1.0, -1.0).multiplyScalar(0.25);

    cloned.traverse((child: THREE.Object3D) => {
      child.userData.selectable = false;
      child.userData.rootId = compositeSceneElement.id;

      if (
        child instanceof THREE.Mesh &&
        child.material instanceof THREE.MeshStandardMaterial
      ) {
        child.material = child.material.clone();
      }
    });

    return cloned;
  }, [basicShapeModel.scene]);
  const isHidden = activeCamera === compositeSceneElement.id;

  useEffect(() => {
    onAddedToScene();
  }, []);

  const onPointerOver = (event: ThreeEvent<MouseEvent>) => {
    if (isHidden) {
      return;
    }

    event.stopPropagation();

    normalizedModel.traverse((child: THREE.Object3D) => {
      if (
        !(child instanceof THREE.Mesh) ||
        !(child.material instanceof THREE.MeshStandardMaterial)
      ) {
        return;
      }

      child.material.emissive.set(new THREE.Color(theme.tertiary.default));
      child.material.emissiveIntensity = 1.0;
    });
  };

  const onPointerOut = () => {
    normalizedModel.traverse((child: THREE.Object3D) => {
      if (
        !(child instanceof THREE.Mesh) ||
        !(child.material instanceof THREE.MeshStandardMaterial)
      ) {
        return;
      }

      child.material.emissiveIntensity = 0.0;
    });
  };

  const onClick = (event: ThreeEvent<MouseEvent>) => {
    if (isHidden) {
      return;
    }

    event.stopPropagation();

    setActiveCamera(compositeSceneElement.id ?? null);
  };

  return (
    <group
      userData={{
        vizcomUI: true,
      }}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      onClick={onClick}
      visible={!isHidden}
      position={rootCompositeSceneElement?.position}
      quaternion={rootCompositeSceneElement?.quaternion}
      scale={rootCompositeSceneElement?.scale}
    >
      <primitive
        object={normalizedModel}
        userData={{
          rootId: compositeSceneElement.id,
          selectable: false,
        }}
      />
    </group>
  );
};

const ElementAsGLTF = ({
  compositeSceneElement,
  onAddedToScene,
}: {
  compositeSceneElement: Partial<CompositeSceneElementType>;
  onAddedToScene: () => void;
}) => {
  const { modelPath, id } = compositeSceneElement;
  const meshes = compositeSceneElement.meshes as Partial<CompositeSceneMesh>[];
  const firstUrl = useFirstValue(modelPath!);
  const { selected, hovered, setSelected, setHovered } =
    useCompositeSceneEditorContext();
  /**
   * NOTE Only use the first URL for a model, this is okay because the URL is immutable
   *      But when uploading a model, the first url is a blob: URL used for optimistic updates
   *      In this case we continue using this URL for the lifetime of this object
   */
  const gltf = useGLTF(firstUrl);
  const [element, setElement] = useState<THREE.Object3D | null>(null);

  const onClick = (event: ThreeEvent<MouseEvent>) => {
    const { object } = event;
    const isIntersectingControls = isPointerIntersectingControls(
      event.intersections
    );

    if (!object || hovered?.uuid === object.uuid || isIntersectingControls) {
      return;
    }

    event.stopPropagation();
  };

  const onPointerDown = (event: ThreeEvent<MouseEvent>) => {
    const { object } = event;
    const isIntersectingControls = isPointerIntersectingControls(
      event.intersections
    );

    if (
      !object ||
      !object.visible ||
      selected?.uuid === object.uuid ||
      isIntersectingControls
    ) {
      return;
    }

    event.stopPropagation();

    setSelected(object);
  };

  const onPointerOver = (event: ThreeEvent<MouseEvent>) => {
    const { object } = event;
    const isIntersectingControls = isPointerIntersectingControls(
      event.intersections
    );

    if (!object || hovered?.uuid === object.uuid || isIntersectingControls) {
      return;
    }

    event.stopPropagation();

    setHovered(object);
  };

  const onPointerOut = (event: ThreeEvent<MouseEvent>) => {
    const { object } = event;

    event.stopPropagation();

    if (!object) {
      return;
    }

    if (hovered === object) {
      setHovered(null);
    }
  };

  useEffect(() => {
    onAddedToScene();
  }, [gltf]);

  useEffect(() => {
    /**
     * NOTE Adjust model to fit data coming from backend.
     *      Deleting meshes currently marks them as `deleted` in the scene, as to not modify the model structure
     *      on the backend.
     *      All children of a deleted object are also hidden.
     */
    if (!gltf || !meshes) {
      return;
    }

    const rootId = id;
    const meshMap: Record<string, THREE.Object3D> = {};
    const rootNode = gltf.scene.children[0];

    rootNode.traverse((child) => {
      const mesh = child as THREE.Mesh;
      const uuid = getCompositeSceneNodeUUID(child);

      if (uuid) {
        meshMap[uuid] = mesh;
      }

      mesh.userData.selectable = true;
      mesh.userData.rootId = rootId;

      if (mesh.isMesh) {
        mesh.castShadow = true;
        mesh.receiveShadow = true;
      }
    });

    Object.entries(meshes).forEach(([uuid, mesh]) => {
      const child = meshMap[uuid] as THREE.Mesh;

      if (!child) {
        return;
      }

      if (mesh.position) {
        child.position.set(...mesh.position);
      }

      if (mesh.quaternion) {
        child.quaternion.set(...mesh.quaternion);
      }

      if (mesh.scale) {
        child.scale.set(...mesh.scale);
      }

      child.traverse((subchild) => (subchild.visible = !mesh.deleted));

      if (mesh.material && child.material) {
        const material = (child.material as THREE.MeshPhysicalMaterial).clone();
        child.material = material;

        if (mesh.material.roughness !== undefined) {
          material.roughness = mesh.material.roughness;
          material.clearcoat = 1.0 - mesh.material.roughness;
        }

        if (mesh.material.metalness !== undefined) {
          material.metalness = mesh.material.metalness;
        }

        if (mesh.material.opacity !== undefined) {
          material.opacity = mesh.material.opacity;
          material.transparent = mesh.material.opacity < 1;
        }

        if (mesh.material.color !== undefined) {
          material.color = new THREE.Color(mesh.material.color);
        }
      }

      child.updateWorldMatrix(true, true);
      child.updateMatrix();
    });

    rootNode.userData.selectable = true;
    rootNode.userData.isRootScene = true;

    setElement(rootNode);
  }, [meshes, gltf]);

  if (!element) {
    return null;
  }

  return (
    <primitive
      object={element}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    />
  );
};

export const CompositeSceneElement = ({
  compositeSceneElement,
  onAddedToScene,
}: {
  compositeSceneElement: Partial<CompositeSceneElementType>;
  onAddedToScene: () => void;
}) => {
  if (compositeSceneElement.modelPath) {
    return (
      <ElementAsGLTF
        compositeSceneElement={compositeSceneElement}
        onAddedToScene={onAddedToScene}
      />
    );
  }

  if (compositeSceneElement.basicShape) {
    return (
      <ElementAsBasicShape
        compositeSceneElement={compositeSceneElement}
        onAddedToScene={onAddedToScene}
      />
    );
  }

  return null;
};
